import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import { useClassroomLekSessionsQuery } from '@/graphql';
import { useCountdown } from '@/hooks/useCountdown';
import { LekSessionStatus } from '@/graphql';

type LekCountdownProps = {
  sessionId: string;
};
type LekCountdownInnerProps = {
  finishDateTime: string;
  startDateTime: string;
  lekSessionStatus?: LekSessionStatus | null | undefined;
};

const LekCountdownInner: React.FC<LekCountdownInnerProps> = ({
  startDateTime,
  finishDateTime,
  lekSessionStatus,
}) => {
  const { t } = useTranslation();

  const duration = useMemo(
    () =>
      +DateTime.fromISO(
        lekSessionStatus === LekSessionStatus.Pending
          ? startDateTime
          : finishDateTime
      ).diffNow(),
    [lekSessionStatus, finishDateTime, startDateTime]
  );
  const { timeLeft, timeLeftInMillis } = useCountdown(duration);

  if (timeLeftInMillis < 1) return <></>;

  return (
    <Box mt={1}>
      <Typography variant="caption">
        {lekSessionStatus === LekSessionStatus.Pending
          ? t('lek.starts-in')
          : t('lek.ends-in')}
      </Typography>
      <Typography variant="caption" color="textPrimary">
        {' '}
        {timeLeft}
      </Typography>
    </Box>
  );
};

const LekCountdown: React.FC<LekCountdownProps> = ({ sessionId }) => {
  const { data } = useClassroomLekSessionsQuery({
    variables: { sessionId },
  });

  return (
    <Box>
      {data?.lekSessionsData?.map(
        (it) =>
          it?.lekSession?.status !== LekSessionStatus.Finished && (
            <LekCountdownInner
              key={it?.id}
              startDateTime={it?.lekSession?.startDateTime}
              finishDateTime={it?.lekSession?.finishDateTime}
              lekSessionStatus={it?.lekSession?.status}
            />
          )
      )}
    </Box>
  );
};

export default LekCountdown;
