import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Tooltip, Button, Box } from '@material-ui/core';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useUserMeQuery } from '@/graphql';
import { LS_KEY_LAST_ONBOARD_PAGE } from '@/utils/constants';

const stepsArr = [
  {
    element: '#home-nav-icon',
    location: '/home?isOnboardingActive=true&step=2',
  },
  {
    element: '#daily-tipper-card-el',
    location: '/home?isOnboardingActive=true&step=3',
  },
  {
    element: '#empty-duel-card-icon',
    location: '/home?isOnboardingActive=true&step=4',
  },
  {
    element: '#my-learning-card-icon',
    location: '/biblio?isOnboardingActive=true&step=5',
  },
  {
    element: '#biblio-nav-icon',
    location: '/duels?isOnboardingActive=true&step=6',
  },
  {
    element: '#duels-nav-icon',
    location: '/notifications?isOnboardingActive=true&step=7',
  },
  {
    element: '#notifications-nav-icon',
    location: '/home?isOnboardingActive=true&step=8',
  },
  {
    element: '#burger-menu-icon',
    location: '/onboard-first-duel',
  },
];

type IntroWalkthroughProps = {
  onboardingStep: number;
};

export const IntroWalkthrough: React.FC<IntroWalkthroughProps> = ({
  onboardingStep,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [step, setStep] = useState(onboardingStep || 1);
  const [elem, setElem] = useState<Element | null>(null);

  const { data } = useUserMeQuery();

  const isIntroCompleted = data?.me?.settings?.isIntroCompleted;

  const waitForElm = (selector: any) => {
    return new Promise<Element>((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  };

  useEffect(() => {
    step <= stepsArr.length &&
      waitForElm(stepsArr[step - 1].element).then((elmn) => setElem(elmn));
  }, [step]);

  const getRefElementPosition = useMemo(() => {
    if (elem) {
      const coordinates = elem?.getBoundingClientRect();
      if (coordinates.bottom > window.innerHeight) {
        elem.scrollIntoView();
      }
      return {
        x:
          (elem?.getBoundingClientRect().right +
            elem?.getBoundingClientRect().left) /
          2,
        y:
          (elem?.getBoundingClientRect().bottom +
            elem?.getBoundingClientRect().top) /
          2,
      };
    } else
      return {
        x: 0,
        y: 0,
      };
  }, [elem]);

  return (
    <>
      {elem && (
        <div className={classes.backDropBox}>
          <svg width="100%" height="100%">
            <mask id="mask">
              <rect fill="white" width="100%" height="100%" fillOpacity="1" />
              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
                title={
                  <Box display="grid" gridRowGap={4} flexGrow={1}>
                    <Box className={classes.container}>
                      <Typography variant="subtitle2" className={classes.title}>
                        {t(`onboarding.walkthrough.step-${step}.title`)}
                      </Typography>
                    </Box>
                    <Box marginTop={2} marginBottom={3}>
                      <Typography variant="body2" gutterBottom>
                        {t(`onboarding.walkthrough.step-${step}.description`)}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        localStorage.setItem(
                          LS_KEY_LAST_ONBOARD_PAGE,
                          stepsArr[step - 1].location
                        );
                        setStep(step + 1);
                        history.push(stepsArr[step - 1].location);
                      }}
                    >
                      {' '}
                      {t('onboarding.walkthrough.button-text', { step })}
                    </Button>
                  </Box>
                }
                interactive
                open={!isIntroCompleted}
                arrow
              >
                <circle
                  cx={getRefElementPosition.x}
                  cy={getRefElementPosition.y}
                  r="50"
                />
              </Tooltip>
            </mask>
            <rect
              mask="url(#mask)"
              fill="#2C3E50"
              width="100%"
              height="100%"
              fillOpacity="0.8"
            />
          </svg>
        </div>
      )}
    </>
  );
};

export default IntroWalkthrough;
