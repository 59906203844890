import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box mt={2}>
      <Typography variant="caption">
        {t('fields.privacy.label')}{' '}
        <Link
          className={classes.link}
          color="primary"
          rel="noopener"
          target="_blank"
          to={{
            pathname: '/privacy-dataprotection',
            search: '?hideBackArrow=true&target_blank=true',
          }}
        >
          <Typography
            variant="caption"
            component="span"
            display="inline"
            className={classes.link}
          >
            {t('fields.privacy.link')}
          </Typography>
        </Link>
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
