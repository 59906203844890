import React, { useMemo } from 'react';
import { Box, LinearProgress, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import StarIcon from '@/components/icons/star';
interface ProgressItemProps {
  idx: number;
  id: string;
  title: string | undefined;
  lectureGoal: number;
  correctAnswersCount: number;
  questions: number;
  isNetworkType: boolean | undefined;
}

const ProgressItem: React.FC<ProgressItemProps> = ({
  id,
  lectureGoal,
  correctAnswersCount,
  questions,
  title,
  idx,
  isNetworkType,
}) => {
  const classes = useStyles();

  const progress = useMemo(() => {
    const info = {
      goal: 0,
      correctAnswers: 0,
    };
    info.goal = lectureGoal > 0 ? (lectureGoal * 100) / questions : questions;
    info.correctAnswers =
      correctAnswersCount > 0 ? (correctAnswersCount * 100) / questions : 0;
    if (info.correctAnswers > 100) {
      info.correctAnswers = 100;
    }
    return info;
  }, [questions, lectureGoal, correctAnswersCount]);

  return (
    <Box
      key={id}
      marginTop={3}
      px={4}
      py={5}
      className={classes.lecProgressBox}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2">
          {idx + 1}. {title}
        </Typography>
        <Box display="flex" alignItems="center" className={classes.goal}>
          <Typography
            color={
              lectureGoal > 0 && correctAnswersCount >= lectureGoal
                ? 'primary'
                : 'inherit'
            }
            variant="subtitle2"
          >
            {lectureGoal}
          </Typography>
          <StarIcon
            style={{ marginLeft: 6 }}
            color={
              lectureGoal > 0 && correctAnswersCount >= lectureGoal
                ? 'primary'
                : 'inherit'
            }
          />
        </Box>
      </Box>

      <Box marginTop={3} className={classes.progressWrap}>
        <LinearProgress
          style={{ height: 4, borderRadius: 4 }}
          variant="determinate"
          value={progress.correctAnswers}
          classes={{
            barColorPrimary: classes.barColorPrimary,
            barColorSecondary: classes.barColorSecondary,
          }}
        />
        <Box
          className={classes.progressGoal}
          style={{ left: `calc(${progress.goal}% - 6px)` }}
        >
          <span className={classes.goalMark} />
          <StarIcon style={{ marginTop: 2 }} />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={3}>
        <Typography color="inherit" variant="overline">
          {correctAnswersCount}
        </Typography>
        <Typography className={classes.caption} variant="overline">
          /{questions}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressItem;
