import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  label: {
    width: `calc(100% - ${theme.spacing(4)}px)`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  rank: {
    backgroundColor: 'rgba(230, 238, 250, 0.5)',
  },
}));
