import React from 'react';
import { Box, Typography, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import HyperText from '@/components/hypertext';

const Forgoted: React.FC = () => {
  const { t } = useTranslation();
  const { search: emailValue } = useLocation();

  return (
    <Box marginTop={3}>
      <Typography variant="body2" component="p">
        <HyperText text={t('forgoted-password.email.info')}>
          <Link>{emailValue.slice(1)}</Link>
        </HyperText>
      </Typography>
    </Box>
  );
};

export default Forgoted;
