import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
  Link,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { ModalContext } from '@/contexts/modal-context';
import DuelRule1Icon from '@/components/icons/duel-rule-1';
import DuelRule2Icon from '@/components/icons/duel-rule-2';
import DuelRule3Icon from '@/components/icons/duel-rule-3';
import DuelRule5Icon from '@/components/icons/duel-rule-5';
import DuelRule7Icon from '@/components/icons/duel-rule-7';
import DuelRule8Icon from '@/components/icons/duel-rule-8';
import BoxMagicIcon from '@/components/icons/box-magic';
import { useQuizTimeDetailsQuery } from '@/graphql';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(17),
  },
  close: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    position: 'absolute',
  },
  listItem: {
    display: 'flex',
    marginTop: 16,
  },
  info: {
    backgroundColor: '#E2F5FD',
    borderRadius: 6,
  },
  iconBox: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 40,
    height: 40,
    background: '#E2F5FD',
    borderRadius: 4,
    marginRight: 20,
    color: theme.palette.primary.dark,
  },
}));

const dataArr = [
  { key: '1', icon: <DuelRule8Icon /> },
  { key: '2', icon: <DuelRule1Icon /> },
  { key: '3', icon: <DuelRule2Icon /> },
  { key: '4', icon: <DuelRule3Icon /> },
  { key: '5', icon: <DuelRule5Icon /> },
  { key: '6', icon: <DuelRule7Icon /> },
  { key: '7', icon: <BoxMagicIcon /> },
];

export const useQuiztimeDuelRulesFullScreenModal = (
  quizSessionId: string,
  setQuizDuelRulesRead: () => void
) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = React.useContext(ModalContext);
  const classes = useStyles();
  const { data } = useQuizTimeDetailsQuery({
    variables: { quizSessionId },
  });
  const participationConditions =
    data?.quizTimeSession?.quizTime?.participationConditions;

  const closeFullScreenModal = useCallback(() => {
    if (setQuizDuelRulesRead) setQuizDuelRulesRead();
    closeModal();
  }, [setQuizDuelRulesRead, closeModal]);

  const element = React.useMemo(
    () => (
      <Box className={classes.root}>
        <IconButton
          aria-label="close"
          className={classes.close}
          onClick={closeFullScreenModal}
        >
          <CloseIcon htmlColor="#4A647C" />
        </IconButton>
        <Typography variant="h2">{t('dialogs.qt-duel-rules.title')}</Typography>
        <Box mt={5} py={3} px={4} className={classes.info}>
          <Typography variant="subtitle2">
            {t('dialogs.qt-duel-rules.info')}
          </Typography>
        </Box>
        <Box mt={5} mb={7}>
          {dataArr.map((it) => (
            <Box key={it.key} mt={2} className={classes.listItem}>
              <Box className={classes.iconBox}>{it.icon}</Box>
              <Box>
                <Typography variant="body2">
                  <Trans
                    i18nKey={`dialogs.qt-duel-rules.rule-${it.key}.descr`}
                    components={{
                      link1: participationConditions && (
                        <Link
                          href={participationConditions}
                          target="_blank"
                          color="primary"
                        />
                      ),
                    }}
                  />
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={closeFullScreenModal}
        >
          {t('common.ok')}
        </Button>
      </Box>
    ),
    [closeFullScreenModal, t, classes, participationConditions]
  );

  const open = React.useCallback(() => {
    openModal({
      content: element,
      handleClose: closeFullScreenModal,
      fullScreen: true,
    });
  }, [openModal, element, closeFullScreenModal]);

  return { open, close: closeFullScreenModal };
};
