import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  myRanking: {
    background: 'rgba(255, 255, 255, 0.92)',
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: `calc(100% - ${theme.spacing(8)}px)`,
  },
  my: {},
  myLabel: { color: theme.palette.primary.dark },
  myAvaWrap: {
    '& .MuiAvatar-circle': {
      borderColor: 'rgba(43, 117, 179, 0.72)',
    },
    '& .avaLazyWrap': {
      border: '1px solid rgba(43, 117, 179, 0.72)',
    },
  },
  btnCreateDuelDisabled: {
    background: 'linear-gradient(#ECF0F1 100%, #ECF0F1 100%)',
    border: 0,
  },
  rank: {
    marginRight: theme.spacing(4),
    zIndex: 1,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#fff',
  },
  myRank: {
    background: '#fff',
  },
  joinedRankItem: {
    left: 0,
    width: 64,
    height: 66,
    margin: '-13px 0 -9px -24px',
    background: '#fff',
    flexShrink: 0,
  },
  joinedRankCircle: {
    position: 'absolute',
    width: 5,
    height: 5,
    left: 34,
    top: 0,
    bottom: 0,
    margin: 'auto',
    background: '#C6D5E3',
    borderRadius: '50%',
    border: '1px solid #FFFFFF',
  },
  joinedRankLine: {
    position: 'absolute',
    width: 1,
    height: 59,
    left: 37,
    bottom: 36,
    background: '#C6D5E3',
  },
  secondaryAction: {
    flexShrink: 0,
  },
}));
