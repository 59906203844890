/* eslint-disable react/display-name */
import React, { useState, useCallback, useEffect } from 'react';

import Create from './create';
import PersonalInfo from './personal-info';
import ChoseProfessions from './choose-profession';
import ProfessionInfo from './profession-info';
import Completed from './completed';
import { SS_KEY_STEP_SIGNUP } from '@/utils/constants';

const jsonStepData = sessionStorage.getItem(SS_KEY_STEP_SIGNUP);
const initialStepData = jsonStepData ? JSON.parse(jsonStepData) : 0;

const SignUp: React.FC = () => {
  const [step, setStep] = useState<number>(initialStepData);

  const next = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const back = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  useEffect(() => {
    sessionStorage.setItem(SS_KEY_STEP_SIGNUP, JSON.stringify(step));
  }, [step]);

  switch (step) {
    case 0:
      return <Create onNext={next} onBack={back} />;
    case 1:
      return <PersonalInfo onNext={next} onBack={back} />;
    case 2:
      return <ChoseProfessions onNext={next} onBack={back} />;
    case 3:
      return <ProfessionInfo onNext={next} onBack={back} />;
    case 4:
      return <Completed />;
    default:
      return <></>;
  }
};

export default SignUp;
