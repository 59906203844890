import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  userRating: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px 4px 4px 4px',
    width: 'max-content',
    height: '44px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '0px 0px 4px 4px',
    border: '1px solid #E6EEFA',
    borderTop: 'none',
    position: 'absolute',
    zIndex: 100,
    right: theme.spacing(4),
    boxSizing: 'border-box',
  },
}));
