import { useCallback, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { sortBy } from 'lodash';
import { useHistory } from 'react-router-dom';

import {
  TodayDailyTipperDocument,
  TodayDailyTipperQuery,
  useGlobalDialogQuery,
} from '@/graphql';
import { useGlobalDialog } from './useGlobalDialog';
import { GlobalDialogTypes } from '@/type';

export const useDailyTipper = () => {
  const history = useHistory();
  const cache = useApolloClient();
  const [loading, setLoading] = useState(false);
  const { setGlobalDialog } = useGlobalDialog();
  const { data: dataGlobalDialog } = useGlobalDialogQuery();

  const openDailyTipper = useCallback(async () => {
    setLoading(true);
    if (!dataGlobalDialog?.dialogId)
      setGlobalDialog(GlobalDialogTypes.DAILY_TIPPER);
    const { data } = await cache.query<TodayDailyTipperQuery>({
      query: TodayDailyTipperDocument,
    });

    if (!data) return;

    const firstQuestion = sortBy(
      (data.dailyTipperData?.dailyTipperQuestions || []).filter(
        (it) => it && !it.userAnswer
      ),
      ['questionNumber']
    )[0];

    setLoading(false);
    return history.push(`/daily-tipper-question/${firstQuestion?.id}`);
  }, [cache, history, dataGlobalDialog, setGlobalDialog]);

  return { openDailyTipper, loading };
};
