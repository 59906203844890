import React, { useCallback, useContext, useEffect, useState } from 'react';

interface IEventContext {
  installEvent: Event | null;
  clearInstallEvent: () => void;
}

export const EventContext =
  React.createContext<IEventContext | undefined>(undefined);

export const EventProvider: React.FC = ({ children }) => {
  const [installEvent, setInstallEvent] = useState<Event | null>(null);

  useEffect(() => {
    if (window?.ReactNativeWebView) {
      console.log(
        'Running inside React Native WebView. Not adding event listener.'
      );
      return;
    }
    const listener = (event: any) => {
      event.preventDefault();
      setInstallEvent(event);
    };

    window.addEventListener('beforeinstallprompt', listener);

    return () => {
      window.removeEventListener('beforeinstallprompt', listener);
    };
  }, []);

  const clearInstallEvent = useCallback(() => {
    setInstallEvent(null);
  }, []);

  return (
    <EventContext.Provider
      value={{
        installEvent,
        clearInstallEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const useInstallEvent = () => {
  const context = useContext(EventContext);
  if (!context) throw new Error('EventContext not found');
  return context;
};
