import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  errorBox: {
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(235, 87, 87, 0.1)',
    border: '1px solid #EB5757',
    borderRadius: 4,
    padding: 8,
  },
}));
