import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';

import { Box, Paper } from '@material-ui/core';

import * as Form from '@/components/form';
import AppButton from '@/components/app-button';
import Loading from '@/components/loading';
import Fallback from '@/components/fallback';
import { useUser } from '@/contexts/user-context';
import { useStudentDataQuery, useUpdateUserProfileMutation } from '@/graphql';

import EditBtn from '../../components/edit-btn';

interface Form {
  semester: string;
  university: string;
  studyBegin: string;
  medicineStudyBegin: DateTime | null;
  registrationNumber: string;
}

interface StudentProps {
  professionType: number;
}

const DoctorInfo: React.FC<StudentProps> = ({ professionType }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const toggleMode = useCallback(
    (value: boolean) => () => {
      setIsEditMode(value);
    },
    []
  );

  const { data, error } = useStudentDataQuery();
  const [updateUserProfile, { loading: saveLoading }] =
    useUpdateUserProfileMutation();

  const { errors, watch, control } = useForm<Form>({ mode: 'onChange' });

  const toYearDate = (date: DateTime | null) => {
    return `${date?.year}-01-01`;
  };

  const save = useCallback(async () => {
    await updateUserProfile({
      variables: {
        semesterId: watch('semester'),
        universityId: watch('university'),
        studyBeginId: watch('studyBegin'),
        medicineStudyBegin: toYearDate(watch('medicineStudyBegin')),
        registrationNumber: watch('registrationNumber'),
      },
    });

    setIsEditMode(false);
  }, [updateUserProfile, watch]);

  const semesters = useMemo(
    () =>
      (data?.semesters || []).map((it) => ({
        value: String(it?.id),
        label: String(it?.title),
      })),
    [data]
  );

  const universities = React.useMemo(
    () =>
      (data?.universities || []).map((it) => ({
        value: String(it?.id),
        label: String(it?.title),
      })),
    [data]
  );

  const studyBegins = React.useMemo(
    () =>
      (data?.studySemesters || []).map((it) => ({
        value: String(it?.id),
        label: String(it?.title),
      })),
    [data]
  );

  const isValid = useMemo(() => {
    const values = watch();

    return (
      isEmpty(errors) &&
      !!values.semester &&
      !!values.university &&
      !!values.studyBegin &&
      !!values.medicineStudyBegin &&
      !!values.registrationNumber
    );
  }, [errors, watch]);

  const isDirty = useMemo(() => {
    const {
      semester,
      university,
      studyBegin,
      medicineStudyBegin,
      registrationNumber,
    } = watch();

    return (
      semester !== user?.semesterId ||
      university !== user?.universityId ||
      studyBegin !== user?.studyBeginId ||
      toYearDate(medicineStudyBegin) !== user?.medicineStudyBegin ||
      registrationNumber !== user?.registrationNumber
    );
  }, [user, watch]);

  const medicalBeginLabel = useMemo(() => {
    const date = DateTime.fromISO(user?.medicineStudyBegin);

    return date.toFormat('yyyy');
  }, [user]);

  if (!user) return <Loading />;

  if (!!error) {
    console.error('useStudentDataQuery', error);
    return <Fallback />;
  }

  return (
    <Box clone height="100%" boxSizing="border-box" paddingY={7} paddingX={4}>
      <Paper elevation={0}>
        {isEditMode ? (
          <>
            <Form.Row>
              <Controller
                render={(props) => (
                  <Form.SelectField
                    fullWidth
                    required
                    autoFocus
                    data-cy-semester
                    variant="outlined"
                    label={t('fields.semester.label')}
                    options={semesters}
                    errors={errors.semester}
                    {...props}
                  />
                )}
                control={control}
                name="semester"
                defaultValue={user?.semesterId || ''}
              />
            </Form.Row>
            <Form.Row>
              <Controller
                render={(props) => (
                  <Form.SelectField
                    fullWidth
                    required
                    data-cy-university
                    variant="outlined"
                    label={t('fields.university.label')}
                    options={universities}
                    errors={errors.university}
                    {...props}
                  />
                )}
                control={control}
                name="university"
                defaultValue={user?.universityId || ''}
              />
            </Form.Row>
            <Form.Row>
              <Controller
                render={(props) => (
                  <Form.DateField
                    autoOk
                    fullWidth
                    disableFuture
                    disableToolbar
                    data-cy-medicine-study-begin
                    format="yyyy"
                    variant="inline"
                    inputVariant="outlined"
                    views={['year']}
                    label={t('fields.medicine-study-begin.label')}
                    errors={errors.medicineStudyBegin}
                    {...props}
                  />
                )}
                control={control}
                name="medicineStudyBegin"
                defaultValue={
                  user?.medicineStudyBegin
                    ? DateTime.fromISO(user?.medicineStudyBegin)
                    : null
                }
              />
            </Form.Row>
            <Form.Row>
              <Controller
                render={(props) => (
                  <Form.RadioField
                    fullWidth
                    data-cy-study-begin
                    label={t('fields.study-begin.label')}
                    options={studyBegins}
                    {...props}
                  />
                )}
                control={control}
                name="studyBegin"
                defaultValue={user?.studyBeginId || ''}
              />
            </Form.Row>
            <Form.Row>
              <Controller
                render={(props) => (
                  <Form.TextField
                    required
                    fullWidth
                    data-cy-registration-number
                    variant="outlined"
                    label={t('fields.registration-number.label')}
                    placeholder={t('fields.registration-number.placeholder')}
                    errors={errors.registrationNumber}
                    {...props}
                    value={props.value?.replace(/[^a-z0-9]/gi, '')}
                  />
                )}
                control={control}
                name="registrationNumber"
                defaultValue={user?.registrationNumber || ''}
              />
            </Form.Row>
            <AppButton
              fullWidth
              data-cy-save-changes
              color="primary"
              variant="contained"
              disabled={!isValid || !isDirty}
              loading={saveLoading}
              onClick={save}
            >
              {t('common.save-changes')}
            </AppButton>
          </>
        ) : (
          <>
            <Form.InfoField label={t('fields.profession.label')}>
              {user?.profession?.title}
            </Form.InfoField>
            <Form.InfoField label={t('fields.semester.label')}>
              {user?.semester?.title}
            </Form.InfoField>
            <Form.InfoField label={t('fields.university.label')}>
              {user?.university?.title}
            </Form.InfoField>
            <Form.InfoField label={t('fields.medicine-study-begin.label')}>
              {medicalBeginLabel}
            </Form.InfoField>
            <Form.InfoField label={t('fields.study-begin.label')}>
              {user?.studyBegin?.title}
            </Form.InfoField>
            <Form.InfoField label={t('fields.registration-number.label')}>
              {user?.registrationNumber}
            </Form.InfoField>
            <EditBtn onClick={toggleMode(true)} />
          </>
        )}
      </Paper>
    </Box>
  );
};

export default DoctorInfo;
