import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Typography,
  Tooltip,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { useStyles } from './styles';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  useCrSessionTypeLazyQuery,
  useUpdateIsClassRoomIntroCompletedSettingsMutation,
  useUserMeQuery,
} from '@/graphql';
import { useApolloClient } from '@apollo/client';
import { USER_SETTINGS_FRAGMENT } from '@/apollo/fragments';
import { ClassroomTypes, TabNavIdsEnum } from '@/type';
import { LS_KEY_TABINDEX } from '@/utils/constants';

type ClassRoomParams = {
  sessionId: string;
};

export const TooltipClassroom: React.FC = () => {
  const [step, setStep] = React.useState(1);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [elem, setElem] = React.useState<Element | null>(null);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [isOpened, setOpened] = React.useState(true);
  const { sessionId } = useParams<ClassRoomParams>();

  const client = useApolloClient();
  const { data } = useUserMeQuery();

  const [update, { loading }] =
    useUpdateIsClassRoomIntroCompletedSettingsMutation();

  const [getClassRoomSession, { data: CRdata }] = useCrSessionTypeLazyQuery({
    variables: { sessionId },
  });

  useEffect(() => {
    return () => {
      localStorage.setItem(`${TabNavIdsEnum.CLASSROOM}${LS_KEY_TABINDEX}`, '0');
    };
  }, [sessionId]);

  useEffect(() => {
    if (sessionId && !CRdata) getClassRoomSession({ variables: { sessionId } });
  }, [sessionId, getClassRoomSession, CRdata]);

  const isClassRoomIntroCompleted =
    data?.me?.settings?.isClassRoomIntroCompleted;

  const isBlendedType = useMemo(
    () =>
      CRdata?.classRoomLearningSession?.classRoom?.enrollmentType ===
      ClassroomTypes.BLENDED,
    [CRdata]
  );

  const handleClose = useCallback(() => {
    history.replace(window.location.pathname);
    setOpened(false);
    setIsCompleted(true);
  }, [history]);

  const waitForElm = (selector: any) => {
    return new Promise<Element>((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((_mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  };

  useEffect(() => {
    if (
      isClassRoomIntroCompleted === false &&
      !loading &&
      (isCompleted || step === 5) &&
      isOpened === false
    ) {
      client.writeFragment({
        id: `${data?.me?.settings?.__typename}:${data?.me?.settings?.id}`,
        fragment: USER_SETTINGS_FRAGMENT,
        data: {
          ...data?.me?.settings,
          isClassRoomIntroCompleted: true,
        },
      });

      try {
        update({
          variables: {
            isClassRoomIntroCompleted: true,
          },
        });
      } catch (err) {
        console.error(
          'useUpdateIsClassRoomIntroCompletedSettingsMutation',
          err
        );
      }
    }
  }, [
    data,
    isClassRoomIntroCompleted,
    client,
    loading,
    update,
    step,
    isCompleted,
    isOpened,
  ]);

  const isNextStep = step <= 4 ? step : null;

  useEffect(() => {
    const info = [
      { element: '#classroom-activity-tab' },
      { element: '#classroom-attendees-tab' },
      { element: '#classroom-details-tab' },
      { element: '#classroom-menu' },
    ];
    step <= 4 &&
      waitForElm(info[step - 1].element).then((element) => setElem(element));
  }, [isNextStep, step]);

  const getRefElementPosition = useMemo(() => {
    if (elem)
      return {
        x:
          (elem?.getBoundingClientRect().right +
            elem?.getBoundingClientRect().left) /
          2,
        y:
          (elem?.getBoundingClientRect().bottom +
            elem?.getBoundingClientRect().top) /
          2,
      };
    else
      return {
        x: 0,
        y: 0,
      };
  }, [elem]);

  return (
    <React.Fragment>
      {!isClassRoomIntroCompleted && isBlendedType && (
        <Dialog
          open={isOpened}
          onClose={handleClose}
          className={classes.dialog}
        >
          <Box display="flex" justifyContent="flex-end" padding={6}>
            <CloseIcon className={classes.bigIcon} onClick={handleClose} />
          </Box>

          <Box marginX={8}>
            <Typography variant="subtitle2" className={classes.title}>
              {t('dialogs.classroom-onboarding.title')}
            </Typography>
          </Box>

          <DialogContent>
            <DialogContentText variant="body2">
              <Box component="span" marginBottom={2}>
                {t('dialogs.classroom-onboarding.content')}
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Box
              width={'100%'}
              display="grid"
              gridRowGap={2}
              flexDirection="column"
              padding={4}
              paddingTop={0}
            >
              <Button
                onClick={() => setOpened(false)}
                color="primary"
                variant="contained"
              >
                {t('dialogs.classroom-onboarding.button')}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}

      {!isOpened && !isClassRoomIntroCompleted && elem && (
        <div className={classes.backDropBox}>
          <svg width="100%" height="100%">
            <mask id="mask">
              <rect fill="white" width="100%" height="100%" fillOpacity="1" />
              <Tooltip
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
                title={
                  <Box display="grid" gridRowGap={4} flexGrow={1}>
                    <Box className={classes.container}>
                      <Typography variant="subtitle2" className={classes.title}>
                        {t(
                          `onboarding.walkthrough-classroom.step-${step}.title`
                        )}
                      </Typography>
                      <CloseIcon
                        className={classes.icon}
                        onClick={handleClose}
                      ></CloseIcon>
                    </Box>
                    <Box marginTop={2} marginBottom={3}>
                      <Typography variant="body2" gutterBottom>
                        {t(
                          `onboarding.walkthrough-classroom.step-${step}.description`
                        )}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        if (step === 4) {
                          handleClose();
                        }
                        setStep(step + 1);
                      }}
                    >
                      {' '}
                      {t(
                        `onboarding.walkthrough-classroom.step-${step}.button-text`
                      )}
                    </Button>
                  </Box>
                }
                interactive
                open={!isCompleted}
                arrow
              >
                <circle
                  cx={getRefElementPosition.x}
                  cy={getRefElementPosition.y}
                  r="50"
                />
              </Tooltip>
            </mask>
            <rect
              mask="url(#mask)"
              fill="#2C3E50"
              width="100%"
              height="100%"
              fillOpacity="0.8"
            />
          </svg>
        </div>
      )}
    </React.Fragment>
  );
};

export default TooltipClassroom;
