import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: 50,
    width: 50,
  },
});

const ProcessingIcon: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 50 50"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <rect width="50" height="50" rx="25" fill="#F2994A" />
      <path
        d="M30.8181 16.5C31.0089 16.5 31.182 16.6851 31.182 16.8872V18.8196C31.182 19.0198 31.0089 19.2049 30.8181 19.2049H30.2671L30.2724 20.1701C30.2724 21.4149 29.6666 22.4594 28.9036 23.3038C28.2801 23.9932 27.5683 24.5353 26.9148 25C27.5683 25.4647 28.2818 26.0011 28.9036 26.6906C29.6666 27.5349 30.2724 28.5851 30.2724 29.8299L30.2671 30.7951H30.8181C31.0089 30.7951 31.182 30.9802 31.182 31.1804V33.1128C31.182 33.3149 31.0089 33.5 30.8181 33.5H19.1822C18.9915 33.5 18.8184 33.3149 18.8184 33.1128V31.1804C18.8184 30.9802 18.9915 30.7951 19.1822 30.7951H19.7333L19.728 29.8299C19.728 28.5851 20.3338 27.5349 21.0968 26.6906C21.7185 26.0011 22.4321 25.4647 23.0856 25C22.4321 24.5353 21.7203 23.9932 21.0968 23.3038C20.3338 22.4594 19.728 21.4149 19.728 20.1701L19.7333 19.2049H19.1822C18.9915 19.2049 18.8184 19.0198 18.8184 18.8196V16.8872C18.8184 16.6964 18.9703 16.5189 19.1822 16.5H30.8181ZM25.0002 25.7727L23.9686 26.4953C23.3204 26.9477 22.647 27.4471 22.0981 28.0449C21.4519 28.749 21.1365 29.3958 21.1365 30.0243L21.1482 31.9545H28.8541L28.858 31.058L28.8638 30.0099C28.8638 29.3958 28.5485 28.749 27.9023 28.0428C27.3534 27.4471 26.6799 26.9477 26.0318 26.4953L25.0002 25.7727ZM28.8541 18.0455H21.1482L21.1365 19.9887C21.1365 20.5989 21.4499 21.2459 22.0981 21.9503C22.6664 22.5687 23.3613 23.0826 23.9666 23.5065L25.0002 24.2273L26.0337 23.5065C26.6391 23.0826 27.3339 22.5687 27.9023 21.9503C28.5504 21.2459 28.8638 20.5989 28.8638 19.9764L28.858 18.9423L28.8541 18.0455Z"
        fill="white"
      />
      <path
        id="cssAnimInfiniteRotating"
        d="M13.7766 37.7688L11.0909 40.4545V34.2727H17.2727L14.8719 36.6736C17.5843 39.0289 21.1257 40.4545 25 40.4545C33.5353 40.4545 40.4545 33.5353 40.4545 25C40.4545 22.7323 39.9661 20.5786 39.0887 18.6385L40.2494 17.4778C41.3702 19.7455 42 22.2992 42 25C42 34.3888 34.3888 42 25 42C20.6988 42 16.7708 40.4027 13.7766 37.7688ZM36.2234 12.2312L38.9091 9.54545V15.7273H32.7273L35.1281 13.3264C32.4157 10.9711 28.8743 9.54545 25 9.54545C16.4647 9.54545 9.54545 16.4647 9.54545 25C9.54545 27.2677 10.0339 29.4214 10.9113 31.3615L9.75055 32.5222C8.6298 30.2544 8 27.7008 8 25C8 15.6112 15.6112 8 25 8C29.3012 8 33.2292 9.59734 36.2234 12.2312Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default ProcessingIcon;
