import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box, IconButton, Paper, Typography } from '@material-ui/core';
import AppButton from '@/components/app-button';
import { RatingField, TextField } from '@/components/form';
import {
  useCreateFeedbackQuestionMutation,
  useEditFeedbackQuestionMutation,
  useGetFeedbackQuestionQuery,
} from '@/graphql';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './styles';
import { isEmpty } from 'lodash';
import { datadogRum } from '@datadog/browser-rum';
import { Skeleton } from '@material-ui/lab';
import FeedbackThankYou from '../feedback-thank-you';

interface FeedbackForQuestionProps {
  questionId: string;
  nextLink: string;
  setFeedbackVisible: (value: boolean) => void;
}

enum FormEntries {
  relevanceRate = 'relevanceRate',
  interestRate = 'interestRate',
  comment = 'comment',
}

interface FormValues {
  [FormEntries.comment]?: string;
  [FormEntries.relevanceRate]: number;
  [FormEntries.interestRate]: number;
}

const FeedbackForQuestion: React.FC<FeedbackForQuestionProps> = ({
  questionId,
  nextLink,
  setFeedbackVisible,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const search = history.location.search;
  const query = new URLSearchParams(search);
  const { register, setError, setValue, errors, getValues, formState } =
    useForm<FormValues>({
      mode: 'onChange',
    });
  const [isThankYouOpened, setThankYouOpened] = useState<boolean>(false);
  const [ratingValue, setRatingValue] = React.useState<Partial<FormValues>>({
    interestRate: 0,
    relevanceRate: 0,
  });

  const [createFeedback, { loading: createLoading }] =
    useCreateFeedbackQuestionMutation();
  const [editFeedback, { loading: editLoading }] =
    useEditFeedbackQuestionMutation();

  const { data, loading } = useGetFeedbackQuestionQuery({
    variables: { questionId },
    onCompleted: (data) => {
      const relRate = data.getFeedbackForQuestion?.relevanceRate;
      const intRate = data.getFeedbackForQuestion?.interestRate;
      register(
        { name: FormEntries.relevanceRate, value: relRate },
        {
          validate: (value: number) => !!value,
          required: true,
        }
      );
      register(
        {
          name: FormEntries.interestRate,
          value: intRate,
        },
        {
          validate: (value: number) => !!value,
          required: true,
        }
      );
      setRatingValue({
        relevanceRate: relRate || 0,
        interestRate: intRate || 0,
      });
    },
  });

  const relevanceRate = useMemo(
    () => data?.getFeedbackForQuestion?.relevanceRate,
    [data]
  );
  const interestRate = useMemo(
    () => data?.getFeedbackForQuestion?.interestRate,
    [data]
  );

  const trackStats = useCallback(
    () =>
      datadogRum.addAction(
        !!data?.getFeedbackForQuestion?.id ? 'FFQ edit' : 'FFQ leave',
        {
          questionId,
          relevanceRate,
          interestRate,
        }
      ),
    [relevanceRate, interestRate, questionId, data]
  );

  const submitFeedback = useCallback(async () => {
    const formValues = getValues();
    for (const [key, value] of Object.entries(formValues)) {
      if (key !== FormEntries.comment && !value) {
        setError(key as keyof FormValues, {
          types: {
            required: true,
          },
          message: t('common.validation.invalid'),
        });
        return;
      }
    }
    const mutationOptions = {
      variables: {
        questionId,
        ...formValues,
      },
    };
    try {
      if (!!data?.getFeedbackForQuestion?.id) {
        await editFeedback(mutationOptions);
      } else {
        await createFeedback(mutationOptions);
      }
      trackStats();
      setThankYouOpened(true);
    } catch (error) {
      setError(FormEntries.relevanceRate, {
        type: 'server',
        message: t('common.validation.invalid'),
      });
    }
  }, [
    createFeedback,
    editFeedback,
    setError,
    t,
    data,
    getValues,
    questionId,
    trackStats,
  ]);

  const handleRatingChange = (val: number | null, name: keyof FormValues) => {
    if (val) {
      setRatingValue((prevState) => ({ ...prevState, [name]: val }));
      setValue(name, val, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  const closeModal = () => {
    query.delete('isFeedbackVisible');
    history.replace({ search: query.toString() });
    setFeedbackVisible(false);
  };

  return (
    <Box className={classes.root} id="animationSlideInBottom">
      {isThankYouOpened ? (
        <FeedbackThankYou
          nextLink={nextLink}
          setFeedbackVisible={setFeedbackVisible}
        />
      ) : (
        <Box className={classes.formWrapper}>
          <Box flexGrow={1}>
            <IconButton
              aria-label="close"
              className={classes.close}
              onClick={closeModal}
              data-dd-action-name="DT close rules dialog"
            >
              <CloseIcon htmlColor="#4A647C" />
            </IconButton>
            <Paper elevation={0}>
              <Box mt={1} py={4} px={6} className={classes.formBlock}>
                <Typography variant="body2">
                  <b>{t('feedback-for-question.relevanceRate.caption')}</b>:{' '}
                  {t('feedback-for-question.relevanceRate.question')}
                </Typography>
                {loading && !data ? (
                  <Skeleton height={42} />
                ) : (
                  <RatingField
                    fullWidth
                    name={FormEntries.relevanceRate}
                    value={ratingValue?.relevanceRate}
                    size="large"
                    errors={errors.relevanceRate}
                    onChange={(e, newValue) =>
                      handleRatingChange(newValue, FormEntries.relevanceRate)
                    }
                    style={{ marginTop: 12 }}
                  />
                )}
              </Box>
            </Paper>
            <Paper elevation={0}>
              <Box mt={2} py={4} px={6} className={classes.formBlock}>
                <Typography variant="body2">
                  <b>{t('feedback-for-question.interestRate.caption')}</b>:{' '}
                  {t('feedback-for-question.interestRate.question')}
                </Typography>
                {loading && !data ? (
                  <Skeleton height={42} />
                ) : (
                  <RatingField
                    fullWidth
                    name={FormEntries.interestRate}
                    value={ratingValue?.interestRate}
                    size="large"
                    errors={errors.interestRate}
                    onChange={(e, newValue) =>
                      handleRatingChange(newValue, FormEntries.interestRate)
                    }
                    style={{ marginTop: 12 }}
                  />
                )}
              </Box>
            </Paper>
            <Paper elevation={0}>
              <Box mt={2} py={4} px={6} className={classes.formBlock}>
                {loading && !data ? (
                  <Skeleton height={114} />
                ) : (
                  <TextField
                    fullWidth
                    variant="outlined"
                    name={FormEntries.comment}
                    placeholder={t(
                      'feedback-for-question.textarea-placeholder'
                    )}
                    multiline
                    rows={6}
                    rowsMax={12}
                    inputRef={register()}
                    errors={errors.comment}
                  />
                )}
              </Box>
            </Paper>
          </Box>
          <Box marginTop={4}>
            <AppButton
              fullWidth
              data-cy-forgot-btn
              color="primary"
              variant="contained"
              visuallyDisabled={!formState.isValid}
              disabled={!isEmpty(errors) || !formState.isDirty}
              loading={createLoading || editLoading}
              onClick={submitFeedback}
            >
              {t('feedback-for-question.btn-cta')}
            </AppButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FeedbackForQuestion;
