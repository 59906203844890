import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Switch,
  Typography,
} from '@material-ui/core';
import { RadioField } from '@/components/form';
import { USER_SETTINGS_FRAGMENT } from '@/apollo/fragments';
import {
  SettingsGoal,
  DailyTipperDataDocument,
  useUpdateDtSettingsMutation,
  useUserMeQuery,
  DtWeeklyProgressDocument,
} from '@/graphql';
import { SettingsField } from '@/type';
import { MAX_DT_WEEKLY_GOAL } from '@/utils/constants';
import { useStyles } from '../styles';

const DailyTipperSettings: React.FC = () => {
  const classes = useStyles();
  const client = useApolloClient();
  const { t } = useTranslation();
  const { data } = useUserMeQuery({
    fetchPolicy: 'cache-first',
  });

  const [isOpen, setIsOpen] = useState(false);
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const [update] = useUpdateDtSettingsMutation();

  const changeField = useCallback(
    (checked, field: SettingsField) => {
      const dailyTipperMode =
        field === SettingsField.mode
          ? checked
          : !!data?.me?.settings?.dailyTipperMode;
      const goal =
        field === SettingsField.goal
          ? Number(checked.replace(`A_`, ''))
          : data?.me?.settings?.goal
          ? Number(data?.me?.settings?.goal.replace(`A_`, ''))
          : MAX_DT_WEEKLY_GOAL;
      const goalOptimisticRes =
        field === SettingsField.goal
          ? checked
          : data?.me?.settings?.goal ?? SettingsGoal.A_7;

      client.writeFragment({
        id: `${data?.me?.settings?.__typename}:${data?.me?.settings?.id}`,
        fragment: USER_SETTINGS_FRAGMENT,
        data: {
          ...data?.me?.settings,
          dailyTipperMode,
          goal: goalOptimisticRes,
        },
      });

      try {
        update({
          variables: {
            mode: dailyTipperMode,
            goal,
          },
          refetchQueries: [
            { query: DailyTipperDataDocument },
            { query: DtWeeklyProgressDocument },
          ],
        });
      } catch (err) {
        console.error('useUpdateDtSettingsMutation', err);
      }
    },
    [update, client, data]
  );

  const changeMode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) changeField(checked, SettingsField.mode);
      if (!checked) setIsOpen(true);
    },
    [changeField]
  );

  const handleConfirm = useCallback(() => {
    changeField(false, SettingsField.mode);
    handleClose();
  }, [handleClose, changeField]);

  const goalOptions = useMemo(
    () => [
      {
        label: '4',
        value: SettingsGoal.A_4,
      },
      {
        label: '5',
        value: SettingsGoal.A_5,
      },
      {
        label: '6',
        value: SettingsGoal.A_6,
      },
      {
        label: '7',
        value: SettingsGoal.A_7,
      },
    ],
    []
  );

  return (
    <>
      <Box data-cy-duel-settings>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">{t('settings.dt.title')}</Typography>
          <Switch
            color="primary"
            checked={!!data?.me?.settings?.dailyTipperMode}
            onChange={changeMode}
          />
        </Box>
        <Typography
          variant="caption"
          component="p"
          className={classes.helpText}
        >
          {t('settings.dt.help-text')}
        </Typography>
        <Box mt={6}>
          <Typography variant="subtitle2">
            {t('settings.dt.goal-question')}
          </Typography>
          <Box mt={2}>
            <Typography variant="caption" style={{ color: '#647485' }}>
              {t('settings.dt.goal-info')}
            </Typography>
          </Box>
        </Box>
        <Box paddingTop={3}>
          <RadioField
            value={data?.me?.settings?.goal || null}
            options={goalOptions}
            onChange={(e, checked) => changeField(checked, SettingsField.goal)}
          />
        </Box>
        <Box mt={4} mb={4}>
          <Divider />
        </Box>
      </Box>

      <Dialog data-cy-welcome-dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>{t('dialogs.settings-dt.title')}</DialogTitle>
        <DialogContent>
          <Box marginBottom={5}>
            <Typography variant="body2">
              {t('dialogs.settings-dt.content')}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            data-cy-later-btn
            onClick={handleClose}
            style={{ color: '#647485' }}
          >
            {t('common.abort')}
          </Button>
          <Button
            data-cy-enter-code-btn
            onClick={handleConfirm}
            color="primary"
          >
            {t('common.pause2')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DailyTipperSettings;
