import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Paper, Typography, Button } from '@material-ui/core';
import StackLayout from '../../components/stack-layout';
import { useUser } from '@/contexts/user-context';
import MastercardIcon from '@/components/icons/mastercard';
import DialogChangePaymentMethod from '../dialog-change-payment-method';

const PaymentMethod: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [cancelSubsVisible, setCancelSubsVisible] = useState(false);

  return (
    <StackLayout title={t('payment.method')}>
      <Box display="grid" height="100%">
        <Paper>
          {user?.customer?.defaultCard?.last4 && (
            <Box px={4} py={5}>
              <Link
                to="/my-profile/payment-method-details"
                style={{ textDecoration: 'none' }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  paddingBottom={5}
                  borderBottom="1px solid #E6EEFA"
                >
                  <MastercardIcon style={{ marginRight: 16 }} />
                  <Typography variant="body1">
                    {user?.customer?.defaultCard?.brand} —{' '}
                    {user?.customer?.defaultCard?.last4}
                  </Typography>
                </Box>
              </Link>
              <Box mt={5} ml={-1} mb={5}>
                <Button
                  color="primary"
                  onClick={() => setCancelSubsVisible(true)}
                >
                  {t('payment.change-card')}
                </Button>
              </Box>
            </Box>
          )}
        </Paper>
      </Box>
      <DialogChangePaymentMethod
        onClose={setCancelSubsVisible}
        isOpened={cancelSubsVisible}
      />
    </StackLayout>
  );
};

export default PaymentMethod;
