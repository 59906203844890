import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(7),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  button: {
    width: '100%',
  },
  close: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    position: 'absolute',
  },
  closeFullscreen: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    position: 'absolute',
  },
  missedElipse: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 120,
    width: 120,
    borderRadius: '50%',
    background: '#E6EEFA',
  },
  missedText: {
    fontWeight: 'bold',
    fontSize: 40,
    lineHeight: 40,
    letterSpacing: 0.15,
  },
}));
