import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  my: {
    background: `rgba(226, 245, 253, 0.48)`,
    boxShadow: `inset 0px -1px 0px #E6EEFA`,
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: `calc(100% - ${theme.spacing(8)}px)`,
  },
  myLabel: {
    color: theme.palette.primary.dark,
  },
}));
