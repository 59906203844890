import React from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import { InfoOutlined } from '@material-ui/icons';
import { Box, IconButton, Paper } from '@material-ui/core';
import QuizTimeHeader from '../header';
import QuizTimeDescription from '../description';
import StackLayout from '../../components/stack-layout';
import QuizTimePrizes from '../prizes';
import AppButton from '@/components/app-button';
import EmptyState from '@/screens/deep-links/empty-state';
import { useQuizTimeDetailsQuery } from '@/graphql';
import { useStyles } from './styles';

type QuizTimeParams = {
  quizSessionId: string;
  hash: string;
};

const PublicQuizTimeDetails: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const { quizSessionId, hash } = useParams<QuizTimeParams>();

  const { data, loading, error } = useQuizTimeDetailsQuery({
    variables: { quizSessionId },
  });

  const organizer = data?.quizTimeSession?.quizTime?.organizer;
  const isForPremiumOnly = data?.quizTimeSession?.isForPremiumOnly;
  const isUseCode = data?.quizTimeSession?.isUseCode;
  const maxCapacity = data?.quizTimeSession?.maxUserCapacity
    ? data?.quizTimeSession?.maxUserCapacity
    : null;
  const numberOfMembers = data?.quizTimeSession?.usersCount;

  const startDate = data?.quizTimeSession?.startDate;
  const startDateTime = data?.quizTimeSession?.startDateTime;
  const quizStatus = data?.quizTimeSession?.status;
  const finishDateTime = data?.quizTimeSession?.finishDateTime;
  const sponsor = data?.quizTimeSession?.quizTime?.sponsor;

  const joinQuizTime = () => {
    history.push(`/auth/?hash=${hash}`);
  };

  if (error?.message) return <EmptyState isQuiztime />;

  return (
    <StackLayout
      title={t('quiztime.title')}
      toolbar={
        <IconButton
          color="primary"
          component={RouterLink}
          size="small"
          to={`/quiztime-rules/${quizSessionId}`}
        >
          <InfoOutlined htmlColor="#fff" />
        </IconButton>
      }
      back={`/auth/?hash=${hash}`}
    >
      {!loading && (
        <QuizTimeHeader
          quizSessionId={quizSessionId}
          quizStatus={quizStatus}
          image={data?.quizTimeSession?.quizTime?.quizTimeImage}
          startDate={startDate}
          startDateTime={startDateTime}
          finishDateTime={finishDateTime}
          title={data?.quizTimeSession?.quizTime?.title}
          organizerName={organizer?.name}
          isForPremiumOnly={isForPremiumOnly}
          duration={data?.quizTimeSession?.quizTimeDuration}
          maxCount={maxCapacity}
          numberOfMembers={numberOfMembers}
        />
      )}
      <div className={classes.content}>
        {loading ? (
          <Paper>
            <Box py={5} px={6}>
              {[...Array(15)].map((i, idx) => (
                <Skeleton key={idx} height={30} />
              ))}
            </Box>
          </Paper>
        ) : (
          <>
            <QuizTimeDescription
              description={data?.quizTimeSession?.quizTime?.description}
              orgId={organizer?.id}
              orgName={organizer?.name}
              orgEmail={organizer?.email}
              orgLogo={organizer?.logo}
              orgWebsite={organizer?.linkToSite}
              isUseCode={isUseCode}
              sponsor={sponsor}
            >
              <AppButton
                color="primary"
                variant="contained"
                onClick={joinQuizTime}
                className={classes.joinBtnSnackfalse}
              >
                {t('quiztime.btn-join')}
              </AppButton>
            </QuizTimeDescription>
            <QuizTimePrizes quizSessionId={quizSessionId} />
          </>
        )}
      </div>
    </StackLayout>
  );
};

export default PublicQuizTimeDetails;
