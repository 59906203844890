import React, { useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import { PauseCircleOutline, PlayCircleOutline } from '@material-ui/icons';
import { useSnackbarContext } from '@/contexts/snackbar-context';
import {
  ClassRoomsOngoingDocument,
  useResumeBlendedClassroomMutation,
  usePauseBlendedClassroomMutation,
} from '@/graphql';

type TogglerOfBlendedProps = {
  indicator?: boolean | null | undefined;
  userSessionSettingsId?: string | null | undefined;
};

const TogglerOfBlended: React.FC<TogglerOfBlendedProps> = ({
  indicator,
  userSessionSettingsId,
}) => {
  const { setCustomSnack } = useSnackbarContext();
  const [resumeBlendedClassroom] = useResumeBlendedClassroomMutation();
  const [pauseBlendedClassroom] = usePauseBlendedClassroomMutation();

  const pauseQuiz = useCallback(async () => {
    try {
      if (indicator) {
        await pauseBlendedClassroom({
          variables: {
            userSessionSettingsId,
          },
          refetchQueries: [
            {
              query: ClassRoomsOngoingDocument,
            },
          ],
        });
        setCustomSnack({
          visibility: true,
          message: 'classrooms.snackBars.message-cr-blended-paused',
        });
      } else {
        await resumeBlendedClassroom({
          variables: {
            userSessionSettingsId,
          },
          refetchQueries: [
            {
              query: ClassRoomsOngoingDocument,
            },
          ],
        });
        setCustomSnack({
          visibility: true,
          message: 'classrooms.snackBars.message-cr-blended-started',
        });
      }
    } catch (err) {
      console.error('useResumeBlendedClassroomMutation', err);
    }
  }, [
    indicator,
    userSessionSettingsId,
    setCustomSnack,
    pauseBlendedClassroom,
    resumeBlendedClassroom,
  ]);

  return (
    <IconButton onClick={pauseQuiz} size="small" style={{ color: '#fff' }}>
      {indicator ? <PauseCircleOutline /> : <PlayCircleOutline />}
    </IconButton>
  );
};

export default TogglerOfBlended;
