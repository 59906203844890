import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    fontSize: 'inherit',
    verticalAlign: 'middle',
  },
});

const AlarmIcon: React.FC<SvgIconProps> = ({
  className,
  htmlColor,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 12 12"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.9722 1.72048L3.3316 0.952576L1.02795 2.87438L1.6685 3.64228L3.9722 1.72048ZM8.66822 0.952878L10.9719 2.87468L10.3313 3.64258L8.02762 1.72078L8.66822 0.952878ZM6.24991 4.04737H5.49991V7.04737L7.87491 8.47237L8.24991 7.85737L6.24991 6.67237V4.04737ZM5.99991 2.04737C3.51491 2.04737 1.49991 4.06237 1.49991 6.54737C1.49991 9.03237 3.51491 11.0474 5.99991 11.0474C8.48491 11.0474 10.4999 9.03237 10.4999 6.54737C10.4999 4.06237 8.48491 2.04737 5.99991 2.04737ZM2.49991 6.54737C2.49991 8.47737 4.06991 10.0474 5.99991 10.0474C7.92991 10.0474 9.49991 8.47737 9.49991 6.54737C9.49991 4.61737 7.92991 3.04737 5.99991 3.04737C4.06991 3.04737 2.49991 4.61737 2.49991 6.54737Z"
        fill={htmlColor}
      />
    </SvgIcon>
  );
};

export default AlarmIcon;
