import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {},
});

type HexahedronIcon = SvgIconProps & {
  colorPrimary: string;
  colorSecondary: string;
};

const HexahedronIcon: React.FC<HexahedronIcon> = ({
  colorPrimary,
  colorSecondary,
  className,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <SvgIcon
      className={clsx(classes.root, className)}
      {...rest}
      viewBox="0 0 29 34"
      fill="none"
    >
      <path
        d="M1.72549 7.98888C0.965832 8.43905 0.5 9.25658 0.5 10.1396V23.8604C0.5 24.7434 0.965832 25.561 1.72549 26.0111L13.2255 32.8259C14.0114 33.2916 14.9886 33.2916 15.7745 32.8259L27.2745 26.0111C28.0342 25.561 28.5 24.7434 28.5 23.8604V10.1396C28.5 9.25658 28.0342 8.43905 27.2745 7.98888L15.7745 1.17406C14.9886 0.70837 14.0114 0.70837 13.2255 1.17406L1.72549 7.98888Z"
        fill={colorSecondary}
        stroke="white"
      />
      <path
        d="M4 11.6141C4 10.9215 4.35837 10.2782 4.94729 9.91358L13.4473 4.65168C14.0923 4.25238 14.9077 4.25238 15.5527 4.65168L24.0527 9.91358C24.6416 10.2782 25 10.9215 25 11.6141V22.3859C25 23.0785 24.6416 23.7218 24.0527 24.0864L15.5527 29.3483C14.9077 29.7476 14.0923 29.7476 13.4473 29.3483L4.94729 24.0864C4.35837 23.7218 4 23.0785 4 22.3859V11.6141Z"
        fill={colorPrimary}
      />
    </SvgIcon>
  );
};

export default HexahedronIcon;
