import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useNavigationListener = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((newLocation, action) => {
      if (action === 'POP') {
        const { pathname } = history.location;

        // Example condition to prevent back navigation
        if (
          pathname.includes('duel/') &&
          !pathname.includes('/create-round') &&
          !pathname.includes('/overview')
        ) {
          history.go(1);
        }

        // Redirect away from certain pages
        if (
          newLocation.pathname.includes('sign-in') ||
          newLocation.pathname.includes('sign-up')
        ) {
          history.replace('/home');
        }
      }
    });

    // Cleanup on component unmount
    return () => {
      unlisten();
    };
  }, [history]);
};
