import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Paper,
  Typography,
  IconButton,
  Dialog,
} from '@material-ui/core';
import Lottie from 'lottie-web';
import { flatten } from 'lottie-colorify';

import CloseIcon from '@material-ui/icons/Close';
import { PermIdentityOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

import { UserStatus } from '@/type';
import { DuelResultUserDataType, DuelStatus } from '@/graphql';
import theme from '@/theme';
import CoolIconJson from '@/components/gifs/cool.json';
import ConfusedIconJson from '@/components/gifs/confused.json';
import DisappointedIconJson from '@/components/gifs/disappointed.json';
import HandshakeIconJson from '@/components/gifs/handshake.json';
import OverviewRoundsList from '../overview-rounds-list';
import { ANIM_ICON_CONTAINER_CSS_ID } from '@/utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    overflowY: 'auto',
  },
  close: {
    top: 0,
    left: 0,
    padding: 0,
    alignSelf: 'start',
  },
  content: {
    border: '1px solid #B6E4FA',
    borderRadius: 4,
    backgroundColor: '#E2F5FD',
  },
  username: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#4A647C',
  },
  iconContainer: {
    '& svg': { width: '56px!important', height: '56px!important' },
  },
}));

interface ResultDialogProps {
  userScore: DuelResultUserDataType;
  opponentScore: DuelResultUserDataType;
  status: DuelStatus;
  maxRoundsCount?: number;
  duelId?: string;
  onClose?: () => void;
}

const ResultDialog: React.FC<ResultDialogProps> = ({
  userScore,
  opponentScore,
  status,
  maxRoundsCount,
  duelId,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [iconElemRef, setIconElemRef] = useState<HTMLDivElement | null>(null);

  const isFinished = useMemo(() => status === DuelStatus.Finished, [status]);
  const isIgnored = useMemo(() => status === DuelStatus.Ignored, [status]);

  const data = useMemo(() => {
    if (isFinished && userScore?.userStatus.valueOf() === UserStatus.win)
      return {
        title: t('duel.win'),
        info: 'duel.win-info',
        color: theme.palette.success.main,
        userColor: theme.palette.success.main,
        opponentColor: '',
        iconJson: CoolIconJson,
        iconColor: '#27AE60',
        iconDataAttribute: 'data-cy-result-dialog-win',
      };

    if (isFinished && userScore?.userStatus.valueOf() === UserStatus.loss)
      return {
        title: t('duel.lost'),
        info: 'duel.lost-info',
        color: theme.palette.error.main,
        userColor: '',
        opponentColor: theme.palette.success.main,
        iconJson: DisappointedIconJson,
        iconColor: '#EB5757',
        iconDataAttribute: 'data-cy-result-dialog-lost',
      };

    if (isFinished && userScore?.userStatus.valueOf() === UserStatus.tie)
      return {
        title: t('duel.tie'),
        info: 'duel.tie-info',
        color: theme.palette.warning.main,
        userColor: theme.palette.warning.main,
        opponentColor: theme.palette.warning.main,
        iconJson: HandshakeIconJson,
        iconColor: '#F2994A',
        iconDataAttribute: 'data-cy-result-dialog-tie',
      };

    if (
      isIgnored &&
      userScore?.userStatus.valueOf() === UserStatus.unfinished &&
      opponentScore?.userStatus.valueOf() === UserStatus.unfinished
    )
      return {
        title: t('duel.confused.unfinished'),
        info: 'duel.confused-info.unfinished',
        color: theme.palette.primary.dark,
        userColor: theme.palette.error.main,
        opponentColor: theme.palette.error.main,
        iconJson: ConfusedIconJson,
        iconColor: '#2B75B3',
        iconDataAttribute: 'data-cy-result-dialog-remis',
      };

    if (isIgnored && userScore?.userStatus.valueOf() === UserStatus.win)
      return {
        title: t('duel.confused.win'),
        info: 'duel.confused-info.win',
        color: theme.palette.primary.dark,
        userColor: '',
        opponentColor: theme.palette.error.main,
        iconJson: ConfusedIconJson,
        iconColor: '#2B75B3',
        iconDataAttribute: 'data-cy-result-dialog-remis',
      };

    if (isIgnored && opponentScore?.userStatus.valueOf() === UserStatus.win)
      return {
        title: t('duel.confused.loss'),
        info: 'duel.confused-info.loss',
        color: theme.palette.primary.dark,
        userColor: theme.palette.error.main,
        opponentColor: '',
        iconJson: ConfusedIconJson,
        iconColor: '#2B75B3',
        iconDataAttribute: 'data-cy-result-dialog-remis',
      };
  }, [isFinished, isIgnored, opponentScore, t, userScore]);

  useEffect(() => {
    const elem = iconElemRef?.querySelector(`#${ANIM_ICON_CONTAINER_CSS_ID}`);
    if (data?.iconJson && elem) {
      elem.setAttribute(data.iconDataAttribute, '');
      elem.innerHTML = '';
      Lottie.loadAnimation({
        container: elem,
        animationData: flatten(data.iconColor, data?.iconJson),
      });
    }
  }, [data, iconElemRef]);

  return (
    <Dialog
      data-cy-result-dialog
      innerRef={(el) => {
        setIconElemRef(el);
      }}
      open={true}
      fullScreen
      onClose={onClose}
    >
      <Box
        display="flex"
        flexDirection="column"
        px={2}
        pt={4}
        pb={2}
        className={classes.root}
      >
        <IconButton
          aria-label="close"
          className={classes.close}
          style={{ color: '#fff' }}
          onClick={(e) => {
            e.stopPropagation();
            onClose && onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box clone mt={4} pt={7} pb={4} px={2} flexGrow={1}>
          <Paper elevation={0}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              color={theme.palette.success.main}
              mb={5}
            >
              <Box textAlign="center" marginBottom={1} color={data?.color}>
                <span
                  className={classes.iconContainer}
                  id={ANIM_ICON_CONTAINER_CSS_ID}
                />
                <Typography color="inherit" variant="h2">
                  {data?.title}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: t(data?.info || '', {
                    interpolation: { escapeValue: false },
                  }),
                }}
                style={{ color: '#4A647C' }}
              />
            </Box>
            <Box
              display="grid"
              gridTemplateColumns="1fr auto 1fr"
              paddingY={5}
              className={classes.content}
            >
              <Box overflow="hidden" paddingX={6}>
                <Box
                  display="flex"
                  justifyContent="center"
                  marginBottom={1}
                  data-cy-user
                >
                  <Avatar
                    src={userScore?.user?.avatar ?? undefined}
                    style={{
                      borderWidth: data?.userColor ? 3 : 1,
                      borderColor: data?.userColor,
                      backgroundColor: '#fff',
                      boxShadow:
                        data?.userColor && '3px 3px 9px rgba(44, 62, 80, 0.48)',
                      fontSize: '1.75rem',
                    }}
                  >
                    {!userScore?.user?.avatar && (
                      <PermIdentityOutlined fontSize="inherit" />
                    )}
                  </Avatar>
                </Box>
                <Typography
                  align="center"
                  variant="subtitle2"
                  className={classes.username}
                >
                  {t('common.me')}
                </Typography>
              </Box>
              <Box paddingY={3}>
                <Typography noWrap variant="h1">
                  {isFinished &&
                    `${userScore?.duelScore} : ${opponentScore?.duelScore}`}
                  {isIgnored && 'vs'}
                </Typography>
              </Box>
              <Box overflow="hidden" paddingX={6}>
                <Box
                  display="flex"
                  justifyContent="center"
                  marginBottom={1}
                  data-cy-opponent
                >
                  <Avatar
                    src={opponentScore?.user?.avatar ?? undefined}
                    style={{
                      borderWidth: data?.opponentColor ? 3 : 1,
                      borderColor: data?.opponentColor,
                      backgroundColor: '#fff',
                      boxShadow:
                        data?.opponentColor &&
                        '3px 3px 9px rgba(44, 62, 80, 0.48)',
                      fontSize: '1.75rem',
                    }}
                  >
                    {!opponentScore?.user?.avatar && (
                      <PermIdentityOutlined fontSize="inherit" />
                    )}
                  </Avatar>
                </Box>
                <Typography
                  align="center"
                  variant="subtitle2"
                  className={classes.username}
                >
                  {opponentScore?.user?.username}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              mt={1}
              py={2}
              style={{ borderRadius: 4, backgroundColor: '#E2F5FD' }}
            >
              <Typography variant="overline">
                {t('duel.rounds-results')}
              </Typography>
            </Box>
            <OverviewRoundsList
              maxRoundsCount={maxRoundsCount || 3}
              duelIdOut={duelId}
            />
          </Paper>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ResultDialog;
