/* eslint-disable react/display-name */
import React from 'react';

import { useSignUp } from '@/contexts/sign-up-context';

import Doctor from './doctor';
import MedicalAssistant from './medical-assistant';
import Student from './student';
import Traine from './trainee';
import MedicalAssistantTraine from './medical-assistant-teaine';
import Another from './another';

interface ProfessionInfoProps {
  onBack: () => void;
  onNext: () => void;
}

const ProfessionInfo: React.FC<ProfessionInfoProps> = (props) => {
  const { professionType } = useSignUp();

  const Comp = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Variants: Record<number, React.FC<any>> = {
      // Facharzt/Fachärztin
      // Arzt/Ärztin in Weiterbildung
      1: Doctor,
      // Medizinische/r Fachangestellte/r (MFA)
      2: MedicalAssistant,
      // Auszubildende/r zur/zum Pflegefachfrau/-mann
      3: Traine,
      // Ausbildung zur Medizinischen Fachangestellten (MFA)
      // Auszubildende/r zur/zum Medizinischen Fachangestellten
      4: MedicalAssistantTraine,
      // Medizinstudent(in)
      5: Student,
      // Anderes Berufsfeld
      6: Another,
    };

    return Variants[professionType || 7];
  }, [professionType]);

  return <Comp {...props} />;
};

export default ProfessionInfo;
