import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

type DialogChangePaymentMethodProps = {
  isOpened: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
};

const DialogChangePaymentMethod: React.FC<DialogChangePaymentMethodProps> = ({
  onClose,
  isOpened,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Dialog open={isOpened} onClose={() => onClose(false)}>
      <DialogTitle>{t('payment.change-card')}</DialogTitle>
      <DialogContent>
        <DialogContentText variant="body2">
          {t('payment.change-card-content')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box component="span" style={{ color: '#647485' }}>
          <Button color="inherit" onClick={() => onClose(false)}>
            {t('common.abort')}
          </Button>
        </Box>
        <Button
          onClick={() => history.push('/my-profile/change-payment-method')}
          color="primary"
        >
          {t('common.change')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogChangePaymentMethod;
