import React from 'react';
import { IconButton } from '@material-ui/core';
import { ShareOutlined } from '@material-ui/icons';
import { shareLink } from '@/utils/helpers';
import { useSnackbarContext } from '@/contexts/snackbar-context';
import { datadogRum } from '@datadog/browser-rum';

type SharingButtonProps = {
  deepLinkHash?: string | null | undefined;
  crTitle?: string;
  sessionId?: string | null;
};

const SharingButton: React.FC<SharingButtonProps> = ({
  deepLinkHash,
  crTitle,
  sessionId,
}) => {
  const { setCustomSnack } = useSnackbarContext();

  function showSnackbar() {
    setCustomSnack({
      visibility: true,
      message: 'sharing.link-copied',
    });
  }
  const handleOnClick = (event: any) => {
    event.preventDefault();
    datadogRum.addAction('classroom sharing', {
      deepLinkHash,
      classroomTitle: crTitle,
      sessionId,
    });
    shareLink({
      url: `${window.location.origin}/goto/${deepLinkHash}`,
      title: 'Humeo',
      text: `Hi! Ich lerne gerade mit Humeo das Thema ${crTitle}. Hast du Lust mit mir dazu zu quizzen? Dann klicke auf den Link! Ich freue mich!`,
      callback: showSnackbar,
    });
  };

  return (
    <IconButton
      onClick={handleOnClick}
      style={{ padding: 0, color: 'inherit' }}
    >
      <ShareOutlined />
    </IconButton>
  );
};

export default SharingButton;
