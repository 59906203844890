import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

if (window?.ReactNativeWebView) {
  serviceWorker.unregister();
} else {
  serviceWorker.register({
    onUpdate: (registration: ServiceWorkerRegistration) => {
      const waitingServiceWorker = registration.waiting;

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener('statechange', (event) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((event.target as any).state === 'activated') {
            window.location.reload();
          }
        });
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    },
  });
}
