import React from 'react';
import { FieldError } from 'react-hook-form';

import CheckIcon from './../../icons/check';

import {
  Box,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  SelectProps as MuiSelectProps,
  Typography,
} from '@material-ui/core';

import Error from '../error';

interface ISelectOptionsProps {
  label: string;
  value: string;
}

interface SelectProps extends Omit<MuiSelectProps, 'error'> {
  errors?: FieldError;
  options: ISelectOptionsProps[];
}

const useStyles = makeStyles((theme) => ({
  menu: {
    maxHeight: theme.spacing(64),
  },
  select: {
    backgroundColor: `${theme.palette.common.white}!important`,
  },
  selected: {
    backgroundColor: `${theme.palette.common.white}!important`,
    color: theme.palette.primary.main,
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

const SelectField: React.FC<SelectProps> = ({
  label,
  name,
  value,
  fullWidth,
  variant,
  options,
  errors = null,
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const openCloseHandler = React.useCallback(
    () => setIsOpen((prevState) => !prevState),
    [setIsOpen]
  );

  const classes = useStyles();

  const renderOptions = options.map((item) => (
    <MenuItem
      color={item.value === value ? 'primary.main' : undefined}
      classes={{
        selected: classes.selected,
      }}
      value={item.value}
      key={item.value}
    >
      {isOpen ? (
        <Box display="grid" gridTemplateColumns="1fr auto" width="100%">
          <Typography color="inherit" className={classes.text}>
            {item.label}
          </Typography>
          {item.value === value ? <CheckIcon color="inherit" /> : null}
        </Box>
      ) : (
        item.label
      )}
    </MenuItem>
  ));

  return (
    <FormControl variant={variant} fullWidth={fullWidth} error={!!errors}>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        fullWidth={fullWidth}
        name={name}
        label={label}
        variant={variant}
        value={value}
        onOpen={openCloseHandler}
        onClose={openCloseHandler}
        classes={{ select: classes.select }}
        MenuProps={{
          classes: { paper: classes.menu },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        {...rest}
      >
        {renderOptions}
      </Select>
      <Error errors={errors} />
    </FormControl>
  );
};

export default SelectField;
