import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '50%',
    border: `1px solid rgba(0, 0, 0, 0.07)`,
    boxSizing: `border-box`,
  },
  medium: {
    width: 12,
    height: 12,
  },
  large: {
    width: 16,
    height: 16,
  },
  default: {
    backgroundColor: '#E6EEFA',
  },
  success: {
    backgroundColor: 'rgba(39, 174, 96, 0.8)',
  },
  error: {
    backgroundColor: 'rgba(235, 87, 87, 0.8)',
  },
  highlighted: {
    borderColor: '#879BB2!important',
    backgroundColor: '#E6EEFA',
  },
}));
