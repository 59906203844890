import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useGetDeepLinkMutation, useUserMeQuery } from '@/graphql';
import { DeepLinkTargetType } from '@/type';
import LinkNotValid from '../../main/components/link-not-valid';
import { useAuth } from '@/contexts/auth-context';

type GoToParams = {
  hash: string;
};

const GoTo: React.FC = () => {
  const history = useHistory();
  const { hash } = useParams<GoToParams>();
  const { isAuthorized } = useAuth();
  const {
    data: dataMe,
    loading: loadingMe,
    refetch: refetchMe,
  } = useUserMeQuery();
  const [getDeepLink, { data, error }] = useGetDeepLinkMutation();

  useEffect(() => {
    const load = async () => {
      const search = history.location.search;
      const query = new URLSearchParams(search);
      const source = query.get('source');
      try {
        const res = await getDeepLink({
          variables: {
            hash,
            source,
          },
        });
        const deepLink = res.data?.getDeepLink?.deepLink;
        if (deepLink?.targetObjectId && deepLink?.targetType) {
          history.push(
            `/${isAuthorized ? '' : 'public-'}${
              deepLink.targetType === DeepLinkTargetType.QUIZTIME
                ? 'quiztime-details'
                : 'classroom-details'
            }/${deepLink.targetObjectId}${
              isAuthorized ? '/?hash=' + hash : '/' + hash
            }`,
            { rootPageForBack: '/home' }
          );
        }
      } catch {}
    };
    load();
  }, [getDeepLink, hash, history, isAuthorized]);

  useEffect(() => {
    if (isAuthorized && !!dataMe && !loadingMe) refetchMe();
  }, [dataMe, refetchMe, loadingMe, isAuthorized]);

  const exploreLink = !isAuthorized
    ? `/auth/?hash=${hash}`
    : data?.getDeepLink?.deepLink?.targetType === DeepLinkTargetType.QUIZTIME
    ? '/quiz-list'
    : '/biblio/';

  return <>{error?.message && <LinkNotValid exploreLink={exploreLink} />}</>;
};

export default GoTo;
