import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  link: {
    color: '#2B75B3',
    fontWeight: 400,
    position: 'relative',
    textDecoration: 'none',
    textTransform: 'initial',
    padding: '2px 0',
    '&:after': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      content: "''",
      display: 'inline-flex',
      height: 1,
      width: '100%',
      backgroundColor: '#2B75B3',
    },
  },
}));
