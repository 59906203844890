import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  discountBox: {
    marginTop: 8,
    padding: 20,
    borderRadius: 4,
    backgroundColor: '#2C3E50',
    color: '#fff',
    marginLeft: '-8px',
    marginRight: '-8px',
  },
  discountBoxHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 12,
    borderBottom: '1px solid #879BB2',
  },
  priceTitle: {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '0.18px',
  },
}));
