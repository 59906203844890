import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useApolloClient } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';

import { Box } from '@material-ui/core';
import { checkNameIsValid } from '@/utils/helpers';
import isPostalCode from 'validator/lib/isPostalCode';

import * as Form from '@/components/form';
import { useSignUp } from '@/contexts/sign-up-context';
import { USERNAME_VERIFY } from '@/apollo/queries';
import { UsernameVerifyQuery, UsernameVerifyQueryVariables } from '@/graphql';
import { UserGender } from '@/type';

import PublicLayout from '../../components/layout';
import StepNavigate from '../../components/step-navigation';
import {
  MAX_REG_AGE,
  MIN_REG_AGE,
  POSTAL_CODE_GERMANY,
} from '@/utils/constants';

interface PersonalInfoProps {
  onBack: () => void;
  onNext: () => void;
}

interface Form {
  firstName: string;
  lastName: string;
  username: string;
  gender: string;
  dateOfBirth: string;
  postcode: string;
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({ onNext, onBack }) => {
  const client = useApolloClient();
  const { t } = useTranslation();
  const {
    firstName,
    lastName,
    username,
    dateOfBirth,
    postcode,
    gender,
    update,
  } = useSignUp();

  const { register, errors, setValue, watch, setError } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      firstName: firstName || '',
      lastName: lastName || '',
      username: username || '',
      dateOfBirth: dateOfBirth || '',
      postcode: postcode || '',
      gender: String(gender),
    },
  });

  const changeField = useCallback(
    (prop: keyof Form) => (e: React.ChangeEvent<{ value: unknown }>) => {
      const value = String(e.target.value).trim();
      setValue(prop, value, {
        shouldDirty: true,
        shouldValidate: true,
      });

      switch (prop) {
        case 'firstName':
          return update({ firstName: value });
        case 'lastName':
          return update({ lastName: value });
        case 'username':
          return update({ username: value });
        case 'postcode':
          return update({ postcode: value });
        case 'gender':
          return update({ gender: Number(value) });
      }
    },
    [setValue, update]
  );

  const validation = useMemo(
    () => ({
      firstName: {
        validate: (value: string) =>
          !checkNameIsValid(value)
            ? (t('fields.first-name.validation.regex') as string)
            : undefined,
      },
      lastName: {
        validate: (value: string) =>
          !checkNameIsValid(value)
            ? (t('fields.last-name.validation.regex') as string)
            : undefined,
      },
      username: {
        validate: async (username: string) => {
          if (!username) {
            return undefined;
          }

          const { data, errors } = await client.query<
            UsernameVerifyQuery,
            UsernameVerifyQueryVariables
          >({
            query: USERNAME_VERIFY,
            variables: { username },
          });

          if (errors) {
            return errors.join('; ');
          }

          if (data && data.usernameVerify) {
            return t('fields.user-name.validation.already-taken') as string;
          }

          return undefined;
        },
      },
      postcode: {
        validate: (value: string) =>
          value &&
          (value === '00000' || !isPostalCode(value, POSTAL_CODE_GERMANY))
            ? (t('fields.post-code.validation.regex') as string)
            : undefined,
      },
    }),
    [client, t]
  );

  useEffect(() => {
    register({ name: 'firstName' }, validation.firstName);
    register({ name: 'lastName' }, validation.lastName);
    register({ name: 'username' }, validation.username);
    register({ name: 'postcode' }, validation.postcode);
    register({ name: 'dateOfBirth' });
    register({ name: 'gender' });
  }, [
    dateOfBirth,
    register,
    validation.firstName,
    validation.lastName,
    validation.postcode,
    validation.username,
  ]);

  const genders = React.useMemo(
    () => [
      {
        value: String(UserGender.Male),
        label: t('common.gender.male') as string,
      },
      {
        value: String(UserGender.Female),
        label: t('common.gender.female') as string,
      },
      {
        value: String(UserGender.Divers),
        label: t('common.gender.divers') as string,
      },
    ],
    [t]
  );

  const changeDate = useCallback(
    async (event: DateTime | null) => {
      const value = event?.toISODate() || undefined;
      setValue('dateOfBirth', value, {
        shouldDirty: true,
        shouldValidate: true,
      });

      update({
        dateOfBirth: value,
      });
    },
    [setValue, update]
  );

  const isValid = useMemo(() => {
    const values = watch();
    // validate postcode when component is mounted
    const postcodeInvalidMessage = validation.postcode.validate(
      values.postcode
    );
    if (postcodeInvalidMessage && isEmpty(errors)) {
      setError('postcode', {
        type: 'validate',
        message: postcodeInvalidMessage,
        shouldFocus: true,
      });
    }
    if (values.username) {
      const validateUsername = async () => {
        // validate username when component is mounted
        const usernameInvalidMessage = await validation.username.validate(
          values.username
        );
        if (usernameInvalidMessage && isEmpty(errors)) {
          setError('username', {
            type: 'validate',
            message: usernameInvalidMessage || undefined,
            shouldFocus: true,
          });
        }
      };
      validateUsername();
    }

    return (
      isEmpty(errors) &&
      !!values.firstName &&
      !!values.lastName &&
      !!values.username &&
      !!values.dateOfBirth &&
      !!values.postcode &&
      !!values.gender
    );
  }, [errors, watch, validation.postcode, validation.username, setError]);

  return (
    <PublicLayout title={t('sign-up.personal-info.title')} page="1 / 3">
      <Box paddingY={7}>
        <Form.Row columns={2}>
          <Form.TextField
            fullWidth
            required
            autoFocus
            data-cy-first-name
            name="firstName"
            variant="outlined"
            label={t('fields.first-name.label')}
            errors={errors.firstName}
            value={watch('firstName').slice(0, 50)}
            onChange={changeField('firstName')}
          />
          <Form.TextField
            fullWidth
            required
            data-cy-last-name
            name="lastName"
            variant="outlined"
            label={t('fields.last-name.label')}
            errors={errors.lastName}
            value={watch('lastName').slice(0, 50)}
            onChange={changeField('lastName')}
          />
        </Form.Row>
        <Form.Row>
          <Form.TextField
            fullWidth
            required
            data-cy-user-name
            name="username"
            variant="outlined"
            label={t('fields.user-name.label')}
            errors={errors.username}
            value={watch('username')}
            onChange={changeField('username')}
          />
        </Form.Row>
        <Form.Row columns={2}>
          <Form.DateField
            autoOk
            fullWidth
            disableFuture
            disableToolbar
            data-cy-date-of-birth
            openTo="year"
            variant="inline"
            inputVariant="outlined"
            placeholder="TT/MM/JJJJ"
            views={['year', 'month', 'date']}
            label={t('fields.date-of-birth.label')}
            value={
              watch('dateOfBirth')
                ? DateTime.fromISO(watch('dateOfBirth'))
                : null
            }
            minDate={DateTime.local().minus({ years: MAX_REG_AGE })}
            maxDate={DateTime.local().minus({ years: MIN_REG_AGE })}
            onChange={changeDate}
          />
          <Form.TextField
            fullWidth
            required
            data-cy-post-code
            name="postcode"
            variant="outlined"
            label={t('fields.post-code.label')}
            errors={errors.postcode}
            value={watch('postcode').replace(/[^0-9-]/gi, '')}
            onChange={changeField('postcode')}
          />
        </Form.Row>
        <Form.Row noPadding columns={2}>
          <Form.RadioField
            fullWidth
            data-cy-gender
            name="gender"
            label={t('fields.gender.label')}
            options={genders}
            value={watch('gender')}
            onChange={changeField('gender')}
          />
        </Form.Row>
      </Box>
      <StepNavigate disabled={!isValid} onNext={onNext} onBack={onBack} />
    </PublicLayout>
  );
};

export default PersonalInfo;
