import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    textAlign: 'left',
    height: 'max-content',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    textTransform: 'uppercase',
  },
  line: {
    height: '1px',
    backgroundColor: '#E6EEFA',
    border: 'none',
    margin: 0,
    padding: 0,
  },
  closeIcon: {
    fontSize: 16,
  },
  titleText: {
    marginBottom: 8,
    maxWidth: 175,
    lineClamp: 2,
    display: '-webkit-box',
    boxOrient: 'vertical',
    maxHeight: '3em',
    overflow: 'hidden',
  },
  contant: {
    padding: 8,
    display: 'flex',
    alignItems: 'center',
  },
  details: {},
  detailsContent: {
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    display: 'flex',
    marginRight: 12,
    width: '80px !important',
    height: 80,
    backgroundColor: '#2C3E50',
    borderRadius: 4,
    overflow: 'hidden',
  },
  link: {
    textDecoration: 'none',
    color: '#647485',
  },
  calendarIcon: {
    marginRight: 8,
    color: '#647485',
  },
  actionButtonsContainer: {
    padding: 8,
    display: 'flex',
    gridGap: '8px',
  },
  actionButton: {
    boxShadow: 'none',
  },
  loverCaseCaption: {
    fontSize: '12px',
    letterSpacing: '0.4 px',
    textTransform: 'none',
    lineHeight: '16px',
    color: '#4A647C',
  },
  cardPicture: {
    boxSizing: `border-box`,
    display: 'flex!important',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 80,
    height: 80,
    padding: 18,
    marginRight: theme.spacing(5),
    backgroundColor: '#2C3E50',
    borderRadius: 6,
    overflow: 'hidden',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
    },
  },
}));
