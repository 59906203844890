import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { sortBy } from 'lodash';

import { Box, List, Paper, Typography } from '@material-ui/core';
import { useGetCompletedQuizDuelsQuery } from '@/graphql';
import Loading from '@/components/loading';
import DuelCompletedItem from '../../components/duel-completed-item';

type QuizTimeParams = {
  quizSessionId: string;
};

const CompletedDuelsList = () => {
  const { t } = useTranslation();
  const { quizSessionId } = useParams<QuizTimeParams>();
  const { data, loading } = useGetCompletedQuizDuelsQuery({
    variables: { quizSessionId },
  });

  const duelsLength = useMemo(
    () => data?.completedQuizDuels?.length || 0,
    [data]
  );

  const completedDuelsList = useMemo(
    () =>
      sortBy(data?.completedQuizDuels, ['userDuelsData.duel.updated'])
        .reverse()
        .map((it, i) => (
          <DuelCompletedItem
            data-cy-completed-duel-item
            key={it?.userDuelsData?.duel?.id}
            divider={i !== duelsLength - 1}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            userDuel={it?.userDuelsData as any}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            opponentDuel={it?.opponentDuelsData as any}
          />
        )),
    [data, duelsLength]
  );
  const isEmpty = useMemo(
    () => !completedDuelsList.length,
    [completedDuelsList.length]
  );

  if (loading) return <Loading />;

  return !isEmpty ? (
    <Box data-cy-completed-duels padding={2}>
      <Paper elevation={0}>
        <List>{completedDuelsList}</List>
      </Paper>
    </Box>
  ) : (
    <Box clone data-cy-completed-duels-empty height="100%">
      <Paper square elevation={0}>
        <Box display="flex" justifyContent="center" paddingY={10}>
          <Typography variant="caption">
            {t('duels.completed-duels-empty')}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default CompletedDuelsList;
