import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

type DialogManageSubscriptionOnOriginalPlatformProps = {
  isOpened: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
};

const DialogManageSubscriptionOnOriginalPlatform: React.FC<DialogManageSubscriptionOnOriginalPlatformProps> =
  ({ onClose, isOpened }) => {
    const { t } = useTranslation();

    return (
      <Dialog open={isOpened} onClose={() => onClose(false)}>
        <DialogTitle>
          {t('dialogs.manage-subscription-on-original-platform.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body2">
            {t('dialogs.manage-subscription-on-original-platform.content')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box component="span">
            <Button color="primary" onClick={() => onClose(false)}>
              {t('common.ok')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  };

export default DialogManageSubscriptionOnOriginalPlatform;
