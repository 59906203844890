import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    fontSize: 'inherit',
    verticalAlign: 'middle',
    width: 12,
  },
});

const StarIcon: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 12 13"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path d="M6.46027 0.752914C6.28798 0.3464 5.71185 0.3464 5.53956 0.752914L4.24424 3.80912C4.17187 3.97987 4.01094 4.09654 3.82615 4.11222L0.51406 4.39322C0.0733468 4.43061 -0.105356 4.97974 0.228943 5.26934L2.74514 7.44912C2.88543 7.57065 2.94685 7.75988 2.90468 7.94063L2.14912 11.1794C2.04864 11.6101 2.51575 11.9496 2.89441 11.7211L5.74155 10.0027C5.90044 9.90677 6.09939 9.90677 6.25828 10.0027L9.10542 11.7211C9.48407 11.9496 9.95119 11.6101 9.85071 11.1794L9.09514 7.94063C9.05298 7.75988 9.1144 7.57065 9.25468 7.44912L11.7709 5.26934C12.1052 4.97974 11.9265 4.43061 11.4858 4.39322L8.17368 4.11222C7.98889 4.09654 7.82796 3.97987 7.75559 3.80912L6.46027 0.752914Z" />
    </SvgIcon>
  );
};

export default StarIcon;
