import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SmallLogoIcon from '@/components/icons/small-logo';
import AgreementsFooter from '@/components/agreements-footer';

import { useStyles } from './styles';
import Intro from '../intro';

const Auth: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const search = history.location.search;
  const query = new URLSearchParams(search);
  const hash = query.get('hash');
  const hashUrlString = hash ? '/?hash=' + hash : '';

  const signUpHandler = React.useCallback(() => {
    history.push(`/sign-up${hashUrlString}`);
  }, [history, hashUrlString]);

  const signInHandler = React.useCallback(() => {
    history.push(`/sign-in${hashUrlString}`);
  }, [history, hashUrlString]);

  return (
    <Box paddingX={4} className={classes.root}>
      <Box marginTop={4}>
        <SmallLogoIcon />
      </Box>
      <Box marginBottom={0}>
        <Intro></Intro>
      </Box>
      <Box>
        <Box>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            onClick={signInHandler}
          >
            {t('common.sign-in')}
          </Button>
        </Box>
        <Box marginTop={4}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={signUpHandler}
          >
            {t('common.sign-up')}
          </Button>
        </Box>
        <AgreementsFooter />
      </Box>
    </Box>
  );
};

export default Auth;
