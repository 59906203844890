import React from 'react';
import { useTranslation } from 'react-i18next';

import SearchLayout from '@/screens/main/components/search-layout';

const SearchNetworksSessions = () => {
  const { t } = useTranslation();

  return (
    <SearchLayout
      inputPlaceholder={t('networks.find-sessions')}
      contentPlaceholder={t('networks.find-sessions-tip')}
    />
  );
};

export default SearchNetworksSessions;
