import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useDataProtectionQuery } from '@/graphql';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import StackLayout from '../../components/stack-layout';
import {
  USERCENTRICS_SCRIPT_ID,
  USERCENTRICS_SCRIPT_SRC,
  USERCENTRICS_SETTINGS_ID,
} from '@/utils/constants';

declare const window: Window &
  typeof globalThis & {
    UC_UI: any;
  };

const Dataprotection: React.FC = () => {
  const { data } = useDataProtectionQuery();
  const [isOpen, setIsOpen] = useState(false);
  const { cookiesEnabled } = useFeatureFlags();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const hideBackArrow = params.get('hideBackArrow') ? true : false;

  useEffect(() => {
    document.body.classList.remove('usercentrics-is-hidden');
    if (!isOpen && cookiesEnabled && !window?.UC_UI?.isInitialized()) {
      const script = document.createElement('script');
      script.src = USERCENTRICS_SCRIPT_SRC;
      script.type = 'text/javascript';
      script.async = true;
      script.id = USERCENTRICS_SCRIPT_ID;
      script.dataset.settingsId = USERCENTRICS_SETTINGS_ID;
      document.body.appendChild(script);
      setIsOpen(true);
    }

    return () => {
      document.body.classList.add('usercentrics-is-hidden');
    };
  }, [isOpen, cookiesEnabled]);

  return (
    <StackLayout hideBackArrow={hideBackArrow} title="Datenschutzinformation">
      <Box clone p={2} className="privacy-page">
        <Paper elevation={0}>
          {data?.dataProtection?.text && (
            <div
              dangerouslySetInnerHTML={{ __html: data?.dataProtection?.text }}
            />
          )}
        </Paper>
      </Box>
    </StackLayout>
  );
};

export default Dataprotection;
