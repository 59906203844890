import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { NotificationType, useReadNotificationMutation } from '@/graphql';
import {
  LS_KEY_IS_PAY_CONF_ACTIVE,
  LS_KEY_PAY_START_PREMIUM_PRESSED,
} from '@/utils/constants';
import { StripeErrorsEnum } from '@/type';
import { useTranslation } from 'react-i18next';

export const useConfirmPayment = () => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const [readNotification] = useReadNotificationMutation();

  const handlePaymentConfirmation = useCallback(
    async (notification: NotificationType) => {
      if (
        (notification?.id && history.location.pathname.includes('payment')) ||
        history.location.pathname.includes('network') ||
        history.location.pathname.includes('subscription') ||
        history.location.pathname.includes('notifications')
      ) {
        const notifData = JSON.parse(notification?.data);
        localStorage.setItem(LS_KEY_IS_PAY_CONF_ACTIVE, 'true');

        const stripe = !window?.ReactNativeWebView
          ? import('@stripe/stripe-js').then((stripe) => {
              return stripe.loadStripe(
                process.env.REACT_APP_STRIPE_PUBLIC_API_KEY || '',
                {
                  locale: i18n.language === 'en' ? 'en' : 'de',
                }
              );
            })
          : null;
        if (!stripe) {
          console.error('Stripe initialization failed');
          return;
        } else {
          stripe?.then((stripeInstance) => {
            stripeInstance
              ?.confirmCardPayment(notifData?.client_secret, {
                setup_future_usage: 'off_session',
              })
              .then((result) => {
                localStorage.removeItem(LS_KEY_PAY_START_PREMIUM_PRESSED);
                if (result.error) {
                  console.error(result.error);
                  history.replace(
                    `${history?.location?.pathname}${history?.location?.search}`,
                    {
                      isStripeError:
                        result.error &&
                        result.error.type !== StripeErrorsEnum.CARD &&
                        result.error.type !== StripeErrorsEnum.AUTHENTICATION &&
                        result.error.type !== StripeErrorsEnum.INVALID_REQUEST
                          ? true
                          : false,
                      isStripeCardError:
                        result.error.type === StripeErrorsEnum.CARD
                          ? true
                          : false,
                      isStripeAuthentificationError:
                        result.error.type === StripeErrorsEnum.AUTHENTICATION
                          ? true
                          : false,
                      isStripeInvalidRequestError:
                        result.error.type === StripeErrorsEnum.INVALID_REQUEST
                          ? true
                          : false,
                      isRedirectedFromNotifications: true,
                    }
                  );
                }
                localStorage.removeItem(LS_KEY_IS_PAY_CONF_ACTIVE);
                readNotification({
                  variables: { id: notification?.id },
                });
              })
              .catch((error) => {
                console.error('Payment confirmation failed:', error);
                localStorage.removeItem(LS_KEY_IS_PAY_CONF_ACTIVE);
              });
          });
        }
      }
    },
    [readNotification, history, i18n.language]
  );

  return {
    handlePaymentConfirmation,
  };
};
