import React, { useMemo } from 'react';
import clsx from 'clsx';

import {
  Box,
  Button,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import DuelOutlinedIcon from '@/components/icons/duel-outlined-icon';

import { useUser } from '@/contexts/user-context';
import { useStyles } from './styles';
import { useCreateDuel } from '@/hooks/useCreateDuel';
import { Participant } from '@/type';
import Ava from '@/components/ava';
import { MEMBER_LIST_ITEM_CSS_ID } from '@/utils/constants';

const MemberItem: React.FC<Participant> = ({
  id,
  userId,
  username,
  avatar,
  score,
  rank,
  joinedRank,
  isAvailableForDuel,
  divider,
  isRanking,
  isMemberSticky,
  sessionId,
  ...rest
}) => {
  const classes = useStyles();
  const { user } = useUser();
  const isMy = useMemo(() => userId === user?.id, [userId, user]);

  const { handleCreateDuel, creatingLoading } = useCreateDuel({
    opponentId: userId,
    sessionId,
    memberId: id,
  });

  const startCreateDuel = () => {
    handleCreateDuel();
  };

  return (
    <ListItem
      divider={divider}
      component="div"
      id={isRanking && isMy ? MEMBER_LIST_ITEM_CSS_ID : undefined}
      className={clsx(
        isMy && classes.my,
        isMy && isRanking && classes.myRanking
      )}
      {...rest}
    >
      {isRanking &&
        (joinedRank && (!isMemberSticky || !isMy) ? (
          <div className={classes.joinedRankItem}>
            <div className={classes.joinedRankCircle}></div>
            <div className={classes.joinedRankLine}></div>
          </div>
        ) : (
          <Box className={clsx(classes.rank, isMy && classes.myRank)}>
            <Typography
              color="textSecondary"
              variant="h2"
              className={clsx(isMy && classes.myLabel)}
            >
              {String('0' + rank).slice(-2)}
            </Typography>
          </Box>
        ))}
      <Ava src={avatar} />
      <ListItemText
        primary={
          <>
            <Typography
              variant="subtitle2"
              className={clsx(classes.label, isMy && classes.myLabel)}
            >
              {username}
            </Typography>
            {!isRanking && (
              <Typography variant="caption">{score} MCQs</Typography>
            )}
          </>
        }
      />
      <div className={classes.secondaryAction}>
        {isRanking ? (
          <Typography
            variant="caption"
            className={clsx(isMy && classes.myLabel)}
          >
            {score} MCQs
          </Typography>
        ) : isMy ? (
          <></>
        ) : (
          <Button
            variant="outlined"
            onClick={startCreateDuel}
            disabled={!isAvailableForDuel || creatingLoading}
            className={!isAvailableForDuel ? classes.btnCreateDuelDisabled : ''}
          >
            <DuelOutlinedIcon
              color={
                !isAvailableForDuel || user?.settings?.activeQuizTime?.id
                  ? 'disabled'
                  : 'primary'
              }
            />
          </Button>
        )}
      </div>
    </ListItem>
  );
};

export default MemberItem;
