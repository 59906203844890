import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    color: theme.palette.text.primary,
  },
  cardPicture: {
    display: 'flex!important',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 72,
    height: 72,
    marginRight: theme.spacing(4),  
    backgroundColor: '#E6EEFA',
    borderRadius: 4,
    overflow: 'hidden',
    border: '1px solid rgba(0, 0, 0, 0.07)',
    objectFit: 'cover',
    '& img': {
      width: 72,
      height: 72,
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },
  duration: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    color: '#647485',
  },
  caption: {
    lineClamp: 2,
    display: '-webkit-box',
    boxOrient: 'vertical',
    maxHeight: '3em',
    overflow: 'hidden',
  },
  chipGolden: {
    borderColor: '#DD6903',
    background: '#fff',
    color: '#DD6903',
  },
  date: {
    fontSize: 12,
    letterSpacing: '0.4px',
    textTransform: 'none',
  },
  loverCaseCaption: {
    fontSize: '12px',
    letterSpacing: '0.4px',
    textTransform: 'none',
    lineHeight: '16px',
    color: '#4A647C',
  },
  captionWithOverflow: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
