import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Divider, Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import RemainingTime from '@/components/remaining-time';
import AppButton from '@/components/app-button';
import {
  useGetActiveQuizDuelsQuery,
  useGetUserQuizSessionSettingsQuery,
  useQuizScoreQuery,
  useQuizSessionsOnHomePageQuery,
  useQuizTimeDetailsQuery,
  UserQuizSessionSettingsUserStatus,
} from '@/graphql';
import { ChevronRight } from '@material-ui/icons';
import QuizCountDown from '../../header/quiz-count-down';
import ResultsTopMenu from '../../results-top-menu';
import DuelsList from '../../duels-list';
import { useCreateQuizDuel } from '@/hooks/useCreateQuizDuel';
import { useUser } from '@/contexts/user-context';
import QuiztimeItem from '../quiztime-item';
import { useStyles } from './styles';
import { QUIZTIME_MAX_DUEL_COUNT } from '@/utils/constants';

const QuizDuelCard: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user } = useUser();
  const { data, refetch: refetchQuizSessionsOnHomePage } =
    useQuizSessionsOnHomePageQuery({ variables: { startLess24Hours: true } });
  const quizSessionId = data?.inProgressUserQuizSession?.id;
  const { data: quizScoreData, refetch: refetchQuizScore } = useQuizScoreQuery({
    variables: {
      quizSessionId: quizSessionId,
    },
  });
  const { handleCreateQuizDuel, creatingLoading } = useCreateQuizDuel({
    sessionId: quizSessionId,
  });

  const {
    handleCreateQuizDuel: handleCreateQuizDuelAndRemovePause,
    creatingLoading: creatingLoadingAndRemovePause,
  } = useCreateQuizDuel({
    sessionId: quizSessionId,
    requiresQuizSettingsRefetch: true,
  });

  const { data: activeQuizDuelsData } = useGetActiveQuizDuelsQuery({
    variables: { quizSessionId },
  });

  const { data: quizDetails } = useQuizTimeDetailsQuery({
    variables: { quizSessionId },
  });

  const activeDuelCount = activeQuizDuelsData?.activeQuizDuels?.length || 0;
  const canCreateDuel = useMemo(
    () =>
      (activeDuelCount || 0 < QUIZTIME_MAX_DUEL_COUNT) &&
      quizDetails?.quizTimeSession?.usersCount &&
      quizDetails?.quizTimeSession?.usersCount > 1,
    [activeDuelCount, quizDetails]
  );
  const yourDuels = useMemo(
    () =>
      (activeQuizDuelsData?.activeQuizDuels || []).filter((it) => {
        const youTurn =
          it?.round?.userQuestions?.filter(
            (x) => x.user?.id === user?.id && !!x.userAnswer?.id
          ).length !== 3 ||
          it?.round.userQuestions.filter((x) => !!x.userAnswer?.id).length ===
            6;
        return youTurn;
      }),
    [activeQuizDuelsData, user]
  );

  const { data: userQuizSettingsData } = useGetUserQuizSessionSettingsQuery({
    variables: { quizSessionId: quizSessionId },
  });
  const userStatus = userQuizSettingsData?.userQuizSessionSettings?.userStatus;

  return (
    <>
      {userStatus !== UserQuizSessionSettingsUserStatus.Paused && (
        <Paper elevation={0}>
          <Box className={classes.row} px={6} py={2}>
            <Typography variant="overline">{t('quiztime.title')}</Typography>
            {data?.inProgressUserQuizSession?.finishDateTime && (
              <QuizCountDown
                finishDateTime={
                  data?.inProgressUserQuizSession?.finishDateTime as string
                }
                isCountToFinishDateTime={true}
                handleFinish={() => {
                  refetchQuizSessionsOnHomePage();
                  refetchQuizScore();
                }}
              />
            )}
          </Box>
          <Divider orientation="horizontal" />

          <div className={classes.header}>
            <Link
              to={`quiztime/${quizSessionId}`}
              style={{ textDecoration: 'none' }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h2">
                  {data?.inProgressUserQuizSession?.quizTime.title}
                </Typography>
                <ChevronRight color="action" />
              </Box>
            </Link>
            <ResultsTopMenu
              quizSessionId={quizSessionId}
              points={quizScoreData?.quizDuelScore?.points}
              duels={quizScoreData?.quizDuelScore?.duels}
              rank={quizScoreData?.quizDuelScore?.rank}
            />
          </div>
          <Divider orientation="horizontal" />
          {!!yourDuels.length && (
            <>
              <Box className={classes.row} px={6} mt={5}>
                <Typography variant="overline">
                  {t('common.duel.your-turn')}
                </Typography>
                <RemainingTime>{t('common.duel.remaining-time')}</RemainingTime>
              </Box>
              <DuelsList
                duelsListData={yourDuels as any}
                sessionId={quizSessionId}
                duelDuration={data?.inProgressUserQuizSession?.duelDuration}
                showOnlyYourTurn
              />
            </>
          )}

          <div className={classes.footer}>
            <Box
              marginX={4}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="inline-flex">
                <Typography variant="subtitle2">
                  {t('common.duel.ongoing-duels')}
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: 4, color: '#2B75B3' }}
                >
                  {activeDuelCount}/{QUIZTIME_MAX_DUEL_COUNT}
                </Typography>
              </Box>
              <RemainingTime>{t('common.duel.remaining-time')}</RemainingTime>
            </Box>

            <AppButton
              fullWidth
              data-cy-duel-start-new-duel
              color="primary"
              variant="contained"
              loading={creatingLoading}
              disabled={
                !canCreateDuel || activeDuelCount >= QUIZTIME_MAX_DUEL_COUNT
              }
              onClick={handleCreateQuizDuel}
            >
              {t('common.duel.start-new-duel')}
            </AppButton>
          </div>
        </Paper>
      )}
      {userStatus === UserQuizSessionSettingsUserStatus.Paused && (
        <QuiztimeItem
          quizSessionId={quizSessionId}
          quiz={{
            id: quizSessionId,
            startDate: quizDetails?.quizTimeSession?.startDate,
            startDateTime: quizDetails?.quizTimeSession?.startDateTime,
            finishDateTime: quizDetails?.quizTimeSession?.finishDateTime,
            quizTimeDuration: quizDetails?.quizTimeSession?.quizTimeDuration,
            quizTime: {
              id: quizSessionId,
              title: quizDetails?.quizTimeSession?.quizTime?.title,
              quizTimeImage:
                quizDetails?.quizTimeSession?.quizTime?.quizTimeImage,
            },
          }}
          quizStatus={quizDetails?.quizTimeSession?.status}
          isSingleItem
          randomDuel={{
            handleCreateQuizDuel: handleCreateQuizDuelAndRemovePause,
            creatingLoading: creatingLoadingAndRemovePause,
            canCreateDuel: canCreateDuel,
          }}
          userStatus={userStatus}
        />
      )}
    </>
  );
};

export default QuizDuelCard;
