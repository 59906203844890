import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { shuffle, sortBy } from 'lodash';

import { Box, Button, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import ChooseList from '@/components/choose-list';
import Dot from '@/components/dot';
import { useUser } from '@/contexts/user-context';
import AppButton from '@/components/app-button';
import QuestionImage from '@/components/question-image';

import {
  useSetUserAnswerMutation,
  useUserDuelQuery,
  UserDuelQuery,
  UserDuelQueryVariables,
  useClassroomMembersLazyQuery,
  useGetActiveDuelsLazyQuery,
  useUpdateDuelsSettingsMutation,
  useClassRoomSessionProgressLazyQuery,
} from '@/graphql';
import { USER_DUEL_QUERY } from '@/apollo/queries';

import EmptyLayout from '../../components/empty-layout';
import RuleWrapper from '../rule-wrapper';

import CorrectAnswer from './correct-answer';
import InCorrectAnswer from './incorrect-answer';
import Fallback from '@/components/fallback';
import CheckIcon from '@/components/icons/check';
import { DUEL_LAST_QUESTION_NUMBER } from '@/utils/constants';
import FeedbackForQuestion from '../../components/feedback-for-question';
import FeedbackButton from '../../components/feedback-button';

interface QuestionParams {
  duelId: string;
  roundId: string;
  questionId: string;
}

const Question: React.FC = ({ ...rest }) => {
  const [answerId, setAnswerId] = useState<string | null>(null);
  const { duelId, roundId, questionId } = useParams<QuestionParams>();
  const { user } = useUser();
  const { t } = useTranslation();
  const history = useHistory();

  const search = history.location.search;
  const query = new URLSearchParams(search);
  const isFeedbackVisible = query.get('isFeedbackVisible');

  const [isVisibleFeedbackForQuestion, setIsVisibleFeedbackForQuestion] =
    useState<boolean>(!!isFeedbackVisible);

  const { data, error, loading } = useUserDuelQuery({ variables: { duelId } });
  const currentSessionId = useMemo(
    () => data?.duelData?.userScore?.classRoomLearningSession?.id,
    [data]
  );
  const [getClassroomSessionProgress] = useClassRoomSessionProgressLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [getClassroomMembers] = useClassroomMembersLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [getActiveDuels] = useGetActiveDuelsLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [updateDuelsSettings] = useUpdateDuelsSettingsMutation();

  const [setAnswer, { loading: setAnswerLoading }] = useSetUserAnswerMutation();

  const round = (data?.duelData?.rounds || []).find((it) => it?.id === roundId);

  const questions = useMemo(
    () => sortBy(round?.userQuestions || [], ['questionNumber']),
    [round]
  );

  const userQuestions = useMemo(
    () =>
      sortBy(
        questions.filter(
          (it) => !!it?.user && !!user && it.user.id === user.id
        ),
        ['questionNumber']
      ),
    [questions, user]
  );

  const indx = useMemo(
    () => userQuestions.findIndex((it) => it.id === questionId),
    [questionId, userQuestions]
  );

  const currentUserQ = useMemo(
    () => (indx < userQuestions.length ? userQuestions[indx] : null),
    [indx, userQuestions]
  );

  const dots = useMemo(
    () =>
      round?.userQuestions ? (
        userQuestions.map((it) => {
          if (!it.userAnswer || !it.correctAnswer)
            return (
              <Dot
                key={`dot-${it.id}`}
                size="large"
                variant={
                  it?.questionNumber === currentUserQ?.questionNumber ||
                  (!!it?.questionNumber &&
                    !!currentUserQ?.questionNumber &&
                    !!currentUserQ?.userAnswer &&
                    it?.questionNumber - currentUserQ?.questionNumber === 1)
                    ? 'highlighted'
                    : 'default'
                }
              />
            );
          return it.userAnswer.value === it.correctAnswer.value ? (
            <Dot key={`dot-${it.id}`} variant="success" size="large">
              <Box
                component="span"
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ width: '100%', height: '100%' }}
              >
                <CheckIcon style={{ width: 12, height: 12, color: '#fff' }} />
              </Box>
            </Dot>
          ) : (
            <Dot key={`dot-${it.id}`} variant="error" size="large" />
          );
        })
      ) : (
        <>
          <Dot size="large" /> <Dot size="large" /> <Dot size="large" />
        </>
      ),
    [round, userQuestions, currentUserQ]
  );

  const options = useMemo(() => {
    if (!currentUserQ?.question?.answers)
      return [...Array(4)].map((_, i) => ({
        value: `skeletorn-${i}`,
        label: <Skeleton />,
      }));
    const answersMixed = shuffle(currentUserQ?.question?.answers || []);
    return answersMixed.map((it) => ({
      value: String(it.id),
      label: it.value,
    }));
  }, [currentUserQ]);

  const nextLink = useMemo(() => {
    if (indx < 0) return '';

    if (indx >= userQuestions.length - 1) return `/duel/${duelId}/overview`;

    const next = userQuestions[indx + 1];

    return `/duel/${duelId}/round/${roundId}/question/${next.id}`;
  }, [duelId, indx, roundId, userQuestions]);

  const hasAnswer = useMemo(
    () => !!currentUserQ && currentUserQ.userAnswer,
    [currentUserQ]
  );

  const isCorrect = useMemo(
    () =>
      hasAnswer &&
      !!currentUserQ &&
      !!currentUserQ.userAnswer &&
      !!currentUserQ.correctAnswer &&
      currentUserQ.userAnswer.value === currentUserQ.correctAnswer.value,
    [currentUserQ, hasAnswer]
  );

  const isInCorrect = useMemo(
    () =>
      hasAnswer &&
      !!currentUserQ &&
      !!currentUserQ.userAnswer &&
      !!currentUserQ.correctAnswer &&
      currentUserQ.userAnswer.value !== currentUserQ.correctAnswer.value,
    [currentUserQ, hasAnswer]
  );

  const handleSetAnswer = useCallback(async () => {
    try {
      if (currentUserQ?.question?.id) {
        const res = await setAnswer({
          variables: {
            duelRoundQuestionAnswerId: currentUserQ.id,
            answerId,
          },
          update: (store, { data }) => {
            const newDuel =
              data?.setAnswer?.duelRoundQuestionAnswer?.duelRound?.duel;

            const oldData = store.readQuery<
              UserDuelQuery,
              UserDuelQueryVariables
            >({
              query: USER_DUEL_QUERY,
              variables: { duelId },
            });

            if (!newDuel || !oldData) return;

            const duelData = {
              ...oldData.duelData,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              status: newDuel.status as any,
              userScore: newDuel.result?.currentUserData,
              opponentScore: newDuel.result?.opponentData,
            };

            store.writeQuery<UserDuelQuery, UserDuelQueryVariables>({
              query: USER_DUEL_QUERY,
              variables: { duelId },
              data: { duelData },
            });
          },
        });
        if (currentUserQ.questionNumber === DUEL_LAST_QUESTION_NUMBER && res) {
          if (!user?.settings?.duelMode)
            updateDuelsSettings({
              variables: {
                mode: true,
              },
            });
          const currentDuelScore =
            res?.data?.setAnswer?.duelRoundQuestionAnswer?.duelRound?.duel
              ?.result?.currentUserData?.duelScore;
          getActiveDuels();
          if (currentSessionId) {
            getClassroomMembers({
              variables: {
                sessionId: currentSessionId,
              },
            });
          }
          if (currentSessionId && currentDuelScore && currentDuelScore > 0) {
            getClassroomSessionProgress({
              variables: {
                sessionId: currentSessionId,
              },
            });
          }
        }
      }
    } catch (error) {
      console.error('SET_ANSWER', error);
    } finally {
      setAnswerId(null);
    }
  }, [
    answerId,
    currentUserQ,
    duelId,
    setAnswer,
    getClassroomSessionProgress,
    getClassroomMembers,
    currentSessionId,
    getActiveDuels,
    updateDuelsSettings,
    user,
  ]);

  const questionNode = currentUserQ?.question?.value ?? (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );

  const nextAnswerNode = useMemo(() => {
    if (indx < 0) return <>&nbsp;</>;

    if (indx >= 0 && indx < userQuestions.length - 1) return t('common.next');

    return t('common.end-round');
  }, [indx, t, userQuestions.length]);

  const setAnswerNode = useMemo(() => {
    if (!data?.duelData?.rounds) return <>&nbsp;</>;

    return t('common.submit-answer');
  }, [data, t]);

  if (!!error) {
    console.error('QUESTION_QUERY', error);
    return (
      <EmptyLayout title="">
        <Fallback />
      </EmptyLayout>
    );
  }

  return (
    <RuleWrapper>
      <Box
        clone
        data-cy-question
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        paddingX={4}
        pt={5}
        pb={7}
        minHeight="-webkit-fill-available"
      >
        <Paper elevation={0}>
          <Box flexGrow={1}>
            <Box
              display="flex"
              justifyContent="space-between"
              mx={-4}
              pb={5}
              px={4}
              style={{ boxShadow: 'inset 0px -1px 0px #E6EEFA' }}
            >
              <Box
                display="grid"
                gridTemplateColumns="auto auto auto"
                gridColumnGap={10}
              >
                {dots}
              </Box>
              <Typography variant="overline">
                {t('common.round-number', { round: round?.roundNumber || '' })}
              </Typography>
            </Box>
            <Box paddingBottom={3} mt={4}>
              <Typography variant="subtitle1">{questionNode}</Typography>
            </Box>
            <QuestionImage
              src={currentUserQ?.question?.questionImage ?? undefined}
              alt={currentUserQ?.question?.value}
            />
            {!hasAnswer && (
              <ChooseList
                data-cy-answers
                options={options}
                value={answerId}
                onChange={setAnswerId}
              />
            )}
            {currentUserQ && isCorrect && (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              <CorrectAnswer userQuestion={currentUserQ as any} />
            )}
            {currentUserQ && isInCorrect && (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              <InCorrectAnswer userQuestion={currentUserQ as any} />
            )}
          </Box>
          <Box paddingTop={7}>
            {!hasAnswer && (
              <AppButton
                fullWidth
                data-cy-submit-answer-btn
                color="primary"
                variant="contained"
                disabled={!answerId}
                loading={setAnswerLoading}
                onClick={handleSetAnswer}
              >
                {setAnswerNode}
              </AppButton>
            )}
            {hasAnswer && (
              <Button
                fullWidth
                data-cy-submit-answer-next
                color="primary"
                variant="contained"
                component={RouterLink}
                to={nextLink}
              >
                {nextAnswerNode}
              </Button>
            )}
            {!loading && hasAnswer && (
              <FeedbackButton
                questionId={currentUserQ?.question?.id}
                handleClick={setIsVisibleFeedbackForQuestion}
              />
            )}
          </Box>
        </Paper>
      </Box>
      {hasAnswer &&
        currentUserQ?.question?.id &&
        isVisibleFeedbackForQuestion && (
          <FeedbackForQuestion
            questionId={currentUserQ.question.id}
            nextLink={nextLink}
            setFeedbackVisible={setIsVisibleFeedbackForQuestion}
          />
        )}
    </RuleWrapper>
  );
};

export default Question;
