import React from 'react';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';

import {
  Box,
  LinearProgress,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';

import RatingInfo from '../../components/rating-info';
import WeeklyActivity from '../../daily-tipper/weekly-activity';

const useStyles = makeStyles((theme) => ({
  progressWrap: {
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#E6EEFA',
    },
  },
  progressBar1: {
    borderRadius: 4,
  },
}));
interface DailyTipperCardProps {
  sucessCount?: number;
  errorCount?: number;
  count?: number;
  all?: number;
}

const DailyTipperCard: React.FC<DailyTipperCardProps> = ({
  sucessCount,
  errorCount,
  count,
  all,
  children,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  if (!isNumber(count) || !isNumber(all)) return <></>;
  const localCount = count > all ? all : count;

  return (
    <Paper elevation={0}>
      <Box p={6}>
        {children}
        <Box display="flex" justifyContent="space-between">
          <Typography variant="overline">
            {t('common.daily-tipper.progress')}
          </Typography>
          <Typography
            variant="subtitle2"
            id="daily-tipper-card-el"
            style={{ color: '#2B75B3' }}
          >
            {t('common.progress', { count: localCount, all })}
          </Typography>
        </Box>

        <Box marginTop={3.5} className={classes.progressWrap}>
          <LinearProgress
            style={{
              height: 8,
              borderRadius: 10,
            }}
            classes={{
              bar1Determinate: classes.progressBar1,
            }}
            variant="determinate"
            value={(localCount / all) * 100}
          />
        </Box>
        <WeeklyActivity />
        <Box marginTop={2}>
          <Typography variant="overline">
            {t('common.daily-tipper.week-answers')}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-between" marginTop={3}>
          <RatingInfo type="success" count={sucessCount}>
            {t('common.daily-tipper.correct')}
          </RatingInfo>
          <RatingInfo type="error" count={errorCount}>
            {t('common.daily-tipper.no-correct')}
          </RatingInfo>
        </Box>
      </Box>
    </Paper>
  );
};

export default DailyTipperCard;
