import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { SS_KEY_MEMO_CUR_TAB } from '@/utils/constants';

type SpecialityGroupCardProps = {
  id: string;
  image?: string | null;
  title?: string;
  count?: number | null;
};

const SpecialityGroupCard: React.FC<SpecialityGroupCardProps> = ({
  id,
  image,
  title,
  count,
}) => {
  const classes = useStyles();
  const memorizeCurrentTab = () => {
    sessionStorage.setItem(SS_KEY_MEMO_CUR_TAB, 'true');
  };

  return (
    <Link component={RouterLink} to={`/network-sessions-by-spec/${id}/`}>
      <Box className={classes.root} onClick={memorizeCurrentTab}>
        <Box className={classes.iconBox}>
          {image && <img src={image} alt={title} loading="lazy" />}
        </Box>
        <Box display="flex" justifyContent="space-between" flexGrow={1}>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="body1" color="textSecondary">
            {count}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

export default SpecialityGroupCard;
