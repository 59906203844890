import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  label: {
    display: 'inline-flex',
    padding: '2px 8px 3px',
    background: theme.palette.action.disabledBackground,
    borderRadius: 4,
    fontWeight: 600,
    fontSize: 9,
    lineHeight: 1.33,
    letterSpacing: 0.4,
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.fontFamily,
  },
}));
