import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';
import LuxonUtils from '@date-io/luxon';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import RnWebView from './components/rn-web-view';
import { ModalProvider } from './contexts/modal-context';
import { AuthProvider } from '@/contexts/auth-context';
import { EventProvider } from './contexts/event-context';

import Routes from './routes';
import client from './apollo-client';
import theme from './theme';
import DevLang from './utils/dev-lang';
import './utils/i18n-config';
import { SnackbarProvider } from './contexts/snackbar-context';
import initDatadogRum from './utils/statistics/datadog-rum-init';
import Loading from './components/loading';
import ErrorBoundary from './components/error-boundary';
import './app.scss';

// initialize statistics
initDatadogRum();

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <EventProvider>
        <MuiThemeProvider theme={theme}>
          <AuthProvider>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <ModalProvider>
                <Suspense fallback={<Loading />}>
                  <SnackbarProvider>
                    <ErrorBoundary>
                      <DevLang>
                        <Routes />
                      </DevLang>
                    </ErrorBoundary>
                  </SnackbarProvider>
                </Suspense>
              </ModalProvider>
            </MuiPickersUtilsProvider>
            {!!window?.ReactNativeWebView && <RnWebView />}
          </AuthProvider>
        </MuiThemeProvider>
      </EventProvider>
    </ApolloProvider>
  );
};

export default App;
