import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    fontSize: 'inherit',
    verticalAlign: 'middle',
    width: 12,
  },
});

const HyperlinkIcon: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 12 12"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path d="M1.33333 1.33333V10.6667H10.6667V6H12V10.6667C12 11.4 11.4 12 10.6667 12H1.33333C0.593333 12 0 11.4 0 10.6667V1.33333C0 0.6 0.593333 0 1.33333 0H6V1.33333H1.33333ZM7.33335 1.33333V0H12V4.66667H10.6667V2.27333L4.11335 8.82667L3.17335 7.88667L9.72669 1.33333H7.33335Z" />
    </SvgIcon>
  );
};

export default HyperlinkIcon;
