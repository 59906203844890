import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link, Box, Divider } from '@material-ui/core';

const AgreementsFooter: React.FC = () => (
  <Box
    display="grid"
    gridTemplateColumns="1fr auto 1fr auto 1fr"
    pt={3.5}
    pb={4}
  >
    <Box textAlign="center">
      <Link
        variant="caption"
        color="textPrimary"
        to="/privacy-agb"
        component={RouterLink}
      >
        AGB
      </Link>
    </Box>
    <Divider orientation="vertical" />
    <Box textAlign="center">
      <Link
        variant="caption"
        color="textPrimary"
        to="/privacy-dataprotection"
        component={RouterLink}
      >
        Datenschutz
      </Link>
    </Box>
    <Divider orientation="vertical" />
    <Box textAlign="center">
      <Link
        variant="caption"
        color="textPrimary"
        to="/privacy-imprint"
        component={RouterLink}
      >
        Impressum
      </Link>
    </Box>
  </Box>
);

export default AgreementsFooter;
