import React from 'react';
import { useTranslation } from 'react-i18next';

import HyperText from '@/components/hypertext';

import {
  Box,
  Divider,
  Typography,
  Checkbox,
  CheckboxProps,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { buildTermConditionLink } from '@/utils/urls';
import { useStyles } from './styles';

interface AgreementProps extends CheckboxProps {
  variant: 'agb' | 'data-protection' | 'subscription';
  noDivider?: boolean;
}
const Agreement: React.FC<AgreementProps> = ({
  noDivider,
  variant,
  name,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const variants = {
    agb: {
      text: t('fields.agb.label'),
      textLink: t('fields.agb.link'),
      link: '/privacy-agb',
    },
    subscription: {
      text: t('fields.subscription.label'),
      textLink: t('fields.subscription.link'),
      link: '/privacy-imprint',
    },
    'data-protection': {
      text: t('fields.data-protection.label'),
      textLink: t('fields.data-protection.link'),
      link: buildTermConditionLink('Datenschutzerklärung'),
    },
  };

  const text = variants[variant].text;
  const textLink = variants[variant].textLink;
  const link = variants[variant].link;

  return (
    <Box>
      <Box
        display="grid"
        alignItems="center"
        gridTemplateColumns="1fr auto"
        gridColumnGap={16}
      >
        <Typography component="span" variant="caption">
          <HyperText text={text}>
            <Link
              className={classes.link}
              to={{
                pathname: link,
                search: '?hideBackArrow=true&target_blank=true',
              }}
              target="_blank"
              rel="noopener"
            >
              <Typography
                variant="caption"
                component="span"
                display="inline"
                className={classes.link}
              >
                {textLink}
              </Typography>
            </Link>
          </HyperText>
        </Typography>
        <Checkbox
          name={name}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          color="primary"
          {...rest}
        />
      </Box>
      {!noDivider && <Divider />}
    </Box>
  );
};

export default Agreement;
