import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Typography, IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import UploadIcon from '@/components/icons/upload';
import DuelRule4Icon from '@/components/icons/duel-rule-4';
import DuelRule8Icon from '@/components/icons/duel-rule-8';
import DuelRule5Icon from '@/components/icons/duel-rule-5';
import AddUsersIcon from '@/components/icons/add-users';
import { useStyles } from './styles';
import SmileIcon from '@/components/icons/smile';
import CheckCircleFilledIcon from '@/components/icons/check-circle-filled';
import clsx from 'clsx';
import { shareLink } from '@/utils/helpers';
import { useUserMeQuery } from '@/graphql';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import DuelRule3Icon from '@/components/icons/duel-rule-3';
import { useSnackbarContext } from '@/contexts/snackbar-context';

const InviteFriends = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { setCustomSnack } = useSnackbarContext();

  const { data } = useUserMeQuery();
  const { isUserPremiumByPayment, isUserPremiumByCode } = useGetPremiumData();

  const dataArr =
    isUserPremiumByPayment || isUserPremiumByCode
      ? [
          { key: '6', icon: <UploadIcon /> },
          { key: '2', icon: <DuelRule8Icon /> },
          { key: '7', icon: <DuelRule3Icon /> },
          { key: '5', icon: <DuelRule5Icon /> },
        ]
      : [
          { key: '1', icon: <UploadIcon /> },
          { key: '2', icon: <DuelRule8Icon /> },
          { key: '3', icon: <DuelRule4Icon /> },
          { key: '4', icon: <AddUsersIcon /> },
          { key: '5', icon: <DuelRule5Icon /> },
        ];
  const invitedFriendsCount = data?.me?.referralCount || 0;

  function closePage() {
    history.goBack();
  }

  function showSnackbar() {
    setCustomSnack({
      visibility: true,
      message: 'sharing.link-copied',
    });
  }
  const handleOnClick = () => {
    shareLink({
      url: window.location.origin + '/sign-up?sharingSenderId=' + data?.me?.id,
      title: 'Humeo',
      text: 'Hey! Ich habe gerade Humeo entdeckt, eine geniale App, die medizinische Fortbildung mit Gamification verbindet. Ich bin schon dabei und total begeistert. Wie wäre es, wenn du dich auch anmeldest und wir uns in einem Quizduell messen? Ich bin gespannt, ob du mich schlagen kannst!',
      callback: showSnackbar,
    });
  };

  return (
    <Box className={classes.root}>
      <div>
        <IconButton
          aria-label="close"
          className={classes.close}
          onClick={closePage}
        >
          <CloseIcon htmlColor="#4A647C" />
        </IconButton>
        <Typography variant="h2">{t('sharing.header-title')}</Typography>
        <Box className={classes.infoBox}>
          <Typography variant="subtitle2">
            {t(
              isUserPremiumByPayment || isUserPremiumByCode
                ? 'sharing.header-info2'
                : 'sharing.header-info'
            )}
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="subtitle2">
            {t('sharing.header-title')}:
          </Typography>
          <Box mt={4} className={classes.successScale}>
            {Array.from(Array(5).keys()).map((it, idx) => (
              <Box
                key={it}
                className={clsx(
                  classes.successScaleItem,
                  invitedFriendsCount <= idx
                    ? classes.successScaleItemDefault
                    : ''
                )}
              >
                {idx === 4 && invitedFriendsCount > 5 ? (
                  <Typography variant="body2">
                    {invitedFriendsCount - 5}+
                  </Typography>
                ) : (
                  <SmileIcon />
                )}
                {invitedFriendsCount > idx && idx < 5 && (
                  <CheckCircleFilledIcon className={classes.circleIcon} />
                )}
              </Box>
            ))}
          </Box>
        </Box>
        <Typography variant="h2">{t('sharing.content-title')}</Typography>
        <Box mt={5} mb={7}>
          {dataArr.map((it) => (
            <Box key={it.key} mt={2} className={classes.listItem}>
              <Box className={classes.iconBox}>{it.icon}</Box>
              <Box>
                <Typography variant="body2">
                  {t(`sharing.rule-${it.key}.caption`)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </div>

      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={handleOnClick}
        style={{ marginTop: 'auto' }}
      >
        {t('sharing.cta-button')}
      </Button>
    </Box>
  );
};

export default InviteFriends;
