import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import DailyTipper from '@/components/icons/daily-tipper';
import AppButton from '@/components/app-button';
import { useDailyTipper } from '@/hooks/useDailyTipper';
import { useUser } from '@/contexts/user-context';
import {
  ActualLearnWeekWeeklyGoal,
  DailyStatusType,
  DailyTipperStatusType,
  useGlobalDialogQuery,
  useUpdateDtDayslostLazyQuery,
} from '@/graphql';
import { useGlobalDialog } from '@/hooks/useGlobalDialog';
import { GlobalDialogTypes } from '@/type';
import WeeklyActivity from '../daily-tipper/weekly-activity';
import { useStyles } from './styles';

const StartDailyTipper: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [dayslostClosed, setDaysLostClosed] = useState<boolean>();
  const { dtWeek, user, dtData } = useUser();
  const dailyTipperMode = user?.settings?.dailyTipperMode;
  const { openDailyTipper, loading } = useDailyTipper();
  const { setGlobalDialog } = useGlobalDialog();
  const { data: dataGlobalDialog } = useGlobalDialogQuery();

  const status = useMemo(() => dtWeek?.me?.dailytipper?.dailyStatus, [dtWeek]);
  const daysLostCount = useMemo(
    () => dtData?.dailyTipperData?.daysLost || 0,
    [dtData]
  );

  const label = useMemo(() => {
    if (status === DailyTipperStatusType.Pending) return t('common.start');
    if (status === DailyTipperStatusType.InProgress)
      return t('common.continue');
    return '';
  }, [t, status]);

  const isStartOpen = useMemo(
    () =>
      dtData?.dailyTipperData &&
      (status === DailyTipperStatusType.Pending ||
        status === DailyTipperStatusType.InProgress)
        ? true
        : false,
    [status, dtData]
  );
  const isDayslostOpen = useMemo(
    () =>
      !dayslostClosed &&
      daysLostCount > 0 &&
      dtData?.dailyTipperData?.dailyResultStatus === DailyStatusType.Minus
        ? true
        : false,
    [dtData, daysLostCount, dayslostClosed]
  );

  const isOpen = useMemo(
    () => (dailyTipperMode && (isStartOpen || isDayslostOpen) ? true : false),
    [dailyTipperMode, isStartOpen, isDayslostOpen]
  );

  const [updateDaysLost, { loading: daysLostLoading, data: daysLostData }] =
    useUpdateDtDayslostLazyQuery({
      fetchPolicy: 'network-only',
    });

  const closeDaysLostDialog = () => {
    setDaysLostClosed(true);
    if (!daysLostLoading && !daysLostData) updateDaysLost();
  };

  useEffect(() => {
    if (
      !loading &&
      !isOpen &&
      dataGlobalDialog?.dialogId === GlobalDialogTypes.DAILY_TIPPER
    ) {
      setGlobalDialog(null);
    }
  }, [isOpen, dataGlobalDialog, setGlobalDialog, loading]);

  const weeklyGoalNumber = useMemo(() => {
    switch (dtWeek?.dailyTipperWeeklyProgress?.weeklyGoal) {
      case ActualLearnWeekWeeklyGoal.A_4:
        return 4;
      case ActualLearnWeekWeeklyGoal.A_5:
        return 5;
      case ActualLearnWeekWeeklyGoal.A_6:
        return 6;
      case ActualLearnWeekWeeklyGoal.A_7:
        return 7;
      default:
        return dtWeek?.dailyTipperWeeklyProgress?.weeklyGoal;
    }
  }, [dtWeek]);

  return (
    <>
      <Dialog
        data-cy-activation-code-dialog
        open={isOpen}
        classes={{ paper: classes.paper }}
        fullScreen={isDayslostOpen}
        style={{ width: isDayslostOpen ? '100%' : 'initial' }}
      >
        {isDayslostOpen && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
          >
            <IconButton
              aria-label="close"
              className={classes.closeFullscreen}
              onClick={closeDaysLostDialog}
            >
              <CloseIcon />
            </IconButton>
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gridRowGap={8}
            >
              <div className={classes.missedElipse}>
                <Typography className={classes.missedText}>
                  -{daysLostCount}
                </Typography>
              </div>
              <Typography align="center" variant="h2">
                {t('daily-tipper.result.lost-study-days')}
              </Typography>
              <Typography align="center" variant="body1">
                {t('daily-tipper.result.missed', { value: weeklyGoalNumber })}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" mb={-2}>
              <WeeklyActivity />
            </Box>
          </Box>
        )}
        {isStartOpen && !isDayslostOpen && (
          <>
            {loading ? (
              <Box display="grid" justifyItems="center" paddingY={12}>
                <CircularProgress size={44} />
              </Box>
            ) : (
              <Box minWidth={232}>
                <Box paddingBottom={7}>
                  <Box display="flex" justifyContent="center">
                    <DailyTipper />
                  </Box>
                  <Box paddingTop={3}>
                    <Typography align="center" variant="h2">
                      {t('daily-tipper.todays-daily-tipper')}
                    </Typography>
                  </Box>
                </Box>
                <AppButton
                  data-cy-start-da-btn
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  loading={loading}
                  onClick={openDailyTipper}
                >
                  {label}
                </AppButton>
              </Box>
            )}
          </>
        )}
      </Dialog>
    </>
  );
};

export default StartDailyTipper;
