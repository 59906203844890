import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { AppTabs, AppTab, TabPanel } from '@/components/tabs';
import { useGetOngoingDuelsQuery } from '@/graphql';
import { Box, IconButton, Link, Paper } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import MainLayout from '@/screens/main/components/main-layout';
import Fallback from '@/components/fallback';
import OnGoing from './ongoing';
import Completed from './completed';
import Ranking from './ranking';
import TopMenuDuel from './top-menu';
import { useDuelRulesFullScreenModal } from '@/hooks/useDuelRulesFullScreenModal';
import { useUser } from '@/contexts/user-context';
import { InfoBox } from '@/components/info-box';
import { useStyles } from './styles';

const Duels: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const classes = useStyles();
  const duelRulesFullScreenModal = useDuelRulesFullScreenModal();

  const { data, error } = useGetOngoingDuelsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [value, setValue] = React.useState(0);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  if (!!error) {
    console.error('DUELS_QUERY', error);
    return (
      <MainLayout title={t('duels.title')}>
        <Fallback />
      </MainLayout>
    );
  }

  return (
    <MainLayout
      title={t('duels.title')}
      toolbar={
        <IconButton
          color="primary"
          size="small"
          onClick={() => duelRulesFullScreenModal.open()}
        >
          <InfoOutlined htmlColor="#fff" />
        </IconButton>
      }
    >
      <div className={classes.root}>
        <Paper square elevation={0}>
          {(user?.settings?.duelMode === false ||
            !!user?.settings?.activeQuizTime?.id) && (
            <Box m={2} mb={0}>
              <InfoBox>
                <Trans
                  i18nKey={
                    user?.settings?.activeQuizTime?.id
                      ? 'home.duels.info-qt-turned-off-duel-mode'
                      : 'home.duels.info-duel-mode-off'
                  }
                  components={{
                    link1: (
                      <Link
                        to="/settings/"
                        component={RouterLink}
                        color="primary"
                      />
                    ),
                  }}
                />
              </InfoBox>
            </Box>
          )}
        </Paper>
        <TopMenuDuel
          points={data?.monthlyScore?.points || 0}
          count={data?.monthlyScore?.duels || 0}
          wins={data?.monthlyScore?.wins || 0}
          ties={data?.monthlyScore?.ties || 0}
          loss={data?.monthlyScore?.loss || 0}
        />
        <AppTabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <AppTab data-cy-ongoind-duels-tab label="Laufend" />
          <AppTab data-cy-completed-duels-tab label="Abgeschlossen" />
          <AppTab data-cy-ranking-tab label="Rangliste" />
        </AppTabs>
        <TabPanel value={value} index={0}>
          <OnGoing />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Completed />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Ranking />
        </TabPanel>
      </div>
    </MainLayout>
  );
};

export default Duels;
