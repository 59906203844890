import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    maxHeight: 164,
    width: '100%',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  image: {
    maxWidth: '100%',
    objectFit: 'contain',
  },
  progressWrap: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    margin: 'auto',
  },
}));
