import React from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const GroupIcon: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99998 4.99992C6.28665 4.99992 7.33331 3.95325 7.33331 2.66659C7.33331 1.37992 6.28665 0.333252 4.99998 0.333252C3.71331 0.333252 2.66665 1.37992 2.66665 2.66659C2.66665 3.95325 3.71331 4.99992 4.99998 4.99992ZM0.333313 8.49992C0.333313 6.94659 3.43998 6.16659 4.99998 6.16659C6.55998 6.16659 9.66665 6.94659 9.66665 8.49992V9.66659H0.333313V8.49992ZM4.99998 7.49992C3.80665 7.49992 2.45331 7.94659 1.89331 8.33325H8.10665C7.54665 7.94659 6.19331 7.49992 4.99998 7.49992ZM5.99998 2.66659C5.99998 2.11325 5.55331 1.66659 4.99998 1.66659C4.44665 1.66659 3.99998 2.11325 3.99998 2.66659C3.99998 3.21992 4.44665 3.66659 4.99998 3.66659C5.55331 3.66659 5.99998 3.21992 5.99998 2.66659ZM9.69331 6.20659C10.4666 6.76659 11 7.51325 11 8.49992V9.66659H13.6666V8.49992C13.6666 7.15325 11.3333 6.38659 9.69331 6.20659ZM11.3333 2.66659C11.3333 3.95325 10.2866 4.99992 8.99998 4.99992C8.63998 4.99992 8.30665 4.91325 7.99998 4.76659C8.41998 4.17325 8.66665 3.44659 8.66665 2.66659C8.66665 1.88659 8.41998 1.15992 7.99998 0.566585C8.30665 0.419919 8.63998 0.333252 8.99998 0.333252C10.2866 0.333252 11.3333 1.37992 11.3333 2.66659Z"
        fill="white"
      />
    </svg>
  );
};

export default GroupIcon;
