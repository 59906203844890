import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Divider,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import Lern from '@/components/icons/learn';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';

type LectureItemProps = {
  id?: string;
  isMember?: boolean;
  number?: number;
  title?: string;
  description?: string;
  link?: string | null;
  lectors?: {
    id: string;
    firstName: string;
    lastName: string;
    photo?: string | null;
    professions: {
      id: string;
      title: string;
    }[];
    specialities: {
      id: string;
      title: string;
    }[];
  }[];
  expanded?: boolean;
  classrooms?: {
    id: string;
    title: string;
  }[];
  withRating?: boolean;
  isNetworkCR?: boolean;
  handleChange: (
    numb: number
    // eslint-disable-next-line @typescript-eslint/ban-types
  ) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
};

const LectureItem: React.FC<LectureItemProps> = ({
  id,
  isMember,
  number,
  title,
  description,
  link,
  lectors,
  expanded,
  withRating,
  classrooms,
  isNetworkCR,
  handleChange,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const descriptionArr = description?.split('\r');

  return (
    <>
      <Accordion
        elevation={0}
        expanded={expanded}
        onChange={handleChange(number || 0)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2a-content"
          id={id}
        >
          <Box my={expanded ? 0 : 2}>
            <Typography variant="subtitle1">
              {!withRating && `${number}. `} {title}
            </Typography>
            {withRating && classrooms?.length && (
              <Typography variant="caption">
                {t('common.classrooms')}:{' '}
                {classrooms?.map((it, idx) =>
                  idx > 0 && idx !== classrooms.length
                    ? ', ' + it.title
                    : it.title
                )}
              </Typography>
            )}
          </Box>
        </AccordionSummary>
        {withRating && (
          <Box mx={-6} mt={3} mb={4}>
            <Divider />
          </Box>
        )}

        <AccordionDetails>
          <Box width="100%">
            {descriptionArr?.map((item) => (
              <Typography key={item} variant="body1">
                <span dangerouslySetInnerHTML={{ __html: item }} />
              </Typography>
            ))}
            {!isNetworkCR &&
              lectors?.map((lector) => (
                <Link
                  to={`/classrooms/lector/${lector.id}`}
                  key={lector.id}
                  className="text-decoration-none"
                >
                  <Box display="flex" alignItems="center" mt={5}>
                    {lector?.photo && (
                      <Avatar
                        src={lector.photo}
                        alt={lector.firstName}
                        className={classes.lectorAvatar}
                      />
                    )}
                    <Box display="grid" gridRowGap={4}>
                      <Typography variant="subtitle2">
                        {lector.firstName} {lector.lastName}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.textSecondary}
                      >
                        {lector?.professions.map((it) => (
                          <span key={it.id}>{it.title}, </span>
                        ))}
                        {lector?.specialities.map((it) => (
                          <span key={it.id}>{it.title} </span>
                        ))}
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              ))}
            {link && isMember && (
              <Box mt={8} mb={5} mx={-4}>
                <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  target="_blank"
                  href={link}
                  startIcon={<Lern />}
                  className={classes.btnOutlined}
                >
                  {t('class-room.learning-link')}
                </Button>
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default LectureItem;
