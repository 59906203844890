import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useParams,
  useHistory,
  Redirect,
  Link as RouterLink,
} from 'react-router-dom';
import { sortBy } from 'lodash';
import { Box, Button, Paper, Typography } from '@material-ui/core';

import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { useUser } from '@/contexts/user-context';
import {
  DuelStatus,
  GetActiveDuelsDocument,
  SubscribeNotificationCreatedDocument,
  useCreateUserRoundMutation,
  UserDuelDocument,
  useUserDuelQuery,
} from '@/graphql';
import { useLazyQuery } from '@apollo/client';
import { DUELS_QUERY } from '@/apollo/queries';

import Fallback from '@/components/fallback';
import { useAnyGuestionModal } from '@/hooks/useModal';
import AppButton from '@/components/app-button';
import ResultDialog from '../../components/result-dialog';
import OverviewRoundsList from '../../components/overview-rounds-list';

const Overview: React.FC = ({ ...rest }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useUser();
  const { duelId } = useParams<{ duelId: string }>();
  const anyGuestionModal = useAnyGuestionModal();

  const { data, error, subscribeToMore, refetch } = useUserDuelQuery({
    variables: { duelId },
  });

  subscribeToMore({
    document: SubscribeNotificationCreatedDocument,
    updateQuery: (prev) => {
      refetch();
      return prev;
    },
  });

  const [getDuels, duelsResponse] = useLazyQuery(DUELS_QUERY, {
    fetchPolicy: 'network-only',
  });

  const rounds = useMemo(
    () => (!!data?.duelData?.rounds ? data?.duelData?.rounds : []),
    [data]
  );
  const activeRoundNumber = rounds.length;

  const isAllQuestionsAnswered = useMemo(
    () =>
      !!data?.duelData?.rounds &&
      data.duelData.rounds[activeRoundNumber - 1]?.userQuestions.every(
        (it) => !!it.userAnswer
      ),
    [activeRoundNumber, data]
  );

  const isYourTurn =
    data?.duelData?.isOnboardingDuel || data?.duelData?.userScore?.youTurn;

  const isCompleted = useMemo(
    () =>
      data?.duelData?.status?.valueOf() === DuelStatus.Finished ||
      data?.duelData?.status?.valueOf() === DuelStatus.Ignored,
    [data]
  );

  const [createRound, { loading: createLoading }] =
    useCreateUserRoundMutation();
  const handleStart = useCallback(async () => {
    try {
      const { data } = await createRound({
        variables: { duelId },
        refetchQueries: [
          { query: UserDuelDocument, variables: { duelId } },
          { query: GetActiveDuelsDocument },
        ],
      });
      if (!!data?.createRound?.round) {
        const roundId = data.createRound.round.id;
        const firstQ = sortBy(
          (data.createRound.round.userQuestions || []).filter(
            (it) => it.user?.id === user?.id
          ),
          ['questionNumber']
        )[0];
        if (!firstQ?.id) {
          console.error('NO_QUESTION_FOUND');
          return;
        }
        return history.push(
          `/duel/${duelId}/round/${roundId}/question/${firstQ?.id}`
        );
      }
    } catch (error) {
      anyGuestionModal.open();
      console.error('CREATE_ROUND', error);
    }
  }, [anyGuestionModal, createRound, duelId, history, user]);

  const goToNextRound = useCallback(() => {
    if (rounds.length) {
      const roundId = rounds[activeRoundNumber - 1]?.id;
      const firstQ = sortBy(
        (rounds[activeRoundNumber - 1]?.userQuestions || []).filter(
          (it) => it.user?.id === user?.id
        ),
        ['questionNumber']
      )[0];
      if (!firstQ?.id) {
        console.error('NO_QUESTION_FOUND');
        return;
      }
      return history.push(
        `/duel/${duelId}/round/${roundId}/question/${firstQ?.id}`
      );
    }
  }, [rounds, duelId, history, user, activeRoundNumber]);

  if (!!error) {
    console.error('DUEL_QUERY', error);
    return <Fallback />;
  }

  if (duelsResponse.called && (duelsResponse.data || duelsResponse.error))
    return <Redirect to="/duels" />;

  return (
    <>
      {data && isCompleted && (
        <ResultDialog
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          status={data?.duelData?.status as any}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          userScore={data?.duelData?.userScore as any}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          opponentScore={data?.duelData?.opponentScore as any}
          maxRoundsCount={data?.duelData?.rounds?.length}
          onClose={() => {
            getDuels();
            if (data?.duelData?.isOnboardingDuel)
              history.push(
                `/onboard-keep-learning?classroomSessionId=${data.duelData.session?.id}`
              );
          }}
        />
      )}
      <Box
        clone
        data-cy-overview
        display="grid"
        gridTemplateRows="1fr auto"
        paddingY={1}
        flexGrow={1}
      >
        <Paper elevation={0}>
          <OverviewRoundsList maxRoundsCount={3} />
          <Box data-cy-create-round px={2} pb={5}>
            {(isYourTurn || isAllQuestionsAnswered) && !isCompleted ? (
              <AppButton
                fullWidth
                color="primary"
                variant="contained"
                loading={createLoading}
                disabled={rounds.length > 3}
                onClick={
                  isAllQuestionsAnswered || activeRoundNumber === 0
                    ? handleStart
                    : goToNextRound
                }
              >
                {isAllQuestionsAnswered || activeRoundNumber === 0
                  ? activeRoundNumber + 1
                  : activeRoundNumber}
                . {t('duels.start-next-round')}
              </AppButton>
            ) : (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                to="/duels"
                component={RouterLink}
              >
                {t('duels.go-to-duels')}
              </Button>
            )}
            {!isCompleted && (
              <Box mt={3} mb={-2}>
                <Typography
                  variant="caption"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#647485',
                  }}
                >
                  {!isYourTurn && !isAllQuestionsAnswered && (
                    <HourglassEmptyIcon
                      style={{
                        width: 16,
                        height: 16,
                        marginRight: 4,
                      }}
                    />
                  )}
                  {t(
                    isYourTurn || isAllQuestionsAnswered
                      ? 'common.duel.your-turn'
                      : 'duels.opponent-turn'
                  )}
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default Overview;
