import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(5),
    marginBottom: theme.spacing(1),
    borderBottom: '1px solid #E6EEFA',
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },
  divider: {
    height: 1,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: '#E6EEFA',
  },
}));
