import gql from 'graphql-tag';

export const USER_FRAGMENT = gql`
  fragment user_fragment on UserType {
    id
    avatar
    firstName
    lastName
    username
    email
    dateOfBirth
    gender
    postcode
    dateJoined
    confirmationToken
    profession {
      id
      title
      type {
        id
        title
        type
      }
    }
    anotherProfession
    speciality {
      id
      title
    }
    workplace {
      id
      title
    }
    anotherWorkplace
    efnNumber
    educationLevel {
      id
      title
    }
    university {
      id
      title
    }
    semester {
      id
      title
    }
    registrationNumber
    medicineStudyBegin
    studyBegin {
      id
      title
    }
    studyYear {
      id
      title
    }
    classRoomLearningSession {
      id
    }
    examYear
    professionId
    specialityId
    workplaceId
    educationLevelId
    universityId
    semesterId
    studyBeginId
    studyYearId
    network {
      title
    }
    isPremium
    isInAppPremium
    isPromotionPremium
    isUserForTest
    referralCount
    customer {
      id
      subscription {
        id
        subscriptionType
        status
        upgradeStatus
        currentPeriodEnd
        requestCancel
        lastError
        lastErrorCode
      }
      defaultCard {
        brand
        last4
      }
    }
    subscribeToNews
  }
`;

export const USER_SETTINGS_FRAGMENT = gql`
  fragment user_settings_fragment on SettingsType {
    id
    duelMode
    goal
    dailyTipperMode
    pushNotificationMode
    pushNotificationDailyTipperMode
    pushNotificationDuelMode
    pushNotificationNewChapterMode
    pushNotificationPremiumMode
    pushNotificationQuizTimeMode
    isDailyTipperRulesRead
    isDuelRulesRead
    isQuizDuelRulesRead
    isLikeHumeoQuestionRead
    isIntroCompleted
    isClassRoomIntroCompleted
    isCookieAccepted
    isMarketingCookieAccepted
    isIosInstallationPopupSkipped
    isChromeInstallationPopupSkipped
    activeQuizTime {
      id
    }
  }
`;

export const LECTURE_FRAGMENT = gql`
  fragment lecture_fragment on LectureType {
    id
    title
    description
    lectureLink
    correctAnswersGoal
    questions {
      id
    }
    lectors {
      id
      firstName
      lastName
      photo
      professions {
        id
        title
      }
      specialities {
        id
        title
      }
    }
  }
`;

export const CLASSROOM_LS_FRAGMENT = gql`
  fragment classroom_ls_fragment on ClassRoomLearningSessionType {
    id
    status
    startDate
    finishDate
    isForPremiumOnly
    duration
    classRoom {
      id
      title
      classRoomImage
      backgroundImage
      enrollmentType
      aggregatedRate {
        id
        averageRate
        feedbackCount
      }
      certificate {
        id
        points
      }
      organizer {
        id
        name
      }
    }
  }
`;

export const USER_QUESTION_FRAGMENT = gql`
  fragment user_question_fragment on DuelRoundQuestionAnswerType {
    id
    questionNumber
    user {
      id
    }
    question {
      id
      value
      questionImage
      explanation
      sortedLinks {
        id
        url
        linkCategory {
          id
          title
        }
      }
      answers {
        id
        value
      }
    }
    userAnswer {
      id
      value
    }
    correctAnswer {
      id
      value
    }
  }
`;

export const DAILY_TIPPER_QUESTION_FRAGMENT = gql`
  fragment daily_tipper_question_fragment on DailyTipperQuestionAnswerType {
    id
    questionNumber
    question {
      id
      value
      questionImage
      explanation
      sortedLinks {
        id
        url
        linkCategory {
          id
          title
        }
      }
      answers {
        id
        value
      }
    }
    userAnswer {
      id
      value
    }
    correctAnswer {
      id
      value
    }
    actualLearningWeek {
      id
      weekProgress
    }
  }
`;

export const USER_SCORE_FRAGMENT = gql`
  fragment user_score_fragment on DuelResultUserDataType {
    id
    user {
      id
      avatar
      username
    }
    classRoomLearningSession {
      id
      classRoom {
        id
        title
      }
    }
    initiator
    youTurn
    duelScore
    userStatus
  }
`;

export const QUIZTIME_LIST_FRAGMENT = gql`
  fragment quiztime_list_fragment on QuizTimeSessionType {
    id
    status
    startDate
    quizTimeDuration
    startDateTime
    finishDateTime
    isForPremiumOnly
    userQuizSessionSetting {
      id
      userStatus
      quizSessionLabelStatus
    }
    quizTime {
      id
      quizTimeImage
      title
      organizer {
        id
        name
      }
    }
  }
`;

export const QUIZTIME__HOMELIST_FRAGMENT = gql`
  fragment quiztime_homelist_fragment on QuizTimeSessionType {
    id
    startDate
    startDateTime
    finishDateTime
    status
    quizTimeDuration
    duelDuration
    quizTime {
      id
      title
      quizTimeImage
    }
  }
`;

export const QUIZ_PRIZE_ITEM = gql`
  fragment quiz_prize_item on QuizSessionPrizeType {
    id
    placeNumber
    prizeType
    roles
    quizTimePrize {
      id
      title
      description
      prizeImage
    }
  }
`;
