import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { Box, Paper } from '@material-ui/core';

import * as Form from '@/components/form';
import AppButton from '@/components/app-button';
import { useUser } from '@/contexts/user-context';
import { useUpdateUserProfileMutation } from '@/graphql';

import EditBtn from '../../components/edit-btn';
import Loading from '@/components/loading';

interface Form {
  anotherProfession: string;
}

const DoctorInfo: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const toggleMode = useCallback(
    (value: boolean) => () => {
      setIsEditMode(value);
    },
    []
  );

  const [updateUserProfile, { loading: saveLoading }] =
    useUpdateUserProfileMutation();

  const { register, errors, watch, setValue } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      anotherProfession: user?.anotherProfession || '',
    },
  });

  useEffect(() => {
    register({ name: 'anotherProfession' }, {});
  }, [register]);

  const save = useCallback(async () => {
    await updateUserProfile({
      variables: {
        anotherProfession: watch('anotherProfession'),
      },
    });

    setIsEditMode(false);
  }, [updateUserProfile, watch]);

  const isValid = useMemo(() => {
    return isEmpty(errors);
  }, [errors]);

  const isDirty = useMemo(() => {
    const { anotherProfession } = watch();

    return anotherProfession !== user?.anotherProfession;
  }, [user, watch]);

  const changeField = useCallback(
    (prop: keyof Form) => (event: React.ChangeEvent<{ value: unknown }>) => {
      const value = String(event.target.value);
      const options = {
        shouldDirty: true,
        shouldValidate: true,
      };

      switch (prop) {
        case 'anotherProfession': {
          setValue('anotherProfession', value.replace(/[^a-z]/gi, ''), options);
        }
      }
    },
    [setValue]
  );

  if (!user) return <Loading />;

  return (
    <Box clone height="100%" boxSizing="border-box" paddingY={7} paddingX={4}>
      <Paper elevation={0}>
        {isEditMode ? (
          <>
            <Form.Row>
              <Form.TextField
                fullWidth
                required
                autoFocus
                data-cy-another-profession
                variant="outlined"
                label={t('fields.another-profession.label')}
                errors={errors.anotherProfession}
                value={watch('anotherProfession').replace(/[^a-z]/gi, '')}
                name="anotherProfession"
                onChange={changeField('anotherProfession')}
              />
            </Form.Row>
            <AppButton
              fullWidth
              data-cy-save-changes
              color="primary"
              variant="contained"
              disabled={!isValid || !isDirty}
              loading={saveLoading}
              onClick={save}
            >
              {t('common.save-changes')}
            </AppButton>
          </>
        ) : (
          <>
            <Form.InfoField label={t('fields.profession.label')}>
              {user?.profession?.title}
            </Form.InfoField>
            <Form.InfoField label={t('fields.speciality.label')}>
              {user?.speciality?.title}
            </Form.InfoField>
            {user?.anotherProfession && (
              <Form.InfoField label={t('fields.another-profession.label')}>
                {user?.anotherProfession}
              </Form.InfoField>
            )}
            <EditBtn onClick={toggleMode(true)} />
          </>
        )}
      </Paper>
    </Box>
  );
};

export default DoctorInfo;
