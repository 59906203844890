import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { isNumber } from 'lodash';
import {
  Box,
  Paper,
  Typography,
  LinearProgress,
  Chip,
} from '@material-ui/core';

import { useStyles } from './styles';
import DurationIcon from '@/components/icons/duration';
import StarIcon from '@/components/icons/star';
import ChipIndicator from '@/components/chip-indicator';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ClassroomTypes } from '../../../../type';
import LekCountdown from '../lek-countdown';
import { SS_KEY_MEMO_CUR_TAB } from '@/utils/constants';
import SharingButton from '../sharing-button';

interface ClassroomCardProps {
  id: string;
  title: string;
  organizerName?: string | null;
  classRoomImage?: string | null;
  specialityGroupIcon?: string | null;
  classRoomType?: number | null;
  certificate?: number | undefined;
  // averageRate?: number | null;
  // feedbackCount?: number | null;
  startDate?: string;
  finishDate?: string;
  link?: string;
  isOngoing?: boolean;
  indicator?: boolean;
  isForPremiumOnly?: boolean;
  duration?: number | null;
  deepLinkHash?: string | null;
  correctAnswersGoal?: number | null;
  questionsAmount?: number | null;
  correctAnswersAmount?: number | null;
  isOnHomepage?: boolean | null;
}
const twoDaysInMs = 172800000;
const ClassroomCard: React.FC<ClassroomCardProps> = ({
  children,
  id,
  title,
  organizerName,
  classRoomImage,
  specialityGroupIcon,
  certificate,
  startDate,
  finishDate,
  // averageRate,
  // feedbackCount,
  correctAnswersGoal,
  questionsAmount,
  correctAnswersAmount,
  link,
  isOngoing,
  indicator,
  classRoomType,
  isForPremiumOnly,
  isOnHomepage,
  duration,
  deepLinkHash,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const range = useMemo(() => {
    const start = startDate
      ? DateTime.fromISO(startDate).toFormat('dd.MM.yyyy')
      : '';
    const end = finishDate
      ? DateTime.fromISO(finishDate).toFormat('dd.MM.yyyy')
      : '';

    return `${start} - ${end}`;
  }, [finishDate, startDate]);

  const memorizeCurrentTab = () => {
    sessionStorage.setItem(SS_KEY_MEMO_CUR_TAB, 'true');
  };

  const totalProgress = useMemo(() => {
    if (
      isNumber(correctAnswersGoal) &&
      isNumber(questionsAmount) &&
      isNumber(correctAnswersAmount)
    ) {
      let progressGoal =
        correctAnswersGoal > 0
          ? (correctAnswersGoal * 100) / questionsAmount
          : questionsAmount;
      if (progressGoal > 100) {
        progressGoal = 100;
      }
      let progressAnswers =
        correctAnswersAmount > 0
          ? (correctAnswersAmount * 100) / questionsAmount
          : 0;
      if (progressAnswers > 100) {
        progressAnswers = 100;
      }

      return (
        <Box
          pl={isOnHomepage ? 0 : 4}
          pt={isOnHomepage ? 0 : 7}
          pb={isOnHomepage ? 0 : 3}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="overline">
              {t('classrooms.learning-goal')}
            </Typography>
            <Box display="flex" alignItems="center" className={classes.goal}>
              <Typography
                color={
                  correctAnswersAmount >= correctAnswersGoal
                    ? 'primary'
                    : 'inherit'
                }
                variant="subtitle2"
              >
                {correctAnswersGoal}
              </Typography>
              <StarIcon
                style={{ marginLeft: 6 }}
                color={
                  correctAnswersAmount >= correctAnswersGoal
                    ? 'primary'
                    : 'inherit'
                }
              />
            </Box>
          </Box>
          <Box marginTop={3} className={classes.progressWrap}>
            <LinearProgress
              style={{ height: 4, borderRadius: 4 }}
              variant="determinate"
              value={progressAnswers}
              classes={{
                barColorPrimary: classes.barColorPrimary,
                barColorSecondary: classes.barColorSecondary,
              }}
            />
            <Box
              className={classes.progressGoal}
              style={{ left: `calc(${progressGoal}% - 6px)` }}
            >
              <span className={classes.goalMark} />
              <StarIcon style={{ marginTop: 4 }} />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" marginTop={3}>
            <Typography color="inherit" variant="overline">
              {correctAnswersAmount}
            </Typography>
            <Typography className={classes.caption} variant="overline">
              /{questionsAmount}
            </Typography>
          </Box>
        </Box>
      );
    } else {
      return <></>;
    }
  }, [
    classes,
    t,
    correctAnswersAmount,
    correctAnswersGoal,
    questionsAmount,
    isOnHomepage,
  ]);

  const timeToEnd = useMemo(() => {
    const timeLeft = DateTime.fromISO(finishDate || '')
      .endOf('day')
      .diffNow();
    const millisecondsLeft = timeLeft.milliseconds;
    if (millisecondsLeft < twoDaysInMs && millisecondsLeft > 0) {
      return t('common.last-day');
    } else if (timeLeft.toFormat('d') === '2') {
      return t('common.penultimate-day');
    }
  }, [t, finishDate]);

  return (
    <Link to={link || '#'} style={{ textDecoration: 'none' }}>
      <Paper
        elevation={0}
        className={classes.card}
        onClick={memorizeCurrentTab}
      >
        <Box
          pt={isOnHomepage ? 1 : 2}
          pb={isOnHomepage ? 1 : 2}
          paddingRight={classRoomType === ClassroomTypes.NETWORK ? 8 : 6}
          paddingLeft={isOnHomepage ? 0 : 2}
          marginTop={isOnHomepage ? 0 : 2}
          position="relative"
          {...rest}
        >
          {!isOnHomepage &&
            classRoomType === ClassroomTypes.NETWORK &&
            !!deepLinkHash && (
              <Box
                style={{
                  position: 'absolute',
                  right: 10,
                  top: 10,
                  color: '#3498DB',
                }}
              >
                <SharingButton
                  crTitle={title}
                  deepLinkHash={deepLinkHash}
                  sessionId={id}
                />
              </Box>
            )}
          {indicator === false && classRoomType === ClassroomTypes.BLENDED && (
            <Box
              display="flex"
              mb={2}
              mr={-4}
              px={2}
              py={0.5}
              className={classes.status}
            >
              <Typography variant="caption" style={{ color: '#4A647C' }}>
                {t('common.pause')}
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="flex-start">
            <Box position="relative" maxHeight={140}>
              {isOnHomepage && timeToEnd && (
                <Box
                  style={{
                    position: 'absolute',
                    left: 4,
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  <ChipIndicator title={timeToEnd} />
                </Box>
              )}
              <LazyLoadImage
                effect="opacity"
                wrapperClassName={classes.cardPicture}
                useIntersectionObserver
                src={classRoomImage || ''}
                alt={title}
                style={{
                  objectPosition: `${
                    specialityGroupIcon ? 'right' : 'center'
                  } center`,
                }}
              />

              {specialityGroupIcon && (
                <LazyLoadImage
                  effect="blur"
                  wrapperClassName={classes.cardIcon}
                  useIntersectionObserver
                  src={specialityGroupIcon}
                  alt={title}
                />
              )}
              {(classRoomType === ClassroomTypes.BLENDED ||
                classRoomType === ClassroomTypes.LEK) && (
                <Box className={classes.typeLabel}>
                  <Typography color="inherit" variant="overline">
                    {classRoomType === ClassroomTypes.BLENDED && 'BLENDED'}
                    {classRoomType === ClassroomTypes.LEK && 'LEK'}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent={
                isOnHomepage && classRoomType !== ClassroomTypes.LEK
                  ? 'center'
                  : 'space-between'
              }
              paddingLeft={4}
              flexGrow={1}
              alignSelf="stretch"
            >
              <Box>
                {!isOnHomepage && (
                  <Box display="flex" alignItems="center" mb={1}>
                    {/* {averageRate ? (
                  <>
                    <StarIcon htmlColor="#F2C94D" style={{ marginRight: 6 }} />
                    <Typography color="inherit" variant="caption">
                      {averageRate}
                    </Typography>
                    <Typography variant="caption" style={{ marginLeft: 3 }}>
                      ({feedbackCount})
                    </Typography>
                  </>
                ) : (
                  <Chip label="NEW" color="primary" size="small" />
                  )}
                */}
                    {isForPremiumOnly &&
                      classRoomType === ClassroomTypes.NETWORK && (
                        <Box mr={1.2}>
                          <Chip
                            label="PREMIUM"
                            color="default"
                            variant="outlined"
                            size="small"
                            className={classes.chipGolden}
                          />
                        </Box>
                      )}
                    {isNumber(certificate) && (
                      <Box>
                        <Chip label={'CME:' + certificate} size="small" />
                      </Box>
                    )}
                  </Box>
                )}
                <Typography
                  variant="subtitle1"
                  className="text-ellipsis-3lines"
                >
                  {title}
                </Typography>
                {(!isOnHomepage || classRoomType === ClassroomTypes.LEK) && (
                  <Box mt={1}>
                    <Typography
                      color="inherit"
                      variant="caption"
                      className="text-ellipsis-3lines"
                    >
                      {organizerName}
                    </Typography>
                  </Box>
                )}
              </Box>
              {isOnHomepage && classRoomType !== ClassroomTypes.LEK && (
                <Box mt={2}>{totalProgress}</Box>
              )}

              <Box>
                {!isOnHomepage && (
                  <Box className={classes.duration}>
                    <Box display="flex" alignItems="center" marginRight={2}>
                      <DurationIcon htmlColor="currentColor" />
                    </Box>
                    <Typography color="inherit" variant="overline">
                      {duration
                        ? `${duration} ${
                            duration > 1 ? t('common.days') : t('common.day')
                          }`
                        : range}
                    </Typography>
                  </Box>
                )}
                {classRoomType === ClassroomTypes.LEK && isOnHomepage && (
                  <LekCountdown sessionId={id} />
                )}
              </Box>
            </Box>
          </Box>
          {!isOnHomepage &&
            classRoomType !== ClassroomTypes.LEK &&
            totalProgress}
          {children}
        </Box>
      </Paper>
    </Link>
  );
};

export default ClassroomCard;
