import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    color: '#929DA8',
  },
  icon: {
    paddingRight: theme.spacing(2),
  },
}));
