import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import AlertIcon from '@/components/icons/alert';

type ErrorBlockProps = {
  message: string;
};

const ErrorBlock: React.FC<ErrorBlockProps> = ({ message }) => {
  const classes = useStyles();

  return (
    <Box marginTop={3} className={classes.errorBox}>
      <AlertIcon />
      <Box ml={2}>
        <Typography variant="body2">{message}</Typography>
      </Box>
    </Box>
  );
};

export default ErrorBlock;
