import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(14),
  },
  btnLeave: {
    padding: 0,
  },
  pushLink: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'inherit',
    textDecoration: 'none',
  },
  helpText: {
    maxWidth: '85%',
    color: '#647485',
  },
}));
