import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import { useStyles } from './styles';

interface FeedbackButtonProps {
  questionId: string | null | undefined;
  handleClick: (value: boolean) => void;
}

const FeedbackButton: React.FC<FeedbackButtonProps> = ({
  questionId,
  handleClick,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const search = history.location.search;
  const query = new URLSearchParams(search);

  function handleFeedbackBtnClick(
    questionId: FeedbackButtonProps['questionId']
  ) {
    handleClick(true);
    query.append('isFeedbackVisible', 'true');
    history.replace({ search: query.toString() });
    datadogRum.addAction('FFQ open btn', {
      questionId,
    });
  }

  return (
    <Box display="flex" justifyContent="center" mt={3}>
      <Button
        variant="text"
        color="primary"
        className={classes.link}
        onClick={() => handleFeedbackBtnClick(questionId)}
      >
        {t('common.give-feedback')}
      </Button>
    </Box>
  );
};

export default FeedbackButton;
