import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  topbar: {
    left: 0,
    right: 0,
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  avatar: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    fontSize: '1.75rem',
  },
  score: {
    marginTop: theme.spacing(3),
  },
  label: {
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  content: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: theme.spacing(2),
  },
  classroomIndicator: {
    padding: '8px 16px',
    background: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
}));
