import React, { useState, useMemo } from 'react';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import { ReactComponent as EmptyActivityTabIcon } from './index.svg';
import {
  QuizTimeSessionStatus,
  useGetActiveQuizDuelsQuery,
  useQuizScoreQuery,
  useQuizTimeDetailsQuery,
  UserQuizSessionSettingsUserStatus,
} from '@/graphql';
import ResultsTopMenu from '../../results-top-menu';
import DuelsList from '../../duels-list';
import { useCreateQuizDuel } from '@/hooks/useCreateQuizDuel';
import QuizMembersList from '../participants-tab/members-list';
import { useTranslation } from 'react-i18next';
import RemainingTime from '@/components/remaining-time';
import { useUser } from '@/contexts/user-context';
import { useStyles } from './styles';
import { DateTime } from 'luxon';
import { HEIGHT_TOPBAR_PX, QUIZTIME_MAX_DUEL_COUNT } from '@/utils/constants';

const _24_HOURS_IN_MILLIS = 86400000;

type QuizTimeActivitiesTabProps = {
  quizStatus: QuizTimeSessionStatus | null | undefined;
  sessionId: string;
  userStatus?: UserQuizSessionSettingsUserStatus | null | undefined;
};

const QuizTimeActivitiesTab: React.FC<QuizTimeActivitiesTabProps> = ({
  quizStatus,
  sessionId,
  userStatus,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useUser();

  const [numberOfMembers, setNumberOfMembers] = useState(0);
  const [isDuelsDisabled, setDuelsDisabled] = useState(true);
  const { data: quizTimeData } = useQuizTimeDetailsQuery({
    variables: { quizSessionId: sessionId },
  });
  const { data: quizScoreData } = useQuizScoreQuery({
    variables: {
      quizSessionId: sessionId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const { handleCreateQuizDuel, creatingLoading } = useCreateQuizDuel({
    sessionId,
  });
  const { data: activeQuizDuelsData } = useGetActiveQuizDuelsQuery({
    variables: { quizSessionId: sessionId },
    fetchPolicy: 'cache-and-network',
  });
  const activeDuelCount = activeQuizDuelsData?.activeQuizDuels?.length;

  const yourDuels = useMemo(
    () =>
      (activeQuizDuelsData?.activeQuizDuels || []).filter((it) => {
        const youTurn =
          it?.round?.userQuestions?.filter(
            (x) => x.user?.id === user?.id && !!x.userAnswer?.id
          ).length !== 3 ||
          it?.round.userQuestions.filter((x) => !!x.userAnswer?.id).length ===
            6;
        return youTurn;
      }),
    [activeQuizDuelsData, user]
  );

  const opponentDuels = useMemo(
    () =>
      (activeQuizDuelsData?.activeQuizDuels || []).filter((it) => {
        const youTurn =
          it?.round?.userQuestions?.filter(
            (x) => x.user?.id === user?.id && !!x.userAnswer?.id
          ).length !== 3 ||
          it?.round.userQuestions.filter((x) => !!x.userAnswer?.id).length ===
            6;
        return !youTurn;
      }),
    [activeQuizDuelsData, user]
  );

  return (
    <div>
      <Box
        minHeight={
          !activeDuelCount
            ? `calc(100vh - ${HEIGHT_TOPBAR_PX}px - 200px - 48px)`
            : 0
        }
        style={{
          backgroundColor: 'white',
        }}
      >
        {userStatus === UserQuizSessionSettingsUserStatus.InProgress ||
        userStatus === UserQuizSessionSettingsUserStatus.Paused ||
        userStatus === UserQuizSessionSettingsUserStatus.Finished ||
        (userStatus === UserQuizSessionSettingsUserStatus.Joined &&
          quizStatus === QuizTimeSessionStatus.InProgress) ? (
          <>
            <ResultsTopMenu
              quizSessionId={sessionId}
              points={quizScoreData?.quizDuelScore?.points}
              duels={quizScoreData?.quizDuelScore?.duels}
              rank={quizScoreData?.quizDuelScore?.rank}
              wins={quizScoreData?.quizDuelScore?.wins}
              ties={quizScoreData?.quizDuelScore?.ties}
              loss={quizScoreData?.quizDuelScore?.loss}
              hasDropdown
            />

            {activeDuelCount ? (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px={4}
                py={3}
              >
                <Box display="inline-flex">
                  <Typography variant="subtitle2">
                    {t('common.duel.ongoing-duels')}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ marginLeft: 4, color: '#2B75B3' }}
                  >
                    {activeDuelCount}/{QUIZTIME_MAX_DUEL_COUNT}
                  </Typography>
                </Box>
                <RemainingTime>{t('common.duel.remaining-time')}</RemainingTime>
              </Box>
            ) : quizStatus !== QuizTimeSessionStatus.Finished &&
              quizStatus !== QuizTimeSessionStatus.WaitingForFinish ? (
              <Box px={4} py={5} textAlign="center">
                <Typography variant="caption" color="textSecondary">
                  {t('common.duel.no-active-duels')}
                </Typography>
              </Box>
            ) : (
              <></>
            )}
            {quizStatus === QuizTimeSessionStatus.InProgress &&
              (userStatus === UserQuizSessionSettingsUserStatus.InProgress ||
                userStatus === UserQuizSessionSettingsUserStatus.Paused) && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleCreateQuizDuel}
                  id="btnAnimatedBySnack"
                  disabled={
                    isDuelsDisabled || creatingLoading || !numberOfMembers
                  }
                  className={classes.playDuelBtn}
                >
                  {t('classrooms.play-random-duel')}
                </Button>
              )}
          </>
        ) : (
          <Box className={classes.emptyTab}>
            <EmptyActivityTabIcon style={{ marginBottom: 20 }} />
            <Typography align="center" variant="body2">
              {t(
                +DateTime.fromISO(
                  quizTimeData?.quizTimeSession?.startDateTime
                ).diffNow() < _24_HOURS_IN_MILLIS
                  ? 'quiztime.inner-tabs.active.empty-title'
                  : 'quiztime.inner-tabs.active.empty-title2'
              )}
            </Typography>
          </Box>
        )}
      </Box>
      {!!activeDuelCount && (
        <Box px={2} pb={2}>
          {!!yourDuels.length && (
            <Paper
              elevation={0}
              style={{ minHeight: !opponentDuels.length ? '40vh' : 0 }}
            >
              <Box paddingTop={5} paddingX={6} mt={2}>
                <Typography variant="overline">
                  {t('duels.your-turn')}
                </Typography>
              </Box>
              <DuelsList
                duelsListData={yourDuels as any}
                sessionId={sessionId}
                duelDuration={quizTimeData?.quizTimeSession?.duelDuration}
              />
            </Paper>
          )}
          {!!opponentDuels.length && (
            <Paper
              elevation={0}
              style={{ minHeight: !yourDuels.length ? '40vh' : 0 }}
            >
              <Box paddingTop={5} paddingX={6} mt={2}>
                <Typography variant="overline">
                  {t('duels.opponent-turn')}
                </Typography>
              </Box>
              <DuelsList
                duelsListData={opponentDuels as any}
                sessionId={sessionId}
                duelDuration={quizTimeData?.quizTimeSession?.duelDuration}
              />
            </Paper>
          )}
        </Box>
      )}
      <div style={{ visibility: 'hidden', height: 0, overflow: 'hidden' }}>
        <QuizMembersList
          quizSessionId={sessionId}
          getNumberOfMembers={setNumberOfMembers}
          getIsDuelsDisabled={setDuelsDisabled}
          navBtnActiveId={0}
        />
      </div>
    </div>
  );
};

export default QuizTimeActivitiesTab;
