import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as ThumbUpIcon } from './thumb-up.svg';
import { ReactComponent as ThumbDownIcon } from './thumb-down.svg';

import { useStyles } from './styles';

interface RatingInfoProps {
  type: 'success' | 'error';
  count?: number;
}

const RatingInfo: React.FC<RatingInfoProps> = ({ type, count, children }) => {
  const classes = useStyles();
  const icon = type === 'success' ? <ThumbUpIcon /> : <ThumbDownIcon />;
  const countClass =
    type === 'success' ? classes.successCount : classes.errorCount;
  const iconClass =
    type === 'success' ? classes.successIcon : classes.errorIcon;

  return (
    <Paper variant="outlined">
      <Box
        py={3.5}
        px={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h5" className={countClass}>
          {count}
        </Typography>
        <Box className={iconClass}>{icon}</Box>
        <Typography variant="caption">{children}</Typography>
      </Box>
    </Paper>
  );
};

export default RatingInfo;
