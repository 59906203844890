import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import HourGlassIcon from '@/components/icons/hourglass';

interface SubsStatusIncompleteProps {
  isSubsStatusIncomplete: boolean | null | undefined;
  subscriptionType: string | undefined;
}
const SubsStatusIncomplete: React.FC<SubsStatusIncompleteProps> = ({
  isSubsStatusIncomplete,
  subscriptionType,
}) => {
  const { t } = useTranslation();

  if (!isSubsStatusIncomplete) {
    return null;
  }

  return (
    <Box
      clone
      px={6}
      py={4}
      mt={5}
      style={{ backgroundColor: 'rgba(226, 245, 253, 0.48)' }}
    >
      <Paper variant="outlined">
        <Box display="grid" gridRowGap={8}>
          <Typography variant="caption">
            {t('fields.next-membership.label')}
          </Typography>
          <Typography variant="subtitle1">
            Premium (
            {subscriptionType?.includes('monthly') && !isSubsStatusIncomplete
              ? t('payment.monthly')
              : t('payment.annual')}
            )
          </Typography>
          <Box
            px={3}
            py={2}
            style={{
              background: '#FEF9F4',
              border: '1px solid #F2994A',
              borderRadius: 4,
            }}
          >
            <Typography variant="body2">
              <Box component="span" display="flex" alignItems="center">
                <HourGlassIcon style={{ marginRight: 13 }} />{' '}
                {t(`payment.processing.title`)}.
              </Box>
            </Typography>
          </Box>
          <Typography variant="body2">
            {t(`payment.processing.content`)}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};
export default SubsStatusIncomplete;
