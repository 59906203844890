import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button } from '@material-ui/core';

import HyperText from '@/components/hypertext';
import { useUserMeQuery } from '@/graphql';
import PublicLayout from '../../components/layout';

interface ComfirmEmailProps {
  email?: string | null | undefined;
  deepLinkHash?: string | null | undefined;
}

const ComfirmEmail: React.FC<ComfirmEmailProps> = ({ email, deepLinkHash }) => {
  const { t } = useTranslation();
  const { data } = useUserMeQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <PublicLayout title={t('sign-up.completed.title')}>
      {(email || data?.me?.email) && (
        <>
          <Box marginTop={3} marginBottom={7}>
            <Typography variant="body2" component="p">
              <HyperText text={t('sing-up.completed.info')}>
                <Typography variant="caption" component="span" color="primary">
                  {/* TODO Handle when userRegistration is empty */}
                  {email || data?.me?.email}
                </Typography>
              </HyperText>
            </Typography>
          </Box>
          {data?.me && !data?.me?.confirmationToken && (
            <Button
              fullWidth
              data-cy-ok
              color="primary"
              variant="contained"
              component={RouterLink}
              to={`${
                deepLinkHash
                  ? '/goto/' + deepLinkHash + '?source=signup'
                  : '/onboard-welcome'
              }`}
            >
              {t('common.ok')}
            </Button>
          )}
        </>
      )}
    </PublicLayout>
  );
};

export default ComfirmEmail;
