import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isEmpty, sortBy } from 'lodash';

import { Box } from '@material-ui/core';

import * as Form from '@/components/form';
import { useSignUp } from '@/contexts/sign-up-context';

import PublicLayout from '../../components/layout';
import StepNavigate from '../../components/step-navigation';
import { useAllProfessionsQuery } from '@/graphql';
import { Skeleton } from '@material-ui/lab';
import { ProfessionTypesEnum } from '@/type';

interface ChsoeJobProps {
  onBack: () => void;
  onNext: () => void;
}

interface Form {
  profession: string;
  anotherProfession: string;
}

const OTHER_TYPE = 6;

export const ChsoeJob: React.FC<ChsoeJobProps> = ({ onBack, onNext }) => {
  const { t } = useTranslation();
  const { data, loading } = useAllProfessionsQuery();

  const { professionId, professionType, anotherProfession, update } =
    useSignUp();

  const { register, errors, setValue, watch } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      profession: professionId || '',
      anotherProfession: anotherProfession || '',
    },
  });

  useEffect(() => {
    register({ name: 'profession' }, {});
    register({ name: 'anotherProfession' }, {});
  }, [professionId, register]);

  const professions = useMemo(() => data?.professions || [], [data]);

  const changeField = useCallback(
    (prop: keyof Form) => (event: React.ChangeEvent<{ value: unknown }>) => {
      // reset data from step 3
      const resetObj = {
        anotherWorkplace: undefined,
        educationLevelId: undefined,
        efnNumber: undefined,
        examYear: undefined,
        medicineStudyBegin: undefined,
        registrationNumber: undefined,
        semesterId: undefined,
        specialityId: undefined,
        studyBeginId: undefined,
        studyYearId: undefined,
        universityId: undefined,
        workplaceId: undefined,
      };

      const value = String(event.target.value);
      const options = {
        shouldDirty: true,
        shouldValidate: true,
      };

      switch (prop) {
        case 'profession': {
          const profession = professions.find((it) => it?.id === value);

          setValue('profession', value, options);

          return update({
            ...resetObj,
            professionType:
              profession?.type?.type || ProfessionTypesEnum.Doctor,
            professionId: value,
          });
        }
        case 'anotherProfession': {
          setValue('anotherProfession', value, options);

          return update({ ...resetObj, anotherProfession: value });
        }
      }
    },
    [professions, setValue, update]
  );

  const isValid = useMemo(() => {
    const values = watch();

    return isEmpty(errors) && !!values.profession;
  }, [errors, watch]);

  const professionsEdited = useMemo(() => {
    return sortBy(professions, [
      (profession) => profession?.type?.type !== 5,
      'type.type',
      'name',
    ]).map((profession) => ({
      value: String(profession?.id),
      label: String(profession?.title),
      type: profession?.type?.type,
    }));
  }, [professions]);

  return (
    <PublicLayout title={t('sign-up.chose-job.title')} page="2 / 3">
      <Box paddingY={7}>
        {loading ? (
          [...Array(10)].map((i, idx) => <Skeleton key={idx} height={30} />)
        ) : (
          <>
            <Form.Row noPadding={professionType !== OTHER_TYPE}>
              <Form.RadioField
                fullWidth
                data-cy-gender
                options={professionsEdited}
                value={watch('profession')}
                onChange={changeField('profession')}
              />
            </Form.Row>
            {professionType === OTHER_TYPE && (
              <Form.Row noPadding>
                <Form.TextField
                  fullWidth
                  required
                  data-cy-another-profession
                  variant="outlined"
                  name="anotherProfession"
                  placeholder={t('fields.another-profession.placeholder')}
                  errors={errors.anotherProfession}
                  value={watch('anotherProfession').replace(/[^a-z]/gi, '')}
                  onChange={changeField('anotherProfession')}
                />
              </Form.Row>
            )}
          </>
        )}
      </Box>
      <StepNavigate disabled={!isValid} onNext={onNext} onBack={onBack} />
    </PublicLayout>
  );
};

export default ChsoeJob;
