import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';
import Offline from '../offline';

interface StackLayoutProps {
  title: string;
}

const EmptyLayout: React.FC<StackLayoutProps> = ({
  title,
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div {...rest}>
      <AppBar elevation={0} className={classes.top}>
        <Toolbar>
          <Box display="flex" justifyContent="center" flexGrow={1}>
            <Typography color="inherit" variant="h6">
              {title}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <main className={classes.main}>
        <Offline>{children}</Offline>
      </main>
    </div>
  );
};

export default EmptyLayout;
