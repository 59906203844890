import React, { useMemo } from 'react';
import clsx from 'clsx';

import Button, { ButtonProps } from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import ThumbUpOutlined from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownAltOutlined from '@material-ui/icons/ThumbDownAltOutlined';
import Check from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  text: {
    width: '100%',
    wordBreak: 'break-word',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    textAlign: 'left',
    textTransform: 'initial',
  },
  none: {},
  correct: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  },
  incorrect: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  selected: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

interface ChooseItemProps extends Omit<ButtonProps, 'variant'> {
  variant?: 'none' | 'selected' | 'correct' | 'incorrect';
  withoutIcon?: boolean;
}

const ChooseItem: React.FC<ChooseItemProps> = ({
  variant = 'none',
  withoutIcon = false,
  children,
  ...rest
}) => {
  const classes = useStyles();

  const style = useMemo(() => classes[variant], [classes, variant]);

  const icon = useMemo(() => {
    switch (variant) {
      case 'correct':
        return <ThumbUpOutlined fontSize={'small'} />;
      case 'incorrect':
        return <ThumbDownAltOutlined fontSize={'small'} />;
      case 'selected':
        return <Check />;
      default:
        return <></>;
    }
  }, [variant]);

  return (
    <Button
      variant="outlined"
      className={clsx(classes.button, style)}
      {...rest}
    >
      <Typography variant="subtitle2" className={classes.text}>
        {children}
      </Typography>
      {!withoutIcon && icon}
    </Button>
  );
};

export default ChooseItem;
