import React from 'react';
import { useTranslation } from 'react-i18next';

const DevLang: React.FC = ({ children }) => {
  const { i18n } = useTranslation();
  const changeLang = (lang: string) => () => {
    i18n.changeLanguage(lang);
  };
  const isDev = process.env.NODE_ENV === 'development';
  const isEn = i18n.language === 'en';
  const isDe = i18n.language === 'de';

  return (
    <>
      {children}
      {isDev && isEn && (
        <button
          style={{ position: 'fixed', bottom: 0 }}
          type="button"
          onClick={changeLang('de')}
        >
          en
        </button>
      )}
      {isDev && isDe && (
        <button
          style={{ position: 'fixed', bottom: 0 }}
          type="button"
          onClick={changeLang('en')}
        >
          de
        </button>
      )}
    </>
  );
};

export default DevLang;
