import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { DateTime } from 'luxon';
import { isNull } from 'lodash';

import {
  Box,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ActualLearnWeekWeeklyGoal } from '@/graphql';
import { useUser } from '@/contexts/user-context';
import EmptyDailyTipper from '../../components/empty-daily-tipper';
import { ChevronRight } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  progressWrap: {
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#E6EEFA',
    },
  },
}));

const DailyTipperCard: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user, dtWeek, dtData, loading } = useUser();

  const dailyTipperWeeklyProgress = useMemo(
    () => dtWeek?.dailyTipperWeeklyProgress,
    [dtWeek]
  );

  const weeklyGoalNumber = useMemo(() => {
    switch (dailyTipperWeeklyProgress?.weeklyGoal) {
      case ActualLearnWeekWeeklyGoal.A_4:
        return 4;
      case ActualLearnWeekWeeklyGoal.A_5:
        return 5;
      case ActualLearnWeekWeeklyGoal.A_6:
        return 6;
      case ActualLearnWeekWeeklyGoal.A_7:
        return 7;
      default:
        return 0;
    }
  }, [dailyTipperWeeklyProgress]);

  const count =
    dailyTipperWeeklyProgress?.weekNumber !== DateTime.local().weekNumber
      ? 0
      : dailyTipperWeeklyProgress?.completedDays;

  const localCount = count > weeklyGoalNumber ? weeklyGoalNumber : count;

  return (
    <Paper elevation={0} style={{ minHeight: 124 }}>
      {isNull(dailyTipperWeeklyProgress) ||
      !dtData?.dailyTipperData ||
      user?.settings?.dailyTipperMode === false ? (
        <EmptyDailyTipper />
      ) : (
        <Box py={5} px={6}>
          {loading && !dtData?.dailyTipperData && !dailyTipperWeeklyProgress ? (
            [...Array(3)].map((i, idx) => <Skeleton key={idx} height={25} />)
          ) : (
            <div id="animationFadeIn">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h2">{t('daily-tipper.title')}</Typography>
                <IconButton
                  color="primary"
                  component={RouterLink}
                  size="small"
                  to="/daily-tipper"
                  style={{ padding: 0 }}
                >
                  <ChevronRight style={{ color: '#879BB2' }} />
                </IconButton>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                marginTop={5.5}
              >
                <Typography variant="overline">
                  {t('common.daily-tipper.progress')}
                </Typography>
                <Typography
                  variant="subtitle2"
                  id="daily-tipper-card-el"
                  style={{ color: '#2B75B3' }}
                >
                  {t('common.progress', {
                    count: localCount,
                    all: weeklyGoalNumber,
                  })}
                </Typography>
              </Box>
              <Box marginTop={3.5} className={classes.progressWrap}>
                <LinearProgress
                  style={{
                    height: 8,
                    borderRadius: 10,
                  }}
                  variant="determinate"
                  value={(localCount / weeklyGoalNumber) * 100}
                />
              </Box>
            </div>
          )}
        </Box>
      )}
    </Paper>
  );
};

export default DailyTipperCard;
