import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateTime, Duration } from 'luxon';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  ClassRoomLearningSessionStatus,
  LekSessionStatus,
  useGetLekSessionResultsQuery,
} from '@/graphql';
import Loading from '@/components/loading';
import AlarmIcon from '@/components/icons/alarm';
import DurationIcon from '@/components/icons/duration';
import StarIcon from '@/components/icons/star';
import LekEndsIn from '../lek-ends-in';
import { useStyles } from './styles';
import { sortBy } from 'lodash';

type LekResultsProps = {
  sessionId: string;
  singlePage?: boolean;
  isSessionFinished?: boolean | null | undefined;
};

const LekResults: React.FC<LekResultsProps> = ({
  singlePage,
  sessionId,
  isSessionFinished,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [lekSessionActiveId, setLekSessionActiveId] = useState(false);

  const { data, loading } = useGetLekSessionResultsQuery({
    variables: {
      sessionId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const lekSessionsData = useMemo(
    () =>
      sortBy(data?.lekSessionsData, [
        function (o) {
          return o?.lekSession?.status !== LekSessionStatus.InProgress;
        },
      ]),
    [data]
  );

  const handleBackToCr = useCallback(() => {
    history.replace(`/classroom/${sessionId}/`, {
      fromCongratulation: true,
    });
  }, [history, sessionId]);

  const userLekDuration = useCallback((it) => {
    const time = Math.round(it?.userLekData?.elapsedTime || 0);
    const { hours, minutes, seconds } = Duration.fromMillis(time * 1000)
      .shiftTo('hours', 'minutes', 'seconds')
      .toObject();
    return hours + 'h ' + minutes + 'm ' + seconds + 's ';
  }, []);

  const progressData = useCallback((it) => {
    const lekGoal = it?.lekSession?.lek.lekGoal || 0;
    const questionCount = it?.lekSession?.lek.questionCount || 0;
    const correctAnswerCount = it?.userLekData?.correctAnswerCount || 0;
    let answers =
      questionCount > 0 ? (correctAnswerCount * 100) / questionCount : 0;
    answers = answers > 100 ? 100 : answers;
    const goal = lekGoal > 0 ? (lekGoal * 100) / questionCount : 0;
    return { answers, goal };
  }, []);

  const attemptsLeft = useCallback((it) => {
    return (
      (it?.lekSession?.lek.possibleAttempts || 0) -
      (it?.userLekData?.attemptNumber || 0)
    );
  }, []);

  const handleLekStart = () => {
    const url = `/classroom/${sessionId}/lek/${lekSessionActiveId}`;
    history.location.pathname.includes('lek-results')
      ? history.replace(url)
      : history.push(url);
  };

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  if (loading)
    return (
      <Paper elevation={0}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: 'calc(100vh - 86px)' }}
        >
          <Loading />
        </Box>
      </Paper>
    );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        p={singlePage ? 0 : 2}
        minHeight="100%"
      >
        {lekSessionsData?.map(
          (it) =>
            it?.userLekData && (
              <Box
                className={classes.attempt}
                key={it?.id}
                mb={singlePage ? 0 : 2}
              >
                <Box
                  className={classes.resultCard}
                  style={{
                    background: it?.userLekData?.isPassed
                      ? '#3498DB'
                      : '#647485',
                  }}
                >
                  <Box flexDirection="column" display="grid" gridRowGap={8}>
                    <Typography variant="caption" color="inherit">
                      {it?.userLekData?.isPassed ? t('lek.win') : t('lek.lost')}
                    </Typography>
                  </Box>
                  <Box
                    mt={8}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <Box display="grid" gridRowGap={12}>
                      <Typography style={{ fontSize: 38 }} color="inherit">
                        <span style={{ fontWeight: 800 }}>
                          {it?.userLekData?.correctAnswerCount}
                        </span>{' '}
                        / {it?.lekSession?.lek.questionCount}
                      </Typography>
                      <Typography variant="body1" color="inherit">
                        {t('lek.right-questions')}
                      </Typography>
                    </Box>
                    <Box display="grid" gridRowGap={12}>
                      <Box
                        display="grid"
                        gridTemplateColumns="auto 1fr"
                        gridColumnGap={4}
                      >
                        <AlarmIcon htmlColor="currentColor" />
                        <Typography variant="caption" color="inherit">
                          {userLekDuration(it)}
                        </Typography>
                      </Box>
                      <Box
                        display="grid"
                        gridTemplateColumns="auto 1fr"
                        gridColumnGap={4}
                      >
                        <DurationIcon />
                        <Typography variant="caption" color="inherit">
                          {DateTime.fromISO(it?.userLekData?.updated).toFormat(
                            'dd/MM/yyyy'
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box p={7} mb={6} flexGrow={1}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="overline">
                      {t('lek.passing-score')}
                    </Typography>
                    <Typography variant="overline">
                      {it?.lekSession?.lek.lekGoal} MCQs
                    </Typography>
                  </Box>
                  <Box marginTop={3} className={classes.progressWrap}>
                    <LinearProgress
                      style={{ height: 8, borderRadius: 4 }}
                      variant="determinate"
                      value={progressData(it).answers}
                      classes={{
                        barColorPrimary: classes.barColorPrimary,
                        barColorSecondary: classes.barColorSecondary,
                      }}
                    />
                    <Box
                      className={classes.progressGoal}
                      style={{ left: `calc(${progressData(it).goal}% - 6px)` }}
                    >
                      <span
                        className={classes.goalMark}
                        style={{
                          height: 12,
                          width: 1,
                        }}
                      />
                      <StarIcon style={{ marginTop: 4 }} />
                    </Box>
                  </Box>
                </Box>
                {it?.lekSession?.status === LekSessionStatus.InProgress &&
                  it?.lekSession?.classRoomLearningSession?.status !==
                    ClassRoomLearningSessionStatus.Finished &&
                  !isSessionFinished && (
                    <Box className={classes.footer}>
                      {!!attemptsLeft(it) &&
                        it?.lekSession?.status ===
                          LekSessionStatus.InProgress && (
                          <Button
                            data-cy-retake
                            color="primary"
                            onClick={() => {
                              setLekSessionActiveId(it.lekSession?.id);
                              setIsOpen(true);
                            }}
                          >
                            {t('lek.repeat')} ({attemptsLeft(it)})
                          </Button>
                        )}
                      {!singlePage && (
                        <LekEndsIn
                          finishDate={it?.lekSession?.finishDateTime}
                          lekSessionId={it?.lekSession?.id}
                        />
                      )}
                    </Box>
                  )}
                {singlePage && (
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={handleBackToCr}
                    style={{ marginTop: 8 }}
                  >
                    {t('lek.back-to-classroom')}
                  </Button>
                )}
              </Box>
            )
        )}
      </Box>
      <Dialog data-cy-welcome-dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>{t('lek.repeat')}</DialogTitle>
        <DialogContent>
          <Box marginBottom={5} mt={-2}>
            <Typography variant="body2">{t('lek.repeat-info')}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button data-cy-later-btn onClick={handleClose}>
            {t('common.back')}
          </Button>
          <Box color="primary">
            <Button data-cy-start-btn color="primary" onClick={handleLekStart}>
              {t('lek.start')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LekResults;
