import React, { useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useQuizSessionsOnHomePageQuery } from '@/graphql';
import QuiztimeItem from './quiztime-item';
import QuizDuelCard from './quiz-duel-card';

const QuiztimeHomepage: React.FC = () => {
  const ignoredQuizIDs = JSON.parse(
    localStorage?.getItem('ignoredQuizIDs') || '[]'
  );

  const { data } = useQuizSessionsOnHomePageQuery({
    variables: { startLess24Hours: true },
    fetchPolicy: 'cache-and-network',
  });
  const activeQuiz = data?.inProgressUserQuizSession;

  const listOfQuizes = useMemo(() => {
    return data?.homeQuizSessions?.filter(
      (it) => ignoredQuizIDs.indexOf(it?.quizSession?.id) < 0
    );
  }, [data, ignoredQuizIDs]);

  return (
    <>
      {activeQuiz?.id ? (
        <QuizDuelCard />
      ) : (
        listOfQuizes &&
        listOfQuizes.length >= 1 && (
          <Carousel
            centerMode={listOfQuizes.length === 1 ? false : true}
            showArrows={false}
            showThumbs={false}
            autoPlay={false}
            showStatus={false}
            showIndicators={false}
            width={`calc(100vw - ${listOfQuizes.length === 1 ? 16 : 8}px)`}
            swipeable
          >
            {listOfQuizes?.map(
              (quiz) =>
                quiz?.quizSession?.id && (
                  <QuiztimeItem
                    quizSessionId={quiz?.quizSession?.id}
                    key={quiz?.quizSession?.id}
                    quiz={quiz?.quizSession}
                    quizStatus={quiz?.quizSession?.status}
                    userStatus={quiz?.userStatus
                      ?.replace(/\s+/g, '_')
                      ?.toUpperCase()}
                    isSingleItem={listOfQuizes.length === 1 ? true : false}
                  />
                )
            )}
          </Carousel>
        )
      )}
    </>
  );
};
export default QuiztimeHomepage;
