import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  memberList: {
    padding: 0,
  },
  navBtn: {
    width: '50%',
  },
  navBtnDisabled: {
    borderColor: '#C6D5E3',
    color: theme.palette.action.disabled,
  },
  playDuelBtn: {
    width: 'calc(100% - 32px)',
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(4),
    right: theme.spacing(4),
  },
}));
