import React from 'react';

import { Box } from '@material-ui/core';

interface RowrProps {
  columns?: number;
  noPadding?: boolean;
}

const Row: React.FC<RowrProps> = ({ columns, noPadding, children }) => (
  <Box
    display="grid"
    gridColumnGap={16}
    gridTemplateColumns={`repeat(${columns},1fr)`}
    marginBottom={!noPadding ? 6 : 0}
  >
    {children}
  </Box>
);

export default Row;
