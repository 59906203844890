import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from '@material-ui/core';

import {
  ClassRoomLearningSessionStatus,
  LekSessionStatus,
  useClassRoomSessionQuery,
  UserLekDataStatus,
} from '@/graphql';
import LekStart from '../../../lek-start';
import LekResults from '../../../lek-results';

type ClassRoomParams = {
  sessionId: string;
  userSessionSettingId: string;
};

interface LekDataProps {
  isSessionFinished?: boolean | null | undefined;
}

const LekData: React.FC<LekDataProps> = ({ isSessionFinished }) => {
  const { t } = useTranslation();
  const { sessionId, userSessionSettingId } = useParams<ClassRoomParams>();

  const { data } = useClassRoomSessionQuery({
    variables: { sessionId, userSessionSettingsId: userSessionSettingId },
  });

  const lekSessionInProgress = useMemo(
    () =>
      data?.lekSessionsData?.find(
        (it) => it?.lekSession?.status === LekSessionStatus.InProgress
      )?.lekSession,
    [data]
  );

  const userLekDataActive = useMemo(
    () =>
      data?.lekSessionsData?.find(
        (it) => it?.userLekData?.status === UserLekDataStatus.InProgress
      )?.userLekData,
    [data]
  );

  const lekWasPassed = useMemo(
    () =>
      data?.lekSessionsData?.find(
        (it) => it?.userLekData?.status === UserLekDataStatus.Finished
      ),
    [data]
  );

  if (lekSessionInProgress && userLekDataActive)
    return (
      <Redirect to={`/classroom/${sessionId}/lek/${lekSessionInProgress.id}`} />
    );

  return (
    <>
      <Box px={2} pt={2}>
        <Paper elevation={0}>
          {
            // !lekWasPassedInActiveLekSession &&
            data?.classRoomLearningSession?.status !==
              ClassRoomLearningSessionStatus.Finished &&
              data?.lekSessionsData?.map(
                (it) =>
                  it?.userLekData?.status !== UserLekDataStatus.Finished && (
                    <div key={it?.id}>
                      <Box px={6} py={5}>
                        <Typography variant="subtitle1">
                          {t('lek.success-control')}
                        </Typography>
                        <Box mt={3}>
                          <Typography variant="body2">
                            {t('lek.success-control-info')}
                          </Typography>
                        </Box>
                      </Box>
                      <LekStart
                        sessionId={sessionId}
                        lekSessionId={it?.lekSession?.id}
                        finishDate={it?.lekSession?.finishDateTime}
                        startDate={it?.lekSession?.startDateTime}
                        lekSessionStatus={it?.lekSession?.status}
                        justForLek
                      />
                    </div>
                  )
              )
          }
        </Paper>
      </Box>
      {lekWasPassed && (
        <LekResults
          sessionId={sessionId}
          isSessionFinished={isSessionFinished}
        />
      )}
    </>
  );
};

export default LekData;
