import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  spacing: 4,
  palette: {
    primary: {
      dark: '#2B75B3',
      main: '#3498DB',
      light: '#45B3EE',
    },
    action: {
      disabledBackground: '#ECF0F1',
      disabled: '#879BB2',
    },
    text: {
      primary: '#47525E',
      secondary: '#879BB2',
    },
    divider: '#E6EEFA',
  },
  overrides: {
    MuiDialogContentText: {
      root: {
        color: '#47525E',
        whiteSpace: 'pre-line',
      },
    },
    MuiAvatar: {
      root: {
        height: 48,
        width: 48,
        border: `1px solid rgba(0, 0, 0, 0.07)`,
      },
      colorDefault: {
        backgroundColor: '#E6EEFA',
        color: '#DADADA',
      },
    },
    MuiListItemAvatar: {
      root: {
        minWidth: 64,
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#2B75B3',
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiList: {
      padding: {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
    MuiListItem: {
      root: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      gutters: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      divider: {
        borderBottom: 'none',
        boxShadow: `inset 0px -1px 0px #E6EEFA`,
      },
      secondaryAction: {
        paddingRight: 80,
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 24,
      },
    },
    MuiListItemText: {
      root: {
        overflow: 'auto',
        marginTop: 0,
        marginBottom: 0,
      },
      multiline: {
        display: 'grid',
        gridGap: 4,
        marginTop: 0,
        marginBottom: 0,
      },
      primary: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      secondary: {
        fontSize: 12,
        color: '#929DA8',
      },
    },
    MuiListItemIcon: {
      root: {
        color: '#879BB2',
        minWidth: 48,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        maxHeight: 'calc(100% - 128px)',
      },
    },
    MuiCollapse: {
      wrapper: {
        paddingLeft: 24,
        paddingRight: 24,
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: 'inset 0px -1px 0px #E6EEFA',
        '&$expanded': {
          margin: '0',
        },
      },
      rounded: {
        borderRadius: 0,
        '&:last-child': {
          boxShadow: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      content: {
        margin: '12px 0',
        '&$expanded': {
          margin: '12px 0',
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiFormControlLabel: {
      labelPlacementStart: {
        marginLeft: 0,
      },
    },
    MuiChip: {
      root: {
        color: '#647485',
        borderRadius: 3,
        backgroundColor: '#ECF0F1',
        fontWeight: 500,
        fontSize: 10,
        lineHeight: '16px',
        letterSpacing: 1.5,
        textTransform: 'uppercase',
      },
      sizeSmall: {
        height: 16,
      },
      iconSmall: {
        fontSize: 14,
      },
      labelSmall: {
        paddingLeft: 6,
        paddingRight: 6,
      },
      colorSecondary: {
        backgroundColor: 'rgba(0, 0, 0, 0.28)',
        color: '#D1D5D8',
      },
    },
  },
  typography: {
    fontFamily: ['Lato', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    allVariants: {
      color: '#2C3E50',
      mixBlendMode: 'normal',
      whiteSpace: 'pre-line',
    },
    h1: {
      fontSize: 24,
      lineHeight: '28px',
      letterSpacing: 0.18,
      color: '#4A647C',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: 20,
      lineHeight: '24px',
      letterSpacing: 0.15,
    },
    h5: {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '24px',
      letterSpacing: 0.18,
    },
    h6: {
      fontWeight: 'bold',
      fontSize: 20,
      lineHeight: '24px',
      letterSpacing: 0.15,
    },
    subtitle1: {
      fontWeight: 'bold',
      fontSize: 16,
      letterSpacing: 0.15,
      lineHeight: '24px',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 0.1,
    },
    body1: {
      fontSize: 16,
      lineHeight: '24px',
      color: '#47525E',
    },
    body2: {
      fontSize: 14,
      color: '#47525E',
    },
    button: {
      fontWeight: 'bold',
      fontSize: 14,
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: 12,
      letterSpacing: 0.4,
      lineHeight: '16px',
      color: '#929DA8',
    },
    overline: {
      fontWeight: 500,
      fontSize: 10,
      lineHeight: '16px',
      letterSpacing: 1.5,
      textTransform: 'uppercase',
    },
  },
});

export default theme;
