import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const isDev = process.env.NODE_ENV === 'development';

const lang = isDev ? 'en' : 'de';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: lang,
    debug: isDev,
    preload: isDev ? ['en', 'de'] : ['de'],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
