import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
  },
  cupIconBox: {
    padding: 8,
    background: '#E2F5FD',
    borderRadius: 4,
  },
  cupIcon: {
    height: 40,
    width: 40,
  },
}));
