import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Button, Paper, Typography } from '@material-ui/core';

import Darts from '@/components/icons/darts';

type GoalCardProps = {
  goal?: number;
};

const GoalCard: React.FC<GoalCardProps> = ({ goal }) => {
  const { t } = useTranslation();

  return (
    <Paper elevation={0}>
      <Box
        display="grid"
        gridTemplateColumns="auto 1fr"
        gridColumnGap={20}
        p={6}
        mb={2}
      >
        <Box>
          <Darts />
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="grid" gridRowGap={4}>
            <Typography variant="overline">
              {t('daily-tipper.goal.weekly')}
            </Typography>
            <Typography variant="subtitle2">
              {t('daily-tipper.goal.count', { goal })}
            </Typography>
          </Box>
          <Box>
            <Button
              variant="outlined"
              to="/settings"
              component={RouterLink}
              style={{ color: '#2B75B3' }}
              data-dd-action-name="DT navigate to settings"
            >
              {t('common.change')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default GoalCard;
