import React from 'react';
import Fallback from '../fallback';

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends React.Component<
  Record<string, unknown>,
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  // Lifecycle method triggered when an error occurs
  static getDerivedStateFromError(error: Error) {
    console.error('Error in ErrorBoundary:', error);
    return { hasError: true, error };
  }

  // Method to log error and additional info
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Log the error to an external service or console
    console.error('Error caught in ErrorBoundary:', error, errorInfo);
  }

  // Custom method to reset the error state (useful for "Try Again" actions)
  resetError = () => {
    this.setState({ hasError: false, error: null });
  };

  // Method to handle general JavaScript errors (global error listener)
  handleError = (event: ErrorEvent) => {
    console.error('Global Error Event:', event);

    // Handle chunk loading errors
    if (
      event.message.includes('Loading chunk') ||
      event.message.includes('Loading CSS chunk')
    ) {
      console.error('Chunk loading failed. Reloading page...');
      setTimeout(() => window.location.reload(), 1000); // Reload page after delay
    } else {
      this.setState({
        hasError: true,
        error: event.error,
      });
    }
  };

  // Method to handle unhandled promise rejections
  handleUnhandledRejection = (event: PromiseRejectionEvent) => {
    console.error('Unhandled Rejection Event:', event);
    this.setState({
      hasError: true,
      error: event.reason,
    });
  };

  // Set up global error and promise rejection listeners when the component mounts
  componentDidMount(): void {
    window.addEventListener('error', this.handleError);
    window.addEventListener(
      'unhandledrejection',
      this.handleUnhandledRejection
    );
  }

  // Remove listeners when the component unmounts
  componentWillUnmount() {
    window.removeEventListener('error', this.handleError);
    window.removeEventListener(
      'unhandledrejection',
      this.handleUnhandledRejection
    );
  }

  // Render method displaying either the children or fallback UI
  render() {
    if (this.state.hasError || this.state.error) {
      // Render fallback UI if an error occurs
      return <Fallback />;
    }

    // Render children if no errors occurred
    return this.props.children;
  }
}

export default ErrorBoundary;
