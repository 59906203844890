import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  date: {
    fontSize: 12,
    lineHeight: '14px',
    letterSpacing: '0.4px',
    textTransform: 'none',
  },
}));
