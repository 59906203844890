import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  startBtn: {},
  startBtnDisabled: {
    borderColor: '#C6D5E3',
    color: theme.palette.action.disabled,
    background: theme.palette.action.disabledBackground + '!important',
  },
}));
