import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  created: {
    background: `linear-gradient(0deg, rgba(226, 245, 253, 0.48), rgba(226, 245, 253, 0.48)), #FFFFFF`,
  },

  icon: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  action: {
    color: theme.palette.primary.dark,
    pointerEvents: 'none',
  },
  offBtn: {
    width: 68,
    height: 36,
    border: '1px solid #C6D5E3',
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.dark,
  },
}));
