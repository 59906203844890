import SearchLayout from '@/screens/main/components/search-layout';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ClassRoomSearchMember = () => {
  const { t } = useTranslation();

  return (
    <SearchLayout
      inputPlaceholder={t('classrooms.find-participants')}
      contentPlaceholder={t('classrooms.find-tip')}
      isMembersList
    />
  );
};

export default ClassRoomSearchMember;
