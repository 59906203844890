import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  ClickAwayListener,
  Divider,
  Link,
  Paper,
  Typography,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { makeStyles } from '@material-ui/core/styles';
import StatusBlock from '@/screens/main/duels/top-menu/status-block';
import { isNumber } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    maxHeight: 64,
    boxSizing: 'border-box',
  },
  dropdown: {
    position: 'absolute',
    height: 102,
    top: 66,
    right: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0px 6px 10px rgba(17, 38, 63, 0.14)`,
  },
}));

interface ResultsTopMenu {
  points: number | undefined | null;
  duels: number | undefined | null;
  rank: number | undefined | null;
  wins?: number | undefined | null;
  ties?: number | undefined | null;
  loss?: number | undefined | null;
  hasDropdown?: boolean | undefined | null;
  quizSessionId: string | undefined | null;
}

const TopMenuDuel: React.FC<ResultsTopMenu> = ({
  points,
  duels,
  rank,
  wins,
  ties,
  loss,
  hasDropdown,
  quizSessionId,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.root}>
        <Paper square elevation={0} onClick={handleClick}>
          <Box
            display="flex"
            justifyContent="space-between"
            paddingX={hasDropdown ? 4 : 0}
            paddingY={hasDropdown ? 3 : 0}
          >
            <Box>
              <Typography variant="overline">{t('common.result')}</Typography>
              <Box display="flex">
                {isNumber(points) && (
                  <>
                    <Typography variant="subtitle2">
                      {t('duels.points', {
                        points: points,
                      })}
                    </Typography>
                    <Box marginX={5}>
                      <Divider orientation="vertical" />
                    </Box>
                  </>
                )}
                {isNumber(duels) && (
                  <>
                    <Typography variant="subtitle2">
                      {t('duels.count', {
                        count: duels,
                      })}
                    </Typography>
                    {!hasDropdown && (
                      <Box marginX={5}>
                        <Divider orientation="vertical" />
                      </Box>
                    )}
                  </>
                )}
                {isNumber(rank) && !hasDropdown && (
                  <Typography variant="subtitle2">
                    {t('duels.place', {
                      place: rank,
                    })}
                  </Typography>
                )}
              </Box>
            </Box>
            {hasDropdown && (
              <Box display="flex" alignItems="center" color="action.disabled">
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Box>
            )}
          </Box>
          {hasDropdown && <Divider />}
        </Paper>
        {open && hasDropdown ? (
          <div className={classes.dropdown}>
            <Box
              display="grid"
              gridTemplateColumns="repeat(3, 1fr)"
              gridColumnGap={8}
              paddingX={4}
              paddingY={2}
            >
              {isNumber(wins) && <StatusBlock variant="victory" count={wins} />}
              {isNumber(ties) && <StatusBlock variant="draw" count={ties} />}
              {isNumber(loss) && <StatusBlock variant="defeat" count={loss} />}
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <Link
                variant="overline"
                color="primary"
                to={`/quiztime/${quizSessionId}/completed-duels`}
                component={RouterLink}
              >
                {t('quiztime.view-completed')}
              </Link>
            </Box>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default TopMenuDuel;
