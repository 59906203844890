import React from 'react';
import { Box, Paper } from '@material-ui/core';
import StackLayout from '../../components/stack-layout';
import { usePrivacyPolicyQuery } from '@/graphql';
import { useLocation } from 'react-router-dom';

const AGB: React.FC = () => {
  const { data } = usePrivacyPolicyQuery();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const hideBackArrow = params.get('hideBackArrow') ? true : false;

  return (
    <StackLayout hideBackArrow={hideBackArrow} title="AGB">
      <Box clone p={2} className="privacy-page">
        <Paper elevation={0}>
          {data?.privatePolicy?.text && (
            <div
              dangerouslySetInnerHTML={{ __html: data?.privatePolicy?.text }}
            />
          )}
        </Paper>
      </Box>
    </StackLayout>
  );
};

export default AGB;
