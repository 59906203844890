import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  discountBox: {
    padding: 20,
    borderRadius: 4,
    backgroundColor: '#2C3E50',
    color: '#fff',
    marginLeft: '-8px',
    marginRight: '-8px',
  },
  priceTitle: {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '0.18px',
  },
}));
