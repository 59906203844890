import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  lightSnackBar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '2px 3px 6px rgba(17, 38, 63, 0.14)',
    color: '#2C3E50',
    padding: '3px 14px 3px 16px',
    '&>*': {
      width: '100%',
    },
  },
  darkSnackBar: {
    backgroundColor: theme.palette.text.primary,
    boxShadow: '2px 3px 6px rgba(17, 38, 63, 0.14)',
    color: theme.palette.common.white,
    padding: '3px 14px 3px 16px',
    boxSizing: 'border-box',
    '&>*': {
      width: '100%',
    },
  },
  captionLight: {
    color: '#2C3E50',
  },
  captionDark: {
    color: theme.palette.common.white,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  timerIcon: {
    color: '#EB5757',
    marginRight: '8px',
  },
}));
