import React, { useEffect, useRef } from 'react';
import { some } from 'lodash';
import { Box, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useSearchNetworkClassRoomSessionsLazyQuery } from '@/graphql';
import ClassroomCard from '../card';
import Loading from '@/components/loading';
import { useUser } from '@/contexts/user-context';
import { MAIN_ORGANIZER_NAME } from '@/utils/constants';

type CardsListProps = {
  searchStr: string;
};

const CardsList: React.FC<CardsListProps> = ({ searchStr }) => {
  const prevSearchStr = useRef<string | null>(null);
  const { t } = useTranslation();
  const { user } = useUser();

  const [getCRSessions, { loading, data }] =
    useSearchNetworkClassRoomSessionsLazyQuery();

  useEffect(() => {
    if (prevSearchStr.current !== searchStr) {
      prevSearchStr.current = searchStr;
      getCRSessions({
        variables: { searchPointer: searchStr },
      });
    }
  }, [searchStr, getCRSessions]);

  if (loading) return <Loading />;

  return (
    <>
      {data?.searchNetworkClassRoomSessions?.map(
        (it) =>
          it && (
            <ClassroomCard
              key={it?.id}
              id={it.id}
              link={`/classroom${
                it.isForSingle &&
                some(user?.classRoomLearningSession, { id: it?.id })
                  ? ''
                  : '-details'
              }/${it?.id}`}
              title={it.classRoom.title}
              organizerName={it?.classRoom?.organizer?.name}
              startDate={it?.startDate}
              finishDate={it?.finishDate}
              classRoomImage={
                (it.classRoom?.organizer?.name === MAIN_ORGANIZER_NAME &&
                  it.classRoom.backgroundImage) ||
                it?.classRoom?.classRoomImage
              }
              specialityGroupIcon={
                it.classRoom?.organizer?.name === MAIN_ORGANIZER_NAME
                  ? it.specialityGroupIcon
                  : null
              }
              classRoomType={it.classRoom?.enrollmentType}
              // averageRate={it.classRoom?.aggregatedRate?.averageRate}
              // feedbackCount={it.classRoom?.aggregatedRate?.feedbackCount}
              certificate={it.classRoom?.certificate?.points}
              isForPremiumOnly={it?.isForPremiumOnly}
              duration={it?.duration}
            />
          )
      )}
      {searchStr && !data?.searchNetworkClassRoomSessions?.length && (
        <Paper square elevation={0} style={{ height: '100%' }}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            px={13}
            minHeight="70vh"
            textAlign="center"
          >
            <Typography variant="subtitle1">
              {t('common.no-results')}
            </Typography>
            <Typography variant="body2" style={{ marginTop: 4 }}>
              {t('common.no-results-descr')}
            </Typography>
          </Box>
        </Paper>
      )}
    </>
  );
};

export default CardsList;
