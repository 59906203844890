import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { ModalContext } from '@/contexts/modal-context';
import { Box } from '@material-ui/core';

import {
  FREEMIUM_MAX_DUEL_COUNT,
  PREMIUM_MAX_DUEL_COUNT,
} from '@/utils/constants';
import { useGetPremiumData } from './useGetPremiumData';

export const useModal = (
  title: string,
  content: string,
  button?: string,
  buttonAction?: () => void,
  button2?: string,
  button2action?: () => void,
  buttonVariant?: 'text' | 'outlined' | 'contained' | undefined,
  button2Variant?: '#2B75B3' | 'red' | undefined,
  fullWidth?: boolean,
  boxPadding?: string,
  boxAlign?: string
) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = React.useContext(ModalContext);

  const onButton2Click = React.useCallback(() => {
    if (button2action) button2action();
    closeModal();
  }, [button2action, closeModal]);

  const onButtonClick = React.useCallback(() => {
    if (buttonAction) buttonAction();
    closeModal();
  }, [buttonAction, closeModal]);

  const element = React.useMemo(
    () => (
      <Box textAlign={boxAlign} padding={boxPadding}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText variant="body2">{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={
              buttonAction
                ? () => {
                    closeModal();
                    onButtonClick();
                  }
                : closeModal
            }
            variant={buttonVariant}
            fullWidth={!!fullWidth}
            color={button2 ? 'default' : 'primary'}
          >
            {button || t('common.back')}
          </Button>
          {button2 && (
            <Button
              onClick={onButton2Click}
              variant={buttonVariant}
              fullWidth={!!fullWidth}
              style={{ color: `${button2Variant}` }}
            >
              {button2}
            </Button>
          )}
        </DialogActions>
      </Box>
    ),
    [
      button,
      button2,
      button2Variant,
      onButton2Click,
      buttonAction,
      onButtonClick,
      closeModal,
      content,
      t,
      title,
      buttonVariant,
      fullWidth,
      boxPadding,
      boxAlign,
    ]
  );

  const open = React.useCallback(() => {
    openModal({
      content: element,
      handleClose: closeModal,
    });
  }, [openModal, element, closeModal]);

  return { open, close: closeModal };
};

export const useDuelCreateError3Modal = () => {
  const { t } = useTranslation();
  const { isUserPremium } = useGetPremiumData();

  return useModal(
    t('dialogs.duel-create-error3.title'),
    t('dialogs.duel-create-error3.content', {
      value: isUserPremium ? PREMIUM_MAX_DUEL_COUNT : FREEMIUM_MAX_DUEL_COUNT,
    }),
    t('common.ok')
  );
};

export const useDuelCreateError4Modal = () => {
  const { t } = useTranslation();

  return useModal(
    t('dialogs.duel-create-error4.title'),
    t('dialogs.duel-create-error4.content'),
    t('common.ok')
  );
};

export const useDuelCreateError5Modal = () => {
  const { t } = useTranslation();

  return useModal(
    t('dialogs.duel-create-error5.title'),
    t('dialogs.duel-create-error5.content'),
    t('common.ok')
  );
};

export const useDuelCreateError8Modal = (action1?: () => void) => {
  const { t } = useTranslation();

  return useModal(
    t('dialogs.error8.title'),
    t('dialogs.error8.content'),
    t('common.lets-go'),
    action1,
    undefined,
    undefined,
    undefined,
    undefined,
    true,
    '16px 8px',
    'center'
  );
};

export const useAnyGuestionModal = () => {
  const { t } = useTranslation();

  return useModal(
    t('dialogs.any-question.title'),
    t('dialogs.any-question.content'),
    t('common.ok')
  );
};

export const useQuizFullModal = (action1: () => void) => {
  const { t } = useTranslation();

  return useModal(
    t('dialogs.quiztime-limit.title'),
    t('dialogs.quiztime-limit.content'),
    t('dialogs.quiztime-limit.button1'),
    undefined,
    t('dialogs.quiztime-limit.button2'),
    action1,
    undefined,
    '#2B75B3'
  );
};

export const useLeaveQuizNotStartedModal = (action: () => void) => {
  const { t } = useTranslation();

  return useModal(
    t('dialogs.leave-quiztime-not-started.title'),
    t('dialogs.leave-quiztime-not-started.content'),
    t('common.cancel'),
    undefined,
    t('dialogs.leave-quiztime-not-started.button2'),
    action,
    undefined,
    'red'
  );
};

export const useLeaveQuizModal = (action: () => void) => {
  const { t } = useTranslation();

  return useModal(
    t('dialogs.leave-quiztime.title'),
    t('dialogs.leave-quiztime.content'),
    t('common.cancel'),
    undefined,
    t('dialogs.leave-quiztime.button2'),
    action,
    undefined,
    'red'
  );
};

export const usePauseQuizModal = (action1: () => void, action2: () => void) => {
  const { t } = useTranslation();

  return useModal(
    t('dialogs.pause-quiztime.title'),
    t('dialogs.pause-quiztime.content'),
    t('common.cancel'),
    action1,
    t('dialogs.pause-quiztime.button2'),
    action2,
    undefined,
    '#2B75B3'
  );
};

export const useNeedPremiumModal = (action: () => void) => {
  const { t } = useTranslation();

  return useModal(
    t('dialogs.upgrade.title2'),
    t('dialogs.upgrade.content2'),
    t('common.back'),
    undefined,
    t('dialogs.upgrade.cta2'),
    action,
    undefined,
    '#2B75B3'
  );
};

export const useSubscriptionInProgressModal = () => {
  const { t } = useTranslation();

  return useModal(
    t('payment.is-processing-title'),
    t('payment.is-processing'),
    t('common.ok')
  );
};
