import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createUploadLink } from 'apollo-upload-client';

import { datadogRum } from '@datadog/browser-rum';
import { getAuth } from './contexts/auth-context';

require('isomorphic-fetch');

const authLink = new ApolloLink((operation, forward) => {
  const { token } = getAuth();
  if (token) {
    try {
      const tokenExpiration = JSON.parse(atob(token.split('.')[1])).exp * 1000;
      const currentTime = Date.now();
      if (tokenExpiration < currentTime) {
        datadogRum.addAction('USER_TOKEN_EXPIRED', {
          tokenExpiration,
          currentTime,
        });
        localStorage.removeItem('auth');
        window.location.href = '/sign-in';
      }
    } catch (error) {
      console.error('Error decoding token:', error);
    }
  } else {
    datadogRum.addAction('USER_TOKEN_ABSENT', {
      token,
      operationName: operation.operationName,
    });
  }
  operation.setContext(({ headers }: { headers: Record<string, string> }) => ({
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  }));

  return forward(operation);
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_SOCKET_URL || '',
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => {
      const { token } = getAuth();
      return { token };
    },
  },
});

const httpLink = createUploadLink({
  fetch,
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql/`,
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      datadogRum.addError(`GraphQL error: ${message}`, {
        locations,
        path,
        operationName: operation.operationName,
      });
    });
  }

  if (networkError) {
    const errorMessage = `[Network error]: ${
      networkError.message || 'Unknown network error'
    }`;
    const detailedError =
      networkError instanceof Error ? networkError : new Error(errorMessage);
    // Log the error with additional context, including status code if available
    datadogRum.addError(detailedError, {
      message: errorMessage,
      stack: networkError.stack || 'No stack available',
      operationName: operation.operationName,
      networkError,
    });
  }
});

// Retry link configuration
const retryLink = new RetryLink({
  attempts: {
    max: 1, // Maximum number of retry attempts
    retryIf: (error, _operation) => !!error, // Retry if an error occurs
  },
  delay: {
    initial: 300, // Initial delay in milliseconds before the first retry
    max: 2000, // Maximum delay in milliseconds between retries
    jitter: true, // Adds randomization to avoid thundering herd problem
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

export default new ApolloClient({
  link: ApolloLink.from([authLink, retryLink, errorLink, splitLink]),
  cache: new InMemoryCache({
    typePolicies: {
      OngoingUserLearningSessionType: {
        keyFields: ['learningSession', ['id']],
      },
    },
  }),
});
