import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
  Box,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';

import { useUser } from '@/contexts/user-context';
import Ava from '@/components/ava';

import { MonthlyScoreType } from '@/graphql';

import { useStyles } from './styles';

interface DuelRankingItemProps {
  score: MonthlyScoreType;
  divider?: boolean;
  onSelect?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const UserRankingItem: React.FC<DuelRankingItemProps> = ({
  score,
  divider,
  onSelect,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user } = useUser();

  const isMy = useMemo(() => score.user?.id === user?.id, [score.user, user]);

  return (
    <ListItem
      button
      divider={divider}
      className={clsx(isMy && classes.my)}
      {...rest}
    >
      <Box marginRight={4}>
        <Typography
          color="textSecondary"
          variant="h2"
          className={clsx(isMy && classes.myLabel)}
        >
          {String('0' + score.rank).slice(-2)}
        </Typography>
      </Box>
      <Ava src={score.user?.avatar} />
      <ListItemText
        primary={
          <Typography
            variant="subtitle2"
            className={clsx(classes.label, isMy && classes.myLabel)}
          >
            {score.user?.username}
          </Typography>
        }
      />
      <ListItemSecondaryAction>
        <Typography variant="caption" className={clsx(isMy && classes.myLabel)}>
          {t('duels.ranking.point', { points: score.points })}
        </Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default UserRankingItem;
