import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as EmptyDailyTipperIcon } from './index.svg';
import { DuelErrorTypes, TabNavIdsEnum } from '@/type';
import { useDailyTipperDataQuery } from '@/graphql';
import { useUser } from '@/contexts/user-context';
import { InfoBox } from '@/components/info-box';
import { LS_KEY_TABINDEX } from '@/utils/constants';

interface EmptyDailyTipperProps {
  disabled?: boolean;
  isDtPage?: boolean | undefined;
}

const EmptyDailyTipper: React.FC<EmptyDailyTipperProps> = ({
  disabled,
  isDtPage,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useUser();

  const dtData = useDailyTipperDataQuery();

  const handleOnclick = () => {
    const err = JSON.stringify(dtData.error?.networkError);
    const message = err && JSON.parse(err)?.result?.errors[0]?.message;
    if (message === DuelErrorTypes.ERROR_8) {
      localStorage.setItem(
        `${TabNavIdsEnum.CLASSROOMS}${LS_KEY_TABINDEX}`,
        '1'
      );
      history.replace('/classroom-rules/');
    } else {
      history.replace('/settings');
    }
  };

  return (
    <Box
      paddingY={7}
      paddingX={2}
      pt={user?.settings?.dailyTipperMode === false ? 2 : 7}
      display="flex"
      flexDirection="column"
      alignItems="center"
      boxSizing="border-box"
      width={isDtPage ? '100%' : 'initial'}
    >
      {user?.settings?.dailyTipperMode === false && !isDtPage && (
        <Box marginBottom={5}>
          <InfoBox>{t('common.daily-tipper.dt-on-pause-info')}</InfoBox>
        </Box>
      )}
      <Box marginBottom={3}>
        <EmptyDailyTipperIcon id="daily-tipper-card-el" />
      </Box>
      {!isDtPage && (
        <Box marginBottom={1}>
          <Typography variant="subtitle1">
            {t('common.daily-tipper.title')}
          </Typography>
        </Box>
      )}
      <Box marginBottom={7}>
        <Typography align="center" variant="body2">
          {t('common.daily-tipper.info')}
        </Typography>
      </Box>
      <Box display="flex" alignItems="flex-end" alignSelf="normal" flexGrow={1}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          disabled={disabled || !user?.network}
          onClick={handleOnclick}
        >
          {t('common.daily-tipper.start-new')}
        </Button>
      </Box>
    </Box>
  );
};

export default EmptyDailyTipper;
