import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import { useApolloClient } from '@apollo/client';

import { Box, Paper, Typography } from '@material-ui/core';

import AppButton from '@/components/app-button';
import { useUser } from '@/contexts/user-context';

import {
  ClassRoomSettingsQuery,
  GetActiveDuelsDocument,
  useCreateUserRoundMutation,
  UserDuelDocument,
  useUserDuelQuery,
} from '@/graphql';
import { CLASS_ROOM_SETTINGS } from '@/apollo/queries';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

import { useAnyGuestionModal } from '@/hooks/useModal';
import Fallback from '@/components/fallback';
import OverviewRoundsList from '../../components/overview-rounds-list';

const CreateRound: React.FC = () => {
  const { cache } = useApolloClient();
  const anyGuestionModal = useAnyGuestionModal();

  const history = useHistory();
  const { user } = useUser();
  const { duelId, sessionId, memberId } =
    useParams<{
      duelId: string;
      sessionId: string;
      memberId: string;
    }>();
  const { t } = useTranslation();

  const [createRound, { loading: createLoading }] =
    useCreateUserRoundMutation();

  const { data: userDuelData, error } = useUserDuelQuery({
    variables: { duelId },
  });
  const rounds = useMemo(
    () =>
      !!userDuelData?.duelData?.rounds ? userDuelData?.duelData?.rounds : [],
    [userDuelData]
  );
  const activeRoundNumber = rounds.length;
  const isYourTurn =
    userDuelData?.duelData?.isOnboardingDuel ||
    userDuelData?.duelData?.userScore?.youTurn;

  const activateDuelModes = useCallback(
    (sessionId: string) => {
      const settingsData: ClassRoomSettingsQuery | null = cache.readQuery({
        query: CLASS_ROOM_SETTINGS,
        variables: {
          learningSessionId: sessionId,
        },
      });
      cache.modify({
        id: `UserClassRoomLearningSessionSettingsType:${settingsData?.userClassRoomSettings?.id}`,
        fields: {
          isDuelModeEnabled() {
            return true;
          },
        },
      });
      cache.modify({
        id: `OngoingUserLearningSessionType:{"learningSession":{"id":"${sessionId}"}}`,
        fields: {
          isSessionActive() {
            return true;
          },
        },
      });
    },
    [cache]
  );

  const isAllQuestionsAnswered = useMemo(
    () =>
      !!userDuelData?.duelData?.rounds &&
      userDuelData.duelData.rounds[activeRoundNumber - 1]?.userQuestions.every(
        (it) => !!it.userAnswer
      ),
    [activeRoundNumber, userDuelData]
  );

  const handleStart = useCallback(async () => {
    try {
      const { data } = await createRound({
        variables: { duelId },
        refetchQueries: [
          { query: UserDuelDocument, variables: { duelId } },
          { query: GetActiveDuelsDocument },
        ],
      });
      if (!!data?.createRound?.round) {
        if (sessionId) activateDuelModes(sessionId);
        const roundId = data.createRound.round.id;
        const firstQ = sortBy(
          (data.createRound.round.userQuestions || []).filter(
            (it) => it.user?.id === user?.id
          ),
          ['questionNumber']
        )[0];
        if (memberId)
          cache.modify({
            id: `ClassRoomMemberType:${memberId}`,
            fields: {
              isAvailableForDuel() {
                return false;
              },
            },
          });
        if (!firstQ?.id) {
          console.error('NO_QUESTION_FOUND');
          return;
        }
        return history.push(
          `/duel/${duelId}/round/${roundId}/question/${firstQ?.id}`
        );
      }
    } catch (error) {
      anyGuestionModal.open();
      console.error('CREATE_ROUND', error);
    }
  }, [
    anyGuestionModal,
    createRound,
    duelId,
    sessionId,
    history,
    user,
    cache,
    memberId,
    activateDuelModes,
  ]);
  const goToNextRound = useCallback(() => {
    if (rounds.length) {
      const roundId = rounds[activeRoundNumber - 1]?.id;
      const firstQ = sortBy(
        (rounds[activeRoundNumber - 1]?.userQuestions || []).filter(
          (it) => it.user?.id === user?.id
        ),
        ['questionNumber']
      )[0];
      if (!firstQ?.id) {
        console.error('NO_QUESTION_FOUND');
        return;
      }
      return history.push(
        `/duel/${duelId}/round/${roundId}/question/${firstQ?.id}`
      );
    }
  }, [rounds, duelId, history, user, activeRoundNumber]);

  if (!!error) {
    console.error('TOPICS_QUERY', error);
    return <Fallback />;
  }

  return (
    <Box
      clone
      display="grid"
      gridTemplateRows="1fr auto"
      gridGap={16}
      flexGrow={1}
      pb={5}
    >
      <Paper data-cy-create-round elevation={0}>
        <Box>
          <OverviewRoundsList maxRoundsCount={3} />
        </Box>
        <Box pb={2} px={2}>
          <AppButton
            fullWidth
            color="primary"
            variant="contained"
            loading={createLoading}
            data-cy-nex-round
            onClick={
              isAllQuestionsAnswered || activeRoundNumber === 0
                ? handleStart
                : goToNextRound
            }
          >
            {isAllQuestionsAnswered || activeRoundNumber === 0
              ? activeRoundNumber + 1
              : activeRoundNumber}
            . {t('duels.start-next-round')}
          </AppButton>
          <Box textAlign="center" mt={3} mb={-2}>
            <Typography
              variant="caption"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#647485',
              }}
            >
              {!isYourTurn && (
                <HourglassEmptyIcon
                  style={{
                    width: 16,
                    height: 16,
                    marginRight: 4,
                  }}
                />
              )}
              {t(isYourTurn ? 'common.duel.your-turn' : 'duels.opponent-turn')}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateRound;
