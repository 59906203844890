import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Paper, Typography } from '@material-ui/core';
import * as Form from '@/components/form';
import {
  GetUserQuizSessionSettingsDocument,
  QuizSessionsAnnouncedDocument,
  QuizSessionsOngoingDocument,
  QuizSessionsOnHomePageDocument,
  QuizTimeDetailsDocument,
  useAddWaitingUserNotificationMutation,
  useApplyQuizTimeCodeMutation,
} from '@/graphql';
import AppButton from '@/components/app-button';
import StackLayout from '../../components/stack-layout';
import { useApolloClient } from '@apollo/client';
import { isEmpty } from 'lodash';
import { useQuizFullModal } from '@/hooks/useModal';
import refetchQueries from 'refetch-queries';
import { TabNavIdsEnum } from '@/type';
import { LS_KEY_TABINDEX } from '@/utils/constants';

interface Form {
  code: string;
}

type QuizTimeParams = {
  quizSessionId: string;
};

const QuizActivateCode: React.FC = () => {
  const client = useApolloClient();
  const history = useHistory();
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const { register, setError, setValue, watch, errors } = useForm<Form>({
    mode: 'onChange',
    defaultValues: { code: '' },
  });

  const { quizSessionId } = useParams<QuizTimeParams>();
  const [joinQuizTime] = useApplyQuizTimeCodeMutation();
  const [addWaitingUser] = useAddWaitingUserNotificationMutation();
  const [isNotFullCodeEntered, setIsNotFullCodeEntered] =
    useState<boolean>(true);

  const subscribeUserToNotification = useCallback(() => {
    try {
      addWaitingUser({
        variables: {
          sessionId: quizSessionId,
        },
      });
    } catch (err) {
      console.error('useAddWaitingUserNotificationMutation', err);
    }
  }, [addWaitingUser, quizSessionId]);

  const quizIsFullModal = useQuizFullModal(subscribeUserToNotification);

  const sendCode = useCallback(async () => {
    setSubmitted(true);
    try {
      const res = await joinQuizTime({
        variables: {
          code: Number(watch('code')),
          sessionId: quizSessionId,
        },
        refetchQueries: [
          {
            query: QuizSessionsOngoingDocument,
          },
          {
            query: QuizSessionsAnnouncedDocument,
          },
          {
            query: QuizTimeDetailsDocument,
            variables: { quizSessionId },
          },
          {
            query: GetUserQuizSessionSettingsDocument,
            variables: { quizSessionId },
          },
          {
            query: QuizSessionsOnHomePageDocument,
            variables: { startLess24Hours: true },
          },
        ],
      });
      const id = res?.data?.joinToQuiz?.quizSession?.id;
      const historyState: any = history.location.state || {};
      localStorage.setItem(
        `${TabNavIdsEnum.QUIZTIME}${id}${LS_KEY_TABINDEX}`,
        '0'
      );
      history.replace(`/quiztime/${id}`, {
        ...historyState,
        showSnackQtJoined: true,
      });
    } catch (error) {
      console.error('useApplyQuizTimeCodeMutation', error);
      if (
        error.networkError.result.errors[0].message ===
        'Quiz user capacity achieved!'
      )
        quizIsFullModal.open();
      else {
        setError('code', {
          type: 'server',
          message: t('fields.activation-code.validation.invalid'),
        });
      }
      setSubmitted(false);
      refetchQueries(client, [
        { query: QuizTimeDetailsDocument, variables: { quizSessionId } },
      ]);
    }
  }, [
    joinQuizTime,
    setError,
    t,
    watch,
    client,
    quizIsFullModal,
    quizSessionId,
    history,
  ]);

  return (
    <StackLayout title={t('quiztime.join-title')}>
      <Box display="grid" height="100%">
        <Paper>
          <Box paddingX={3} paddingTop={7} paddingBottom={5}>
            <Typography variant="body1">
              {t('classrooms-activation-code.label')}
            </Typography>
            <Box marginTop={5}>
              <Form.Row>
                <Form.TextField
                  fullWidth
                  required
                  name="code"
                  variant="outlined"
                  label={t('fields.activation-code.label')}
                  errors={errors.code}
                  onChange={(e) => {
                    const val = e?.target?.value?.replace(/[^0-9]/g, '') || '';
                    if (val && val.length > 6)
                      setError('code', {
                        type: 'string',
                        message: t(
                          'fields.activation-code.validation.invalid-digits'
                        ),
                      });
                    if (val && val.length === 6) setIsNotFullCodeEntered(false);
                    else setIsNotFullCodeEntered(true);
                    setValue('code', val);
                    setSubmitted(false);
                  }}
                  inputRef={register()}
                />
              </Form.Row>
            </Box>
            <Box marginTop={7}>
              <AppButton
                fullWidth
                color="primary"
                variant="contained"
                disabled={!isEmpty(errors) || submitted || isNotFullCodeEntered}
                onClick={sendCode}
              >
                {t('common.confirm')}
              </AppButton>
            </Box>
          </Box>
        </Paper>
      </Box>
    </StackLayout>
  );
};

export default QuizActivateCode;
