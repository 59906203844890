import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    display: 'grid',
    gridRowGap: `${theme.spacing(4)}px`,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    paddingRight: theme.spacing(1),
  },
  textColor: {
    color: '#929DA8',
  },
  footer: {
    display: 'grid',
    gridRowGap: `${theme.spacing(6)}px`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
  },
  count: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.dark,
  },
  lowerCaseCaption: {
    fontSize: '12px',
    letterSpacing: '0.4px',
    textTransform: 'none',
    lineHeight: '16px',
    color: '#4A647C',
  },
}));
