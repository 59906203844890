import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import isEmail from 'validator/lib/isEmail';

import { Box, Typography } from '@material-ui/core';

import * as Form from '@/components/form';
import AppButton from '@/components/app-button';

import { useForgotPasswordMutation } from '@/graphql';

interface Form {
  email: string;
}

const Forgot: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { register, errors, watch, setError, setValue } = useForm<Form>({
    mode: 'onChange',
    defaultValues: { email: '' },
  });

  const [forgotPassword, { loading }] = useForgotPasswordMutation();

  const emailRules = {
    validate: (email: string) =>
      !isEmail(email)
        ? (t('fields.email.validation.regex') as string)
        : undefined,
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.replace(/\s/g, '');
    setValue('email', value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const forgot = useCallback(async () => {
    const email = watch('email');
    try {
      const { data } = await forgotPassword({
        variables: { email },
      });

      if (data && !data.userForgotPassword) {
        throw new Error('userForgotPassword couldnt be null');
      }

      if (data && data.userForgotPassword?.user?.id) {
        history.push(`/forgot-password/completed?${email}`);
        return;
      }
    } catch {
      return setError('email', {
        type: 'server',
        message: t('server.error.user-not-exist'),
      });
    }
  }, [forgotPassword, history, setError, t, watch]);

  const isValid = useMemo(
    () => isEmpty(errors) && !!watch('email'),
    [errors, watch]
  );

  return (
    <Box>
      <Box paddingTop={3}>
        <Typography variant="body2">
          {t('forgot-password.reset.info')}
        </Typography>
      </Box>
      <Box paddingY={7}>
        <Form.Row noPadding>
          <Form.TextField
            fullWidth
            required
            data-cy-email
            variant="outlined"
            name="email"
            label={t('fields.email.label')}
            errors={errors.email}
            inputRef={register(emailRules)}
            onChange={handleChange}
          />
        </Form.Row>
      </Box>
      <AppButton
        fullWidth
        data-cy-forgot-btn
        color="primary"
        variant="contained"
        disabled={!isValid}
        loading={loading}
        onClick={forgot}
      >
        {t('common.reset')}
      </AppButton>
    </Box>
  );
};

export default Forgot;
