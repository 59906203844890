import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Chip, Typography } from '@material-ui/core';
import GroupIcon from '@/components/icons/group-outlined';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import {
  GetUserQuizSessionSettingsDocument,
  QuizSessionsOnHomePageDocument,
  QuizTimeDetailsDocument,
  QuizTimeSessionStatus,
  QuizTimeUsersDocument,
  usePauseQuizMutation,
  useQuizSessionsFinishedQuery,
  UserQuizSessionSettingsUserStatus,
  useStartQuizMutation,
} from '@/graphql';
import { useSnackbarContext } from '@/contexts/snackbar-context';
import { useFitText } from '@/hooks/useFitText';
import { usePauseQuizModal } from '@/hooks/useModal';
import RankLabel from '../rank-label';
import QuizTimeBlock from '../time-block';
import { QuizTimeBlockWithTime, TabNavIdsEnum } from '@/type';
import { useStyles } from './styles';
import { LS_KEY_TABINDEX } from '@/utils/constants';

type QuizTimeHeaderProps = {
  quizSessionId: string;
  quizStatus?: QuizTimeSessionStatus | null | undefined;
  image?: string | null;
  title?: string;
  organizerName?: string | null;
  maxCount?: number | null;
  startDate: string;
  startDateTime: string;
  finishDateTime: string;
  isForPremiumOnly?: boolean | null;
  duration?: any;
  numberOfMembers?: number | null;
  userStatus?: UserQuizSessionSettingsUserStatus | null;
  isQuizSessionAvailableToStart?: boolean | null;
};

const QuizTimeHeader: React.FC<QuizTimeHeaderProps> = ({
  quizSessionId,
  quizStatus,
  image,
  startDateTime,
  finishDateTime,
  title,
  organizerName,
  maxCount,
  isForPremiumOnly,
  numberOfMembers,
  userStatus,
  duration,
  isQuizSessionAvailableToStart,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [startQuizMutation] = useStartQuizMutation();
  const [pauseQuizMutation] = usePauseQuizMutation();
  const { setCustomSnack } = useSnackbarContext();
  const [isPaused, setIsPaused] = useState<boolean>(
    userStatus && userStatus === UserQuizSessionSettingsUserStatus.Paused
      ? true
      : false
  );

  const { data, loading } = useQuizSessionsFinishedQuery();
  const userRank = data?.finishedQuizSessions?.filter(
    (it) => it?.id === quizSessionId
  )[0]?.userRank;
  const lineHeight = title && title?.length > 31 ? 50 : 26;

  const { fontSize, ref: TitleDivRef } = useFitText();

  const startQuizTime = useCallback(async () => {
    try {
      await startQuizMutation({
        variables: {
          quizSessionId: quizSessionId,
        },
        refetchQueries: [
          {
            query: QuizTimeDetailsDocument,
            variables: { quizSessionId },
          },
          {
            query: GetUserQuizSessionSettingsDocument,
            variables: { quizSessionId },
          },
          {
            query: QuizTimeUsersDocument,
            variables: { quizSessionId },
          },
          {
            query: QuizSessionsOnHomePageDocument,
            variables: { startLess24Hours: true },
          },
          {
            query: GetUserQuizSessionSettingsDocument,
            variables: { quizSessionId },
          },
        ],
      });
      const historyState: any = history.location.state || {};
      history.replace({
        state: { ...historyState, showSnackQtStarted: true },
      });
    } catch (err) {
      console.error('useStartQuizMutation', err);
      setCustomSnack({
        visibility: true,
        message: 'quiztime.snackBars.snack-message-failed-start',
      });
    }
  }, [quizSessionId, startQuizMutation, history, setCustomSnack]);

  const pauseQuiz = useCallback(() => {
    try {
      pauseQuizMutation({
        variables: {
          pauseMode: !isPaused,
          quizSessionId: quizSessionId,
        },
        refetchQueries: [
          {
            query: QuizSessionsOnHomePageDocument,
            variables: { startLess24Hours: true },
          },
        ],
      });
      !isPaused &&
        setCustomSnack({
          visibility: true,
          message: 'quiztime.snackBars.snack-message-paused',
          durationInMs: 10000,
        });
    } catch (err) {
      console.error('usePauseQuizMutation', err);
    }
  }, [isPaused, pauseQuizMutation, quizSessionId, setCustomSnack]);

  const pauseModal = usePauseQuizModal(
    () => pauseCancelRedirectToTabActivity(),
    pauseQuiz
  );
  useEffect(() => {
    if (userStatus && userStatus === UserQuizSessionSettingsUserStatus.Paused)
      setIsPaused(true);
    if (userStatus && userStatus !== UserQuizSessionSettingsUserStatus.Paused)
      setIsPaused(false);
  }, [userStatus]);

  const pauseCancelRedirectToTabActivity = useCallback(() => {
    localStorage.setItem(`${TabNavIdsEnum.QUIZTIME}${LS_KEY_TABINDEX}`, '0');
    history.replace(`/quiztime/${quizSessionId}/`, {
      manualQuizTabChangeOn: true,
    });
  }, [history, quizSessionId]);

  useEffect(() => {
    return () => {
      localStorage.setItem(`${TabNavIdsEnum.QUIZTIME}${LS_KEY_TABINDEX}`, '0');
    };
  }, [quizSessionId]);

  return (
    <Box className={classes.root} style={{ backgroundImage: `url(${image})` }}>
      {quizStatus === QuizTimeSessionStatus.Finished && !loading && (
        <RankLabel userRank={userRank} />
      )}
      <Box className={classes.overlay}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={isForPremiumOnly ? 'space-between' : 'flex-end'}
        >
          {isForPremiumOnly && (
            <Chip
              label="PREMIUM"
              color="default"
              variant="outlined"
              size="small"
              style={{
                borderColor: '#fff',
                background: '#fff',
                color: '#DD6903',
              }}
            />
          )}
          {quizStatus === QuizTimeSessionStatus.InProgress &&
            (userStatus === UserQuizSessionSettingsUserStatus.InProgress ||
              userStatus === UserQuizSessionSettingsUserStatus.Paused) && (
              <Typography
                variant="caption"
                className={!isPaused ? classes.statusIsActive : ''}
              >
                • {!isPaused ? t('common.active') : t('common.pause')}
              </Typography>
            )}
        </Box>
        <Box
          display="grid"
          gridRowGap={12}
          gridTemplateRows="min-content min-content"
          marginTop={3}
        >
          <Box maxHeight={52} overflow="hidden">
            <div style={{ height: lineHeight }} ref={TitleDivRef}>
              <Typography color="inherit" style={{ fontSize: `${fontSize}` }}>
                {title}
              </Typography>
            </div>
          </Box>
          <Typography color="inherit" variant="caption">
            {organizerName}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="end"
          height={36}
        >
          <QuizTimeBlock
            quizStatus={quizStatus}
            userStatus={userStatus}
            startDateTime={startDateTime || ''}
            finishDateTime={finishDateTime || ''}
            duration={duration}
            type={QuizTimeBlockWithTime.QT_HEADER}
            quizSessionId={quizSessionId}
          />
          {(quizStatus === QuizTimeSessionStatus.Announced ||
            quizStatus === QuizTimeSessionStatus.Finished ||
            quizStatus === QuizTimeSessionStatus.WaitingForFinish ||
            !userStatus ||
            userStatus === UserQuizSessionSettingsUserStatus.Out ||
            userStatus ===
              UserQuizSessionSettingsUserStatus.WaitingForJoining ||
            (userStatus &&
              quizStatus === QuizTimeSessionStatus.InProgress &&
              userStatus !== UserQuizSessionSettingsUserStatus.Joined &&
              userStatus !== UserQuizSessionSettingsUserStatus.InProgress &&
              userStatus !== UserQuizSessionSettingsUserStatus.Paused)) && (
            <Box display="flex" alignItems="center">
              <GroupIcon fontSize="small" />
              <Typography
                color="inherit"
                variant="overline"
                className={classes.participants}
              >
                {maxCount
                  ? `${numberOfMembers}/${maxCount}`
                  : t('quiztime.details.unlimited')}
              </Typography>
            </Box>
          )}
          {quizStatus === QuizTimeSessionStatus.InProgress &&
            userStatus === UserQuizSessionSettingsUserStatus.Joined && (
              <Box display="flex" alignItems="center">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={startQuizTime}
                  className={`${classes.startBtn} ${
                    !isQuizSessionAvailableToStart
                      ? classes.startBtnDisabled
                      : ''
                  }`}
                >
                  {t('common.start')}
                </Button>
              </Box>
            )}
          {quizStatus === QuizTimeSessionStatus.InProgress &&
            (userStatus === UserQuizSessionSettingsUserStatus.InProgress ||
              userStatus === UserQuizSessionSettingsUserStatus.Paused) && (
              <Box
                display="flex"
                alignItems="center"
                onClick={isPaused ? pauseQuiz : () => pauseModal.open()}
              >
                {isPaused ? (
                  <PlayCircleOutlineIcon />
                ) : (
                  <PauseCircleOutlineIcon />
                )}
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default QuizTimeHeader;
