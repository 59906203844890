import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchLayout from '../../components/search-layout';

const QuizSearchMember = () => {
  const { t } = useTranslation();

  return (
    <SearchLayout
      inputPlaceholder={t('classrooms.find-participants')}
      contentPlaceholder={t('classrooms.find-tip')}
      isMembersList
      isForQuiz
    />
  );
};

export default QuizSearchMember;
