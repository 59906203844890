import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(17),
  },
  close: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    position: 'absolute',
  },
  adventageListItem: {
    display: 'flex',
    alignItems: 'center',
  },

  circleIcon: {
    marginRight: 13,
  },
}));
