import { useReadNotificationMutation } from '@/graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, Typography, Button } from '@material-ui/core';
import SmallLogoIcon from '@/components/icons/small-logo';
import { useHistory } from 'react-router-dom';
import { getSubscriptionsPath } from '@/utils/helpers';

interface PremiumExpiredProps {
  premiumExpiredNotificationId?: string;
}

const PremiumExpiredModal: React.FC<PremiumExpiredProps> = ({
  premiumExpiredNotificationId,
}) => {
  const [readNotification] = useReadNotificationMutation();
  const { t } = useTranslation();
  const history = useHistory();
  const [opened, setOpened] = useState(true);

  const onClose = () => {
    if (premiumExpiredNotificationId) {
      const readNotificationWithError = async () => {
        try {
          await readNotification({
            variables: { id: premiumExpiredNotificationId },
          });
        } catch (error) {
          console.error('useReadNotificationMutation', error);
        }
      };
      readNotificationWithError();
      setOpened(false);
    }
  };

  return (
    <Dialog data-cy-premium-is-expired-dialog open={opened} onClose={onClose}>
      <Box display="flex" flexDirection="column" p={6} pt={5}>
        <Box marginBottom={4}>
          <SmallLogoIcon />
        </Box>
        <Typography variant="h2">
          {t('dialogs.premium-expired.title')}
        </Typography>
        <Box marginTop={3}>
          <Typography variant="body2">
            {t('dialogs.premium-expired.text')}
          </Typography>
        </Box>
        <Box mt={7} display="flex" flexDirection="column">
          <Button
            onClick={() => {
              onClose();
              history.push(getSubscriptionsPath());
            }}
            variant="contained"
            color="primary"
          >
            {t('dialogs.premium-expired.btn')}
          </Button>
          <Button
            variant="outlined"
            style={{ color: '#2B75B3', marginTop: 16 }}
            onClick={onClose}
          >
            {t('common.later')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default PremiumExpiredModal;
