import React from 'react';

import { Box, CircularProgress, Paper } from '@material-ui/core';

const Loading: React.FC<{ confirmPage?: boolean }> = ({ confirmPage }) => {
  return !confirmPage ? (
    <Box
      clone
      display="grid"
      alignItems="center"
      justifyItems="center"
      height="100%"
    >
      <Paper elevation={0}>
        <CircularProgress />
      </Paper>
    </Box>
  ) : (
    <Box
      clone
      display="grid"
      alignItems="center"
      justifyItems="center"
      position="absolute"
      top="calc(50% - 40px)"
      left={0}
      right={0}
    >
      <Paper elevation={0}>
        <CircularProgress />
      </Paper>
    </Box>
  );
};

export default Loading;
