import { LekSessionStatus } from '@/graphql';
import { useCountdown } from '@/hooks/useCountdown';
import { useApolloClient } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type LekEndsInProps = {
  lekSessionId: string;
  finishDate: string;
};

const LekEndsIn: React.FC<LekEndsInProps> = ({ lekSessionId, finishDate }) => {
  const { t } = useTranslation();
  const { cache } = useApolloClient();

  const duration = useMemo(
    () => +DateTime.fromISO(finishDate).diffNow(),
    [finishDate]
  );
  const { timeLeft, timeLeftInMillis } = useCountdown(duration);

  useEffect(() => {
    if (timeLeftInMillis < 1)
      cache.modify({
        id: `LEKSessionType:${lekSessionId}`,
        fields: {
          status() {
            return LekSessionStatus.Finished;
          },
        },
      });
  }, [cache, timeLeftInMillis, lekSessionId]);

  return timeLeftInMillis > 1 ? (
    <Typography variant="caption">
      {t('lek.ends-in')} <span style={{ color: '#647485' }}>{timeLeft}</span>
    </Typography>
  ) : (
    <></>
  );
};

export default LekEndsIn;
