import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const AddUsersIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12C13.93 12 15.5 10.43 15.5 8.5C15.5 6.57 13.93 5 12 5C10.07 5 8.5 6.57 8.5 8.5C8.5 10.43 10.07 12 12 12ZM5 15V12H8V10H5V7H3V10H0V12H3V15H5ZM12 13.75C9.66 13.75 5 14.92 5 17.25V19H19V17.25C19 14.92 14.34 13.75 12 13.75ZM12 15.75C10.21 15.75 8.18 16.42 7.34 17H16.66C15.82 16.42 13.79 15.75 12 15.75ZM13.5 8.5C13.5 7.67 12.83 7 12 7C11.17 7 10.5 7.67 10.5 8.5C10.5 9.33 11.17 10 12 10C12.83 10 13.5 9.33 13.5 8.5ZM17 12C18.93 12 20.5 10.43 20.5 8.5C20.5 6.57 18.93 5 17 5C16.76 5 16.52 5.02 16.29 5.07C17.05 6.01 17.5 7.2 17.5 8.5C17.5 9.8 17.03 10.98 16.27 11.92C16.51 11.97 16.75 12 17 12ZM21 17.25C21 15.89 20.32 14.83 19.32 14.02C21.56 14.49 24 15.56 24 17.25V19H21V17.25Z"
        fill="#2B75B3"
      />
      <path
        d="M12.2565 1.95665C12.5183 2.22895 12.989 2.229 13.2508 1.95665L14.0039 1.20361C14.6382 0.520838 13.6927 -0.425009 13.0095 0.209399L12.7536 0.465299L12.4977 0.209399C11.8145 -0.425009 10.869 0.520885 11.5033 1.20361C11.5033 1.20366 12.2565 1.95665 12.2565 1.95665Z"
        fill="#2B75B3"
      />
      <path
        d="M15.9625 2.95665C16.2244 3.22895 16.6951 3.229 16.9569 2.95665L17.71 2.20361C18.3443 1.52084 17.3987 0.574991 16.7156 1.2094L16.4597 1.4653L16.2038 1.2094C15.5206 0.574991 14.5751 1.52089 15.2094 2.20361C15.2094 2.20366 15.9625 2.95665 15.9625 2.95665Z"
        fill="#2B75B3"
      />
    </SvgIcon>
  );
};

export default AddUsersIcon;
