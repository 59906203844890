import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    fontSize: 'inherit',
    verticalAlign: 'middle',
  },
});

const СupIcon: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path d="M18.9357 4.26931H17.4294V2.95496C17.4294 2.52819 17.1156 2.18182 16.7288 2.18182H7.27095C6.88423 2.18182 6.57036 2.52819 6.57036 2.95496V4.26931H5.06411C4.67739 4.26931 4.36353 4.61568 4.36353 5.04246V6.43412C4.36353 8.11185 5.39619 9.50196 6.82818 9.87771C7.16166 11.0962 7.8146 12.1987 8.75338 12.9749C9.4918 13.5873 10.3731 13.9692 11.2993 14.1022V17.4963C9.55485 17.538 8.14668 19.1091 8.14668 21.045C8.14668 21.4718 8.46054 21.8182 8.84726 21.8182H15.1525C15.5392 21.8182 15.8531 21.4718 15.8531 21.045C15.8531 19.1091 14.4449 17.538 12.7005 17.4963V14.1037C13.6182 13.9707 14.5052 13.5904 15.2464 12.9765C16.1852 12.2002 16.8381 11.0977 17.1716 9.87926C18.605 9.50351 19.6363 8.11339 19.6363 6.43412V5.04246C19.6363 4.61568 19.3238 4.26931 18.9357 4.26931ZM5.76469 6.43412V5.8156H6.57036V8.06855C6.09116 7.70827 5.76469 7.12531 5.76469 6.43412ZM14.3118 20.2734H9.68796C9.96259 19.5467 10.6127 19.0364 11.3694 19.0364H12.6304C13.387 19.0364 14.0372 19.5467 14.3118 20.2734ZM16.0282 8.15978C16.0282 9.56072 15.4215 10.8983 14.4071 11.7379C13.055 12.8605 10.9896 12.8961 9.59268 11.7379C8.57824 10.8983 7.97153 9.56072 7.97153 8.15978V3.72811H16.0282V8.15978ZM18.2351 6.43412C18.2351 7.12377 17.9086 7.70672 17.4294 8.06701V5.8156H18.2351V6.43412Z" />
    </SvgIcon>
  );
};

export default СupIcon;
