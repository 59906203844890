import React, { useCallback, useContext, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { LS_KEY_AUTH } from '@/utils/constants';
import { datadogRum } from '@datadog/browser-rum';

interface IAuthContext {
  isAuthorized: boolean;
  token: string;
  signIn: (token: string) => void;
  signOut: () => void;
}

type AuthTokenT = { isAuthorized: boolean; token: string };

export const AuthContext =
  React.createContext<IAuthContext | undefined>(undefined);

let authToken: AuthTokenT = { isAuthorized: false, token: '' };

export const getAuth = (): AuthTokenT => {
  if (!authToken.token) {
    const authData = localStorage.getItem(LS_KEY_AUTH);
    authToken = authData
      ? JSON.parse(authData)
      : { isAuthorized: false, token: '' };
  }
  return authToken;
};

const saveAuth = (data: { isAuthorized: boolean; token: string }) => {
  authToken = data;
  localStorage.setItem(LS_KEY_AUTH, JSON.stringify(data));
};

export const AuthProvider: React.FC = (props) => {
  const { cache } = useApolloClient();
  const { children } = props;
  const [state, setState] = useState<AuthTokenT>(getAuth());

  const reload = () => {
    setState(getAuth());
  };

  const signIn = useCallback((token: string) => {
    saveAuth({ isAuthorized: true, token });
    reload();
  }, []);

  const signOut = useCallback(() => {
    cache.reset();
    datadogRum.stopSession();

    saveAuth({ isAuthorized: false, token: '' });
    reload();
  }, [cache]);

  const globalState: IAuthContext = React.useMemo(
    () => ({ ...state, signIn, signOut }),
    [state, signIn, signOut]
  );

  return (
    <AuthContext.Provider value={globalState}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const state = useContext(AuthContext);
  if (!state) throw new Error('AuthContext not found');
  return state;
};
