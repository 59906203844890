import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import AccessAlarmIcon from '@/components/icons/alarm';
import { useCountdown } from '@/hooks/useCountdown';
import { DateTime } from 'luxon';
import { TIMER_REFRESH_CYCLE } from '@/utils/constants';

type QuizCountDownProps = {
  finishDateTime: string;
  timeToStart?: (isTimeToStart?: boolean, isTimeToFinish?: boolean) => void;
  handleFinish?: () => void;
  isCountToStartDateTime?: boolean;
  isCountToFinishDateTime?: boolean;
};

const QuizCountDown: React.FC<QuizCountDownProps> = ({
  finishDateTime,
  timeToStart,
  handleFinish,
  isCountToStartDateTime,
  isCountToFinishDateTime,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [finishWasHandled, setFinishWasHandled] = useState(false);

  const durationLeft = useMemo(
    () => +DateTime.fromISO(finishDateTime).diffNow(),
    [finishDateTime]
  );

  const { timeLeft, timeLeftInMillis } = useCountdown(
    durationLeft,
    false,
    false
  );

  useEffect(() => {
    if (timeToStart && timeLeftInMillis < TIMER_REFRESH_CYCLE) {
      timeToStart(isCountToStartDateTime, isCountToFinishDateTime);
    }
  }, [
    timeLeftInMillis,
    timeToStart,
    isCountToStartDateTime,
    isCountToFinishDateTime,
  ]);

  useEffect(() => {
    if (
      !finishWasHandled &&
      handleFinish &&
      timeLeftInMillis < TIMER_REFRESH_CYCLE
    ) {
      setFinishWasHandled(true);
      handleFinish();
    }
  }, [timeLeftInMillis, handleFinish, finishWasHandled]);

  return (
    <Box display="flex" flexDirection="row" alignItems="end" gridGap={5}>
      <AccessAlarmIcon />
      {isCountToFinishDateTime && (
        <Typography color="inherit" variant="body1" className={classes.date}>
          {timeLeftInMillis < TIMER_REFRESH_CYCLE
            ? t('time-relative.less-minute')
            : timeLeft}{' '}
          {t('time-relative.left')}
        </Typography>
      )}
      {isCountToStartDateTime && (
        <Typography color="inherit" variant="body1" className={classes.date}>
          {timeLeftInMillis < TIMER_REFRESH_CYCLE
            ? t('time-relative.less-minute')
            : timeLeft}{' '}
          {t('time-relative.till-begining')}
        </Typography>
      )}
    </Box>
  );
};

export default QuizCountDown;
