import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(226, 245, 253, 0.48)',
  },
  hyperlinkIcon: {
    marginRight: 10,
  },
  box: {
    boxShadow: 'inset 0px -1px 0px #E6EEFA',
    width: '100%',
    paddingTop: 2,
    paddingBottom: 2,
    '&:last-child': {
      boxShadow: 'none',
    },
  },
}));
