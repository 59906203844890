import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    position: 'relative',
    '&>.MuiTabs-root': {
      position: 'fixed',
      top: 55,
      left: 0,
      right: 0,
      zIndex: 1,
    },
  },
  emptyWrap: {
    height: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
  },
  tabPanel: {
    paddingTop: 48,
    paddingBottom: 8,
  },
}));
