import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  empty: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    height: `calc(100% - ${theme.spacing(8)}px)`,
    backgroundColor: theme.palette.common.white,
  },
  startDuelBtn: {
    position: 'fixed',
    bottom: 68,
    left: 16,
    right: 16,
    maxWidth: 'calc(100% - 32px)',
    filter: 'drop-shadow(2px 3px 6px rgba(17, 38, 63, 0.28))',
    '&:hover': {
      boxShadow: 'none',
    },
  },
}));
