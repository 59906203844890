import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: 80,
    width: 100,
  },
});

const Notification: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 100 80"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path
        d="M97.2403 64.2666H91.6404H81.3737C79.387 64.2666 77.7737 62.68 77.7737 60.6933V60.68C77.7737 58.6933 79.387 57.0666 81.3737 57.0666H91.6404V57.0933C93.627 57.0933 95.2403 55.4933 95.2403 53.5066C95.2403 51.52 93.627 49.8933 91.6404 49.8933V49.8666H88.5737C86.587 49.8666 84.9737 48.28 84.9737 46.2933V46.28C84.9737 44.2933 86.587 42.6666 88.5737 42.6666H91.6404H95.907C97.8937 42.6666 99.507 41.08 99.507 39.0933V39.08C99.507 37.0933 97.8937 35.4666 95.907 35.4666H88.947C87.6003 28.5333 84.2137 21.7333 78.7737 16.2933C65.267 2.78666 43.9203 1.91999 29.4003 13.6533L17.2537 13.4667C15.267 13.44 13.6403 15.04 13.6137 17.0267C13.6137 17.4533 13.6803 17.8667 13.8137 18.2533C14.2937 19.64 15.6137 20.6533 17.1737 20.6667L23.4537 20.8C25.4403 20.8267 27.0403 22.4533 27.0137 24.44C26.987 26.4267 25.3603 28.0266 23.3737 28L13.707 27.8666L3.667 27.7333C1.65366 27.72 0.0269952 29.3067 0.000328555 31.2933C-0.0263381 33.28 1.57366 34.9066 3.56033 34.9333L13.6003 35.0666L16.067 35.1066C18.0537 35.1333 19.6537 36.76 19.627 38.7466C19.6003 40.7333 17.9737 42.3333 15.987 42.3066L13.5203 42.2533L12.187 42.2266C10.2003 42.2 8.57366 43.8 8.547 45.7866C8.52033 47.7733 10.1203 49.4 12.107 49.4266L16.2937 49.4933C17.6403 56.5066 21.027 63.1866 26.467 68.6133C39.8537 82.0266 61.0137 83.0666 75.5337 71.4666H97.2403C99.227 71.4666 100.84 69.88 100.84 67.8933V67.88C100.84 65.8933 99.227 64.2666 97.2403 64.2666Z"
        fill="#E6EEFA"
      />
      <path
        d="M36.4004 74.4H31.6004C31.3737 74.4 31.2003 74.5734 31.2003 74.8C31.2003 75.0267 31.3737 75.2 31.6004 75.2H36.4004C36.6271 75.2 36.8004 75.0267 36.8004 74.8C36.8004 74.5734 36.6271 74.4 36.4004 74.4Z"
        fill="#2C3E50"
      />
      <path
        d="M47.6003 74.4H38.8004C38.5737 74.4 38.4004 74.5734 38.4004 74.8C38.4004 75.0267 38.5737 75.2 38.8004 75.2H47.6003C47.827 75.2 48.0003 75.0267 48.0003 74.8C48.0003 74.5734 47.827 74.4 47.6003 74.4Z"
        fill="#2C3E50"
      />
      <path
        d="M81.2003 62.4001H78.0003C77.7737 62.4001 77.6003 62.5734 77.6003 62.8C77.6003 63.0267 77.7737 63.2 78.0003 63.2H81.2003C81.427 63.2 81.6003 63.0267 81.6003 62.8C81.6003 62.5734 81.427 62.4001 81.2003 62.4001Z"
        fill="#2C3E50"
      />
      <path
        d="M92.4003 62.4001H83.6003C83.3737 62.4001 83.2003 62.5734 83.2003 62.8C83.2003 63.0267 83.3737 63.2 83.6003 63.2H92.4003C92.627 63.2 92.8003 63.0267 92.8003 62.8C92.8003 62.5734 92.627 62.4001 92.4003 62.4001Z"
        fill="#2C3E50"
      />
      <path
        d="M88.0004 60H81.6004C81.3737 60 81.2004 60.1733 81.2004 60.4C81.2004 60.6267 81.3737 60.8 81.6004 60.8H88.0004C88.227 60.8 88.4004 60.6267 88.4004 60.4C88.4004 60.1733 88.227 60 88.0004 60Z"
        fill="#2C3E50"
      />
      <path
        d="M40.4004 76.8H20.8004C20.5737 76.8 20.4004 76.9733 20.4004 77.2C20.4004 77.4267 20.5737 77.6 20.8004 77.6H40.4004C40.627 77.6 40.8004 77.4267 40.8004 77.2C40.8004 76.9733 40.627 76.8 40.4004 76.8Z"
        fill="#2C3E50"
      />
      <path
        d="M45.2004 76.8H43.6004C43.3737 76.8 43.2004 76.9733 43.2004 77.2C43.2004 77.4267 43.3737 77.6 43.6004 77.6H45.2004C45.427 77.6 45.6004 77.4267 45.6004 77.2C45.6004 76.9733 45.427 76.8 45.2004 76.8Z"
        fill="#2C3E50"
      />
      <path
        d="M46.8003 3.60004H45.6003C45.3737 3.60004 45.2003 3.77337 45.2003 4.00004C45.2003 4.2267 45.3737 4.40004 45.6003 4.40004H46.8003C47.027 4.40004 47.2003 4.2267 47.2003 4.00004C47.2003 3.77337 47.027 3.60004 46.8003 3.60004Z"
        fill="#2C3E50"
      />
      <path
        d="M52.8004 3.60004H51.6004C51.3737 3.60004 51.2004 3.77337 51.2004 4.00004C51.2004 4.2267 51.3737 4.40004 51.6004 4.40004H52.8004C53.027 4.40004 53.2004 4.2267 53.2004 4.00004C53.2004 3.77337 53.027 3.60004 52.8004 3.60004Z"
        fill="#2C3E50"
      />
      <path
        d="M46.9737 1.21335C46.8137 1.05335 46.5604 1.05335 46.4137 1.21335C46.2537 1.37335 46.2537 1.62669 46.4137 1.77335L47.267 2.62669C47.347 2.70669 47.4537 2.74669 47.547 2.74669C47.6537 2.74669 47.747 2.70669 47.827 2.62669C47.987 2.46669 47.987 2.21335 47.827 2.06669L46.9737 1.21335Z"
        fill="#2C3E50"
      />
      <path
        d="M51.227 5.45333C51.067 5.29333 50.8137 5.29333 50.667 5.45333C50.507 5.61333 50.507 5.86666 50.667 6.01333L51.5204 6.86666C51.6004 6.94666 51.707 6.98666 51.8004 6.98666C51.8937 6.98666 52.0004 6.94666 52.0804 6.86666C52.2404 6.70666 52.2404 6.45333 52.0804 6.30666L51.227 5.45333Z"
        fill="#2C3E50"
      />
      <path
        d="M49.2003 0C48.9737 0 48.8003 0.173333 48.8003 0.4V1.6C48.8003 1.82667 48.9737 2 49.2003 2C49.427 2 49.6003 1.82667 49.6003 1.6V0.4C49.6003 0.173333 49.427 0 49.2003 0Z"
        fill="#2C3E50"
      />
      <path
        d="M49.2003 6C48.9737 6 48.8003 6.17333 48.8003 6.4V7.6C48.8003 7.82667 48.9737 8 49.2003 8C49.427 8 49.6003 7.82667 49.6003 7.6V6.4C49.6003 6.17333 49.427 6 49.2003 6Z"
        fill="#2C3E50"
      />
      <path
        d="M51.507 1.21336L50.6537 2.0667C50.4937 2.2267 50.4937 2.48003 50.6537 2.6267C50.7337 2.7067 50.8404 2.7467 50.9337 2.7467C51.0404 2.7467 51.1337 2.7067 51.2137 2.6267L52.067 1.77336C52.227 1.61336 52.227 1.36003 52.067 1.21336C51.9204 1.0667 51.667 1.0667 51.507 1.21336Z"
        fill="#2C3E50"
      />
      <path
        d="M47.267 5.45334L46.4137 6.30667C46.2537 6.46667 46.2537 6.72 46.4137 6.86667C46.4937 6.94667 46.6004 6.98667 46.6937 6.98667C46.8004 6.98667 46.8937 6.94667 46.9737 6.86667L47.827 6.01334C47.987 5.85334 47.987 5.6 47.827 5.45334C47.667 5.30667 47.4137 5.30667 47.267 5.45334Z"
        fill="#2C3E50"
      />
      <path
        d="M7.20034 34.4001H6.00034C5.77367 34.4001 5.60034 34.5734 5.60034 34.8001C5.60034 35.0268 5.77367 35.2001 6.00034 35.2001H7.20034C7.42701 35.2001 7.60034 35.0268 7.60034 34.8001C7.60034 34.5734 7.42701 34.4001 7.20034 34.4001Z"
        fill="#2C3E50"
      />
      <path
        d="M13.2003 34.4001H12.0003C11.7737 34.4001 11.6003 34.5734 11.6003 34.8001C11.6003 35.0268 11.7737 35.2001 12.0003 35.2001H13.2003C13.427 35.2001 13.6003 35.0268 13.6003 34.8001C13.6003 34.5734 13.427 34.4001 13.2003 34.4001Z"
        fill="#2C3E50"
      />
      <path
        d="M7.3737 32.0134C7.2137 31.8534 6.96036 31.8534 6.8137 32.0134C6.6537 32.1734 6.6537 32.4267 6.8137 32.5734L7.66703 33.4267C7.74703 33.5067 7.8537 33.5467 7.94703 33.5467C8.0537 33.5467 8.14703 33.5067 8.22703 33.4267C8.38703 33.2667 8.38703 33.0134 8.22703 32.8667L7.3737 32.0134Z"
        fill="#2C3E50"
      />
      <path
        d="M11.627 36.2533C11.467 36.0933 11.2137 36.0933 11.067 36.2533C10.907 36.4133 10.907 36.6667 11.067 36.8133L11.9203 37.6667C12.0003 37.7467 12.107 37.7867 12.2003 37.7867C12.307 37.7867 12.4003 37.7467 12.4803 37.6667C12.6403 37.5067 12.6403 37.2533 12.4803 37.1067L11.627 36.2533Z"
        fill="#2C3E50"
      />
      <path
        d="M9.60038 30.8C9.37371 30.8 9.20038 30.9734 9.20038 31.2V32.4001C9.20038 32.6267 9.37371 32.8001 9.60038 32.8001C9.82704 32.8001 10.0004 32.6267 10.0004 32.4001V31.2C10.0004 30.9734 9.82704 30.8 9.60038 30.8Z"
        fill="#2C3E50"
      />
      <path
        d="M9.60038 36.8001C9.37371 36.8001 9.20038 36.9734 9.20038 37.2001V38.4001C9.20038 38.6267 9.37371 38.8001 9.60038 38.8001C9.82704 38.8001 10.0004 38.6267 10.0004 38.4001V37.2001C10.0004 36.9734 9.82704 36.8001 9.60038 36.8001Z"
        fill="#2C3E50"
      />
      <path
        d="M11.907 32.0134L11.0537 32.8667C10.8937 33.0267 10.8937 33.2801 11.0537 33.4267C11.1337 33.5067 11.2403 33.5467 11.3337 33.5467C11.4403 33.5467 11.5337 33.5067 11.6137 33.4267L12.467 32.5734C12.627 32.4134 12.627 32.16 12.467 32.0134C12.3203 31.8667 12.067 31.8667 11.907 32.0134Z"
        fill="#2C3E50"
      />
      <path
        d="M7.66703 36.2534L6.8137 37.1067C6.6537 37.2667 6.6537 37.52 6.8137 37.6667C6.8937 37.7467 7.00036 37.7867 7.0937 37.7867C7.20036 37.7867 7.2937 37.7467 7.3737 37.6667L8.22703 36.8134C8.38703 36.6534 8.38703 36.4 8.22703 36.2534C8.06703 36.1067 7.8137 36.1067 7.66703 36.2534Z"
        fill="#2C3E50"
      />
      <path
        d="M22.7337 61.4666L21.8403 60.9733C21.4403 60.7466 21.107 60.4266 20.8803 60.0133L20.387 59.1199C20.3203 58.9866 20.187 58.9066 20.0403 58.9066C19.8937 58.9066 19.7603 58.9866 19.6937 59.1199L19.2003 60.0133C18.9737 60.4133 18.6537 60.7466 18.2403 60.9733L17.347 61.4666C17.2137 61.5333 17.1337 61.6666 17.1337 61.8133C17.1337 61.9599 17.2137 62.0933 17.347 62.1599L18.2403 62.6533C18.6403 62.8799 18.9737 63.1999 19.2003 63.6133L19.6937 64.5066C19.7603 64.6399 19.8937 64.7199 20.0403 64.7199C20.187 64.7199 20.3203 64.6399 20.387 64.5066L20.8803 63.6133C21.107 63.2133 21.427 62.8799 21.8403 62.6533L22.7337 62.1599C22.867 62.0933 22.947 61.9599 22.947 61.8133C22.947 61.6666 22.867 61.5333 22.7337 61.4666ZM21.4537 61.9466C20.9203 62.2399 20.4803 62.6799 20.1737 63.2266L20.0403 63.4799L19.907 63.2266C19.6137 62.6933 19.1737 62.2399 18.627 61.9466L18.3737 61.8133L18.627 61.6799C19.1603 61.3866 19.6137 60.9466 19.907 60.3999L20.0403 60.1466L20.1737 60.3999C20.467 60.9333 20.907 61.3733 21.4537 61.6799L21.707 61.8133L21.4537 61.9466Z"
        fill="#2C3E50"
      />
      <path
        d="M99.267 42.9333L98.3736 42.44C97.9736 42.2133 97.6403 41.8933 97.4136 41.48L96.9203 40.5867C96.8536 40.4533 96.7203 40.3733 96.5736 40.3733C96.4269 40.3733 96.2936 40.4533 96.227 40.5867L95.7336 41.48C95.5069 41.88 95.1869 42.2133 94.7736 42.44L93.8803 42.9333C93.7469 43 93.6669 43.1333 93.6669 43.28C93.6669 43.4267 93.7469 43.56 93.8803 43.6267L94.7736 44.12C95.1736 44.3467 95.5069 44.6667 95.7336 45.08L96.227 45.9734C96.2936 46.1067 96.4269 46.1867 96.5736 46.1867C96.7203 46.1867 96.8536 46.1067 96.9203 45.9734L97.4136 45.08C97.6403 44.68 97.9603 44.3467 98.3736 44.12L99.267 43.6267C99.4003 43.56 99.4803 43.4267 99.4803 43.28C99.467 43.1467 99.387 43.0133 99.267 42.9333ZM97.987 43.4267C97.4536 43.72 97.0136 44.16 96.7069 44.7067L96.5736 44.96L96.4403 44.7067C96.1469 44.1733 95.707 43.7333 95.1603 43.4267L94.9069 43.2933L95.1603 43.16C95.6936 42.8667 96.1469 42.4267 96.4403 41.88L96.5736 41.6267L96.7069 41.88C97.0003 42.4133 97.4403 42.8667 97.987 43.16L98.2403 43.2933L97.987 43.4267Z"
        fill="#2C3E50"
      />
      <path
        d="M62.0003 76.8H61.2003C60.9736 76.8 60.8003 76.9734 60.8003 77.2C60.8003 77.4267 60.9736 77.6 61.2003 77.6H62.0003C62.227 77.6 62.4003 77.4267 62.4003 77.2C62.4003 76.9734 62.227 76.8 62.0003 76.8Z"
        fill="#2C3E50"
      />
      <path
        d="M66.0003 76.8H65.2003C64.9736 76.8 64.8003 76.9734 64.8003 77.2C64.8003 77.4267 64.9736 77.6 65.2003 77.6H66.0003C66.227 77.6 66.4003 77.4267 66.4003 77.2C66.4003 76.9734 66.227 76.8 66.0003 76.8Z"
        fill="#2C3E50"
      />
      <path
        d="M63.6003 74.4C63.3736 74.4 63.2003 74.5734 63.2003 74.8V75.6C63.2003 75.8267 63.3736 76 63.6003 76C63.827 76 64.0003 75.8267 64.0003 75.6V74.8C64.0003 74.5734 63.827 74.4 63.6003 74.4Z"
        fill="#2C3E50"
      />
      <path
        d="M63.6003 78.4001C63.3736 78.4001 63.2003 78.5734 63.2003 78.8001V79.6001C63.2003 79.8268 63.3736 80.0001 63.6003 80.0001C63.827 80.0001 64.0003 79.8268 64.0003 79.6001V78.8001C64.0003 78.5734 63.827 78.4001 63.6003 78.4001Z"
        fill="#2C3E50"
      />
      <path
        d="M84.8004 23.2H84.0004C83.7737 23.2 83.6004 23.3733 83.6004 23.6C83.6004 23.8266 83.7737 24 84.0004 24H84.8004C85.027 24 85.2004 23.8266 85.2004 23.6C85.2004 23.3733 85.027 23.2 84.8004 23.2Z"
        fill="#2C3E50"
      />
      <path
        d="M88.8004 23.2H88.0004C87.7737 23.2 87.6004 23.3733 87.6004 23.6C87.6004 23.8266 87.7737 24 88.0004 24H88.8004C89.027 24 89.2004 23.8266 89.2004 23.6C89.2004 23.3733 89.027 23.2 88.8004 23.2Z"
        fill="#2C3E50"
      />
      <path
        d="M86.4003 20.8C86.1737 20.8 86.0003 20.9733 86.0003 21.2V22C86.0003 22.2267 86.1737 22.4 86.4003 22.4C86.627 22.4 86.8003 22.2267 86.8003 22V21.2C86.8003 20.9733 86.627 20.8 86.4003 20.8Z"
        fill="#2C3E50"
      />
      <path
        d="M86.4003 24.8C86.1737 24.8 86.0003 24.9733 86.0003 25.2V26C86.0003 26.2266 86.1737 26.4 86.4003 26.4C86.627 26.4 86.8003 26.2266 86.8003 26V25.2C86.8003 24.9733 86.627 24.8 86.4003 24.8Z"
        fill="#2C3E50"
      />
      <path
        d="M24.4003 10H20.4003C20.1737 10 20.0003 10.1733 20.0003 10.4C20.0003 10.6267 20.1737 10.8 20.4003 10.8H24.4003C24.627 10.8 24.8003 10.6267 24.8003 10.4C24.8003 10.1733 24.627 10 24.4003 10Z"
        fill="#2C3E50"
      />
      <path
        d="M38.4003 12.8H25.6003C25.3737 12.8 25.2003 12.9733 25.2003 13.2C25.2003 13.4267 25.3737 13.6 25.6003 13.6H38.4003C38.627 13.6 38.8003 13.4267 38.8003 13.2C38.8003 12.9733 38.627 12.8 38.4003 12.8Z"
        fill="#2C3E50"
      />
      <path
        d="M36.8004 15.6H33.6004C33.3737 15.6 33.2004 15.7733 33.2004 16C33.2004 16.2267 33.3737 16.4 33.6004 16.4H36.8004C37.027 16.4 37.2004 16.2267 37.2004 16C37.2004 15.7733 37.027 15.6 36.8004 15.6Z"
        fill="#2C3E50"
      />
      <path
        d="M30.4004 15.6H23.2004C22.9737 15.6 22.8004 15.7733 22.8004 16C22.8004 16.2267 22.9737 16.4 23.2004 16.4H30.4004C30.627 16.4 30.8004 16.2267 30.8004 16C30.8004 15.7733 30.627 15.6 30.4004 15.6Z"
        fill="#2C3E50"
      />
      <path
        d="M87.6003 13.2H66.8004C66.5737 13.2 66.4004 13.3733 66.4004 13.6C66.4004 13.8267 66.5737 14 66.8004 14H87.6003C87.827 14 88.0003 13.8267 88.0003 13.6C88.0003 13.3733 87.827 13.2 87.6003 13.2Z"
        fill="#2C3E50"
      />
      <path
        d="M78.0003 10.4H70.8004C70.5737 10.4 70.4004 10.5733 70.4004 10.8C70.4004 11.0266 70.5737 11.2 70.8004 11.2H78.0003C78.227 11.2 78.4003 11.0266 78.4003 10.8C78.4003 10.5733 78.227 10.4 78.0003 10.4Z"
        fill="#2C3E50"
      />
      <path
        d="M84.4004 10.4H82.0004C81.7737 10.4 81.6004 10.5733 81.6004 10.8C81.6004 11.0266 81.7737 11.2 82.0004 11.2H84.4004C84.6271 11.2 84.8004 11.0266 84.8004 10.8C84.8004 10.5733 84.6271 10.4 84.4004 10.4Z"
        fill="#2C3E50"
      />
      <path
        d="M70.9452 43.5335C70.617 43.5335 70.3639 43.353 70.2607 43.0964C70.1482 42.8208 70.2138 42.5168 70.4295 42.3077C70.6545 42.0891 70.8889 41.8801 71.0952 41.6425C71.6296 41.0343 72.014 40.3312 72.2484 39.5519C72.4453 38.8963 72.5296 38.2216 72.4828 37.5374C72.3703 35.998 71.7515 34.7056 70.6358 33.6508C70.5514 33.5653 70.4482 33.4893 70.3732 33.3942C70.1388 33.1092 70.1763 32.691 70.4295 32.444C70.692 32.1874 71.0952 32.1779 71.3764 32.4155C72.4359 33.3277 73.1766 34.4585 73.5891 35.8174C73.8329 36.6156 73.9266 37.4233 73.8797 38.2596C73.8141 39.5044 73.4578 40.6637 72.8015 41.7185C72.4078 42.3552 71.9296 42.9064 71.3577 43.391C71.217 43.486 71.067 43.5335 70.9452 43.5335Z"
        fill="#2C3E50"
      />
      <path
        d="M70.9452 43.6571C70.5795 43.6571 70.2608 43.448 70.1389 43.1344C70.017 42.8113 70.092 42.4502 70.3358 42.2127L70.5139 42.0416C70.6733 41.8801 70.842 41.7185 70.9921 41.557C71.5077 40.9773 71.8827 40.2836 72.1171 39.5044C72.314 38.8487 72.389 38.1836 72.3422 37.5374C72.239 36.0455 71.6296 34.7626 70.5326 33.7363L70.4576 33.6698C70.392 33.6128 70.317 33.5463 70.2608 33.4703C69.9889 33.1377 70.0264 32.6435 70.3358 32.3489C70.6452 32.0449 71.1233 32.0354 71.4421 32.3204C72.5203 33.2517 73.2797 34.4205 73.6923 35.7794C73.936 36.5776 74.0392 37.4138 73.9923 38.2596C73.9267 39.5234 73.561 40.7018 72.8953 41.7756C72.5015 42.4122 72.0046 42.9824 71.4233 43.4765C71.2827 43.5906 71.1139 43.6571 70.9452 43.6571ZM70.9077 32.368C70.767 32.368 70.617 32.425 70.5045 32.5295C70.2889 32.7386 70.2701 33.0806 70.4576 33.3182C70.5045 33.3752 70.5701 33.4322 70.6264 33.4893C70.6545 33.5083 70.6827 33.5368 70.7014 33.5558C71.8452 34.6296 72.4734 35.9599 72.5859 37.5184C72.6328 38.2026 72.5578 38.8963 72.3515 39.5804C72.1078 40.3882 71.714 41.1104 71.1796 41.7185C71.0202 41.8991 70.8514 42.0606 70.6827 42.2222L70.5139 42.3932C70.3358 42.5643 70.2889 42.8113 70.3733 43.0489C70.4576 43.2675 70.6733 43.41 70.9452 43.41C71.0577 43.41 71.1702 43.3625 71.264 43.277C71.8265 42.8018 72.3047 42.2507 72.6797 41.633C73.3266 40.5972 73.6829 39.4474 73.7391 38.2311C73.7766 37.4138 73.6829 36.6061 73.4485 35.8364C73.0547 34.525 72.3234 33.3942 71.2733 32.501C71.1702 32.4155 71.0389 32.368 70.9077 32.368Z"
        fill="#2C3E50"
      />
      <path
        d="M68.5262 42.2317C68.2731 42.2317 68.0762 42.0892 68.0012 41.8896C67.9168 41.671 67.9637 41.443 68.1325 41.2814C68.3012 41.1104 68.4794 40.9488 68.6388 40.7683C69.0513 40.3027 69.3419 39.761 69.5201 39.1528C69.6701 38.6492 69.7357 38.1266 69.6982 37.6039C69.6138 36.4161 69.1357 35.4183 68.2731 34.6106C68.2075 34.5441 68.1325 34.4871 68.0762 34.411C67.8981 34.1925 67.9262 33.8694 68.1231 33.6793C68.32 33.4798 68.6388 33.4703 68.8544 33.6603C69.6701 34.3635 70.242 35.2378 70.5608 36.2735C70.7483 36.8817 70.8233 37.5089 70.7858 38.1551C70.7389 39.1148 70.4576 40.0081 69.9514 40.8158C69.6513 41.3004 69.2763 41.7281 68.8356 42.0987C68.7419 42.1937 68.62 42.2317 68.5262 42.2317Z"
        fill="#2C3E50"
      />
      <path
        d="M68.5263 42.3647C68.2356 42.3647 67.9825 42.1937 67.8793 41.9466C67.7762 41.69 67.8418 41.3955 68.0387 41.2054L68.17 41.0724C68.2919 40.9488 68.4231 40.8253 68.5356 40.6923C68.9294 40.2456 69.2201 39.723 69.3982 39.1243C69.5482 38.6207 69.6045 38.1171 69.5763 37.6134C69.492 36.4731 69.0325 35.4943 68.1981 34.7056L68.1418 34.6581C68.0856 34.6106 68.0293 34.5631 67.9825 34.4965C67.7668 34.2305 67.7949 33.8314 68.0387 33.5938C68.2825 33.3562 68.6669 33.3372 68.9294 33.5653C69.7638 34.2875 70.3545 35.1902 70.6733 36.2355C70.8608 36.8532 70.9358 37.4994 70.9077 38.1551C70.8608 39.1338 70.5702 40.0461 70.0545 40.8823C69.7451 41.3765 69.3701 41.8231 68.92 42.1937C68.8075 42.3077 68.6669 42.3552 68.5263 42.3647ZM68.4981 33.6603C68.395 33.6603 68.2919 33.6983 68.2075 33.7743C68.0575 33.9264 68.0387 34.1735 68.17 34.335C68.2075 34.3825 68.2544 34.4205 68.3012 34.4585L68.3575 34.5156C69.2388 35.3423 69.7357 36.3781 69.8201 37.5849C69.8576 38.1171 69.792 38.6492 69.6326 39.1813C69.4451 39.8085 69.1357 40.3692 68.7231 40.8443C68.6013 40.9868 68.47 41.1104 68.3387 41.2339L68.2075 41.3574C68.0856 41.481 68.0481 41.6615 68.1137 41.8231C68.1794 41.9846 68.3294 42.0796 68.5263 42.0796C68.6013 42.0796 68.6856 42.0416 68.7513 41.9846C69.1826 41.6235 69.5482 41.2054 69.8389 40.7303C70.3264 39.9321 70.6077 39.0578 70.6545 38.1266C70.6827 37.4994 70.6077 36.8817 70.4295 36.2925C70.1295 35.2853 69.567 34.4205 68.77 33.7363C68.695 33.6983 68.5919 33.6603 68.4981 33.6603Z"
        fill="#2C3E50"
      />
      <path
        d="M36.0581 43.5335C36.3863 43.5335 36.6394 43.353 36.7425 43.0964C36.855 42.8208 36.7894 42.5168 36.5738 42.3077C36.3488 42.0891 36.1144 41.8801 35.9081 41.6425C35.3737 41.0343 34.9893 40.3312 34.7549 39.5519C34.558 38.8963 34.4736 38.2216 34.5205 37.5374C34.633 35.998 35.2518 34.7056 36.3675 33.6508C36.4519 33.5653 36.555 33.4893 36.63 33.3942C36.8644 33.1092 36.8269 32.691 36.5738 32.444C36.3113 32.1874 35.9081 32.1779 35.6268 32.4155C34.5674 33.3277 33.8267 34.4585 33.4142 35.8174C33.1891 36.6061 33.0954 37.4138 33.1329 38.2501C33.1985 39.4949 33.5642 40.6542 34.2205 41.709C34.6142 42.3457 35.0924 42.8969 35.6643 43.3815C35.7862 43.486 35.9362 43.5335 36.0581 43.5335Z"
        fill="#2C3E50"
      />
      <path
        d="M36.0581 43.6571C35.8894 43.6571 35.7206 43.581 35.58 43.467C34.9987 42.9824 34.5018 42.4027 34.108 41.766C33.4423 40.6923 33.0767 39.5044 33.011 38.2501C32.9641 37.4043 33.0673 36.5681 33.311 35.7699C33.7236 34.411 34.483 33.2422 35.5612 32.3109C35.8894 32.0259 36.3675 32.0449 36.6676 32.3394C36.977 32.6435 37.0051 33.1282 36.7426 33.4608C36.6863 33.5368 36.6113 33.6033 36.5457 33.6603L36.4707 33.7268C35.3737 34.7531 34.7737 36.036 34.6611 37.5279C34.6143 38.1741 34.6893 38.8392 34.8862 39.4949C35.1206 40.2741 35.4956 40.9583 36.0113 41.5475C36.1613 41.7185 36.33 41.8801 36.4894 42.0321L36.6676 42.2032C36.9113 42.4407 36.9863 42.8018 36.8644 43.1249C36.7426 43.4575 36.4238 43.6571 36.0581 43.6571ZM36.0956 32.368C35.9644 32.368 35.8237 32.4155 35.7206 32.5105C34.6799 33.4132 33.9486 34.5345 33.5454 35.8459C33.311 36.6156 33.2173 37.4233 33.2548 38.2406C33.3204 39.4569 33.6767 40.6067 34.3142 41.6425C34.6987 42.2602 35.1768 42.8113 35.73 43.2865C35.8237 43.3625 35.9456 43.41 36.0488 43.4195C36.3113 43.4195 36.5363 43.277 36.6207 43.0584C36.7144 42.8303 36.6582 42.5738 36.48 42.4027L36.3113 42.2317C36.1425 42.0701 35.9737 41.9086 35.8144 41.728C35.2799 41.1199 34.8862 40.3977 34.6424 39.5899C34.4361 38.9058 34.3518 38.2121 34.408 37.5279C34.5205 35.9694 35.1581 34.6296 36.2925 33.5653C36.3207 33.5368 36.3488 33.5178 36.3675 33.4988C36.4332 33.4417 36.4894 33.3847 36.5363 33.3277C36.7238 33.0997 36.7051 32.7481 36.4894 32.539C36.3863 32.4155 36.2456 32.368 36.0956 32.368Z"
        fill="#2C3E50"
      />
      <path
        d="M38.4771 42.2317C38.7302 42.2317 38.9271 42.0891 39.0021 41.8896C39.0865 41.671 39.0396 41.4429 38.8709 41.2814C38.7021 41.1104 38.524 40.9488 38.3646 40.7683C37.952 40.3026 37.6614 39.761 37.4833 39.1528C37.3332 38.6492 37.2676 38.1265 37.3051 37.6039C37.3895 36.4161 37.8677 35.4183 38.7302 34.6106C38.7959 34.544 38.8709 34.487 38.9271 34.411C39.1053 34.1924 39.0771 33.8694 38.8802 33.6793C38.6834 33.4797 38.3646 33.4702 38.1489 33.6603C37.3332 34.3635 36.7613 35.2377 36.4426 36.2735C36.255 36.8817 36.18 37.5089 36.2175 38.155C36.2644 39.1148 36.5457 40.008 37.052 40.8158C37.352 41.3004 37.727 41.728 38.1677 42.0986C38.2614 42.1937 38.3833 42.2317 38.4771 42.2317Z"
        fill="#2C3E50"
      />
      <path
        d="M38.4771 42.3647C38.3458 42.3647 38.2052 42.3077 38.0927 42.2127C37.6426 41.8326 37.2582 41.3954 36.9582 40.9013C36.4425 40.0746 36.1613 39.1528 36.105 38.174C36.0675 37.5184 36.1519 36.8722 36.3394 36.2545C36.6582 35.1997 37.2395 34.297 38.0833 33.5843C38.3458 33.3562 38.7302 33.3657 38.974 33.6128C39.2177 33.8504 39.2459 34.2495 39.0302 34.5155C38.9834 34.5726 38.9271 34.6296 38.8708 34.6771L38.8146 34.7246C37.9802 35.5133 37.5114 36.4826 37.4364 37.6324C37.3989 38.1265 37.4551 38.6397 37.6145 39.1433C37.7926 39.7325 38.0833 40.2646 38.4771 40.7112C38.5896 40.8443 38.7208 40.9678 38.8427 41.0819L38.974 41.2149C39.1709 41.4049 39.2271 41.6995 39.1334 41.9561C39.0209 42.1937 38.7677 42.3552 38.4771 42.3647ZM38.5052 33.6603C38.4114 33.6603 38.3083 33.6983 38.2333 33.7648C37.4364 34.4585 36.8738 35.3138 36.5738 36.321C36.3957 36.9102 36.3206 37.5279 36.3488 38.155C36.3957 39.0863 36.6676 39.9605 37.1645 40.7588C37.4551 41.2339 37.8208 41.652 38.252 42.0131C38.3177 42.0701 38.4021 42.1081 38.4771 42.1081C38.674 42.1081 38.824 42.0036 38.8896 41.8516C38.9552 41.6805 38.9177 41.5 38.7958 41.3764L38.6646 41.2434C38.5333 41.1199 38.4021 40.9963 38.2802 40.8538C37.8676 40.3787 37.5582 39.818 37.3707 39.1908C37.2113 38.6587 37.1457 38.117 37.1832 37.5944C37.2676 36.3876 37.7645 35.3518 38.6458 34.525L38.7021 34.468C38.749 34.43 38.7958 34.3825 38.8333 34.3445C38.9646 34.1829 38.9552 33.9264 38.7958 33.7838C38.7115 33.7078 38.6083 33.6603 38.5052 33.6603Z"
        fill="#2C3E50"
      />
      <path
        d="M51.453 23.3025C51.453 23.3025 51.0968 19.3114 53.0657 19.1593C54.8471 19.0263 55.4752 19.929 55.4752 19.929L56.2253 21.2404L58.0254 24.7374L51.453 23.3025Z"
        fill="#3498DB"
      />
      <path
        d="M54.6033 63.4985C57.9879 63.47 60.7162 60.6573 60.6881 57.1983L48.4247 57.2838C48.4528 60.7428 51.2186 63.5271 54.6033 63.4985Z"
        fill="#3498DB"
      />
      <path
        d="M71.7515 52.1619C70.2514 51.5728 66.6511 49.1116 66.473 47.6387C66.1917 45.3485 66.0979 43.0109 65.9104 40.7113C65.7698 39.0008 65.6292 37.2998 65.4698 35.5893C65.301 33.8599 65.2729 31.9308 64.776 30.2869C64.0447 27.8352 60.9319 25.8206 58.9349 25.0319C58.288 24.7754 57.6785 24.6043 57.1348 24.4808L57.1254 23.1409C57.116 21.554 55.8315 20.2616 54.2752 20.2711C52.7188 20.2806 51.4531 21.592 51.4624 23.1789L51.4718 24.5188C50.9374 24.6518 50.3186 24.8324 49.6811 25.0984C47.684 25.9062 44.6088 27.9682 43.9056 30.4294C43.4368 32.0829 43.4275 34.0119 43.2868 35.7414C43.1462 37.4519 43.0337 39.1528 42.9118 40.8633C42.7618 43.1629 42.7055 45.5006 42.4524 47.8002C42.293 49.2731 38.6083 52.3045 37.1082 52.9221C35.73 53.4923 35.58 55.3073 36.8738 55.982C37.9802 56.7137 45.4526 57.2363 54.5283 57.1698C63.5946 57.1033 71.0671 56.4761 72.164 55.7349C73.4485 55.0602 73.1391 52.7131 71.7515 52.1619Z"
        fill="#45B3EE"
      />
      <path
        d="M53.5532 64.9905C49.5404 64.9905 46.2589 61.6931 46.2308 57.6165L46.2214 56.7422L60.8756 56.6377L60.885 57.5119C60.8944 59.498 60.1537 61.3605 58.7755 62.7764C57.3973 64.1923 55.569 64.9715 53.6095 64.9905C53.5907 64.9905 53.572 64.9905 53.5532 64.9905ZM48.0215 58.4717C48.2091 59.6595 48.7622 60.7523 49.6248 61.6076C50.6936 62.6719 52.1094 63.2515 53.6001 63.242C55.1002 63.2325 56.4972 62.6339 57.5566 61.5506C58.4005 60.6763 58.9442 59.5835 59.113 58.3957L48.0215 58.4717Z"
        fill="#2C3E50"
      />
      <path
        d="M52.1375 58.3767C45.8276 58.3767 36.2925 58.0916 34.4924 57.0178C33.6298 56.5617 33.1235 55.5924 33.1704 54.4806C33.2173 53.2167 33.9298 52.1239 34.9706 51.6963C36.7238 50.9836 39.8365 48.5319 39.9397 47.5912C40.1178 46.0137 40.2022 44.3793 40.2866 42.7924C40.3241 41.9941 40.371 41.1769 40.4272 40.3692C40.5304 38.7727 40.6616 36.8817 40.821 34.9907C40.8585 34.5916 40.8773 34.1735 40.9054 33.7649C40.9148 33.5463 40.9335 33.3277 40.9523 33.1092C40.9898 32.634 41.3929 32.2634 41.8711 32.3015C42.3492 32.3395 42.7055 32.7481 42.668 33.2327C42.6493 33.4513 42.6399 33.6603 42.6211 33.8789C42.593 34.3065 42.5649 34.7246 42.5368 35.1427C42.3867 37.0148 42.2555 38.8963 42.143 40.4927C42.0867 41.2909 42.0492 42.1082 42.0023 42.8969C41.918 44.5028 41.8242 46.1658 41.6461 47.8002C41.4023 50.0143 36.9301 52.7891 35.6081 53.3213C35.1393 53.5113 34.9049 54.1005 34.8862 54.5566C34.8768 54.7181 34.8862 55.2788 35.2987 55.4879L35.3831 55.5354C36.2081 56.0485 42.9774 56.7137 53.5251 56.6377C64.0728 56.5617 70.8233 55.8014 71.6483 55.2788L71.7327 55.2313C72.1453 55.0127 72.1453 54.4616 72.1359 54.2905C72.1078 53.8344 71.864 53.2547 71.3952 53.0647C70.0639 52.542 65.5635 49.8243 65.2822 47.6292C65.1979 46.9735 65.1322 46.2798 65.0666 45.5861C65.0291 45.111 65.376 44.6834 65.8542 44.6454C66.3323 44.6074 66.7449 44.959 66.7824 45.4436C66.8386 46.1183 66.9042 46.7835 66.9886 47.4201C67.1011 48.3609 70.2514 50.7651 72.014 51.4492C73.0641 51.8579 73.786 52.9412 73.8516 54.1955C73.9173 55.3073 73.4204 56.2861 72.5672 56.7612C70.6264 57.93 59.7224 58.3291 53.5251 58.3767C53.0844 58.3767 52.625 58.3767 52.1375 58.3767Z"
        fill="#2C3E50"
      />
      <path
        d="M65.7135 43.7046C65.2635 43.7046 64.8884 43.353 64.8603 42.8969L64.8322 42.5073C64.7853 41.7756 64.7291 41.0534 64.6728 40.3312C64.5415 38.7347 64.3915 36.8532 64.2134 34.9812C64.1759 34.5536 64.1384 34.126 64.1009 33.6793C63.9978 32.2824 63.8852 30.8285 63.5102 29.5932C62.8352 27.3221 59.6849 25.355 57.866 24.6518C57.2941 24.4333 56.6941 24.2527 56.0847 24.1102L55.419 23.9581L55.4096 21.8676C55.4002 20.6797 54.4345 19.7295 53.2626 19.7295C52.1 19.739 51.153 20.7082 51.153 21.8866L51.1718 23.9962L50.5061 24.1577C49.9061 24.3002 49.306 24.4903 48.7341 24.7279C46.9527 25.4501 44.0087 27.3316 43.2212 29.5362C43.0618 29.9923 42.5649 30.2204 42.1242 30.0588C41.6742 29.8973 41.4492 29.3937 41.6086 28.947C42.6586 25.9822 46.3152 23.8346 48.0965 23.1124C48.5372 22.9319 48.9872 22.7798 49.4373 22.6468L49.4279 21.9246C49.4279 19.7675 51.1437 18.019 53.2438 18C53.2532 18 53.2626 18 53.2719 18C55.3815 18 57.1066 19.7295 57.116 21.8676V22.5898C57.5754 22.7133 58.0254 22.8653 58.4661 23.0364C60.3694 23.7776 64.2134 25.9917 65.1416 29.0991C65.5635 30.515 65.6854 32.0639 65.7979 33.5558C65.8354 33.9834 65.8635 34.411 65.901 34.8197C66.0792 36.7012 66.2386 38.5922 66.3698 40.1886C66.4261 40.9203 66.4823 41.652 66.5292 42.3837L66.5573 42.7733C66.5855 43.258 66.2292 43.6666 65.7604 43.7046C65.751 43.7046 65.7323 43.7046 65.7135 43.7046Z"
        fill="#2C3E50"
      />
      <path
        d="M61.9726 41.405C61.9632 41.405 61.9445 41.405 61.9351 41.405C61.4569 41.386 61.0913 40.9774 61.11 40.4927C61.3163 35.6084 60.36 31.5032 60.3506 31.4652C60.2381 30.9996 60.5288 30.5245 60.9882 30.4199C61.4476 30.3059 61.9164 30.6005 62.0195 31.0661C62.057 31.2467 63.0414 35.4468 62.8258 40.5783C62.8164 41.0344 62.432 41.405 61.9726 41.405Z"
        fill="white"
      />
      <path
        d="M61.7851 45.3486C61.7289 45.3486 61.682 45.3391 61.6257 45.3296C61.1569 45.244 60.8569 44.7879 60.9413 44.3128C61.0726 43.5811 61.0257 42.9444 61.0257 42.9349C60.9788 42.4598 61.3257 42.0321 61.8039 41.9846C62.282 41.9466 62.6946 42.2887 62.7414 42.7733C62.7414 42.8114 62.8164 43.6571 62.6383 44.6359C62.5539 45.0635 62.1976 45.3486 61.7851 45.3486Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Notification;
