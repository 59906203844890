import React, { useMemo } from 'react';
import { Box, LinearProgress, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { sortBy, toNumber } from 'lodash';

import StarIcon from '@/components/icons/star';
import { useClassRoomSessionQuery } from '@/graphql';
import { Skeleton } from '@material-ui/lab';
import ProgressItem from './progress-item';
import { useStyles } from './styles';
import { ClassroomTypes } from '@/type';

interface LectureProgressProps {
  sessionId: string;
  userSessionSettingId?: string | undefined;
}

const LectureProgress: React.FC<LectureProgressProps> = ({
  sessionId,
  userSessionSettingId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, loading } = useClassRoomSessionQuery({
    variables: { sessionId, userSessionSettingsId: userSessionSettingId },
  });

  const classRoomLectures = useMemo(
    () =>
      sortBy(data?.classRoomLearningSession?.classRoom.classRoomLecture, [
        'lectureNumber',
      ]),
    [data]
  );

  const classRoomLecturesFiltered = useMemo(
    () => classRoomLectures?.filter((it) => !!it.isVisible),
    [classRoomLectures]
  );

  const isNetworkType =
    data?.classRoomLearningSession?.classRoom.enrollmentType ===
    ClassroomTypes.NETWORK;

  const answersData = useMemo(() => {
    if (!classRoomLectures?.length) return null;
    const specifics = {
      correctAnswersGoal: 0,
      questions: 0,
      correctAnswers: 0,
      progressGoal: 0,
      progressAnswers: 0,
    };
    classRoomLectures?.forEach((lec) => {
      specifics.correctAnswersGoal += toNumber(lec?.lectureGoal);
      specifics.questions += lec?.lectureQuestionCount || 0;
      specifics.correctAnswers += lec?.correctAnswerCount || 0;
    });
    specifics.progressAnswers =
      specifics.correctAnswers > 0
        ? (specifics.correctAnswers * 100) / specifics.questions
        : 0;
    if (specifics.progressAnswers > 100) {
      specifics.progressAnswers = 100;
    }

    specifics.progressGoal =
      specifics.correctAnswersGoal > 0
        ? (specifics.correctAnswersGoal * 100) / specifics.questions
        : 0;
    return specifics;
  }, [classRoomLectures]);

  const totalProgress = useMemo(
    () =>
      answersData ? (
        <Box pl={6} pr={6} pt={5}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              {t('classrooms.learning-goal')}
            </Typography>
            <Box display="flex" alignItems="center" className={classes.goal}>
              <Typography
                color={
                  answersData.correctAnswersGoal > 0 &&
                  answersData.correctAnswers >= answersData.correctAnswersGoal
                    ? 'primary'
                    : 'inherit'
                }
                variant="subtitle2"
              >
                {answersData?.correctAnswersGoal}
              </Typography>
              <StarIcon
                style={{ marginLeft: 6 }}
                color={
                  answersData.correctAnswersGoal > 0 &&
                  answersData.correctAnswers >= answersData.correctAnswersGoal
                    ? 'primary'
                    : 'inherit'
                }
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="caption">
              {t('classrooms.proper-mcqs')}
            </Typography>
          </Box>

          <Box marginTop={3} className={classes.progressWrap}>
            <LinearProgress
              style={{ height: 8, borderRadius: 4 }}
              variant="determinate"
              value={answersData.progressAnswers}
              classes={{
                barColorPrimary: classes.barColorPrimary,
                barColorSecondary: classes.barColorSecondary,
              }}
            />
            <Box
              className={classes.progressGoal}
              style={{ left: `calc(${answersData.progressGoal}% - 6px)` }}
            >
              <span
                className={classes.goalMark}
                style={{
                  height: 12,
                }}
              />
              <StarIcon style={{ marginTop: 4 }} />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" marginTop={3}>
            <Typography color="inherit" variant="overline">
              {answersData?.correctAnswers}
            </Typography>
            <Typography className={classes.caption} variant="overline">
              /{answersData?.questions}
            </Typography>
          </Box>
        </Box>
      ) : (
        <></>
      ),
    [answersData, classes, t]
  );

  if (loading)
    return (
      <Box py={2} px={2} mt={2} width="100%">
        {[...Array(2)].map((i, idx) => (
          <Skeleton key={idx} height={20} />
        ))}
      </Box>
    );

  return (
    <Box p={2}>
      <Paper elevation={0}>
        {totalProgress}
        <Box pt={2} pb={10} px={2}>
          {!!classRoomLecturesFiltered.length && (
            <Box px={4} marginTop={7}>
              <Typography variant="overline">
                {t('classrooms.training-units')}
              </Typography>
            </Box>
          )}
          {classRoomLecturesFiltered.map(
            (lec, idx) =>
              lec?.id && (
                <ProgressItem
                  key={lec.id}
                  idx={idx}
                  id={lec?.lecture.id}
                  title={lec?.lecture?.title}
                  questions={lec?.lectureQuestionCount || 0}
                  lectureGoal={toNumber(lec?.lectureGoal)}
                  correctAnswersCount={lec?.correctAnswerCount || 0}
                  isNetworkType={isNetworkType}
                />
              )
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default LectureProgress;
