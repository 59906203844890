import { useEffect, useState, useMemo, useRef } from 'react';
import { DateTime, DurationObject } from 'luxon';
import { useTranslation } from 'react-i18next';
import { TIMER_REFRESH_CYCLE } from '@/utils/constants';

export const useRelativeTime = (
  time: DateTime,
  offset: DurationObject = {},
  refreshCycle = TIMER_REFRESH_CYCLE,
  shouldNegativeIntervalCleared = false
) => {
  const { t } = useTranslation();

  const [now, setNow] = useState(getTime());
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const intervalId = setInterval(() => setNow(getTime()), refreshCycle);
    intervalIdRef.current = intervalId;
    return () => clearInterval(intervalId);
  }, [refreshCycle, setNow]);

  const relative = useMemo(() => {
    const dateTime = time
      .plus(offset)
      .diff(now, ['days', 'hours', 'minutes', 'seconds', 'milliseconds']);
    const { days, hours, minutes, seconds } = dateTime;

    if (
      shouldNegativeIntervalCleared &&
      dateTime.toMillis() < TIMER_REFRESH_CYCLE &&
      intervalIdRef.current
    ) {
      clearInterval(intervalIdRef.current);
    }
    if (shouldNegativeIntervalCleared) {
      if (dateTime.toMillis() > 3600000) {
        return dateTime.toFormat('hh:mm') + ' Stunden';
      } else if (dateTime.toMillis() < 3600000 && dateTime.toMillis() > 60000) {
        return dateTime.toFormat('m') + ' min';
      } else {
        return t('time-relative.less-minute');
      }
    }

    if (Math.abs(days) >= 1) {
      return time.toFormat('dd MMM');
    }
    if (Math.abs(hours) >= 1) {
      return t('time-relative.hours-short', {
        hours: Math.round(Math.abs(hours)),
      });
    }
    if (Math.abs(minutes) >= 1) {
      return t('time-relative.minutes', {
        minutes: Math.round(Math.abs(minutes)),
      });
    }
    return t('time-relative.seconds', {
      seconds: Math.round(Math.abs(seconds)),
    });
  }, [time, offset, now, t, shouldNegativeIntervalCleared]);
  return { timeLeft: relative, timeLeftInMillis: +time.plus(offset).diff(now) };
};

export const getTime = () => DateTime.local();
