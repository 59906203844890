import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Skeleton } from '@material-ui/lab';
import { Box, IconButton, Paper } from '@material-ui/core';
import { ExitToAppOutlined, InfoOutlined } from '@material-ui/icons';
import { AppTab, AppTabs, TabPanel } from '@/components/tabs';

import {
  GetUserQuizSessionSettingsDocument,
  QuizSessionsAnnouncedDocument,
  QuizSessionsOngoingDocument,
  QuizSessionsOnHomePageDocument,
  QuizTimeSessionStatus,
  useGetUserQuizSessionSettingsQuery,
  useLeaveQuizTimeMutation,
  useQuizTimeDetailsQuery,
  UserQuizSessionSettingsUserStatus,
} from '@/graphql';

import StackLayout from '../../components/stack-layout';
import { useTabsNav } from '@/hooks/useTabsNav';
import {
  useLeaveQuizModal,
  useLeaveQuizNotStartedModal,
} from '@/hooks/useModal';
import QuizTimeHeader from '../header';
import QuizTimeDescription from '../description';
import QuizTimePrizes from '../prizes';
import QuizTimeActivitiesTab from '../quiz-tabs/activities-tab';
import { useSnackbarContext } from '@/contexts/snackbar-context';
import QuizTimeParticipantsTab from '../quiz-tabs/participants-tab';
import LinkNotValid from '@/screens/main/components/link-not-valid';
import { TabNavIdsEnum } from '@/type';
import { LS_KEY_TABINDEX } from '@/utils/constants';

type QuizTimeParams = {
  quizSessionId: string;
};

const QuizTime: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { quizSessionId } = useParams<QuizTimeParams>();
  const { setCustomSnack } = useSnackbarContext();

  const { value, handleChange } = useTabsNav(
    `${TabNavIdsEnum.QUIZTIME}${quizSessionId}`
  );
  const elementRef = useRef<HTMLDivElement | null>(null);

  const { data, loading, error } = useQuizTimeDetailsQuery({
    variables: { quizSessionId },
    fetchPolicy: 'cache-and-network',
  });
  const { data: userQuizSettingsData } = useGetUserQuizSessionSettingsQuery({
    variables: { quizSessionId },
    fetchPolicy: 'cache-and-network',
  });
  const [leaveQuizMutation] = useLeaveQuizTimeMutation();
  const [manualTabChangeOn, setManualTabChangeOn] = useState<boolean>(false);

  const organizer = data?.quizTimeSession?.quizTime?.organizer;
  const sponsor = data?.quizTimeSession?.quizTime?.sponsor;
  const isForPremiumOnly = data?.quizTimeSession?.isForPremiumOnly;
  const maxCapacity = data?.quizTimeSession?.maxUserCapacity
    ? data?.quizTimeSession?.maxUserCapacity
    : null;
  const numberOfMembers = data?.quizTimeSession?.usersCount;
  const startDate = data?.quizTimeSession?.startDate;
  const startDateTime = data?.quizTimeSession?.startDateTime;
  const quizStatus = data?.quizTimeSession?.status;
  const finishDateTime = data?.quizTimeSession?.finishDateTime;
  const title = data?.quizTimeSession?.quizTime?.title;
  const userStatus = userQuizSettingsData?.userQuizSessionSettings?.userStatus;
  const isQuizSessionAvailableToStart =
    userQuizSettingsData?.userQuizSessionSettings
      ?.isQuizSessionAvailableToStart;

  useEffect(() => {
    if (
      data?.quizTimeSession &&
      userQuizSettingsData?.userQuizSessionSettings
    ) {
      const historyState: any = history.location.state || {};
      if (historyState?.showSnackQtStarted) {
        setCustomSnack({
          visibility: true,
          message: 'quiztime.snackBars.snack-message-started',
        });
        history.replace({
          state: { ...historyState, showSnackQtStarted: null },
        });
      }
      if (historyState?.showSnackQtJoined) {
        setCustomSnack({
          visibility: true,
          message: 'quiztime.snackBars.snack-message-joined',
          messageOpts: {
            quiz: `${title}`,
          },
        });
        history.replace({
          state: { ...historyState, showSnackQtJoined: null },
        });
      }
    }
  }, [data, userQuizSettingsData, history, setCustomSnack, title]);

  const leaveQuizTime = useCallback(async () => {
    try {
      await leaveQuizMutation({
        variables: {
          sessionId: quizSessionId,
        },
        refetchQueries: [
          {
            query: GetUserQuizSessionSettingsDocument,
            variables: { quizSessionId },
          },
          {
            query: QuizSessionsOngoingDocument,
          },

          {
            query: QuizSessionsAnnouncedDocument,
          },
          {
            query: QuizSessionsOnHomePageDocument,
            variables: { startLess24Hours: true },
          },
        ],
      });
      setCustomSnack({
        visibility: true,
        message: 'quiztime.snackBars.snack-message-qt-leaved',
        messageOpts: { value: title },
      });
      history.push('/quiz-list');
    } catch (err) {
      console.error('useLeaveQuizTimeMutation', err);
    }
  }, [quizSessionId, history, leaveQuizMutation, title, setCustomSnack]);

  useEffect(() => {
    if (userStatus === UserQuizSessionSettingsUserStatus.WaitingForJoining) {
      history.replace(
        `/quiztime-details/${quizSessionId}`,
        history.location.state
      );
    }
  });

  const leaveModal = useLeaveQuizModal(leaveQuizTime);
  const leaveNotStartedModal = useLeaveQuizNotStartedModal(leaveQuizTime);

  const backLink = useMemo(() => {
    const historyState: any = history.location.state;
    if (historyState?.rootPageForBack === '/home') return '/home';
    return historyState?.backPageForQuiz
      ? historyState?.backPageForQuiz
      : undefined;
  }, [history]);

  useEffect(() => {
    const historyState: any = history.location.state;
    if (historyState?.manualQuizTabChangeOn) setManualTabChangeOn(true);
  }, [history.location.state]);

  if (error) {
    return <LinkNotValid exploreLink="/quiz-list" />;
  }

  return (
    <StackLayout
      title={'QuizTime'}
      back={backLink}
      toolbar={
        quizStatus !== QuizTimeSessionStatus.Finished &&
        quizStatus !== QuizTimeSessionStatus.WaitingForFinish && (
          <Box display="flex" gridGap={4}>
            <IconButton
              color="primary"
              component={RouterLink}
              size="small"
              to={`/quiztime-rules/${quizSessionId}`}
            >
              <InfoOutlined htmlColor="#fff" />
            </IconButton>

            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                quizStatus === QuizTimeSessionStatus.Announced
                  ? leaveNotStartedModal.open()
                  : quizStatus === QuizTimeSessionStatus.InProgress
                  ? leaveModal.open()
                  : leaveQuizTime()
              }
            >
              <ExitToAppOutlined htmlColor="#fff" />
            </IconButton>
          </Box>
        )
      }
    >
      <div
        ref={elementRef}
        id="classroomScrollBox"
        style={{
          height: 'min-content',
        }}
      >
        {loading && !data?.quizTimeSession ? (
          <Paper>
            <Box py={5} px={6}>
              {[...Array(15)].map((i, idx) => (
                <Skeleton key={idx} height={30} />
              ))}
            </Box>
          </Paper>
        ) : (
          <QuizTimeHeader
            quizSessionId={quizSessionId}
            quizStatus={quizStatus}
            image={data?.quizTimeSession?.quizTime?.quizTimeImage}
            startDate={startDate}
            startDateTime={startDateTime}
            finishDateTime={finishDateTime}
            title={data?.quizTimeSession?.quizTime?.title}
            organizerName={organizer?.name}
            isForPremiumOnly={isForPremiumOnly}
            duration={data?.quizTimeSession?.quizTimeDuration}
            maxCount={maxCapacity}
            numberOfMembers={numberOfMembers}
            userStatus={userStatus}
            isQuizSessionAvailableToStart={isQuizSessionAvailableToStart}
          />
        )}

        {loading && !data?.quizTimeSession ? (
          <Paper>
            <Box py={5} px={6}>
              {[...Array(15)].map((i, idx) => (
                <Skeleton key={idx} height={30} />
              ))}
            </Box>
          </Paper>
        ) : (
          <>
            <AppTabs
              style={{ position: 'sticky', top: 0, zIndex: 100 }}
              value={
                manualTabChangeOn
                  ? parseInt(
                      localStorage.getItem(
                        `${TabNavIdsEnum.QUIZTIME}${LS_KEY_TABINDEX}`
                      ) as any
                    )
                  : value
              }
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <AppTab label={t('classrooms.activities')} />
              <AppTab label={t('classrooms.attendees')} />
              <AppTab label={t('classrooms.details')} />
            </AppTabs>

            <TabPanel
              value={
                manualTabChangeOn
                  ? parseInt(
                      localStorage.getItem(
                        `${TabNavIdsEnum.QUIZTIME}${LS_KEY_TABINDEX}`
                      ) as any
                    )
                  : value
              }
              index={0}
            >
              <QuizTimeActivitiesTab
                quizStatus={quizStatus}
                sessionId={quizSessionId}
                userStatus={userStatus}
              />
            </TabPanel>
            <TabPanel
              value={
                manualTabChangeOn
                  ? parseInt(
                      localStorage.getItem(
                        `${TabNavIdsEnum.QUIZTIME}${LS_KEY_TABINDEX}`
                      ) as any
                    )
                  : value
              }
              index={1}
            >
              {quizStatus && userStatus && (
                <QuizTimeParticipantsTab
                  sessionId={quizSessionId}
                  quizStatus={quizStatus}
                  userStatus={userStatus}
                  finishDateTime={finishDateTime}
                />
              )}
            </TabPanel>
            <TabPanel
              value={
                manualTabChangeOn
                  ? parseInt(
                      localStorage.getItem(
                        `${TabNavIdsEnum.QUIZTIME}${LS_KEY_TABINDEX}`
                      ) as any
                    )
                  : value
              }
              index={2}
            >
              <QuizTimeDescription
                description={data?.quizTimeSession?.quizTime?.description}
                orgId={organizer?.id}
                orgName={organizer?.name}
                orgEmail={organizer?.email}
                orgLogo={organizer?.logo}
                orgWebsite={organizer?.linkToSite}
                sponsor={sponsor}
              ></QuizTimeDescription>
              <QuizTimePrizes quizSessionId={quizSessionId} />
            </TabPanel>
          </>
        )}
      </div>
    </StackLayout>
  );
};

export default QuizTime;
