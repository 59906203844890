import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'grid',
    gridRowGap: theme.spacing(2),
    paddingBottom: theme.spacing(16),
  },
  dialogConent: {
    padding: '60px 24px 24px',
  },
  close: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    position: 'absolute',
  },
}));
