import { WebActionType } from '@/screens/main/in-app-subscriptions/types';
import isAlpha from 'validator/lib/isAlpha';
import isWhitelisted from 'validator/lib/isWhitelisted';

export const checkNameIsValid = (value: string | null) => {
  const whiteListChars = [
    ' ',
    '-',
    '`',
    "'",
    'Ä',
    'ä',
    'Ö',
    'ö',
    'Ü',
    'ü',
    'ẞ',
    'ß',
    'ë',
  ];
  return (
    value &&
    value.length < 50 &&
    value
      .split('')
      .every(
        (char) => isAlpha(char, 'de-DE') || isWhitelisted(char, whiteListChars)
      )
  );
};

export const listenToRefreshIOSApp = (client: any) => {
  const iOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false;
  if (!iOS) return false;
  function handleVisibilityChange() {
    if (document.hidden) {
      localStorage.setItem('appFreezed', Date.now().toString());
    } else {
      const appFreezedMark = localStorage.getItem('appFreezed');
      if (appFreezedMark && Number(appFreezedMark) + 600000 < Date.now()) {
        client?.cache?.reset();
        window.location.reload();
      }
      localStorage.setItem('appFreezed', '');
    }
  }
  // Warn if the browser doesn't support addEventListener or the Page Visibility API
  if (
    typeof document.addEventListener === 'undefined' ||
    typeof document.hidden === 'undefined'
  ) {
    console.log(
      'This website requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.'
    );
  } else {
    // Handle page visibility change
    document.addEventListener(
      'visibilitychange',
      handleVisibilityChange,
      false
    );
  }
};

interface MetadataObj {
  [key: string]: string;
}
export const stripeBackendErrorsCodes: MetadataObj = {
  insufficient_funds: 'insufficient-funds',
  incorrect_number: 'incorrect-number',
  expired_card: 'expired-card',
  card_declined: 'card-declined',
  generic_decline: 'card-declined',
  incorrect_cvc: 'incorrect-cvc',
  invalid_cvc: 'invalid-cvc',
  invalid_expiry_month: 'invalid-expiry-month',
  invalid_expiry_year: 'invalid-expiry-year',
  card_decline_rate_limit_exceeded: 'card-decline-rate-limit-exceeded',
  payment_intent_authentication_failure:
    'payment-intent-authentication-failure',
};
export const getDtYearsColors = (year: number) => {
  if (year === 0) return null;
  const dtYearsColorsObj: any = {
    1: {
      color1: '#1FD47D',
      color2: '#BCF2D8',
      color3: '#BCF2D8',
      color4: '#039C77',
    },
    2: {
      color1: '#FF8C04',
      color2: '#FFCF86',
      color3: '#F8E4C6',
      color4: '#944802',
    },
    3: {
      color1: '#FF6363',
      color2: '#FFADA9',
      color3: '#FBD5D3',
      color4: '#9A3C1E',
    },
    4: {
      color1: '#8C77C3',
      color2: '#D1BBFF',
      color3: '#E0D3FD',
      color4: '#1F1E66',
    },
    0: {
      color1: '#E87DCA',
      color2: '#F5B8FF',
      color3: '#F9D8FF',
      color4: '#A123A4',
    },
  };
  const yearNumber = year % 5;
  return dtYearsColorsObj[yearNumber];
};

export const shareLink = ({
  url,
  title,
  text,
  callback,
}: {
  url: string;
  title: string;
  text: string;
  callback: () => void;
}) => {
  const payload = { title, text, url };
  async function copyToClipboard() {
    try {
      await navigator.clipboard.writeText(url);
      callback();
    } catch (err) {
      console.error('Failed to copy to clipboard ', err);
    }
  }
  if (navigator.share) {
    navigator
      .share(payload)
      .then(function () {
        console.log('Sharing success');
      })
      .catch(function () {
        console.log('Sharing failed');
      });
  } else {
    copyToClipboard();
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: WebActionType.Share,
        payload,
      })
    );
    console.log('Sorry! Your browser does not support Web Share API');
  }
};

export const getSubscriptionsPath = () =>
  window?.ReactNativeWebView
    ? '/purchase-in-app-subscription'
    : '/choose-subscription';
