import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: 18,
    width: 10,
  },
});

const HourGlassIcon: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 10 18"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path
        d="M0 0.666656V5.66666H0.00833352L0 5.67499L3.33333 8.99999L0 12.3333L0.00833352 12.3417H0V17.3333H10V12.3417H9.99167L10 12.3333L6.66667 8.99999L10 5.67499L9.99167 5.66666H10V0.666656H0ZM8.33333 12.75V15.6667H1.66667V12.75L5 9.41666L8.33333 12.75ZM5 8.58332L1.66667 5.24999V2.33332H8.33333V5.24999L5 8.58332Z"
        fill="#F2994A"
      />
    </SvgIcon>
  );
};

export default HourGlassIcon;
