import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: 12,
    width: 12,
  },
});

const DurationIcon: React.FC<SvgIconProps> = ({
  className,
  htmlColor,
  ...rest
}) => {
  const classes = useStyles();
  const fillColor = htmlColor || 'white';

  return (
    <SvgIcon
      viewBox="0 0 12 12"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path
        d="M11.0769 12H0.923077C0.414 12 0 11.586 0 11.0769V0.923077C0 0.414 0.414 0 0.923077 0H11.0769C11.586 0 12 0.414 12 0.923077V11.0769C12 11.586 11.586 12 11.0769 12ZM0.923077 0.923077V11.0769H11.0776L11.0769 0.923077H0.923077Z"
        fill={fillColor}
      />
      <path
        d="M11.5385 2.30769H0.692322V3.23077H11.5385V2.30769Z"
        fill={fillColor}
      />
      <path
        d="M3.46152 4.84615H2.30768V5.76923H3.46152V4.84615Z"
        fill={fillColor}
      />
      <path
        d="M5.53843 4.84615H4.38458V5.76923H5.53843V4.84615Z"
        fill={fillColor}
      />
      <path
        d="M3.46152 6.69231H2.30768V7.61539H3.46152V6.69231Z"
        fill={fillColor}
      />
      <path
        d="M5.53843 6.69231H4.38458V7.61539H5.53843V6.69231Z"
        fill={fillColor}
      />
      <path
        d="M3.46152 8.53846H2.30768V9.46154H3.46152V8.53846Z"
        fill={fillColor}
      />
      <path
        d="M5.53843 8.53846H4.38458V9.46154H5.53843V8.53846Z"
        fill={fillColor}
      />
      <path
        d="M7.61536 8.53846H6.46152V9.46154H7.61536V8.53846Z"
        fill={fillColor}
      />
      <path
        d="M9.69233 8.53846H8.53848V9.46154H9.69233V8.53846Z"
        fill={fillColor}
      />
      <path
        d="M7.36153 7.57592L6.13522 6.34938L6.78784 5.69677L7.36153 6.27023L9.13522 4.49677L9.78784 5.14938L7.36153 7.57592Z"
        fill={fillColor}
      />
    </SvgIcon>
  );
};

export default DurationIcon;
