import React from 'react';

import { Box, Divider, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { useStyles } from './styles';
import { useGetQuizSessionPrizeQuery } from '@/graphql';
import PrizeItem from './prizes-item';

const QuizTimePrizes: React.FC<{ quizSessionId: string }> = ({
  quizSessionId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data } = useGetQuizSessionPrizeQuery({
    variables: { quizSessionId },
  });
  const lotteryPrizes = data?.quizSessionPrize?.lotteryList;
  const placePrizes = data?.quizSessionPrize?.placeList;
  const participationPrizes = data?.quizSessionPrize?.participationList;
  const isPictureInPrizesSet =
    lotteryPrizes?.some(
      (prize) => prize?.quizTimePrize?.prizeImage !== null || undefined
    ) ||
    placePrizes?.some(
      (prize) => prize?.quizTimePrize?.prizeImage !== null || undefined
    ) ||
    participationPrizes?.some(
      (prize) => prize?.quizTimePrize?.prizeImage !== null || undefined
    );

  return (
    <Box id="prizesSection" display="grid" gridRowGap={8} mx={2} my={2}>
      <Paper elevation={0}>
        <Box paddingY={5} paddingX={6} display="grid" gridRowGap={20}>
          <Box display="flex" flexDirection="column" gridGap="12px">
            <Typography variant="h2">{t('quiztime.prizes.title')}</Typography>
            {placePrizes && placePrizes.length >= 1 && (
              <Box display="flex" flexDirection="column" gridGap="12px">
                {placePrizes.map((prize) => (
                  <PrizeItem
                    key={prize?.id}
                    title={prize?.quizTimePrize?.title}
                    description={prize?.quizTimePrize?.description}
                    prizeImage={prize?.quizTimePrize?.prizeImage}
                    isDefaultImage={isPictureInPrizesSet}
                  />
                ))}
              </Box>
            )}
            {participationPrizes && participationPrizes.length >= 1 && (
              <Box display="flex" flexDirection="column" gridGap="12px">
                {participationPrizes.map((prize) => (
                  <PrizeItem
                    key={prize?.id}
                    title={prize?.quizTimePrize?.title}
                    description={prize?.quizTimePrize?.description}
                    prizeImage={prize?.quizTimePrize?.prizeImage}
                    isDefaultImage={isPictureInPrizesSet}
                  />
                ))}
              </Box>
            )}
            {lotteryPrizes && lotteryPrizes.length >= 1 && (
              <Box>
                <Box className={classes.lotteryCaptionWrapper}>
                  <Typography
                    variant="overline"
                    className={classes.lotteryCaption}
                  >
                    {t('quiztime.prizes.lottery')}
                  </Typography>
                </Box>
                <Divider />
                {lotteryPrizes && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gridGap="12px"
                    mt={3}
                  >
                    {lotteryPrizes.map((prize) => (
                      <PrizeItem
                        key={prize?.id}
                        title={prize?.quizTimePrize?.title}
                        description={prize?.quizTimePrize?.description}
                        prizeImage={prize?.quizTimePrize?.prizeImage}
                        isDefaultImage={isPictureInPrizesSet}
                        rules={
                          prize?.roles || t('quiztime.prizes.lottery-rules')
                        }
                      />
                    ))}
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <Box>
            <Typography variant="body1" className={classes.body1}>
              {t('quiztime.details.how-to-win-title')}
            </Typography>
            <Typography variant="body1" component="span">
              {t('quiztime.details.how-to-win-text')}
            </Typography>
            <Link
              component={RouterLink}
              to={`/quiztime-rules/${quizSessionId}`}
            >
              <Typography
                variant="body1"
                className={classes.link}
                component="span"
              >
                {' '}
                {t('quiztime.details.read-more')}
              </Typography>
            </Link>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default QuizTimePrizes;
