import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import AppButton from '../app-button';
import { useHistory } from 'react-router-dom';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import { useUserDiscountsQuery } from '@/graphql';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { getSubscriptionsPath } from '@/utils/helpers';

const useStyles = makeStyles((theme) => ({
  infoBox: {
    background: '#E2F5FD',
    border: '1px solid #B6E4FA',
    borderRadius: 4,
    padding: 12,
  },
  container: {
    padding: 8,
    paddingBottom: 0,
  },
}));

const DiscountBanner = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    isUserPremiumByPayment,
    isUserInAppPremium,
    isUserStudent,
    isSpecificProfession,
  } = useGetPremiumData();
  const { subsDiscountsEnabled } = useFeatureFlags();

  const { data } = useUserDiscountsQuery();

  const activeDiscount = data?.discounts?.find(
    (it) =>
      it?.status === 'ACTIVE' &&
      it?.subscriptionType?.includes(isUserStudent ? 'STUDENT' : 'PROFESSIONAL')
  );

  const isBannerVisible =
    subsDiscountsEnabled &&
    activeDiscount &&
    !isUserPremiumByPayment &&
    !isUserInAppPremium &&
    !isSpecificProfession;

  return isBannerVisible ? (
    <div className={classes.container}>
      <Box className={classes.infoBox} id="glowingBanner">
        <Typography variant="subtitle1">
          <Trans
            i18nKey="discount-box.title"
            components={{
              link1: <span style={{ color: '#3498DB' }} />,
            }}
            values={{ percent: activeDiscount?.discount }}
          />
        </Typography>
        <Box mt={1}>
          <Typography variant="caption">
            {t('discount-box.description')}
          </Typography>
        </Box>
        <Box mt={2}>
          <AppButton
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => history.push(getSubscriptionsPath())}
          >
            {t('discount-box.btn-text')}
          </AppButton>
        </Box>
      </Box>
    </div>
  ) : (
    <></>
  );
};

export default DiscountBanner;
