import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';
import { sortBy } from 'lodash';

import { Box } from '@material-ui/core';

import * as Form from '@/components/form';
import { useSignUp } from '@/contexts/sign-up-context';
import { useMedicalAssistantDataQuery } from '@/graphql';

import PublicLayout from '../../../components/layout';
import StepNavigate from '../../../components/step-navigation';
import Agreement from '../../../components/agreement';
import PrivacyPolicy from '../../privacy-policy';
import { OTHER_WORKPLACE } from '@/utils/constants';

interface MedicalAssistantInfoProps {
  onBack: () => void;
  onNext: () => void;
}

interface Form {
  speciality: string;
  examYear: number;
  workplace: string;
  anotherWorkplace: string;
  dataProtection: boolean;
  agb: boolean;
  subscription: boolean;
}

const MedicalAssistantInfo: React.FC<MedicalAssistantInfoProps> = ({
  onBack,
  onNext,
}) => {
  const { t } = useTranslation();
  const {
    professionId,
    professionType,
    specialityId,
    examYear,
    workplaceId,
    anotherWorkplace,
    subscribeToNews,
    update,
  } = useSignUp();

  const { register, errors, setValue, watch } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      speciality: specialityId || '',
      examYear,
      workplace: !!anotherWorkplace ? OTHER_WORKPLACE : workplaceId || '',
      anotherWorkplace: anotherWorkplace || '',
      dataProtection: false,
      agb: false,
      subscription: !!subscribeToNews,
    },
  });

  if (professionType === undefined) {
    // TODO handle this case
    throw new Error('Not suported professionType');
  }

  const { data } = useMedicalAssistantDataQuery({
    variables: { professionType },
  });

  useEffect(() => {
    register({ name: 'speciality' }, {});
    register({ name: 'examYear' }, {});
    register({ name: 'workplace' }, {});
    register({ name: 'anotherWorkplace' }, {});

    register({ name: 'dataProtection' }, {});
    register({ name: 'agb' }, {});
    register({ name: 'subscription' }, {});
  }, [anotherWorkplace, examYear, register, specialityId, workplaceId]);

  const changeExamYear = useCallback(
    (e: DateTime | null) => {
      setValue('examYear', e?.year, {
        shouldDirty: true,
        shouldValidate: true,
      });

      update({ examYear: e?.year });
    },
    [setValue, update]
  );

  const changeField = useCallback(
    (prop: keyof Form) =>
      (e: React.ChangeEvent<{ checked?: boolean; value: unknown }>) => {
        const options = {
          shouldDirty: true,
          shouldValidate: true,
        };
        const value = String(e.target.value);
        const checked = Boolean(e.target.checked);

        switch (prop) {
          case 'speciality': {
            setValue(prop, value, options);

            return update({ specialityId: value });
          }
          case 'workplace': {
            setValue(prop, value, options);

            return update({
              workplaceId: value,
            });
          }
          case 'anotherWorkplace':
            setValue(prop, value, options);

            return update({ anotherWorkplace: value });
          case 'subscription': {
            setValue(prop, checked, options);

            return update({ subscribeToNews: e.target.checked });
          }
          case 'agb': {
            setValue(prop, checked, options);
            return;
          }
          case 'dataProtection': {
            setValue(prop, checked, options);
            return;
          }
        }
      },
    [setValue, update]
  );

  const specialities = React.useMemo(() => {
    const specs = data
      ? (data.specialitiesByType || []).map((it) => ({
          value: String(it?.id),
          label: String(it?.title),
        }))
      : [];
    return sortBy(specs, ['label']);
  }, [data]);

  const workplaces = React.useMemo(
    () =>
      (data?.workplaces || [])
        .map((it) => ({
          value: String(it?.id),
          label: String(it?.title),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [data]
  );

  // TODO: Make `isMFA` detection better HUM-427
  const isMFA = React.useMemo(
    () =>
      data
        ? (data?.professions || []).find(
            (it) => it?.id === professionId && it?.title.includes('MFA')
          )
        : [],
    [data, professionId]
  );

  const isValid = useMemo(() => {
    const values = watch();

    return (
      isEmpty(errors) &&
      !!values.examYear &&
      !!values.speciality &&
      !!values.agb &&
      (values.workplace === OTHER_WORKPLACE
        ? !!values.anotherWorkplace
        : !!values.workplace)
    );
  }, [errors, watch]);

  return (
    <PublicLayout title={t('sign-up.medical-assistant.title')} page="3 / 3">
      <Box paddingY={7}>
        <Form.Row>
          <Form.SelectField
            fullWidth
            required
            autoFocus
            data-cy-speciality
            variant="outlined"
            name="speciality"
            label={t('fields.speciality.label')}
            options={specialities}
            errors={errors.speciality}
            value={watch('speciality')}
            onChange={changeField('speciality')}
          />
        </Form.Row>
        <Form.Row>
          <Form.DateField
            autoOk
            fullWidth
            disableFuture
            disableToolbar
            data-cy-exam-year
            format="yyyy"
            views={['year']}
            variant="inline"
            name="exam-year"
            inputVariant="outlined"
            placeholder={t('common.date-format')}
            label={
              isMFA
                ? t('fields.graduation-year.label')
                : t('fields.exam-year.label')
            }
            errors={errors.examYear}
            value={
              !!watch('examYear')
                ? DateTime.fromObject({ year: watch('examYear') })
                : null
            }
            onChange={changeExamYear}
          />
        </Form.Row>
        <Form.Row>
          <Form.SelectField
            fullWidth
            required
            data-cy-workplace
            variant="outlined"
            name="workplace"
            label={t('fields.workplace.label')}
            options={workplaces}
            errors={errors.workplace}
            value={watch('workplace')}
            onChange={changeField('workplace')}
          />
        </Form.Row>
        {watch('workplace') === OTHER_WORKPLACE && (
          <Form.Row>
            <Form.TextField
              fullWidth
              required
              data-cy-another-workplace
              variant="outlined"
              name="another-workplace"
              label={t('fields.another-workplace.label')}
              placeholder={t('fields.another-workplace.placeholder')}
              errors={errors.anotherWorkplace}
              value={watch('anotherWorkplace').replace(/[^0-9a-zA-Z]/gi, '')}
              onChange={changeField('anotherWorkplace')}
            />
          </Form.Row>
        )}
        <Agreement
          data-cy-agb
          name="agb"
          variant="agb"
          checked={watch('agb')}
          onChange={changeField('agb')}
        />
        <Agreement
          noDivider
          data-cy-subscription
          name="subscription"
          variant="subscription"
          checked={watch('subscription')}
          onChange={changeField('subscription')}
        />
        <PrivacyPolicy />
      </Box>
      <StepNavigate disabled={!isValid} onNext={onNext} onBack={onBack} />
    </PublicLayout>
  );
};

export default MedicalAssistantInfo;
