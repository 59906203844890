import React from 'react';

import { Fab, FabProps } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import useStyles from './styles';

const EditBtn: React.FC<Omit<FabProps, 'children'>> = ({
  color = 'primary',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Fab className={classes.editBtn} color={color} aria-label="edit" {...rest}>
      <EditIcon />
    </Fab>
  );
};

export default EditBtn;
