import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const DuelRule6Icon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M23.2316 22.4645H21.5406V16.4769C21.5406 16.0526 21.1966 15.709 20.7722 15.709H17.1173C16.693 15.709 16.349 16.0526 16.349 16.4769C16.349 16.9012 16.693 17.2449 17.1173 17.2449H20.0044V22.4645H15.8319V13.2396C15.8319 12.8153 15.4879 12.4716 15.0636 12.4716C14.6393 12.4716 14.2956 12.8153 14.2956 13.2396V22.4645H9.70722V11.9275H15.0636C15.4879 11.9275 15.8319 11.5839 15.8319 11.1596C15.8319 10.7352 15.4879 10.3916 15.0636 10.3916H8.93933C8.51544 10.3916 8.17139 10.7352 8.17139 11.1596V22.4644H3.99898V14.8841H6.886C7.30989 14.8841 7.65394 14.5404 7.65394 14.1161C7.65394 13.6918 7.31031 13.3482 6.886 13.3482H3.23062C2.80673 13.3482 2.46268 13.6918 2.46268 14.1161V22.4645H0.76794C0.34401 22.4645 0 22.8085 0 23.2324C0 23.6564 0.34401 24 0.76794 24H23.2316C23.6555 24 24 23.6567 24 23.2324C24 22.8081 23.6559 22.4645 23.2316 22.4645Z"
        fill="#2B75B3"
      />
      <path
        d="M8.88366 6.17298L8.54795 8.13363C8.4789 8.53591 8.56295 8.9028 8.78546 9.16699C9.11784 9.56135 9.69608 9.64957 10.2402 9.36335L12.0007 8.43817L13.7612 9.36377C13.9784 9.47735 14.1959 9.53514 14.4081 9.53514C14.7296 9.53514 15.0167 9.40408 15.2167 9.16614C15.4389 8.90238 15.5225 8.53506 15.453 8.13447L15.1169 6.17345L16.5413 4.78609C16.9094 4.42665 17.0434 3.98279 16.9094 3.56846C16.775 3.15329 16.4061 2.87208 15.8936 2.79511L13.9267 2.51016L13.0473 0.728439C12.8194 0.265829 12.4375 0 12.0002 0C11.563 0 11.1816 0.265407 10.9548 0.726751L10.0742 2.51016L8.104 2.79554C7.59439 2.87166 7.22502 3.15371 7.09147 3.56888C6.95793 3.98405 7.09232 4.42792 7.46089 4.78693L8.88366 6.17298ZM10.6941 3.97234C10.9445 3.93615 11.1608 3.77888 11.2727 3.55219L12.0007 2.07872L12.7283 3.55262C12.8402 3.77935 13.0565 3.93657 13.307 3.97281L14.9335 4.20868L13.7563 5.3552C13.5753 5.53159 13.4921 5.78575 13.5354 6.03493L13.8125 7.65569L12.3582 6.89106C12.2463 6.832 12.1236 6.80289 12.0008 6.80289C11.8781 6.80289 11.7554 6.832 11.6439 6.89069L10.1883 7.65531L10.4658 6.03456C10.5082 5.78537 10.4258 5.53121 10.2445 5.35482L9.06802 4.20788L10.6941 3.97234Z"
        fill="#2B75B3"
      />
    </SvgIcon>
  );
};

export default DuelRule6Icon;
