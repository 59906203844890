import React from 'react';
import { useHistory } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useStyles } from './styles';
import Offline from '../offline';

interface StackLayoutProps {
  title: string;
  back?: string;
  backState?: Record<string, unknown>;
  toolbar?: React.ReactNode;
  hideBackArrow?: boolean;
}

const StackLayout: React.FC<StackLayoutProps> = ({
  title,
  back,
  backState,
  toolbar,
  children,
  hideBackArrow,
}) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar elevation={0} className={classes.top}>
        <Toolbar>
          {!hideBackArrow && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={() =>
                back ? history.push(back, backState) : history.goBack()
              }
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography color="inherit" variant="h6">
            {title}
          </Typography>
          <div className={classes.toolbar}>{toolbar}</div>
        </Toolbar>
      </AppBar>
      <main className={classes.main} id="scrollContainer">
        <Offline>{children}</Offline>
      </main>
    </div>
  );
};

export default StackLayout;
