import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const DuelRule7Icon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M12 1C9.08264 1 6.28489 2.15892 4.22181 4.22181C2.15896 6.28485 1 9.08296 1 12C1 14.917 2.15892 17.7151 4.22181 19.7782C6.28485 21.841 9.08296 23 12 23C14.917 23 17.7151 21.8411 19.7782 19.7782C21.841 17.7152 23 14.917 23 12C22.9968 9.08354 21.837 6.28759 19.7747 4.22541C17.7125 2.16305 14.9162 1.00323 12.0001 1.00009L12 1ZM12 21.4287C9.49939 21.4287 7.10114 20.4354 5.33305 18.6671C3.56482 16.8989 2.57144 14.5007 2.57144 12.0002C2.57144 9.49966 3.56482 7.10132 5.33305 5.33323C7.10128 3.565 9.49948 2.57162 12 2.57162C14.5005 2.57162 16.8989 3.565 18.667 5.33323C20.4352 7.10146 21.4286 9.49966 21.4286 12.0002C21.4259 14.4999 20.4317 16.8963 18.664 18.6644C16.8963 20.4321 14.4997 21.4264 11.9997 21.429L12 21.4287Z"
        fill="#2B75B3"
      />
      <path
        d="M9.6429 8.07153C9.43454 8.07153 9.2346 8.15432 9.08728 8.30164C8.93996 8.44896 8.85718 8.6489 8.85718 8.85725V15.143C8.85718 15.4238 9.00695 15.6832 9.25004 15.8235C9.49312 15.9638 9.79268 15.9638 10.0358 15.8235C10.2788 15.6832 10.4286 15.4238 10.4286 15.143V8.85725C10.4286 8.6489 10.3458 8.44896 10.1985 8.30164C10.0512 8.15432 9.85125 8.07153 9.6429 8.07153Z"
        fill="#2B75B3"
      />
      <path
        d="M14.3573 8.07153C14.1489 8.07153 13.949 8.15432 13.8016 8.30164C13.6543 8.44896 13.5715 8.6489 13.5715 8.85725V15.143C13.5715 15.4238 13.7213 15.6832 13.9644 15.8235C14.2075 15.9638 14.507 15.9638 14.7501 15.8235C14.9932 15.6832 15.143 15.4238 15.143 15.143V8.85725C15.143 8.6489 15.0602 8.44896 14.9129 8.30164C14.7656 8.15432 14.5656 8.07153 14.3573 8.07153Z"
        fill="#2B75B3"
      />
    </SvgIcon>
  );
};

export default DuelRule7Icon;
