import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 48,
    height: 48,
    borderRadius: '50%',
    backgroundColor: '#E6EEFA',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },
  centeredAvatar: {
    display: 'flex!important',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 48,
    height: 48,
    borderRadius: '50%',
    backgroundColor: '#2C3E50',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
      objectPosition: 'center',
      width: 24,
      height: 24,
      filter: 'brightness(0) invert(1)',
    },
  },
}));
