import React from 'react';

import Box from '@material-ui/core/Box';

import ChooseItem from '@/components/choose-item';
import { DuelRoundQuestionAnswerType } from '@/graphql';

import Explanation from '../../components/explanation';

interface InCorrectAnswerProps {
  userQuestion: DuelRoundQuestionAnswerType;
}

const InCorrectAnswer: React.FC<InCorrectAnswerProps> = ({ userQuestion }) => {
  const uAnswer = (userQuestion?.question?.answers || []).find(
    (it) =>
      !!userQuestion?.userAnswer?.value &&
      it.value === userQuestion.userAnswer.value
  );
  const cAnswer = (userQuestion?.question?.answers || []).find(
    (it) =>
      !!userQuestion?.userAnswer?.value &&
      it.value === userQuestion?.correctAnswer?.value
  );

  return (
    <>
      <Box paddingBottom={3}>
        <Box display="grid" gridRowGap={12}>
          <ChooseItem data-cy-correct withoutIcon variant="correct">
            {cAnswer?.value}
          </ChooseItem>
          <ChooseItem data-cy-incorrect variant="incorrect">
            {uAnswer?.value}
          </ChooseItem>
        </Box>
      </Box>
      <Explanation
        data-cy-explanation
        explanation={userQuestion?.question?.explanation}
        links={userQuestion?.question?.sortedLinks}
      />
    </>
  );
};

export default InCorrectAnswer;
