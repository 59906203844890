import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dialog: {
    position: 'fixed',
    width: '100%',
    margin: 0,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    bottom: 0,
  },
  body2: {
    color: '#2C3E50',
  },
}));
