import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(17),
  },
  close: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    position: 'absolute',
  },
  headerInfo: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderRadius: 6,
    backgroundColor: '#E2F5FD',
  },
  listItem: {
    display: 'flex',
    marginTop: 16,
    color: '#2B75B3',
  },
  iconBox: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 40,
    height: 40,
    background: '#E2F5FD',
    borderRadius: 4,
    marginRight: 20,
  },
}));
