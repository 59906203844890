import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { ReactComponent as CupIcon } from './ranking.svg';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

type RankLabelPrors = {
  userRank?: number | null;
  leftPos?: number | null;
};

const RankLabel: React.FC<RankLabelPrors> = ({ userRank, leftPos }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const userRankPrefix = useMemo(() => {
    switch (userRank) {
      case 1:
        return t('quiztime.ranking.postfix-st');
      case 2:
        return t('quiztime.ranking.postfix-nd');
      case 3:
        return t('quiztime.ranking.postfix-rd');
      default:
        return t('quiztime.ranking.postfix-th');
    }
  }, [userRank, t]);

  return (
    <div className={classes.userRating} style={{ left: leftPos || 'auto' }}>
      <CupIcon />
      <Typography variant="caption" color="inherit">
        {t('quiztime.ranking.prefix-no')}
        {userRank}
        {userRankPrefix}
      </Typography>
    </div>
  );
};

export default RankLabel;
