import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  plan: {
    position: 'relative',
    padding: theme.spacing(4),
    paddingRight: theme.spacing(5),
    backgroundColor: '#E6EEFA',
    border: '1px solid #E6EEFA',
    borderRadius: 4,
    marginTop: theme.spacing(2),
  },
  planChosen: {
    borderColor: theme.palette.primary.main,
    backgroundColor: 'transparent',
  },
  chipGolden: {
    position: 'absolute',
    right: 20,
    top: -12,
    height: 24,
    padding: '4px',
  },
  radio: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 14,
    height: 14,
    color: '#DADADA',
    border: '2px solid currentColor',
    borderRadius: '50%',
    marginLeft: 'auto',
  },
  radioActive: {
    color: theme.palette.primary.main,
  },
  radioInner: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  statusIsActive: {
    color: '#27AE60',
  },
}));
