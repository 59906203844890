import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  playDuelBtn: {
    width: 'calc(100% - 32px)',
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(4),
    right: theme.spacing(4),
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)!important',
  },
  startBtnDisabled: {
    width: 'calc(100% - 32px)',
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(4),
    right: theme.spacing(4),
    borderColor: '#C6D5E3',
    color: theme.palette.action.disabled,
    background: theme.palette.action.disabledBackground + '!important',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)!important',
  },
}));
