import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: 24,
    width: 24,
  },
});

const KeyIcon: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 19H22V15H24V9H13.32C12.17 6.58 9.72 5 7 5C3.14 5 0 8.14 0 12C0 15.86 3.14 19 7 19C9.72 19 12.18 17.42 13.32 15H16V19ZM20 17H18V13H11.94L11.71 13.67C11.01 15.66 9.11 17 7 17C4.24 17 2 14.76 2 12C2 9.24 4.24 7 7 7C9.11 7 11.01 8.34 11.71 10.33L11.94 11H22V13H20V17ZM7 15C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9C8.65 9 10 10.35 10 12C10 13.65 8.65 15 7 15ZM6 12C6 11.45 6.45 11 7 11C7.55 11 8 11.45 8 12C8 12.55 7.55 13 7 13C6.45 13 6 12.55 6 12Z"
        fill="#879BB2"
      />
    </SvgIcon>
  );
};

export default KeyIcon;
