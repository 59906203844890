import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as EmptyLaufendIcon } from './empty-laufend.svg';
import { ReactComponent as EmptyAnstehendIcon } from './empty-anstehend.svg';

interface EmptyTabsProps {
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: 'Laufend' | 'Anstehend' | 'Fertig';
}

const EmptyTabs: React.FC<EmptyTabsProps> = ({
  disabled,
  onClick,
  variant,
}) => {
  const { t } = useTranslation();

  const displayIcon = () => {
    switch (variant) {
      case 'Laufend':
        return <EmptyLaufendIcon />;
      case 'Anstehend':
        return <EmptyAnstehendIcon />;
      case 'Fertig':
        return <EmptyLaufendIcon />;
    }
  };

  const displayText = () => {
    switch (variant) {
      case 'Laufend':
        return <>{t('quiztime.tabs.active.empty-title')}</>;
      case 'Anstehend':
        return <>{t('quiztime.tabs.pending.empty-title')}</>;
      case 'Fertig':
        return <>{t('quiztime.tabs.finished.empty-title')}</>;
    }
  };

  return (
    <Box
      paddingY={7}
      display="flex"
      flexDirection="column-reverse"
      alignItems="center"
      justifyContent="end"
      height="100%"
      boxSizing="border-box"
    >
      <Box
        id="viewarea"
        position="absolute"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        alignItems="center"
        top="26%"
      >
        <Box marginBottom={5}>{displayIcon()}</Box>
        <Box maxWidth={260}>
          <Typography align="center" variant="body2">
            {displayText()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EmptyTabs;
