import { datadogRum } from '@datadog/browser-rum';
import packageJson from '../../../package.json';

export default function initDatadogRum() {
  if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID!,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN!,
      site: 'datadoghq.eu',
      service: 'hum',
      env: process.env.REACT_APP_ENV_NAME,
      version: packageJson.version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 50,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }
}
