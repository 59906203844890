import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: 16,
    width: 16,
  },
});

const CheckCircleFilledIcon: React.FC<SvgIconProps> = ({
  className,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 16 16"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#3498DB" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="white" />
      <path
        d="M6.66459 9.61457L4.92709 7.87707L4.33542 8.46457L6.66459 10.7937L11.6646 5.79374L11.0771 5.20624L6.66459 9.61457Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default CheckCircleFilledIcon;
