import React from 'react';
import { Avatar, Box, Divider, Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import HyperText from '@/components/hypertext';
import { CustomCodesType } from '@/type';

interface SponsorBlockProps {
  code: CustomCodesType | null | undefined;
}

const SponsorBlock: React.FC<SponsorBlockProps> = ({ code }) => {
  const { t } = useTranslation();

  if (!code?.sponsor) return null;

  return (
    <Box mt={5}>
      <Divider />
      <Box paddingTop={5}>
        <Typography variant="caption">
          {t('fields.sponsored-by.label')}
        </Typography>
        <Typography variant="body1" component="div">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            component="span"
          >
            {code?.sponsor?.title}
            {code?.sponsor?.logo && <Avatar src={code?.sponsor?.logo} />}
          </Box>
        </Typography>
        <Box marginTop={2}>
          <Typography variant="body2">
            {!!code?.sponsor?.website && (
              <HyperText text={t('fields.sponsor-website.info')}>
                <Link href={code.sponsor.website} target="_blank">
                  {new URL(code.sponsor.website).hostname}
                </Link>
              </HyperText>
            )}
            {!!code?.sponsor?.email && (
              <HyperText text={t('fields.sponsor-email.info')}>
                <Link href={`mailto:${code.sponsor.email}`} target="_blank">
                  {code.sponsor.email}
                </Link>
              </HyperText>
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SponsorBlock;
