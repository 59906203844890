import React from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Box, Typography } from '@material-ui/core';

import { useStyles } from './styles';
// import StarIcon from '@/components/icons/star';
import { Place } from '@material-ui/icons';

type OrgHeaderProps = {
  name: string;
  // averageRate?: number | null;
  // feedbackCount?: number | null;
  professions?: {
    id: string;
    title: string;
  }[];
  specialities?: {
    id: string;
    title: string;
  }[];
  location?: string | null;
  classroomsCount?: number | null;
  image?: string | null;
};

const OrgHeader: React.FC<OrgHeaderProps> = ({
  name,
  // averageRate,
  // feedbackCount,
  professions,
  specialities,
  location,
  classroomsCount,
  image,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Box className={classes.inner}>
        <Box display="flex" marginRight={5} className={classes.orgAvatarBox}>
          {image && (
            <Avatar src={image} alt={name} className={classes.orgAvatar}>
              {t('class-room.learning-more-link')}
            </Avatar>
          )}
        </Box>
        <Box display="grid" alignContent="flex-start" gridRowGap={8}>
          <Typography variant="h2">{name}</Typography>
          <Typography variant="subtitle2">
            {professions?.map((it, idx) => (
              <span key={it.id}>
                {idx > 0 && idx < professions.length && `, ${' '}`}
                {it.title}
              </span>
            ))}
            {!!specialities?.length && `, ${' '}`}
            {specialities?.map((it, idx) => (
              <span key={it.id}>
                {idx > 0 && idx < specialities.length && `, ${' '}`}
                {it.title}
              </span>
            ))}
          </Typography>
          {location && (
            <Box display="flex" alignItems="center">
              <Place
                htmlColor="inherit"
                style={{ marginRight: 4, fontSize: 14 }}
              />
              <Typography color="inherit" variant="caption">
                {location}
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="center">
            {/* {!!averageRate && (
              <>
                <StarIcon htmlColor="#F2C94D" style={{ marginRight: 6 }} />
                <Typography color="inherit" variant="caption">
                  {averageRate}
                </Typography>
              </>
            )} */}
            {/* {!!feedbackCount && (
              <Typography variant="caption" style={{ marginLeft: 3 }}>
                ({feedbackCount})
              </Typography>
            )} */}
            {!!classroomsCount && (
              <Box
                component="span"
                // ml={averageRate || feedbackCount ? 4 : 0}
              >
                <Typography variant="caption">
                  {classroomsCount} {t('common.classrooms')}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OrgHeader;
