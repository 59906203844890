import React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

interface TabPanelProps {
  index: number;
  value: number;
  styles?: string | undefined;
  cssId?: string | undefined;
}

export const AppTabs = withStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
  },
}))(Tabs);

export const AppTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      textTransform: 'none',
      color: '#879BB2',
      fontWeight: 400,
      boxShadow: 'inset 0px -1px 0px #ECF0F1',
      '& .MuiTab-wrapper': {
        whiteSpace: 'nowrap',
      },
    },
    selected: {
      boxShadow: `inset 0px -2px 0px ${theme.palette.primary.main}`,
    },
  })
)(Tab);

const useTabPanelStyle = makeStyles({
  root: {
    position: 'relative',
    height: '100%',
    overflow: 'auto',
  },
});

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  styles,
  cssId,
  ...other
}) => {
  const classes = useTabPanelStyle();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={cssId || `simple-tabpanel-${index}`}
      aria-labelledby={cssId || `simple-tabpanel-${index}`}
      className={clsx(classes.root, styles)}
      {...other}
    >
      {value === index && children}
    </div>
  );
};
