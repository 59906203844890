import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  caption: {
    color: '#929DA8',
  },
  goal: {
    color: '#647485',
  },
  barColorSecondary: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  barColorPrimary: {
    backgroundColor: theme.palette.primary.light,
  },
  progressWrap: {
    position: 'relative',
  },
  progressGoal: {
    position: 'absolute',
    top: -2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.primary.light,
    fontSize: 10,
  },
  goalMark: {
    width: 1,
    height: 8,
    borderRadius: 2,
    backgroundColor: 'currentColor',
  },
  lecProgressBox: {
    background: 'rgba(230, 238, 250, 0.23)',
    border: '1px solid #E6EEFA',
    borderRadius: 4,
  },
}));
