import React, { useState, useCallback, useRef, useLayoutEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Link,
  Box,
  Collapse,
  Paper,
  Typography,
  IconButton,
  Avatar,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { useStyles } from './styles';

type QuizTimeDescriptionProps = {
  description?: string | null;
  link?: string | null;
  orgId?: string | null;
  orgName?: string | null;
  orgEmail?: string | null;
  orgLogo?: string | null;
  orgWebsite?: string | null;
  isUseCode?: boolean | null;
  sponsor?:
    | {
        id: string | null;
        title?: string | null;
        logo?: string | null;
        website?: string | null;
        email?: string | null;
      }[]
    | null;
};

const QuizTimeDescription: React.FC<QuizTimeDescriptionProps> = ({
  description,
  link,
  orgId,
  orgName,
  orgEmail,
  orgLogo,
  orgWebsite,
  children,
  isUseCode,
  sponsor,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const descrBlockRef = useRef<HTMLDivElement | null>(null);
  const [descrBlockHeight, setDescrBlockHeight] = useState<number | null>(null);

  useLayoutEffect(() => {
    if (descrBlockRef?.current?.clientHeight)
      setDescrBlockHeight(descrBlockRef?.current?.clientHeight);
  }, []);

  const toggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const descriptionArr = description?.split('\r') || [];
  const descrBlock = (
    <div ref={descrBlockRef}>
      <Box paddingY={5} className={classes.descriptionBox}>
        <Typography variant="h2" className={classes.descriptionTitle}>
          {t('class-room.descr-title')}
        </Typography>
        {descriptionArr.map((line, idx) => (
          <Typography key={idx} variant="body1">
            <span dangerouslySetInnerHTML={{ __html: line }}></span>
          </Typography>
        ))}
        {link && (
          <Box mt={3}>
            <Link href={link}>
              <Typography variant="body1" className={classes.linkText}>
                {t('class-room.learning-more-link')}
              </Typography>
            </Link>
          </Box>
        )}
        {isUseCode && (
          <Box mt={5}>
            <Typography variant="subtitle1" component="span">
              {t('quiztime.details.use-code-warning-title')}
              <Typography variant="body1" display="inline">
                {t('quiztime.details.use-code-warning-body')}
              </Typography>
            </Typography>
          </Box>
        )}
        <Box>
          {(orgLogo || orgName || orgWebsite || orgEmail) && (
            <Box mt={7} mb={1}>
              <Typography variant="overline">
                {t('class-room.organizator')}
              </Typography>
            </Box>
          )}

          <Box display="flex">
            {orgLogo && (
              <RouterLink to={`/classrooms/organizer/${orgId}`}>
                <Box mt={2}>
                  <Avatar src={orgLogo} alt={orgName || ''}>
                    {t('class-room.learning-more-link')}
                  </Avatar>
                </Box>
              </RouterLink>
            )}
            <Box marginLeft={3}>
              {orgName && (
                <Box my={2}>
                  <Typography variant="subtitle2">{orgName}</Typography>
                </Box>
              )}
              {orgWebsite && (
                <Box mb={1}>
                  <Link href={orgWebsite} target="_blank">
                    <Typography variant="body2" className={classes.linkText}>
                      {orgWebsite}
                    </Typography>
                  </Link>
                </Box>
              )}
              {orgEmail && (
                <Box mb={1}>
                  <Link href={`mailto:${orgEmail}`} target="_blank">
                    <Typography variant="body2" className={classes.linkText}>
                      {orgEmail}
                    </Typography>
                  </Link>
                </Box>
              )}
            </Box>
          </Box>
          {sponsor && sponsor?.length >= 1 && (
            <Box mt={7} mb={1}>
              <Typography variant="overline">
                {t('quiztime.details.sponsor')}
              </Typography>
            </Box>
          )}
          <Box display="flex" flexDirection="column" gridGap="8px">
            {sponsor?.map((sponsor) => (
              <Box display="flex" key={sponsor?.id} alignItems="center">
                {sponsor?.logo && (
                  <Link href={sponsor?.website || ''} target="_blank">
                    <Box mt={2}>
                      <Avatar
                        src={sponsor?.logo || ''}
                        alt={sponsor.title || ''}
                      >
                        {t('class-room.learning-more-link')}
                      </Avatar>
                    </Box>
                  </Link>
                )}
                <Box marginLeft={3}>
                  {sponsor?.title && (
                    <Box my={2}>
                      <Typography variant="subtitle2">
                        {sponsor?.title}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </div>
  );

  return (
    <Box display="grid" gridRowGap={8} mx={2} mt={2}>
      <Paper elevation={0}>
        {descrBlockHeight && descrBlockHeight > 300 ? (
          <>
            <Collapse in={expanded} collapsedHeight={300}>
              {descrBlock}
            </Collapse>
            <Box display="grid">
              <IconButton aria-label="expand" onClick={toggle}>
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Box>
          </>
        ) : (
          <Box px={6}>{descrBlock}</Box>
        )}
      </Paper>
      {children}
    </Box>
  );
};

export default QuizTimeDescription;
