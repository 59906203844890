import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { useStyles } from './styles';
import { LinkType, Maybe } from '@/graphql';
import HyperlinkIcon from '@/components/icons/hyperlink';
import { datadogRum } from '@datadog/browser-rum';

interface ExplanationProps {
  explanation?: string | null;
  links?: Maybe<LinkType>[] | undefined | null;
}

const Explanation: React.FC<ExplanationProps> = ({
  explanation,
  links,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  function handleExternalLinkClick(linkCategory: string, linkUrl: string) {
    datadogRum.addAction('external explanation link', {
      linkCategory,
      linkUrl,
    });
  }

  return explanation || links?.length ? (
    <Box
      clone
      display="grid"
      gridRowGap={16}
      paddingX={6}
      paddingY={4}
      paddingBottom={2}
      className={classes.root}
      {...rest}
    >
      <Paper variant="outlined">
        {explanation && (
          <Typography variant="body2" color="textPrimary">
            <Box component="span" paddingRight={1} style={{ fontWeight: 600 }}>
              {t('common.explanation')}
            </Box>
            {explanation}
          </Typography>
        )}
        {!!links?.length && (
          <Box>
            <Typography
              variant="subtitle2"
              color="textPrimary"
              style={{ fontWeight: 500 }}
            >
              {t('common.recommended-reading')}
            </Typography>
            <Box
              marginLeft={-2}
              mt={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              {links?.map(
                (it, idx) =>
                  it && (
                    <Box key={idx} className={classes.box}>
                      <Button
                        key={it.id}
                        href={it.url}
                        target="_blank"
                        rel="noreferrer"
                        color="primary"
                        style={{
                          minWidth: 24,
                          textTransform: 'initial',
                          fontWeight: 400,
                        }}
                        onClick={() =>
                          handleExternalLinkClick(
                            it.linkCategory?.title,
                            it.url
                          )
                        }
                      >
                        <HyperlinkIcon className={classes.hyperlinkIcon} />
                        {it.linkCategory?.title}
                      </Button>
                    </Box>
                  )
              )}
            </Box>
          </Box>
        )}
      </Paper>
    </Box>
  ) : (
    <></>
  );
};

export default Explanation;
