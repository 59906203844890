import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, ButtonGroup, Paper } from '@material-ui/core';

import MembersList from '../../../members-list';
import { useUser } from '@/contexts/user-context';
import { LockOutlined } from '@material-ui/icons';
import { useStyles } from './styles';

interface MembersTabProps {
  sessionId: string;
  roomFinished?: boolean;
  roomInProgress?: boolean;
  isDuelsLimit?: boolean;
  isDuelsOff?: boolean;
  isNetworkType?: boolean;
  crTitle?: string | undefined;
  deepLinkHash?: string | null;
  handleClickOnPlayBtn: () => void;
}

const MembersTab: React.FC<MembersTabProps> = ({
  sessionId,
  roomFinished,
  roomInProgress,
  isDuelsLimit,
  isNetworkType,
  isDuelsOff,
  crTitle,
  deepLinkHash,
  handleClickOnPlayBtn,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user } = useUser();

  const [navBtnActiveId, setNavBtnActiveId] = useState(roomFinished ? 2 : 1);
  const [numberOfMembers, setNumberOfMembers] = useState(0);
  const [isDuelsDisabled, setDuelsDisabled] = useState(true);

  const nav = React.useMemo(() => {
    const navBtnConfig = [
      {
        id: 1,
        label: t('classrooms.list'),
      },
      {
        id: 2,
        label: t('classrooms.ranking'),
      },
    ];
    return (
      <ButtonGroup
        color="primary"
        fullWidth
        aria-label="outlined primary button group"
      >
        {navBtnConfig.map((btn) => (
          <Button
            key={btn.id}
            className={`${classes.navBtn} ${
              navBtnActiveId !== btn.id ? classes.navBtnDisabled : ''
            }`}
            onClick={() => setNavBtnActiveId(btn.id)}
          >
            {btn.label}
          </Button>
        ))}
      </ButtonGroup>
    );
  }, [t, classes.navBtn, classes.navBtnDisabled, navBtnActiveId]);

  return (
    <>
      {!roomFinished && !isNetworkType && (
        <Paper square elevation={0} className={classes.navList}>
          <Box display="flex" justifyContent="center" px={4} py={3}>
            {nav}
          </Box>
        </Paper>
      )}
      <Box data-cy-members-list px={2} pb={14} mt={2}>
        <Paper elevation={0}>
          <MembersList
            sessionId={sessionId}
            navBtnActiveId={navBtnActiveId}
            roomInProgress={roomInProgress}
            isNetworkType={isNetworkType}
            isDuelsOff={isDuelsOff}
            crTitle={crTitle}
            deepLinkHash={deepLinkHash}
            getNumberOfMembers={setNumberOfMembers}
            getIsDuelsDisabled={setDuelsDisabled}
          />
        </Paper>
        {navBtnActiveId !== 2 && !isDuelsDisabled && !isDuelsOff && (
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickOnPlayBtn}
            startIcon={isDuelsLimit && <LockOutlined />}
            className={`${
              isDuelsLimit ? classes.startBtnDisabled : classes.playDuelBtn
            }`}
            disabled={!roomInProgress || isDuelsDisabled || !numberOfMembers}
            style={{
              background: user?.settings?.activeQuizTime?.id ? '#929DA8' : '',
            }}
          >
            {t('classrooms.play-duel')}
          </Button>
        )}
      </Box>
    </>
  );
};

export default MembersTab;
