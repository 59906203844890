import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const DuelRule3Icon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M1.89877 21.4871C2.08711 21.6929 2.37924 21.7678 2.64344 21.6782L5.65355 20.6561L6.91198 23.5752C7.0227 23.832 7.27512 23.9989 7.55478 24H7.55769C7.83623 24 8.0886 23.8356 8.20106 23.5805L10.5468 18.2597C10.9141 18.3048 11.288 18.3284 11.6673 18.3284C12.0466 18.3284 12.4204 18.3048 12.7877 18.2597L15.1335 23.5805C15.2459 23.8356 15.4983 24 15.7769 24H15.7798C16.0595 23.9989 16.3119 23.832 16.4226 23.5752L17.6811 20.6561L20.6912 21.6782C20.9552 21.7678 21.2475 21.6929 21.4359 21.4871C21.6243 21.2814 21.6732 20.9837 21.5606 20.7285L18.9203 14.7438C20.1035 13.2029 20.8081 11.2761 20.8081 9.18763C20.8081 4.12154 16.7075 0 11.6673 0C6.62712 0 2.52657 4.12154 2.52657 9.18763C2.52657 11.2761 3.23116 13.2029 4.4144 14.7438L1.77408 20.7285C1.66148 20.9837 1.71038 21.2814 1.89877 21.4871ZM19.628 19.832L17.5185 19.1157C17.1713 18.9978 16.7919 19.1665 16.6467 19.5032L15.7697 21.5375L14.1978 17.9718C15.5918 17.5696 16.8497 16.8436 17.885 15.8812L19.628 19.832ZM3.93284 9.18763C3.93284 4.89696 7.40253 1.40627 11.6673 1.40627C15.9321 1.40627 19.4018 4.89696 19.4018 9.18763C19.4018 13.4524 15.9321 16.9221 11.6673 16.9221C7.40253 16.9221 3.93284 13.4524 3.93284 9.18763ZM5.4496 15.8812C6.48499 16.8436 7.74285 17.5696 9.13688 17.9718L7.56491 21.5374L6.68796 19.5031C6.54279 19.1664 6.16333 18.9978 5.81621 19.1157L3.70667 19.832L5.4496 15.8812Z"
        fill="#2B75B3"
      />
      <path
        d="M8.90565 10.0849L8.4945 12.4818C8.44927 12.7455 8.55769 13.0122 8.77421 13.1695C8.99035 13.3265 9.27742 13.3477 9.5147 13.223L11.6673 12.0913L13.8198 13.223C14.0568 13.3475 14.3439 13.3268 14.5603 13.1695C14.7768 13.0122 14.8853 12.7456 14.8401 12.4818L14.429 10.0849L16.1704 8.38735C16.362 8.20055 16.431 7.92113 16.3483 7.66659C16.2655 7.41206 16.0456 7.22653 15.7807 7.18804L13.3741 6.83835L12.2978 4.6576C12.1793 4.4176 11.9349 4.26562 11.6673 4.26562C11.3997 4.26562 11.1552 4.41755 11.0368 4.6576L9.96049 6.83835L7.55385 7.18804C7.289 7.22653 7.06892 7.41206 6.98623 7.66659C6.9035 7.92113 6.9725 8.20055 7.16412 8.3874L8.90565 10.0849ZM10.5285 8.17679C10.7576 8.14351 10.9555 7.99969 11.0579 7.79213L11.6673 6.55747L12.2766 7.79213C12.379 7.99965 12.577 8.14351 12.806 8.17679L14.1686 8.37479L13.1826 9.33584C13.0169 9.49737 12.9413 9.73011 12.9805 9.9582L13.2132 11.3153L11.9945 10.6746C11.7897 10.5669 11.5449 10.5668 11.3401 10.6746L10.1214 11.3153L10.3541 9.9582C10.3932 9.73011 10.3176 9.49737 10.1519 9.33584L9.1659 8.37479L10.5285 8.17679Z"
        fill="#2B75B3"
      />
    </SvgIcon>
  );
};

export default DuelRule3Icon;
