import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const BulbIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...rest}>
      <g clipPath="url(#clip0_19941_17550)">
        <path
          d="M0.703125 22.2855H8.16384C8.46581 23.2767 9.38841 24 10.477 24H13.523C14.6116 24 15.5342 23.2767 15.8362 22.2855H23.2969C23.6852 22.2855 24 21.9707 24 21.5824C24 21.1941 23.6852 20.8793 23.2969 20.8793H22.8716V18.427C22.8716 17.9732 22.4473 17.6383 22.006 17.743C20.8816 18.01 20.1353 18.1429 19.1764 18.1304C17.5369 18.1088 16.8362 17.7812 14.8871 17.6309C13.6349 17.5344 12.6192 18.1129 11.9722 18.9349C11.3249 18.1129 10.3091 17.5344 9.05737 17.6309C7.11281 17.7808 6.40472 18.1089 4.76798 18.1304C3.80911 18.1428 3.06295 18.01 1.93842 17.743C1.497 17.6381 1.07283 17.9734 1.07283 18.427V20.8793H0.703125C0.314812 20.8793 0 21.1941 0 21.5824C0 21.9707 0.314812 22.2855 0.703125 22.2855ZM2.47913 19.3046C3.1958 19.4499 3.92391 19.5481 4.78669 19.5365C6.56137 19.5131 7.29905 19.1769 9.16552 19.0329C9.843 18.9808 10.9689 19.3695 11.2901 20.6548C11.2902 20.6552 11.2904 20.6555 11.2905 20.6559C11.4652 21.3484 12.4725 21.3764 12.6542 20.6556C12.6543 20.6553 12.6544 20.6551 12.6544 20.6548C12.9756 19.3695 14.1011 18.9808 14.779 19.0329C16.6458 19.1769 17.3828 19.5131 19.1577 19.5365C20.0205 19.5481 20.7486 19.4499 21.4653 19.3046V20.8793H15.2413C15.24 20.8793 15.2389 20.8791 15.2377 20.8791C14.8493 20.8791 14.5345 21.1939 14.5345 21.5822C14.5345 22.14 14.0807 22.5938 13.523 22.5938H10.477C9.91927 22.5938 9.46547 22.14 9.46547 21.5822C9.46547 21.1939 9.15066 20.8791 8.76234 20.8791C8.76112 20.8791 8.75995 20.8793 8.75873 20.8793H2.47913V19.3046Z"
          fill="#2B75B3"
        />
        <path
          d="M11.2706 16.2498H12.7186C13.8367 16.2498 14.7526 15.3704 14.7603 14.2897L14.7714 12.8147C14.9733 11.5493 15.4784 10.3726 16.2735 9.31704C16.976 8.38493 17.3474 7.27333 17.3474 6.1024C17.3474 3.09312 14.8846 0.713508 11.9246 0.75593C9.04286 0.795914 6.69607 3.13948 6.65393 6.02219C6.63611 7.22904 7.01116 8.37368 7.73857 9.33241C8.52635 10.3706 9.0274 11.5391 9.22896 12.8058V14.2755C9.22891 15.3641 10.1448 16.2498 11.2706 16.2498ZM13.354 14.2794C13.3518 14.5905 13.0667 14.8436 12.7185 14.8436H11.2705C10.9202 14.8436 10.6351 14.5887 10.6351 14.2755V13.4373H13.3603L13.354 14.2794ZM8.05994 6.04282C8.09083 3.93223 9.83322 2.19134 11.9441 2.16204C14.1388 2.12955 15.9411 3.89834 15.9411 6.1024C15.9411 6.96546 15.6677 7.78432 15.1503 8.47076C14.3337 9.55488 13.7766 10.7501 13.4898 12.0311H12.7035V7.95443C12.7035 7.56611 12.3887 7.2513 12.0004 7.2513C11.6121 7.2513 11.2973 7.56611 11.2973 7.95443V12.0311H10.5128C10.2274 10.7459 9.67244 9.55464 8.85883 8.48243C8.3231 7.7763 8.04686 6.93274 8.05994 6.04282Z"
          fill="#2B75B3"
        />
        <path
          d="M5.29246 5.40796C5.29246 5.01965 4.97765 4.70483 4.58934 4.70483H2.24512C1.8568 4.70483 1.54199 5.01965 1.54199 5.40796C1.54199 5.79627 1.8568 6.11108 2.24512 6.11108H4.58934C4.9777 6.11108 5.29246 5.79627 5.29246 5.40796Z"
          fill="#2B75B3"
        />
        <path
          d="M18.7477 9.52877L20.7675 10.7187C21.1023 10.9159 21.5333 10.8041 21.7302 10.4698C21.9273 10.1352 21.8159 9.70422 21.4813 9.50707L19.4615 8.31715C19.1269 8.12008 18.6959 8.23141 18.4988 8.56605C18.3017 8.90064 18.4131 9.33166 18.7477 9.52877Z"
          fill="#2B75B3"
        />
        <path
          d="M3.233 10.7187L5.2528 9.52877C5.58739 9.33166 5.69882 8.90064 5.50171 8.56605C5.30455 8.23141 4.87353 8.12008 4.53899 8.31715L2.51919 9.50706C2.18459 9.70417 2.07317 10.1352 2.27028 10.4698C2.46744 10.8044 2.89841 10.9157 3.233 10.7187Z"
          fill="#2B75B3"
        />
        <path
          d="M19.4615 2.49897L21.4813 1.30904C21.8159 1.11193 21.9273 0.680919 21.7302 0.346325C21.5331 0.0116846 21.102 -0.0996436 20.7675 0.0974189L18.7477 1.28734C18.4131 1.48445 18.3017 1.91547 18.4988 2.25006C18.696 2.58475 19.1269 2.69603 19.4615 2.49897Z"
          fill="#2B75B3"
        />
        <path
          d="M5.2528 1.28734L3.233 0.097419C2.89841 -0.0996436 2.46748 0.0116846 2.27028 0.346325C2.07317 0.680919 2.18459 1.11189 2.51919 1.30904L4.53899 2.49897C4.87377 2.69617 5.30464 2.58451 5.50171 2.25006C5.69877 1.91547 5.58735 1.48445 5.2528 1.28734Z"
          fill="#2B75B3"
        />
        <path
          d="M22.4585 5.40796C22.4585 5.01965 22.1437 4.70483 21.7554 4.70483H19.4111C19.0228 4.70483 18.708 5.01965 18.708 5.40796C18.708 5.79627 19.0228 6.11108 19.4111 6.11108H21.7554C22.1437 6.11108 22.4585 5.79627 22.4585 5.40796Z"
          fill="#2B75B3"
        />
      </g>
      <defs>
        <clipPath id="clip0_19941_17550">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default BulbIcon;
