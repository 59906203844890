import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { ReactComponent as FallbackIcon } from './index.svg';
import theme from '@/theme';
import ClearCacheButton from '../clear-cache-button';

const Fallback: React.FC = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      paddingY={7}
      paddingX={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      boxSizing="border-box"
      style={{ backgroundColor: theme.palette.common.white }}
      className="fallback"
    >
      <Box marginBottom={3}>
        <FallbackIcon />
      </Box>
      <Box marginBottom={1}>
        <Typography variant="subtitle1">
          {t('fallback.message.title')}
        </Typography>
      </Box>
      <Box marginBottom={5} marginX={8}>
        <Typography align="center" variant="body2">
          {t('fallback.message.info')}
        </Typography>
      </Box>
      <Box marginBottom={5}>
        <ClearCacheButton />
      </Box>
    </Box>
  );
};

export default Fallback;
