import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  successCount: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  successIcon: {
    color: theme.palette.success.main,
    height: theme.spacing(5),
    width: theme.spacing(5),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  errorCount: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  errorIcon: {
    color: theme.palette.error.main,
    height: theme.spacing(5),
    width: theme.spacing(5),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));
