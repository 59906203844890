import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: 18,
    width: 18,
  },
});

const CheckCircleIcon: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 18 18"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99984 0.666656C4.39984 0.666656 0.666504 4.39999 0.666504 8.99999C0.666504 13.6 4.39984 17.3333 8.99984 17.3333C13.5998 17.3333 17.3332 13.6 17.3332 8.99999C17.3332 4.39999 13.5998 0.666656 8.99984 0.666656ZM8.99984 15.6667C5.32484 15.6667 2.33317 12.675 2.33317 8.99999C2.33317 5.32499 5.32484 2.33332 8.99984 2.33332C12.6748 2.33332 15.6665 5.32499 15.6665 8.99999C15.6665 12.675 12.6748 15.6667 8.99984 15.6667ZM7.33317 10.8083L12.8248 5.31666L13.9998 6.49999L7.33317 13.1667L3.99984 9.83333L5.17484 8.65833L7.33317 10.8083Z"
        fill="#3498DB"
      />
    </SvgIcon>
  );
};

export default CheckCircleIcon;
