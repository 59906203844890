import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Paper from '@material-ui/core/Paper';

import DuelOutlinedIcon from '@/components/icons/duel-outlined-icon';

import HomeIcon from '@material-ui/icons/HomeOutlined';
import NotificationsIcon from '@material-ui/icons/NotificationsNoneOutlined';

import { makeStyles } from '@material-ui/styles';
import { Badge } from '@material-ui/core';
import BiblioIcon from '@/components/icons/biblio';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

type BottomMenuProps = {
  notifications: number;
};

const BottomMenu: React.FC<BottomMenuProps> = ({ children, notifications }) => {
  const { pathname } = useLocation();
  const classes = useStyles();

  return (
    <Paper elevation={4} className={classes.root}>
      <BottomNavigation value={pathname}>
        <BottomNavigationAction
          id="home-nav-icon"
          to="/home"
          value="/home"
          icon={<HomeIcon />}
          component={RouterLink}
        />
        <BottomNavigationAction
          id="biblio-nav-icon"
          to="/biblio/"
          value="/biblio/"
          icon={<BiblioIcon />}
          component={RouterLink}
        />
        <BottomNavigationAction
          id="duels-nav-icon"
          to="/duels"
          value="/duels"
          icon={<DuelOutlinedIcon />}
          component={RouterLink}
        />
        <BottomNavigationAction
          id="notifications-nav-icon"
          to="/notifications"
          value="/notifications"
          icon={
            <Badge badgeContent={notifications} variant="dot" color="error">
              <NotificationsIcon />
            </Badge>
          }
          component={RouterLink}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomMenu;
