import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

import LeftMenu from './left-menu';
import BottomMenu from './bottom-menu';

import { useStyles } from './styles';
import { useUserNotificationsQuery } from '@/graphql';
import Offline from '../offline';
import { useHistory } from 'react-router-dom';

interface MainLayoutProps {
  title: string;
  toolbar?: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  title,
  toolbar,
  children,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const { data } = useUserNotificationsQuery();

  const count = (data?.notifications || []).filter(
    (it) => !JSON.parse(it?.data)?.hidden && !it?.isRead
  ).length;

  const params = new URLSearchParams(history.location.search);
  const leftMenuParam = params.get('leftMenu') || '';
  const [open, setOpen] = React.useState(
    leftMenuParam === 'true' ? true : false
  );

  const handleDrawerOpen = () => {
    setOpen(true);
    history.push({
      search: '?leftMenu=true',
    });
  };

  const handleDrawerClose = () => {
    setOpen(false);
    history.push({
      search: '',
    });
  };

  return (
    <div>
      <AppBar elevation={0} className={classes.top}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
          >
            <MenuIcon id="burger-menu-icon" />
          </IconButton>
          <Typography style={{ color: '#fff' }} variant="h6">
            {title}
          </Typography>
          <div className={classes.toolbar}>{toolbar}</div>
        </Toolbar>
      </AppBar>
      <LeftMenu
        open={open}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
      />
      <main className={classes.main}>
        <Offline>{children}</Offline>
      </main>
      <BottomMenu notifications={count} />
    </div>
  );
};

export default MainLayout;
