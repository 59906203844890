import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useApolloClient } from '@apollo/client';
import { Box } from '@material-ui/core';
import { AppTab, AppTabs, TabPanel } from '@/components/tabs';
import EmptyTabs from '../empty-tabs';
import MainLayout from '../../components/main-layout';
import QuizTimeCard from '../card';
import Loading from '@/components/loading';
import { useTabsNav } from '@/hooks/useTabsNav';
import {
  useQuizSessionsAnnouncedLazyQuery,
  useQuizSessionsFinishedLazyQuery,
  useQuizSessionsOngoingLazyQuery,
} from '@/graphql';
import { TabNavIdsEnum } from '@/type';
import { useStyles } from './styles';

const QuizTimeList: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const client = useApolloClient();

  const { value, handleChange } = useTabsNav(TabNavIdsEnum.QUIZTIMES);

  const [
    getQuizSessionsOngoing,
    { loading: loadingQuizSessionsOngoing, data: dataQuizSessionsOngoing },
  ] = useQuizSessionsOngoingLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [
    getQuizSessionsAnnounced,
    { loading: loadingQuizSessionsAnnounced, data: dataQuizSessionsAnnounced },
  ] = useQuizSessionsAnnouncedLazyQuery({ fetchPolicy: 'cache-and-network' });

  const [
    getQuizSessionsFinished,
    { loading: loadingQuizSessionsFinished, data: dataQuizSessionsFinished },
  ] = useQuizSessionsFinishedLazyQuery({ fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    switch (value) {
      case 0:
        getQuizSessionsOngoing();
        break;
      case 1:
        getQuizSessionsAnnounced();
        break;
      case 2:
        getQuizSessionsFinished();
        break;
    }
  }, [
    value,
    getQuizSessionsAnnounced,
    getQuizSessionsFinished,
    getQuizSessionsOngoing,
  ]);

  const cardLoading = useMemo(() => <Loading />, []);

  const firstQuizSessionToFinishTime = useMemo(() => {
    if (
      dataQuizSessionsAnnounced?.announcedQuizSessions &&
      dataQuizSessionsAnnounced?.announcedQuizSessions?.length >= 2
    ) {
      return dataQuizSessionsAnnounced?.announcedQuizSessions?.reduce(
        (prevQuiz, currentQuiz) =>
          prevQuiz?.finishDateTime < currentQuiz?.finishDateTime
            ? prevQuiz
            : currentQuiz
      )?.finishDateTime;
    } else if (dataQuizSessionsAnnounced?.announcedQuizSessions)
      return dataQuizSessionsAnnounced?.announcedQuizSessions[0]
        ?.finishDateTime;
    else return ' ';
  }, [dataQuizSessionsAnnounced]);

  useEffect(() => {
    const minuteInterval = setInterval(() => {
      const currentTime = DateTime.local().toString();
      if (currentTime >= firstQuizSessionToFinishTime) {
        getQuizSessionsAnnounced();
        getQuizSessionsFinished();
      }
    }, 60000);
    return () => clearInterval(minuteInterval);
  }, [
    firstQuizSessionToFinishTime,
    client,
    getQuizSessionsAnnounced,
    getQuizSessionsFinished,
  ]);

  return (
    <MainLayout title={t('quiztime.title')}>
      <div className={classes.root}>
        <AppTabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <AppTab
            data-cy-quiztime-caption-active
            label={t('quiztime.tabs.active.caption')}
          />
          <AppTab
            data-cy-quiztime-caption-pending
            label={t('quiztime.tabs.pending.caption')}
          />
          <AppTab
            data-cy-quiztime-caption-finished
            label={t('quiztime.tabs.finished.caption')}
          />
        </AppTabs>
        <TabPanel value={value} index={0}>
          {loadingQuizSessionsOngoing ? (
            cardLoading
          ) : (
            <>
              {dataQuizSessionsOngoing?.ongoingQuizSessions?.map(
                (it) =>
                  it && (
                    <QuizTimeCard
                      key={it.id}
                      id={it.id}
                      link={`/quiztime/${it.id}/`}
                      title={it.quizTime.title}
                      organizerName={it?.quizTime?.organizer?.name}
                      startDate={it?.startDate}
                      startDateTime={it?.startDateTime}
                      finishDateTime={it?.finishDateTime}
                      duration={it?.quizTimeDuration}
                      classRoomImage={it.quizTime?.quizTimeImage}
                      isForPremiumOnly={it.isForPremiumOnly}
                      quizStatus={it?.status}
                      userStatus={it?.userQuizSessionSetting?.userStatus}
                      quizSessionLabelStatus={
                        it?.userQuizSessionSetting?.quizSessionLabelStatus
                      }
                    />
                  )
              )}
              {!dataQuizSessionsOngoing?.ongoingQuizSessions?.length && (
                <Box className={classes.emptyWrap}>
                  <EmptyTabs variant="Laufend" />
                </Box>
              )}
            </>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {loadingQuizSessionsAnnounced
            ? cardLoading
            : dataQuizSessionsAnnounced?.announcedQuizSessions?.map(
                (it) =>
                  it && (
                    <QuizTimeCard
                      key={it.id}
                      id={it.id}
                      link={`/quiztime-details/${it.id}/`}
                      title={it.quizTime.title}
                      organizerName={it?.quizTime?.organizer?.name}
                      startDate={it?.startDate}
                      startDateTime={it?.startDateTime}
                      finishDateTime={it?.finishDateTime}
                      duration={it?.quizTimeDuration}
                      classRoomImage={it.quizTime?.quizTimeImage}
                      isForPremiumOnly={it.isForPremiumOnly}
                    />
                  )
              )}
          {!dataQuizSessionsAnnounced?.announcedQuizSessions?.length && (
            <Box className={classes.emptyWrap}>
              <EmptyTabs variant="Anstehend" />
            </Box>
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {loadingQuizSessionsFinished ? (
            cardLoading
          ) : (
            <>
              {dataQuizSessionsFinished?.finishedQuizSessions?.map(
                (it) =>
                  it && (
                    <QuizTimeCard
                      key={it.quizSession?.id}
                      id={it.quizSession?.id}
                      link={`/quiztime/${it.quizSession?.id}/`}
                      title={it.quizSession?.quizTime.title}
                      organizerName={it.quizSession?.quizTime?.organizer?.name}
                      startDate={it.quizSession?.startDate}
                      startDateTime={it.quizSession?.startDateTime}
                      finishDateTime={it.quizSession?.finishDateTime}
                      duration={it.quizSession?.quizTimeDuration}
                      classRoomImage={it.quizSession?.quizTime?.quizTimeImage}
                      isForPremiumOnly={it.quizSession?.isForPremiumOnly}
                      userRank={it.userRank}
                      quizStatus={it.quizSession?.status}
                      userStatus={
                        it?.quizSession?.userQuizSessionSetting?.userStatus
                      }
                      quizSessionLabelStatus={
                        it?.quizSession?.userQuizSessionSetting
                          ?.quizSessionLabelStatus
                      }
                    />
                  )
              )}
              {!dataQuizSessionsFinished?.finishedQuizSessions?.length && (
                <Box className={classes.emptyWrap}>
                  <EmptyTabs variant="Fertig" />
                </Box>
              )}
            </>
          )}
        </TabPanel>
        {children}
      </div>
    </MainLayout>
  );
};

export default QuizTimeList;
