import React from 'react';
import { useTranslation } from 'react-i18next';
import StackLayout from '../../components/stack-layout';
import CompletedDuelsList from '../completed-duels-list';

const QuizCompletedDuels: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StackLayout title={t('quiztime.completed-duels-title')}>
      <CompletedDuelsList />
    </StackLayout>
  );
};

export default QuizCompletedDuels;
