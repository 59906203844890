import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    gridRowGap: theme.spacing(2),
    '&>*': {
      marginBottom: 8,
    },
  },
  card: {
    height: theme.spacing(66),
  },
  dialogConent: {
    padding: '60px 24px 24px',
  },
}));
