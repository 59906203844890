import React from 'react';
import { FieldError } from 'react-hook-form';

import {
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from '@material-ui/core';

import Error from '../error';

interface TextFieldProps extends Omit<MUITextFieldProps, 'error'> {
  errors?: FieldError;
}

const TextField: React.FC<TextFieldProps> = ({ errors = null, ...rest }) => (
  <div>
    <MUITextField error={!!errors} {...rest} />
    <Error errors={errors} />
  </div>
);

export default TextField;
