import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { useChangeUserCardMutation, UserSubscriptionDocument } from '@/graphql';

type DialogRemovePaymentMethodProps = {
  isOpened: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
};

const DialogRemovePaymentMethod: React.FC<DialogRemovePaymentMethodProps> = ({
  onClose,
  isOpened,
}) => {
  const history = useHistory();

  const { t } = useTranslation();
  const [changeCard, { loading }] = useChangeUserCardMutation();

  const handleRemoveCard = useCallback(async () => {
    try {
      await changeCard({
        refetchQueries: [{ query: UserSubscriptionDocument }],
      });
      history.push('/my-profile');
    } catch (error) {
      console.error('useChangeUserCardMutation', error);
    } finally {
      onClose(false);
    }
  }, [changeCard, onClose, history]);

  return (
    <Dialog open={isOpened} onClose={() => onClose(false)}>
      <DialogTitle>{t('payment.remove-card')}</DialogTitle>
      <DialogContent>
        <DialogContentText variant="body2">
          {t('payment.remove-card-content')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box component="span" style={{ color: '#647485' }}>
          <Button color="inherit" onClick={() => onClose(false)}>
            {t('common.abort')}
          </Button>
        </Box>
        <Button onClick={handleRemoveCard} disabled={loading} color="secondary">
          {t('common.remove')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogRemovePaymentMethod;
