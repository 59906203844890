import { useCallback, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { sortBy } from 'lodash';
import { useHistory } from 'react-router-dom';

import { useUser } from '@/contexts/user-context';
import {
  DuelStatusesEnum,
  UserDuelDocument,
  UserDuelQuery,
  UserDuelQueryVariables,
  useReadNotificationMutation,
  useUserNotificationsQuery,
} from '@/graphql';

export const useQuizDuel = ({ sessionId = '' }) => {
  const history = useHistory();
  const { user } = useUser();
  const cache = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [readNotification] = useReadNotificationMutation();
  const { data: notificationsData } = useUserNotificationsQuery();

  const openDuel = useCallback(
    async (duelId: string) => {
      setLoading(true);
      const { data } = await cache.query<UserDuelQuery, UserDuelQueryVariables>(
        {
          query: UserDuelDocument,
          variables: { duelId },
          fetchPolicy: 'network-only',
        }
      );

      if (!data) return;

      const messageId = notificationsData?.notifications?.find((it) => {
        const notifData = JSON.parse(it?.data);
        return !it?.isRead && notifData?.id === duelId;
      })?.id;

      if (messageId) {
        readNotification({
          variables: { id: messageId },
        });
      }

      const duel = data.duelData;
      const rounds = data?.duelData?.rounds || [];
      const round = sortBy(rounds, ['roundNumber'])[rounds.length - 1];

      if (!round) return;

      const firstQuestion = sortBy(
        round.userQuestions.filter(
          (it) => it.user?.id === user?.id && !it.userAnswer
        ),
        ['questionNumber']
      )[0];

      const filedQuestions = sortBy(
        round.userQuestions.filter(
          (it) => it.user?.id === user?.id && !!it.userAnswer
        ),
        ['questionNumber']
      );

      const userQuestions = sortBy(
        round.userQuestions.filter((it) => it.user?.id === user?.id),
        ['questionNumber']
      );

      const isAllFiled =
        round.userQuestions.filter((it) => !it.userAnswer).length === 0;

      const userShouldCreateRound =
        isAllFiled &&
        round.roundNumber % 2 === (duel?.userScore?.initiator ? 1 : 0);

      if (
        filedQuestions.length === userQuestions.length ||
        data?.duelData?.status === DuelStatusesEnum.Ignored
      )
        return history.push(
          `/quiz-duel/${duelId}/overview/session/${sessionId}`
        );

      if (userShouldCreateRound)
        return history.push(
          `/quiz-duel/${duelId}/create-round/session/${sessionId}`
        );

      if (!firstQuestion) return;

      setLoading(false);
      return history.push(
        `/quiz-duel/${duelId}/round/${round.id}/question/${firstQuestion.id}/session/${sessionId}`
      );
    },
    [cache, history, user, readNotification, notificationsData, sessionId]
  );

  return { openDuel, loading };
};
