import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Box, Paper, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import CardGiftcardOutlined from '@material-ui/icons/CardGiftcardOutlined';

interface PrizeItemProps {
  title: string | undefined;
  rules?: string | undefined;
  description?: string | undefined | null;
  prizeImage?: string | null;
  isDefaultImage?: boolean | null;
}

const PrizeItem: React.FC<PrizeItemProps> = ({
  title,
  description,
  prizeImage,
  isDefaultImage,
  rules,
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.card}>
      <Box>
        <Box display="flex">
          {prizeImage && (
            <LazyLoadImage
              wrapperClassName={classes.cardPicture}
              useIntersectionObserver
              src={prizeImage || ''}
              alt={title}
            />
          )}
          {!prizeImage && isDefaultImage && (
            <Box className={classes.cardPicture}>
              <CardGiftcardOutlined
                style={{ fontSize: '24px', color: '#879BB2' }}
              />{' '}
            </Box>
          )}
          {!isDefaultImage && <Typography> • </Typography>}
          <Box display="flex" flexDirection="column" ml={4}>
            <Typography variant="subtitle1">{title}</Typography>
            {description && (
              <Typography variant="body2">{description}</Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box mt={2}>
        <Typography>{rules}</Typography>{' '}
      </Box>
    </Paper>
  );
};

export default PrizeItem;
