import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    fontSize: 'inherit',
    verticalAlign: 'middle',
    height: 72,
    width: 100,
  },
});

const DailyTipper: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 100 72"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M91.5996 25.666H82.058H79.521C77.8774 25.666 76.5206 24.1872 76.5206 22.3812V22.3691C76.5206 20.563 77.8774 19.1085 79.521 19.1085H82.058H85.4776C87.1211 19.1085 88.4228 17.6297 88.4338 15.8236C88.4338 14.0176 87.1211 12.563 85.4776 12.563H73.068H71.8877C71.7332 12.3206 71.5788 12.1873 71.4133 12.0176C59.456 -1.10971 40.0749 -1.13396 28.1176 11.9933C25.4481 14.9266 23.3854 18.2721 21.9073 21.7872H16.1823C14.5387 21.7872 13.204 23.2539 13.204 25.06C13.204 26.866 14.5387 28.3327 16.1823 28.3327H17.5281H25.6136C27.2572 28.3327 28.5919 29.7994 28.5919 31.6054C28.5919 33.4115 27.2572 34.8782 25.6136 34.8782H17.5281H7.57831C5.93473 34.8782 4.60001 36.3448 4.60001 38.1509C4.60001 39.9569 5.93473 41.4236 7.57831 41.4236H17.5281H19.657C21.3006 41.4236 22.6353 42.8903 22.6353 44.6963C22.6353 46.5024 21.3006 47.9691 19.657 47.9691H17.5281H13.7004C12.0568 47.9691 10.7221 49.4357 10.7221 51.2418C10.7221 53.0478 12.0568 54.5145 13.7004 54.5145H24.3561C25.4371 56.3327 26.6946 57.9933 28.1176 59.5569C40.0749 72.6963 59.456 72.7206 71.4023 59.5812C75.1638 55.4478 77.734 50.5145 79.1349 45.3024H82.9846C84.6282 45.3024 85.985 43.8236 85.996 42.0175C85.996 40.2115 84.6282 38.7569 82.9846 38.7569H82.047H73.5533C71.9097 38.7569 70.5529 37.2782 70.5529 35.4721V35.46C70.5529 33.6539 71.9097 32.1994 73.5533 32.1994H82.047H91.5886C93.2322 32.1994 94.589 30.7206 94.6 28.9145C94.6 27.1206 93.2432 25.666 91.5996 25.666Z"
          fill="#E6EEFA"
        />
        <path
          d="M32.0556 65.4545H30.0701C29.8825 65.4545 29.7391 65.6121 29.7391 65.8182C29.7391 66.0242 29.8825 66.1818 30.0701 66.1818H32.0556C32.2431 66.1818 32.3865 66.0242 32.3865 65.8182C32.3865 65.6121 32.2431 65.4545 32.0556 65.4545Z"
          fill="#2C3E50"
        />
        <path
          d="M41.3214 65.4545H34.0411C33.8536 65.4545 33.7102 65.6121 33.7102 65.8182C33.7102 66.0242 33.8536 66.1818 34.0411 66.1818H41.3214C41.5089 66.1818 41.6523 66.0242 41.6523 65.8182C41.6523 65.6121 41.5089 65.4545 41.3214 65.4545Z"
          fill="#2C3E50"
        />
        <path
          d="M35.3648 63.2727H28.0845C27.897 63.2727 27.7536 63.4303 27.7536 63.6363C27.7536 63.8424 27.897 64 28.0845 64H35.3648C35.5523 64 35.6957 63.8424 35.6957 63.6363C35.6957 63.4303 35.5523 63.2727 35.3648 63.2727Z"
          fill="#2C3E50"
        />
        <path
          d="M39.3359 63.2727H38.0122C37.8247 63.2727 37.6813 63.4303 37.6813 63.6363C37.6813 63.8424 37.8247 64 38.0122 64H39.3359C39.5234 64 39.6668 63.8424 39.6668 63.6363C39.6668 63.4303 39.5234 63.2727 39.3359 63.2727Z"
          fill="#2C3E50"
        />
        <path
          d="M17.495 29.0909H16.5022C16.3147 29.0909 16.1713 29.2485 16.1713 29.4545C16.1713 29.6606 16.3147 29.8182 16.5022 29.8182H17.495C17.6825 29.8182 17.8259 29.6606 17.8259 29.4545C17.8259 29.2485 17.6825 29.0909 17.495 29.0909Z"
          fill="#2C3E50"
        />
        <path
          d="M22.4588 29.0909H21.466C21.2785 29.0909 21.1351 29.2485 21.1351 29.4545C21.1351 29.6606 21.2785 29.8182 21.466 29.8182H22.4588C22.6463 29.8182 22.7897 29.6606 22.7897 29.4545C22.7897 29.2485 22.6463 29.0909 22.4588 29.0909Z"
          fill="#2C3E50"
        />
        <path
          d="M17.881 30.7757L17.175 31.5515C17.0427 31.6969 17.0427 31.9272 17.175 32.0606C17.2412 32.1333 17.3295 32.1697 17.4067 32.1697C17.4839 32.1697 17.5721 32.1333 17.6383 32.0606L18.3443 31.2848C18.4767 31.1394 18.4767 30.9091 18.3443 30.7757C18.2119 30.6424 18.0023 30.6424 17.881 30.7757Z"
          fill="#2C3E50"
        />
        <path
          d="M21.3888 26.9212L20.6829 27.697C20.5505 27.8424 20.5505 28.0727 20.6829 28.2061C20.749 28.2788 20.8373 28.3152 20.9145 28.3152C21.0028 28.3152 21.08 28.2788 21.1462 28.2061L21.8521 27.4303C21.9845 27.2849 21.9845 27.0546 21.8521 26.9212C21.7308 26.7879 21.5212 26.7879 21.3888 26.9212Z"
          fill="#2C3E50"
        />
        <path
          d="M19.4805 31.2727C19.293 31.2727 19.1496 31.4303 19.1496 31.6363V32.7273C19.1496 32.9333 19.293 33.0909 19.4805 33.0909C19.668 33.0909 19.8114 32.9333 19.8114 32.7273V31.6363C19.8114 31.4303 19.668 31.2727 19.4805 31.2727Z"
          fill="#2C3E50"
        />
        <path
          d="M19.4805 25.8182C19.293 25.8182 19.1496 25.9758 19.1496 26.1818V27.2727C19.1496 27.4788 19.293 27.6364 19.4805 27.6364C19.668 27.6364 19.8114 27.4788 19.8114 27.2727V26.1818C19.8114 25.9758 19.668 25.8182 19.4805 25.8182Z"
          fill="#2C3E50"
        />
        <path
          d="M21.1572 30.7758C21.0248 30.6303 20.8152 30.6303 20.6939 30.7758C20.5615 30.9212 20.5615 31.1515 20.6939 31.2849L21.3998 32.0606C21.466 32.1334 21.5543 32.1697 21.6315 32.1697C21.7197 32.1697 21.797 32.1334 21.8631 32.0606C21.9955 31.9152 21.9955 31.6849 21.8631 31.5515L21.1572 30.7758Z"
          fill="#2C3E50"
        />
        <path
          d="M17.6494 26.9212C17.517 26.7758 17.3074 26.7758 17.1861 26.9212C17.0537 27.0667 17.0537 27.297 17.1861 27.4303L17.8921 28.2061C17.9583 28.2788 18.0465 28.3152 18.1237 28.3152C18.212 28.3152 18.2892 28.2788 18.3554 28.2061C18.4877 28.0606 18.4877 27.8303 18.3554 27.697L17.6494 26.9212Z"
          fill="#2C3E50"
        />
        <path
          d="M78.3847 20.3636H77.392C77.2045 20.3636 77.0611 20.5212 77.0611 20.7272C77.0611 20.9333 77.2045 21.0909 77.392 21.0909H78.3847C78.5723 21.0909 78.7157 20.9333 78.7157 20.7272C78.7157 20.5212 78.5723 20.3636 78.3847 20.3636Z"
          fill="#2C3E50"
        />
        <path
          d="M83.3486 20.3636H82.3559C82.1683 20.3636 82.0249 20.5212 82.0249 20.7272C82.0249 20.9333 82.1683 21.0909 82.3559 21.0909H83.3486C83.5361 21.0909 83.6796 20.9333 83.6796 20.7272C83.6796 20.5212 83.5361 20.3636 83.3486 20.3636Z"
          fill="#2C3E50"
        />
        <path
          d="M78.7708 22.0484L78.0649 22.8242C77.9325 22.9696 77.9325 23.1999 78.0649 23.3333C78.131 23.406 78.2193 23.4424 78.2965 23.4424C78.3847 23.4424 78.462 23.406 78.5282 23.3333L79.2341 22.5575C79.3665 22.4121 79.3665 22.1818 79.2341 22.0484C79.1018 21.9151 78.8922 21.9151 78.7708 22.0484Z"
          fill="#2C3E50"
        />
        <path
          d="M82.2786 18.1939L81.5726 18.9697C81.4402 19.1151 81.4402 19.3454 81.5726 19.4788C81.6388 19.5515 81.727 19.5879 81.8043 19.5879C81.8925 19.5879 81.9697 19.5515 82.0359 19.4788L82.7419 18.703C82.8743 18.5576 82.8743 18.3273 82.7419 18.1939C82.6205 18.0606 82.411 18.0606 82.2786 18.1939Z"
          fill="#2C3E50"
        />
        <path
          d="M80.3703 22.5454C80.1828 22.5454 80.0394 22.703 80.0394 22.909V24C80.0394 24.206 80.1828 24.3636 80.3703 24.3636C80.5578 24.3636 80.7012 24.206 80.7012 24V22.909C80.7012 22.703 80.5578 22.5454 80.3703 22.5454Z"
          fill="#2C3E50"
        />
        <path
          d="M80.3703 17.0909C80.1828 17.0909 80.0394 17.2485 80.0394 17.4545V18.5454C80.0394 18.7515 80.1828 18.9091 80.3703 18.9091C80.5578 18.9091 80.7012 18.7515 80.7012 18.5454V17.4545C80.7012 17.2485 80.5578 17.0909 80.3703 17.0909Z"
          fill="#2C3E50"
        />
        <path
          d="M82.047 22.0485C81.9146 21.903 81.7051 21.903 81.5837 22.0485C81.4513 22.1939 81.4513 22.4242 81.5837 22.5576L82.2897 23.3333C82.3559 23.4061 82.4441 23.4424 82.5213 23.4424C82.5985 23.4424 82.6868 23.4061 82.753 23.3333C82.8853 23.1879 82.8853 22.9576 82.753 22.8242L82.047 22.0485Z"
          fill="#2C3E50"
        />
        <path
          d="M78.5392 18.1939C78.4068 18.0485 78.1972 18.0485 78.0759 18.1939C77.9435 18.3394 77.9435 18.5697 78.0759 18.703L78.7819 19.4788C78.8481 19.5515 78.9363 19.5879 79.0135 19.5879C79.1018 19.5879 79.179 19.5515 79.2452 19.4788C79.3775 19.3333 79.3775 19.103 79.2452 18.9697L78.5392 18.1939Z"
          fill="#2C3E50"
        />
        <path
          d="M78.5502 50.3516L77.8111 49.9031C77.4802 49.697 77.2044 49.4061 77.0169 49.0304L76.6088 48.2182C76.5536 48.097 76.4433 48.0243 76.322 48.0243C76.2006 48.0243 76.0903 48.097 76.0352 48.2182L75.627 49.0304C75.4395 49.394 75.1748 49.697 74.8328 49.9031L74.0938 50.3516C73.9834 50.4122 73.9173 50.5334 73.9173 50.6667C73.9173 50.8 73.9834 50.9213 74.0938 50.9819L74.8328 51.4303C75.1637 51.6364 75.4395 51.9273 75.627 52.3031L76.0352 53.1152C76.0903 53.2364 76.2006 53.3091 76.322 53.3091C76.4433 53.3091 76.5536 53.2364 76.6088 53.1152L77.0169 52.3031C77.2044 51.9394 77.4692 51.6364 77.8111 51.4303L78.5502 50.9819C78.6605 50.9213 78.7267 50.8 78.7267 50.6667C78.7157 50.5334 78.6495 50.4122 78.5502 50.3516ZM77.4912 50.7879C77.05 51.0546 76.686 51.4546 76.4323 51.9516L76.322 52.1819L76.2117 51.9516C75.969 51.4667 75.605 51.0546 75.1527 50.7879L74.9431 50.6667L75.1527 50.5455C75.5939 50.2788 75.958 49.8788 76.2117 49.3819L76.322 49.1516L76.4323 49.3819C76.675 49.8667 77.039 50.2667 77.4912 50.5455L77.7008 50.6667L77.4912 50.7879Z"
          fill="#2C3E50"
        />
        <path
          d="M22.6573 54.0243L21.9183 53.5758C21.5873 53.3697 21.3116 53.0788 21.1241 52.7031L20.7159 51.891C20.6608 51.7697 20.5505 51.697 20.4291 51.697C20.3078 51.697 20.1975 51.7697 20.1423 51.891L19.7342 52.7031C19.5467 53.0667 19.2819 53.3697 18.94 53.5758L18.2009 54.0243C18.0906 54.0849 18.0244 54.2061 18.0244 54.3394C18.0244 54.4728 18.0906 54.594 18.2009 54.6546L18.94 55.1031C19.2709 55.3091 19.5467 55.6 19.7342 55.9758L20.1423 56.7879C20.1975 56.9091 20.3078 56.9819 20.4291 56.9819C20.5505 56.9819 20.6608 56.9091 20.7159 56.7879L21.1241 55.9758C21.3116 55.6122 21.5763 55.3091 21.9183 55.1031L22.6573 54.6546C22.7676 54.594 22.8338 54.4728 22.8338 54.3394C22.8228 54.2061 22.7566 54.0849 22.6573 54.0243ZM21.5984 54.4728C21.1572 54.7394 20.7931 55.1394 20.5394 55.6364L20.4291 55.8667L20.3188 55.6364C20.0761 55.1516 19.7121 54.7394 19.2599 54.4728L19.0503 54.3516L19.2599 54.2304C19.7011 53.9637 20.0651 53.5637 20.3188 53.0667L20.4291 52.8364L20.5394 53.0667C20.7821 53.5516 21.1461 53.9637 21.5984 54.2304L21.808 54.3516L21.5984 54.4728Z"
          fill="#2C3E50"
        />
        <path
          d="M51.9109 69.0909H51.2491C51.0616 69.0909 50.9182 69.2485 50.9182 69.4546C50.9182 69.6606 51.0616 69.8182 51.2491 69.8182H51.9109C52.0985 69.8182 52.2419 69.6606 52.2419 69.4546C52.2419 69.2485 52.0985 69.0909 51.9109 69.0909Z"
          fill="#2C3E50"
        />
        <path
          d="M55.2202 69.0909H54.5583C54.3708 69.0909 54.2274 69.2485 54.2274 69.4546C54.2274 69.6606 54.3708 69.8182 54.5583 69.8182H55.2202C55.4077 69.8182 55.5511 69.6606 55.5511 69.4546C55.5511 69.2485 55.4077 69.0909 55.2202 69.0909Z"
          fill="#2C3E50"
        />
        <path
          d="M53.2346 70.5455C53.0471 70.5455 52.9037 70.703 52.9037 70.9091V71.6364C52.9037 71.8424 53.0471 72 53.2346 72C53.4222 72 53.5656 71.8424 53.5656 71.6364V70.9091C53.5656 70.703 53.4222 70.5455 53.2346 70.5455Z"
          fill="#2C3E50"
        />
        <path
          d="M53.2346 66.9091C53.0471 66.9091 52.9037 67.0667 52.9037 67.2728V68C52.9037 68.2061 53.0471 68.3637 53.2346 68.3637C53.4222 68.3637 53.5656 68.2061 53.5656 68V67.2728C53.5656 67.0667 53.4222 66.9091 53.2346 66.9091Z"
          fill="#2C3E50"
        />
        <path
          d="M50.9177 2.18182H50.2558C50.0683 2.18182 49.9249 2.3394 49.9249 2.54546C49.9249 2.75152 50.0683 2.9091 50.2558 2.9091H50.9177C51.1052 2.9091 51.2486 2.75152 51.2486 2.54546C51.2486 2.3394 51.1052 2.18182 50.9177 2.18182Z"
          fill="#2C3E50"
        />
        <path
          d="M54.2273 2.18182H53.5655C53.378 2.18182 53.2346 2.3394 53.2346 2.54546C53.2346 2.75152 53.378 2.9091 53.5655 2.9091H54.2273C54.4149 2.9091 54.5583 2.75152 54.5583 2.54546C54.5583 2.3394 54.4149 2.18182 54.2273 2.18182Z"
          fill="#2C3E50"
        />
        <path
          d="M52.2418 3.63635C52.0543 3.63635 51.9109 3.79393 51.9109 3.99999V4.72726C51.9109 4.93332 52.0543 5.0909 52.2418 5.0909C52.4293 5.0909 52.5727 4.93332 52.5727 4.72726V3.99999C52.5727 3.79393 52.4293 3.63635 52.2418 3.63635Z"
          fill="#2C3E50"
        />
        <path
          d="M52.2418 0C52.0543 0 51.9109 0.157576 51.9109 0.363636V1.09091C51.9109 1.29697 52.0543 1.45455 52.2418 1.45455C52.4293 1.45455 52.5727 1.29697 52.5727 1.09091V0.363636C52.5727 0.157576 52.4293 0 52.2418 0Z"
          fill="#2C3E50"
        />
        <path
          d="M68.4571 10.5454H64.1551C63.9676 10.5454 63.8242 10.703 63.8242 10.909C63.8242 11.1151 63.9676 11.2727 64.1551 11.2727H68.4571C68.6446 11.2727 68.788 11.1151 68.788 10.909C68.788 10.703 68.6446 10.5454 68.4571 10.5454Z"
          fill="#2C3E50"
        />
        <path
          d="M79.7084 8H66.8025C66.615 8 66.4716 8.15758 66.4716 8.36364C66.4716 8.5697 66.615 8.72727 66.8025 8.72727H79.7084C79.896 8.72727 80.0394 8.5697 80.0394 8.36364C80.0394 8.15758 79.896 8 79.7084 8Z"
          fill="#2C3E50"
        />
        <path
          d="M75.0755 5.45453H73.4209C73.2334 5.45453 73.09 5.6121 73.09 5.81816C73.09 6.02423 73.2334 6.1818 73.4209 6.1818H75.0755C75.2631 6.1818 75.4065 6.02423 75.4065 5.81816C75.4065 5.6121 75.2631 5.45453 75.0755 5.45453Z"
          fill="#2C3E50"
        />
        <path
          d="M70.7735 5.45453H61.5077C61.3202 5.45453 61.1768 5.6121 61.1768 5.81816C61.1768 6.02423 61.3202 6.1818 61.5077 6.1818H70.7735C70.9611 6.1818 71.1045 6.02423 71.1045 5.81816C71.1045 5.6121 70.9611 5.45453 70.7735 5.45453Z"
          fill="#2C3E50"
        />
        <path
          d="M28.0845 12.3636H24.1134C23.9259 12.3636 23.7825 12.5212 23.7825 12.7273C23.7825 12.9333 23.9259 13.0909 24.1134 13.0909H28.0845C28.272 13.0909 28.4154 12.9333 28.4154 12.7273C28.4154 12.5212 28.272 12.3636 28.0845 12.3636Z"
          fill="#2C3E50"
        />
        <path
          d="M35.6957 9.81818H28.4154C28.2279 9.81818 28.0845 9.97575 28.0845 10.1818C28.0845 10.3879 28.2279 10.5455 28.4154 10.5455H35.6957C35.8832 10.5455 36.0266 10.3879 36.0266 10.1818C36.0266 9.97575 35.8832 9.81818 35.6957 9.81818Z"
          fill="#2C3E50"
        />
        <path
          d="M33.0483 7.27271H32.0556C31.868 7.27271 31.7246 7.43028 31.7246 7.63634C31.7246 7.8424 31.868 7.99998 32.0556 7.99998H33.0483C33.2359 7.99998 33.3793 7.8424 33.3793 7.63634C33.3793 7.43028 33.2359 7.27271 33.0483 7.27271Z"
          fill="#2C3E50"
        />
        <path
          d="M30.7319 7.27271H25.4371C25.2496 7.27271 25.1062 7.43028 25.1062 7.63634C25.1062 7.8424 25.2496 7.99998 25.4371 7.99998H30.7319C30.9194 7.99998 31.0628 7.8424 31.0628 7.63634C31.0628 7.43028 30.9194 7.27271 30.7319 7.27271Z"
          fill="#2C3E50"
        />
        <path
          d="M92.6144 40.7272H80.3703C80.1827 40.7272 80.0393 40.8848 80.0393 41.0909C80.0393 41.2969 80.1827 41.4545 80.3703 41.4545H92.6144C92.8019 41.4545 92.9453 41.2969 92.9453 41.0909C92.9453 40.8848 92.8019 40.7272 92.6144 40.7272Z"
          fill="#2C3E50"
        />
        <path
          d="M85.0032 42.9091H81.3631C81.1755 42.9091 81.0321 43.0666 81.0321 43.2727C81.0321 43.4788 81.1755 43.6363 81.3631 43.6363H85.0032C85.1907 43.6363 85.3341 43.4788 85.3341 43.2727C85.3341 43.0666 85.1907 42.9091 85.0032 42.9091Z"
          fill="#2C3E50"
        />
        <path
          d="M88.3125 42.9091H86.6579C86.4703 42.9091 86.3269 43.0666 86.3269 43.2727C86.3269 43.4788 86.4703 43.6363 86.6579 43.6363H88.3125C88.5 43.6363 88.6434 43.4788 88.6434 43.2727C88.6434 43.0666 88.5 42.9091 88.3125 42.9091Z"
          fill="#2C3E50"
        />
        <path
          d="M57.9447 15.2485L44.5424 10.7152C43.1525 10.2424 41.6744 11.1152 41.2442 12.6303L31.868 46.097C31.4378 47.6242 32.232 49.2485 33.6109 49.7212L47.0022 54.2546C48.3921 54.7273 49.8702 53.8545 50.3004 52.3394L59.6766 18.8727C60.1178 17.3455 59.3346 15.7212 57.9447 15.2485Z"
          fill="white"
        />
        <path
          d="M61.0017 16.1699L47.6104 11.6487C46.2205 11.176 44.7424 12.0487 44.3122 13.5638L34.936 47.0305C34.5058 48.5578 35.3 50.182 36.6789 50.6548L50.0813 55.1881C51.4711 55.6608 52.9493 54.7881 53.3795 53.2729L62.7556 19.8063C63.1748 18.279 62.3916 16.6426 61.0017 16.1699Z"
          fill="#879BB2"
        />
        <path
          d="M59.1692 18.0485L45.2925 13.3576C44.4982 13.0909 43.6489 13.5879 43.4062 14.4485L35.7509 41.7818C35.5082 42.6546 35.9604 43.5879 36.7436 43.8546L50.6203 48.5455C51.4145 48.8121 52.2639 48.3152 52.5066 47.4546L60.1619 20.1212C60.4046 19.2485 59.9523 18.3152 59.1692 18.0485Z"
          fill="white"
        />
        <path
          d="M61.0554 15.5394L45.0167 10.1091C43.2738 9.51517 41.4207 10.594 40.8912 12.5091L31.515 45.9758C31.2613 46.897 31.3386 47.8788 31.7577 48.7394C32.1659 49.6 32.8608 50.2303 33.6991 50.5091L43.1304 53.697C43.1966 53.7212 43.2628 53.7333 43.329 53.7333C43.6158 53.7333 43.8695 53.5273 43.9577 53.2243C44.0681 52.8364 43.8695 52.4364 43.5165 52.3152L34.0852 49.1273C33.5778 48.9576 33.1697 48.5818 32.927 48.0606C32.6843 47.5515 32.6292 46.9576 32.7836 46.4121L42.1597 12.9455C42.4796 11.8061 43.5937 11.1515 44.6306 11.5031L60.6694 16.9212C61.7062 17.2728 62.3019 18.497 61.982 19.6364L60.5921 24.6424C60.4818 25.0303 60.6804 25.4303 61.0334 25.5515C61.3864 25.6727 61.7504 25.4546 61.8607 25.0667L63.2506 20.0728C63.769 18.1576 62.7873 16.1212 61.0554 15.5394Z"
          fill="#2C3E50"
        />
        <path
          d="M60.1619 28.3516C59.8089 28.2303 59.4449 28.4485 59.3346 28.8364L52.5948 53.1152C52.4404 53.6728 52.0984 54.1213 51.6241 54.3879C51.1608 54.6667 50.6203 54.7152 50.1239 54.5455L45.9543 53.1394C45.6013 53.0182 45.2373 53.2364 45.127 53.6243C45.0167 54.0122 45.2152 54.4122 45.5682 54.5334L49.7378 55.9394C50.0577 56.0485 50.3776 56.097 50.7086 56.097C51.238 56.097 51.7675 55.9516 52.2529 55.6728C53.036 55.2243 53.6096 54.4607 53.8634 53.5394L60.6032 29.2607C60.7135 28.8728 60.5149 28.4607 60.1619 28.3516Z"
          fill="#2C3E50"
        />
        <path
          d="M60.8789 26C60.5259 25.8788 60.1619 26.097 60.0516 26.4848L59.9082 26.9818C59.7979 27.3697 59.9965 27.7697 60.3494 27.8909C60.4156 27.9151 60.4818 27.9273 60.548 27.9273C60.8348 27.9273 61.0885 27.7212 61.1767 27.4182L61.3201 26.9212C61.4305 26.5212 61.2319 26.1091 60.8789 26Z"
          fill="#2C3E50"
        />
        <path
          d="M45.0939 49.2728L40.9464 47.8667C40.5493 47.7334 40.1301 47.9758 40.0087 48.4122C39.8874 48.8485 40.108 49.3091 40.5051 49.4425L44.6527 50.8485C45.0498 50.9818 45.469 50.7394 45.5903 50.3031C45.7116 49.8667 45.491 49.4061 45.0939 49.2728Z"
          fill="#2C3E50"
        />
        <path
          d="M45.2925 48.5818L41.1449 47.1758C40.7809 47.0546 40.4058 47.0909 40.0639 47.2849C39.733 47.4788 39.4793 47.8061 39.3689 48.2061C39.1373 49.0303 39.5565 49.903 40.3066 50.1455L44.4541 51.5515C44.5975 51.6 44.7299 51.6243 44.8733 51.6243C45.48 51.6243 46.0426 51.1879 46.2301 50.5212C46.3404 50.1212 46.3073 49.7091 46.1308 49.3333C45.9433 48.9697 45.6455 48.703 45.2925 48.5818ZM44.9615 50.097C44.9505 50.1455 44.8954 50.1818 44.8512 50.1697L40.7037 48.7636C40.6595 48.7515 40.6265 48.6909 40.6375 48.6424C40.6485 48.6061 40.6706 48.5939 40.6816 48.5818C40.6926 48.5697 40.7257 48.5576 40.7478 48.5697L44.8954 49.9758C44.9285 49.9879 44.9395 50.0121 44.9505 50.0243C44.9615 50.0364 44.9726 50.0606 44.9615 50.097Z"
          fill="#2C3E50"
        />
        <path
          d="M55.3966 37.1515C55.0436 37.0303 54.6796 37.2485 54.5693 37.6364L51.8778 47.2364C51.7454 47.7213 51.2711 48 50.8189 47.8546L36.9422 43.1516C36.5009 43.0061 36.2472 42.4849 36.3796 41.9758L37.3503 38.3273C37.4496 37.9394 37.251 37.5394 36.898 37.4303C36.5451 37.3212 36.181 37.5394 36.0818 37.9273L35.1111 41.5637C34.7581 42.8243 35.3979 44.1576 36.5451 44.5455L50.4217 49.2364C50.6313 49.3091 50.8519 49.3455 51.0615 49.3455C51.9881 49.3455 52.8485 48.6909 53.1353 47.6606L55.8268 38.0606C55.9371 37.6728 55.7386 37.2606 55.3966 37.1515Z"
          fill="#2C3E50"
        />
        <path
          d="M59.3567 17.3455L45.48 12.6546C44.3328 12.2667 43.1194 12.9697 42.7664 14.2303L37.251 33.9152C37.1407 34.303 37.3393 34.703 37.6923 34.8243C38.0453 34.9455 38.4093 34.7273 38.5196 34.3394L44.035 14.6546C44.1673 14.1697 44.6417 13.8909 45.0939 14.0364L58.9706 18.7273C59.1802 18.8 59.3567 18.9576 59.467 19.1879C59.5773 19.4061 59.5994 19.6606 59.5332 19.8909L55.2312 35.2364C55.1209 35.6243 55.3194 36.0243 55.6724 36.1455C55.7386 36.1697 55.8048 36.1818 55.871 36.1818C56.1578 36.1818 56.4115 35.9758 56.4997 35.6727L60.8017 20.3273C60.9672 19.7212 60.912 19.0788 60.6473 18.5091C60.3605 17.9515 59.9082 17.5394 59.3567 17.3455Z"
          fill="#2C3E50"
        />
        <path
          d="M37.5378 35.2243C37.1848 35.1273 36.8208 35.3697 36.7436 35.7576L36.6664 36.1334C36.5782 36.5212 36.7988 36.9212 37.1518 37.0061C37.2069 37.0182 37.251 37.0303 37.3062 37.0303C37.604 37.0303 37.8798 36.8 37.946 36.4727L38.0232 36.097C38.1114 35.7091 37.8908 35.3212 37.5378 35.2243Z"
          fill="#2C3E50"
        />
        <path
          d="M54.1281 20.4242L39.4572 15.4909C38.9939 15.3333 38.5086 15.6242 38.3652 16.1333L36.887 21.4303C36.7436 21.9394 37.0084 22.4727 37.4717 22.6303L52.1426 27.5636C52.6059 27.7212 53.0912 27.4303 53.2346 26.9212L54.7127 21.6242C54.8451 21.1151 54.5804 20.5697 54.1281 20.4242Z"
          fill="#B6E4FA"
        />
        <path
          d="M53.8855 20.303L52.628 19.8788C52.2198 19.7454 53.378 20.206 53.1905 20.8969L51.6462 26.4242C51.4477 27.1394 50.3997 27.1151 50.7969 27.2485L51.8448 27.6C52.2529 27.7333 52.7824 27.2848 52.9037 26.8485L54.448 21.3212C54.5804 20.8848 54.2936 20.4485 53.8855 20.303Z"
          fill="#3498DB"
        />
        <path
          d="M54.3157 19.7212L38.3983 14.3636C38.0122 14.2303 37.6151 14.2545 37.2731 14.4363C36.9091 14.6303 36.6333 14.9697 36.512 15.406C36.4017 15.7939 36.6002 16.1939 36.9532 16.3151C37.3062 16.4363 37.6702 16.2182 37.7805 15.8303C37.8026 15.7697 37.8247 15.7454 37.8357 15.7454C37.8798 15.7212 37.935 15.7212 38.0122 15.7454L53.9296 21.103C54.0399 21.1394 54.1061 21.2727 54.073 21.3939L52.5949 26.6909C52.5618 26.8121 52.4404 26.8848 52.3301 26.8485L48.2156 25.4545C47.8627 25.3333 47.4986 25.5515 47.3883 25.9394C47.278 26.3272 47.4766 26.7272 47.8296 26.8485L51.944 28.2424C52.0985 28.2909 52.2419 28.3151 52.3963 28.3151C53.0581 28.3151 53.6648 27.8424 53.8634 27.1272L55.3415 21.8303C55.5842 20.9454 55.1319 20 54.3157 19.7212Z"
          fill="#2C3E50"
        />
        <path
          d="M36.8319 16.4606C36.4789 16.3394 36.1149 16.5575 36.0046 16.9454L35.9936 16.9697C35.8833 17.3575 36.0818 17.7575 36.4348 17.8787C36.501 17.903 36.5672 17.9151 36.6333 17.9151C36.9201 17.9151 37.1739 17.7091 37.2621 17.406L37.2731 17.3818C37.3724 16.9818 37.1849 16.5818 36.8319 16.4606Z"
          fill="#2C3E50"
        />
        <path
          d="M46.7375 24.9696L36.4017 21.503C36.3245 21.4787 36.2914 21.4181 36.2804 21.3818C36.2693 21.3454 36.2362 21.2848 36.2583 21.2121L36.865 19.1272C36.9753 18.7393 36.7878 18.3393 36.4348 18.2181C36.0818 18.0969 35.7178 18.303 35.6075 18.6909L35.0008 20.7878C34.8794 21.2242 34.9236 21.6727 35.1111 22.0727C35.2986 22.4727 35.6295 22.7636 36.0156 22.8969L46.3514 26.3636C46.4176 26.3878 46.4838 26.3999 46.55 26.3999C46.8368 26.3999 47.0905 26.1939 47.1787 25.8909C47.278 25.4909 47.0905 25.0787 46.7375 24.9696Z"
          fill="#2C3E50"
        />
        <path
          d="M60.2171 33.7333L44.5865 28.4484C44.1232 28.2909 43.6379 28.5818 43.4945 29.0909L42.0164 34.3878C41.873 34.8969 42.1377 35.4303 42.601 35.5878L52.5066 38.9333L58.7611 40.8727C59.0258 40.7757 59.2464 40.5454 59.3347 40.2424L60.8128 34.9454C60.9341 34.4242 60.6694 33.8909 60.2171 33.7333Z"
          fill="#FE9C9A"
        />
        <path
          d="M60.0958 33.7333L58.8383 33.3091C58.4301 33.1758 59.7318 33.7455 59.5332 34.4364L57.9889 39.9636C57.7903 40.6788 56.599 40.5576 56.9961 40.6909L58.0441 41.0424C58.4522 41.1758 58.8052 40.7273 58.9376 40.2788L60.4819 34.7515C60.6032 34.303 60.4929 33.8667 60.0958 33.7333Z"
          fill="#FF6464"
        />
        <path
          d="M51.4035 37.7939L48.4804 36.9091C48.1274 36.8 47.7633 37.0303 47.6641 37.4182C47.5648 37.806 47.7744 38.206 48.1274 38.3151L51.0505 39.2C51.1057 39.2121 51.1719 39.2242 51.227 39.2242C51.5138 39.2242 51.7786 39.0182 51.8668 38.6909C51.9661 38.303 51.7565 37.903 51.4035 37.7939Z"
          fill="#2C3E50"
        />
        <path
          d="M47.1346 36.3515L47.0684 36.3273C46.7154 36.2061 46.3514 36.4242 46.2411 36.8121C46.1308 37.2 46.3294 37.6 46.6824 37.7212L46.7485 37.7454C46.8147 37.7697 46.8809 37.7818 46.9471 37.7818C47.2339 37.7818 47.4876 37.5757 47.5758 37.2727C47.6862 36.8848 47.4876 36.4727 47.1346 36.3515Z"
          fill="#2C3E50"
        />
        <path
          d="M50.6644 29.7454L43.5165 27.3333C43.1304 27.2 42.7113 27.2485 42.3473 27.4545C41.9832 27.6606 41.7185 28.0242 41.5972 28.4485L40.119 33.7333C39.8653 34.6182 40.3286 35.5757 41.1339 35.8424H41.1449L44.7409 36.9818C44.8071 37.006 44.8623 37.006 44.9284 37.006C45.2152 37.006 45.48 36.8 45.5682 36.4848C45.6675 36.0969 45.469 35.6969 45.116 35.5879L41.5199 34.4485C41.4096 34.4121 41.3434 34.2788 41.3765 34.1575L42.8547 28.8606C42.8767 28.7757 42.9319 28.7394 42.965 28.7272C42.9981 28.7151 43.0532 28.6788 43.1304 28.7151L50.2784 31.1272C50.6314 31.2485 50.9954 31.0303 51.1057 30.6424C51.205 30.2666 51.0174 29.8545 50.6644 29.7454Z"
          fill="#2C3E50"
        />
        <path
          d="M60.4377 33.0424L52.1095 30.2303C51.7565 30.1091 51.3925 30.3273 51.2822 30.7151C51.1719 31.103 51.3704 31.503 51.7234 31.6242L60.0516 34.4363C60.1619 34.4727 60.2281 34.606 60.195 34.7273L58.7169 40.0242C58.6728 40.1939 58.6397 40.2424 58.5073 40.1939C58.4963 40.1818 58.4963 40.1939 58.4742 40.1818L53.1464 38.4848C52.7934 38.3757 52.4294 38.5939 52.3301 38.9818C52.2308 39.3697 52.4294 39.7697 52.7824 39.8788L58.0992 41.5636C58.1764 41.5879 58.3529 41.6485 58.5845 41.6485C58.7831 41.6485 59.0037 41.6121 59.2353 41.4788C59.4891 41.3454 59.82 41.0424 59.9965 40.4363L61.4746 35.1394C61.7062 34.2666 61.2429 33.3212 60.4377 33.0424Z"
          fill="#2C3E50"
        />
        <path
          d="M47.4655 31.1757L44.5645 30.1939C44.3218 30.109 44.0681 30.2666 43.9909 30.5212L43.0974 33.7091C43.0202 33.9757 43.1636 34.2545 43.3952 34.3394L46.2963 35.3212C46.539 35.406 46.7927 35.2484 46.8699 34.9939L47.7634 31.806C47.8406 31.5394 47.6972 31.2606 47.4655 31.1757Z"
          fill="#FF6464"
        />
        <path
          d="M52.1095 32.9454L48.8554 31.8424C48.6458 31.7697 48.4142 31.903 48.348 32.1333L48.3149 32.2666C48.2487 32.497 48.37 32.7515 48.5796 32.8242L51.8337 33.9272C52.0433 34 52.2749 33.8666 52.3411 33.6363L52.3742 33.503C52.4404 33.2606 52.3191 33.0182 52.1095 32.9454Z"
          fill="#FF6464"
        />
        <path
          d="M56.4887 34.4242L53.4221 33.3939C53.2126 33.3212 52.9809 33.4545 52.9147 33.6848L52.8816 33.8182C52.8154 34.0485 52.9368 34.303 53.1464 34.3757L56.2129 35.406C56.4225 35.4788 56.6542 35.3454 56.7203 35.1151L56.7534 34.9818C56.8196 34.7394 56.6983 34.4969 56.4887 34.4242Z"
          fill="#FF6464"
        />
        <path
          d="M50.157 33.8424L48.4693 33.2727C48.2597 33.2 48.0281 33.3333 47.9619 33.5636L47.9288 33.6969C47.8626 33.9272 47.984 34.1818 48.1936 34.2545L49.8813 34.8242C50.0908 34.8969 50.3225 34.7636 50.3887 34.5333L50.4218 34.4C50.488 34.1575 50.3666 33.9151 50.157 33.8424Z"
          fill="#FF6464"
        />
        <path
          d="M57.3491 36.2788L51.4586 34.2909C51.2491 34.2182 51.0174 34.3515 50.9512 34.5818L50.9181 34.7151C50.8519 34.9454 50.9733 35.2 51.1829 35.2727L57.0733 37.2606C57.2829 37.3333 57.5145 37.2 57.5807 36.9697L57.6138 36.8364C57.68 36.5939 57.5587 36.3515 57.3491 36.2788Z"
          fill="#FF6464"
        />
        <path
          d="M51.9771 36.0121L48.0612 34.6909C47.8516 34.6182 47.6199 34.7515 47.5538 34.9818L47.5207 35.1152C47.4545 35.3455 47.5758 35.6 47.7854 35.6727L51.7013 36.9939C51.9109 37.0667 52.1426 36.9333 52.2088 36.703L52.2418 36.5697C52.308 36.3394 52.1867 36.0849 51.9771 36.0121Z"
          fill="#FF6464"
        />
        <path
          d="M54.3487 36.8121L53.2898 36.4606C53.0802 36.3878 52.8485 36.5212 52.7823 36.7515L52.7492 36.8848C52.6831 37.1151 52.8044 37.3697 53.014 37.4424L54.0729 37.7939C54.2825 37.8666 54.5142 37.7333 54.5804 37.503L54.6134 37.3697C54.6796 37.1394 54.5583 36.8848 54.3487 36.8121Z"
          fill="#FF6464"
        />
        <path
          d="M42.5899 18.2667L39.6888 17.2848C39.4462 17.2 39.1925 17.3576 39.1152 17.6121L38.2218 20.8C38.1445 21.0667 38.2879 21.3454 38.5196 21.4303L41.4207 22.4121C41.6634 22.497 41.9171 22.3394 41.9943 22.0848L42.8878 18.897C42.965 18.6303 42.8326 18.3515 42.5899 18.2667Z"
          fill="#45B3EE"
        />
        <path
          d="M46.2522 19.6727L43.9908 18.9333C43.7813 18.8606 43.5496 18.9939 43.4834 19.2242L43.4503 19.3575C43.3841 19.5878 43.5055 19.8424 43.7151 19.9151L45.9764 20.6545C46.186 20.7272 46.4176 20.5939 46.4838 20.3636L46.5169 20.2303C46.5831 19.9878 46.4617 19.7454 46.2522 19.6727Z"
          fill="#45B3EE"
        />
        <path
          d="M50.6203 21.1515L47.5538 20.1212C47.3442 20.0485 47.1125 20.1818 47.0463 20.4121L47.0133 20.5455C46.9471 20.7758 47.0684 21.0303 47.278 21.103L50.3445 22.1333C50.5541 22.2061 50.7858 22.0727 50.852 21.8424L50.885 21.7091C50.9623 21.4667 50.8409 21.2242 50.6203 21.1515Z"
          fill="#45B3EE"
        />
        <path
          d="M45.2815 20.9333L43.5937 20.3636C43.3842 20.2909 43.1525 20.4242 43.0863 20.6545L43.0532 20.7878C42.987 21.0181 43.1084 21.2727 43.318 21.3454L45.0057 21.9151C45.2153 21.9878 45.4469 21.8545 45.5131 21.6242L45.5462 21.4909C45.6124 21.2484 45.491 20.9939 45.2815 20.9333Z"
          fill="#45B3EE"
        />
        <path
          d="M51.4918 22.9939L46.5941 21.3697C46.3845 21.297 46.1529 21.4303 46.0867 21.6606L46.0536 21.7939C45.9874 22.0242 46.1088 22.2788 46.3183 22.3515L51.216 23.9757C51.4256 24.0485 51.6572 23.9151 51.7234 23.6848L51.7565 23.5515C51.8227 23.3212 51.7013 23.0667 51.4918 22.9939Z"
          fill="#45B3EE"
        />
        <path
          d="M47.1125 23.103L43.1966 21.7818C42.987 21.7091 42.7554 21.8424 42.6892 22.0727L42.6561 22.206C42.5899 22.4363 42.7113 22.6909 42.9208 22.7636L46.8368 24.0848C47.0463 24.1576 47.278 24.0242 47.3442 23.7939L47.3773 23.6606C47.4435 23.4303 47.3221 23.1757 47.1125 23.103Z"
          fill="#45B3EE"
        />
        <path
          d="M49.4731 23.903L48.4142 23.5515C48.2046 23.4788 47.973 23.6121 47.9068 23.8424L47.8737 23.9758C47.8075 24.2061 47.9289 24.4606 48.1384 24.5333L49.1974 24.8848C49.407 24.9576 49.6386 24.8242 49.7048 24.5939L49.7379 24.4606C49.8151 24.2303 49.6938 23.9758 49.4731 23.903Z"
          fill="#45B3EE"
        />
        <path
          d="M72.009 57.2364L70.7625 54.6545C70.3764 53.8545 70.1558 52.9697 70.1007 52.0727L69.9683 49.8182C69.869 48.2061 69.4719 46.6303 68.777 45.2L65.4567 38.303C65.4567 38.303 64.3978 36.3273 62.9748 37.1515C62.4122 37.4788 62.2357 38.2909 62.2357 38.2909L61.1437 37.806C60.3495 37.4545 59.4339 37.7091 58.9044 38.4485C58.6838 38.7515 58.4853 38.9818 58.3419 38.9454L57.3491 38.7394C56.8196 38.6303 56.2681 38.7636 55.8269 39.1273L54.8672 39.9151C54.8672 39.9151 52.1977 33.7818 51.569 34.1454C49.6276 35.2727 50.7858 37.0666 50.7858 37.0666L55.5511 46.9576C54.7128 46.5939 52.7824 46.3273 51.933 46.6545C50.8189 47.0788 49.6496 48.0364 50.7968 49.7697C51.4697 50.8 53.1685 50.9091 54.1833 51.3576C55.4629 51.9152 55.4959 52.4 56.4446 54.1212C57.6469 56.3273 59.1471 56.3273 60.3164 56.9818C61.5739 57.6849 63.3388 57.7697 64.0668 59.2849L65.0265 61.2849C65.0265 61.2849 66.4826 63.2606 70.1558 61.1273C73.8511 58.9939 72.009 57.2364 72.009 57.2364Z"
          fill="white"
        />
        <path
          d="M73.1672 57.2897L71.9207 54.7079C71.5347 53.9079 71.3141 53.023 71.2589 52.126L71.1265 49.8715C71.0273 48.2594 70.6301 46.6836 69.9352 45.2533L66.615 38.3564C66.615 38.3564 65.556 36.3806 64.133 37.2048C63.5705 37.5321 63.394 38.3442 63.394 38.3442L62.3019 37.8594C61.5077 37.5079 60.5922 37.7624 60.0627 38.5018C59.8421 38.8048 59.6435 39.0351 59.5001 38.9988L58.5074 38.7927C57.9779 38.6836 57.4263 38.817 56.9851 39.1806L56.0254 39.9685C56.0254 39.9685 53.356 33.8351 52.7272 34.1988C50.7858 35.3261 51.944 37.12 51.944 37.12L56.7093 47.0109C55.871 46.6473 53.9406 46.3806 53.0912 46.7079C51.9771 47.1321 50.8079 48.0897 51.9551 49.823C52.628 50.8533 54.3267 50.9624 55.3415 51.4109C56.6211 51.9685 56.6542 52.4533 57.6028 54.1745C58.8052 56.3806 60.3054 56.3806 61.4746 57.0351C62.7321 57.7382 64.497 57.823 65.2251 59.3382L66.1848 61.3382C66.1848 61.3382 67.6408 63.3139 71.3141 61.1806C75.0094 59.0351 73.1672 57.2897 73.1672 57.2897Z"
          fill="white"
        />
        <path
          d="M51.216 34.3637C51.0064 34.4849 50.8299 34.6182 50.6865 34.7515C52.0543 34.4 52.8155 36.097 52.8155 36.097L57.2278 45.2606C57.4925 45.8061 57.9227 46.2182 58.4411 46.4364L59.2023 46.594L53.8193 35.5152C53.8193 35.5152 52.8927 33.3818 51.216 34.3637Z"
          fill="white"
        />
        <path
          d="M57.0733 38.5697C56.8637 38.691 56.6873 38.8243 56.5439 38.9576C57.9117 38.6061 58.6728 40.3031 58.6728 40.3031L60.3164 43.7213C60.5811 44.2667 61.0113 44.6789 61.5297 44.897L62.2909 45.0546L59.6656 39.7213C59.6766 39.7213 58.75 37.6001 57.0733 38.5697Z"
          fill="white"
        />
        <path
          d="M60.5149 37.3334C60.3053 37.4546 60.1288 37.5879 59.9854 37.7213C61.3533 37.3697 62.1144 39.0667 62.1144 39.0667L63.427 41.7819C63.6918 42.3273 64.122 42.7394 64.6404 42.9576L65.4016 43.1152L63.1182 38.4728C63.1182 38.4849 62.1806 36.3637 60.5149 37.3334Z"
          fill="white"
        />
        <path
          d="M71.7442 54.0848C71.3581 53.2848 71.1706 52.3879 71.1155 51.4788L70.961 49.2364C70.8618 47.6242 70.4426 46.0606 69.7476 44.6424L66.4274 37.7454C66.4274 37.7454 65.4126 35.7454 63.9896 36.5697C63.78 36.6909 63.769 36.7394 63.6476 36.8848C64.8059 36.703 65.7655 38.1333 65.7655 38.1333L69.0858 45.0303C69.7697 46.4606 70.0565 48.1091 70.1448 49.7212L70.1778 52.0364C70.233 52.9333 70.3543 53.8788 70.7404 54.6788L71.9869 57.2606C71.9869 57.2606 72.4281 59.5758 69.2513 61.6485C69.4278 61.5636 69.5822 61.4788 69.7697 61.3697C73.454 59.2242 72.9797 56.6788 72.9797 56.6788L71.7442 54.0848Z"
          fill="white"
        />
        <path
          d="M73.9284 56.1212L72.6819 53.5394C72.3399 52.8242 72.1414 52.0606 72.0973 51.2363L71.9869 49.7697C71.9539 49.3697 71.634 49.0666 71.2699 49.103C70.9059 49.1394 70.6302 49.4909 70.6633 49.8909L70.7736 51.3454C70.8287 52.3515 71.0824 53.3212 71.5126 54.2182L72.6929 56.6788C72.715 57.103 72.6047 58.8848 69.8028 60.5212C66.9679 62.1697 65.7435 61.1272 65.5229 60.8969C65.3354 60.5212 64.6184 59.0181 64.5963 58.9818C63.9676 57.6727 62.7763 57.2485 61.7283 56.8606C61.2981 56.703 60.901 56.5575 60.5591 56.3636C60.2281 56.1818 59.8752 56.0363 59.5442 55.903C58.6177 55.5394 57.7352 55.2 56.963 53.7697C56.7976 53.4788 56.6652 53.2121 56.5439 52.9818C55.9482 51.8303 55.6504 51.2606 54.3818 50.703C54.0399 50.5454 53.6428 50.4363 53.2346 50.3272C52.4735 50.1091 51.6131 49.8666 51.2822 49.3697C51.0836 49.0666 50.863 48.6303 50.9623 48.2909C51.0616 47.9515 51.4807 47.6 52.0985 47.3697C52.7713 47.1151 54.2936 47.2848 55.0216 47.5879C55.3636 47.7333 55.7496 47.5515 55.882 47.1757C56.0144 46.8 55.8489 46.3757 55.507 46.2303C54.6355 45.8545 52.7493 45.5757 51.6683 45.9879C50.9402 46.2666 50.0026 46.8121 49.7048 47.8424C49.4952 48.5818 49.6607 49.3818 50.2122 50.2182C50.8189 51.1515 51.933 51.4666 52.9037 51.7333C53.2677 51.8424 53.6207 51.9394 53.8854 52.0485C54.7238 52.4242 54.8451 52.6424 55.3967 53.7091C55.518 53.9394 55.6614 54.206 55.8269 54.5212C56.8527 56.3878 58.0992 56.8727 59.103 57.2727C59.4119 57.3939 59.7097 57.503 59.9634 57.6485C60.3936 57.8909 60.8679 58.0606 61.3202 58.2303C62.2468 58.5697 63.052 58.8606 63.4271 59.6485C63.4932 59.7818 63.6918 60.1939 64.3867 61.6484C64.4088 61.6848 64.4309 61.7333 64.4529 61.7697C64.497 61.8303 65.2913 62.8727 67.0121 62.8727C67.8945 62.8727 69.0197 62.606 70.4095 61.7939C74.4799 59.4303 74.0056 56.4363 73.9835 56.3151C73.9725 56.2545 73.9504 56.1939 73.9284 56.1212Z"
          fill="#2C3E50"
        />
        <path
          d="M70.7625 46.7151C70.5529 46.8485 70.3544 47.1394 70.4316 47.5879C70.4978 47.9394 70.7736 48.1818 71.0824 48.1818C71.1265 48.1818 71.1707 48.1818 71.2148 48.1697C71.5347 48.0969 71.7553 47.7939 71.7553 47.4424C71.7774 47.2969 71.7443 47.1394 71.6781 46.9939C71.4795 46.6424 71.0824 46.5212 70.7625 46.7151ZM71.4133 47.9757C71.5016 47.9272 71.5678 47.8545 71.6229 47.7697C71.5678 47.8545 71.5016 47.9272 71.4133 47.9757Z"
          fill="#2C3E50"
        />
        <path
          d="M67.376 37.2C67.376 37.1879 67.365 37.1758 67.365 37.1758C66.8907 36.2909 65.567 34.8485 64.0116 35.7576C63.2174 36.2182 62.9417 36.8485 62.8424 37.297C62.6218 38.3273 63.1292 39.2727 63.2726 39.5031L64.839 42.7515C65.0044 43.1031 65.4126 43.2364 65.7325 43.0546C66.0523 42.8606 66.1737 42.4243 66.0082 42.0728L64.4308 38.8C64.4198 38.7758 64.3977 38.7515 64.3867 38.7152C64.3095 38.594 64.0447 38.0606 64.133 37.6364C64.1551 37.5515 64.2102 37.2849 64.6404 37.0424C65.4567 36.5697 66.1627 37.7697 66.2288 37.9031L69.8469 45.4182C69.9683 45.6606 70.1999 45.8061 70.4316 45.8061C70.5308 45.8061 70.6411 45.7818 70.7404 45.7212C71.0603 45.5273 71.1817 45.0909 71.0162 44.7394L67.376 37.2Z"
          fill="#2C3E50"
        />
        <path
          d="M62.2578 36.0121C61.6511 35.7818 61.0003 35.8546 60.3715 36.2182C59.7869 36.5576 59.3898 37.0667 59.2243 37.697C58.9375 38.7879 59.4339 39.8424 59.5222 40.0121L61.7945 44.7273C61.9158 44.9697 62.1475 45.1152 62.3791 45.1152C62.4784 45.1152 62.5887 45.0909 62.688 45.0303C63.0079 44.8364 63.1292 44.4 62.9637 44.0485L60.6914 39.3212C60.6914 39.3091 60.6804 39.3091 60.6804 39.297C60.6142 39.1636 60.3715 38.5697 60.5039 38.097C60.5701 37.8546 60.7355 37.6606 61.0003 37.503C61.3091 37.3212 61.5739 37.2849 61.8276 37.3818C62.346 37.5758 62.7211 38.2909 62.8314 38.5455C62.9748 38.9091 63.3609 39.0788 63.7028 38.9212C64.0337 38.7636 64.1882 38.3273 64.0448 37.9636C63.9675 37.8182 63.405 36.4606 62.2578 36.0121Z"
          fill="#2C3E50"
        />
        <path
          d="M59.3457 45.3697C59.1692 45.3697 59.0037 45.4424 58.8824 45.5879C58.7611 45.7212 58.6838 45.9151 58.6838 46.097C58.6838 46.2909 58.75 46.4727 58.8824 46.606C59.0037 46.7394 59.1802 46.8242 59.3457 46.8242C59.5222 46.8242 59.6876 46.7515 59.809 46.606C59.9303 46.4727 60.0075 46.2788 60.0075 46.097C60.0075 45.903 59.9413 45.7212 59.809 45.5879C59.6987 45.4424 59.5222 45.3697 59.3457 45.3697Z"
          fill="#2C3E50"
        />
        <path
          d="M60.4046 38.7515C60.3274 38.5818 59.7758 37.4545 58.7169 37.0788C58.1212 36.8727 57.4925 36.9454 56.8748 37.3091C56.3453 37.6242 55.9813 38.0848 55.8268 38.6667C55.54 39.7454 56.0695 40.8363 56.1578 41.0182L57.8896 44.6545C58.0109 44.8969 58.2426 45.0424 58.4742 45.0424C58.5735 45.0424 58.6838 45.0182 58.7831 44.9576C59.103 44.7757 59.2353 44.3273 59.0588 43.9757L57.327 40.3273C57.327 40.3151 57.316 40.3151 57.316 40.303C57.2388 40.1576 56.9851 39.5273 57.1064 39.0545C57.1395 38.9212 57.2167 38.7394 57.5035 38.5697C57.8013 38.4 58.0661 38.3515 58.3088 38.4363C58.772 38.5939 59.125 39.1757 59.2243 39.3818C59.2243 39.3818 59.2243 39.3939 59.2353 39.3939L59.6766 40.303C59.842 40.6545 60.2502 40.7879 60.5701 40.606C60.8899 40.4121 61.0113 39.9757 60.8458 39.6242L60.4046 38.7515Z"
          fill="#2C3E50"
        />
        <path
          d="M51.3487 36.7515C51.3377 36.7273 51.3267 36.703 51.3046 36.6788C51.3046 36.6788 50.9957 36.1697 51.106 35.6727C51.1833 35.3455 51.4259 35.0667 51.8561 34.8121C52.154 34.6424 52.4187 34.5939 52.6614 34.6788C53.1247 34.8364 53.4777 35.4182 53.5769 35.6242C53.5769 35.6242 53.5769 35.6364 53.588 35.6364L56.3346 41.3333C56.5001 41.6848 56.9082 41.8182 57.2281 41.6364C57.548 41.4545 57.6694 41.0061 57.5039 40.6545L54.7683 34.9697C54.691 34.8 54.1395 33.6727 53.0806 33.297C52.4849 33.0909 51.8561 33.1636 51.2384 33.5273C50.4663 33.9758 49.9809 34.5939 49.8265 35.3333C49.6059 36.3636 50.0692 37.2364 50.2015 37.4667L56.3567 50.2303C56.478 50.4727 56.7097 50.6182 56.9413 50.6182C57.0406 50.6182 57.1509 50.5939 57.2502 50.5333C57.5701 50.3394 57.6914 49.903 57.526 49.5515L51.3487 36.7515Z"
          fill="#2C3E50"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="100" height="72" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default DailyTipper;
