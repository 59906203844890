import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 56,
    background: '#FFFFFF',
    borderRadius: 4,
    marginTop: 4,
    padding: '8px 16px 8px 8px',
    boxSizing: 'border-box',
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 40,
    height: 40,
    marginRight: 10,
    background: '#E2F5FD',
    borderRadius: 4,
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
}));
