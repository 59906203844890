import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

import { useAuth } from '@/contexts/auth-context';
import {
  useInAppUserSubscribeMutation,
  UserSubscriptionDocument,
  useSetExpoTokenMutation,
  useUpgradeInAppSubscriptionMutation,
} from '@/graphql';
import {
  RNMessageType,
  WebActionType,
} from '@/screens/main/in-app-subscriptions/types';
import { LS_KEY_NATIVE_OS_NAME } from '@/utils/constants';

const RnWebView = () => {
  const history = useHistory();
  const [setExpoToken] = useSetExpoTokenMutation();
  const [upgradeInAppSubscription] = useUpgradeInAppSubscriptionMutation();
  const { isAuthorized } = useAuth();

  const [inAppUserSubscribe] = useInAppUserSubscribeMutation();

  useEffect(() => {
    async function handleEvent(message: any) {
      if (!isAuthorized || !message?.data) return;
      const messageData = message.data;

      switch (messageData.type) {
        case RNMessageType.RN_EXPO_TOKEN:
          try {
            const { data } = await setExpoToken({
              variables: {
                expoToken: messageData.payload,
              },
            });
            const resToken = data?.setUserExpoToken?.result;
            if (resToken) {
              window?.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  type: WebActionType.SendExpoToken,
                  payload: {
                    token: resToken,
                  },
                })
              );
            }
          } catch (error) {
            console.error('SET_EXPO_TOKEN', error);
          }
          break;
        case RNMessageType.RN_ERROR: {
          datadogRum.addAction('react-native-error', messageData.payload);
          break;
        }
        case RNMessageType.RN_PLATFORM_INFO: {
          localStorage.setItem(
            LS_KEY_NATIVE_OS_NAME,
            messageData.payload?.osName
          );
          break;
        }
        case RNMessageType.RN_SUBSCRIPTION_RENEW_SUCCESS: {
          try {
            await inAppUserSubscribe({
              variables: {
                subscriptionObject: messageData.payload,
              },
              refetchQueries: [{ query: UserSubscriptionDocument }],
            });
          } catch (error) {
            console.error('RN_RENEW_ERROR', error);
          }
          break;
        }
        case RNMessageType.RN_SUBSCRIPTION_UPGRADE_SUCCESS: {
          try {
            const { data } = await upgradeInAppSubscription({
              variables: {
                subscriptionObject: messageData.payload,
              },
              refetchQueries: [{ query: UserSubscriptionDocument }],
            });
            if (
              data?.changeInAppSubscription?.inAppSubscription?.customer
                .isInAppPremium
            ) {
              history.push('/my-profile/network', {
                prevPage: '/home',
              });
            }
          } catch (error) {
            console.error('UPGRADE_IN_APP_SUBSCRIPTION', error);
          }
          break;
        }
      }
    }
    document.addEventListener('RN_MESSAGE', handleEvent);

    return () => document.removeEventListener('RN_MESSAGE', handleEvent);
  }, [
    setExpoToken,
    isAuthorized,
    history,
    upgradeInAppSubscription,
    inAppUserSubscribe,
  ]);

  return <div />;
};

export default RnWebView;
