import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: 24,
    width: 24,
  },
});

const CheckIcon: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path d="M8.79508 15.875L4.62508 11.705L3.20508 13.115L8.79508 18.705L20.7951 6.705L19.3851 5.295L8.79508 15.875Z" />
    </SvgIcon>
  );
};

export default CheckIcon;
