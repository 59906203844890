import React from 'react';
import { useTranslation } from 'react-i18next';
import AppButton from '../app-button';

const ClearCacheButton: React.FC = () => {
  const { t } = useTranslation();

  const clearCache = async () => {
    if ('serviceWorker' in navigator) {
      // Unregister all service workers
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.unregister();
      }

      // Clear all caches
      if (window.caches) {
        const cacheNames = await caches.keys();
        await Promise.all(cacheNames.map((cache) => caches.delete(cache)));
      }

      // Reload the page to fetch fresh assets
      window.location.reload();
    }
  };

  return (
    <AppButton
      fullWidth
      color="primary"
      variant="contained"
      onClick={clearCache}
    >
      {t('fallback.clear-button-text')}
    </AppButton>
  );
};

export default ClearCacheButton;
