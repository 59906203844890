import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const BoxMagicIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M23.9465 11.7327C23.8376 11.47 23.5812 11.2987 23.2969 11.2987H15.7656C15.5791 11.2987 15.4003 11.3728 15.2684 11.5046L12 14.7726L8.73161 11.5046C8.59975 11.3728 8.42092 11.2987 8.23445 11.2987H0.703119C0.418728 11.2987 0.162321 11.47 0.0535234 11.7327C-0.0552738 11.9954 0.00486704 12.2978 0.205961 12.4989L3.76556 16.058V23.2969C3.76556 23.6852 4.08038 24 4.46869 24H19.5312C19.9195 24 20.2343 23.6852 20.2343 23.2969V16.058L23.794 12.4989C23.9951 12.2978 24.0553 11.9954 23.9465 11.7327ZM2.40056 12.7048H7.94308L10.3024 15.0638H4.75993L2.40056 12.7048ZM11.2969 22.5939H5.17182V16.4698H11.2969V22.5939ZM18.8281 22.5939H12.7031V16.4698H18.8281V22.5939H18.8281ZM19.24 15.0638H13.6975L16.0568 12.7048H21.5993L19.24 15.0638Z"
        fill="#2B75B3"
      />
      <path
        d="M8.98615 8.93982C9.37446 8.93982 9.68927 8.62505 9.68927 8.2368C9.68927 7.84854 9.37446 7.53377 8.98615 7.53377C6.60705 7.53377 5.52344 6.36043 5.52344 5.27027C5.52344 4.47309 6.12841 3.66874 7.47991 3.66874C8.99247 3.66874 10.0924 4.88019 10.7492 7.26939C11.2872 9.22632 11.2955 11.2292 11.2955 11.2492C11.2957 11.6374 11.6104 11.9519 11.9987 11.9519C12.387 11.9519 12.7018 11.6371 12.7018 11.2489C12.7018 11.1605 12.6951 9.05938 12.1122 6.92233C11.0617 3.07112 9.01329 2.2627 7.47991 2.2627C5.27224 2.2627 4.11719 3.77569 4.11719 5.27027C4.11719 7.21348 5.8938 8.93982 8.98615 8.93982Z"
        fill="#2B75B3"
      />
      <path
        d="M14.2598 7.53348C13.8715 7.53348 13.5566 7.84825 13.5566 8.23651C13.5566 8.62476 13.8715 8.93953 14.2598 8.93953C18.0481 8.93953 20.2525 6.88684 20.2525 4.52942C20.2525 2.88707 18.9631 0.756348 15.766 0.756348C15.3777 0.756348 15.0629 1.07111 15.0629 1.45937C15.0629 1.84763 15.3777 2.16239 15.766 2.16239C20.5337 2.32456 19.5971 7.68206 14.2598 7.53348Z"
        fill="#2B75B3"
      />
      <path
        d="M12.2565 1.95665C12.5183 2.22895 12.989 2.229 13.2508 1.95665L14.0039 1.20361C14.6382 0.520838 13.6927 -0.425009 13.0095 0.209399L12.7536 0.465299L12.4977 0.209399C11.8145 -0.425009 10.869 0.520885 11.5033 1.20361C11.5033 1.20366 12.2565 1.95665 12.2565 1.95665Z"
        fill="#2B75B3"
      />
    </SvgIcon>
  );
};

export default BoxMagicIcon;
