import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  btnNewBox: {
    padding: '6px 8px',
    backgroundColor: theme.palette.common.white,
  },
  emptyWrap: {
    height: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
  },
}));
