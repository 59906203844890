import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Box, Button } from '@material-ui/core';

import { useCreateDuel } from '@/hooks/useCreateDuel';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import { ClassRoomLearningSessionStatus } from '@/graphql';
import { LockOutlined } from '@material-ui/icons';
import { ClassroomTypes } from '@/type';
import {
  CLASSROOM_MAX_DUEL_COUNT,
  FREEMIUM_MAX_DUEL_COUNT,
  PREMIUM_MAX_DUEL_COUNT,
} from '@/utils/constants';
import { useStyles } from './styles';

interface ClassroomCardWrapProps {
  sessionId: string;
  sessionStatus?: ClassRoomLearningSessionStatus | undefined | null;
  classRoomType?: number | null;
  finishDate?: string;
  activeDuelsLength?: number | null;
}

const ClassroomCardWrap: React.FC<ClassroomCardWrapProps> = ({
  children,
  sessionId,
  classRoomType,
  finishDate,
  sessionStatus,
  activeDuelsLength,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isUserPremium } = useGetPremiumData();

  const { handleCreateDuel, creatingLoading } = useCreateDuel({
    sessionId,
  });
  const isDuelsLimit =
    (activeDuelsLength || 0) >=
    (classRoomType === ClassroomTypes.BLENDED
      ? CLASSROOM_MAX_DUEL_COUNT
      : isUserPremium
      ? PREMIUM_MAX_DUEL_COUNT
      : FREEMIUM_MAX_DUEL_COUNT);
  const handleClickOnStartBtn = () => handleCreateDuel();

  return (
    <>
      {children}
      {classRoomType !== ClassroomTypes.LEK &&
        DateTime.fromISO(finishDate || '').endOf('day') >=
          DateTime.local().endOf('day') && (
          <Box mt={2} mb={5} px={2}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickOnStartBtn}
              fullWidth
              startIcon={isDuelsLimit && <LockOutlined />}
              className={`${isDuelsLimit ? classes.startBtnDisabled : ''}`}
              disabled={
                creatingLoading ||
                sessionStatus === ClassRoomLearningSessionStatus.Pending
              }
            >
              {t('classrooms.play-duel')}
            </Button>
          </Box>
        )}
    </>
  );
};

export default ClassroomCardWrap;
