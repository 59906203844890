import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const BiblioIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...rest}>
      <g
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M7.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H7.5C7.91421 20.25 8.25 19.9142 8.25 19.5V4.5C8.25 4.08579 7.91421 3.75 7.5 3.75Z" />
        <path d="M3.75 7.5H8.25" />
        <path d="M12 3.75H9C8.58579 3.75 8.25 4.08579 8.25 4.5V19.5C8.25 19.9142 8.58579 20.25 9 20.25H12C12.4142 20.25 12.75 19.9142 12.75 19.5V4.5C12.75 4.08579 12.4142 3.75 12 3.75Z" />
        <path d="M8.25 16.5H12.75" />
        <path d="M16.3727 3.5099L13.4749 4.28635C13.0748 4.39356 12.8374 4.80481 12.9446 5.20491L16.8269 19.6938C16.9341 20.0939 17.3453 20.3313 17.7454 20.2241L20.6432 19.4477C21.0433 19.3405 21.2808 18.9292 21.1736 18.5291L17.2913 4.04023C17.1841 3.64013 16.7728 3.40269 16.3727 3.5099Z" />
        <path d="M16.0498 16.7998L20.3998 15.6279" />
        <path d="M13.7251 8.1L18.0751 6.9375" />
      </g>
    </SvgIcon>
  );
};

export default BiblioIcon;
