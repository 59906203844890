import React, { lazy } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DailyTipperIntroIcon } from './icons/index.svg';
import { ReactComponent as BLIntroIcon } from './icons/icon-1.svg';
import { ReactComponent as ImproveIntroIcon } from './icons/icon-2.svg';
import { ReactComponent as KapitelIntroIcon } from './icons/icon-3.svg';
import { ReactComponent as DuelsIntroIcon } from './icons/icon-4.svg';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useStyles } from './styles';
import { CAROUSEL_INTERVAL } from '@/utils/constants';
const Carousel = lazy(() => import('react-material-ui-carousel'));

const Intro: React.FC<unknown> = () => {
  const { t } = useTranslation();

  const items = [
    {
      title: t(`onboarding.intro.step-1.title`),
      description: t(`onboarding.intro.step-1.description`),
      icon: <DailyTipperIntroIcon />,
    },
    {
      title: t(`onboarding.intro.step-2.title`),
      description: t(`onboarding.intro.step-2.description`),
      icon: <DuelsIntroIcon />,
    },
    {
      title: t(`onboarding.intro.step-3.title`),
      description: t(`onboarding.intro.step-3.description`),
      icon: <KapitelIntroIcon />,
    },
    {
      title: t(`onboarding.intro.step-4.title`),
      description: t(`onboarding.intro.step-4.description`),
      icon: <BLIntroIcon />,
    },
    {
      title: t(`onboarding.intro.step-5.title`),
      description: t(`onboarding.intro.step-5.description`),
      icon: <ImproveIntroIcon />,
    },
  ];

  return (
    <React.Fragment>
      <Carousel
        swipe={true}
        cycleNavigation={false}
        animation="slide"
        autoPlay={true}
        interval={CAROUSEL_INTERVAL}
        navButtonsAlwaysInvisible={true}
        IndicatorIcon={
          <FiberManualRecordIcon
            style={{ fontSize: 10 }}
          ></FiberManualRecordIcon>
        }
        indicatorIconButtonProps={{
          style: {
            margin: '3px',
            color: '#C6D5E3',
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: '#3498DB',
          },
        }}
        indicatorContainerProps={{
          style: {
            position: 'absolute',
            top: '0',
          },
        }}
      >
        {items.map((item, idx) => (
          <Item
            key={idx}
            title={item.title}
            description={item.description}
            icon={item.icon}
          />
        ))}
      </Carousel>
    </React.Fragment>
  );
};

const Item: React.FC<{
  title: string;
  description: string;
  icon: any;
  key: number;
}> = ({ title, description, icon }) => {
  const classes = useStyles();
  return (
    <Box textAlign="center" display="grid" gridRowGap={4} marginTop={12}>
      <Typography variant="h2">{title}</Typography>
      <Typography variant="body2" className={classes.description}>
        {description}
      </Typography>
      <Box>{icon}</Box>
    </Box>
  );
};

export default Intro;
