import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { Divider, Link, Paper } from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

import RemainingTime from '@/components/remaining-time';
import AppButton from '@/components/app-button';
import { InfoBox } from '@/components/info-box';
import DuelItem from '../../components/duel-item';
import { ReactComponent as EmptyDuelIcon } from '../../components/empty-duel/index.svg';
import { useDuel } from '@/hooks/useDuel';
import { useCreateDuel } from '@/hooks/useCreateDuel';
import { useGetActiveDuelsQuery, useUserMeQuery } from '@/graphql';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import {
  FREEMIUM_MAX_DUEL_COUNT,
  PREMIUM_MAX_DUEL_COUNT,
} from '@/utils/constants';
import { useStyles } from './styles';
import Fallback from '@/components/fallback';

const DuelCard: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openDuel, loading: openLoading } = useDuel();
  const { handleCreateDuel, creatingLoading } = useCreateDuel({});
  const { data: userData, error: userError } = useUserMeQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { isUserPremium } = useGetPremiumData();

  const { data, error, loading } = useGetActiveDuelsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const activeDuels = useMemo(() => data?.activeDuels || [], [data]);
  const points = userData?.monthlyScore?.points || 0;
  const activeDuelsCount = useMemo(
    () =>
      (isUserPremium ? data?.activeDuels?.length : data?.startedDuelsToday) ||
      0,
    [data, isUserPremium]
  );
  const maxDuelCount = isUserPremium
    ? PREMIUM_MAX_DUEL_COUNT
    : activeDuelsCount >= FREEMIUM_MAX_DUEL_COUNT
    ? activeDuelsCount
    : FREEMIUM_MAX_DUEL_COUNT;
  const canCreateDuel = useMemo(
    () =>
      activeDuelsCount < maxDuelCount &&
      !userData?.me?.settings?.activeQuizTime?.id,
    [activeDuelsCount, maxDuelCount, userData]
  );

  const duelsList = useMemo(() => {
    if (userData?.me?.settings?.activeQuizTime?.id) return null;
    const active = activeDuels.filter(
      (duel) =>
        (duel &&
          duel.round &&
          duel.round.duel &&
          duel?.round?.userQuestions.filter(
            (x) => x.user?.id === userData?.me?.id && !!x.userAnswer?.id
          ).length !== 3) ||
        (duel?.round?.userQuestions.filter((x) => !!x.userAnswer?.id).length ===
          6 &&
          duel?.youTurn)
    );

    return active
      .map((it, idx) => {
        if (it?.round && it?.round.duel) {
          const isNew =
            it.round.roundNumber === 1 &&
            !it.round.userQuestions.some((x) =>
              !!it.isInitiator
                ? !!x.userAnswer?.id
                : !!x.userAnswer?.id && x.user?.id === userData?.me?.id
            );
          const youTurn =
            it.round.userQuestions.filter(
              (x) => x.user?.id === userData?.me?.id && !!x.userAnswer?.id
            ).length !== 3 ||
            (it.round.userQuestions.filter((x) => !!x.userAnswer?.id).length ===
              6 &&
              it.youTurn);

          if (!youTurn) return null;

          const handleSelect = () => openDuel(it.round?.duel?.id);

          return (
            <DuelItem
              data-cy-duel-item
              youTurn={!!youTurn}
              isNew={isNew}
              isInitiator={!!it.isInitiator}
              key={it.round.id}
              username={it.opponent?.username}
              avatar={it.opponent?.avatar ?? undefined}
              created={it.round.created}
              roundNumber={it.round.roundNumber}
              loading={openLoading}
              onSelect={handleSelect}
              classroomType={it?.session?.classRoom.enrollmentType}
              shouldNegativeIntervalCleared
              divider={active.length !== idx + 1 || active.length !== 1}
            />
          );
        }

        return null;
      })
      .filter((duel) => duel !== null);
  }, [activeDuels, openDuel, openLoading, userData]);

  if (!!error || !!userError) {
    if (!!userError) {
      console.error('useUserMeQuery in DuelCard', userError);
    }
    if (!!error) {
      console.error('useGetActiveDuelsQuery in DuelCard', error);
    }
    return <Fallback />;
  }

  return (
    <Paper data-cy-duels elevation={0} style={{ paddingTop: 1 }}>
      {loading && !data?.activeDuels ? (
        <Box p={4}>
          {[...Array(7)].map((i, idx) => (
            <Skeleton key={idx} height={25} />
          ))}
        </Box>
      ) : (
        <div id="animationFadeIn">
          {(userData?.me?.settings?.duelMode === false ||
            !!userData?.me?.settings?.activeQuizTime?.id) && (
            <Box m={2} mb={0}>
              <InfoBox>
                <Trans
                  i18nKey={
                    userData?.me?.settings?.activeQuizTime?.id
                      ? 'home.duels.info-qt-turned-off-duel-mode'
                      : 'home.duels.info-duel-mode-off'
                  }
                  components={{
                    link1: (
                      <Link
                        to="/settings/"
                        component={RouterLink}
                        color="primary"
                      />
                    ),
                  }}
                />
              </InfoBox>
            </Box>
          )}
          {(!!activeDuels?.length || (!activeDuels?.length && points > 0)) && (
            <div className={classes.header}>
              <div className={classes.row}>
                <Typography id="empty-duel-card-icon" variant="h6">
                  {t('home.duels.title')}
                </Typography>
                <div>
                  <Typography variant="subtitle2" align="right">
                    {t('common.duel.points', { points })}
                  </Typography>
                </div>
              </div>
              <Box className={classes.row} mt={1}>
                <Typography variant="subtitle2">
                  {t(
                    `common.duel.${
                      isUserPremium ? 'ongoing-duels' : 'duels-per-day'
                    }`
                  )}
                  <span className={classes.count}>
                    {activeDuelsCount}/{maxDuelCount}
                  </span>
                </Typography>
                <RemainingTime>{t('common.duel.remaining-time')}</RemainingTime>
              </Box>
              <Divider className={classes.divider} />
            </div>
          )}

          {(!activeDuels?.length || (!activeDuels?.length && points > 0)) && (
            <Box textAlign="center" px={6} mt={5} mb={1}>
              <Box mb={3}>
                <EmptyDuelIcon id="empty-duel-card-icon" />
              </Box>
              {!activeDuels?.length && points <= 0 && (
                <Typography variant="subtitle1">
                  {t('home.duels.title')}
                </Typography>
              )}
              <Box mt={!activeDuels?.length && points > 0 ? 5 : 1}>
                <Typography align="center" variant="body2">
                  {t(
                    `home.duels.empty-caption-${
                      !activeDuels?.length && points > 0 ? '2' : '1'
                    }`
                  )}
                </Typography>
              </Box>
            </Box>
          )}

          {!!duelsList?.length && (
            <>
              <Box mt={5} mb={2.5} mx={6}>
                <Typography variant="overline">
                  {t('common.duel.your-turn')}
                </Typography>
              </Box>
              <List>{duelsList}</List>
            </>
          )}
          {((!!duelsList && duelsList?.length === 0 && !!activeDuelsCount) ||
            !!userData?.me?.settings?.activeQuizTime?.id) && (
            <Box textAlign="center" mt={5} mb={1}>
              <Typography variant="caption" color="textSecondary">
                {t(
                  userData?.me?.settings?.activeQuizTime?.id
                    ? 'home.duels.qt-is-active'
                    : 'home.duels.not-your-turn'
                )}
              </Typography>
            </Box>
          )}
          <div className={classes.footer}>
            <AppButton
              fullWidth
              data-cy-duel-start-new-duel
              color="primary"
              variant="contained"
              loading={creatingLoading}
              startIcon={activeDuelsCount >= maxDuelCount && <LockOutlined />}
              visuallyDisabled={
                !canCreateDuel || activeDuelsCount >= maxDuelCount
              }
              onClick={handleCreateDuel}
            >
              {t('common.duel.start-random-duel')}
            </AppButton>
          </div>
        </div>
      )}
    </Paper>
  );
};

export default DuelCard;
