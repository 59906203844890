import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const DuelOutlinedIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M7.54669 11.5C6.40813 11.5 5.1747 11.1379 4.2259 10.5043H1.9488C1.4744 10.5043 1 10.1422 1 9.59914V2.90086C1 2.44828 1.37952 1.99569 1.9488 1.99569H4.2259C5.1747 1.36207 6.31325 1 7.54669 1H13.619C15.3268 1 16.75 2.35776 16.75 3.98707V8.51293C16.75 10.1422 15.3268 11.5 13.619 11.5H7.54669ZM13.619 9.68966C14.2831 9.68966 14.8524 9.14655 14.8524 8.51293V3.98707C14.8524 3.35345 14.2831 2.81034 13.619 2.81034C13.619 2.90086 13.619 3.0819 13.619 3.17241C13.619 3.98707 13.2395 4.80172 12.3855 5.07328V8.24138C12.3855 8.81258 12.9774 9.68966 13.619 9.68966ZM5.45934 9.14655C6.02861 9.50862 6.78765 9.78017 7.54669 9.78017H10.8675C10.6777 9.4181 10.5828 8.96552 10.5828 8.60345V5.25431H8.4006C7.92621 5.25431 7.45181 4.89224 7.45181 4.34914C7.45181 3.80603 7.83133 3.44397 8.4006 3.44397H11.4367C11.6265 3.44397 11.8163 3.26293 11.8163 3.0819C11.8163 2.90086 11.6265 2.71983 11.4367 2.71983H7.54669C6.78765 2.71983 6.12349 2.90086 5.45934 3.35345V9.14655ZM3.56175 8.69397V3.80603H2.80271V8.69397H3.56175Z" />
    <path d="M19.2751 8.90707C18.7497 8.31007 19.0655 7.99923 19.4848 7.6361L20.4283 7.00062L19.38 6.002C19.1703 5.82043 19.0655 5.63886 19.0655 5.36651C19.0655 5.09416 19.1388 4.96907 19.4848 4.73103L21.2669 3.46006C21.6876 3.16752 22.3827 3.15671 22.7522 3.57973C23.1216 4.00275 23.0799 4.40449 22.6144 4.73103L21.3717 5.54808C21.3717 5.54808 22.1551 6.28413 22.42 6.5467C22.685 6.80927 22.7915 6.91458 22.7702 7.22707C22.749 7.53957 22.6593 7.66165 22.3152 7.90845C21.9711 8.15525 20.8822 8.95715 20.8822 8.95715C20.8822 8.95715 19.9275 9.64828 19.2751 8.90707Z" />
    <path d="M16.4533 23C17.5919 23 18.8253 22.6379 19.7741 22.0043H22.0512C22.5256 22.0043 23 21.6422 23 21.0991V14.4009C23 13.9483 22.6205 13.4957 22.0512 13.4957H19.7741C18.8253 12.8621 17.6867 12.5 16.4533 12.5H10.381C8.67319 12.5 7.25 13.8578 7.25 15.4871V20.0129C7.25 21.6422 8.67319 23 10.381 23H16.4533ZM10.381 21.1897C9.71687 21.1897 9.14759 20.6466 9.14759 20.0129V15.4871C9.14759 14.8534 9.71687 14.3103 10.381 14.3103C10.381 14.4009 10.381 14.5819 10.381 14.6724C10.381 15.4871 10.7605 16.3017 11.6145 16.5733V19.7414C11.6145 20.3126 11.0226 21.1897 10.381 21.1897ZM18.5407 20.6466C17.9714 21.0086 17.2123 21.2802 16.4533 21.2802H13.1325C13.3223 20.9181 13.4172 20.4655 13.4172 20.1034V16.7543H15.5994C16.0738 16.7543 16.5482 16.3922 16.5482 15.8491C16.5482 15.306 16.1687 14.944 15.5994 14.944H12.5633C12.3735 14.944 12.1837 14.7629 12.1837 14.5819C12.1837 14.4009 12.3735 14.2198 12.5633 14.2198H16.4533C17.2123 14.2198 17.8765 14.4009 18.5407 14.8534V20.6466ZM20.4383 20.194V15.306H21.1973V20.194H20.4383Z" />
    <path d="M4.72486 20.4071C5.25031 19.8101 4.93452 19.4992 4.5152 19.1361L3.57174 18.5006L4.62003 17.502C4.82969 17.3204 4.93452 17.1389 4.93452 16.8665C4.93452 16.5942 4.86125 16.4691 4.5152 16.231L2.7331 14.9601C2.31238 14.6675 1.6173 14.6567 1.24785 15.0797C0.878393 15.5028 0.920104 15.9045 1.38561 16.231L2.62827 17.0481C2.62827 17.0481 1.84494 17.7841 1.57997 18.0467C1.315 18.3093 1.20855 18.4146 1.22977 18.7271C1.251 19.0396 1.34075 19.1617 1.6848 19.4085C2.02886 19.6553 3.11785 20.4572 3.11785 20.4572C3.11785 20.4572 4.07248 21.1483 4.72486 20.4071Z" />
  </SvgIcon>
);

export default DuelOutlinedIcon;
