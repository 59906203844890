import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as EmptyLaufendIcon } from './empty-laufend.svg';
import { Paper } from '@material-ui/core';

const EmptyHomepageClassrooms = () => {
  const { t } = useTranslation();

  return (
    <Paper elevation={0}>
      <Box py={7} px={2}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <EmptyLaufendIcon id="my-learning-card-icon" />
          <Box mt={2}>
            <Typography align="center" variant="subtitle1">
              {t('class-room.title2')}
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography align="center" variant="body2">
              {t('classrooms.empty-my-learning-info')}
            </Typography>
          </Box>
        </Box>
        <Box mt={5}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            to={'/biblio/'}
            component={RouterLink}
          >
            {t('classrooms.empty-cr-btn-explore')}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default EmptyHomepageClassrooms;
