import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const Reseted: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const goForward = React.useCallback(() => {
    history.push('/sign-in');
  }, [history]);

  return (
    <>
      <Box marginTop={4}>
        <Typography color="textPrimary" variant="caption">
          {t('forgot-password.renewed.info')}
        </Typography>
      </Box>
      <Box marginTop={7}>
        <Button
          fullWidth
          data-cy-back-login
          color="primary"
          variant="contained"
          onClick={goForward}
        >
          {t('common.back-login')}
        </Button>
      </Box>
    </>
  );
};

export default Reseted;
