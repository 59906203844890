import React from 'react';
import { Route as ReactRoute, Redirect, RouteProps } from 'react-router-dom';
import { useNavigationListener } from '@/hooks/useNavigationListener';
import { useAuth } from '@/contexts/auth-context';

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { isAuthorized } = useAuth();
  useNavigationListener();

  return (
    <ReactRoute
      {...rest}
      render={({ location }) =>
        isAuthorized ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
