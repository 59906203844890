import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '../../../../components/icons/check-circle';
import PlanChooser from '../plan-chooser';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';

import { useStyles } from './styles';
import { SubscriptionTypes } from '@/type';
import { useUser } from '@/contexts/user-context';

interface stateType {
  search: string;
  prevPage: string;
}

const ChooseSubscription = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { paymentEnabled } = useFeatureFlags();
  const { user } = useUser();
  const { state } = useLocation<stateType>();

  const closePage = () => {
    state?.search
      ? history.push({ pathname: '/home', search: `${state.search}` })
      : state?.prevPage
      ? history.replace({ pathname: `${state.prevPage}` })
      : history.push('/home');
  };

  return (
    <Box className={classes.root}>
      <IconButton
        aria-label="close"
        className={classes.close}
        onClick={closePage}
      >
        <CloseIcon htmlColor="#4A647C" />
      </IconButton>
      <Typography variant="h2">
        {t('subscription.full-access-title')}
      </Typography>
      <Box mt={6} mb={5}>
        {[1, 2, 3, 4].map((it) => (
          <Box key={it} mt={2} className={classes.adventageListItem}>
            <CheckCircleIcon className={classes.circleIcon} />
            <Typography variant="subtitle2">
              {t(`subscription.adventages.${it}`)}
            </Typography>
          </Box>
        ))}
      </Box>
      <PlanChooser
        nextStepURL="/payment"
        chosenPlanId={
          paymentEnabled || user?.isUserForTest
            ? undefined
            : SubscriptionTypes.Code
        }
      />
    </Box>
  );
};

export default ChooseSubscription;
