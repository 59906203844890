import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bottom: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    left: theme.spacing(4),
  },
  version: {
    position: 'fixed',
    display: 'grid',
    textAlign: 'center',
    left: 0,
    right: 0,
    bottom: 0,
  },
}));
