import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';

interface RowrProps {
  label?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridRowGap: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
}));

const InfoField: React.FC<RowrProps> = ({ label, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="caption">{label}</Typography>
      <Typography variant="body1" className="text-overflow-ellipsis">
        {children}
      </Typography>
    </div>
  );
};

export default InfoField;
