import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, Typography, Button } from '@material-ui/core';
import theme from '@/theme';
import Lottie from 'lottie-web';
import { flatten } from 'lottie-colorify';
import { makeStyles } from '@material-ui/core';
import TrophyIconJson from '@/components/gifs/trophy.json';
import { TabNavIdsEnum } from '@/type';
import { ANIM_ICON_CONTAINER_CSS_ID, LS_KEY_TABINDEX } from '@/utils/constants';

const useStyles = makeStyles((theme) => ({
  close: {
    top: 0,
    right: 0,
    padding: 0,
    alignSelf: 'flex-end',
  },
  iconTrophy: {
    '& svg': {
      maxHeight: 56,
    },
  },
}));

interface ResultDialogProps {
  sessionId: string;
  rank: number;
  onClose?: () => void;
}

const QuizResultDialog: React.FC<ResultDialogProps> = ({
  sessionId,
  rank,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [iconElemRef, setIconElemRef] = useState<HTMLDivElement | null>(null);

  const goToQuiz = () => {
    localStorage.setItem(
      `${TabNavIdsEnum.QUIZTIME}${sessionId}${LS_KEY_TABINDEX}`,
      '1'
    );
    history.push(`/quiztime/${sessionId}`);
  };

  useEffect(() => {
    const elem = iconElemRef?.querySelector(`#${ANIM_ICON_CONTAINER_CSS_ID}`);
    if (elem) {
      elem.innerHTML = '';
      Lottie.loadAnimation({
        container: elem,
        animationData: flatten('#2B75B3', TrophyIconJson),
      });
    }
  }, [iconElemRef]);

  return (
    <Dialog
      innerRef={(el) => {
        setIconElemRef(el);
      }}
      open={true}
      onClose={onClose}
    >
      <Box display="flex" flexDirection="column" px={4} py={6} maxWidth={264}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          color={theme.palette.primary.dark}
          mt={4}
          mb={10}
        >
          <Box
            textAlign="center"
            marginBottom={1}
            className={classes.iconTrophy}
          >
            <span
              id={ANIM_ICON_CONTAINER_CSS_ID}
              style={{ display: 'block', marginBottom: 8 }}
            />
            <Typography color="inherit" variant="h2">
              {t('quiztime.result-dialog.title', { place: rank })}
            </Typography>
          </Box>
          <Typography variant="body1" align="center">
            {t('quiztime.result-dialog.content')}
          </Typography>
        </Box>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={goToQuiz}
        >
          {t('quiztime.result-dialog.cta-btn')}
        </Button>
        <Box mt={4}>
          <Button
            fullWidth
            variant="outlined"
            onClick={onClose}
            style={{ color: '#2B75B3' }}
          >
            {t('common.cancel')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default QuizResultDialog;
