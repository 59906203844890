import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { ReactComponent as FallbackIcon } from './index.svg';
import theme from '@/theme';
import AppButton from '@/components/app-button';

const Congratulation: React.FC = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleLetsGo = () => {
    const historyState: any = history.location.state;
    if (historyState?.sessionId) {
      history.replace(`/classroom-details2/${historyState.sessionId}/`, {
        fromCongratulation: true,
      });
    } else if (historyState?.quizSessionId) {
      history.replace(`/quiztime-details/${historyState.quizSessionId}/`, {
        fromCongratulation: true,
        backPageForQuiz: historyState?.backPageForQuiz,
      });
    } else if (historyState?.prevPage) {
      history.replace(
        `${historyState?.prevPage}${
          historyState?.search ? historyState?.search : ''
        }`
      );
    } else {
      history.push('/my-profile/network');
    }
  };

  return (
    <Box
      paddingY={2}
      paddingX={7}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      boxSizing="border-box"
      style={{ backgroundColor: theme.palette.common.white }}
      className="fallback"
      textAlign="center"
    >
      <FallbackIcon />
      <Box mt={5}>
        <Typography variant="subtitle1">
          {t('payment.congratulation.title')}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography align="center" variant="caption">
          {t('payment.congratulation.subtitle')}
        </Typography>
      </Box>
      <Box mt={10} mb={20}>
        <AppButton
          style={{ width: 200 }}
          color="primary"
          variant="contained"
          onClick={handleLetsGo}
        >
          {t('common.lets-go')}!
        </AppButton>
      </Box>
    </Box>
  );
};

export default Congratulation;
