import React from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import TooltipClassroom from './classroom-tooltip';
import { useEffect } from 'react';
import { useState } from 'react';
import { useUser } from '@/contexts/user-context';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import IntroWalkthrough from './intro-walkthrough';

const TooltipWrrapper: React.FC = ({ children }) => {
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [classroomIntroURL, setClassroomIntroURL] = useState(false);
  const location = useLocation();
  const { user } = useUser();
  const history = useHistory();
  const { cookiesEnabled } = useFeatureFlags();

  const isIntroCompleted = user?.settings?.isIntroCompleted;
  const isClassRoomIntroCompleted = user?.settings?.isClassRoomIntroCompleted;

  useEffect(() => {
    if (
      location.search.includes('isOnboardingActive') &&
      !location.search.includes('leftMenu')
    ) {
      const params = new URLSearchParams(location.search);
      const stepParam = params.get('step');
      if (stepParam) setOnboardingStep(parseInt(stepParam));
    }
  }, [location]);

  useEffect(() => {
    if (
      matchPath(location.pathname, {
        path: '/classroom/:sessionId',
        exact: true,
        strict: false,
      })
    )
      setClassroomIntroURL(true);
  }, [location]);

  return (
    <>
      {user &&
        !!onboardingStep &&
        ((cookiesEnabled && user?.settings?.isCookieAccepted) ||
          !cookiesEnabled) &&
        isIntroCompleted === false &&
        !history.location.pathname.includes('/onboard-welcome') &&
        !history.location.pathname.includes('/onboard-first-duel') && (
          <IntroWalkthrough onboardingStep={onboardingStep} />
        )}
      {user &&
        classroomIntroURL &&
        ((cookiesEnabled && user?.settings?.isCookieAccepted) ||
          !cookiesEnabled) &&
        isClassRoomIntroCompleted === false &&
        !history.location.pathname.includes('/onboard-welcome') && (
          <TooltipClassroom />
        )}
      {children}
    </>
  );
};

export default TooltipWrrapper;
