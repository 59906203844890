import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    minHeight: 200,
    maxHeight: 200,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'calc(100% - 18px)',
    backgroundSize: '30%',
    backgroundColor: '#2C3E50',
    width: '100%',
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  overlay: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    width: '100%',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    color: theme.palette.common.white,
    background: 'rgba(26, 44, 62, 0.88)',
  },
  orgLink: {
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
  statusIsActive: {
    color: '#27AE60',
  },
  date: {
    fontSize: 12,
    lineHeight: '14px',
  },
  participants: {
    letterSpacing: 1.5,
    marginLeft: 5,
  },
  startBtn: {
    lineHeight: '16px',
    padding: '10px 29px 10px 29px',
  },
  startBtnDisabled: {
    borderColor: '#C6D5E3',
    color: theme.palette.action.disabled,
    background: theme.palette.action.disabledBackground + '!important',
  },
}));
