import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { useCountdown } from '@/hooks/useCountdown';
import { LekSessionStatus } from '@/graphql';

type LekStartProps = {
  sessionId: string;
  lekSessionId: string;
  finishDate: string;
  startDate: string;
  lekSessionStatus?: LekSessionStatus | null | undefined;
  justForLek?: boolean;
};

const LekStart: React.FC<LekStartProps> = ({
  sessionId,
  lekSessionId,
  finishDate,
  startDate,
  justForLek,
  lekSessionStatus,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  const lekUrl = `/classroom/${sessionId}/lek/${lekSessionId}`;

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openStartDialog = () => setIsOpen(true);
  const handleLekStart = () => history.push(lekUrl);

  const duration = useMemo(
    () =>
      +DateTime.fromISO(
        lekSessionStatus === LekSessionStatus.Pending ? startDate : finishDate
      ).diffNow(),
    [finishDate, startDate, lekSessionStatus]
  );
  const { timeLeft, timeLeftInMillis } = useCountdown(duration);

  if (timeLeftInMillis < 1) return <></>;

  return (
    <>
      {justForLek ? (
        <Box px={4} py={3} style={{ backgroundColor: 'white' }}>
          <Box textAlign="center">
            <Button
              color="primary"
              disabled={lekSessionStatus === LekSessionStatus.Pending}
              variant="contained"
              onClick={openStartDialog}
              fullWidth
            >
              {t('lek.start')}
            </Button>
            {lekSessionStatus !== LekSessionStatus.Finished && (
              <Box mt={3}>
                <Typography variant="caption">
                  {lekSessionStatus === LekSessionStatus.Pending
                    ? t('lek.starts-in')
                    : t('lek.ends-in')}
                </Typography>
                <Typography variant="caption" color="textPrimary">
                  {' '}
                  {timeLeft}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={4}
          py={3}
          style={{ backgroundColor: 'white' }}
        >
          <Box mr={3}>
            <Typography variant="subtitle2">{t('lek.paticipate')}</Typography>
            <Box mt={1}>
              <Typography variant="caption">
                {lekSessionStatus === LekSessionStatus.Pending
                  ? t('lek.starts-in')
                  : t('lek.ends-in')}
              </Typography>
              <Typography variant="caption" color="textPrimary">
                {' '}
                {timeLeft}
              </Typography>
            </Box>
          </Box>
          <Box flexShrink={0}>
            <Button
              color="primary"
              disabled={lekSessionStatus === LekSessionStatus.Pending}
              variant="contained"
              onClick={openStartDialog}
            >
              {t('lek.start')}
            </Button>
          </Box>
        </Box>
      )}
      <Dialog data-cy-welcome-dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>{t('lek.paticipate')}</DialogTitle>
        <DialogContent>
          <Box marginBottom={5} mt={-2}>
            <Typography variant="body2">{t('lek.paticipate-info')}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button data-cy-later-btn onClick={handleClose}>
            {t('common.back')}
          </Button>
          <Box color="primary">
            <Button data-cy-start-btn color="primary" onClick={handleLekStart}>
              {t('lek.start')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LekStart;
