import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const DuelRule8Icon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M23.3143 14.1458H20.8104C19.8367 13.4053 18.6408 13 17.4148 13H17.3714H10.9714C9.33298 13 8 14.3365 8 15.9792V21.0208C8 22.6635 9.33298 24 10.9714 24H17.4148C18.6408 24 19.8367 23.5947 20.8104 22.8542H23.3143C23.693 22.8542 24 22.5464 24 22.1667V14.8333C24 14.4536 23.693 14.1458 23.3143 14.1458ZM12.5714 21.0208C12.5714 21.9054 11.8537 22.625 10.9714 22.625C10.0892 22.625 9.37143 21.9054 9.37143 21.0208V15.9792C9.37143 15.0946 10.0892 14.375 10.9714 14.375H11.3179C11.2418 14.5902 11.2 14.8215 11.2 15.0625C11.2 15.9588 11.7733 16.723 12.5714 17.0068V21.0208ZM19.8857 21.8296C19.1678 22.3445 18.3017 22.625 17.4148 22.625H13.4733C13.77 22.1615 13.9429 21.6112 13.9429 21.0208V17.125H16.4571C16.8358 17.125 17.1429 16.8172 17.1429 16.4375C17.1429 16.0578 16.8358 15.75 16.4571 15.75H13.2571C12.879 15.75 12.5714 15.4416 12.5714 15.0625C12.5714 14.6834 12.879 14.375 13.2571 14.375H17.3714H17.4148C18.3016 14.375 19.1678 14.6555 19.8857 15.1704V21.8296ZM22.6286 21.4792H21.2571V15.5208H22.6286V21.4792Z" />
    <path d="M16 9.02083V3.97917C16 2.33645 14.667 1 13.0286 1H6.62857H6.58519C5.35922 1 4.16334 1.40526 3.18962 2.14583H0.685714C0.307017 2.14583 0 2.45365 0 2.83333V10.1667C0 10.5464 0.307017 10.8542 0.685714 10.8542H3.18962C4.16334 11.5947 5.35922 12 6.58519 12H13.0286C14.667 12 16 10.6635 16 9.02083ZM14.6286 3.97917V9.02083C14.6286 9.90537 13.9108 10.625 13.0286 10.625C12.1463 10.625 11.4286 9.90537 11.4286 9.02083V5.00675C12.2267 4.72295 12.8 3.95877 12.8 3.0625C12.8 2.82146 12.7582 2.59019 12.6821 2.375H13.0286C13.9108 2.375 14.6286 3.09463 14.6286 3.97917ZM1.37143 3.52083H2.74286V9.47917H1.37143V3.52083ZM4.11429 9.82961V3.17039C4.83223 2.65555 5.69833 2.375 6.58519 2.375H6.62857H10.7429C11.121 2.375 11.4286 2.68341 11.4286 3.0625C11.4286 3.44159 11.121 3.75 10.7429 3.75H7.54286C7.16416 3.75 6.85714 4.05782 6.85714 4.4375C6.85714 4.81718 7.16416 5.125 7.54286 5.125H10.0571V9.02083C10.0571 9.61121 10.23 10.1615 10.5267 10.625H6.58519C5.69833 10.625 4.83223 10.3445 4.11429 9.82961Z" />
    <path d="M5.99833 17.0279C5.98513 16.8277 5.89192 16.6435 5.74282 16.5231L4.02858 15.1385C3.74458 14.9091 3.34165 14.9711 3.12861 15.2769C2.91557 15.5828 2.97317 16.0167 3.25718 16.2462L4.37846 17.1518L3.18831 18.4335C3.05652 18.5754 2.98843 18.7719 3.00167 18.9722C3.01487 19.1723 3.10808 19.3565 3.25718 19.4769L4.97142 20.8615C5.08704 20.9549 5.22238 21 5.35661 21C5.55207 21 5.7451 20.9044 5.87139 20.723C6.08443 20.4172 6.02683 19.9832 5.74282 19.7538L4.62154 18.8481L5.81169 17.5664C5.94348 17.4245 6.01157 17.228 5.99833 17.0279Z" />
    <path d="M19.3785 7.84816L18.2572 8.75382C17.9732 8.98325 17.9156 9.41718 18.1286 9.72303C18.2549 9.90442 18.4479 10 18.6434 10C18.7776 10 18.913 9.95486 19.0286 9.86149L20.7428 8.4769C20.8919 8.35649 20.9851 8.17229 20.9983 7.97213C21.0116 7.77191 20.9435 7.57539 20.8117 7.43352L19.6215 6.1518L20.7428 5.24618C21.0268 5.01676 21.0844 4.58283 20.8714 4.27697C20.6584 3.97102 20.2554 3.90913 19.9714 4.13851L18.2572 5.5231C18.1081 5.64352 18.0149 5.82771 18.0017 6.02788C17.9884 6.22809 18.0565 6.42461 18.1883 6.56649L19.3785 7.84816Z" />
  </SvgIcon>
);

export default DuelRule8Icon;
