import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import TrophyIconJson from '@/components/gifs/trophy.json';
import GuaranteeIconJson from '@/components/gifs/guarantee.json';

import {
  DailyStatusType,
  useDailyTipperDataQuery,
  useDtWeeklyProgressQuery,
} from '@/graphql';
import { TAKEN_ACHIEVEMENT } from '@/apollo/queries';
import Lottie from 'lottie-web';
import { flatten } from 'lottie-colorify';
import {
  ANIM_ICON_CONTAINER_CSS_ID,
  DT_LEARNING_YEAR_IN_DAYS,
} from '@/utils/constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 280,
    padding: '36px 10px',
  },
  paperFullwidth: {
    background: '#2B75B3',
  },
  closeFullscreen: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    position: 'absolute',
    color: '#fff',
  },
  close: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    position: 'absolute',
  },
  iconContainer: {
    '& svg': { width: '58px!important', height: '58px!important' },
  },
  circleTrophy: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '27vh',
    marginBottom: 4,
    width: 200,
    height: 200,
    background: '#FFFFFF',
    border: '4px solid #45B3EE',
    borderRadius: '50%',
    '& svg': {
      maxHeight: 56,
    },
  },
}));

const ResultDialog: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const [iconElemRef, setIconElemRef] = useState<HTMLDivElement | null>(null);
  const [plusWasShowed, setPlusWasShowed] = useState<boolean>(false);

  const { data: dtWeekData } = useDtWeeklyProgressQuery();
  const currentAchievement = useMemo(
    () => dtWeekData?.me?.dailytipper?.currentAchievement?.learningDays ?? 0,
    [dtWeekData]
  );

  const { data, refetch, loading } = useDailyTipperDataQuery();

  const localData = useQuery(TAKEN_ACHIEVEMENT);
  const localDailyStatus = localData?.data?.dailyStatus;
  const dailyResultStatus = data?.dailyTipperData?.dailyResultStatus;
  const isStatusTakeYearAchievement =
    localDailyStatus === DailyStatusType.TakeYearAchievement ||
    dailyResultStatus === DailyStatusType.TakeYearAchievement;
  const isStatusTakeAchievement =
    localDailyStatus === DailyStatusType.TakeAchievement ||
    dailyResultStatus === DailyStatusType.TakeAchievement ||
    isStatusTakeYearAchievement;
  const isStatusPlus =
    localDailyStatus === DailyStatusType.Plus ||
    dailyResultStatus === DailyStatusType.Plus;
  const isDialogOpen = useMemo(
    () => (isStatusPlus || isStatusTakeAchievement ? true : false),
    [isStatusTakeAchievement, isStatusPlus]
  );

  const handleClose = useCallback(() => {
    if (isStatusTakeAchievement && !plusWasShowed) {
      setPlusWasShowed(true);
    } else if (isStatusTakeYearAchievement) {
      history.replace('/daily-tipper/year-congrats');
    } else {
      refetch();
      history.replace('/daily-tipper');
    }
  }, [
    history,
    refetch,
    plusWasShowed,
    setPlusWasShowed,
    isStatusTakeAchievement,
    isStatusTakeYearAchievement,
  ]);

  const content = useMemo(() => {
    if (loading)
      return (
        <Box paddingY={12}>
          <CircularProgress size={44} />
        </Box>
      );

    if (isStatusTakeAchievement && plusWasShowed) {
      return (
        <>
          <Box className={classes.circleTrophy}>
            <Box display="flex" justifyContent="center">
              <span id={ANIM_ICON_CONTAINER_CSS_ID} />
            </Box>
            <Typography variant="h2" style={{ marginTop: 8, color: '#2B73B3' }}>
              {t('daily-tipper.learning-days', {
                value: isStatusTakeYearAchievement
                  ? DT_LEARNING_YEAR_IN_DAYS
                  : currentAchievement,
              })}
            </Typography>
          </Box>
          <Box mx="auto" maxWidth={248}>
            <Typography
              align="center"
              variant="body1"
              style={{ color: '#fff' }}
            >
              {t('daily-tipper.result.weekly-trophy')}
            </Typography>
          </Box>
        </>
      );
    }

    if (isStatusPlus || (isStatusTakeAchievement && !plusWasShowed)) {
      return (
        <>
          <Box display="flex" justifyContent="center">
            <span
              className={classes.iconContainer}
              id={ANIM_ICON_CONTAINER_CSS_ID}
            />
          </Box>
          <Typography color="primary" variant="h2">
            {t('daily-tipper.plus-learning')}
          </Typography>
          <Typography align="center" variant="body1">
            {t('daily-tipper.result.guarantee')}
          </Typography>
        </>
      );
    }

    return <></>;
  }, [
    loading,
    t,
    isStatusTakeAchievement,
    isStatusTakeYearAchievement,
    isStatusPlus,
    currentAchievement,
    classes,
    plusWasShowed,
  ]);

  useEffect(() => {
    const elem = iconElemRef?.querySelector(`#${ANIM_ICON_CONTAINER_CSS_ID}`);
    if ((isStatusTakeAchievement || isStatusPlus) && elem) {
      elem.innerHTML = '';
      Lottie.loadAnimation({
        container: elem,
        animationData: isStatusTakeAchievement
          ? flatten('#2B75B3', TrophyIconJson)
          : flatten('#3498DB', GuaranteeIconJson),
      });
    }
  }, [iconElemRef, isStatusTakeAchievement, isStatusPlus, plusWasShowed]);

  return (
    <Dialog
      data-cy-daily-tipper-result-dialog
      innerRef={(el) => {
        setIconElemRef(el);
      }}
      fullScreen={isStatusTakeAchievement && plusWasShowed}
      open={isDialogOpen}
      classes={{
        paper:
          isStatusTakeAchievement && plusWasShowed
            ? classes.paperFullwidth
            : classes.paper,
      }}
      style={{
        background:
          isStatusTakeAchievement && plusWasShowed ? '#2B75B3' : 'initial',
      }}
      onClose={handleClose}
    >
      <Box display="flex" flexDirection="column" py={3} px={1.5}>
        <IconButton
          aria-label="close"
          className={
            isStatusTakeAchievement && plusWasShowed
              ? classes.closeFullscreen
              : classes.close
          }
          onClick={handleClose}
          data-dd-action-name="DT close result dialog"
        >
          <CloseIcon />
        </IconButton>
        <Box display="grid" justifyItems="center" gridRowGap={8}>
          {content}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ResultDialog;
