import React from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Box, Paper, Typography } from '@material-ui/core';
import { PermIdentityOutlined } from '@material-ui/icons';

import { MonthlyScoreType } from '@/graphql';

import { useStyles } from './styles';

interface DuelRankingItemProps {
  score: MonthlyScoreType;
}

const UserRankingItem: React.FC<DuelRankingItemProps> = ({
  score,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.root} {...rest}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        paddingY={2}
      >
        <Box paddingY={1}>
          <Avatar
            src={score.user?.avatar ?? undefined}
            style={{ fontSize: '1.75rem' }}
          >
            {!score.user?.avatar && <PermIdentityOutlined fontSize="inherit" />}
          </Avatar>
        </Box>
        <Typography variant="subtitle2" className={classes.label}>
          {score.user?.username}
        </Typography>
        <Typography color="initial" variant="caption">
          {t('duels.ranking.point', { points: score.points })}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        paddingY={1}
        className={classes.rank}
      >
        <Typography color="textSecondary" variant="h2">
          {score.rank}
        </Typography>
      </Box>
    </Paper>
  );
};

export default UserRankingItem;
