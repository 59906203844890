import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  linkText: {
    color: theme.palette.primary.dark,
  },
  descriptionTitle: {
    marginBottom: 12,
  },
  descriptionBox: {
    overflowWrap: 'anywhere',
  },
}));
