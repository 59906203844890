import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  ClickAwayListener,
  Divider,
  Paper,
  Typography,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { makeStyles } from '@material-ui/core/styles';

import StatusBlock from './status-block';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    maxHeight: 64,
    boxSizing: 'border-box',
  },
  dropdown: {
    position: 'absolute',
    height: 72,
    top: 66,
    right: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0px 3px 10px rgba(17, 38, 63, 0.14)`,
  },
}));

interface TopMenuDuelPoint {
  points: number;
  count: number;
  wins: number;
  ties: number;
  loss: number;
}

const TopMenuDuel: React.FC<TopMenuDuelPoint> = ({
  points,
  count,
  wins,
  ties,
  loss,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.root}>
        <Paper square elevation={0} onClick={handleClick}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="end"
            paddingX={4}
            paddingY={3}
          >
            <Box>
              <Typography variant="overline">
                {t('duels.monthly-result')}
              </Typography>
              <Box display="flex">
                <Typography variant="subtitle2">
                  {t('duels.points', { points })}
                </Typography>
                <Box marginX={5}>
                  <Divider orientation="vertical" />
                </Box>
                <Typography variant="subtitle2">
                  {t('duels.count', { count })}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" color="action.disabled">
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
          </Box>
          <Divider />
        </Paper>
        {open ? (
          <div className={classes.dropdown}>
            <Box
              display="grid"
              gridTemplateColumns="repeat(3, 1fr)"
              gridColumnGap={8}
              paddingX={4}
              paddingY={2}
            >
              <StatusBlock variant="victory" count={wins} />
              <StatusBlock variant="draw" count={ties} />
              <StatusBlock variant="defeat" count={loss} />
            </Box>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default TopMenuDuel;
