import React, { useCallback } from 'react';

import Box from '@material-ui/core/Box';
import ChooseItem from './../choose-item';

interface ChooseListProps {
  value: string | null;
  options: { value: string | null; label: React.ReactNode }[];
  onChange: (value: string) => void;
}

const ChooseList: React.FC<ChooseListProps> = ({
  options,
  value,
  onChange,
  ...rest
}) => {
  const handleSelect = useCallback(
    (value: string | null) => () => {
      if (value) onChange(value);
    },
    [onChange]
  );

  const renderOptions = options.map((it, i) => (
    <ChooseItem
      key={`option-${i}`}
      variant={it.value === value ? 'selected' : 'none'}
      onClick={handleSelect(it.value)}
    >
      {it.label}
    </ChooseItem>
  ));

  return (
    <Box display="grid" gridRowGap={12} {...rest}>
      {renderOptions}
    </Box>
  );
};

export default ChooseList;
