import React from 'react';

import { useStyles } from './styles';
import { Box } from '@material-ui/core';

interface ChipIndicatorProps {
  title: string;
}

const ChipIndicator: React.FC<ChipIndicatorProps> = ({ title, ...rest }) => {
  const classes = useStyles();

  return (
    <Box component="span" className={classes.label} {...rest}>
      {title}
    </Box>
  );
};

export default ChipIndicator;
