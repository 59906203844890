import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Typography } from '@material-ui/core';

import { useClassroomMembersQuery } from '@/graphql';
import { PermIdentityOutlined } from '@material-ui/icons';
import { useStyles } from './styles';

type AvaListProps = {
  sessionId?: string | null | undefined;
};

const AvaList: React.FC<AvaListProps> = ({ sessionId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: crDataMembers } = useClassroomMembersQuery({
    variables: { sessionId },
  });

  const membersLength = crDataMembers?.classRoomMembers?.length;
  const MAX_MEMBERS_QUANTITY = 4;
  if (!membersLength) return <></>;

  return (
    <Box mb={2} mt={1} display="flex" alignItems="center">
      <Box display="flex">
        {crDataMembers?.classRoomMembers
          ?.slice(
            0,
            membersLength && membersLength < MAX_MEMBERS_QUANTITY
              ? membersLength
              : MAX_MEMBERS_QUANTITY
          )
          ?.map((it) => {
            const player = it?.userData?.user;
            return (
              <Avatar
                key={it?.id}
                alt={player?.username || ''}
                src={player?.avatar || ''}
                className={classes.avatar}
              >
                {!player?.avatar && <PermIdentityOutlined fontSize="inherit" />}
              </Avatar>
            );
          })}
      </Box>
      <Box ml={4}>
        {crDataMembers?.classRoomMembers &&
          membersLength &&
          membersLength > MAX_MEMBERS_QUANTITY && (
            <Typography color="textSecondary" variant="overline">
              + {membersLength - MAX_MEMBERS_QUANTITY} {t('common.others')}
            </Typography>
          )}
      </Box>
    </Box>
  );
};

export default AvaList;
