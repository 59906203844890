import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
  },
  inner: {
    display: 'flex',
    padding: theme.spacing(4),
    color: theme.palette.text.primary,
  },
  orgAvatar: {
    width: 100,
    height: 100,
    border: '2px solid rgba(0, 0, 0, 0.1)',
  },
  orgAvatarBox: {
    width: 100,
    height: 100,
  },
}));
