import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { datadogRum } from '@datadog/browser-rum';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';

import * as Form from '@/components/form';
import SponsorBlock from '../sponsor-block';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { LS_KEY_IN_APP_SUBS_CANCELING } from '@/utils/constants';
import { WebActionType } from '@/screens/main/in-app-subscriptions/types';
import { useRenewPlanMutation, useUserSubscriptionQuery } from '@/graphql';
import { plansConfig } from '@/screens/main/subscriptions/plansConfig';
import DiscountBox from '@/screens/main/components/discount-box';
import AppButton from '@/components/app-button';
import DialogCancelSubscription from '@/screens/main/subscriptions/dialog-cancel-subscription';
import DialogManageSubscriptionOnOriginalPlatform from '@/screens/main/subscriptions/dialog-manage-subscription-on-original-platform';
import CancelingInProgress from '../canceling-in-progress';
import { getSubscriptionsPath } from '@/utils/helpers';

interface UserPremiumBlockProps {
  isSubsStatusIncomplete: boolean | null | undefined;
}
const UserPremiumBlock: React.FC<UserPremiumBlockProps> = ({
  isSubsStatusIncomplete,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [cancelSubsVisible, setCancelSubsVisible] = useState(false);
  const [dialogManageSubsVisible, setDialogManageSubsVisible] = useState(false);
  const { subsDiscountsEnabled } = useFeatureFlags();
  const {
    isUserPremium,
    currentCode,
    isUserPremiumByFriendship,
    isUserPremiumByPayment,
    isUserInAppPremium,
    premiumFinishDate,
    isPlatformSuitableForSubscription,
    subscriptionType,
    isSpecificProfession,
  } = useGetPremiumData();

  const { data: subsData } = useUserSubscriptionQuery();

  const currentCodeFinishDate = useMemo(
    () => DateTime.fromISO(currentCode?.finishDate).toFormat('dd/LL/yyyy'),
    [currentCode]
  );

  const choosedPlan = useMemo(
    () => plansConfig.find((it) => it.id === subscriptionType),
    [subscriptionType]
  );

  const [renewPlan, { loading: loadingRenewSubs }] = useRenewPlanMutation();
  const [isRenewInProgress, setRenewInProgress] = useState(false);

  const handleRenewSubscription = useCallback(async () => {
    if (isPlatformSuitableForSubscription) {
      if (window?.ReactNativeWebView) {
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: WebActionType.RenewSubscription,
            payload: null,
          })
        );
        setRenewInProgress(true);
        datadogRum.addAction('in-app-subscription', {
          event: 'click RESUBSCRIBE button',
        });
      } else {
        try {
          await renewPlan();
        } catch (error) {
          console.error('useRenewPlanMutation', error);
        }
      }
    } else {
      setDialogManageSubsVisible(true);
    }
  }, [renewPlan, isPlatformSuitableForSubscription]);

  const [isUpgradeInProgress, setUpgradeInProgress] = useState(false);
  const handleUpgradeSubscription = useCallback(async () => {
    if (isPlatformSuitableForSubscription) {
      if (window?.ReactNativeWebView) {
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: WebActionType.UpgradeSubscription,
            payload: {},
          })
        );
        setUpgradeInProgress(true);
        datadogRum.addAction('in-app-subscription', {
          event: 'click UPGRADE button',
        });
      } else {
        history.push(`/choose-subscription`, {
          prevPage: '/my-profile/network',
          isRedirectedFromNetwork: true,
        });
      }
    } else {
      setDialogManageSubsVisible(true);
    }
  }, [history, isPlatformSuitableForSubscription]);

  const handleCancelSubscription = () => {
    if (isPlatformSuitableForSubscription) {
      setCancelSubsVisible(true);
    } else {
      setDialogManageSubsVisible(true);
    }
  };

  const getIsSubsCancelingInProgress = useCallback(() => {
    const lsValue = localStorage.getItem(LS_KEY_IN_APP_SUBS_CANCELING);
    if (typeof lsValue !== 'string') return false;
    const timestampOfCanceling = JSON.parse(lsValue)?.timestamp;
    const isStatusCanceled =
      subsData?.me?.inAppSubscription?.status === 'canceled';
    if (isStatusCanceled) {
      return false;
    }
    return Date.now() - timestampOfCanceling < 600000;
  }, [subsData]);

  const [isSubsCancelingInProgress, setSubsCancelingInProgress] = useState(
    getIsSubsCancelingInProgress()
  );
  useEffect(() => {
    setSubsCancelingInProgress(getIsSubsCancelingInProgress());
  }, [subsData, getIsSubsCancelingInProgress]);

  if (!isUserPremium) {
    return null;
  }

  return (
    <>
      <Box>
        {((isUserPremiumByPayment || isUserInAppPremium) && subscriptionType) ||
        isUserPremiumByFriendship ? (
          <Box component="span">
            <Form.InfoField label={t('fields.current-plan.label')}>
              <Box
                component="span"
                display="flex"
                justifyContent="space-between"
              >
                <b>
                  Premium
                  {subscriptionType?.includes('monthly')
                    ? `(${t('payment.monthly')})`
                    : subscriptionType?.includes('yearly') ||
                      subscriptionType?.includes('annual')
                    ? `(${t('payment.annual')})`
                    : ''}
                </b>
                {!!choosedPlan?.id && <span>{choosedPlan?.price}</span>}
                {!!subsData?.me?.inAppSubscription?.id && (
                  <span>
                    {subsData?.me?.inAppSubscription?.price}
                    {subsData?.me?.inAppSubscription?.currency}
                  </span>
                )}
              </Box>
              {(isUserPremiumByPayment ||
                isUserInAppPremium ||
                isUserPremiumByFriendship) && (
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="space-between"
                  component="span"
                >
                  <Typography variant="body1">
                    {!subsData?.me?.customer?.subscription?.requestCancel ||
                    subsData?.me?.inAppSubscription?.status !== 'canceled'
                      ? t(
                          isUserPremiumByFriendship
                            ? 'common.finishDate'
                            : 'payment.renews'
                        )
                      : t('payment.expires')}
                  </Typography>
                  <Typography variant="body1">
                    {DateTime.fromISO(premiumFinishDate).toFormat('dd/LL/yyyy')}
                  </Typography>
                </Box>
              )}
            </Form.InfoField>
            {!!subsDiscountsEnabled &&
              subscriptionType?.includes('monthly') &&
              !window?.ReactNativeWebView && (
                <Box mt={-2}>
                  <DiscountBox planId={subscriptionType} hideTotalAmount />
                </Box>
              )}
            {(isUserPremiumByPayment || isUserInAppPremium) && (
              <Box mt={7} display="grid" gridRowGap={16} component="span">
                {subsData?.me?.customer?.subscription?.requestCancel ||
                subsData?.me?.inAppSubscription?.status === 'canceled' ? (
                  <AppButton
                    fullWidth
                    color="primary"
                    variant="contained"
                    disabled={loadingRenewSubs || isRenewInProgress}
                    loading={loadingRenewSubs || isRenewInProgress}
                    onClick={handleRenewSubscription}
                  >
                    {t('payment.renew-subscription')}
                  </AppButton>
                ) : isSpecificProfession ||
                  (subscriptionType &&
                    !subscriptionType.includes('yearly') &&
                    !isSubsStatusIncomplete) ? (
                  <AppButton
                    fullWidth
                    color="primary"
                    variant="outlined"
                    disabled={isUpgradeInProgress}
                    loading={isUpgradeInProgress}
                    onClick={handleUpgradeSubscription}
                  >
                    {t('payment.upgrade-subscription')}
                  </AppButton>
                ) : (
                  ''
                )}
                {!subsData?.me?.customer?.subscription?.requestCancel &&
                  subsData?.me?.inAppSubscription?.status !== 'canceled' &&
                  !isSubsStatusIncomplete && (
                    <Box color="error.main" component="span">
                      <Button
                        fullWidth
                        color="inherit"
                        onClick={handleCancelSubscription}
                      >
                        {t('payment.cancel-subscription')}
                      </Button>
                    </Box>
                  )}
              </Box>
            )}
          </Box>
        ) : (
          currentCodeFinishDate && (
            <>
              <Typography variant="subtitle1" component="span">
                {t('common.preemium')}
              </Typography>
              <Typography variant="body1">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  component="span"
                  mt={2}
                >
                  <span>{t('payment.expires')}</span>
                  <span>{currentCodeFinishDate}</span>
                </Box>
              </Typography>
            </>
          )
        )}

        <SponsorBlock code={currentCode} />
        {!isUserInAppPremium && !isUserPremiumByPayment && (
          <Box pt={7} display="block" component="span">
            <Button
              fullWidth
              data-cy-re-activate-premium
              color="primary"
              variant="outlined"
              component={RouterLink}
              to={{
                pathname: getSubscriptionsPath(),
                state: {
                  prevPage: '/my-profile/network',
                },
              }}
            >
              {t('common.re-activate-premium')}
            </Button>
          </Box>
        )}
      </Box>
      <CancelingInProgress
        isSubsCancelingInProgress={isSubsCancelingInProgress}
      />
      <DialogCancelSubscription
        isOpened={cancelSubsVisible}
        onClose={setCancelSubsVisible}
        setSubsCancelingInProgress={setSubsCancelingInProgress}
      />
      <DialogManageSubscriptionOnOriginalPlatform
        onClose={setDialogManageSubsVisible}
        isOpened={dialogManageSubsVisible}
      />
    </>
  );
};
export default UserPremiumBlock;
