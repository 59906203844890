import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(4),
    background: theme.palette.primary.main,
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerQCount: {
    fontSize: 14,
    marginLeft: 15,
  },
  content: {
    minHeight: '-webkit-fill-available',
    padding: '76px 16px 28px',
    display: 'flex',
    flexDirection: 'column',
  },
  loadingWrap: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
