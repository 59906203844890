import CustomSnackbar from '@/screens/main/components/snack-bar';
import { datadogRum } from '@datadog/browser-rum';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ISnackbarData {
  visibility: boolean;
  message: string;
  messageOpts?: any;
  durationInMs?: number;
  isTimerIcon?: boolean;
}

interface ISnackbarContext {
  setCustomSnack: (data: ISnackbarData) => void;
}

export const SnackbarContext =
  React.createContext<ISnackbarContext | undefined>(undefined);

export const SnackbarProvider: React.FC = (props) => {
  const { children } = props;
  const { t } = useTranslation();

  const [customSnackVisibility, setCustomSnackVisibility] =
    useState<boolean>(false);
  const [customSnackMessage, setCustomSnackMessage] = useState<string | null>();
  const [customSnackMessageOpts, setCustomSnackMessageOpts] = useState<any>();
  const [customSnackDurationInMs, setCustomSnackDurationInMs] =
    useState<number>(5000);
  const [customSnackIsTimerIcon, setCustomSnackIsTimerIcon] =
    useState<boolean>(false);

  const setCustomSnack = useCallback((args: ISnackbarData) => {
    const { visibility, message, durationInMs, isTimerIcon, messageOpts } =
      args;
    setCustomSnackVisibility(visibility);
    setCustomSnackMessage(message);
    setCustomSnackDurationInMs(durationInMs || 5000);
    setCustomSnackIsTimerIcon(!!isTimerIcon);
    setCustomSnackMessageOpts(messageOpts);

    datadogRum.addAction('snackbar', {
      message: message,
      messageOpts: messageOpts,
    });
  }, []);

  return (
    <SnackbarContext.Provider
      value={{
        setCustomSnack,
      }}
    >
      {children}
      <CustomSnackbar
        open={customSnackVisibility}
        setOpen={setCustomSnackVisibility}
        message={t(customSnackMessage || '', customSnackMessageOpts)}
        durationInMs={customSnackDurationInMs}
        isLight={false}
        closeIcon={true}
        isTimerIcon={customSnackIsTimerIcon}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbarContext = () => {
  const state = useContext(SnackbarContext);
  if (!state) throw new Error('SnackbarContext not found');
  return state;
};
