import React from 'react';

interface PublicHeaderProps {
  text: string;
}

const HyperText: React.FC<PublicHeaderProps> = ({ children, text }) => (
  <>
    {text.split('{0}')[0]}
    {children}
    {text.split('{0}')[1]}
  </>
);

export default HyperText;
