import React, { useMemo } from 'react';

import { useUser } from '@/contexts/user-context';
import { useQuizDuel } from '@/hooks/useQuizDuel';
import { ActiveQuizDuelsType } from '@/graphql';
import DuelItem from '@/screens/main/components/duel-item';
import { List } from '@material-ui/core';

type DuelsListProps = {
  duelsListData?: ActiveQuizDuelsType[] | null | undefined;
  showOnlyYourTurn?: boolean | null | undefined;
  sessionId: string;
  duelDuration?: number | null | undefined;
};

const DuelsList: React.FC<DuelsListProps> = ({
  duelsListData,
  showOnlyYourTurn,
  sessionId,
  duelDuration,
}) => {
  const { user } = useUser();
  const { openDuel, loading: openLoading } = useQuizDuel({
    sessionId: sessionId,
  });

  const activeDuels = useMemo(() => duelsListData || [], [duelsListData]);
  const duelsList = useMemo(() => {
    const active = activeDuels.filter((it) => it && it.round && it.round.duel);

    return active.map((it, idx) => {
      if (it?.round && it?.round.duel) {
        const isNew =
          it.round.roundNumber === 1 &&
          !it.round.userQuestions.some((x) => !!x.userAnswer);

        const youTurn =
          it.round.userQuestions.filter(
            (x) => x.user?.id === user?.id && !!x.userAnswer?.id
          ).length !== 3 ||
          it.round.userQuestions.filter((x) => !!x.userAnswer?.id).length === 6;

        const handleSelect = () => openDuel(it.round?.duel?.id);

        return (showOnlyYourTurn ? youTurn : true) ? (
          <DuelItem
            data-cy-duel-item
            youTurn={youTurn}
            isNew={isNew}
            isInitiator
            key={it.round.id}
            duelId={it.id}
            sessionId={sessionId}
            username={it.opponent?.username}
            avatar={it.opponent?.avatar ?? undefined}
            created={it.round.created}
            loading={openLoading}
            timeLeftInMinutes={duelDuration}
            divider={idx !== active.length - 1}
            highlightDisabled
            onSelect={handleSelect}
            shouldNegativeIntervalCleared
          />
        ) : (
          false
        );
      }

      return null;
    });
  }, [
    activeDuels,
    openDuel,
    openLoading,
    user,
    duelDuration,
    showOnlyYourTurn,
    sessionId,
  ]);

  return <List>{duelsList}</List>;
};

export default DuelsList;
