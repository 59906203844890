import React from 'react';
import { useAuth } from '@/contexts/auth-context';
import { useSignUp } from '@/contexts/sign-up-context';
import { useSignUpMutation } from '@/graphql';

import ComfirmEmail from '../confirm-email';
import { useHistory } from 'react-router-dom';

const Completed: React.FC = () => {
  const history = useHistory();
  // professionType added here to execulde this value from singUpValues
  const { update, clean, professionType, ...singUpValues } = useSignUp();
  const { signIn, signOut } = useAuth();

  const [registration, { data }] = useSignUpMutation();

  const params = new URLSearchParams(history.location.search);
  const deepLinkHash = params.get('hash');
  const sharingSenderId = params.get('sharingSenderId');

  React.useEffect(() => {
    const signUp = async () => {
      try {
        signOut();
        const cleanedSingUpValues = JSON.parse(JSON.stringify(singUpValues));
        if (deepLinkHash) {
          cleanedSingUpValues.deepLinkHash = deepLinkHash;
          cleanedSingUpValues.sharingSenderId = sharingSenderId;
        }
        if (sharingSenderId) {
          cleanedSingUpValues.sharingSenderId = sharingSenderId;
        }
        const { data } = await registration({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          variables: cleanedSingUpValues as any,
        });

        if (data?.userRegistration?.token) {
          // clear all registration Data
          sessionStorage.clear();
          signIn(data.userRegistration.token);
        }
      } catch (error) {
        console.error('REGISTRATION', error);
      } finally {
        clean();
      }
    };

    signUp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ComfirmEmail
      email={data?.userRegistration?.user?.email || singUpValues?.email}
      deepLinkHash={deepLinkHash}
    />
  );
};

export default Completed;
