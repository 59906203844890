import React from 'react';
import { useParams } from 'react-router-dom';

import { useClassRoomStatisticsQuery } from '@/graphql';
import { useStyles } from './styles';
import { Skeleton } from '@material-ui/lab';
import { Box, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import StackLayout from '../../../components/stack-layout';

type ClassRoomParams = {
  sessionId: string;
  userSessionSettingId: string;
};

const ClassRoomActivityDetails = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { sessionId, userSessionSettingId } = useParams<ClassRoomParams>();
  const { data, loading } = useClassRoomStatisticsQuery({
    variables: { sessionId, userSessionSettingsId: userSessionSettingId },
    fetchPolicy: 'network-only',
  });

  return (
    <StackLayout title={t('classrooms.detailed-results')}>
      <Paper className={classes.content}>
        {loading ? (
          <Box py={5} px={6}>
            {[...Array(15)].map((i, idx) => (
              <Skeleton key={idx} height={30} />
            ))}
          </Box>
        ) : (
          <>
            <Box px={4} py={5}>
              <Box display="flex" justifyContent="space-between" py={2}>
                <Typography variant="body1">
                  {t('classrooms.answered-questions')}
                </Typography>
                <Typography variant="body1">
                  {data?.userQuestionsCountData?.questionCount}/
                  {data?.userQuestionsCountData?.questionCountTotal}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" py={2}>
                <Typography
                  variant="body1"
                  className={classes.textSecondaryColor}
                >
                  {t('classrooms.correct')}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.textSecondaryColor}
                >
                  {data?.userQuestionsCountData?.correctAnswerCount}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" py={2}>
                <Typography
                  variant="body1"
                  className={classes.textSecondaryColor}
                >
                  {t('classrooms.not-correct')}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.textSecondaryColor}
                >
                  {data?.userQuestionsCountData?.wrongAnswerCount}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" py={2}>
                <Typography variant="body1">
                  {t('classrooms.questions-repeatedly')}
                </Typography>
                <Typography variant="body1">
                  {data?.userQuestionsCountData?.questionCountRepeat}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" py={2}>
                <Typography
                  variant="body1"
                  className={classes.textSecondaryColor}
                >
                  {t('classrooms.questions-repeated-correct')}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.textSecondaryColor}
                >
                  {data?.userQuestionsCountData?.correctAnswerCountRepeat}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mt={8}>
                <Typography variant="subtitle1">
                  {t('classrooms.your-activity')}
                </Typography>
                <Typography variant="subtitle1">
                  {data?.userQuestionsCountData?.generalQuestionCount} MCQs
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mt={1}>
                <Typography
                  variant="caption"
                  className={classes.textSecondaryColor}
                >
                  {t('classrooms.total-answered')}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Paper>
    </StackLayout>
  );
};

export default ClassRoomActivityDetails;
