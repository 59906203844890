import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Box, Paper, Typography } from '@material-ui/core';
import { useRelativeTime } from '@/hooks/useTime';
import {
  QT_RANKING_REFRESH_INTERVAL_MS,
  QT_TIME_TO_FINAL_RANKING_HOURS,
} from '@/utils/constants';

interface TimeToFinalRankingProps {
  finishDateTime: string;
}

const TimeToFinalRanking: React.FC<TimeToFinalRankingProps> = ({
  finishDateTime,
}) => {
  const { t } = useTranslation();

  const { timeLeft } = useRelativeTime(
    DateTime.fromISO(finishDateTime),
    {
      hours: QT_TIME_TO_FINAL_RANKING_HOURS,
    },
    QT_RANKING_REFRESH_INTERVAL_MS,
    true
  );

  return (
    <>
      <Paper square elevation={0}>
        <Box textAlign="center" px={7} py={3}>
          <Typography variant="caption">
            {t('quiztime.final-ranking')} {timeLeft}
          </Typography>
        </Box>
      </Paper>
    </>
  );
};

export default TimeToFinalRanking;
