import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import StackLayout from '../../../components/stack-layout';
import LekResults from '../../lek-results';

type ClassRoomLekParams = {
  sessionId: string;
};

const ClassRoomLekResults = () => {
  const { t } = useTranslation();
  const { sessionId } = useParams<ClassRoomLekParams>();

  return (
    <StackLayout title={t('lek.your-result')}>
      <LekResults sessionId={sessionId} singlePage />
    </StackLayout>
  );
};

export default ClassRoomLekResults;
