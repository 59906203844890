import React, { useCallback, useMemo } from 'react';
import { useParams, useHistory, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  useClassRoomDetailsQuery,
  ClassRoomLearningSessionStatus,
} from '@/graphql';
import { Skeleton } from '@material-ui/lab';
import { Box, Link, Paper, Typography } from '@material-ui/core';
import ClassRoomHeader from '../../header';
import ClassRoomDescription from '../../description';
import Lectures from '../../lectures';
import StackLayout from '../../../components/stack-layout';
import AppButton from '@/components/app-button';
import EmptyState from '@/screens/deep-links/empty-state';
import { useStyles } from './styles';
import { MAIN_ORGANIZER_NAME } from '@/utils/constants';

type ClassRoomParams = {
  sessionId: string;
  hash: string;
};

const PublicClassRoomDetails: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const { sessionId, hash } = useParams<ClassRoomParams>();
  const { data, loading, error } = useClassRoomDetailsQuery({
    variables: { sessionId },
    fetchPolicy: 'cache-and-network',
  });

  const handleJoinClassroom = useCallback(async () => {
    history.push(`/auth/?hash=${hash}`);
  }, [hash, history]);

  const classroom = useMemo(
    () => data?.classRoomLearningSession?.classRoom,
    [data]
  );
  const organizer = useMemo(() => classroom?.organizer, [classroom]);

  const pageTitle = useMemo(() => {
    switch (data?.classRoomLearningSession?.classRoom.enrollmentType) {
      case 1:
        return t('classrooms.single-page-title-blended');
      case 2:
        return t('classrooms.single-page-title-network');
      case 3:
        return t('classrooms.single-page-title-lek');
      default:
        return '';
    }
  }, [data, t]);

  if (error?.message) return <EmptyState />;

  return (
    <StackLayout title={pageTitle} back={`/auth/?hash=${hash}`}>
      <ClassRoomHeader
        image={
          (organizer?.name === MAIN_ORGANIZER_NAME &&
            classroom?.backgroundImage) ||
          classroom?.classRoomImage
        }
        startDate={data?.classRoomLearningSession?.startDate}
        finishDate={data?.classRoomLearningSession?.finishDate}
        title={classroom?.title}
        organizerName={organizer?.name}
        organizerId={organizer?.id}
        certificate={classroom?.certificate?.points}
        isForPremiumOnly={data?.classRoomLearningSession?.isForPremiumOnly}
        duration={data?.classRoomLearningSession?.duration}
        classroomType={classroom?.enrollmentType}
        deepLinkHash={data?.classRoomLearningSession?.deepLink}
      />
      <div className={classes.content}>
        {loading ? (
          <Paper>
            <Box py={5} px={6}>
              {[...Array(15)].map((i, idx) => (
                <Skeleton key={idx} height={30} />
              ))}
            </Box>
          </Paper>
        ) : (
          <>
            <ClassRoomDescription
              description={
                data?.classRoomLearningSession?.classRoom?.description
              }
              link={
                data?.classRoomLearningSession?.classRoom?.learningMoreInfoLink
              }
              orgId={organizer?.id}
              orgName={organizer?.name}
              orgEmail={organizer?.email}
              orgLogo={organizer?.logo}
              orgWebsite={organizer?.linkToSite}
              sponsors={data?.classRoomLearningSession?.classRoom.sponsors}
              isCRPublic
            >
              <Lectures sessionId={data?.classRoomLearningSession?.id} />
              {data?.classRoomLearningSession?.status !==
                ClassRoomLearningSessionStatus.Finished && (
                <Box px={2}>
                  <AppButton
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={handleJoinClassroom}
                  >
                    {t('classrooms.join-link2')}
                  </AppButton>
                  {!!classroom?.isExternalOrganizer && (
                    <Box mt={2}>
                      <Typography variant="caption">
                        {t('classrooms.external-organizer.message')}
                        <Link
                          variant="caption"
                          color="primary"
                          to="/privacy-dataprotection"
                          component={RouterLink}
                        >
                          {t('classrooms.external-organizer.privacy')}
                        </Link>
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </ClassRoomDescription>
          </>
        )}
      </div>
    </StackLayout>
  );
};

export default PublicClassRoomDetails;
