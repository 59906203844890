import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: '44px',
    width: '82px',
  },
});

const SmallLogoIcon: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 82 44"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path
        d="M10.546 42.7778C25.362 27.9778 5.69068 18.9491 17.5401 5.13333C2.85303 17.3871 18.72 26.6174 10.546 42.7778Z"
        fill="#3498DB"
      />
      <path
        d="M18.2636 1.95555C8.51746 8.61181 1.27905 16.2817 12.0245 31.2889C6.42557 18.0088 9.21382 8.96708 18.2636 1.95555Z"
        fill="#2C3E50"
      />
      <path
        d="M14.8146 13.4445C21.778 15.8387 9.9094 23.2011 1.14014 23.4667C14.4489 23.1093 25.7816 13.6284 14.8146 13.4445Z"
        fill="#2C3E50"
      />
      <path
        d="M16.9264 17.8445C23.9826 20.2387 11.9558 27.6011 3.06958 27.8667C16.5558 27.5093 28.0396 18.0284 16.9264 17.8445Z"
        fill="#2C3E50"
      />
      <path
        d="M39.0518 15.84V28.0246H37.067V22.6007H30.9345V28.0246H28.9412V15.84H30.9345V21.1707H37.067V15.84H39.0518Z"
        fill="#2C3E50"
      />
      <path
        d="M48.7522 19.3938V28.0246H47.6495C47.4064 28.0246 47.2509 27.9089 47.183 27.6777L47.0473 26.8738C46.8663 27.0656 46.6741 27.2405 46.4705 27.3984C46.2726 27.5564 46.0605 27.6918 45.8344 27.8046C45.6082 27.9174 45.365 28.0048 45.1049 28.0669C44.8504 28.1289 44.5762 28.16 44.2821 28.16C43.8071 28.16 43.3887 28.081 43.0268 27.923C42.6649 27.7651 42.3595 27.5423 42.1107 27.2546C41.8676 26.9669 41.6838 26.6228 41.5594 26.2223C41.435 25.8161 41.3728 25.3705 41.3728 24.8854V19.3938H43.1964V24.8854C43.1964 25.4664 43.3293 25.9177 43.5951 26.2392C43.8665 26.5607 44.2765 26.7215 44.825 26.7215C45.2265 26.7215 45.5997 26.6312 45.9446 26.4507C46.2952 26.2646 46.6232 26.0107 46.9286 25.6892V19.3938H48.7522Z"
        fill="#2C3E50"
      />
      <path
        d="M50.9668 28.0246V19.3938H52.0695C52.3183 19.3938 52.4767 19.5095 52.5445 19.7407L52.6633 20.4938C52.8216 20.3133 52.9856 20.1497 53.1552 20.003C53.3249 19.8507 53.5058 19.721 53.6981 19.6138C53.896 19.501 54.1052 19.4136 54.3258 19.3515C54.5519 19.2895 54.7951 19.2584 55.0552 19.2584C55.3323 19.2584 55.5868 19.2979 55.8186 19.3769C56.0505 19.4559 56.254 19.5687 56.4293 19.7154C56.6103 19.862 56.7658 20.0397 56.8958 20.2484C57.0259 20.4571 57.1333 20.6912 57.2182 20.9507C57.3482 20.6518 57.5122 20.3951 57.7101 20.1807C57.908 19.9664 58.1286 19.7915 58.3717 19.6561C58.6149 19.5207 58.8722 19.422 59.1436 19.36C59.4207 19.2923 59.7006 19.2584 59.9833 19.2584C60.447 19.2584 60.8598 19.3318 61.2217 19.4784C61.5893 19.6251 61.8975 19.8395 62.1463 20.1215C62.4007 20.3979 62.593 20.7392 62.723 21.1454C62.8531 21.5515 62.9181 22.0141 62.9181 22.533V28.0246H61.0945V22.533C61.0945 21.9238 60.9616 21.4669 60.6958 21.1623C60.4357 20.852 60.0484 20.6969 59.5338 20.6969C59.3019 20.6969 59.0842 20.7364 58.8807 20.8154C58.6771 20.8943 58.499 21.01 58.3463 21.1623C58.1936 21.3146 58.072 21.5064 57.9815 21.7377C57.8967 21.9633 57.8543 22.2284 57.8543 22.533V28.0246H56.0307V22.533C56.0307 21.9012 55.9034 21.4387 55.649 21.1454C55.3945 20.8464 55.0213 20.6969 54.5293 20.6969C54.19 20.6969 53.8762 20.7843 53.5878 20.9592C53.2994 21.1284 53.0336 21.3625 52.7905 21.6615V28.0246H50.9668Z"
        fill="#2C3E50"
      />
      <path
        d="M70.723 22.7361C70.723 22.4315 70.6778 22.1495 70.5873 21.89C70.5025 21.6248 70.3752 21.3964 70.2056 21.2046C70.036 21.0071 69.8239 20.8548 69.5694 20.7477C69.3206 20.6348 69.0322 20.5784 68.7043 20.5784C68.0427 20.5784 67.5224 20.7674 67.1436 21.1454C66.7647 21.5233 66.5244 22.0536 66.4226 22.7361H70.723ZM66.3717 23.853C66.3943 24.3438 66.4706 24.7697 66.6007 25.1307C66.7308 25.4861 66.9032 25.7823 67.1181 26.0192C67.3386 26.2561 67.5988 26.4338 67.8985 26.5523C68.2038 26.6651 68.5431 26.7215 68.9163 26.7215C69.2726 26.7215 69.5808 26.682 69.8409 26.603C70.101 26.5184 70.3272 26.4282 70.5194 26.3323C70.7117 26.2307 70.8757 26.1405 71.0114 26.0615C71.1471 25.9769 71.2715 25.9346 71.3846 25.9346C71.5316 25.9346 71.6447 25.991 71.7239 26.1038L72.2413 26.7723C72.0264 27.0261 71.7833 27.2405 71.5118 27.4153C71.2404 27.5902 70.952 27.7341 70.6467 27.8469C70.3413 27.9541 70.0275 28.0302 69.7052 28.0754C69.3828 28.1261 69.069 28.1515 68.7636 28.1515C68.1642 28.1515 67.6072 28.0528 67.0927 27.8554C66.5837 27.6523 66.1398 27.3561 65.761 26.9669C65.3878 26.5777 65.0937 26.0954 64.8788 25.52C64.6696 24.9446 64.565 24.2818 64.565 23.5315C64.565 22.9336 64.6583 22.3751 64.8449 21.8561C65.0372 21.3371 65.3086 20.8859 65.6592 20.5023C66.0154 20.1187 66.4452 19.8169 66.9485 19.5969C67.4574 19.3712 68.0314 19.2584 68.6703 19.2584C69.2019 19.2584 69.6938 19.3459 70.1462 19.5207C70.5986 19.69 70.9888 19.941 71.3168 20.2738C71.6447 20.6066 71.8992 21.0156 72.0802 21.5007C72.2668 21.9802 72.3601 22.5274 72.3601 23.1423C72.3601 23.4243 72.329 23.6133 72.2668 23.7092C72.2046 23.8051 72.0915 23.853 71.9275 23.853H66.3717Z"
        fill="#2C3E50"
      />
      <path
        d="M77.8013 19.2584C78.4403 19.2584 79.0171 19.3628 79.5317 19.5715C80.0519 19.7802 80.493 20.0764 80.8549 20.46C81.2225 20.8436 81.5052 21.3089 81.7031 21.8561C81.901 22.4033 82 23.0182 82 23.7007C82 24.3833 81.901 24.9982 81.7031 25.5454C81.5052 26.0925 81.2225 26.5607 80.8549 26.95C80.493 27.3336 80.0519 27.6297 79.5317 27.8384C79.0171 28.0471 78.4403 28.1515 77.8013 28.1515C77.1567 28.1515 76.5743 28.0471 76.054 27.8384C75.5394 27.6297 75.0984 27.3336 74.7308 26.95C74.3633 26.5607 74.0805 26.0925 73.8826 25.5454C73.6847 24.9982 73.5857 24.3833 73.5857 23.7007C73.5857 23.0182 73.6847 22.4033 73.8826 21.8561C74.0805 21.3089 74.3633 20.8436 74.7308 20.46C75.0984 20.0764 75.5394 19.7802 76.054 19.5715C76.5743 19.3628 77.1567 19.2584 77.8013 19.2584ZM77.8013 26.73C78.5817 26.73 79.1613 26.4705 79.5402 25.9515C79.9247 25.4269 80.117 24.6795 80.117 23.7092C80.117 22.7389 79.9247 21.9915 79.5402 21.4669C79.1613 20.9366 78.5817 20.6715 77.8013 20.6715C77.0097 20.6715 76.4216 20.9366 76.0371 21.4669C75.6525 21.9915 75.4603 22.7389 75.4603 23.7092C75.4603 24.6795 75.6525 25.4269 76.0371 25.9515C76.4216 26.4705 77.0097 26.73 77.8013 26.73Z"
        fill="#2C3E50"
      />
    </SvgIcon>
  );
};

export default SmallLogoIcon;
