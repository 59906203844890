import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
  },
  cupIconBox: {
    padding: 8,
    background: '#E2F5FD',
    borderRadius: 4,
  },
  cupIcon: {
    height: 40,
    width: 40,
  },
  dayCircle: {
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 32,
    height: 32,
    marginLeft: 6,
    marginRight: 6,
    borderRadius: '50%',
  },
  dayCircleCheck: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: -1,
    bottom: -10,
    width: 16,
    height: 16,
    background: theme.palette.primary.main,
    borderRadius: '50%',
    border: '1px solid #FFFFFF',
    color: '#fff',
    '&>svg': {
      fontSize: 10,
    },
  },
}));
