import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import {
  UserMeQuery,
  useUpdateRulesSettingsMutation,
  useUserMeQuery,
} from '@/graphql';
import { USER_SETTINGS_FRAGMENT } from '@/apollo/fragments';
import {
  GetActiveQuizDuelsDocument,
  GetUserQuizSessionSettingsDocument,
  useCreateQuiztimeDuelByOpponentMutation,
  useCreateQuiztimeDuelMutation,
} from '@/graphql';
import {
  useDuelCreateError3Modal,
  useDuelCreateError4Modal,
  useDuelCreateError5Modal,
} from '@/hooks/useModal';
import { DuelErrorTypes } from '@/type';
import { ME_QUERY } from '@/apollo/queries';
import { useSnackbarContext } from '@/contexts/snackbar-context';
import { useQuiztimeDuelRulesFullScreenModal } from './useQuiztimeDuelRulesFullScreenModal';
import { useDuelLimitModal } from './useDuelLimitModal';

export const useCreateQuizDuel = ({
  opponentId = '',
  sessionId = '',
  requiresQuizSettingsRefetch = false,
}) => {
  const history = useHistory();
  const { cache } = useApolloClient();
  const { data } = useUserMeQuery();
  const [update, { loading }] = useUpdateRulesSettingsMutation();
  const quiztimeDuelRulesFullScreenModal = useQuiztimeDuelRulesFullScreenModal(
    sessionId,
    () => setQuizDuelRulesRead()
  );

  const duelLimitModal = useDuelLimitModal();
  const duelCreateError3Modal = useDuelCreateError3Modal();
  const duelCreateError4Modal = useDuelCreateError4Modal();
  const duelCreateError5Modal = useDuelCreateError5Modal();
  const { setCustomSnack } = useSnackbarContext();

  const [createQuizDuel, { loading: creatingLoading }] =
    useCreateQuiztimeDuelMutation({
      variables: {
        quizSessionId: sessionId,
      },
    });

  const [createQuizDuelByOpponent, { loading: creatingLoading2 }] =
    useCreateQuiztimeDuelByOpponentMutation({
      variables: {
        quizSessionId: sessionId,
        opponentId: opponentId,
      },
    });

  const handleMutationError = useCallback(
    (errorMessage: string) => {
      switch (errorMessage) {
        case DuelErrorTypes.ERROR_1:
          setCustomSnack({
            visibility: true,
            message: 'quiztime.snackBars.snack-message-no-opponent-for-qt-duel',
          });
          break;
        case DuelErrorTypes.ERROR_3:
          duelCreateError3Modal.open();
          break;
        case DuelErrorTypes.ERROR_4:
          duelCreateError4Modal.open();
          break;
        case DuelErrorTypes.ERROR_5:
          duelCreateError5Modal.open();
          break;
        case DuelErrorTypes.ERROR_8:
          break;
        default:
          duelLimitModal.open();
          break;
      }
    },
    [
      duelCreateError3Modal,
      duelCreateError4Modal,
      duelCreateError5Modal,
      duelLimitModal,
      setCustomSnack,
    ]
  );

  const handleCreateQuizDuel = useCallback(async () => {
    const userData: UserMeQuery | undefined | null = cache.readQuery({
      query: ME_QUERY,
    });
    const isQuizDuelRulesRead = userData?.me?.settings?.isQuizDuelRulesRead;
    if (isQuizDuelRulesRead === false) {
      quiztimeDuelRulesFullScreenModal.open();
      return;
    }
    try {
      let duelId = '';
      if (opponentId) {
        const { data } = await createQuizDuelByOpponent({
          refetchQueries: !requiresQuizSettingsRefetch
            ? [
                {
                  query: GetActiveQuizDuelsDocument,
                  variables: { quizSessionId: sessionId },
                },
              ]
            : [
                {
                  query: GetActiveQuizDuelsDocument,
                  variables: { quizSessionId: sessionId },
                },
                {
                  query: GetUserQuizSessionSettingsDocument,
                  variables: { quizSessionId: sessionId },
                },
              ],
        });
        duelId = data?.createQuizDuelByOpponent?.duel?.id;
      } else {
        const { data } = await createQuizDuel({
          refetchQueries: !requiresQuizSettingsRefetch
            ? [
                {
                  query: GetActiveQuizDuelsDocument,
                  variables: { quizSessionId: sessionId },
                },
              ]
            : [
                {
                  query: GetActiveQuizDuelsDocument,
                  variables: { quizSessionId: sessionId },
                },
                {
                  query: GetUserQuizSessionSettingsDocument,
                  variables: { quizSessionId: sessionId },
                },
              ],
        });
        duelId = data?.createQuizDuel?.duel?.id;
      }
      history.push(
        `/quiz-duel/${duelId}/create-round${
          sessionId ? '/session/' + sessionId : ''
        }`
      );
    } catch (error) {
      console.error('useCreateQuiztimeDuelMutation', error);
      handleMutationError(
        error?.networkError?.result?.errors[0]?.message || error?.message
      );
    }
  }, [
    history,
    cache,
    createQuizDuel,
    createQuizDuelByOpponent,
    opponentId,
    sessionId,
    handleMutationError,
    requiresQuizSettingsRefetch,
    quiztimeDuelRulesFullScreenModal,
  ]);

  const setQuizDuelRulesRead = useCallback(async () => {
    const userData: UserMeQuery | undefined | null = cache.readQuery({
      query: ME_QUERY,
    });
    const isQuizDuelRulesRead = userData?.me?.settings?.isQuizDuelRulesRead;
    if (isQuizDuelRulesRead === false && !loading) {
      cache.writeFragment({
        id: `${data?.me?.settings?.__typename}:${data?.me?.settings?.id}`,
        fragment: USER_SETTINGS_FRAGMENT,
        data: {
          ...data?.me?.settings,
          isQuizDuelRulesRead: true,
        },
      });
      try {
        await update({
          variables: {
            isQuizDuelRulesRead: true,
          },
        });
      } catch (err) {
        console.error('UPDATE_ROOLS_SETTINGS_MUTATION', err);
      } finally {
        quiztimeDuelRulesFullScreenModal.close();
        handleCreateQuizDuel();
      }
    }
  }, [
    data,
    cache,
    quiztimeDuelRulesFullScreenModal,
    loading,
    update,
    handleCreateQuizDuel,
  ]);

  return {
    handleCreateQuizDuel,
    creatingLoading: creatingLoading || creatingLoading2,
  };
};
