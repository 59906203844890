import React from 'react';
import { Box, FormControl, FormLabel, makeStyles } from '@material-ui/core';
import { Rating, RatingProps as MuiRatingProps } from '@material-ui/lab';
import { FieldError } from 'react-hook-form';
import Error from '../error';
import { StarBorderOutlined } from '@material-ui/icons';

type RatingFieldProps = MuiRatingProps & {
  fullWidth?: boolean;
  errors?: FieldError;
  label?: string;
};

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 43px)',
    '& .MuiRating-icon': {
      transform: 'scale(1)!important',
    },
  },
  iconHover: {
    color: '#ffb400',
  },
  iconFocus: {
    color: '#ffb400',
  },
}));

const RatingField: React.FC<RatingFieldProps> = ({
  errors = null,
  label,
  fullWidth,
  disabled,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <FormControl component="fieldset" disabled={disabled} fullWidth={fullWidth}>
      {label && (
        <Box marginBottom={4}>
          <FormLabel component="legend">{label}</FormLabel>
        </Box>
      )}
      <Rating
        color="primary"
        className={classes.labelContainer}
        emptyIcon={<StarBorderOutlined fontSize="inherit" />}
        classes={{
          iconFocus: classes.iconFocus,
          iconHover: classes.iconHover,
        }}
        {...rest}
      />
      <Error errors={errors} />
    </FormControl>
  );
};

export default RatingField;
