import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(14),
    minHeight: '-webkit-fill-available',
  },
  close: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    position: 'absolute',
  },
  listItem: {
    display: 'flex',
    marginTop: 16,
  },
  infoBox: {
    marginTop: 16,
    marginBottom: 16,
    padding: '12px 16px',
    background: '#E2F5FD',
    borderRadius: 6,
  },
  successScale: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px 16px',
    gap: 33,
    background: theme.palette.primary.main,
    borderRadius: 6,
  },
  successScaleItem: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    color: theme.palette.primary.main,
    borderRadius: '100%',
    background: '#FFF',
    borderWidth: 2,
    borderColor: '#E6EEFA',
  },
  successScaleItemDefault: {
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    color: '#E6EEFA',
  },
  circleIcon: {
    width: 14,
    height: 14,
    bottom: '-8px',
    right: '-4px',
    position: 'absolute',
  },
  iconBox: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 40,
    height: 40,
    background: '#E2F5FD',
    borderRadius: 4,
    marginRight: 20,
    color: theme.palette.primary.dark,
  },
}));
