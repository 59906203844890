import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 304;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
    width: drawerWidth,
    minHeight: 0,
  },
  menuItem: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  menuSubItem: {
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
  },
  action: {
    color: theme.palette.primary.contrastText,
    textTransform: 'none',
    textAlign: 'left',
  },
  subAction: {
    color: `rgba(255, 255, 255, 0.6)`,
    textTransform: 'none',
    textAlign: 'left',
  },
  header: {
    display: 'block',
    width: '100%',
  },
  content: {
    display: 'grid',
    gridTemplateRows: '1fr auto',
    height: 'calc(100vh - 201px)',
    minHeight: 0,
    overflow: 'auto',
    marginBottom: 56,
  },
  list: {
    paddingTop: theme.spacing(4),
  },
  footer: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    height: 55,
  },
  addIconTitle: {
    color: theme.palette.text.primary,
    textTransform: 'none',
    textAlign: 'left',
    marginLeft: 2,
  },
}));
