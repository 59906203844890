import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: '200px',
    width: '200px',
  },
});

const BattleIcon: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 200 200"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <g clipPath="url(#clip0_23273_214898)">
        <path
          d="M7.64 147.586H18.7467H39.1089C43.0491 147.586 46.2489 144.413 46.2489 140.44V140.414C46.2489 136.441 43.0491 133.188 39.1089 133.188H18.7467V133.242C14.8065 133.242 11.6067 130.042 11.6067 126.07C11.6067 122.097 14.8065 118.844 18.7467 118.844V118.791H24.8289C28.7691 118.791 31.9689 115.618 31.9689 111.646V111.619C31.9689 107.647 28.7691 104.394 24.8289 104.394H18.7467H10.2845C6.34422 104.394 3.14445 101.221 3.14445 97.2485V97.2218C3.14445 93.2493 6.34422 89.9966 10.2845 89.9966H24.0885C26.7593 76.1325 33.4762 62.5351 44.2656 51.6572C71.0538 24.649 113.391 22.916 142.189 46.3782L166.28 46.005C170.22 45.9516 173.447 49.151 173.5 53.1236C173.5 53.9768 173.367 54.8033 173.103 55.5765C172.151 58.3493 169.533 60.3755 166.439 60.4022L153.984 60.6688C150.043 60.7222 146.87 63.9749 146.923 67.9474C146.976 71.92 150.202 75.1194 154.142 75.0661L173.314 74.7995L193.227 74.5328C197.22 74.5062 200.446 77.6789 200.499 81.6515C200.552 85.6241 197.379 88.8768 193.439 88.9301L173.526 89.1967L168.634 89.2767C164.694 89.33 161.52 92.5827 161.573 96.5553C161.626 100.528 164.852 103.727 168.792 103.674L173.685 103.567L176.329 103.514C180.269 103.461 183.496 106.66 183.548 110.633C183.601 114.605 180.428 117.858 176.488 117.911L168.184 118.045C165.513 132.069 158.796 145.426 148.007 156.277C121.457 183.099 79.4896 185.178 50.6916 161.983H7.64C3.69978 161.983 0.5 158.81 0.5 154.838V154.811C0.5 150.838 3.69978 147.586 7.64 147.586Z"
          fill="#E6EEFA"
        />
        <path
          d="M73.3007 168.8H63.7006C63.2473 168.8 62.9006 169.147 62.9006 169.6C62.9006 170.053 63.2473 170.4 63.7006 170.4H73.3007C73.7541 170.4 74.1007 170.053 74.1007 169.6C74.1007 169.147 73.7541 168.8 73.3007 168.8Z"
          fill="#2C3E50"
        />
        <path
          d="M95.7007 168.8H78.1008C77.6474 168.8 77.3008 169.147 77.3008 169.6C77.3008 170.053 77.6474 170.4 78.1008 170.4H95.7007C96.1541 170.4 96.5007 170.053 96.5007 169.6C96.5007 169.147 96.1541 168.8 95.7007 168.8Z"
          fill="#2C3E50"
        />
        <path
          d="M162.901 144.8H156.501C156.047 144.8 155.701 145.147 155.701 145.6C155.701 146.053 156.047 146.4 156.501 146.4H162.901C163.354 146.4 163.701 146.053 163.701 145.6C163.701 145.147 163.354 144.8 162.901 144.8Z"
          fill="#2C3E50"
        />
        <path
          d="M185.301 144.8H167.701C167.247 144.8 166.901 145.147 166.901 145.6C166.901 146.053 167.247 146.4 167.701 146.4H185.301C185.754 146.4 186.101 146.053 186.101 145.6C186.101 145.147 185.754 144.8 185.301 144.8Z"
          fill="#2C3E50"
        />
        <path
          d="M176.501 140H163.701C163.248 140 162.901 140.347 162.901 140.8C162.901 141.253 163.248 141.6 163.701 141.6H176.501C176.954 141.6 177.301 141.253 177.301 140.8C177.301 140.347 176.954 140 176.501 140Z"
          fill="#2C3E50"
        />
        <path
          d="M81.3008 173.6H42.1008C41.6474 173.6 41.3008 173.947 41.3008 174.4C41.3008 174.853 41.6474 175.2 42.1008 175.2H81.3008C81.7541 175.2 82.1008 174.853 82.1008 174.4C82.1008 173.947 81.7541 173.6 81.3008 173.6Z"
          fill="#2C3E50"
        />
        <path
          d="M90.9006 173.6H87.7006C87.2473 173.6 86.9006 173.947 86.9006 174.4C86.9006 174.853 87.2473 175.2 87.7006 175.2H90.9006C91.354 175.2 91.7006 174.853 91.7006 174.4C91.7006 173.947 91.354 173.6 90.9006 173.6Z"
          fill="#2C3E50"
        />
        <path
          d="M94.1006 27.2001H91.7006C91.2473 27.2001 90.9006 27.5467 90.9006 28.0001C90.9006 28.4534 91.2473 28.8001 91.7006 28.8001H94.1006C94.554 28.8001 94.9006 28.4534 94.9006 28.0001C94.9006 27.5467 94.554 27.2001 94.1006 27.2001Z"
          fill="#2C3E50"
        />
        <path
          d="M106.101 27.2001H103.701C103.247 27.2001 102.901 27.5467 102.901 28.0001C102.901 28.4534 103.247 28.8001 103.701 28.8001H106.101C106.554 28.8001 106.901 28.4534 106.901 28.0001C106.901 27.5467 106.554 27.2001 106.101 27.2001Z"
          fill="#2C3E50"
        />
        <path
          d="M94.4474 22.4267C94.1274 22.1067 93.6207 22.1067 93.3274 22.4267C93.0074 22.7467 93.0074 23.2534 93.3274 23.5467L95.0341 25.2534C95.1941 25.4134 95.4074 25.4934 95.5941 25.4934C95.8074 25.4934 95.9941 25.4134 96.1541 25.2534C96.4741 24.9334 96.4741 24.4267 96.1541 24.1334L94.4474 22.4267Z"
          fill="#2C3E50"
        />
        <path
          d="M102.954 30.9067C102.634 30.5867 102.127 30.5867 101.834 30.9067C101.514 31.2267 101.514 31.7333 101.834 32.0267L103.541 33.7333C103.701 33.8933 103.914 33.9733 104.101 33.9733C104.287 33.9733 104.501 33.8933 104.661 33.7333C104.981 33.4133 104.981 32.9067 104.661 32.6133L102.954 30.9067Z"
          fill="#2C3E50"
        />
        <path
          d="M98.9006 20C98.4473 20 98.1006 20.3467 98.1006 20.8V23.2C98.1006 23.6533 98.4473 24 98.9006 24C99.3539 24 99.7006 23.6533 99.7006 23.2V20.8C99.7006 20.3467 99.3539 20 98.9006 20Z"
          fill="#2C3E50"
        />
        <path
          d="M98.9006 32C98.4473 32 98.1006 32.3467 98.1006 32.8V35.2C98.1006 35.6533 98.4473 36 98.9006 36C99.3539 36 99.7006 35.6533 99.7006 35.2V32.8C99.7006 32.3467 99.3539 32 98.9006 32Z"
          fill="#2C3E50"
        />
        <path
          d="M103.514 22.4267L101.807 24.1334C101.487 24.4534 101.487 24.9601 101.807 25.2534C101.967 25.4134 102.181 25.4934 102.367 25.4934C102.581 25.4934 102.767 25.4134 102.927 25.2534L104.634 23.5467C104.954 23.2267 104.954 22.7201 104.634 22.4267C104.341 22.1334 103.834 22.1334 103.514 22.4267Z"
          fill="#2C3E50"
        />
        <path
          d="M95.0341 30.9067L93.3274 32.6133C93.0074 32.9333 93.0074 33.44 93.3274 33.7333C93.4874 33.8933 93.7007 33.9733 93.8874 33.9733C94.1007 33.9733 94.2874 33.8933 94.4474 33.7333L96.1541 32.0267C96.4741 31.7067 96.4741 31.2 96.1541 30.9067C95.8341 30.6133 95.3274 30.6133 95.0341 30.9067Z"
          fill="#2C3E50"
        />
        <path
          d="M14.9007 88.8002H12.5007C12.0473 88.8002 11.7007 89.1468 11.7007 89.6002C11.7007 90.0535 12.0473 90.4002 12.5007 90.4002H14.9007C15.354 90.4002 15.7007 90.0535 15.7007 89.6002C15.7007 89.1468 15.354 88.8002 14.9007 88.8002Z"
          fill="#2C3E50"
        />
        <path
          d="M26.9007 88.8002H24.5007C24.0473 88.8002 23.7007 89.1468 23.7007 89.6002C23.7007 90.0535 24.0473 90.4002 24.5007 90.4002H26.9007C27.354 90.4002 27.7007 90.0535 27.7007 89.6002C27.7007 89.1468 27.354 88.8002 26.9007 88.8002Z"
          fill="#2C3E50"
        />
        <path
          d="M15.2475 84.0268C14.9275 83.7068 14.4208 83.7068 14.1275 84.0268C13.8075 84.3468 13.8075 84.8535 14.1275 85.1468L15.8341 86.8535C15.9941 87.0135 16.2075 87.0935 16.3941 87.0935C16.6075 87.0935 16.7941 87.0135 16.9541 86.8535C17.2741 86.5335 17.2741 86.0268 16.9541 85.7335L15.2475 84.0268Z"
          fill="#2C3E50"
        />
        <path
          d="M23.754 92.5067C23.434 92.1867 22.9274 92.1867 22.634 92.5067C22.314 92.8267 22.314 93.3333 22.634 93.6267L24.3407 95.3333C24.5007 95.4933 24.714 95.5733 24.9007 95.5733C25.114 95.5733 25.3007 95.4933 25.4607 95.3333C25.7807 95.0133 25.7807 94.5067 25.4607 94.2133L23.754 92.5067Z"
          fill="#2C3E50"
        />
        <path
          d="M19.7006 81.6001C19.2473 81.6001 18.9006 81.9468 18.9006 82.4001V84.8001C18.9006 85.2534 19.2473 85.6001 19.7006 85.6001C20.154 85.6001 20.5006 85.2534 20.5006 84.8001V82.4001C20.5006 81.9468 20.154 81.6001 19.7006 81.6001Z"
          fill="#2C3E50"
        />
        <path
          d="M19.7006 93.6002C19.2473 93.6002 18.9006 93.9469 18.9006 94.4002V96.8002C18.9006 97.2535 19.2473 97.6002 19.7006 97.6002C20.154 97.6002 20.5006 97.2535 20.5006 96.8002V94.4002C20.5006 93.9469 20.154 93.6002 19.7006 93.6002Z"
          fill="#2C3E50"
        />
        <path
          d="M24.3141 84.0268L22.6074 85.7334C22.2874 86.0534 22.2874 86.5601 22.6074 86.8534C22.7674 87.0134 22.9808 87.0934 23.1674 87.0934C23.3808 87.0934 23.5674 87.0134 23.7274 86.8534L25.4341 85.1468C25.7541 84.8268 25.7541 84.3201 25.4341 84.0268C25.1408 83.7334 24.6341 83.7334 24.3141 84.0268Z"
          fill="#2C3E50"
        />
        <path
          d="M15.8341 92.5067L14.1275 94.2134C13.8075 94.5334 13.8075 95.0401 14.1275 95.3334C14.2875 95.4934 14.5008 95.5734 14.6875 95.5734C14.9008 95.5734 15.0875 95.4934 15.2475 95.3334L16.9541 93.6267C17.2741 93.3067 17.2741 92.8001 16.9541 92.5067C16.6341 92.2134 16.1275 92.2134 15.8341 92.5067Z"
          fill="#2C3E50"
        />
        <path
          d="M45.9674 142.933L44.1807 141.947C43.3807 141.493 42.714 140.853 42.2607 140.027L41.274 138.24C41.1407 137.973 40.874 137.813 40.5807 137.813C40.2873 137.813 40.0207 137.973 39.8873 138.24L38.9007 140.027C38.4473 140.827 37.8073 141.493 36.9807 141.947L35.194 142.933C34.9273 143.067 34.7673 143.333 34.7673 143.627C34.7673 143.92 34.9273 144.187 35.194 144.32L36.9807 145.307C37.7807 145.76 38.4473 146.4 38.9007 147.227L39.8873 149.013C40.0207 149.28 40.2873 149.44 40.5807 149.44C40.874 149.44 41.1407 149.28 41.274 149.013L42.2607 147.227C42.714 146.427 43.354 145.76 44.1807 145.307L45.9674 144.32C46.234 144.187 46.394 143.92 46.394 143.627C46.394 143.333 46.234 143.067 45.9674 142.933ZM43.4074 143.893C42.3407 144.48 41.4607 145.36 40.8474 146.453L40.5807 146.96L40.314 146.453C39.7273 145.387 38.8473 144.48 37.754 143.893L37.2473 143.627L37.754 143.36C38.8207 142.773 39.7273 141.893 40.314 140.8L40.5807 140.293L40.8474 140.8C41.434 141.867 42.314 142.747 43.4074 143.36L43.914 143.627L43.4074 143.893Z"
          fill="#2C3E50"
        />
        <path
          d="M199.034 105.867L197.247 104.88C196.447 104.427 195.781 103.787 195.327 102.96L194.341 101.173C194.207 100.907 193.941 100.747 193.647 100.747C193.354 100.747 193.087 100.907 192.954 101.173L191.967 102.96C191.514 103.76 190.874 104.427 190.047 104.88L188.261 105.867C187.994 106 187.834 106.267 187.834 106.56C187.834 106.853 187.994 107.12 188.261 107.253L190.047 108.24C190.847 108.693 191.514 109.333 191.967 110.16L192.954 111.947C193.087 112.213 193.354 112.373 193.647 112.373C193.941 112.373 194.207 112.213 194.341 111.947L195.327 110.16C195.781 109.36 196.421 108.693 197.247 108.24L199.034 107.253C199.301 107.12 199.461 106.853 199.461 106.56C199.434 106.293 199.274 106.027 199.034 105.867ZM196.474 106.853C195.407 107.44 194.527 108.32 193.914 109.413L193.647 109.92L193.381 109.413C192.794 108.347 191.914 107.467 190.821 106.853L190.314 106.587L190.821 106.32C191.887 105.733 192.794 104.853 193.381 103.76L193.647 103.253L193.914 103.76C194.501 104.827 195.381 105.733 196.474 106.32L196.981 106.587L196.474 106.853Z"
          fill="#2C3E50"
        />
        <path
          d="M124.501 173.6H122.901C122.447 173.6 122.101 173.947 122.101 174.4C122.101 174.853 122.447 175.2 122.901 175.2H124.501C124.954 175.2 125.301 174.853 125.301 174.4C125.301 173.947 124.954 173.6 124.501 173.6Z"
          fill="#2C3E50"
        />
        <path
          d="M132.501 173.6H130.901C130.447 173.6 130.101 173.947 130.101 174.4C130.101 174.853 130.447 175.2 130.901 175.2H132.501C132.954 175.2 133.301 174.853 133.301 174.4C133.301 173.947 132.954 173.6 132.501 173.6Z"
          fill="#2C3E50"
        />
        <path
          d="M127.701 168.8C127.247 168.8 126.901 169.147 126.901 169.6V171.2C126.901 171.653 127.247 172 127.701 172C128.154 172 128.501 171.653 128.501 171.2V169.6C128.501 169.147 128.154 168.8 127.701 168.8Z"
          fill="#2C3E50"
        />
        <path
          d="M127.701 176.8C127.247 176.8 126.901 177.147 126.901 177.6V179.2C126.901 179.653 127.247 180 127.701 180C128.154 180 128.501 179.653 128.501 179.2V177.6C128.501 177.147 128.154 176.8 127.701 176.8Z"
          fill="#2C3E50"
        />
        <path
          d="M170.101 66.4H168.501C168.047 66.4 167.701 66.7466 167.701 67.2C167.701 67.6533 168.047 68 168.501 68H170.101C170.554 68 170.901 67.6533 170.901 67.2C170.901 66.7466 170.554 66.4 170.101 66.4Z"
          fill="#2C3E50"
        />
        <path
          d="M178.101 66.4H176.501C176.047 66.4 175.701 66.7466 175.701 67.2C175.701 67.6533 176.047 68 176.501 68H178.101C178.554 68 178.901 67.6533 178.901 67.2C178.901 66.7466 178.554 66.4 178.101 66.4Z"
          fill="#2C3E50"
        />
        <path
          d="M173.301 61.6C172.847 61.6 172.501 61.9466 172.501 62.4V64C172.501 64.4533 172.847 64.8 173.301 64.8C173.754 64.8 174.101 64.4533 174.101 64V62.4C174.101 61.9466 173.754 61.6 173.301 61.6Z"
          fill="#2C3E50"
        />
        <path
          d="M173.301 69.6C172.847 69.6 172.501 69.9466 172.501 70.4V71.9999C172.501 72.4533 172.847 72.7999 173.301 72.7999C173.754 72.7999 174.101 72.4533 174.101 71.9999V70.4C174.101 69.9466 173.754 69.6 173.301 69.6Z"
          fill="#2C3E50"
        />
        <path
          d="M49.3007 40H41.3007C40.8474 40 40.5007 40.3467 40.5007 40.8C40.5007 41.2533 40.8474 41.6 41.3007 41.6H49.3007C49.754 41.6 50.1007 41.2533 50.1007 40.8C50.1007 40.3467 49.754 40 49.3007 40Z"
          fill="#2C3E50"
        />
        <path
          d="M77.3006 45.6H51.7006C51.2473 45.6 50.9006 45.9467 50.9006 46.4C50.9006 46.8533 51.2473 47.2 51.7006 47.2H77.3006C77.754 47.2 78.1006 46.8533 78.1006 46.4C78.1006 45.9467 77.754 45.6 77.3006 45.6Z"
          fill="#2C3E50"
        />
        <path
          d="M74.1008 51.2H67.7009C67.2475 51.2 66.9009 51.5467 66.9009 52C66.9009 52.4534 67.2475 52.8 67.7009 52.8H74.1008C74.5542 52.8 74.9008 52.4534 74.9008 52C74.9008 51.5467 74.5542 51.2 74.1008 51.2Z"
          fill="#2C3E50"
        />
        <path
          d="M61.3008 51.2H46.9008C46.4475 51.2 46.1008 51.5467 46.1008 52C46.1008 52.4534 46.4475 52.8 46.9008 52.8H61.3008C61.7542 52.8 62.1008 52.4534 62.1008 52C62.1008 51.5467 61.7542 51.2 61.3008 51.2Z"
          fill="#2C3E50"
        />
        <path
          d="M175.701 46.4H134.101C133.647 46.4 133.301 46.7467 133.301 47.2C133.301 47.6533 133.647 48 134.101 48H175.701C176.154 48 176.501 47.6533 176.501 47.2C176.501 46.7467 176.154 46.4 175.701 46.4Z"
          fill="#2C3E50"
        />
        <path
          d="M156.501 40.8H142.101C141.647 40.8 141.301 41.1466 141.301 41.6C141.301 42.0533 141.647 42.4 142.101 42.4H156.501C156.954 42.4 157.301 42.0533 157.301 41.6C157.301 41.1466 156.954 40.8 156.501 40.8Z"
          fill="#2C3E50"
        />
        <path
          d="M169.301 40.8H164.501C164.047 40.8 163.701 41.1466 163.701 41.6C163.701 42.0533 164.047 42.4 164.501 42.4H169.301C169.754 42.4 170.101 42.0533 170.101 41.6C170.101 41.1466 169.754 40.8 169.301 40.8Z"
          fill="#2C3E50"
        />
        <path
          d="M98.7714 94.0664C98.2809 90.23 95.1664 82.264 86.436 82.264L83.1253 82.8993C83.1253 82.8993 87.3188 82.0685 88.0545 77.4745C88.7167 73.2471 85.9455 70.5836 82.0707 69.6795C74.4439 67.9201 68.6808 75.2264 64.7815 80.138C62.3291 83.2414 60.588 85.4895 57.3754 87.8353C54.408 90.0101 51.7349 88.9594 49.6504 89.106L49.9692 118.282C53.3289 119.089 56.1737 119.626 60.5634 123.072C66.4982 127.69 72.5801 128.325 79.0053 128.325C86.9019 128.325 93.8176 125.613 96.3681 121.63C97.1773 120.359 99.8995 115.546 98.9185 109.021C97.9866 102.692 99.6052 100.737 98.7714 94.0664Z"
          fill="#FF6464"
        />
        <path
          d="M94.1361 94.0665C93.6947 90.2301 92.493 83.1437 84.4738 83.1437L81.0895 84.6098C84.3511 83.1925 86.313 77.5234 83.1495 73.4427C80.3047 69.7773 75.4 70.9991 72.825 72.2698C69.7105 73.7848 67.5279 76.4238 65.4679 79.234C63.2117 82.3373 61.2989 84.9519 58.356 87.2978C55.6339 89.4725 50.8273 88.9838 48.8899 89.1304L49.1842 118.307C52.2742 119.113 55.8056 118.576 59.852 121.997C65.3208 126.615 70.0048 128.35 75.9395 128.35C83.223 128.35 89.5747 125.637 91.929 121.654C92.6892 120.384 95.1906 115.57 94.2832 109.046C93.4004 102.692 94.9208 100.737 94.1361 94.0665Z"
          fill="#FE9C9A"
        />
        <path
          d="M51.6366 88.5928H50.7292C49.7973 88.5928 49.0371 89.3503 49.0371 90.2789V117.427C49.0371 118.356 49.7973 119.113 50.7292 119.113H51.6366C52.5685 119.113 53.3288 120.31 53.3288 119.382V89.4969C53.3288 88.5684 52.5685 88.5928 51.6366 88.5928Z"
          fill="white"
        />
        <path
          d="M97.1772 120.433C96.4906 119.993 95.5832 120.188 95.1418 120.873C92.8365 124.465 86.3378 126.884 79.0052 126.884C72.0895 126.884 66.7433 126.053 61.4707 121.923C57.0074 118.453 53.9664 117.72 50.7538 116.963L50.3124 116.865C49.5276 116.67 48.7183 117.158 48.5467 117.94C48.3505 118.722 48.841 119.529 49.6257 119.7L50.0671 119.797C53.2062 120.53 55.7076 121.117 59.6559 124.196C66.2528 129.327 73.1195 129.767 79.0052 129.767C87.4413 129.767 94.7249 126.884 97.6187 122.388C98.0601 121.777 97.8639 120.873 97.1772 120.433Z"
          fill="#2C3E50"
        />
        <path
          d="M100.291 101.69C100.487 99.54 100.684 97.3163 100.242 93.8953C99.6782 89.3747 96.0732 80.8222 86.4599 80.8222C85.6507 80.8222 84.9885 81.482 84.9885 82.2884C84.9885 83.0947 85.6507 83.7545 86.4599 83.7545C94.0132 83.7545 96.858 90.6454 97.3239 94.2619C97.7163 97.3897 97.5446 99.3445 97.3485 101.446C97.1523 103.621 96.9561 105.869 97.4711 109.29C97.8389 111.782 98.1332 115.032 96.9561 118.062C96.6618 118.82 97.0542 119.675 97.7899 119.968C97.9616 120.042 98.1332 120.066 98.3294 120.066C98.918 120.066 99.482 119.7 99.7027 119.137C101.125 115.448 100.806 111.709 100.389 108.874C99.9234 105.771 100.095 103.792 100.291 101.69Z"
          fill="#2C3E50"
        />
        <path
          d="M82.3894 68.2622C77.7299 67.187 73.193 68.8975 68.558 73.5159C67.994 74.0779 67.9694 75.0065 68.558 75.5929C69.1221 76.155 70.054 76.1794 70.6425 75.5929C74.5663 71.7077 78.0977 70.2659 81.7272 71.1212C83.4194 71.5122 87.2941 72.905 86.6074 77.2546C85.9944 81.1399 81.4575 84.341 77.4111 86.027C76.6508 86.3447 76.3075 87.2 76.6263 87.9575C76.8715 88.5195 77.4111 88.8616 77.9751 88.8616C78.1713 88.8616 78.3675 88.8372 78.5392 88.7394C80.8689 87.762 88.4958 84.121 89.5012 77.7189C90.2369 73.0761 87.5148 69.4351 82.3894 68.2622Z"
          fill="#2C3E50"
        />
        <path
          d="M66.0074 76.2772C65.7867 76.5216 65.6641 76.7659 65.5905 76.9858C65.3207 77.5723 65.4679 78.3054 66.0074 78.7208C66.2772 78.9407 66.596 79.0385 66.9393 79.0385C67.3562 79.0385 67.7976 78.843 68.0919 78.5009C68.2636 78.2809 68.3862 78.061 68.4598 77.8655C68.7541 77.2791 68.6314 76.546 68.0919 76.0817C67.4543 75.593 66.5469 75.6663 66.0074 76.2772Z"
          fill="#2C3E50"
        />
        <path
          d="M65.6642 79.014C65.0266 78.5009 64.0947 78.6231 63.6042 79.2584L63.3835 79.5272C61.0783 82.4351 59.4352 84.5121 56.4923 86.6869C54.7511 87.982 53.1571 87.8598 51.514 87.7376C50.8764 87.6888 50.2142 87.6399 49.5521 87.6888C48.7428 87.7376 48.1297 88.4463 48.1788 89.2526C48.2278 90.059 48.939 90.6699 49.7483 90.6211C50.1897 90.5966 50.7292 90.6211 51.2933 90.6699C51.7838 90.6944 52.3478 90.7432 52.9364 90.7432C54.5059 90.7432 56.3452 90.45 58.258 89.0572C61.5197 86.6625 63.3835 84.3166 65.7378 81.3599L65.9585 81.0911C66.4244 80.4313 66.3018 79.5028 65.6642 79.014Z"
          fill="#2C3E50"
        />
        <path
          d="M84.4246 83.388C83.7134 82.997 82.806 83.2658 82.4382 83.9745C82.0458 84.6831 82.3156 85.5872 83.0267 85.9538C84.817 86.9312 85.6753 88.0552 85.7734 89.5458C85.8225 90.3278 86.4601 90.9142 87.2448 90.9142C87.2694 90.9142 87.3184 90.9142 87.3429 90.9142C88.1522 90.8653 88.7653 90.1567 88.7163 89.3503C88.5446 86.8334 87.1222 84.8297 84.4246 83.388Z"
          fill="#2C3E50"
        />
        <path
          d="M46.5114 85.9293H40.0862C38.3695 85.9293 36.9717 87.3222 36.9717 89.0327V118.209C36.9717 119.92 38.3695 121.312 40.0862 121.312H46.5114C48.2281 121.312 49.6259 119.92 49.6259 118.209V89.0571C49.6505 87.3222 48.2526 85.9293 46.5114 85.9293Z"
          fill="#F96B6B"
        />
        <path
          d="M47.8355 85.9293H47.1734C46.1679 85.9293 45.3586 86.7357 45.3586 87.7376V119.529C45.3586 120.53 46.1679 121.337 47.1734 121.337H47.8355C48.841 121.337 49.6503 120.53 49.6503 119.529V87.7376C49.6503 86.7601 48.841 85.9293 47.8355 85.9293Z"
          fill="#E84242"
        />
        <path
          d="M38.4673 86.9068H37.6335C36.7997 86.9068 36.113 87.7131 36.113 88.715V117.867C36.113 118.869 36.7997 119.675 37.6335 119.675H38.4673C39.3011 119.675 39.9878 120.97 39.9878 119.968V87.8842C39.9878 86.8823 39.3011 86.9068 38.4673 86.9068Z"
          fill="white"
        />
        <path
          d="M143.502 87.8598C140.29 85.514 138.549 83.2659 136.096 80.1625C132.197 75.2265 126.409 67.9202 118.807 69.704C114.908 70.6081 112.161 73.2716 112.823 77.499C113.534 82.0929 117.752 82.9238 117.752 82.9238L114.442 82.2884C105.736 82.2884 102.597 90.2545 102.106 94.0909C101.273 100.737 102.916 102.692 101.935 109.07C100.954 115.594 104.412 119.773 105.221 121.044C107.771 125.027 113.951 128.374 121.872 128.374C128.322 128.374 134.38 127.739 140.314 123.121C144.729 119.7 147.549 119.138 150.909 118.331L151.227 89.1549C149.118 88.9838 146.47 90.0346 143.502 87.8598Z"
          fill="#45B3EE"
        />
        <path
          d="M147.328 89.1304L146.764 119.382C147.868 118.82 147.524 119.113 150.884 118.307L151.203 89.1304C149.118 88.9838 149.167 89.8635 147.328 89.1304Z"
          fill="#3498DB"
        />
        <path
          d="M160.767 85.9293H154.342C152.625 85.9293 151.227 87.3222 151.227 89.0327V118.209C151.227 119.92 152.625 121.312 154.342 121.312H160.767C162.484 121.312 163.882 119.92 163.882 118.209V89.0571C163.882 87.3222 162.484 85.9293 160.767 85.9293Z"
          fill="#3498DB"
        />
        <path
          d="M162.165 85.9293H161.748C160.791 85.9293 160.031 86.7113 160.031 87.6398V119.602C160.031 120.555 160.816 121.312 161.748 121.312H162.165C163.121 121.312 163.881 120.53 163.881 119.602V87.6643C163.881 86.7113 163.121 85.9293 162.165 85.9293Z"
          fill="#2B75B3"
        />
        <path
          d="M153.14 85.9293H152.723C151.767 85.9293 151.007 86.7113 151.007 87.6398V119.602C151.007 120.555 151.791 121.312 152.723 121.312H153.14C154.097 121.312 154.857 120.53 154.857 119.602V87.6643C154.881 86.7113 154.097 85.9293 153.14 85.9293Z"
          fill="white"
        />
        <path
          d="M81.3348 84.8787C80.5501 85.123 80.1332 85.9538 80.3784 86.7358C80.7463 87.8598 80.2313 89.2771 79.2258 89.888C78.5391 90.3279 78.3184 91.232 78.7598 91.9162C79.0296 92.356 79.5201 92.6004 80.0105 92.6004C80.2803 92.6004 80.5501 92.5271 80.7953 92.3805C82.9534 91.0365 83.9834 88.2264 83.1986 85.8317C82.9289 85.0497 82.0951 84.6343 81.3348 84.8787Z"
          fill="#2C3E50"
        />
        <path
          d="M46.5111 84.4631H40.0859C37.56 84.4631 35.5 86.5157 35.5 89.0326V118.209C35.5 120.726 37.56 122.778 40.0859 122.778H46.5111C49.0371 122.778 51.0971 120.726 51.0971 118.209V112.442C51.0971 111.636 50.4349 110.976 49.6256 110.976C48.8164 110.976 48.1542 111.636 48.1542 112.442V118.209C48.1542 119.113 47.4185 119.846 46.5111 119.846H40.0859C39.1786 119.846 38.4428 119.113 38.4428 118.209V89.057C38.4428 88.1529 39.1786 87.4198 40.0859 87.4198H46.5111C47.4185 87.4198 48.1542 88.1529 48.1542 89.057V104.671C48.1542 105.478 48.8164 106.138 49.6256 106.138C50.4349 106.138 51.0971 105.478 51.0971 104.671V89.057C51.1216 86.5157 49.0616 84.4631 46.5111 84.4631Z"
          fill="#2C3E50"
        />
        <path
          d="M49.6501 106.846C48.8409 106.846 48.1787 107.506 48.1787 108.313V108.557C48.1787 109.363 48.8409 110.023 49.6501 110.023C50.4594 110.023 51.1216 109.363 51.1216 108.557V108.313C51.1216 107.506 50.4594 106.846 49.6501 106.846Z"
          fill="#2C3E50"
        />
        <path
          d="M118.782 69.6796C114.883 70.5837 112.136 73.2472 112.799 77.4746C113.51 82.0685 117.728 82.8993 117.728 82.8993L114.417 82.264C105.711 82.264 102.572 90.2301 102.082 94.0665C101.248 100.713 102.891 102.668 101.91 109.046C100.929 115.57 104.387 119.749 105.196 121.019C107.747 125.002 113.927 128.35 121.848 128.35C121.848 128.35 105.196 123.218 105.932 110.194C106.668 97.1454 104.828 94.433 107.232 89.6681C109.586 84.9764 114.589 84.341 118.635 85.0741C120.548 85.4162 123.638 86.7602 121.578 85.6361C119.175 84.3166 117.924 81.9219 116.796 79.405C115.128 75.7396 117.139 73.1006 118.831 72.2453C122.314 70.4859 125.821 70.2171 129.695 75.1776C130.873 76.6926 133.815 79.5028 135.017 81.0422C131.093 76.155 126.409 67.9202 118.782 69.6796Z"
          fill="white"
        />
        <path
          d="M150.688 116.89L150.246 116.987C147.034 117.745 143.993 118.453 139.529 121.948C135.753 124.88 131.657 126.371 126.189 126.762C125.379 126.811 124.766 127.519 124.84 128.326C124.889 129.107 125.551 129.694 126.311 129.694C126.336 129.694 126.385 129.694 126.409 129.694C132.491 129.254 137.102 127.568 141.344 124.269C145.293 121.19 147.794 120.604 150.933 119.871L151.374 119.773C152.159 119.578 152.65 118.796 152.453 118.014C152.257 117.183 151.473 116.694 150.688 116.89Z"
          fill="#2C3E50"
        />
        <path
          d="M121.971 126.884C121.21 126.884 120.475 126.859 119.764 126.81C118.954 126.761 118.243 127.348 118.194 128.154C118.145 128.961 118.734 129.669 119.543 129.718C120.328 129.767 121.137 129.816 121.971 129.816C122.78 129.816 123.442 129.156 123.442 128.35C123.442 127.543 122.78 126.884 121.971 126.884Z"
          fill="#2C3E50"
        />
        <path
          d="M115.84 126.224C110.91 125.027 107.355 122.29 105.221 118.087C103.75 115.179 102.572 108.899 103.284 103.034C103.504 101.299 103.382 99.6867 103.284 98.1228C103.161 96.2413 103.014 94.262 103.431 91.965C103.774 90.0834 105 88.1041 106.815 86.5891C107.428 86.076 107.502 85.1474 106.987 84.5121C106.472 83.9012 105.54 83.8279 104.902 84.341C102.572 86.2959 100.978 88.8861 100.537 91.4274C100.047 94.0909 100.218 96.339 100.341 98.3183C100.439 99.8089 100.537 101.226 100.365 102.668C99.556 109.192 100.88 116.01 102.597 119.406C105.123 124.416 109.365 127.666 115.153 129.059C115.276 129.083 115.374 129.107 115.496 129.107C116.159 129.107 116.772 128.668 116.919 127.983C117.115 127.201 116.624 126.42 115.84 126.224Z"
          fill="#2C3E50"
        />
        <path
          d="M114.54 80.7978C112.235 80.7978 110.42 80.8955 108.188 82.0929C107.477 82.4838 107.207 83.3635 107.6 84.0722C107.87 84.5609 108.36 84.8297 108.9 84.8297C109.145 84.8297 109.365 84.7808 109.611 84.6586C111.18 83.8034 112.382 83.7056 114.564 83.7056C115.374 83.7056 116.036 83.0459 116.036 82.2395C116.011 81.4575 115.349 80.7978 114.54 80.7978Z"
          fill="#2C3E50"
        />
        <path
          d="M151.448 87.6643C150.81 87.6154 150.124 87.6643 149.486 87.7132C147.818 87.8353 146.249 87.9575 144.508 86.6624C143.698 86.076 142.84 85.4895 142.006 84.903C139.48 83.1681 136.881 81.3843 135.164 79.2339C134.551 78.452 133.938 77.6212 133.3 76.7415C130.014 72.1964 125.919 66.5517 118.586 68.2378C113.461 69.4352 110.739 73.0517 111.474 77.6945C112.48 84.0967 120.131 87.7376 122.436 88.715C123.197 89.0327 124.055 88.6662 124.374 87.9331C124.693 87.1756 124.325 86.3203 123.589 86.0027C119.543 84.3166 115.006 81.1399 114.393 77.2302C113.706 72.8562 117.581 71.4878 119.273 71.0968C124.693 69.8506 127.734 74.0291 130.946 78.452C131.584 79.3317 132.221 80.2114 132.884 81.0422C134.87 83.5591 137.666 85.4651 140.363 87.3222C141.222 87.9086 142.006 88.4462 142.791 89.0083C144.704 90.4011 146.543 90.6943 148.113 90.6943C148.701 90.6943 149.241 90.6455 149.756 90.621C150.32 90.5722 150.859 90.5477 151.301 90.5722C152.11 90.621 152.821 90.0101 152.87 89.2038C152.87 88.4218 152.257 87.7132 151.448 87.6643Z"
          fill="#2C3E50"
        />
        <path
          d="M116.551 83.3881C113.878 84.8542 112.431 86.858 112.259 89.3504C112.21 90.1568 112.823 90.8654 113.632 90.9143C113.657 90.9143 113.706 90.9143 113.731 90.9143C114.491 90.9143 115.153 90.3279 115.202 89.5459C115.3 88.0553 116.183 86.9313 117.949 85.9538C118.66 85.5629 118.93 84.6832 118.537 83.9745C118.145 83.2415 117.262 82.9971 116.551 83.3881Z"
          fill="#2C3E50"
        />
        <path
          d="M121.75 89.888C120.744 89.2771 120.229 87.8598 120.597 86.7358C120.843 85.9538 120.426 85.1475 119.641 84.8787C118.856 84.6343 118.047 85.0497 117.777 85.8317C116.992 88.2264 118.022 91.0365 120.18 92.3805C120.426 92.5271 120.695 92.6004 120.965 92.6004C121.456 92.6004 121.946 92.356 122.216 91.9162C122.657 91.2075 122.461 90.3034 121.75 89.888Z"
          fill="#2C3E50"
        />
        <path
          d="M160.89 84.4631H154.464C151.938 84.4631 149.878 86.5157 149.878 89.0326V118.209C149.878 120.726 151.938 122.778 154.464 122.778H160.89C163.416 122.778 165.476 120.726 165.476 118.209V105.111C165.476 104.305 164.813 103.645 164.004 103.645C163.195 103.645 162.533 104.305 162.533 105.111V118.209C162.533 119.113 161.797 119.846 160.89 119.846H154.464C153.557 119.846 152.821 119.113 152.821 118.209V89.057C152.821 88.1529 153.557 87.4198 154.464 87.4198H160.89C161.797 87.4198 162.533 88.1529 162.533 89.057V96.3633C162.533 97.1697 163.195 97.8295 164.004 97.8295C164.813 97.8295 165.476 97.1697 165.476 96.3633V89.057C165.5 86.5157 163.44 84.4631 160.89 84.4631Z"
          fill="#2C3E50"
        />
        <path
          d="M164.029 99.2714C163.219 99.2714 162.557 99.9312 162.557 100.738V101.226C162.557 102.033 163.219 102.692 164.029 102.692C164.838 102.692 165.5 102.033 165.5 101.226V100.738C165.5 99.9312 164.838 99.2714 164.029 99.2714Z"
          fill="#2C3E50"
        />
        <path
          d="M87.3919 145.797L96.5638 129.889L84.8169 138.857L87.3919 145.797Z"
          fill="#ECF0F1"
        />
        <path
          d="M95.8772 129.01L84.1303 137.978C83.738 138.271 83.5908 138.784 83.7625 139.224L86.3375 146.164C86.4846 146.555 86.8525 146.848 87.2694 146.872C87.2939 146.872 87.343 146.872 87.3675 146.872C87.7599 146.872 88.1277 146.652 88.3239 146.31L97.4958 130.402C97.7656 129.938 97.6675 129.376 97.2751 129.034C96.8827 128.716 96.2941 128.692 95.8772 129.01ZM87.6127 143.207L86.1413 139.224L92.8608 134.092L87.6127 143.207Z"
          fill="#2C3E50"
        />
        <path
          d="M99.7026 147.165L107.035 148.876L101.248 130.231L99.7026 147.165Z"
          fill="#ECF0F1"
        />
        <path
          d="M102.302 129.889C102.155 129.376 101.665 129.058 101.125 129.132C100.61 129.181 100.193 129.596 100.144 130.134L98.5994 147.068C98.5503 147.605 98.9182 148.118 99.4332 148.241L106.766 149.976C106.839 150 106.937 150 107.011 150C107.305 150 107.6 149.878 107.82 149.658C108.09 149.365 108.188 148.949 108.066 148.583L102.302 129.889ZM100.88 146.31L101.836 135.876L105.417 147.385L100.88 146.31Z"
          fill="#2C3E50"
        />
        <path
          d="M119.371 142.743L121.088 136.976L107.011 130.647L119.371 142.743Z"
          fill="#ECF0F1"
        />
        <path
          d="M121.529 135.974L107.452 129.645C106.962 129.425 106.398 129.596 106.079 130.036C105.785 130.476 105.834 131.062 106.226 131.453L118.586 143.549C118.807 143.745 119.077 143.867 119.371 143.867C119.469 143.867 119.567 143.867 119.641 143.842C120.009 143.745 120.303 143.451 120.425 143.085L122.142 137.318C122.289 136.756 122.044 136.194 121.529 135.974ZM118.831 140.666L112.21 134.19L119.763 137.587L118.831 140.666Z"
          fill="#2C3E50"
        />
        <path
          d="M104.632 69.704L116.158 60.9315L113.632 54.1139L104.632 69.704Z"
          fill="#ECF0F1"
        />
        <path
          d="M114.662 53.723C114.515 53.332 114.147 53.0387 113.73 53.0143C113.314 52.9899 112.897 53.1854 112.676 53.5519L103.7 69.1664C103.431 69.6307 103.529 70.1927 103.921 70.5348C104.117 70.7058 104.387 70.8036 104.657 70.8036C104.902 70.8036 105.123 70.7303 105.319 70.5837L116.845 61.7868C117.237 61.4935 117.384 60.9804 117.213 60.5405L114.662 53.723ZM108.335 65.501L113.412 56.6797L114.834 60.5161L108.335 65.501Z"
          fill="#2C3E50"
        />
        <path
          d="M100.61 69.3864L102.131 52.7944L94.9209 51.0839L100.61 69.3864Z"
          fill="#ECF0F1"
        />
        <path
          d="M102.376 51.7192L95.1906 50.0331C94.7982 49.9354 94.4058 50.0576 94.1361 50.3508C93.8663 50.644 93.7682 51.0594 93.8908 51.426L99.5803 69.7284C99.7275 70.1927 100.144 70.5104 100.635 70.5104C100.684 70.5104 100.708 70.5104 100.757 70.5104C101.272 70.4615 101.689 70.0461 101.738 69.5085L103.259 52.9166C103.259 52.3301 102.916 51.8414 102.376 51.7192ZM100.022 63.7416L96.5394 52.5989L100.929 53.6252L100.022 63.7416Z"
          fill="#2C3E50"
        />
        <path
          d="M79.4463 62.202L93.2531 68.4087L81.1139 56.5574L79.4463 62.202Z"
          fill="#ECF0F1"
        />
        <path
          d="M81.8987 55.751C81.629 55.4822 81.2121 55.3845 80.8442 55.4822C80.4764 55.58 80.1821 55.8732 80.0594 56.2397L78.3918 61.8844C78.2447 62.422 78.4899 62.984 79.0049 63.204L92.8118 69.4106C92.9589 69.484 93.1061 69.5084 93.2532 69.5084C93.5965 69.5084 93.9644 69.3373 94.1606 69.0197C94.4549 68.5798 94.4058 67.9934 94.0134 67.6024L81.8987 55.751ZM80.7706 61.5912L81.6535 58.61L88.0542 64.8656L80.7706 61.5912Z"
          fill="#2C3E50"
        />
      </g>
      <defs>
        <clipPath id="clip0_23273_214898">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default BattleIcon;
