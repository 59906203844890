import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  btnOutlined: {
    color: theme.palette.primary.dark,
    borderColor: '#C6D5E3',
  },
  lectorAvatar: {
    border: '2px solid rgba(0, 0, 0, 0.1)',
    marginRight: theme.spacing(3),
  },
  textSecondary: {
    color: '#647485',
  },
}));
