import { HEIGHT_TOPBAR_PX } from '@/utils/constants';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  top: {
    position: 'fixed',
    top: 0,
  },
  main: {
    overflowY: 'auto',
    position: 'fixed',
    top: HEIGHT_TOPBAR_PX,
    bottom: 0,
    left: 0,
    right: 0,
    height: `calc(100% - ${HEIGHT_TOPBAR_PX}px)`,
    display: 'grid',
  },
}));
