import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Button } from '@material-ui/core';

import { useUser } from '@/contexts/user-context';
import MastercardIcon from '@/components/icons/mastercard';

const CurrentPaymentMethod: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <>
      <Box mt={6}>
        <Box mb={4}>
          <Typography variant="subtitle2">
            {t('payment.method')}{' '}
            {!user?.customer?.defaultCard?.last4 &&
              ` - ${t('common.not-found')}`}
          </Typography>
        </Box>
        {user?.customer?.defaultCard?.last4 && (
          <Box
            display="flex"
            alignItems="center"
            paddingBottom={5}
            borderBottom="1px solid #E6EEFA"
          >
            <MastercardIcon style={{ marginRight: 16 }} />
            <Typography variant="body1">
              {user?.customer?.defaultCard?.brand} —
              {user?.customer?.defaultCard?.last4}
            </Typography>
          </Box>
        )}
      </Box>
      <Box mt={5} ml={-1} mb={5}>
        <Button
          color="primary"
          component={RouterLink}
          to={`/my-profile/change-payment-method`}
        >
          {t('payment.change-card')}
        </Button>
      </Box>
    </>
  );
};

export default CurrentPaymentMethod;
