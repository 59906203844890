import React from 'react';
import { Route as ReactRoute, RouteProps } from 'react-router-dom';

import Layout from '../layout';

const Route: React.FC<
  RouteProps & {
    page?: string;
    title: string;
  }
> = ({ children, page, title, ...rest }) => {
  return (
    <ReactRoute
      {...rest}
      render={({ location }) => (
        <Layout page={page} title={title}>
          {children}
        </Layout>
      )}
    />
  );
};

export default Route;
