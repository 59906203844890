import React from 'react';
import clsx from 'clsx';

import {
  Button,
  ButtonProps,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';

type AppButtonProps = ButtonProps & {
  loading?: boolean;
  visuallyDisabled?: boolean;
};

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  spiner: {
    display: 'flex',
    position: 'absolute',
    left: theme.spacing(-7),
  },
  buttonInLoading: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
  btnDisabledVisually: {
    borderColor: '#C6D5E3',
    color: theme.palette.action.disabled,
    background: theme.palette.action.disabledBackground + '!important',
    boxShadow: 'none',
  },
}));

const AppButton: React.FC<AppButtonProps> = ({
  children,
  loading,
  className,
  visuallyDisabled,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button
      {...rest}
      className={clsx(
        className,
        loading && classes.buttonInLoading,
        visuallyDisabled && classes.btnDisabledVisually
      )}
    >
      <span className={classes.labelContainer}>
        {loading && (
          <span className={classes.spiner}>
            <CircularProgress color="inherit" size={20} />
          </span>
        )}
        {children}
      </span>
    </Button>
  );
};

export default AppButton;
