import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import clsx from 'clsx';

import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';

import { ChevronRight } from '@material-ui/icons';

import { useRelativeTime } from '@/hooks/useTime';

import { useStyles } from './styles';
import Ava from '@/components/ava';
import { NotifTypes } from '@/type';

interface DuelItemProps {
  avatar?: string;
  specialityGroupIcon?: string | undefined;
  created: string;
  message: string;
  isNew?: boolean;
  onSelect?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  type?: NotifTypes;
}

const Item: React.FC<DuelItemProps> = ({
  avatar,
  specialityGroupIcon,
  created,
  message,
  isNew,
  onSelect,
  type,
  ...rest
}) => {
  const styles = useStyles();
  const { timeLeft } = useRelativeTime(DateTime.fromISO(created));

  const isQuizTime = useMemo(
    () =>
      type === NotifTypes.QT_STARTED ||
      type === NotifTypes.QT_GOING_T0_START ||
      type === NotifTypes.QT_FINISHED ||
      type === NotifTypes.QT_SESSION_FREE_PLACE
        ? true
        : false,
    [type]
  );

  const isIconCentered = !!specialityGroupIcon || isQuizTime;

  const content = (
    <>
      <Ava src={avatar} isIconCentered={isIconCentered} />
      <ListItemText
        primary={
          <Typography variant="subtitle2" color="textPrimary">
            {message?.replace(/DELETED[0-9]{0,11}_/, '')}
          </Typography>
        }
        secondary={<Typography variant="caption">{timeLeft}</Typography>}
      />
    </>
  );

  return isNew ? (
    <ListItem
      divider
      button
      className={clsx(styles.root, styles.new)}
      onClick={onSelect}
      {...rest}
    >
      {content}
      <ListItemSecondaryAction className={styles.secondary}>
        <ChevronRight color="disabled" />
      </ListItemSecondaryAction>
    </ListItem>
  ) : (
    <ListItem divider className={styles.root} {...rest}>
      {content}
    </ListItem>
  );
};

export default Item;
