import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    display: 'grid',
    gridRowGap: `${theme.spacing(3)}px`,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(5),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    paddingRight: theme.spacing(1),
  },
  footer: {
    display: 'grid',
    gridRowGap: `${theme.spacing(6)}px`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(7),
  },
  count: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.dark,
  },
  divider: {
    marginLeft: -24,
    marginRight: -24,
  },
}));
