import { useEffect, useRef, useState } from 'react';
import { Duration } from 'luxon';

export const useCountdown = (
  duration = 0,
  isLocaleString = false,
  isSeconds = true
) => {
  const [count, setCount] = useState(duration);
  const requestRef = useRef<number | undefined>();
  const previousTimeRef = useRef<number | undefined>();

  useEffect(() => {
    const countdown = (time: number) => {
      if (previousTimeRef.current !== undefined) {
        const deltaTime = time - previousTimeRef?.current;
        setCount((prevCount) => prevCount - deltaTime);
      }
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(countdown);
    };
    requestRef.current = requestAnimationFrame(countdown);
    if (count < 0) cancelAnimationFrame(requestRef.current || 0);
    return () => cancelAnimationFrame(requestRef.current || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLocaleString)
    return {
      timeLeft: Duration.fromMillis(count).toFormat('hh:mm:ss'),
      timeLeftInMillis: count,
    };

  const { days, hours, minutes, seconds } = Duration.fromMillis(count)
    .shiftTo('days', 'hours', 'minutes', 'seconds')
    .toObject();

  const timeWithLeadingZeros = Duration.fromObject({
    days,
    hours,
    minutes,
    seconds,
  })
    .toFormat('hh mm ss')
    .split(' ');

  const daysVal = days ? `${days}d ` : '';
  const hoursVal = hours ? `${hours}h ` : '';
  const minutesVal = minutes ? `${minutes}m ` : '';

  return {
    timeLeft: isSeconds
      ? `${daysVal}${hoursVal}${minutesVal}${Math.round(seconds || 0)}s`
      : `${timeWithLeadingZeros[0]}:${timeWithLeadingZeros[1]}:${timeWithLeadingZeros[2]}`,
    timeLeftInMillis: count,
  };
};
