import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as EmptyLaufendIcon } from './empty-laufend.svg';
import { ReactComponent as EmptyKapitelIcon } from './empty-kapitel.svg';
import { ReactComponent as EmptyBlendedIcon } from './empty-blended.svg';
import { ReactComponent as EmptyFertigIcon } from './empty-fertig.svg';
import { ClassroomVariantsEnum } from '@/type';

interface EmptyClassroomsProps {
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: ClassroomVariantsEnum;
}

const EmptyClassrooms: React.FC<EmptyClassroomsProps> = ({
  disabled,
  onClick,
  variant,
}) => {
  const { t } = useTranslation();

  const displayIcon = () => {
    switch (variant) {
      case ClassroomVariantsEnum.Laufend:
        return <EmptyLaufendIcon></EmptyLaufendIcon>;
      case ClassroomVariantsEnum.Kapitel:
        return <EmptyKapitelIcon></EmptyKapitelIcon>;
      case ClassroomVariantsEnum.Blended:
        return <EmptyBlendedIcon></EmptyBlendedIcon>;
      case ClassroomVariantsEnum.Fertig:
        return <EmptyFertigIcon></EmptyFertigIcon>;
    }
  };

  const displayText = () => {
    switch (variant) {
      case ClassroomVariantsEnum.Laufend:
        return <>{t('classrooms.empty-laufend-info')}</>;
      case ClassroomVariantsEnum.Kapitel:
        return <>{t('classrooms.empty-kapitel-info')}</>;
      case ClassroomVariantsEnum.Blended:
        return <>{t('classrooms.empty-blended-info')}</>;
      case ClassroomVariantsEnum.Fertig:
        return <>{t('classrooms.empty-fertig-info')}</>;
    }
  };

  return (
    <Box
      pt={7}
      pb={15}
      display="flex"
      flexDirection="column-reverse"
      alignItems="center"
      justifyContent="end"
      height="100%"
      boxSizing="border-box"
    >
      <Box
        id="viewarea"
        position="absolute"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        alignItems="center"
        top="26%"
      >
        <Box marginBottom={5}>{displayIcon()}</Box>
        <Box maxWidth={variant === 'Blended' ? 230 : 260}>
          <Typography align="center" variant="body2">
            {displayText()}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="flex-end" alignSelf="normal">
        {(variant === 'Fertig' || variant === 'Laufend') && (
          <Button
            fullWidth
            color="primary"
            variant="contained"
            disabled={disabled}
            to="/biblio/"
            component={RouterLink}
          >
            {t('classrooms.empty-cr-btn-explore')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default EmptyClassrooms;
