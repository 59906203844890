import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(14),
  },
  helpText: {
    maxWidth: '85%',
  },
}));
