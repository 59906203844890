import React from 'react';
import { FieldError } from 'react-hook-form';

import {
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import Error from '../error';

interface PasswordFieldProps extends Omit<MUITextFieldProps, 'error'> {
  errors?: FieldError;
}

const PasswordField: React.FC<PasswordFieldProps> = ({
  errors = null,
  ...rest
}) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const toggleShowPassword = React.useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, [setShowPassword]);

  return (
    <div>
      <MUITextField
        error={!!errors}
        {...rest}
        type={showPassword ? 'text' : 'password'}
        {...rest}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={toggleShowPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Error errors={errors} />
    </div>
  );
};

export default PasswordField;
