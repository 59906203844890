import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfiedOutlined';
import SentimentNeutralIcon from '@/components/icons/sentiment-neutral';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 16,
    minWidth: theme.spacing(6),
  },
  victory: {
    color: theme.palette.success.main,
  },
  draw: {
    color: theme.palette.warning.main,
  },
  defeat: {
    color: theme.palette.error.main,
  },
}));

interface StatusBlockProps {
  count: number;
  variant: 'victory' | 'draw' | 'defeat';
}

const StatusBlock: React.FC<StatusBlockProps> = ({ variant, count }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const content = useMemo(() => {
    switch (variant) {
      case 'victory':
        return (
          <>
            <Box display="flex">
              <SentimentSatisfiedIcon
                className={clsx(classes.icon, classes.victory)}
              />
              <Typography variant="overline" className={clsx(classes.victory)}>
                {count}
              </Typography>
            </Box>
            <Typography variant="overline">{t('duels.victory')}</Typography>
          </>
        );
      case 'defeat':
        return (
          <>
            <Box display="flex">
              <SentimentDissatisfiedIcon
                className={clsx(classes.icon, classes.defeat)}
              />
              <Typography variant="overline" className={clsx(classes.defeat)}>
                {count}
              </Typography>
            </Box>
            <Typography variant="overline">{t('duels.defeat')}</Typography>
          </>
        );
      default:
        return (
          <>
            <Box display="flex">
              <SentimentNeutralIcon
                className={clsx(classes.icon, classes.draw)}
              />
              <Typography variant="overline" className={clsx(classes.draw)}>
                {count}
              </Typography>
            </Box>
            <Typography variant="overline">{t('duels.draw')}</Typography>
          </>
        );
    }
  }, [
    variant,
    classes.icon,
    classes.victory,
    classes.defeat,
    classes.draw,
    count,
    t,
  ]);

  return (
    <Paper variant="outlined">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        height={36}
        paddingY={2}
      >
        {content}
      </Box>
    </Paper>
  );
};

export default StatusBlock;
