import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
interface CancelingInProgressProps {
  isSubsCancelingInProgress: boolean;
}

const CancelingInProgress: React.FC<CancelingInProgressProps> = ({
  isSubsCancelingInProgress,
}) => {
  const { t } = useTranslation();

  if (!isSubsCancelingInProgress) {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      bgcolor="rgba(44, 62, 80, 0.58)"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        position="absolute"
        top="0"
        left="0"
        right="0"
        padding="10px 16px"
        bgcolor="#fff"
        textAlign="center"
      >
        <Typography variant="body1">
          {t('in-app-subscriptions.in-progress-title')}
        </Typography>
        <CircularProgress size={24} style={{ marginLeft: 12, flexShrink: 0 }} />
      </Box>
    </Box>
  );
};
export default CancelingInProgress;
