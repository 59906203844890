import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import clsx from 'clsx';
import { PermIdentityOutlined } from '@material-ui/icons';
import { Avatar, Box } from '@material-ui/core';

import { useStyles } from './styles';

interface AvaProps {
  src?: string | undefined | null;
  isIconCentered?: boolean;
}

const Ava: React.FC<AvaProps> = ({ src, isIconCentered }) => {
  const classes = useStyles();

  return (
    <Box mr={4}>
      {src ? (
        <LazyLoadImage
          width={48}
          height={48}
          effect="blur"
          placeholder={
            <Avatar style={{ fontSize: '1.75rem' }}>
              {!src && <PermIdentityOutlined fontSize="inherit" />}
            </Avatar>
          }
          wrapperClassName={clsx(
            'avaLazyWrap',
            isIconCentered ? classes.centeredAvatar : classes.avatar
          )}
          useIntersectionObserver
          src={src}
        />
      ) : (
        <Avatar src={undefined} style={{ fontSize: '1.75rem' }}>
          {!src && <PermIdentityOutlined fontSize="inherit" />}
        </Avatar>
      )}
    </Box>
  );
};

export default Ava;
