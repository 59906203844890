import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const DuelRule1Icon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M17.9529 8.00179L12.3269 5.04811C12.1222 4.94067 11.8777 4.94067 11.673 5.04811L6.04701 8.00179C6.04387 8.00343 6.04087 8.00535 6.03778 8.00704C6.03436 8.00892 6.03084 8.01051 6.02742 8.01243C5.81639 8.13215 5.67445 8.3586 5.67103 8.61463C5.67098 8.61792 5.6707 8.62115 5.6707 8.62439V14.8131H5.67065C5.67065 15.0555 5.79553 15.2809 6.00117 15.4094L11.6272 18.9257C11.6322 18.9288 11.6373 18.9314 11.6423 18.9344C11.8731 19.0706 12.154 19.0623 12.3727 18.9257L17.9987 15.4094C18.2043 15.2809 18.3292 15.0555 18.3292 14.8131V8.62448C18.3292 8.36868 18.1873 8.1254 17.9529 8.00179ZM11.2967 17.0605L7.07714 14.4233V9.86657L11.2967 12.3983V17.0605ZM11.9999 11.18L7.80895 8.6654L11.9999 6.46509L16.1909 8.6654L11.9999 11.18ZM16.9227 14.4233L12.7032 17.0605V12.3982L16.9227 9.86652V14.4233Z"
        fill="#2B75B3"
      />
      <path
        d="M21.8524 16.9062H19.7357C19.3382 16.9062 19.0159 17.2285 19.0159 17.626C19.0159 18.0235 19.3381 18.3458 19.7357 18.3458H20.4069C18.4237 20.9727 15.3068 22.5603 11.9999 22.5603C6.17695 22.5603 1.43958 17.823 1.43958 12C1.43958 11.1686 1.55733 10.3072 1.79948 9.36639C1.89858 8.98141 1.66683 8.58897 1.28184 8.48992C0.896765 8.39087 0.504422 8.62258 0.405375 9.00756C0.132562 10.0671 0 11.046 0 12C0 18.6168 5.38312 24 11.9999 24C14.1363 24 16.2363 23.4236 18.073 22.3332C19.2286 21.6472 20.2602 20.7702 21.1258 19.7489C21.1268 20.1455 21.4486 20.4668 21.8455 20.4668C22.2431 20.4668 22.5653 20.1445 22.5653 19.747V17.7255C22.6255 17.2938 22.2903 16.9062 21.8524 16.9062Z"
        fill="#2B75B3"
      />
      <path
        d="M12 0C9.86363 0 7.76358 0.576328 5.92688 1.66673C4.77109 2.35289 3.73932 3.23011 2.87354 4.2517C2.86679 3.86002 2.54776 3.54441 2.15438 3.54441C1.75684 3.54441 1.43457 3.86667 1.43457 4.26422V6.38081C1.43457 6.81548 1.8181 7.15392 2.25385 7.09383H4.27093C4.66848 7.09383 4.99074 6.77156 4.99074 6.37402C4.99074 5.97647 4.66852 5.65416 4.27098 5.65416H3.59302C5.57621 3.02728 8.69312 1.43963 12 1.43963C17.823 1.43963 22.5603 6.177 22.5603 12C22.5603 12.8313 22.4426 13.6928 22.2004 14.6336C22.1013 15.0185 22.3331 15.411 22.7181 15.51C23.1031 15.6091 23.4955 15.3773 23.5945 14.9924C23.8673 13.9328 23.9999 12.954 23.9999 12C23.9999 5.38317 18.6168 0 12 0Z"
        fill="#2B75B3"
      />
    </SvgIcon>
  );
};

export default DuelRule1Icon;
