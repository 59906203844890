import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Box, Paper, Typography, Chip } from '@material-ui/core';
import { useStyles } from './styles';
import {
  QuizSessionLabelStatusesEnum,
  QuizTimeSessionStatus,
  UserQuizSessionSettingsUserStatus,
} from '@/graphql';
import { useTranslation } from 'react-i18next';
import RankLabel from '../rank-label';
import QuizTimeBlock from '../time-block';
import { QuizTimeBlockWithTime } from '@/type';

interface QuizTimeCardProps {
  id: string;
  title: string | undefined;
  organizerName?: string | null;
  classRoomImage?: string | null;
  startDate?: string;
  startDateTime?: string;
  finishDateTime?: string;
  link?: string;
  isForPremiumOnly?: boolean;
  duration?: any;
  userRank?: number | null;
  quizStatus?: QuizTimeSessionStatus | null;
  quizSessionLabelStatus?: QuizSessionLabelStatusesEnum | null;
  userStatus?: UserQuizSessionSettingsUserStatus | null;
}

const QuizTimeCard: React.FC<QuizTimeCardProps> = ({
  children,
  id,
  title,
  organizerName,
  classRoomImage,
  startDate,
  startDateTime,
  finishDateTime,
  link,
  isForPremiumOnly,
  duration,
  quizStatus,
  userRank,
  userStatus,
  quizSessionLabelStatus,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const statusIsPaused =
    quizSessionLabelStatus === QuizSessionLabelStatusesEnum.Paused;

  return (
    <Link
      to={{ pathname: link || '#', state: { backPageForQuiz: '/quiz-list' } }}
      style={{ textDecoration: 'none' }}
    >
      <Paper elevation={0} className={classes.card}>
        {quizStatus === QuizTimeSessionStatus.Finished &&
          userStatus === UserQuizSessionSettingsUserStatus.Finished && (
            <RankLabel userRank={userRank} leftPos={24} />
          )}
        <Box padding={2} paddingRight={6} marginTop={2} {...rest}>
          {statusIsPaused && (
            <Box
              display="flex"
              mb={2}
              mr={-4}
              px={2}
              py={0.5}
              className={classes.status}
            >
              <Typography variant="caption" style={{ color: '#4A647C' }}>
                {t('common.pause')}
              </Typography>
            </Box>
          )}
          <Box display="flex">
            <LazyLoadImage
              effect="blur"
              wrapperClassName={classes.cardPicture}
              useIntersectionObserver
              src={classRoomImage || ''}
              alt={title}
            />
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Box display="grid" gridRowGap={4} marginBottom={4}>
                {isForPremiumOnly && (
                  <Box display="flex" alignItems="center">
                    {isForPremiumOnly && (
                      <Chip
                        label="PREMIUM"
                        color="default"
                        variant="outlined"
                        size="small"
                        className={classes.chipGolden}
                      />
                    )}
                  </Box>
                )}
                <Typography
                  variant="subtitle1"
                  className="text-ellipsis-3lines"
                >
                  {title}
                </Typography>
                <Typography
                  color="inherit"
                  variant="caption"
                  className="text-ellipsis-3lines"
                >
                  {organizerName}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                marginTop="auto"
              >
                <QuizTimeBlock
                  quizStatus={quizStatus}
                  userStatus={userStatus}
                  startDateTime={startDateTime || ''}
                  finishDateTime={finishDateTime || ''}
                  duration={duration}
                  type={QuizTimeBlockWithTime.LIST_PAGE_CARD}
                  quizSessionId={id}
                  isShortFormat
                />
              </Box>
            </Box>
          </Box>
          {children}
        </Box>
      </Paper>
    </Link>
  );
};

export default QuizTimeCard;
