import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
  Box,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { SentimentDissatisfied, SentimentSatisfied } from '@material-ui/icons';
import SentimentNeutral from '@/components/icons/sentiment-neutral';

import {
  DuelResultUserDataType,
  DuelResultUserDataUserStatus,
  DuelStatus,
} from '@/graphql';
import { ClassroomTypes, UserStatus } from '@/type';
import ChipIndicator from '@/components/chip-indicator';
import Ava from '@/components/ava';
import { useStyles } from './styles';

interface DuelCompletedItemProps {
  userDuel: DuelResultUserDataType;
  opponentDuel: DuelResultUserDataType;
  divider: boolean;
  classroomType?: number | undefined | null;
}

const operators = Object.freeze({
  [UserStatus.win]: '<',
  [UserStatus.loss]: '>',
  [UserStatus.tie]: ':',
  [UserStatus.unfinished]: '',
});

const convertStatus = (value: DuelResultUserDataUserStatus): UserStatus => {
  switch (value) {
    case DuelResultUserDataUserStatus.A_1:
      return UserStatus.tie;
    case DuelResultUserDataUserStatus.A_2:
      return UserStatus.win;
    case DuelResultUserDataUserStatus.A_3:
      return UserStatus.loss;
    case DuelResultUserDataUserStatus.A_4:
      return UserStatus.unfinished;
  }
};

const DuelCompletedItem: React.FC<DuelCompletedItemProps> = ({
  userDuel,
  opponentDuel,
  divider,
  classroomType,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const classStatuses = Object.freeze({
    [UserStatus.win]: classes.win,
    [UserStatus.loss]: classes.loss,
    [UserStatus.tie]: classes.tie,
    [UserStatus.unfinished]: classes.unfinished,
  });

  const status = convertStatus(userDuel?.userStatus);

  const operator = useMemo(() => operators[status], [status]);

  const icon = useMemo(() => {
    if (status === UserStatus.win)
      return <SentimentSatisfied className={clsx(classes.icon, classes.win)} />;
    if (status === UserStatus.loss)
      return (
        <SentimentDissatisfied className={clsx(classes.icon, classes.loss)} />
      );
    if (status === UserStatus.tie)
      return <SentimentNeutral className={clsx(classes.icon, classes.tie)} />;
  }, [classes, status]);

  const rightContent = useMemo(
    () =>
      userDuel?.duel?.status === DuelStatus.Ignored ? (
        <Typography color="initial" variant="caption">
          {t('duels.not-ended')}
        </Typography>
      ) : (
        <Box display="flex" alignItems="center">
          <Box marginRight={5}>
            <Typography color="initial" variant="caption">
              {opponentDuel.duelScore}
            </Typography>
            <Typography
              color="initial"
              variant="caption"
              className={clsx(classes.operator, classStatuses[status])}
            >
              {operator}
            </Typography>
            <Typography color="initial" variant="caption">
              {userDuel.duelScore}
            </Typography>
          </Box>
          {icon}
        </Box>
      ),
    [
      classStatuses,
      classes.operator,
      icon,
      operator,
      opponentDuel.duelScore,
      status,
      t,
      userDuel,
    ]
  );

  return (
    <ListItem divider={divider} {...rest}>
      <Ava src={opponentDuel?.user?.avatar} />
      <ListItemText
        primary={
          <Typography variant="subtitle2">
            {opponentDuel?.user?.username?.replace(/DELETED[0-9]{0,11}_/, '')}
          </Typography>
        }
        secondary={
          !!classroomType && (
            <ChipIndicator
              title={t(
                classroomType === ClassroomTypes.NETWORK
                  ? 'common.network-cr'
                  : 'common.blended-cr'
              )}
            />
          )
        }
      />
      <ListItemSecondaryAction>{rightContent}</ListItemSecondaryAction>
    </ListItem>
  );
};

export default DuelCompletedItem;
