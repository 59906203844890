import React, { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { useUpdateRulesSettingsMutation, useUserMeQuery } from '@/graphql';
import { USER_SETTINGS_FRAGMENT } from '@/apollo/fragments';
import { useDailyTipperRulesFullScreenModal } from '@/hooks/useDailyTipperRulesFullScreenModal';

const RuleWrapper: React.FC = ({ children }) => {
  const client = useApolloClient();
  const dtRulesFullScreenModal = useDailyTipperRulesFullScreenModal();

  const { data } = useUserMeQuery({ fetchPolicy: 'cache-first' });
  const [update, { loading }] = useUpdateRulesSettingsMutation();
  const isDailyTipperRulesRead = data?.me?.settings?.isDailyTipperRulesRead;

  useEffect(() => {
    if (isDailyTipperRulesRead === false && !loading) {
      client.writeFragment({
        id: `${data?.me?.settings?.__typename}:${data?.me?.settings?.id}`,
        fragment: USER_SETTINGS_FRAGMENT,
        data: {
          ...data?.me?.settings,
          isDailyTipperRulesRead: true,
        },
      });

      try {
        update({
          variables: {
            isDailyTipperRulesRead: true,
          },
        });
      } catch (err) {
        console.error('useUpdateRulesSettingsMutation', err);
      }
      dtRulesFullScreenModal.open();
    }
  }, [
    data,
    isDailyTipperRulesRead,
    client,
    dtRulesFullScreenModal,
    loading,
    update,
  ]);

  return <>{children}</>;
};

export default RuleWrapper;
