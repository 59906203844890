import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, IconButton, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  PurchasesPackage,
  PurchasesStoreProduct,
} from 'react-native-purchases';

import {
  useInAppUserSubscribeMutation,
  UserSubscriptionDocument,
} from '@/graphql';
import { useStyles } from './styles';
import AppButton from '@/components/app-button';
import CheckCircleIcon from '@/components/icons/check-circle';
import Loading from '@/components/loading';
import SubscriptionOption from './components';
import { RNMessageType, WebActionType } from './types';
import { datadogRum } from '@datadog/browser-rum';
import { useSendUserDataToNative } from '../my-profile/network/useUserDataToNative';
import SubsAgreementText from '../subscriptions/subs-agreement-text';

const InAppSubscriptions = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  useSendUserDataToNative();

  const [packages, setPackages] = useState<PurchasesPackage[] | null>(null);
  const [selectedProduct, setSelectedProduct] =
    useState<PurchasesStoreProduct | null>(null);
  const [isPurchaseAvailable, setPurchaseAvailable] = useState<boolean>(false);
  const [isLoadingPackages, setIsLoadingPackages] = useState<boolean>(true);
  const [isPurchasing, setIsPurchasing] = useState<boolean>(false);
  const [inAppUserSubscribe] = useInAppUserSubscribeMutation();
  const processPurchaseResult = useCallback(
    async (result) => {
      try {
        datadogRum.addAction('in-app-subscription', {
          event: 'click buy button',
        });
        const res = await inAppUserSubscribe({
          variables: {
            subscriptionObject: result,
          },
          refetchQueries: [{ query: UserSubscriptionDocument }],
        });
        const isInAppPremium =
          res?.data?.inAppSubscribe?.inAppSubscription?.customer
            ?.isInAppPremium;
        setIsPurchasing(false);
        if (isInAppPremium) {
          history.push('/my-profile/network', {
            prevPage: '/home',
          });
        }
      } catch (error) {
        alert(JSON.stringify(error));
        console.error('SEND_RN_PURCHASE_RESULT', error);
      }
    },
    [inAppUserSubscribe, history]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoadingPackages(false);
    }, 60000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    async function handleNativeMessage(message: any) {
      if (!message?.data) return;
      const messageData = message.data;

      switch (messageData?.type) {
        case RNMessageType.RN_FETCH_PACKAGES_SUCCESS:
          const packs: PurchasesPackage[] | null = messageData?.payload;
          setIsLoadingPackages(false);
          if (packs?.length) {
            setPurchaseAvailable(true);
            setPackages(packs);
            setSelectedProduct(packs[0].product);
          } else {
            setPurchaseAvailable(false);
            setPackages(null);
            setSelectedProduct(null);
          }
          break;
        case RNMessageType.RN_PURCHASE_COMPLETED:
          processPurchaseResult(messageData?.payload);
          break;
      }
    }

    document.addEventListener('RN_MESSAGE', handleNativeMessage);
    return () =>
      document.removeEventListener('RN_MESSAGE', handleNativeMessage);
  }, [processPurchaseResult]);

  function sendMessageToNative({
    type,
    payload,
  }: {
    type: string;
    payload?: any;
  }) {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type,
        payload,
      })
    );
  }

  const closePage = () => {
    history.replace('/my-profile/network');
  };

  const handleProductBuying = (selectedProduct: PurchasesStoreProduct) => {
    setIsPurchasing(true);
    sendMessageToNative({
      type: WebActionType.InitiatePurchase,
      payload: { product: selectedProduct },
    });
  };
  const introPrice = selectedProduct?.introPrice?.priceString;

  return (
    <Paper elevation={0} className={classes.root}>
      <Box>
        <IconButton
          aria-label="close"
          className={classes.close}
          onClick={closePage}
        >
          <CloseIcon htmlColor="#4A647C" />
        </IconButton>
        <Typography variant="h2">{t('in-app-subscriptions.title')}</Typography>

        <Box mt={6} mb={5}>
          {[1, 2, 3, 4].map((it) => (
            <Box key={it} mt={2} className={classes.adventageListItem}>
              <CheckCircleIcon className={classes.circleIcon} />
              <Typography variant="subtitle2">
                {t(`in-app-subscriptions.adventages.${it}`)}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box className={classes.subscriptionOptions}>
          {!isLoadingPackages &&
            packages?.map((pack) => (
              <SubscriptionOption
                key={pack.product.identifier}
                product={pack.product}
                isSelected={
                  selectedProduct?.identifier === pack.product.identifier
                }
                onSelect={() => setSelectedProduct(pack.product)}
              />
            ))}

          {!isPurchaseAvailable && !isLoadingPackages && (
            <Typography className={classes.subscriptionPlaceholderText}>
              {t('in-app-subscriptions.no-subscription-caption')}
            </Typography>
          )}
        </Box>
        {isLoadingPackages && (
          <Box display="flex" justifyContent="center" mb={10}>
            <Loading />
          </Box>
        )}
        <AppButton
          fullWidth
          data-cy-forgot-btn
          color="primary"
          variant="contained"
          disabled={!selectedProduct || isPurchasing}
          loading={isPurchasing}
          onClick={() =>
            selectedProduct && handleProductBuying(selectedProduct)
          }
        >
          {t(
            selectedProduct?.subscriptionPeriod === 'P1Y'
              ? 'subscription.start-annual-plan'
              : 'subscription.start-monthly-plan'
          )}
        </AppButton>
        <SubsAgreementText
          subscriptionPeriod={selectedProduct?.subscriptionPeriod}
          price={selectedProduct?.priceString}
          discountPrice={introPrice}
        />
      </Box>
    </Paper>
  );
};

export default InAppSubscriptions;
