import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const DuelRule5Icon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M23.5078 18.0164L15.0394 2.42579C15.0351 2.41791 15.0307 2.41013 15.0261 2.40235C13.6629 0.106407 10.3385 0.103876 8.97372 2.40235C8.96913 2.41008 8.96472 2.41787 8.96045 2.42579L0.491914 18.0164C-0.88865 20.3617 0.800023 23.3204 3.52534 23.3204H20.4745C23.1977 23.3204 24.8896 20.3638 23.5078 18.0164ZM20.4745 21.9126H3.52534C1.8879 21.9126 0.873102 20.1317 1.70963 18.7229C1.71423 18.7151 1.71863 18.7073 1.7229 18.6994L10.1905 3.11045C11.0115 1.74563 12.9891 1.74704 13.8092 3.11045L22.2769 18.6995C22.2811 18.7074 22.2856 18.7152 22.2901 18.723C23.1262 20.1308 22.1129 21.9126 20.4745 21.9126Z"
        fill="#2B75B3"
      />
      <path
        d="M11.9999 6.42725C10.8355 6.42725 9.88818 7.37454 9.88818 8.53892V12.7622C9.88818 13.9266 10.8355 14.8739 11.9999 14.8739C13.1642 14.8739 14.1115 13.9266 14.1115 12.7622V8.53892C14.1115 7.37454 13.1642 6.42725 11.9999 6.42725ZM12.7037 12.7622C12.7037 13.1504 12.388 13.4661 11.9999 13.4661C11.6117 13.4661 11.296 13.1504 11.296 12.7622V8.53892C11.296 8.1508 11.6117 7.83505 11.9999 7.83505C12.388 7.83505 12.7037 8.1508 12.7037 8.53892V12.7622Z"
        fill="#2B75B3"
      />
      <path
        d="M11.9999 16.2815C10.8355 16.2815 9.88818 17.2288 9.88818 18.3932C9.88818 19.5575 10.8355 20.5048 11.9999 20.5048C13.1642 20.5048 14.1115 19.5575 14.1115 18.3932C14.1115 17.2288 13.1642 16.2815 11.9999 16.2815ZM11.9999 19.097C11.6117 19.097 11.296 18.7813 11.296 18.3932C11.296 18.005 11.6117 17.6893 11.9999 17.6893C12.388 17.6893 12.7037 18.005 12.7037 18.3932C12.7037 18.7813 12.388 19.097 11.9999 19.097Z"
        fill="#2B75B3"
      />
    </SvgIcon>
  );
};

export default DuelRule5Icon;
