import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { ReactComponent as IocnSample } from './index.svg';
import { ReactComponent as SafariShareIcon } from './safari-share-icon.svg';

import { useStyles } from './styles';
import {
  Divider,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import {
  useUpdateIsIosInstallationPopupSkippedSettingsMutation,
  useUpdateIsChromeInstallationPopupSkippedSettingsMutation,
  useUserMeQuery,
} from '@/graphql';
import { USER_SETTINGS_FRAGMENT } from '@/apollo/fragments';
import { useApolloClient } from '@apollo/client';
import theme from '@/theme';
import { useInstallEvent } from '@/contexts/event-context';
import { useGlobalDialog } from '@/hooks/useGlobalDialog';
import { GlobalDialogTypes } from '@/type';
import { LS_KEY_CLICKED_DATE, WEEK_IN_MS } from '@/utils/constants';

const isRunningStandalone = () => {
  return window.matchMedia('(display-mode: standalone)').matches;
};

const isChrome = () => {
  const userAgent = navigator.userAgent;
  const vendor = navigator.vendor;

  return window.chrome && /Chrome/.test(userAgent) && vendor === 'Google Inc.';
};

const isSafari = () => {
  return (
    navigator.vendor.match(/apple/i) &&
    !navigator.userAgent.match(/crios/i) &&
    !navigator.userAgent.match(/fxios/i)
  );
};

const isMobile = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

export interface Window {
  matchMedia: (mode: string) => MediaQueryList;
  chrome?: boolean;
}

declare let window: Window;

export interface Navigator {
  getInstalledRelatedApps?: any;
  userAgent?: any;
  userAgentData?: any;
  vendor?: any;
}

declare let navigator: Navigator;

const AddIcon: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { data: dataGlobalDialog, setGlobalDialog } = useGlobalDialog();
  const dialogWasOpenedEarlierRef = useRef(false);

  const [chromePopupVisibility, setChromePopupVisibility] = useState(true);
  const [iosPopupVisibility, setIosPopupVisibility] = React.useState(
    localStorage.getItem(LS_KEY_CLICKED_DATE) ? false : true
  );
  const classes = useStyles();
  const [remindChecked, setRemindChecked] = React.useState(false);
  const [skipChecked, setSkipChecked] = React.useState(false);
  const [skipChromeChecked, setSkipChromeChecked] = React.useState(false);

  const client = useApolloClient();
  const { data } = useUserMeQuery({ fetchPolicy: 'cache-first' });
  const { installEvent, clearInstallEvent } = useInstallEvent();
  const [updateIos, { loading: iosLoading }] =
    useUpdateIsIosInstallationPopupSkippedSettingsMutation();
  const isIosInstallationPopupSkipped =
    data?.me?.settings?.isIosInstallationPopupSkipped;

  const [update, { loading }] =
    useUpdateIsChromeInstallationPopupSkippedSettingsMutation();
  const isChromeInstallationPopupSkipped =
    data?.me?.settings?.isChromeInstallationPopupSkipped;

  const isIntroCompleted = data?.me?.settings?.isIntroCompleted;

  useEffect(() => {
    if (isIosInstallationPopupSkipped === false && skipChecked && !iosLoading) {
      client.writeFragment({
        id: `${data?.me?.settings?.__typename}:${data?.me?.settings?.id}`,
        fragment: USER_SETTINGS_FRAGMENT,
        data: {
          ...data?.me?.settings,
          isIosInstallationPopupSkipped: true,
        },
      });

      try {
        updateIos({
          variables: {
            isIosInstallationPopupSkipped: true,
          },
        });
      } catch (err) {
        console.error(
          'useUpdateIsIosInstallationPopupSkippedSettingsMutation',
          err
        );
      }
    }
  }, [
    data,
    isIosInstallationPopupSkipped,
    client,
    iosLoading,
    updateIos,
    skipChecked,
  ]);

  useEffect(() => {
    if (
      isChromeInstallationPopupSkipped === false &&
      skipChromeChecked &&
      !loading
    ) {
      client.writeFragment({
        id: `${data?.me?.settings?.__typename}:${data?.me?.settings?.id}`,
        fragment: USER_SETTINGS_FRAGMENT,
        data: {
          ...data?.me?.settings,
          isChromeInstallationPopupSkipped: true,
        },
      });

      try {
        update({
          variables: {
            isChromeInstallationPopupSkipped: true,
          },
        });
      } catch (err) {
        console.error(
          'useUpdateIsChromeInstallationPopupSkippedSettingsMutation',
          err
        );
      }
    }
  }, [
    data,
    isChromeInstallationPopupSkipped,
    client,
    loading,
    update,
    skipChromeChecked,
  ]);

  useEffect(() => {
    const retrievedData = localStorage.getItem(LS_KEY_CLICKED_DATE);
    if (retrievedData) {
      setIosPopupVisibility(false);
      const DateToCheck = Date.parse(retrievedData);
      const currentTime = Date.parse(DateTime.local().toString());
      if (DateToCheck + WEEK_IN_MS < currentTime) {
        setRemindChecked(false);
        setIosPopupVisibility(true);
        localStorage.removeItem(LS_KEY_CLICKED_DATE);
      }
    }
  }, []);

  const handleRemindChange = () => {
    setGlobalDialog(null);
    setIosPopupVisibility(false);
    setRemindChecked(!remindChecked);
    const clickedDate = DateTime.local();
    localStorage.setItem(LS_KEY_CLICKED_DATE, clickedDate.toString());
  };

  const handleSkipChange = () => {
    setSkipChecked(true);
    handleCloseIOS();
  };

  const handleClose = useCallback(() => {
    setSkipChromeChecked(true);
    setGlobalDialog(null);
    setChromePopupVisibility(false);
    history.replace('/home');
  }, [history, setGlobalDialog]);

  const handleCloseIOS = useCallback(() => {
    setGlobalDialog(null);
    setIosPopupVisibility(false);
    history.replace('/home');
  }, [history, setGlobalDialog]);

  const handleConfirm = async () => {
    setSkipChromeChecked(true);
    if (installEvent) {
      (installEvent as any).prompt();
      (installEvent as any).userChoice.then(() => {
        setChromePopupVisibility(false);
        clearInstallEvent();
      });
    }
  };

  useEffect(() => {
    if (
      !dialogWasOpenedEarlierRef.current &&
      !dataGlobalDialog?.dialogId &&
      isIntroCompleted &&
      isMobile() &&
      !isRunningStandalone() &&
      ((isChrome() && installEvent && !isChromeInstallationPopupSkipped) ||
        (!isIosInstallationPopupSkipped && isSafari()))
    ) {
      dialogWasOpenedEarlierRef.current = true;
      setGlobalDialog(GlobalDialogTypes.ADD_ICON);
    }
  }, [
    isIntroCompleted,
    installEvent,
    isChromeInstallationPopupSkipped,
    isIosInstallationPopupSkipped,
    setGlobalDialog,
    dataGlobalDialog,
  ]);

  return (
    <>
      {isIntroCompleted &&
        isMobile() &&
        !isRunningStandalone() &&
        isChrome() &&
        installEvent &&
        !isChromeInstallationPopupSkipped && (
          <Dialog
            open={chromePopupVisibility}
            onClose={handleClose}
            classes={{
              paper: classes.dialog,
            }}
          >
            <Box
              padding={4}
              display="grid"
              gridTemplateColumns="min-content auto min-content"
              gridColumnGap={15}
            >
              <IocnSample />
              <Box>
                <Typography
                  variant="subtitle2"
                  style={{ color: '#2C3E50', fontWeight: 700 }}
                >
                  {t('add-to-home-screen.name')}
                </Typography>
                <Typography variant="body2" style={{ color: '#879BB2' }}>
                  {t('add-to-home-screen.domain')}
                </Typography>
              </Box>
              <CloseIcon
                style={{ color: theme.palette.text.secondary }}
                onClick={handleClose}
              ></CloseIcon>
            </Box>
            <Divider />
            <Box padding={4} paddingTop={2} paddingBottom={2}>
              <Typography variant="body2" style={{ color: '#2C3E50' }}>
                {t('add-to-home-screen.chrome.description')}
              </Typography>
            </Box>
            <Box padding={4}>
              <Button
                onClick={handleConfirm}
                color="primary"
                style={{ width: '100%' }}
                variant="contained"
                id="butInstall"
              >
                {t('add-to-home-screen.chrome.button')}
              </Button>
            </Box>
          </Dialog>
        )}
      {isIntroCompleted &&
        isMobile() &&
        !isIosInstallationPopupSkipped &&
        isSafari() &&
        !isRunningStandalone() && (
          <Dialog
            open={iosPopupVisibility}
            onClose={handleRemindChange}
            classes={{
              paper: classes.dialog,
            }}
          >
            <Box
              padding={4}
              display="grid"
              gridTemplateColumns="min-content auto min-content"
              gridColumnGap={15}
            >
              <IocnSample />
              <Box>
                <Typography
                  variant="subtitle2"
                  style={{ color: '#2C3E50', fontWeight: 700 }}
                >
                  {t('add-to-home-screen.name')}
                </Typography>
                <Typography variant="body2" style={{ color: '#879BB2' }}>
                  {t('add-to-home-screen.domain')}
                </Typography>
              </Box>
              <CloseIcon
                style={{ color: theme.palette.text.secondary }}
                onClick={handleCloseIOS}
              ></CloseIcon>
            </Box>
            <Divider />
            <Box>
              <Box display="grid" gridRowGap={10} padding={4}>
                <Typography variant="body2" className={classes.body2}>
                  {t('add-to-home-screen.safari.description')}
                </Typography>
                <Typography variant="body2" className={classes.body2}>
                  {t('add-to-home-screen.safari.step-1-first-half')}
                  <SafariShareIcon
                    style={{ paddingLeft: 8, paddingRight: 8 }}
                  />{' '}
                  {t('add-to-home-screen.safari.step-1-second-half')}
                </Typography>
                <Typography variant="body2" className={classes.body2}>
                  {t('add-to-home-screen.safari.step-2')}
                </Typography>
                <Box display="grid" paddingX={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            fontSize="small"
                            style={{ marginRight: 10 }}
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon
                            fontSize="small"
                            style={{ marginRight: 10 }}
                          />
                        }
                        checked={remindChecked}
                        onChange={handleRemindChange}
                        style={{
                          paddingLeft: 0,
                          paddingRight: 0,
                          color: '#C6D5E3',
                        }}
                      />
                    }
                    label={
                      <Typography variant="caption">
                        {t('add-to-home-screen.safari.remind-later-checkbox')}
                      </Typography>
                    }
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            fontSize="small"
                            style={{ marginRight: 10 }}
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon
                            fontSize="small"
                            style={{ marginRight: 10 }}
                          />
                        }
                        checked={skipChecked}
                        onChange={handleSkipChange}
                        style={{
                          paddingLeft: 0,
                          paddingRight: 0,
                          color: '#C6D5E3',
                        }}
                      />
                    }
                    label={
                      <Typography variant="caption">
                        {t('add-to-home-screen.safari.skip-checkbox')}
                      </Typography>
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Dialog>
        )}
    </>
  );
};

export default AddIcon;
