import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, ButtonGroup, makeStyles, Paper } from '@material-ui/core';

import QuizMembersList from './members-list';
import {
  QuizTimeSessionStatus,
  UserQuizSessionSettingsUserStatus,
} from '@/graphql';
import TimeToFinalRanking from './time-to-final-ranking';

interface MembersTabProps {
  sessionId: string;
  finishDateTime: string | null | undefined;
  quizStatus: QuizTimeSessionStatus;
  userStatus?: UserQuizSessionSettingsUserStatus | null | undefined;
}

const useStyles = makeStyles((theme) => ({
  navList: {
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
  },
  navBtn: {
    width: '50%',
  },
  navBtnDisabled: {
    borderColor: '#C6D5E3',
    color: theme.palette.action.disabled,
  },
}));

const QuizTimeParticipantsTab: React.FC<MembersTabProps> = ({
  sessionId,
  quizStatus,
  userStatus,
  finishDateTime,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [navBtnActiveId, setNavBtnActiveId] = useState(
    quizStatus &&
      (quizStatus === QuizTimeSessionStatus.Finished ||
        quizStatus === QuizTimeSessionStatus.WaitingForFinish)
      ? 2
      : 1
  );

  const nav = React.useMemo(() => {
    const navBtnConfig = [
      {
        id: 1,
        label: t('classrooms.list'),
      },
      {
        id: 2,
        label: t('classrooms.ranking'),
      },
    ];
    return (
      <>
        {quizStatus &&
          quizStatus === QuizTimeSessionStatus.InProgress &&
          (userStatus === UserQuizSessionSettingsUserStatus.InProgress ||
            userStatus === UserQuizSessionSettingsUserStatus.Paused) && (
            <ButtonGroup
              color="primary"
              fullWidth
              aria-label="outlined primary button group"
            >
              {navBtnConfig.map((btn) => (
                <Button
                  key={btn.id}
                  className={`${classes.navBtn} ${
                    navBtnActiveId !== btn.id ? classes.navBtnDisabled : ''
                  }`}
                  onClick={() => setNavBtnActiveId(btn.id)}
                >
                  {btn.label}
                </Button>
              ))}
            </ButtonGroup>
          )}
      </>
    );
  }, [
    t,
    classes.navBtn,
    classes.navBtnDisabled,
    navBtnActiveId,
    quizStatus,
    userStatus,
  ]);

  return (
    <>
      {quizStatus &&
        quizStatus === QuizTimeSessionStatus.InProgress &&
        (userStatus === UserQuizSessionSettingsUserStatus.InProgress ||
          userStatus === UserQuizSessionSettingsUserStatus.Paused) && (
          <Paper square elevation={0} className={classes.navList}>
            <Box display="flex" justifyContent="center" px={4} py={3}>
              {nav}
            </Box>
          </Paper>
        )}
      {quizStatus &&
        quizStatus === QuizTimeSessionStatus.WaitingForFinish &&
        finishDateTime && (
          <TimeToFinalRanking finishDateTime={finishDateTime} />
        )}
      <Box px={2} pb={14} mt={2}>
        <Paper elevation={0}>
          {quizStatus && (
            <QuizMembersList
              quizSessionId={sessionId}
              navBtnActiveId={navBtnActiveId}
            />
          )}
        </Paper>
      </Box>
    </>
  );
};

export default QuizTimeParticipantsTab;
