import React from 'react';
import { Box, Paper } from '@material-ui/core';
import StackLayout from '../../components/stack-layout';
import { useImprintQuery } from '@/graphql';
import { useLocation } from 'react-router-dom';

const Imprint: React.FC = () => {
  const { data } = useImprintQuery();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const hideBackArrow = params.get('hideBackArrow') ? true : false;

  return (
    <StackLayout
      back="/home?leftMenu=true"
      hideBackArrow={hideBackArrow}
      title="Impressum"
    >
      <Box clone p={2} className="privacy-page">
        <Paper elevation={0}>
          {data?.impressum?.text && (
            <div dangerouslySetInnerHTML={{ __html: data.impressum?.text }} />
          )}
        </Paper>
      </Box>
    </StackLayout>
  );
};

export default Imprint;
