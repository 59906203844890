import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: 24,
    width: 40,
  },
});

const MastercardIcon: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 40 24"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <rect width="40" height="24" rx="4" fill="#EAEAF3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1057 20C29.4658 20 33.0004 16.4183 33.0004 12C33.0004 7.58172 29.4658 4 25.1057 4C20.7455 4 17.2109 7.58172 17.2109 12C17.2109 16.4183 20.7455 20 25.1057 20Z"
        fill="url(#paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8947 20C20.2549 20 23.7895 16.4183 23.7895 12C23.7895 7.58172 20.2549 4 15.8947 4C11.5346 4 8 7.58172 8 12C8 16.4183 11.5346 20 15.8947 20Z"
        fill="url(#paint1_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5004 5.50134C18.5082 6.95358 17.2109 9.32325 17.2109 11.9999C17.2109 14.6766 18.5082 17.0463 20.5004 18.4985C22.4926 17.0463 23.7899 14.6766 23.7899 11.9999C23.7899 9.32325 22.4926 6.95358 20.5004 5.50134Z"
        fill="url(#paint2_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="17.2109"
          y1="4"
          x2="17.2109"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F79F1A" />
          <stop offset="1" stopColor="#FFC874" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="8"
          y1="4"
          x2="8"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA001B" />
          <stop offset="1" stopColor="#FF4848" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="17.2109"
          y1="5.50134"
          x2="17.2109"
          y2="18.4985"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5F01" />
          <stop offset="1" stopColor="#FF7727" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default MastercardIcon;
