import { useApolloClient } from '@apollo/client';
import { useGlobalDialogQuery } from '@/graphql';
import { GLOBAL_DIALOG } from '@/apollo/queries';
import { GlobalDialogTypes } from '@/type';

export const useGlobalDialog = () => {
  const { cache } = useApolloClient();
  const { data } = useGlobalDialogQuery();

  const setGlobalDialog = (dialogId: GlobalDialogTypes | null) => {
    cache.writeQuery({
      query: GLOBAL_DIALOG,
      data: {
        dialogId,
      },
    });
  };

  return { data, setGlobalDialog };
};
