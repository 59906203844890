import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { datadogRum } from '@datadog/browser-rum';

import {
  useCancelUserSubscriptionMutation,
  UserSubscriptionDocument,
} from '@/graphql';
import { WebActionType } from '../../in-app-subscriptions/types';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';

type DialogCancelSubscriptionProps = {
  isOpened: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  setSubsCancelingInProgress: (arg: boolean) => void;
};

const DialogCancelSubscription: React.FC<DialogCancelSubscriptionProps> = ({
  isOpened,
  onClose,
  setSubsCancelingInProgress,
}) => {
  const { t } = useTranslation();
  const { premiumFinishDate } = useGetPremiumData();

  const [cancelUserSubscription, { loading }] =
    useCancelUserSubscriptionMutation();

  const handleCancelSubscription = useCallback(async () => {
    if (window?.ReactNativeWebView) {
      setSubsCancelingInProgress(true);
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: WebActionType.CancelSubscription,
          payload: null,
        })
      );
      onClose(false);
      datadogRum.addAction('in-app-subscription', {
        event: 'click CANCEL button',
      });
    } else {
      try {
        await cancelUserSubscription({
          refetchQueries: [{ query: UserSubscriptionDocument }],
        });
      } catch (error) {
        console.error('useCancelUserSubscriptionMutation', error);
      } finally {
        onClose(false);
      }
    }
  }, [cancelUserSubscription, onClose, setSubsCancelingInProgress]);

  return (
    <Dialog open={isOpened} onClose={() => onClose(false)}>
      <DialogTitle>{t('payment.cancel-subscription')}</DialogTitle>
      <DialogContent>
        <DialogContentText variant="body2">
          {t('payment.cancel-subscription-content', {
            endDate: DateTime.fromISO(premiumFinishDate).toFormat('dd/LL/yyyy'),
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box component="span" style={{ color: '#647485' }}>
          <Button color="inherit" onClick={() => onClose(false)}>
            {t('common.not-now')}
          </Button>
        </Box>

        <Button
          data-cy-confirm-code-btn
          onClick={handleCancelSubscription}
          disabled={loading}
          color="secondary"
        >
          {t('payment.cancel-subscription')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCancelSubscription;
