import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    fontSize: 'inherit',
    verticalAlign: 'middle',
    height: 44,
    width: 44,
  },
});

const Darts: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 44 44"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path
        d="M38.519 14.3347C38.0208 14.5547 37.7952 15.1352 38.0138 15.6334C39.0683 18.0264 39.6034 20.5755 39.6034 23.214C39.6034 33.5893 31.1627 42.03 20.786 42.03C10.4107 42.03 1.97002 33.5893 1.97002 23.214C1.97002 12.8373 10.4107 4.39661 20.786 4.39661C23.4231 4.39661 25.975 4.93312 28.368 5.98767C28.8676 6.20341 29.4467 5.982 29.6681 5.48381C29.8867 4.98563 29.661 4.40371 29.1643 4.18371C26.5186 3.01845 23.6999 2.42659 20.7874 2.42659C9.32496 2.42659 0 11.7515 0 23.214C0 34.675 9.32496 44 20.786 44C32.2485 44 41.5734 34.675 41.5734 23.214C41.5734 20.3016 40.983 17.4828 39.8177 14.84C39.5963 14.3418 39.0158 14.1176 38.519 14.3347Z"
        fill="#45B3EE"
      />
      <path
        d="M26.4123 13.4533C26.6436 12.9594 26.4293 12.3732 25.9368 12.1447C24.3173 11.391 22.5858 11.0078 20.7875 11.0078C14.0571 11.0078 8.5813 16.4836 8.5813 23.214C8.5813 29.9444 14.0571 35.4188 20.7875 35.4188C27.5179 35.4188 32.9937 29.9444 32.9937 23.214C32.9937 21.4129 32.609 19.6785 31.8525 18.0576C31.6212 17.5623 31.0364 17.3494 30.5439 17.5807C30.05 17.8106 29.8371 18.3968 30.067 18.8893C30.7015 20.249 31.0222 21.7038 31.0222 23.214C31.0222 28.8572 26.4307 33.4487 20.7861 33.4487C15.1428 33.4487 10.5513 28.8572 10.5513 23.214C10.5513 17.5694 15.1428 12.9778 20.7861 12.9778C22.2948 12.9778 23.7482 13.2986 25.1036 13.9288C25.5962 14.1601 26.1823 13.9458 26.4123 13.4533Z"
        fill="#3498DB"
      />
      <path
        d="M21.043 19.9666C21.5795 20.0163 22.0592 19.6018 22.1004 19.0582C22.1415 18.516 21.7356 18.042 21.1934 18.0008C21.06 17.9909 20.9238 17.9852 20.7861 17.9852C17.9049 17.9852 15.5587 20.3299 15.5587 23.2126C15.5587 26.0938 17.9034 28.44 20.7861 28.44C23.6687 28.44 26.0135 26.0952 26.0135 23.2126C26.0135 23.0763 26.0092 22.9401 25.9993 22.8052C25.9581 22.2631 25.4855 21.8614 24.9419 21.8969C24.3997 21.938 23.9923 22.4107 24.0335 22.9543C24.0406 23.0394 24.0434 23.126 24.0434 23.2126C24.0434 25.008 22.583 26.4685 20.7861 26.4685C18.9906 26.4685 17.5302 25.008 17.5302 23.2126C17.5302 21.3306 19.2021 19.8147 21.043 19.9666Z"
        fill="#2B75B3"
      />
      <path
        d="M43.9153 5.72367C43.7506 5.35181 43.3915 5.10201 42.97 5.13466L38.6765 5.32201L38.8625 1.02855C38.8795 0.622625 38.6467 0.247924 38.2749 0.0832821C37.9044 -0.0799401 37.4701 0.000961333 37.182 0.289084L30.6474 6.82365C30.4246 7.04648 30.3224 7.36157 30.3721 7.67383L30.9937 11.6139L20.302 22.3042C19.9173 22.6889 19.9173 23.3119 20.302 23.6966C20.495 23.8882 20.7462 23.9847 20.9989 23.9847C21.2515 23.9847 21.5027 23.8882 21.6957 23.6966L32.3875 13.0062L36.3261 13.6279C36.3772 13.6364 36.4283 13.6407 36.4794 13.6407C36.7392 13.6407 36.9904 13.5385 37.1763 13.3525L43.7109 6.81797C43.9976 6.52843 44.0799 6.09412 43.9153 5.72367ZM36.1374 11.6025L32.907 11.093L32.3974 7.86118L36.786 3.47263L36.6639 6.30985C36.6526 6.58662 36.7562 6.85346 36.9521 7.04932C37.1479 7.24377 37.4077 7.3488 37.6915 7.33745L40.5287 7.21538L36.1374 11.6025Z"
        fill="#4A647C"
      />
    </SvgIcon>
  );
};

export default Darts;
