import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import WavingHandIcon from '@/components/icons/waving-hand';
import { Paper, Typography } from '@material-ui/core';

const OnboardWelcome: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleConfirm = useCallback(() => {
    history.replace('/home?isOnboardingActive=true&step=1', {
      prevPage: '/home',
    });
  }, [history]);

  return (
    <Box
      p={2}
      display="grid"
      style={{
        height: '-webkit-fill-available',
        background: '#D7D7D7',
      }}
    >
      <Box clone px={2} py={6} display="flex" flexDirection="column">
        <Paper elevation={0}>
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
            px={4}
            textAlign="center"
          >
            <Typography variant="h2">{t('dialogs.welcome.title')}</Typography>
            <WavingHandIcon />
          </Box>
          <Box pt={2}>
            <Button
              onClick={handleConfirm}
              color="primary"
              variant="contained"
              fullWidth
            >
              {t('dialogs.welcome.cta')}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default OnboardWelcome;
