import React, { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { uniq } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AppTab, AppTabs, TabPanel } from '@/components/tabs';
import { useLectorQuery } from '@/graphql';
import { Skeleton } from '@material-ui/lab';
import { Box, Paper } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import StackLayout from '../../../components/stack-layout';
import OrgHeader from '../../org-header';
import OrgOverview from '../../org-overview';
import LectureItem from '../../lectures/lecture-item';
import { useStyles } from './styles';
import DropdownMenu from '../../../components/dropdown-menu';
import { useTabsNav } from '@/hooks/useTabsNav';
import { TabNavIdsEnum } from '@/type';
import { HEIGHT_TOPBAR_PX, REPORT_EMAIL } from '@/utils/constants';

type ClassRoomParams = {
  lectorId: string;
};

const ClassroomLector = () => {
  const { t } = useTranslation();
  const { lectorId } = useParams<ClassRoomParams>();
  const classes = useStyles();
  const { value, handleChange } = useTabsNav(TabNavIdsEnum.LECTOR);
  const [expandedNumber, setExpanded] = React.useState<number | false>(1);
  const toggleAccordion =
    (numb: number) =>
    (
      // eslint-disable-next-line @typescript-eslint/ban-types
      event: React.ChangeEvent<{}>,
      isExpanded: boolean
    ) => {
      setExpanded(isExpanded ? numb : false);
    };

  const [tabsHeight, setTabsHeight] = React.useState<number | null>(null);
  const elementRef = useRef<HTMLDivElement | null>(null);

  const { data, loading } = useLectorQuery({
    variables: { lectorId },
  });

  const lectorName = useMemo(
    () =>
      data?.lector
        ? data?.lector?.firstName + ' ' + data?.lector?.lastName
        : '',
    [data]
  );

  const classroomsCount = useMemo(() => {
    const classroomsArr: ''[] = [];
    data?.lector?.lectureSet.forEach((lec) =>
      lec.classroomSet.forEach((it) => classroomsArr.push(it.id))
    );
    return uniq(classroomsArr).length;
  }, [data]);

  const dropMenuOptions = useMemo(
    () => [
      {
        id: '001',
        link: { pathname: `mailto:${REPORT_EMAIL}` },
        label: t('classrooms.report-profile'),
        targetBlank: true,
      },
    ],
    [t]
  );

  useEffect(() => {
    if (elementRef.current) {
      const maxHeight =
        window.innerHeight - elementRef.current?.offsetTop - HEIGHT_TOPBAR_PX;
      if (maxHeight) setTabsHeight(maxHeight);
    }
  }, [data]);

  return (
    <StackLayout
      title={t('classrooms.title-lecturer')}
      toolbar={<DropdownMenu btn={<MoreVert />} options={dropMenuOptions} />}
    >
      <OrgHeader
        name={lectorName}
        image={data?.lector?.photo}
        // averageRate={data?.lector?.aggregatedRate?.averageRate}
        // feedbackCount={data?.lector?.aggregatedRate?.feedbackCount}
        professions={data?.lector?.professions}
        specialities={data?.lector?.specialities}
        location={data?.lector?.city?.title}
        classroomsCount={classroomsCount}
      />
      {loading ? (
        <Paper>
          <Box py={5} px={6}>
            {[...Array(15)].map((i, idx) => (
              <Skeleton key={idx} height={30} />
            ))}
          </Box>
        </Paper>
      ) : (
        <>
          <AppTabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            className={classes.lectorTabs}
          >
            <AppTab
              data-cy-classrooms-caption-overview
              label={t('common.overview')}
            />
            <AppTab
              data-cy-classrooms-caption-lectures
              label={`${t('class-room.lectures')} (${
                data?.lector?.lectureSet.length
              })`}
            />
          </AppTabs>
          <div
            ref={elementRef}
            style={{
              height: tabsHeight ? tabsHeight : 'auto',
              overflowY: 'auto',
            }}
          >
            <TabPanel value={value} index={0}>
              <OrgOverview
                about={data?.lector?.about}
                email={data?.lector?.email}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box p={2}>
                {data?.lector?.lectureSet?.map((it, idx) => {
                  return (
                    it && (
                      <Paper key={it.id} elevation={0}>
                        <Box mb={2}>
                          <LectureItem
                            number={idx + 1}
                            title={it.title}
                            description={it.description}
                            expanded={expandedNumber === idx + 1}
                            withRating
                            handleChange={toggleAccordion}
                          />
                        </Box>
                      </Paper>
                    )
                  );
                })}
              </Box>
            </TabPanel>
          </div>
        </>
      )}
    </StackLayout>
  );
};

export default ClassroomLector;
