import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Typography } from '@material-ui/core';
import { isBoolean, isNumber } from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import DurationIcon from '@/components/icons/duration';
import { ClassroomTypes } from '../../../../type';
import { useStyles } from './styles';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import TogglerOfBlended from '../toggler-of-blended';
import { ClassRoomLearningSessionStatus } from '@/graphql';
import AvaList from '../ava-list';
import SharingButton from '../sharing-button';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
// import StarIcon from '@/components/icons/star';

type ClassRoomHeaderProps = {
  userSessionSettingsId?: string | null | undefined;
  sessionId?: string | null | undefined;
  image?: string | null;
  specialityGroupIcon?: string | null;
  title?: string;
  organizerId: string;
  organizerName?: string | null;
  certificate?: number | undefined;
  averageRate?: number | null;
  feedbackCount?: number | null;
  startDate?: string;
  finishDate?: string;
  finishDateCosExpiredPremium?: string;
  isForPremiumOnly?: boolean;
  indicator?: boolean | null | undefined;
  duration?: number | null;
  classroomType?: number | null | undefined;
  deepLinkHash: string | null | undefined;
  sessionStatus?: ClassRoomLearningSessionStatus | undefined | null;
};

const ClassRoomHeader: React.FC<ClassRoomHeaderProps> = ({
  userSessionSettingsId,
  sessionId,
  title,
  image,
  specialityGroupIcon,
  organizerId,
  organizerName,
  certificate,
  startDate,
  finishDate,
  isForPremiumOnly,
  duration,
  indicator,
  classroomType,
  finishDateCosExpiredPremium,
  sessionStatus,
  deepLinkHash,
  averageRate,
  feedbackCount,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isUserPremium, premiumFinishDate } = useGetPremiumData();
  const { crAvaGroupEnabled } = useFeatureFlags();

  const range = useMemo(() => {
    const start = startDate
      ? DateTime.fromISO(startDate).toFormat('dd.MM.yyyy')
      : '';
    const end =
      !isUserPremium &&
      isForPremiumOnly &&
      (premiumFinishDate || finishDateCosExpiredPremium) < (finishDate || 0)
        ? DateTime.fromISO(
            premiumFinishDate || finishDateCosExpiredPremium
          ).toFormat('dd.MM.yyyy')
        : DateTime.fromISO(finishDate || '').toFormat('dd.MM.yyyy');
    return `${start} - ${end}`;
  }, [
    finishDate,
    startDate,
    finishDateCosExpiredPremium,
    isForPremiumOnly,
    isUserPremium,
    premiumFinishDate,
  ]);

  return (
    <Box className={classes.root} style={{ backgroundImage: `url(${image})` }}>
      {specialityGroupIcon && (
        <LazyLoadImage
          effect="blur"
          wrapperClassName={classes.cardIcon}
          useIntersectionObserver
          src={specialityGroupIcon}
          alt={title}
        />
      )}
      <Box
        className={classes.overlay}
        style={{
          background: specialityGroupIcon
            ? 'linear-gradient(90deg, #1A2C3E 12.36%, rgba(26, 44, 62, 0.7) 100%)'
            : '#1A2C3E',
          opacity: specialityGroupIcon ? 1 : 0.88,
        }}
      >
        {classroomType === ClassroomTypes.NETWORK && !!deepLinkHash && (
          <Box
            style={{
              position: 'absolute',
              right: 12,
              top: 20,
              color: '#fff',
            }}
          >
            <SharingButton
              crTitle={title}
              deepLinkHash={deepLinkHash}
              sessionId={sessionId}
            />
          </Box>
        )}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {/* {averageRate ? (
            <>
              <StarIcon htmlColor="#F2C94D" style={{ marginRight: 6 }} />
              <Typography color="inherit" variant="caption">
                {averageRate}
              </Typography>
              <Typography variant="caption" style={{ marginLeft: 3 }}>
                ({feedbackCount})
              </Typography>
            </>
          ) : (
            <Chip label="NEW" color="primary" size="small" />
          )} */}
          <Box>
            {isForPremiumOnly && classroomType === ClassroomTypes.NETWORK && (
              <Chip
                label="PREMIUM"
                color="default"
                variant="outlined"
                size="small"
                className={classes.chipGolden}
                style={{ marginRight: 10 }}
              />
            )}
            {isNumber(certificate) && (
              <Chip
                label={'CME:' + certificate}
                color="secondary"
                size="small"
              />
            )}
          </Box>
          {classroomType === ClassroomTypes.BLENDED && isBoolean(indicator) && (
            <Typography
              variant="caption"
              className={indicator ? classes.statusIsActive : ''}
            >
              • {indicator ? t('common.active') : t('common.pause')}
            </Typography>
          )}
        </Box>
        <Box
          display="grid"
          gridRowGap={12}
          gridTemplateRows="min-content min-content"
          marginTop={3}
        >
          <Typography
            color="inherit"
            variant="h1"
            className="text-ellipsis-3lines"
          >
            {title}
          </Typography>
          <Typography color="inherit" variant="caption">
            {organizerName}
          </Typography>
        </Box>
        {classroomType === ClassroomTypes.NETWORK && crAvaGroupEnabled && (
          <AvaList sessionId={sessionId} />
        )}
        <Box display="flex" justifyContent="space-between">
          <Box
            display="grid"
            gridTemplateColumns="auto 1fr"
            alignItems="center"
            gridColumnGap={8}
          >
            <DurationIcon />
            <Typography color="inherit" variant="overline">
              {duration
                ? `${duration} ${
                    duration > 1 ? t('common.days') : t('common.day')
                  }`
                : range}
            </Typography>
          </Box>
          {classroomType === ClassroomTypes.BLENDED &&
            sessionStatus === ClassRoomLearningSessionStatus.InProgress && (
              <TogglerOfBlended
                indicator={indicator}
                userSessionSettingsId={userSessionSettingsId}
              />
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default ClassRoomHeader;
