import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import PlanItem from '../plan-item';
import AppButton from '@/components/app-button';
import { useUser } from '@/contexts/user-context';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import { plansConfig } from '../plansConfig';
import { SubscriptionStatus, SubscriptionTypes } from '@/type';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { useStyles } from './styles';
import { datadogRum } from '@datadog/browser-rum';
import { useUserDiscountsQuery } from '@/graphql';

type PlanChooserProps = {
  nextStepURL: string;
  chosenPlanId?: SubscriptionTypes;
};

const PlanChooser: React.FC<PlanChooserProps> = ({
  nextStepURL,
  chosenPlanId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { search } = useLocation();
  const { user } = useUser();
  const { subsDiscountsEnabled } = useFeatureFlags();
  const {
    isUserPremiumByCode,
    isUserPremiumByPayment,
    isUserStudent,
    isSpecificProfession,
  } = useGetPremiumData();

  const params = new URLSearchParams(search);

  const { data } = useUserDiscountsQuery();
  const activeDiscounts = data?.discounts?.filter((it) =>
    it?.subscriptionType?.includes(isUserStudent ? 'STUDENT' : 'PROFESSIONAL')
  );

  const filterPlans = (plan: any) => {
    if (chosenPlanId) {
      return plan.id === chosenPlanId;
    }
    if (
      (isUserPremiumByCode || isSpecificProfession) &&
      plan.id === SubscriptionTypes.Code
    ) {
      return true;
    }
    if (
      !isUserPremiumByPayment &&
      !isUserPremiumByCode &&
      plan.id === SubscriptionTypes.Referral
    ) {
      return true;
    }
    if (!isSpecificProfession) {
      if (
        (user?.customer?.subscription?.subscriptionType?.includes('Yearly') &&
          user?.customer?.subscription?.status === SubscriptionStatus.ACTIVE) ||
        user?.customer?.subscription?.status === SubscriptionStatus.TRIALING
      ) {
        return false;
      }
      let premiumPlans = [
        SubscriptionTypes.StudentMonthly,
        SubscriptionTypes.StudentYearly,
        SubscriptionTypes.ProfessionalMonthly,
        SubscriptionTypes.ProfessionalYearly,
      ].filter((it) => it.includes(isUserStudent ? 'Student' : 'Professional'));
      if (isUserPremiumByPayment) {
        premiumPlans = premiumPlans.filter((it) => it.includes('Yearly'));
      }
      return premiumPlans.includes(plan.id);
    }
    return false;
  };

  const filteredPlansConfig = plansConfig.filter(filterPlans);

  const [chosenId, setChosenId] = useState(
    params.get('planId') || filteredPlansConfig[0].id
  );

  const choosePlan = () => {
    datadogRum.addAction('subscription', {
      event: `go to payments, plan "${chosenId}" `,
    });
    const historyState: any = history.location.state;
    if (historyState) historyState['isRedirectedFormPlanChooser'] = true;
    history.push(
      chosenId === SubscriptionTypes.Referral
        ? '/invite-friends'
        : `${
            chosenId === SubscriptionTypes.Code ? '/payment' : nextStepURL
          }?planId=${chosenId}`,
      historyState ? historyState : { isRedirectedFormPlanChooser: true }
    );
  };

  const choosedPlan = useMemo(
    () =>
      filteredPlansConfig?.find((it) => it.id === chosenId) ||
      filteredPlansConfig[0],
    [chosenId, filteredPlansConfig]
  );

  return (
    <>
      <Box mb={2}>
        {filteredPlansConfig.map((plan) => (
          <div
            key={plan.id}
            onClick={() => {
              datadogRum.addAction('subscription', {
                event: `click plan "${plan.id}"`,
              });
              setChosenId(plan.id);
            }}
          >
            <PlanItem {...plan} isChosen={plan.id === chosenId} />
          </div>
        ))}
      </Box>
      {subsDiscountsEnabled === false ||
      chosenId === SubscriptionTypes.Referral ? (
        <Box mt={5} textAlign="center">
          <Typography variant="caption">{t(choosedPlan.info)}</Typography>
          <Box mt={3}>
            <AppButton
              fullWidth
              color="primary"
              variant="contained"
              onClick={choosePlan}
            >
              {t(choosedPlan.btnText)}
            </AppButton>
          </Box>
        </Box>
      ) : chosenId === SubscriptionTypes.Code ? (
        <Box mt={5}>
          {!isUserStudent && (
            <Box mb={3} textAlign="center">
              <Typography variant="caption" align="center">
                {t('subscription.ending')}
              </Typography>
            </Box>
          )}
          <AppButton
            fullWidth
            color="primary"
            variant="contained"
            onClick={choosePlan}
          >
            {t(choosedPlan.btnText)}
          </AppButton>
        </Box>
      ) : (
        activeDiscounts?.find(
          (it) =>
            it?.subscriptionType === chosenId.toUpperCase().replace(/ /g, '_')
        ) && (
          <Box className={classes.discountBox}>
            <Typography variant="h2" color="inherit">
              {t('subscription.discount-title', {
                discountPercent: choosedPlan.discountPercent,
              })}
            </Typography>
            <Box my={3}>
              <Typography variant="caption" color="inherit">
                {t('subscription.total-amount')}
              </Typography>
              <Box display="flex" alignItems="baseline">
                <Typography className={classes.priceTitle} color="inherit">
                  {choosedPlan.discountPrice}
                </Typography>
                {choosedPlan.discountProfit && (
                  <Typography
                    color="inherit"
                    variant="body1"
                    style={{ marginLeft: 5 }}
                  >
                    ({choosedPlan.discountProfit}/{t('payment.month')})
                  </Typography>
                )}
              </Box>
            </Box>
            <Box mb={3}>
              <Typography variant="caption">{t(choosedPlan.info)}</Typography>
            </Box>
            <AppButton
              fullWidth
              color="primary"
              variant="contained"
              onClick={choosePlan}
            >
              {t(choosedPlan.btnText)}
            </AppButton>
          </Box>
        )
      )}
    </>
  );
};

export default PlanChooser;
