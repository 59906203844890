import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { getSubscriptionsPath } from '@/utils/helpers';

const CtaSubscription = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleConfirm = (url?: string, urlState?: Record<string, unknown>) => {
    if (url) history.push(url, urlState);
  };

  const onClose = () => {
    history.push('/notifications');
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{t('dialogs.cta-subscription.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText variant="body2">
          {t('dialogs.cta-subscription.content')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" style={{ color: '#647485' }} onClick={onClose}>
          {t('common.later')}
        </Button>
        <Button
          onClick={() =>
            handleConfirm(getSubscriptionsPath(), {
              prevPage: '/notifications',
            })
          }
          color="primary"
        >
          {t('common.lets-go')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CtaSubscription;
