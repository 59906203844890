import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Link, Typography } from '@material-ui/core';

import AppButton from '@/components/app-button';

const SubscribedToNews: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const userEmail = query.get('email')?.replaceAll('/', '');

  return (
    <>
      <Box mt={3}>
        <Typography variant="h2">{t('subscribed-to-news.title')}</Typography>
      </Box>
      <Box mt={3} mb={7}>
        <Typography variant="body2" component="span">
          {t('subscribed-to-news.text1')}
        </Typography>
        {userEmail && <Link href={`mailto:${userEmail}`}>{userEmail}</Link>}
        <Typography variant="body2" component="span">
          {t('subscribed-to-news.text2')}
        </Typography>
      </Box>
      <AppButton
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => history.push('/')}
      >
        {t('subscribed-to-news.button')}
      </AppButton>
    </>
  );
};

export default SubscribedToNews;
