import React from 'react';
import { FieldError } from 'react-hook-form';

import { DatePicker, DatePickerProps } from '@material-ui/pickers';

import Error from '../error';

interface DateFieldProps extends DatePickerProps {
  errors?: FieldError;
}

const DateField: React.FC<DateFieldProps> = ({
  format = 'dd/MM/yyyy',
  errors = null,
  ...rest
}) => {
  return (
    <>
      <DatePicker format={format} {...rest} />
      <Error errors={errors} />
    </>
  );
};

export default DateField;
