import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, Redirect } from 'react-router-dom';
import { isNumber, shuffle, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useApolloClient } from '@apollo/client';

import { Box, Button, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import ChooseList from '@/components/choose-list';
import Dot from '@/components/dot';
import { useUser } from '@/contexts/user-context';
import AppButton from '@/components/app-button';
import QuestionImage from '@/components/question-image';
import { useCountdown } from '@/hooks/useCountdown';
import {
  useSetUserAnswerMutation,
  useUserDuelQuery,
  UserDuelQuery,
  UserDuelQueryVariables,
  useQuizTimeDetailsQuery,
  useQuizScoreLazyQuery,
  useGetActiveQuizDuelsLazyQuery,
  useGetUserQuizSessionSettingsQuery,
  UserQuizSessionSettingsUserStatus,
} from '@/graphql';
import { USER_DUEL_QUERY, USER_QUIZ_SESSION_SETTINGS } from '@/apollo/queries';

import CorrectAnswer from './correct-answer';
import InCorrectAnswer from './incorrect-answer';
import Fallback from '@/components/fallback';
import StackLayout from '@/screens/main/components/stack-layout';
import { useStyles } from './styles';
import CheckIcon from '@/components/icons/check';

interface QuestionParams {
  duelId: string;
  roundId: string;
  questionId: string;
  sessionId: string;
}

const QuizDuelQuestion: React.FC = ({ ...rest }) => {
  const classes = useStyles();
  const [answerId, setAnswerId] = useState<string | null>(null);
  const { duelId, roundId, questionId, sessionId } =
    useParams<QuestionParams>();
  const { user } = useUser();
  const { t } = useTranslation();
  const { cache } = useApolloClient();

  const { data: userQuizSettingsData } = useGetUserQuizSessionSettingsQuery({
    variables: { quizSessionId: sessionId },
  });

  const [getActiveQuizDuels] = useGetActiveQuizDuelsLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: { quizSessionId: sessionId },
  });
  const [getQuizScore] = useQuizScoreLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: { quizSessionId: sessionId },
  });

  const { data, error } = useUserDuelQuery({ variables: { duelId } });
  const [setAnswer, { loading: setAnswerLoading }] = useSetUserAnswerMutation();
  const { data: quizTimeData } = useQuizTimeDetailsQuery({
    variables: { quizSessionId: sessionId },
  });

  const round = (data?.duelData?.rounds || []).find((it) => it?.id === roundId);

  const questions = useMemo(
    () => sortBy(round?.userQuestions || [], ['questionNumber']),
    [round]
  );

  const userQuestions = useMemo(
    () =>
      sortBy(
        questions.filter(
          (it) => !!it?.user && !!user && it.user.id === user.id
        ),
        ['questionNumber']
      ),
    [questions, user]
  );

  const indx = useMemo(
    () => userQuestions.findIndex((it) => it.id === questionId),
    [questionId, userQuestions]
  );

  const currentUserQ = useMemo(
    () => (indx < userQuestions.length ? userQuestions[indx] : null),
    [indx, userQuestions]
  );

  const dots = useMemo(
    () =>
      round?.userQuestions ? (
        userQuestions.map((it) => {
          if (!it.userAnswer || !it.correctAnswer)
            return (
              <Dot
                key={`dot-${it.id}`}
                size="large"
                variant={
                  it?.questionNumber === currentUserQ?.questionNumber ||
                  (!!it?.questionNumber &&
                    !!currentUserQ?.questionNumber &&
                    !!currentUserQ?.userAnswer &&
                    it?.questionNumber - currentUserQ?.questionNumber === 1)
                    ? 'highlighted'
                    : 'default'
                }
              />
            );
          return it.userAnswer.value === it.correctAnswer.value ? (
            <Dot key={`dot-${it.id}`} variant="success" size="large">
              <Box
                component="span"
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ width: '100%', height: '100%' }}
              >
                <CheckIcon style={{ width: 12, height: 12, color: '#fff' }} />
              </Box>
            </Dot>
          ) : (
            <Dot key={`dot-${it.id}`} variant="error" size="large" />
          );
        })
      ) : (
        <>
          <Dot size="large" /> <Dot size="large" /> <Dot size="large" />
        </>
      ),
    [round, userQuestions, currentUserQ]
  );

  const options = useMemo(() => {
    if (!currentUserQ?.question?.answers)
      return [...Array(4)].map((_, i) => ({
        value: `skeletorn-${i}`,
        label: <Skeleton />,
      }));
    const answersMixed = shuffle(currentUserQ?.question?.answers || []);
    return answersMixed.map((it) => ({
      value: String(it.id),
      label: it.value,
    }));
  }, [currentUserQ]);

  const nextLink = useMemo(() => {
    if (indx < 0) return '';

    if (indx >= userQuestions.length - 1)
      return `/quiz-duel/${duelId}/overview/session/${sessionId}`;

    const next = userQuestions[indx + 1];

    return `/quiz-duel/${duelId}/round/${roundId}/question/${next.id}/session/${sessionId}`;
  }, [duelId, indx, roundId, userQuestions, sessionId]);

  const hasAnswer = useMemo(
    () => !!currentUserQ && currentUserQ.userAnswer,
    [currentUserQ]
  );

  const isCorrect = useMemo(
    () =>
      hasAnswer &&
      !!currentUserQ &&
      !!currentUserQ.userAnswer &&
      !!currentUserQ.correctAnswer &&
      currentUserQ.userAnswer.value === currentUserQ.correctAnswer.value,
    [currentUserQ, hasAnswer]
  );

  const isInCorrect = useMemo(
    () =>
      hasAnswer &&
      !!currentUserQ &&
      !!currentUserQ.userAnswer &&
      !!currentUserQ.correctAnswer &&
      currentUserQ.userAnswer.value !== currentUserQ.correctAnswer.value,
    [currentUserQ, hasAnswer]
  );

  const handleSetAnswer = useCallback(async () => {
    try {
      if (currentUserQ?.question?.id) {
        const res = await setAnswer({
          variables: {
            duelRoundQuestionAnswerId: currentUserQ.id,
            answerId,
          },
          update: (store, { data }) => {
            const newDuel =
              data?.setAnswer?.duelRoundQuestionAnswer?.duelRound?.duel;

            const oldData = store.readQuery<
              UserDuelQuery,
              UserDuelQueryVariables
            >({
              query: USER_DUEL_QUERY,
              variables: { duelId },
            });

            if (!newDuel || !oldData) return;

            const duelData = {
              ...oldData.duelData,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              status: newDuel.status as any,
              userScore: newDuel.result?.currentUserData,
              opponentScore: newDuel.result?.opponentData,
            };

            store.writeQuery<UserDuelQuery, UserDuelQueryVariables>({
              query: USER_DUEL_QUERY,
              variables: { duelId },
              data: { duelData },
            });
          },
        });
        if (currentUserQ.questionNumber === 2 && res) {
          getActiveQuizDuels();
          getQuizScore();
          cache.writeQuery({
            query: USER_QUIZ_SESSION_SETTINGS,
            variables: {
              quizSessionId: sessionId,
            },
            data: {
              userQuizSessionSettings: {
                ...userQuizSettingsData?.userQuizSessionSettings,
                userStatus: UserQuizSessionSettingsUserStatus.InProgress,
              },
            },
          });
        }
      }
    } catch (error) {
      console.error('SET_ANSWER', error);
    } finally {
      setAnswerId(null);
    }
  }, [
    answerId,
    currentUserQ,
    duelId,
    setAnswer,
    getQuizScore,
    getActiveQuizDuels,
    cache,
    sessionId,
    userQuizSettingsData,
  ]);

  const roundNumberNode = isNumber(currentUserQ?.questionNumber) ? (
    t('common.question', {
      question: currentUserQ?.questionNumber
        ? currentUserQ?.questionNumber + 1
        : 1,
    })
  ) : (
    <Skeleton width={100} />
  );

  const questionNode = currentUserQ?.question?.value ?? (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );

  const nextAnswerNode = useMemo(() => {
    if (indx < 0) return <>&nbsp;</>;

    if (indx >= 0 && indx < userQuestions.length - 1) return t('common.next');

    return t('common.finish');
  }, [indx, t, userQuestions.length]);

  const setAnswerNode = useMemo(() => {
    if (!data?.duelData?.rounds) return <>&nbsp;</>;

    return t('common.submit-answer');
  }, [data, t]);

  if (!!error) {
    console.error('QUESTION_QUERY', error);
    return (
      <StackLayout title={t('common.duel.title')} hideBackArrow>
        <Fallback />
      </StackLayout>
    );
  }

  return (
    <StackLayout
      title={t('common.duel.title')}
      toolbar={
        quizTimeData?.quizTimeSession?.duelDuration &&
        round?.created && (
          <TimeLeft
            duelId={duelId}
            sessionId={sessionId}
            created={round?.created}
            duelDuration={quizTimeData?.quizTimeSession?.duelDuration}
          />
        )
      }
      hideBackArrow
    >
      <Box
        clone
        data-cy-question
        display="grid"
        gridTemplateRows="1fr auto"
        paddingX={4}
        paddingY={5}
        className={classes.content}
      >
        <Paper elevation={0}>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              mx={-4}
              pb={5}
              px={4}
            >
              <Box
                display="grid"
                gridTemplateColumns="auto auto auto"
                gridColumnGap={12}
              >
                {dots}
              </Box>
              <Typography variant="overline">{roundNumberNode}</Typography>
            </Box>
            <Box paddingBottom={3}>
              <Typography variant="subtitle1">{questionNode}</Typography>
            </Box>
            <QuestionImage
              src={currentUserQ?.question?.questionImage ?? undefined}
              alt={currentUserQ?.question?.value}
            />
            {!hasAnswer && (
              <ChooseList
                data-cy-answers
                options={options}
                value={answerId}
                onChange={setAnswerId}
              />
            )}
            {currentUserQ && isCorrect && (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              <CorrectAnswer userQuestion={currentUserQ as any} />
            )}
            {currentUserQ && isInCorrect && (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              <InCorrectAnswer userQuestion={currentUserQ as any} />
            )}
          </Box>
          <Box paddingTop={2}>
            {!hasAnswer && (
              <AppButton
                fullWidth
                data-cy-submit-answer-btn
                color="primary"
                variant="contained"
                disabled={!answerId}
                loading={setAnswerLoading}
                onClick={handleSetAnswer}
              >
                {setAnswerNode}
              </AppButton>
            )}
            {hasAnswer && (
              <Button
                fullWidth
                data-cy-submit-answer-next
                color="primary"
                variant="contained"
                component={RouterLink}
                to={nextLink}
              >
                {nextAnswerNode}
              </Button>
            )}
          </Box>
        </Paper>
      </Box>
    </StackLayout>
  );
};

export default QuizDuelQuestion;

type TimeLeftProps = {
  duelId: string;
  sessionId: string;
  created: string;
  duelDuration: number;
};
const TimeLeft: React.FC<TimeLeftProps> = ({
  duelId,
  sessionId,
  created,
  duelDuration,
}) => {
  const duration = useMemo(() => {
    let timeUsed = +DateTime.local().minus(+DateTime.fromISO(created)) / 1000;
    timeUsed = timeUsed < 0 ? 0 : timeUsed;
    return (duelDuration * 60 || 0) - timeUsed;
  }, [created, duelDuration]);
  const { timeLeft, timeLeftInMillis } = useCountdown(duration * 1000, true);

  if (timeLeftInMillis < 1)
    return (
      <Redirect to={`/quiz-duel/${duelId}/overview/session/${sessionId}`} />
    );

  return (
    <Typography color="inherit" variant="h6" align="right">
      {timeLeftInMillis > 0 && timeLeft}
    </Typography>
  );
};
