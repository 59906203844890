import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  IconButton,
  List,
  Paper,
  Typography,
} from '@material-ui/core';
import { Search, ShareOutlined } from '@material-ui/icons';
import { useClassroomMembersQuery } from '@/graphql';
import MemberItem from './member-item';
import { useStyles } from './styles';
import { Participant } from '@/type';
import { useUser } from '@/contexts/user-context';
import { Skeleton } from '@material-ui/lab';
import { CLASSROOM_MAX_DUEL_COUNT } from '@/utils/constants';
import NotFoundIcon from '@/components/icons/not-found';
import { shareLink } from '@/utils/helpers';
import { useSnackbarContext } from '@/contexts/snackbar-context';
import { datadogRum } from '@datadog/browser-rum';

interface MembersListProps {
  sessionId: string;
  navBtnActiveId?: number;
  getNumberOfMembers?: React.Dispatch<React.SetStateAction<number>>;
  getIsDuelsDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  hideMagnifier?: boolean;
  searchStr?: string;
  roomInProgress?: boolean;
  isNetworkType?: boolean;
  isDuelsOff?: boolean;
  crTitle?: string | undefined;
  deepLinkHash?: string | null;
}

const MembersList: React.FC<MembersListProps> = ({
  sessionId,
  navBtnActiveId,
  hideMagnifier,
  searchStr,
  roomInProgress,
  isNetworkType,
  isDuelsOff,
  crTitle,
  deepLinkHash,
  getNumberOfMembers,
  getIsDuelsDisabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setCustomSnack } = useSnackbarContext();
  const [members, setMembers] = useState<Participant[]>();
  const { data, loading } = useClassroomMembersQuery({
    variables: { sessionId },
    fetchPolicy: 'cache-and-network',
  });

  const [isUserAvailableForDuel, setUserAvailableForDuel] = useState(false);

  const { user } = useUser();

  useEffect(() => {
    if (data?.classRoomMembers) {
      const copyClassRoomMembers = [...data.classRoomMembers];
      let isDuelsDisabled = true;
      let isUserDuelsDisabled = false;
      let membersList: Participant[] = copyClassRoomMembers
        .map((it) => {
          const member: Participant = {
            id: it?.id,
            userId: it?.userData?.user?.id,
            username: it?.userData?.user?.username || '',
            avatar: it?.userData?.user?.avatar || '',
            score: it?.userData?.classRoomCorrectAnswersCount || 0,
            rank: 0,
            isAvailableForDuel: !!it?.isAvailableForDuel,
            sessionSettingsIsClosed:
              it?.userData?.userClassRoomLearningSessionSettings?.isClosed,
          };
          if (it?.isAvailableForDuel === true && member.userId !== user?.id) {
            isDuelsDisabled = false;
          }
          if (
            member.userId === user?.id &&
            data?.startedDuelsToday &&
            data.startedDuelsToday >= CLASSROOM_MAX_DUEL_COUNT
          ) {
            isUserDuelsDisabled = true;
          }
          return member;
        })
        .sort((a, b) =>
          isNetworkType
            ? Number(b.isAvailableForDuel) - Number(a.isAvailableForDuel)
            : navBtnActiveId === 2
            ? b.score - a.score
            : a.username.localeCompare(b.username)
        );
      if (isNetworkType) {
        const actualMembersList = membersList?.filter(
          (member) => !member?.sessionSettingsIsClosed
        );
        const availableForDuel = actualMembersList
          .filter((member) => member.isAvailableForDuel)
          .sort((a, b) => b.score - a.score);
        const notAvailableForDuel = actualMembersList.filter(
          (member) => !member.isAvailableForDuel
        );
        membersList = availableForDuel.concat(notAvailableForDuel);
      }

      if (searchStr) {
        membersList = membersList?.filter((member) =>
          member?.username?.toLowerCase()?.includes(searchStr.toLowerCase())
        );
      }
      if (navBtnActiveId === 2 && membersList.length > 0) {
        let prevRank = 0;
        let prevScore = 0;
        membersList?.map((it, idx) => {
          it.rank = prevScore > it.score || idx === 0 ? prevRank + 1 : prevRank;
          it.joinedRank = prevRank === it.rank;
          prevRank = it.rank;
          prevScore = it.score;
          return it;
        });
      }
      getIsDuelsDisabled &&
        getIsDuelsDisabled(isDuelsDisabled || isUserDuelsDisabled);
      setUserAvailableForDuel(!isUserDuelsDisabled);
      setMembers(membersList);
      getNumberOfMembers && getNumberOfMembers(membersList?.length);
    }
  }, [
    data,
    searchStr,
    getNumberOfMembers,
    getIsDuelsDisabled,
    user,
    navBtnActiveId,
    isNetworkType,
  ]);

  const [isMemberSticky, setMemberSticky] = useState(false);
  useLayoutEffect(() => {
    if (navBtnActiveId === 2) {
      const elem = document?.getElementById('scrollContainer');
      const h = window?.innerHeight;
      const handleScroll = () => {
        const myMemberElem = elem?.querySelector('#myMemberRanglist');
        const myMemberPrevElem = myMemberElem?.previousElementSibling;
        const offset =
          myMemberPrevElem &&
          myMemberPrevElem?.getBoundingClientRect().bottom +
            myMemberPrevElem?.clientHeight;
        if (offset && offset > h + 10) {
          myMemberElem?.classList.add('sticky-member-ranglist');
          setMemberSticky(true);
        } else {
          myMemberElem?.classList.remove('sticky-member-ranglist');
          setMemberSticky(false);
        }
      };
      if (elem) elem.scrollTop = 0;
      setTimeout(() => {
        handleScroll();
      }, 100);
      elem?.addEventListener('scroll', debounce(handleScroll, 60), true);
      return () => {
        elem?.removeEventListener('scroll', handleScroll);
      };
    }
  }, [navBtnActiveId]);

  function showSnackbar() {
    setCustomSnack({
      visibility: true,
      message: 'sharing.link-copied',
    });
  }
  const handleOnClick = () => {
    datadogRum.addAction('classroom sharing', {
      deepLinkHash,
      classroomTitle: crTitle,
      sessionId,
    });
    shareLink({
      url: `${window.location.origin}/goto/${deepLinkHash}`,
      title: 'Humeo',
      text: `Hi! Ich lerne gerade mit Humeo das Thema ${crTitle}. Hast du Lust mit mir dazu zu quizzen? Dann klicke auf den Link! Ich freue mich!`,
      callback: showSnackbar,
    });
  };

  if (loading)
    return (
      <Paper>
        <Box py={5} px={6}>
          {[...Array(10)].map((i, idx) => (
            <Skeleton key={idx} height={30} />
          ))}
        </Box>
      </Paper>
    );

  return (
    <Paper elevation={0}>
      {navBtnActiveId !== 2 && members && members?.length > 1 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py={5}
          px={6}
        >
          <Typography variant="overline">
            {members?.length} {t('classrooms.attendees')}
          </Typography>
          {!hideMagnifier && !isNetworkType && (
            <IconButton
              color="primary"
              component={RouterLink}
              size="small"
              to={`/classroom/${sessionId}/search-members`}
            >
              <Search htmlColor="#dadada" />
            </IconButton>
          )}
        </Box>
      )}

      {!(isNetworkType && members && members?.length < 2) && (
        <List className={classes.memberList} component="div">
          {members?.map((member, i) => (
            <MemberItem
              key={member?.id}
              id={member?.id}
              userId={member?.userId}
              username={member?.username}
              avatar={member?.avatar}
              score={member?.score}
              isAvailableForDuel={
                !isDuelsOff &&
                roomInProgress &&
                isUserAvailableForDuel &&
                member?.isAvailableForDuel
              }
              rank={member.rank}
              joinedRank={member.joinedRank}
              isRanking={navBtnActiveId === 2}
              isMemberSticky={isMemberSticky}
              divider={i !== (members?.length || 1) - 1}
              sessionId={sessionId}
            />
          ))}
        </List>
      )}
      {searchStr && !members?.length && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          px={13}
          minHeight="70vh"
          textAlign="center"
        >
          <Typography variant="subtitle1">{t('common.no-results')}</Typography>
          <Typography variant="body2" style={{ marginTop: 4 }}>
            {t('common.no-results-descr')}
          </Typography>
        </Box>
      )}
      {isNetworkType && members && members?.length < 2 && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          px={2}
          py={6}
        >
          <NotFoundIcon />
          <Typography
            variant="body2"
            style={{
              marginTop: 8,
              marginBottom: 27,
              maxWidth: '60%',
              textAlign: 'center',
            }}
          >
            {t('classrooms.no-members.caption')}
          </Typography>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleOnClick}
            startIcon={<ShareOutlined />}
          >
            {t('classrooms.no-members.button')}
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default MembersList;
