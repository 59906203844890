import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  inputWrap: {
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
  },
  inputPlaceholder: {
    color: 'rgba(255,255,255,0.3)',
    position: 'absolute',
    left: '4px',
    top: 0,
    bottom: 0,
    display: 'inline-flex',
    alignItems: 'center',
    zIndex: 0,
  },
}));
