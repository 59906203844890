import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  operator: {
    color: theme.palette.primary.dark,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  icon: {
    fontSize: 16,
  },
  win: {
    color: theme.palette.success.main,
  },
  loss: {
    color: theme.palette.error.main,
  },
  tie: {
    color: theme.palette.warning.main,
  },
  unfinished: {},
}));
