import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useStyles } from './styles';

import * as Form from '@/components/form';
import StackLayout from '../stack-layout';
import { Box, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import CardsList from '../../classrooms/cards-list';
import QuizMembersList from '../../quiztime/quiz-tabs/participants-tab/members-list';
import MembersList from '../../classrooms/members-list';
import { TabNavIdsEnum } from '@/type';
import { LS_KEY_TABINDEX } from '@/utils/constants';

type SearchLayoutParamsCR = {
  sessionId: string;
};

type SearchLayoutParamsQuiz = {
  quizSessionId: string;
};

interface Form {
  code: string;
}
const CssTextField = withStyles({
  root: {
    '& input': {
      color: 'white',
      zIndex: 1,
      paddingLeft: 0,
    },
    '& label.Mui-focused': {
      color: 'transparent',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
  },
})(TextField);

type SearchLayoutProps = {
  contentPlaceholder?: string;
  inputPlaceholder?: string;
  isMembersList?: boolean;
  isForQuiz?: boolean;
};

const SearchLayout: React.FC<SearchLayoutProps> = ({
  contentPlaceholder,
  inputPlaceholder,
  isMembersList,
  isForQuiz,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const { register, watch } = useForm<Form>({
    mode: 'onChange',
    defaultValues: { code: '' },
  });

  const { sessionId } = useParams<SearchLayoutParamsCR>();
  const { quizSessionId } = useParams<SearchLayoutParamsQuiz>();

  useEffect(() => {
    if (isForQuiz)
      history.listen((newLocation, action) => {
        if (action === 'POP') {
          localStorage.setItem(
            `${TabNavIdsEnum.QUIZTIME}${LS_KEY_TABINDEX}`,
            '1'
          );
          history.push(newLocation.pathname, {
            manualQuizTabChangeOn: true,
          });
        }
      });
  }, [history, isForQuiz, quizSessionId]);

  return (
    <StackLayout
      title=""
      toolbar={
        <div className={classes.inputWrap}>
          <CssTextField
            fullWidth
            autoFocus
            name="code"
            variant="outlined"
            size="small"
            autoComplete="false"
            inputRef={register()}
          />
          {watch('code').length === 0 && (
            <Typography variant="body1" className={classes.inputPlaceholder}>
              {inputPlaceholder}
            </Typography>
          )}
        </div>
      }
    >
      <>
        {watch('code').length > 0 ? (
          isMembersList ? (
            isForQuiz ? (
              <QuizMembersList
                quizSessionId={quizSessionId}
                searchStr={watch('code')}
                hideMagnifier
              />
            ) : (
              <MembersList
                sessionId={sessionId}
                searchStr={watch('code')}
                hideMagnifier
              />
            )
          ) : (
            <CardsList searchStr={watch('code')} />
          )
        ) : (
          <Box pt={10} px={16} maxWidth={250}>
            <Typography variant="body2" align="center" color="textSecondary">
              {contentPlaceholder}
            </Typography>
          </Box>
        )}
      </>
    </StackLayout>
  );
};

export default SearchLayout;
