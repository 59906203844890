import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  count: {
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 100,
    lineHeight: 1.2,
    letterSpacing: 0.15,
    color: theme.palette.primary.main,
  },
}));
