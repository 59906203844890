import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';

import { Box } from '@material-ui/core';

import * as Form from '@/components/form';
import { useSignUp } from '@/contexts/sign-up-context';
import { useStudentDataQuery } from '@/graphql';

import PublicLayout from '../../../components/layout';
import StepNavigate from '../../../components/step-navigation';
import Agreement from '../../../components/agreement';
import PrivacyPolicy from '../../privacy-policy';
import { DEFAULT_STUDY_BEGIN_ID } from '@/utils/constants';

interface TraineeInfoProps {
  onBack: () => void;
  onNext: () => void;
}

interface Form {
  semester: string;
  university: string;
  studyBegin: string;
  medicineStudyBegin: string;
  registrationNumber: string;
  dataProtection: boolean;
  agb: boolean;
  subscribeToNews: boolean;
}

const TraineeInfo: React.FC<TraineeInfoProps> = ({ onBack, onNext }) => {
  const { t } = useTranslation();
  const {
    professionType,
    semesterId,
    universityId,
    studyBeginId,
    medicineStudyBegin,
    registrationNumber,
    subscribeToNews,
    update,
  } = useSignUp();

  const { data } = useStudentDataQuery({});

  const initialStudyBegin = useMemo(
    () =>
      (data?.studySemesters || []).find((it) => it?.id === studyBeginId)?.id ||
      DEFAULT_STUDY_BEGIN_ID,
    [data, studyBeginId]
  );

  const { register, errors, setValue, watch } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      semester: semesterId || '',
      university: universityId || '',
      studyBegin: initialStudyBegin,
      medicineStudyBegin: medicineStudyBegin || '',
      registrationNumber: registrationNumber || '',
      dataProtection: false,
      agb: false,
      subscribeToNews: !!subscribeToNews,
    },
  });

  useEffect(() => {
    update({ studyBeginId: initialStudyBegin });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStudyBegin]);

  if (professionType === undefined) {
    // TODO handle this case
    throw new Error('Not suported professionType');
  }

  useEffect(() => {
    register({ name: 'semester' }, {});
    register({ name: 'university' }, {});
    register({ name: 'studyBegin' }, {});
    register({ name: 'medicineStudyBegin' }, {});
    register({ name: 'registrationNumber' }, {});

    register({ name: 'dataProtection' }, {});
    register({ name: 'agb' }, {});
    register({ name: 'subscribeToNews' }, {});
  }, [
    medicineStudyBegin,
    register,
    registrationNumber,
    semesterId,
    studyBeginId,
    universityId,
  ]);

  const changeField = useCallback(
    (prop: keyof Form) =>
      (e: React.ChangeEvent<{ checked?: boolean; value: unknown }>) => {
        const options = {
          shouldDirty: true,
          shouldValidate: true,
        };
        const value = String(e.target.value);
        const checked = Boolean(e.target.checked);

        switch (prop) {
          case 'semester': {
            setValue(prop, value, options);

            return update({ semesterId: value });
          }
          case 'university': {
            setValue(prop, value, options);

            return update({ universityId: value });
          }
          case 'studyBegin': {
            setValue(prop, value, options);

            return update({ studyBeginId: value });
          }
          case 'registrationNumber': {
            setValue(prop, value, options);

            return update({ registrationNumber: value });
          }
          case 'subscribeToNews': {
            setValue(prop, checked, options);

            return update({ subscribeToNews: e.target.checked });
          }
          case 'agb': {
            setValue(prop, checked, options);
            return;
          }
          case 'dataProtection': {
            setValue(prop, checked, options);
            return;
          }
        }
      },
    [setValue, update]
  );

  const toYearDate = (date: DateTime | null) => {
    return `${date?.year}-01-01`;
  };

  const changeMedicineStudyBegin = useCallback(
    (event: DateTime | null) => {
      const value = event ? toYearDate(event) : undefined;

      setValue('medicineStudyBegin', value, {
        shouldDirty: true,
        shouldValidate: true,
      });

      update({ medicineStudyBegin: value });
    },
    [setValue, update]
  );

  const semesters = useMemo(
    () =>
      (data?.semesters || []).map((it) => ({
        value: String(it?.id),
        label: String(it?.title),
      })),
    [data]
  );

  const universities = React.useMemo(
    () =>
      (data?.universities || []).map((it) => ({
        value: String(it?.id),
        label: String(it?.title),
      })),
    [data]
  );

  const studySemesters = React.useMemo(
    () =>
      (data?.studySemesters || []).map((it) => ({
        value: String(it?.id),
        label: String(it?.title),
      })),
    [data]
  );

  const isValid = useMemo(() => {
    const values = watch();

    return (
      isEmpty(errors) &&
      !!values.semester &&
      !!values.university &&
      !!values.studyBegin &&
      !!values.medicineStudyBegin &&
      !!values.registrationNumber &&
      !!values.agb
    );
  }, [errors, watch]);

  return (
    <PublicLayout title={t('sign-up.student.title')} page="3 / 3">
      <Box paddingY={7}>
        <Form.Row>
          <Form.SelectField
            fullWidth
            required
            autoFocus
            data-cy-semester
            variant="outlined"
            name="semester"
            label={t('fields.semester.label')}
            options={semesters}
            errors={errors.semester}
            value={watch('semester')}
            onChange={changeField('semester')}
          />
        </Form.Row>
        <Form.Row>
          <Form.SelectField
            fullWidth
            required
            data-cy-university
            variant="outlined"
            name="university"
            label={t('fields.university.label')}
            options={universities}
            errors={errors.university}
            value={watch('university')}
            onChange={changeField('university')}
          />
        </Form.Row>
        <Form.Row>
          <Form.DateField
            autoOk
            fullWidth
            disableFuture
            disableToolbar
            data-cy-medicine-study-begin
            format="yyyy"
            variant="inline"
            inputVariant="outlined"
            name="medicine-study-begin"
            views={['year']}
            label={t('fields.medicine-study-begin.label')}
            errors={errors.medicineStudyBegin}
            value={
              watch('medicineStudyBegin')
                ? DateTime.fromISO(watch('medicineStudyBegin'))
                : null
            }
            onChange={changeMedicineStudyBegin}
          />
        </Form.Row>
        <Form.Row>
          <Form.RadioField
            fullWidth
            data-cy-study-begin
            name="study-begin"
            label={t('fields.study-begin.label')}
            options={studySemesters}
            value={watch('studyBegin')}
            onChange={changeField('studyBegin')}
          />
        </Form.Row>
        <Form.Row>
          <Form.TextField
            required
            fullWidth
            data-cy-registration-number
            variant="outlined"
            name="registration-number"
            label={t('fields.registration-number.label')}
            placeholder={t('fields.registration-number.placeholder')}
            errors={errors.registrationNumber}
            value={watch('registrationNumber').replace(/[^a-z0-9]/gi, '')}
            onChange={changeField('registrationNumber')}
          />
        </Form.Row>
        <Agreement
          data-cy-agb
          variant="agb"
          name="agb"
          checked={watch('agb')}
          onChange={changeField('agb')}
        />
        <Agreement
          noDivider
          data-cy-subscription
          variant="subscription"
          name="subscribeToNews"
          checked={watch('subscribeToNews')}
          onChange={changeField('subscribeToNews')}
        />
        <PrivacyPolicy />
      </Box>
      <StepNavigate disabled={!isValid} onNext={onNext} onBack={onBack} />
    </PublicLayout>
  );
};

export default TraineeInfo;
