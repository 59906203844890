import React, { useEffect, useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

import Loading from '@/components/loading';
import { useConfirmTokenMutation } from '@/graphql';
import AppButton from '@/components/app-button';
import SmallLogoIcon from '@/components/icons/small-logo';

const ConfirmRegistration: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const query = new URLSearchParams(location.search);
  const [confirm, { error }] = useConfirmTokenMutation();
  const [loading, setLoading] = useState<boolean>(false);

  const signInHandler = useCallback(() => {
    history.push('/sign-in');
  }, [history]);

  useEffect(() => {
    if (location.search.includes('amp;')) {
      const cleanSearch = location.search.replace(/amp;/g, '');
      history.replace({
        ...location,
        search: cleanSearch,
      });
    }
  }, [history, location]);

  useEffect(() => {
    const userConfirm = async () => {
      setLoading(true);
      try {
        const token = query.get('token');
        if (!token) {
          throw new Error('Token is missing while confirming registration');
        }

        const { data } = await confirm({
          variables: {
            token,
          },
        });

        if (data?.userConfirm?.user?.id) {
          const deepLinkHash = query.get('hash');
          history.push(
            deepLinkHash
              ? `/goto/${deepLinkHash}?source=signup`
              : '/onboard-welcome'
          );
        }
      } catch (error) {
        console.error('CONFIRM_REGISTRATION:', error);
        // You can add user-friendly error handling here if needed
      } finally {
        setLoading(false);
      }
    };
    userConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    console.error('CONFIRM_REGISTRATION_ERROR:', error);
  }

  return (
    <Box display="grid" padding={4}>
      <Box>
        <Box marginBottom={7}>
          <SmallLogoIcon />
        </Box>
        {loading ? (
          <Loading confirmPage={true} />
        ) : (
          <>
            <Box paddingBottom={7}>
              <Typography variant="h2">
                {t('confirm-reg.link-is-expired')}
              </Typography>
            </Box>
            <AppButton
              fullWidth
              data-cy-forgot-btn
              color="primary"
              variant="contained"
              onClick={signInHandler}
            >
              {t('common.sign-in')}
            </AppButton>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ConfirmRegistration;
