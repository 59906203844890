import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 20,
  },
  determinate: {
    color: theme.palette.common.white,
  },
  learningYear: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 40,
    marginTop: 16,
    marginLeft: -24,
    padding: '3px 0 3px 24px',
    background: theme.palette.common.white,
    borderRadius: '0px 74px 74px 0px',
    minWidth: 27,
  },
  hexahedronNumber: {
    position: 'absolute',
    right: 13,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 1,
    letterSpacing: '0.1px',
    color: '#FFFFFF',
  },
}));
