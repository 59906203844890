import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import {
  useLastUserNotificationLazyQuery,
  UserMeQuery,
  useUserSubscriptionLazyQuery,
} from '@/graphql';
import { NotifTypes, SubscriptionStatus } from '@/type';
import ProcessingIcon from '@/components/icons/processing';
import { stripeBackendErrorsCodes } from '@/utils/helpers';
import { ME_QUERY } from '@/apollo/queries';
import { useApolloClient } from '@apollo/client';
import { useConfirmPayment } from '@/hooks/useConfirmPayment';
import {
  LS_KEY_IS_PAY_CONF_ACTIVE,
  PAY_PROCESSING_MAX_AGE_MS,
  PAY_PROCESSING_POLL_INTERVAL,
} from '@/utils/constants';

type PaymentProcessingProps = {
  setVisibility: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string | null>>;
  isUpgradingSubscription?: boolean | null;
};

const PaymentProcessing: React.FC<PaymentProcessingProps> = ({
  setVisibility,
  setError,
  isUpgradingSubscription,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const cache = useApolloClient();
  const { handlePaymentConfirmation } = useConfirmPayment();

  const [getUserSubscription, { data, error }] = useUserSubscriptionLazyQuery({
    fetchPolicy: 'network-only',
    pollInterval: PAY_PROCESSING_POLL_INTERVAL,
  });
  const [getLastUserNotification, { data: lastNotificationData }] =
    useLastUserNotificationLazyQuery({
      fetchPolicy: 'network-only',
      pollInterval: PAY_PROCESSING_POLL_INTERVAL,
    });

  const subscription = data?.me?.customer?.subscription;
  const historyState: any = history.location.state;

  useEffect(() => {
    if (
      historyState?.isStripeError ||
      historyState?.isStripeAuthentificationError
    ) {
      if (historyState?.isStripeError) {
        setError(t('payment.declined'));
      }
      setVisibility(false);
    }
  }, [history.location, setError, setVisibility, t, historyState]);

  useEffect(() => {
    const notifData =
      lastNotificationData?.lastNotification &&
      JSON.parse(lastNotificationData?.lastNotification?.data);
    if (
      lastNotificationData?.lastNotification &&
      notifData.type === NotifTypes.PAYMENT_REQUIRES_CONFIRMATION &&
      lastNotificationData?.lastNotification.isRead === false
    ) {
      handlePaymentConfirmation(lastNotificationData?.lastNotification);
    }
  }, [lastNotificationData, handlePaymentConfirmation]);

  useEffect(() => {
    if (
      isUpgradingSubscription
        ? subscription?.upgradeStatus === SubscriptionStatus.ACTIVE ||
          subscription?.upgradeStatus === SubscriptionStatus.TRIALING
        : (subscription?.status === SubscriptionStatus.ACTIVE ||
            subscription?.status === SubscriptionStatus.TRIALING) &&
          !historyState?.isStripeError &&
          !historyState?.isStripeInvalidRequestError &&
          !historyState?.isStripeAuthentificationError &&
          !historyState?.isStripeCardError &&
          !localStorage.getItem(LS_KEY_IS_PAY_CONF_ACTIVE)
    ) {
      const historyState: any = history.location.state;
      history.push(
        historyState?.sessionId
          ? `/classroom/${historyState.sessionId}/`
          : '/subscription-payment-congratulation',
        history.location.state
      );
    } else if (historyState?.isStripeCardError) {
      const data: UserMeQuery | undefined | null = cache.readQuery({
        query: ME_QUERY,
      });
      cache.writeQuery({
        query: ME_QUERY,
        data: {
          ...data,
          me: {
            ...data?.me,
            customer: {
              ...data?.me?.customer,
              defaultCard: {
                ...data?.me?.customer?.defaultCard,
                last4: null,
              },
            },
          },
        },
      });
      setError(
        t(`payment.errors-codes.${stripeBackendErrorsCodes['card_declined']}`)
      );
    } else if (historyState?.isStripeInvalidRequestError) {
      setError(
        t(
          `payment.errors-codes.${stripeBackendErrorsCodes['payment_intent_authentication_failure']}`
        )
      );
    } else if (historyState?.isStripeError) {
      setError(t('payment.declined'));
    } else if (
      !historyState?.isStripeInvalidRequestError &&
      !historyState?.isStripeAuthentificationError &&
      !historyState?.isStripeCardError &&
      isUpgradingSubscription
        ? subscription?.upgradeStatus === SubscriptionStatus.CANCELED ||
          subscription?.upgradeStatus === SubscriptionStatus.INCOMPLETE_EXPIRED
        : subscription?.status === SubscriptionStatus.CANCELED
    ) {
      setError(t('payment.declined'));
      setVisibility(false);
    }
  }, [
    data,
    history,
    setError,
    t,
    setVisibility,
    isUpgradingSubscription,
    subscription,
    historyState,
    cache,
  ]);

  useEffect(() => {
    if (error) {
      setError(
        error?.message || error?.networkError?.message || t('payment.declined')
      );
      setVisibility(false);
    }
  }, [error, t, setError, setVisibility]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getUserSubscription();
      getLastUserNotification();
    }, 3000);
    return () => clearTimeout(timer);
  }, [getUserSubscription, getLastUserNotification]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        historyState?.isStripeInvalidRequestError ||
        historyState?.isStripeCardError ||
        historyState?.isStripeError ||
        historyState?.isStripeAuthentificationError
      ) {
        setVisibility(false);
      }
    }, 4000);
    return () => clearTimeout(timer);
  }, [historyState, setVisibility]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!localStorage.getItem(LS_KEY_IS_PAY_CONF_ACTIVE))
        history.push('/my-profile/network');
    }, PAY_PROCESSING_MAX_AGE_MS);
    return () => clearTimeout(timer);
  }, [history, subscription]);

  return (
    <Box className={classes.root}>
      <Box
        paddingY={2}
        paddingX={7}
        pb={20}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        boxSizing="border-box"
        className="fallback"
        textAlign="center"
      >
        <ProcessingIcon />
        <Box mt={5}>
          <Typography variant="subtitle1">
            {t('payment.processing.title')}!
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography align="center" variant="caption">
            {t('payment.processing.content')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentProcessing;
