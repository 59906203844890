import React from 'react';
import { FieldError } from 'react-hook-form';

import { Box, Typography } from '@material-ui/core';

interface ErrorProps {
  errors?: FieldError | null;
}

const Error: React.FC<ErrorProps> = ({ errors }) => {
  return !!errors ? (
    <Box marginBottom={-6}>
      <Box clone display="grid" marginBottom={3}>
        <Typography color="error" paragraph variant="caption">
          {errors.message}
        </Typography>
      </Box>
    </Box>
  ) : null;
};

export default Error;
