import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface StepNavigateProps {
  onBack?: () => void;
  onNext?: () => void;
  disabled?: boolean;
}

const StepNavigate: React.FC<StepNavigateProps> = ({
  onBack,
  onNext,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gridColumnGap={16}>
      <Button
        fullWidth
        data-cy-back
        color="primary"
        variant="outlined"
        onClick={onBack}
      >
        {t('common.back')}
      </Button>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        data-cy-next
        disabled={disabled}
        onClick={onNext}
      >
        <span style={{ color: '#fff' }}>{t('common.next')}</span>
      </Button>
    </Box>
  );
};

export default StepNavigate;
