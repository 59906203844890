import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, List, Paper, Typography } from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';

import { useGetOngoingDuelsQuery } from '@/graphql';
import { useUser } from '@/contexts/user-context';
import { useDuel } from '@/hooks/useDuel';
import { useCreateDuel } from '@/hooks/useCreateDuel';
import AppButton from '@/components/app-button';
import Loading from '@/components/loading';
import RemainingTime from '@/components/remaining-time';
import DuelItem from '../../components/duel-item';
import EmptyDuel from '../../components/empty-duel';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import {
  FREEMIUM_MAX_DUEL_COUNT,
  PREMIUM_MAX_DUEL_COUNT,
} from '@/utils/constants';
import { useStyles } from './styles';

const Ongoing = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { openDuel, loading: openLoading } = useDuel();
  const classes = useStyles();
  const { handleCreateDuel, creatingLoading } = useCreateDuel({});
  const { isUserPremium } = useGetPremiumData();

  const { data, loading } = useGetOngoingDuelsQuery({
    fetchPolicy: 'cache-and-network',
  });

  // preparation of the data because sometimes
  // value of the field "youTurn" is wrong on the back-end side
  const opponentDuels = useMemo(
    () =>
      (data?.activeDuels || []).filter(
        (duel) =>
          !(
            duel?.round?.userQuestions.filter(
              (x) => x.user?.id === user?.id && !!x.userAnswer?.id
            ).length !== 3 ||
            (duel?.round?.userQuestions.filter((x) => !!x.userAnswer?.id)
              .length === 6 &&
              duel?.youTurn)
          )
      ),
    [data, user]
  );

  const yourDuelsList = useMemo(
    () =>
      (data?.activeDuels || [])
        .filter((duel) => !opponentDuels.some((it) => it?.id === duel?.id))
        .map((it, i) => {
          if (it?.round && it?.round.duel) {
            const isNew =
              it?.round.roundNumber === 1 &&
              !it?.round.userQuestions.some((x) =>
                !!it.isInitiator
                  ? !!x.userAnswer?.id
                  : !!x.userAnswer?.id && x.user?.id === user?.id
              );

            const handleSelect = () => openDuel(it?.round?.duel?.id);

            return (
              <DuelItem
                data-cy-your-duel-item
                key={it?.round.id}
                isNew={isNew}
                isInitiator={!!it?.isInitiator}
                youTurn
                username={it?.opponent?.username}
                avatar={it?.opponent?.avatar ?? undefined}
                created={it?.round.created}
                roundNumber={it?.round.roundNumber}
                loading={openLoading}
                onSelect={handleSelect}
                classroomType={it?.session?.classRoom.enrollmentType}
                shouldNegativeIntervalCleared
                divider={
                  (data?.activeDuels?.length || 0) - opponentDuels.length !==
                  i + 1
                }
              />
            );
          }

          return <></>;
        })
        .filter((duel) => duel !== null),
    [openDuel, openLoading, user, opponentDuels, data]
  );

  const opponentDuelsList = useMemo(
    () =>
      opponentDuels
        .map((it, i) => {
          if (it?.round && it?.round.duel) {
            const isNew =
              it?.round.roundNumber === 1 &&
              !it?.round.userQuestions.some((x) =>
                !!it.isInitiator
                  ? !!x.userAnswer?.id
                  : !!x.userAnswer?.id && x.user?.id === user?.id
              );

            const handleSelect = () => openDuel(it?.round?.duel?.id);

            return (
              <DuelItem
                data-cy-your-opponent-item
                key={it?.round.id}
                youTurn={false}
                isNew={isNew}
                username={it?.opponent?.username}
                avatar={it?.opponent?.avatar ?? undefined}
                created={it?.round.created}
                roundNumber={it?.round.roundNumber}
                loading={openLoading}
                onSelect={handleSelect}
                classroomType={it?.session?.classRoom.enrollmentType}
                shouldNegativeIntervalCleared
                divider={opponentDuels.length !== i + 1}
              />
            );
          }

          return <></>;
        })

        .filter((duel) => duel !== null),
    [openDuel, openLoading, opponentDuels, user]
  );

  const activeDueslCount = useMemo(
    () =>
      (isUserPremium ? data?.activeDuels?.length : data?.startedDuelsToday) ||
      0,
    [data, isUserPremium]
  );

  const isEmpty = useMemo(() => activeDueslCount === 0, [activeDueslCount]);
  const maxDuelCount = isUserPremium
    ? PREMIUM_MAX_DUEL_COUNT
    : activeDueslCount >= FREEMIUM_MAX_DUEL_COUNT
    ? activeDueslCount
    : FREEMIUM_MAX_DUEL_COUNT;
  const canCreateDuel = useMemo(
    () =>
      user &&
      activeDueslCount < maxDuelCount &&
      !user?.settings?.activeQuizTime?.id,
    [activeDueslCount, user, maxDuelCount]
  );

  if (loading || !user) return <Loading />;

  return (
    <>
      {isEmpty ? (
        <Box data-cy-empty-ongoind-duels className={classes.empty}>
          <EmptyDuel
            disabled={!canCreateDuel}
            loading={creatingLoading}
            onCreate={handleCreateDuel}
          />
        </Box>
      ) : (
        <>
          <Box position="absolute" left={0} right={0}>
            <Paper square elevation={0}>
              <Box
                display="flex"
                justifyContent="space-between"
                paddingX={4}
                paddingY={3}
              >
                <Box display="inline-flex">
                  <Typography variant="subtitle2">
                    {t(
                      `common.duel.${
                        isUserPremium ? 'ongoing-duels' : 'duels-per-day'
                      }`
                    )}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ marginLeft: 4, color: '#2B75B3' }}
                  >
                    {activeDueslCount}/{maxDuelCount}
                  </Typography>
                </Box>
                <RemainingTime>{t('common.duel.remaining-time')}</RemainingTime>
              </Box>
            </Paper>
          </Box>

          <Box
            position="relative"
            height="100%"
            boxSizing="border-box"
            paddingTop={11}
          >
            <Box
              display="grid"
              height="100%"
              overflow="auto"
              boxSizing="border-box"
              gridTemplateRows="1fr auto"
              pt={2}
              pb={15}
              paddingX={2}
            >
              <Box data-cy-ongoing-duels display="grid" gridRowGap={8}>
                {!!yourDuelsList.length && (
                  <Paper elevation={0}>
                    <Box paddingTop={5} paddingX={6}>
                      <Typography variant="overline">
                        {t('duels.your-turn')}
                      </Typography>
                    </Box>
                    <List>{yourDuelsList}</List>
                  </Paper>
                )}
                {!!opponentDuelsList.length && (
                  <Paper elevation={0}>
                    <Box paddingTop={5} paddingX={6}>
                      <Typography variant="overline">
                        {t('duels.opponent-turn')}
                      </Typography>
                    </Box>
                    <List>{opponentDuelsList}</List>
                  </Paper>
                )}
              </Box>
              <AppButton
                fullWidth
                color="primary"
                variant="contained"
                loading={creatingLoading}
                onClick={handleCreateDuel}
                startIcon={!canCreateDuel && <LockOutlined />}
                visuallyDisabled={!canCreateDuel}
                className={classes.startDuelBtn}
              >
                {t('common.duel.start-random-duel')}
              </AppButton>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Ongoing;
