import { HEIGHT_TOPBAR_PX } from '@/utils/constants';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  top: {
    position: 'fixed',
    top: 0,
  },
  main: {
    overflowY: 'auto',
    backgroundColor: '#E6EEFA',
    position: 'absolute',
    top: HEIGHT_TOPBAR_PX,
    bottom: 56,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  toolbar: {
    flexGrow: 1,
    display: 'inline-flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));
