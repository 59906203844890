import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { CustomCodesType } from '@/type';
import SponsorBlock from '../sponsor-block';

interface FutureCodesProps {
  futureCodes: CustomCodesType[] | null | undefined;
}

const FutureCodes: React.FC<FutureCodesProps> = ({ futureCodes }) => {
  const { t } = useTranslation();

  if (!futureCodes || futureCodes.length === 0) {
    return null;
  }

  return (
    <>
      {futureCodes?.map((it) => (
        <Box
          key={it?.id}
          clone
          px={6}
          py={4}
          mt={5}
          style={{ backgroundColor: 'rgba(226, 245, 253, 0.48)' }}
        >
          <Paper variant="outlined">
            <Box display="grid" gridRowGap={8}>
              <Typography variant="caption">
                {t('fields.next-membership.label')}
              </Typography>
              <Typography variant="subtitle1">
                {t(`common.preemium`)}
              </Typography>
              <Typography variant="body1">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  component="span"
                  width="100%"
                >
                  <span>{t('common.startDate')}</span>
                  <span>
                    {DateTime.fromISO(it?.startDate).toFormat('dd/LL/yyyy')}
                  </span>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  component="span"
                  width="100%"
                  mt={2}
                >
                  <span>{t('common.finishDate')}</span>
                  <span>
                    {DateTime.fromISO(it?.finishDate).toFormat('dd/LL/yyyy')}
                  </span>
                </Box>
              </Typography>
            </Box>
            <SponsorBlock code={it} />
          </Paper>
        </Box>
      ))}
    </>
  );
};

export default FutureCodes;
