import React from 'react';
import clsx from 'clsx';

import { useStyles } from './styles';

interface DotProps {
  size?: 'medium' | 'large';
  variant?: 'default' | 'success' | 'error' | 'highlighted';
}

const Dot: React.FC<DotProps> = ({
  variant = 'default',
  size = 'medium',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, classes[size], classes[variant])}
      {...rest}
    />
  );
};

export default Dot;
