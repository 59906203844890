import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'grid',
    gridRowGap: 2,
    paddingBottom: theme.spacing(5),
    overflowY: 'scroll',
  },
  joinBtn: {
    marginLeft: 16,
    marginRight: 16,
  },
  dialogConent: {
    padding: '60px 24px 24px',
  },
  close: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    position: 'absolute',
  },
  snackBar: {
    backgroundColor: 'red',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}));
