import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime, Info } from 'luxon';
import { Box, Typography } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import { useUser } from '@/contexts/user-context';
import { useStyles } from './styles';

type WeeklyActivityProps = {
  hideLinkArrow?: boolean | undefined;
};

const getDayCircleData = (status: any) => {
  switch (status) {
    case 'Passed':
      return {
        textColor: '#2B75B3',
        borderColor: '#3498DB',
        bgColor: '#fff',
      };
    case 'Failed':
      return {
        textColor: '#879BB2',
        borderColor: '#E6EEFA',
        bgColor: '#E6EEFA',
      };
    case 'Missed':
      return {
        textColor: '#879BB2',
        borderColor: '#E6EEFA',
        bgColor: '#fff',
      };
    case 'Today':
      return {
        textColor: '#2B75B3',
        borderColor: '#3498DB',
        bgColor: '#fff',
      };
  }
};

const currentDayName = DateTime.now().toFormat('cccc');

const WeeklyActivity: React.FC<WeeklyActivityProps> = () => {
  const classes = useStyles();
  const { dtData } = useUser();
  const { i18n } = useTranslation();
  const dailyTipperQuestion =
    dtData?.dailyTipperData?.dailyTipperQuestions?.find(
      (it) => !!it?.actualLearningWeek?.weekProgress
    );
  const weekProgress: any =
    dailyTipperQuestion?.actualLearningWeek?.weekProgress &&
    JSON.parse(dailyTipperQuestion?.actualLearningWeek?.weekProgress);
  if (!weekProgress || !weekProgress?.length) return null;

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center" py={4} mx={-1.5} minHeight={34}>
        {weekProgress?.map((it: any, idx: number) => {
          const isToday =
            currentDayName === it?.dayName &&
            it?.status !== 'Passed' &&
            it?.status !== 'Failed';
          const circleData = getDayCircleData(isToday ? 'Today' : it?.status);
          return (
            <Box
              key={idx}
              className={classes.dayCircle}
              style={{
                border: `1px solid ${circleData?.borderColor}`,
                backgroundColor: circleData?.bgColor,
              }}
            >
              {it?.status === 'Passed' && (
                <Box component="span" className={classes.dayCircleCheck}>
                  <Check color="inherit" />
                </Box>
              )}
              <Typography
                variant="overline"
                style={{ color: circleData?.textColor }}
              >
                {Info.weekdays('short', { locale: i18n.language })[idx]}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </div>
  );
};

export default WeeklyActivity;
