import React from 'react';
import Box from '@material-ui/core/Box';

import ChooseItem from '@/components/choose-item';
import { DuelRoundQuestionAnswerType } from '@/graphql';

interface CorrectAnswerProps {
  userQuestion: DuelRoundQuestionAnswerType;
}

const CorrectAnswer: React.FC<CorrectAnswerProps> = ({ userQuestion }) => {
  const uAnswer = (userQuestion?.question?.answers || []).find(
    (it) => it.value === userQuestion?.userAnswer?.value
  );

  return (
    <>
      <Box paddingBottom={3}>
        <Box display="grid" gridRowGap={12}>
          <ChooseItem data-cy-correct variant="correct">
            {uAnswer?.value}
          </ChooseItem>
        </Box>
      </Box>
    </>
  );
};

export default CorrectAnswer;
