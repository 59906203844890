import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import { Button, Paper, Typography } from '@material-ui/core';
import FlyingBoyIcon from '@/components/icons/flying-boy';
import { useCreateDuel } from '@/hooks/useCreateDuel';
import AppButton from '@/components/app-button';
import {
  useClassRoomSessionLazyQuery,
  useJoinNetworkClassRoomMutation,
} from '@/graphql';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';

const OnboardKeepLearning: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { handleCreateDuel, creatingLoading } = useCreateDuel({});
  const { isUserStudent } = useGetPremiumData();

  const params = new URLSearchParams(history.location.search);
  const sessionId = params.get('classroomSessionId') || '';
  const [getCRSession, { loading, data }] = useClassRoomSessionLazyQuery({
    variables: { sessionId },
  });

  useEffect(() => {
    if (isUserStudent && !data && !loading) getCRSession();
  }, [isUserStudent, data, loading, getCRSession]);

  const [joinNetworkCR, { loading: joinNetworkCRLoading }] =
    useJoinNetworkClassRoomMutation();

  const handleJoinClassroom = useCallback(async () => {
    if (!sessionId) {
      console.error('No classroom sessionId was provided');
      return;
    }
    try {
      await joinNetworkCR({
        variables: {
          sessionId,
          specialityGroupIcon:
            data?.classRoomLearningSession?.specialityGroupIcon,
          isAfterOnboarding: true,
        },
      });
      history.replace(`/classroom/${sessionId}/`, {
        fromCongratulation: true,
        showSnackCrJoined: true,
      });
    } catch (error) {
      console.error('useJoinNetworkClassRoomMutation', error);
    }
  }, [joinNetworkCR, sessionId, history, data]);

  const handleConfirm = () =>
    isUserStudent ? handleJoinClassroom() : handleCreateDuel();
  const handleBrowse = () =>
    history.replace(isUserStudent ? '/classroom-rules/' : '/biblio/');

  return (
    <Box
      p={2}
      display="grid"
      style={{
        height: '-webkit-fill-available',
        background: '#D7D7D7',
      }}
    >
      <Box clone px={2} py={6} display="flex" flexDirection="column">
        <Paper elevation={0}>
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
            px={4}
            textAlign="center"
          >
            <Typography variant="h2">
              {t(
                `onboard.keep-learning.${
                  isUserStudent ? 'student' : 'profi'
                }.title`
              )}
            </Typography>
            <FlyingBoyIcon />
          </Box>
          {isUserStudent && (
            <Box
              mt={2}
              px={3}
              py={2}
              textAlign="center"
              style={{
                borderRadius: 4,
                background: '#E6EEFA',
              }}
            >
              <Typography variant="subtitle2">
                {t(`onboard.keep-learning.topic`)}:{' '}
                {data?.classRoomLearningSession?.classRoom.title}
              </Typography>
            </Box>
          )}
          <Box pt={2}>
            <AppButton
              onClick={handleConfirm}
              color="primary"
              variant="contained"
              fullWidth
              loading={creatingLoading || joinNetworkCRLoading}
            >
              {t(
                `onboard.keep-learning.${
                  isUserStudent ? 'student' : 'profi'
                }.btn1`
              )}
            </AppButton>
            <Button
              onClick={handleBrowse}
              color="primary"
              variant="outlined"
              fullWidth
              style={{ marginTop: 16, borderColor: '#e6eefa' }}
            >
              {t(
                `onboard.keep-learning.${
                  isUserStudent ? 'student' : 'profi'
                }.btn2`
              )}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default OnboardKeepLearning;
