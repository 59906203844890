import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { Box, Paper, Typography } from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

import {
  DuelStatus,
  SubscribeNotificationCreatedDocument,
  useUserDuelQuery,
} from '@/graphql';

import Fallback from '@/components/fallback';
import AppButton from '@/components/app-button';
import ResultDialog from '@/screens/main/components/result-dialog';
import OverviewRoundsList from '@/screens/main/components/overview-rounds-list';
import { TabNavIdsEnum } from '@/type';
import { LS_KEY_TABINDEX } from '@/utils/constants';

const QuizDuelOverview: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { duelId, sessionId } =
    useParams<{ duelId: string; sessionId: string }>();

  const { data, error, subscribeToMore, refetch } = useUserDuelQuery({
    variables: { duelId },
  });

  subscribeToMore({
    document: SubscribeNotificationCreatedDocument,
    updateQuery: (prev) => {
      refetch();
      return prev;
    },
  });

  const activeRoundNumber = data?.duelData?.rounds?.length || 0;
  const isYourTurn = useMemo(
    () =>
      data && data?.duelData?.userScore?.initiator
        ? activeRoundNumber % 2 === 0
        : activeRoundNumber % 2 === 1,
    [activeRoundNumber, data]
  );
  const isCompleted = useMemo(
    () =>
      data?.duelData?.status?.valueOf() === DuelStatus.Finished ||
      data?.duelData?.status?.valueOf() === DuelStatus.Ignored,
    [data]
  );

  const backToQuiztime = () => {
    localStorage.setItem(
      `${TabNavIdsEnum.QUIZTIME}${sessionId}${LS_KEY_TABINDEX}`,
      '0'
    );
    history.push(`/quiztime/${sessionId}/`, {
      backPageForQuiz: '/quiz-list',
    });
  };

  if (!!error) {
    console.error('DUEL_QUERY', error);
    return <Fallback />;
  }

  return (
    <>
      {data && isCompleted && (
        <ResultDialog
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          status={data?.duelData?.status as any}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          userScore={data?.duelData?.userScore as any}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          opponentScore={data?.duelData?.opponentScore as any}
          onClose={backToQuiztime}
          maxRoundsCount={data?.duelData?.rounds?.length}
        />
      )}
      <Box
        clone
        data-cy-overview
        display="grid"
        gridTemplateRows="1fr auto"
        paddingY={1}
        flexGrow={1}
      >
        <Paper elevation={0}>
          <Box>
            <OverviewRoundsList maxRoundsCount={1} />
          </Box>
          {!isCompleted && (
            <>
              <Box paddingX={2} paddingY={5}>
                <AppButton
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={backToQuiztime}
                >
                  {t('quiztime.go-to-activity-page')}
                </AppButton>
              </Box>
              <Box mt={-2} mb={3}>
                <Typography
                  variant="caption"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#647485',
                  }}
                >
                  {!isYourTurn && (
                    <HourglassEmptyIcon
                      style={{
                        width: 16,
                        height: 16,
                        marginRight: 4,
                      }}
                    />
                  )}
                  {t(
                    isYourTurn ? 'common.duel.your-turn' : 'duels.opponent-turn'
                  )}
                </Typography>
              </Box>
            </>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default QuizDuelOverview;
