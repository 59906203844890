import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ChevronRight } from '@material-ui/icons';
import { useStyles } from '../styles';

const PushNotificationsSettings: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box pl={4} mb={6}>
      <Link className={classes.pushLink} to="/settings/pushes" color="inherit">
        <Typography variant="body1">{t('settings.push.link')}</Typography>
        <ChevronRight />
      </Link>
    </Box>
  );
};

export default PushNotificationsSettings;
