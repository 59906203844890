import {
  Box,
  IconButton,
  Slide,
  SlideProps,
  Snackbar,
  SnackbarContent,
  Typography,
} from '@material-ui/core';
import { useStyles } from './styles';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import TimerOutlined from '@material-ui/icons/TimerOutlined';
import { Link } from '@material-ui/core';
import { DEFAULT_SNACK_DURATION_MS } from '@/utils/constants';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" timeout={500} />;
}

type SnackBarProps = {
  open: boolean;
  setOpen: (state: boolean) => void;
  message: string;
  messagePostfix?: string;
  durationInMs?: number;
  isLight?: boolean;
  link?: string | undefined | null;
  linkText?: string;
  closeIcon?: boolean;
  isTimerIcon?: boolean;
};
const AnimationFillForwards: KeyframeAnimationOptions['fill'] = 'forwards';

const CustomSnackbar: React.FC<SnackBarProps> = ({
  open,
  setOpen,
  message,
  messagePostfix,
  durationInMs,
  isLight,
  link,
  linkText,
  closeIcon,
  isTimerIcon,
}) => {
  const [dragX, setDragX] = React.useState<number | null>(null);
  const classes = useStyles();

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleDragStart = (event: React.MouseEvent) => {
    const startDragX = event.clientX;
    if (startDragX) setDragX(startDragX);
  };

  const handleDragEnd = (event: React.MouseEvent) => {
    const endDragX = event.clientX;
    if (dragX && Math.abs(dragX - endDragX) > 50) {
      handleClose(event);
    }
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    const startDragX = event.changedTouches[0].clientX;
    setDragX(startDragX);
  };

  const handleTouchEnd = (event: React.TouchEvent) => {
    const endDragX = event.changedTouches[0].clientX;
    if (dragX && Math.abs(dragX - endDragX) > 50) {
      handleClose(event);
    }
  };

  const btnAnimatedBySnackTiming = {
    duration: 500,
    fill: AnimationFillForwards,
    easing: 'cubic-bezier(0, 0, 0.2, 1)',
  };
  const handleOnEnter = () => {
    const btnAnimatedBySnack = document.querySelector('#btnAnimatedBySnack');
    if (btnAnimatedBySnack) {
      const snackElem = document.querySelector('#customSnackBar');
      const elemHeight = snackElem?.getBoundingClientRect().height || 42;
      btnAnimatedBySnack.animate(
        [{ transform: `translateY(-${elemHeight + 8}px)` }],
        btnAnimatedBySnackTiming
      );
    }
  };
  const handleOnExit = () => {
    const btnAnimatedBySnack = document.querySelector('#btnAnimatedBySnack');
    if (btnAnimatedBySnack)
      btnAnimatedBySnack.animate([{ transform: 'translateY(0px)' }], {
        ...btnAnimatedBySnackTiming,
        easing: 'cubic-bezier(0.4, 0, 0.6, 1)',
      });
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={durationInMs ? durationInMs : DEFAULT_SNACK_DURATION_MS}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      draggable={true}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onEnter={handleOnEnter}
      onExit={handleOnExit}
      id="customSnackBar"
    >
      <SnackbarContent
        className={isLight ? classes.lightSnackBar : classes.darkSnackBar}
        message={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {isTimerIcon && (
              <TimerOutlined fontSize="inherit" className={classes.timerIcon} />
            )}
            <Typography
              variant="body2"
              className={isLight ? classes.captionLight : classes.captionDark}
            >
              {message}
              {link && (
                <Link href={link} target="_blank">
                  <Typography
                    variant="caption"
                    className={classes.link}
                    component="span"
                  >
                    {linkText}
                  </Typography>
                </Link>
              )}
              {messagePostfix && messagePostfix}
            </Typography>
            {closeIcon && (
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        }
      />
    </Snackbar>
  );
};

export default CustomSnackbar;
