import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { Box } from '@material-ui/core';

import * as Form from '@/components/form';
import { useSignUp } from '@/contexts/sign-up-context';
import { useTraineeDataQuery } from '@/graphql';

import PublicLayout from '../../../components/layout';
import StepNavigate from '../../../components/step-navigation';
import Agreement from '../../../components/agreement';
import PrivacyPolicy from '../../privacy-policy';
import { OTHER_WORKPLACE } from '@/utils/constants';

interface TraineeInfoProps {
  onBack: () => void;
  onNext: () => void;
}

interface Form {
  educationLevel: string;
  workplace: string;
  anotherWorkplace: string;

  dataProtection: boolean;
  agb: boolean;
  subscribeToNews: boolean;
}

const TraineeInfo: React.FC<TraineeInfoProps> = ({ onBack, onNext }) => {
  const { t } = useTranslation();
  const {
    professionType,
    educationLevelId,
    workplaceId,
    anotherWorkplace,
    subscribeToNews,
    update,
  } = useSignUp();

  const { register, errors, setValue, watch } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      educationLevel: educationLevelId || '',
      workplace: !!anotherWorkplace ? OTHER_WORKPLACE : workplaceId || '',
      anotherWorkplace: anotherWorkplace || '',
      dataProtection: false,
      agb: false,
      subscribeToNews: !!subscribeToNews,
    },
  });

  if (professionType === undefined) {
    // TODO handle this case
    throw new Error('Not suported professionType');
  }

  const { data } = useTraineeDataQuery({ variables: { professionType } });

  useEffect(() => {
    register({ name: 'educationLevel' }, {});
    register({ name: 'workplace' }, {});
    register({ name: 'anotherWorkplace' }, {});

    register({ name: 'dataProtection' }, {});
    register({ name: 'agb' }, {});
    register({ name: 'subscribeToNews' }, {});
  }, [anotherWorkplace, educationLevelId, register, workplaceId]);

  const changeField = useCallback(
    (prop: keyof Form) =>
      (e: React.ChangeEvent<{ checked?: boolean; value: unknown }>) => {
        const options = {
          shouldDirty: true,
          shouldValidate: true,
        };
        const value = String(e.target.value);
        const checked = Boolean(e.target.checked);

        switch (prop) {
          case 'workplace': {
            setValue(prop, value, options);

            return update({
              workplaceId: value,
            });
          }
          case 'educationLevel': {
            setValue(prop, value, options);

            return update({ educationLevelId: value });
          }
          case 'anotherWorkplace': {
            setValue(prop, value, options);

            return update({ anotherWorkplace: value });
          }
          case 'subscribeToNews': {
            setValue(prop, checked, options);

            return update({ subscribeToNews: checked });
          }
          case 'agb': {
            setValue(prop, checked, options);
            return;
          }
          case 'dataProtection': {
            setValue(prop, checked, options);
            return;
          }
        }
      },
    [setValue, update]
  );

  const educationLevels = useMemo(
    () =>
      (data?.educationLevels || []).map((it) => ({
        value: String(it?.id),
        label: String(it?.title),
      })),
    [data]
  );

  const workplaces = React.useMemo(
    () =>
      (data?.workplaces || [])
        .map((it) => ({
          value: String(it?.id),
          label: String(it?.title),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [data]
  );

  const isValid = useMemo(() => {
    const values = watch();

    return (
      isEmpty(errors) &&
      !!values.educationLevel &&
      !!values.agb &&
      (values.workplace === OTHER_WORKPLACE
        ? !!values.anotherWorkplace
        : !!values.workplace)
    );
  }, [errors, watch]);

  return (
    <PublicLayout title={t('sign-up.trainee.title')} page="3 / 3">
      <Box paddingY={7}>
        <Form.Row>
          <Form.SelectField
            fullWidth
            required
            autoFocus
            data-cy-education-level
            variant="outlined"
            name="education-level"
            label={t('fields.education-level.label')}
            options={educationLevels}
            errors={errors.educationLevel}
            value={watch('educationLevel')}
            onChange={changeField('educationLevel')}
          />
        </Form.Row>
        <Form.Row>
          <Form.SelectField
            fullWidth
            required
            data-cy-workplace
            variant="outlined"
            name="workplace"
            label={t('fields.workplace.label')}
            options={workplaces}
            errors={errors.workplace}
            value={watch('workplace')}
            onChange={changeField('workplace')}
          />
        </Form.Row>
        {watch('workplace') === OTHER_WORKPLACE && (
          <Form.Row noPadding>
            <Form.TextField
              fullWidth
              required
              data-cy-another-workplace
              variant="outlined"
              name="another-workplace"
              label={t('fields.another-workplace.label')}
              placeholder={t('fields.another-workplace.placeholder')}
              errors={errors.anotherWorkplace}
              value={watch('anotherWorkplace').replace(/[^0-9a-zA-Z]/gi, '')}
              onChange={changeField('anotherWorkplace')}
            />
          </Form.Row>
        )}
        <Agreement
          data-cy-agb
          variant="agb"
          name="agb"
          checked={watch('agb')}
          onChange={changeField('agb')}
        />
        <Agreement
          noDivider
          data-cy-subscription
          variant="subscription"
          name="subscribeToNews"
          checked={watch('subscribeToNews')}
          onChange={changeField('subscribeToNews')}
        />
        <PrivacyPolicy />
      </Box>
      <StepNavigate disabled={!isValid} onNext={onNext} onBack={onBack} />
    </PublicLayout>
  );
};

export default TraineeInfo;
