import { UserGender } from '@/type';
import React, { useCallback, useContext, useState } from 'react';
import { SS_KEY_REG_DATA } from '@/utils/constants';

interface SignUpType {
  anotherProfession?: string;
  anotherWorkplace?: string;
  dateOfBirth?: string;
  educationLevelId?: string;
  efnNumber?: string;
  email?: string;
  examYear?: number;
  firstName?: string;
  gender?: number;
  lastName?: string;
  medicineStudyBegin?: string;
  password?: string;
  passwordConfirmation?: string;
  postcode?: string;
  professionId?: string;
  professionType?: number;
  registrationNumber?: string;
  semesterId?: string;
  specialityId?: string;
  studyBeginId?: string;
  studyYearId?: string;
  subscribeToNews: boolean;
  universityId?: string;
  username?: string;
  workplaceId?: string;
}

interface SignUpContextType extends SignUpType {
  update: (data: Partial<SignUpType>) => void;
  clean: () => void;
}
const jsonRegData = sessionStorage.getItem(SS_KEY_REG_DATA);
const initialRegData = jsonRegData ? JSON.parse(jsonRegData) : {};
const initState: SignUpType = {
  gender: UserGender.Male,
  ...initialRegData,
};

export const SignUpContext =
  React.createContext<SignUpContextType | undefined>(undefined);

export const SignUpProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<SignUpType>(initState);

  const update = useCallback(
    (data: Partial<SignUpType>) => {
      sessionStorage.setItem(
        SS_KEY_REG_DATA,
        JSON.stringify({
          ...state,
          ...data,
        })
      );
      setState({
        ...state,
        ...data,
      });
    },
    [state]
  );

  const clean = useCallback(() => {
    setState(initState);
  }, []);

  const globalState: SignUpContextType = React.useMemo(
    () => ({ ...state, clean, update }),
    [clean, state, update]
  );

  return (
    <SignUpContext.Provider value={globalState}>
      {children}
    </SignUpContext.Provider>
  );
};

export const useSignUp = () => {
  const state = useContext(SignUpContext);

  if (!state) throw new Error('SignUpContext not found');
  return state;
};
