import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { ModalContext } from '@/contexts/modal-context';
import DuelRule1Icon from '@/components/icons/duel-rule-1';
import DuelRule2Icon from '@/components/icons/duel-rule-2';
import DuelRule3Icon from '@/components/icons/duel-rule-3';
import DuelRule4Icon from '@/components/icons/duel-rule-4';
import DuelRule5Icon from '@/components/icons/duel-rule-5';
import DuelRule6Icon from '@/components/icons/duel-rule-6';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(17),
  },
  close: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    position: 'absolute',
  },
  listItem: {
    display: 'flex',
    marginTop: 16,
  },
  info: {
    backgroundColor: '#E2F5FD',
    borderRadius: 6,
  },
  iconBox: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 40,
    height: 40,
    background: '#E2F5FD',
    borderRadius: 4,
    marginRight: 20,
  },
}));

const dataArr = [
  { key: '1', icon: <DuelRule1Icon /> },
  { key: '2', icon: <DuelRule2Icon /> },
  { key: '3', icon: <DuelRule3Icon /> },
  { key: '4', icon: <DuelRule4Icon /> },
  { key: '5', icon: <DuelRule5Icon /> },
  { key: '6', icon: <DuelRule6Icon /> },
];

export const useDuelRulesFullScreenModal = () => {
  const { t } = useTranslation();
  const { openModal, closeModal } = React.useContext(ModalContext);
  const classes = useStyles();

  const element = React.useMemo(
    () => (
      <Box className={classes.root}>
        <IconButton
          aria-label="close"
          className={classes.close}
          onClick={closeModal}
        >
          <CloseIcon htmlColor="#4A647C" />
        </IconButton>
        <Typography variant="h2">{t('dialogs.duels-rules.title')}</Typography>
        <Box mt={5} py={3} px={4} className={classes.info}>
          <Typography variant="subtitle2">
            {t('dialogs.duels-rules.info')}
          </Typography>
        </Box>
        <Box mt={5} mb={7}>
          {dataArr.map((it) => (
            <Box key={it.key} mt={2} className={classes.listItem}>
              <Box className={classes.iconBox}>{it.icon}</Box>
              <Box>
                <Typography variant="body2">
                  {t(`dialogs.duels-rules.rule-${it.key}.descr`)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={closeModal}
        >
          {t('common.ok')}
        </Button>
      </Box>
    ),
    [closeModal, t, classes]
  );

  const open = React.useCallback(() => {
    openModal({
      content: element,
      handleClose: closeModal,
      fullScreen: true,
    });
  }, [openModal, element, closeModal]);

  return { open, close: closeModal };
};
