import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'grid',
    gridRowGap: 2,
    paddingBottom: theme.spacing(14),
    overflowY: 'scroll',
  },
  joinBtnSnackfalse: {
    width: 'calc(100% - 32px)',
    position: 'fixed',
    bottom: theme.spacing(5),
    left: theme.spacing(4),
    right: theme.spacing(4),
  },
  joinBtnSnacktrue: {
    width: 'calc(100% - 32px)',
    position: 'fixed',
    bottom: theme.spacing(20),
    left: theme.spacing(4),
    right: theme.spacing(4),
  },
  dialogConent: {
    padding: '60px 24px 24px',
  },
  close: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    position: 'absolute',
  },
  snackBar: {
    backgroundColor: 'red',
  },
}));
