import React, { useCallback, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import isEmail from 'validator/lib/isEmail';

import { Typography, Box, Link } from '@material-ui/core';
import AppButton from '@/components/app-button';

import * as Form from '@/components/form';
import { useAuth } from '@/contexts/auth-context';
import { useSignInMutation } from '@/graphql';

interface Form {
  email: string;
  password: string;
}

const LogIn: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { signIn } = useAuth();

  const [{ email, password }, setForm] = useState<Form>({
    email: '',
    password: '',
  });

  const [signInMutation, { loading }] = useSignInMutation();
  const [hasServerErrors, setHasServerErrors] = React.useState<boolean>(false);

  const isValid = React.useMemo(() => {
    return !!email && !!password && !hasServerErrors;
  }, [email, hasServerErrors, password]);

  const login = React.useCallback(async () => {
    if (!isValid) {
      return;
    }

    const variables = isEmail(email)
      ? {
          username: null,
          email: email,
          password,
        }
      : {
          email: null,
          username: email,
          password,
        };

    try {
      const { data } = await signInMutation({ variables });

      if (data?.userLogin?.token) {
        signIn(data.userLogin.token);
        const search = history.location.search;
        const query = new URLSearchParams(search);
        const hash = query.get('hash');
        if (hash) {
          return history.push(`/goto/${hash}?source=signin`);
        } else {
          return history.push('/home');
        }
      }
    } catch {
      setHasServerErrors(true);
    }
  }, [isValid, email, password, signInMutation, signIn, history]);

  const onEmailChange = useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) => {
      setHasServerErrors(false);
      setForm({
        email: String(e.target.value).replace(/\s/g, ''),
        password,
      });
    },
    [password]
  );

  const onPasswordChange = useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) => {
      setHasServerErrors(false);
      setForm({
        email,
        password: String(e.target.value).replace(/\s/g, ''),
      });
    },
    [email]
  );

  return (
    <>
      <Box paddingY={7}>
        <Form.Row>
          <Form.TextField
            fullWidth
            required
            autoFocus
            data-cy-email
            name="email"
            variant="outlined"
            label={t('fields.email-name.label')}
            value={email}
            onChange={onEmailChange}
          />
        </Form.Row>
        <Form.Row noPadding>
          <Form.PasswordField
            fullWidth
            required
            data-cy-password
            name="password"
            variant="outlined"
            label={t('fields.password.label')}
            value={password}
            onChange={onPasswordChange}
          />
        </Form.Row>
        {hasServerErrors && (
          <Form.Error
            errors={{ type: 'server', message: t('server.error.unauthorized') }}
          />
        )}
        <Box marginY={5}>
          <Typography variant="caption">
            <Link component={RouterLink} to="/forgot-password">
              {t('common.forgot-password-question')}
            </Link>
          </Typography>
        </Box>
        <Box>
          <AppButton
            fullWidth
            data-cy-sign-in-btn
            color="primary"
            variant="contained"
            disabled={!isValid}
            loading={loading}
            onClick={login}
          >
            <span style={{ color: '#fff' }}>{t('common.sign-in')}</span>
          </AppButton>
        </Box>
      </Box>
      <Box>
        <Typography variant="caption">
          {t('common.create-account')}
          <Link className="m-l" component={RouterLink} to="/sign-up">
            {t('common.create-account-link')}
          </Link>
        </Typography>
      </Box>
    </>
  );
};

export default LogIn;
