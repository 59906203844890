import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography, Button } from '@material-ui/core';
import StackLayout from '../../components/stack-layout';
import { useUser } from '@/contexts/user-context';
import MastercardIcon from '@/components/icons/mastercard';
import DialogRemovePaymentMethod from '../dialog-remove-payment-method';

const PaymentMethodDetails: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [cancelSubsVisible, setCancelSubsVisible] = useState(false);

  return (
    <StackLayout title={t('payment.method')}>
      <Box display="grid" height="100%">
        <Paper>
          {user?.customer?.defaultCard?.last4 && (
            <Box px={4} py={5}>
              <Box
                display="flex"
                alignItems="center"
                paddingBottom={5}
                borderBottom="1px solid #E6EEFA"
              >
                <MastercardIcon
                  style={{ marginRight: 16, width: 100, height: 60 }}
                />
                <Box>
                  <Typography variant="subtitle1">
                    {t('payment.method')}
                  </Typography>
                  <Typography variant="body1">
                    {user?.customer?.defaultCard?.brand} —{' '}
                    {user?.customer?.defaultCard?.last4}
                  </Typography>
                </Box>
              </Box>
              <Box mt={5} ml={-1} mb={5}>
                <Button
                  color="secondary"
                  onClick={() => setCancelSubsVisible(true)}
                >
                  {t('common.remove')}
                </Button>
              </Box>
            </Box>
          )}
        </Paper>
      </Box>
      <DialogRemovePaymentMethod
        onClose={setCancelSubsVisible}
        isOpened={cancelSubsVisible}
      />
    </StackLayout>
  );
};

export default PaymentMethodDetails;
