import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Avatar, Box, Typography } from '@material-ui/core';

import { useUserDuelQuery } from '@/graphql';

import StackLayout from '@/screens/main/components/stack-layout';
import AlarmIcon from '@/components/icons/alarm';
import { PermIdentityOutlined } from '@material-ui/icons';
import RuleWrapper from './rule-wrapper';

import { useStyles } from './styles';
import { DEFAULT_DUEL_DURATION_HOURS } from '@/utils/constants';

const Duel: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { duelId } = useParams<{ duelId: string }>();
  const classes = useStyles();
  const history = useHistory();

  const { data } = useUserDuelQuery({ variables: { duelId } });

  const userData = data?.duelData?.userScore;
  const opponentData = data?.duelData?.opponentScore;
  const classroomTitle = userData?.classRoomLearningSession?.classRoom.title;

  const RoundDuration = useMemo(
    () => (
      <Box
        display="grid"
        gridColumnGap={4}
        gridTemplateColumns="auto auto"
        justifyContent="flex-end"
        flexGrow={1}
      >
        <AlarmIcon htmlColor="currentColor" />
        <Typography color="inherit" variant="caption">
          {t('time-relative.hours', { hours: DEFAULT_DUEL_DURATION_HOURS })}
        </Typography>
      </Box>
    ),
    [t]
  );

  return (
    <StackLayout
      back={'/duels'}
      hideBackArrow={
        history.location.pathname.includes('/overview') &&
        !!data?.duelData?.isOnboardingDuel
      }
      title={t('common.duel.title')}
      toolbar={RoundDuration}
    >
      <RuleWrapper>
        <div className={classes.wrapper}>
          <div className={classes.topbar}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              overflow="auto"
            >
              <Avatar
                className={classes.avatar}
                src={userData?.user?.avatar ?? undefined}
              >
                {!userData?.user?.avatar && (
                  <PermIdentityOutlined fontSize="inherit" />
                )}
              </Avatar>
              <Typography
                variant="subtitle2"
                color="inherit"
                className={classes.label}
              >
                {t('common.me')}
              </Typography>
            </Box>
            <Typography variant="h2" color="inherit" className={classes.score}>
              {`${userData?.duelScore ?? 0} : ${opponentData?.duelScore ?? 0}`}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              overflow="auto"
            >
              <Avatar
                className={classes.avatar}
                src={opponentData?.user?.avatar ?? undefined}
              >
                {!opponentData?.user?.avatar && (
                  <PermIdentityOutlined fontSize="inherit" />
                )}
              </Avatar>
              <Typography
                variant="subtitle2"
                color="inherit"
                className={classes.label}
              >
                {(opponentData?.user?.username &&
                  opponentData?.user?.username.replace(
                    /DELETED[0-9]{0,11}_/,
                    ''
                  )) ?? <>&nbsp;</>}
              </Typography>
            </Box>
          </div>
          {classroomTitle && history.location.pathname.includes('overview') && (
            <Box className={classes.classroomIndicator}>
              <Typography variant="overline" color="inherit">
                {t('common.classroom')}
              </Typography>
              <Box mt={1}>
                <Typography variant="caption" color="inherit">
                  {classroomTitle}
                </Typography>
              </Box>
            </Box>
          )}
          <div className={classes.content}>{children}</div>
        </div>
      </RuleWrapper>
    </StackLayout>
  );
};

export default Duel;
