import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  avatar: {
    fontSize: '1.75rem',
    borderColor: '#1E274A',
    borderWidth: 1.5,
    height: 32,
    width: 32,
    marginRight: -12,
  },
}));
