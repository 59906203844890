import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  backDropBox: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 1110,
  },
  customTooltip: {
    padding: 16,
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: 'white',
    maxWidth: 250,
    zIndex: 1111,
    filter:
      ' drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.12))',
  },
  customArrow: {
    color: 'white',
    filter: ' drop-shadow(2px 0px 4px rgba(0, 0, 0, 0.14)) ',
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
  },
  icon: {
    color: theme.palette.action.disabled,
    fontSize: 16,
  },
  bigIcon: {
    color: theme.palette.action.disabled,
    fontSize: 22,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialog: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
