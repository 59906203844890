import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';
import theme from '@/theme';
import { ReactComponent as ErrorIcon } from './index.svg';
import { LS_KEY_TABINDEX } from '@/utils/constants';
import { TabNavIdsEnum } from '@/type';

type LinkNotValidProps = {
  exploreLink: string;
};

const LinkNotValid: React.FC<LinkNotValidProps> = ({ exploreLink }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = () => {
    localStorage.setItem(`${TabNavIdsEnum.QUIZTIME}${LS_KEY_TABINDEX}`, '1');
    localStorage.setItem(`${TabNavIdsEnum.CLASSROOMS}${LS_KEY_TABINDEX}`, '1');
    history.push(exploreLink);
  };

  return (
    <Box
      py={7}
      px={4}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      height="100%"
      boxSizing="border-box"
      style={{ backgroundColor: theme.palette.common.white }}
      className="fallback"
    >
      <Box display="flex" flexDirection="column" alignItems="center" pt={30}>
        <ErrorIcon />
        <Box mb={1} mt={3}>
          <Typography variant="subtitle1">{t('deep-links.title')}</Typography>
        </Box>
        <Box marginBottom={5} marginX={8}>
          <Typography align="center" variant="body2">
            {t('deep-links.link-not-valid.descr')}
          </Typography>
        </Box>
      </Box>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={handleClick}
      >
        {t('deep-links.btn-explore')}
      </Button>
    </Box>
  );
};

export default LinkNotValid;
