import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const BoxIntoIcon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...rest}>
      <g clipPath="url(#clip0_19941_17564)">
        <path
          d="M22.3409 8.69032C22.0664 8.41573 21.6212 8.41573 21.3466 8.69032L18.74 11.2969H5.26001L2.65343 8.69032C2.37888 8.41573 1.93366 8.41573 1.65907 8.69032C1.38448 8.96491 1.38448 9.41009 1.65907 9.68468L4.26565 12.2913V23.2969C4.26565 23.6852 4.58046 24 4.96877 24H19.0313C19.4196 24 19.7344 23.6852 19.7344 23.2969V12.2913L22.3409 9.68468C22.6155 9.41013 22.6155 8.96491 22.3409 8.69032ZM5.6719 22.5938V12.7031H18.3281V22.5938H5.6719Z"
          fill="#2B75B3"
        />
        <path
          d="M11.5028 9.6847C11.6401 9.822 11.82 9.89062 12 9.89062C12.18 9.89062 12.3599 9.822 12.4972 9.6847L16.7159 5.46595C16.917 5.26486 16.9771 4.96242 16.8684 4.69969C16.7596 4.43695 16.5031 4.26562 16.2188 4.26562H14.8125V0.703125C14.8125 0.314812 14.4977 0 14.1094 0H9.89062C9.50231 0 9.1875 0.314812 9.1875 0.703125V4.26562H7.78124C7.49685 4.26562 7.24045 4.43695 7.13165 4.69969C7.02285 4.96242 7.08299 5.26486 7.28409 5.46595L11.5028 9.6847ZM9.89062 5.67188C10.2789 5.67188 10.5937 5.35706 10.5937 4.96875V1.40625H13.4063V4.96875C13.4063 5.35706 13.7211 5.67188 14.1094 5.67188H14.5213L12 8.19314L9.47873 5.67188H9.89062Z"
          fill="#2B75B3"
        />
      </g>
      <defs>
        <clipPath id="clip0_19941_17564">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default BoxIntoIcon;
