import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(17),
  },
  close: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    position: 'absolute',
  },
  adventageListItem: {
    display: 'flex',
    alignItems: 'center',
  },
  circleIcon: {
    marginRight: 13,
  },
  subscriptionOptions: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  subscriptionPlaceholderText: {
    marginVertical: 20,
    fontSize: 16,
    letterSpacing: 0.1,
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
}));
