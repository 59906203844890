import React, { useMemo } from 'react';
import clsx from 'clsx';

import {
  Box,
  Button,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import DuelOutlinedIcon from '@/components/icons/duel-outlined-icon';

import { useUser } from '@/contexts/user-context';
import { useStyles } from './styles';
import { useCreateQuizDuel } from '@/hooks/useCreateQuizDuel';
import { Participant } from '@/type';
import Ava from '@/components/ava';
import { MEMBER_LIST_ITEM_CSS_ID } from '@/utils/constants';

const QuizMemberItem: React.FC<Participant> = ({
  id,
  userId,
  username,
  avatar,
  score,
  rank,
  joinedRank,
  isAvailableForDuel,
  divider,
  isRanking,
  isMemberSticky,
  sessionId,
  ...rest
}) => {
  const classes = useStyles();
  const { user } = useUser();
  const isMy = useMemo(() => userId === user?.id, [userId, user]);

  const { handleCreateQuizDuel, creatingLoading } = useCreateQuizDuel({
    sessionId,
    opponentId: userId,
  });

  return (
    <>
      <ListItem
        divider={divider}
        component="div"
        id={isRanking && isMy ? MEMBER_LIST_ITEM_CSS_ID : undefined}
        className={clsx(
          isMy && classes.my,
          isMy && isRanking && classes.myRanking
        )}
        {...rest}
      >
        {isRanking &&
          (joinedRank && (!isMemberSticky || !isMy) ? (
            <div className={classes.joinedRankItem}>
              <div className={classes.joinedRankCircle}></div>
              <div className={classes.joinedRankLine}></div>
            </div>
          ) : (
            <Box className={clsx(classes.rank, isMy && classes.myRank)}>
              <Typography
                color="textSecondary"
                variant="h2"
                className={clsx(isMy && classes.myLabel)}
              >
                {String('0' + rank).slice(-2)}
              </Typography>
            </Box>
          ))}
        <span className={clsx(isMy && isRanking && classes.myAvaWrap)}>
          <Ava src={avatar} />
        </span>
        <ListItemText
          primary={
            <>
              <Typography
                variant="subtitle2"
                className={clsx(
                  classes.label,
                  isMy && isRanking && classes.myLabel
                )}
              >
                {username}
              </Typography>
              {!isRanking && (
                <Typography variant="caption">{score} P</Typography>
              )}
            </>
          }
        />
        <div className={classes.secondaryAction}>
          {isRanking ? (
            <Typography
              variant="caption"
              className={clsx(isMy && classes.myLabel)}
            >
              {score} P
            </Typography>
          ) : isMy ? (
            <></>
          ) : (
            <Button
              variant="outlined"
              disableRipple={!isAvailableForDuel || creatingLoading}
              onClick={() =>
                !isAvailableForDuel || creatingLoading
                  ? null
                  : handleCreateQuizDuel()
              }
              className={
                !isAvailableForDuel || creatingLoading
                  ? classes.btnCreateDuelDisabled
                  : ''
              }
            >
              <DuelOutlinedIcon
                color={!isAvailableForDuel ? 'disabled' : 'primary'}
              />
            </Button>
          )}
        </div>
      </ListItem>
    </>
  );
};

export default QuizMemberItem;
