import React from 'react';

import Typography from '@material-ui/core/Typography';

import AlarmIcon from '../icons/alarm';

import { useStyles } from './styles';

const RemainingTime: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography
      variant="caption"
      style={{ display: 'inline-flex', alignItems: 'center' }}
    >
      <AlarmIcon htmlColor="#929DA8" className={classes.icon} />
      {children}
    </Typography>
  );
};

export default RemainingTime;
