import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  linkText: {
    color: theme.palette.primary.dark,
  },
  descriptionTitle: {
    marginBottom: 12,
  },
  body1: {
    fontWeight: 700,
    marginBottom: 12,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  lotteryCaption: {
    padding: "4px 10px 4px 10px", 
  }, 
  lotteryCaptionWrapper: {
    width: "min-content",
    border: '1px solid #E6EEFA',
    borderBottom: 'none',
    borderRadius: "4px 4px 0px 0px"
  }
}));
