import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: 16,
    width: 14,
  },
});

const Lern: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 14 16"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path
        d="M0.290314 2.83859V13.1612C0.290314 13.914 0.589391 14.6361 1.12175 15.1684C1.38535 15.432 1.69829 15.6411 2.0427 15.7838C2.3871 15.9265 2.75624 15.9999 3.12902 15.9999H13.7097V-0.00012207H3.12902C2.37615 -0.00012207 1.65411 0.298955 1.12175 0.831317C0.589391 1.36368 0.290314 2.08572 0.290314 2.83859ZM3.12902 1.54826H8.03225V10.3225H3.12902C2.68019 10.3223 2.2378 10.4294 1.8387 10.6347V2.83859C1.8387 2.49637 1.97465 2.16817 2.21663 1.92619C2.45861 1.68421 2.78681 1.54826 3.12902 1.54826ZM12.1613 14.4515H3.12902C2.78681 14.4515 2.45861 14.3155 2.21663 14.0736C1.97465 13.8316 1.8387 13.5034 1.8387 13.1612C1.8387 12.819 1.97465 12.4908 2.21663 12.2488C2.45861 12.0068 2.78681 11.8708 3.12902 11.8708H12.1613V14.4515ZM12.1613 10.3225H9.58064V1.54826H12.1613V10.3225Z"
        fill="#2B75B3"
      />
    </SvgIcon>
  );
};

export default Lern;
