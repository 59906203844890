import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(10),
  },
  new: {
    background: `linear-gradient(0deg, rgba(226, 245, 253, 0.48), rgba(226, 245, 253, 0.48)), #FFFFFF`,
  },
  secondary: {
    right: theme.spacing(4),
  },
}));
