import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { ReactComponent as FeedbackThankYouIcon } from './index.svg';
import AppButton from '@/components/app-button';
import { useStyles } from './styles';

interface FeedbackThankYouProps {
  nextLink: string;
  setFeedbackVisible: (value: boolean) => void;
}

const FeedbackThankYou: React.FC<FeedbackThankYouProps> = ({
  nextLink,
  setFeedbackVisible,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  function redirectToNextPage() {
    setFeedbackVisible(false);
    history.replace(nextLink);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      boxSizing="border-box"
      style={{ background: '#fff' }}
    >
      <Box className={classes.header}>
        <Typography align="center" variant="h2" color="inherit">
          {t('feedback-for-question.thank-you.title')}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
        py={7}
        px={4}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
          mb={20}
        >
          <FeedbackThankYouIcon />
          <Typography
            align="center"
            variant="subtitle1"
            style={{ marginTop: 12 }}
          >
            {t('feedback-for-question.thank-you.info')}
          </Typography>
        </Box>
        <AppButton
          fullWidth
          color="primary"
          variant="contained"
          onClick={redirectToNextPage}
        >
          {t('common.ok')}
        </AppButton>
      </Box>
    </Box>
  );
};

export default FeedbackThankYou;
