import React, { useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { Box, Typography } from '@material-ui/core';

import * as Form from '@/components/form';
import AppButton from '@/components/app-button';
import { isValidPassword } from '@/utils/password-validator';

import { useResetPasswordMutation } from '@/graphql';

interface Form {
  email: string;
  password: string;
  passwordConfirmation: string;
}

const Reset: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const keyParam = params.get('key') || '';
  const resetPasswordKey = keyParam.replace('/', '');

  const { register, errors, trigger, setError, watch } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  const [resetPassword, { loading }] = useResetPasswordMutation();

  const rules = useMemo(
    () => ({
      password: {
        validate: (value: string) => {
          trigger('passwordConfirmation');

          return value && !isValidPassword(value) ? '' : undefined;
        },
      },
      passwordConfirmation: {
        validate: (value: string) =>
          value && watch('password') !== value
            ? (t('fields.confirm-password.validation.regex') as string)
            : undefined,
      },
    }),
    [t, trigger, watch]
  );

  const reset = useCallback(async () => {
    const { password, passwordConfirmation } = watch();
    try {
      const { data } = await resetPassword({
        variables: {
          newPassword: password,
          newPasswordConfirmation: passwordConfirmation,
          resetPasswordKey,
        },
      });

      if (data && !data.userRestorePassword) {
        throw new Error('userRestorePassword couldnt be null');
      }

      if (data && data.userRestorePassword?.user?.id) {
        history.push('/reset-password/completed');
        return;
      }
    } catch {
      return setError('passwordConfirmation', {
        type: 'server',
        message: t('server.error.rest-failed'),
      });
    }
  }, [history, resetPassword, resetPasswordKey, setError, t, watch]);

  const isValid = useMemo(() => {
    const values = watch();

    return (
      isEmpty(errors) && !!values.password && !!values.passwordConfirmation
    );
  }, [watch, errors]);

  return (
    <Box>
      <Box paddingY={7}>
        <Form.Row noPadding>
          <Form.PasswordField
            fullWidth
            required
            data-cy-password
            variant="outlined"
            name="password"
            label={t('fields.password.label')}
            errors={errors.password}
            inputRef={register(rules.password)}
          />
        </Form.Row>
        <Box marginTop={2} marginBottom={6}>
          <Typography color="textPrimary" variant="caption">
            {t('fields.password.help')}
          </Typography>
        </Box>
        <Form.Row noPadding>
          <Form.PasswordField
            fullWidth
            required
            data-cy-confirmation-password
            variant="outlined"
            name="passwordConfirmation"
            label={t('fields.confirm-password.label')}
            errors={errors.passwordConfirmation}
            inputRef={register(rules.passwordConfirmation)}
          />
        </Form.Row>
      </Box>
      <AppButton
        fullWidth
        data-cy-reset-btn
        color="primary"
        variant="contained"
        disabled={!isValid}
        loading={loading}
        onClick={reset}
      >
        {t('common.confirm-password')}
      </AppButton>
    </Box>
  );
};

export default Reset;
