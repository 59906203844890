import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { AvatarGroup, Skeleton } from '@material-ui/lab';
import СupIcon from '@/components/icons/cup';
import { Check, PermIdentityOutlined } from '@material-ui/icons';
import { useStyles } from './styles';

type ItemProps = {
  learningDays?: number;
  currentAchievement?: number;
  users?: {
    avatar?: string;
    username?: string;
  }[];
  countUsers?: number | null;
  divider?: boolean;
};

const Item: React.FC<ItemProps> = ({
  divider = true,
  learningDays,
  users,
  countUsers,
  currentAchievement,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const usersAvatars = useMemo(() => {
    if (countUsers === 0) {
      return (
        <Typography variant="caption">
          {t('daily-tipper.no-achievement')}
        </Typography>
      );
    }

    if (countUsers && users && countUsers <= 3)
      return (
        <AvatarGroup classes={{ avatar: classes.avatar }}>
          {users.map((it) => (
            <Avatar
              key={it.username}
              alt={it.username}
              src={it.avatar}
              style={{ fontSize: '1.75rem' }}
            >
              {!it.avatar && <PermIdentityOutlined fontSize="inherit" />}
            </Avatar>
          ))}
        </AvatarGroup>
      );

    if (countUsers && users && countUsers > 3)
      return (
        <AvatarGroup classes={{ avatar: classes.avatar }}>
          {users.map((it) => (
            <Avatar
              key={it.username}
              alt={it.username}
              src={it.avatar}
              style={{ fontSize: '1.75rem' }}
            >
              {!it.avatar && <PermIdentityOutlined fontSize="inherit" />}
            </Avatar>
          ))}
          <Avatar className={classes.extraAvatar}>
            <Typography
              key="extra"
              component="span"
              className={classes.usersCount}
            >
              +{countUsers}
            </Typography>
          </Avatar>
        </AvatarGroup>
      );
  }, [t, classes, countUsers, users]);

  const learningDaysNode = learningDays ? (
    t('daily-tipper.learning-days', { value: learningDays })
  ) : (
    <Skeleton />
  );

  const isItemHighligted =
    currentAchievement && learningDays
      ? currentAchievement >= learningDays
      : false;

  return (
    <ListItem divider={divider} className={classes.root}>
      <ListItemIcon
        className={clsx(
          classes.itemIcon,
          isItemHighligted ? classes.itemIconActive : classes.itemIconDefault
        )}
      >
        <СupIcon color="inherit" style={{ fontSize: 24 }} />
        {isItemHighligted && (
          <span className={classes.cirlceCheck}>
            <Check style={{ fontSize: 11, color: '#fff' }} />
          </span>
        )}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant="subtitle2"
            style={{
              color: isItemHighligted ? '#2C3E50' : '#4A647C',
            }}
          >
            {learningDaysNode}
          </Typography>
        }
      />
      <ListItemSecondaryAction>{usersAvatars}</ListItemSecondaryAction>
    </ListItem>
  );
};

export default Item;
