import React, { useState, useCallback, useRef, useLayoutEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import {
  Link,
  Box,
  Collapse,
  Paper,
  Typography,
  IconButton,
  Avatar,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { useStyles } from './styles';

type ClassRoomParams = {
  hash: string;
};

type ClassRoomDescriptionProps = {
  description?: string | null;
  link?: string | null;
  orgId?: string | null;
  orgName?: string | null;
  orgEmail?: string | null;
  orgLogo?: string | null;
  orgWebsite?: string | null;
  isCRPublic?: boolean | null;
  sponsors?:
    | {
        id: string;
        title: string;
        logo?: string | null;
      }[]
    | undefined;
};

const ClassRoomDescription: React.FC<ClassRoomDescriptionProps> = ({
  description,
  link,
  orgId,
  orgName,
  orgEmail,
  orgLogo,
  orgWebsite,
  children,
  sponsors,
  isCRPublic,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { hash } = useParams<ClassRoomParams>();

  const [expanded, setExpanded] = useState(false);

  const descrBlockRef = useRef<HTMLDivElement | null>(null);
  const [descrBlockHeight, setDescrBlockHeight] = useState<number | null>(null);

  useLayoutEffect(() => {
    if (descrBlockRef?.current?.clientHeight)
      setDescrBlockHeight(descrBlockRef?.current?.clientHeight);
  }, []);

  const toggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const descriptionArr = description?.split('\r') || [];
  const descrBlock = (
    <div ref={descrBlockRef}>
      <Box paddingY={5} className={classes.descriptionBox}>
        <Typography variant="h2" className={classes.descriptionTitle}>
          {t('class-room.descr-title')}
        </Typography>
        {descriptionArr.map((line, idx) => (
          <Typography key={idx} variant="body1">
            <span dangerouslySetInnerHTML={{ __html: line }}></span>
          </Typography>
        ))}

        {link && (
          <Box mt={3}>
            <Link href={link}>
              <Typography variant="body1" className={classes.linkText}>
                {t('class-room.learning-more-link')}
              </Typography>
            </Link>
          </Box>
        )}
        {/* Organizator */}
        {(orgLogo || orgName || orgWebsite || orgEmail) && (
          <>
            <Box mt={7} mb={1}>
              <Typography variant="overline">
                {t('class-room.organizator')}
              </Typography>
            </Box>
            <Box display="flex" mt={2}>
              {orgLogo && (
                <RouterLink
                  to={
                    isCRPublic
                      ? `/auth/?hash=${hash}`
                      : `/classrooms/organizer/${orgId}`
                  }
                >
                  <Box>
                    <Avatar src={orgLogo} alt={orgName || ''}>
                      {t('class-room.learning-more-link')}
                    </Avatar>
                  </Box>
                </RouterLink>
              )}
              <Box ml={3} mb={1}>
                {orgName && (
                  <Box my={2}>
                    <Typography variant="body1">{orgName}</Typography>
                  </Box>
                )}
                {orgWebsite && (
                  <Box mb={1}>
                    <Link href={orgWebsite} target="_blank">
                      <Typography variant="body2" className={classes.linkText}>
                        {orgWebsite}
                      </Typography>
                    </Link>
                  </Box>
                )}
                {orgEmail && (
                  <Box mb={1}>
                    <Link
                      href={
                        isCRPublic
                          ? `/auth/?hash=${hash}`
                          : `mailto:${orgEmail}`
                      }
                      target={isCRPublic ? 'initial' : '_blank'}
                    >
                      <Typography variant="body2" className={classes.linkText}>
                        {orgEmail}
                      </Typography>
                    </Link>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        )}
        {/* Sponsor */}
        {!!sponsors?.length && (
          <Box mt={4} mb={1}>
            <Typography variant="overline">
              {t('class-room.sponsor')}
            </Typography>
          </Box>
        )}
        {sponsors?.map((it) => (
          <Box key={it.id} display="flex" alignItems="center">
            {it.logo && (
              <Box mt={2}>
                <Avatar src={it.logo} alt={it.title} />
              </Box>
            )}
            <Box my={2} ml={3}>
              <Typography variant="body1">{it.title}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </div>
  );

  return (
    <Box display="grid" gridRowGap={8} mx={2} my={2}>
      <Paper elevation={0}>
        {descrBlockHeight && descrBlockHeight > 300 ? (
          <>
            <Collapse in={expanded} collapsedHeight={300}>
              {descrBlock}
            </Collapse>
            <Box display="grid">
              <IconButton aria-label="expand" onClick={toggle}>
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Box>
          </>
        ) : (
          <Box px={6}>{descrBlock}</Box>
        )}
      </Paper>
      {children}
    </Box>
  );
};

export default ClassRoomDescription;
