import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: 18,
    width: 18,
  },
});

const AlertIcon: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 18 18"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.9915 0.666672C4.3915 0.666672 0.666504 4.40001 0.666504 9.00001C0.666504 13.6 4.3915 17.3333 8.9915 17.3333C13.5998 17.3333 17.3332 13.6 17.3332 9.00001C17.3332 4.40001 13.5998 0.666672 8.9915 0.666672ZM9.83317 9.83334V4.83334H8.1665V9.83334H9.83317ZM9.83317 13.1667V11.5H8.1665V13.1667H9.83317ZM2.33317 9C2.33317 12.6833 5.3165 15.6667 8.99984 15.6667C12.6832 15.6667 15.6665 12.6833 15.6665 9C15.6665 5.31667 12.6832 2.33334 8.99984 2.33334C5.3165 2.33334 2.33317 5.31667 2.33317 9Z"
        fill="#EB5757"
      />
    </SvgIcon>
  );
};

export default AlertIcon;
