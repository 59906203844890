import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import { useApolloClient } from '@apollo/client';

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import {
  ArrowForward,
  HourglassEmpty,
  PermIdentityOutlined,
} from '@material-ui/icons';

import RemainingTime from '@/components/remaining-time';
import { useRelativeTime } from '@/hooks/useTime';

import { useStyles } from './styles';
import ChipIndicator from '@/components/chip-indicator';
import { GetActiveQuizDuelsQuery } from '@/graphql';
import { ACTIVE_QUIZ_DUELS } from '@/apollo/queries';
import { ClassroomTypes } from '@/type';
import { DEFAULT_DUEL_DURATION_HOURS } from '@/utils/constants';

interface DuelItemProps {
  isNew: boolean;
  duelId?: string | null;
  sessionId?: string | null;
  username?: string | null;
  avatar?: string | null;
  youTurn: boolean;
  isInitiator?: boolean;
  created: string;
  roundNumber?: number | null;
  divider?: boolean;
  classroomType?: number | null | undefined;
  loading: boolean;
  highlightDisabled?: boolean | null;
  timeLeftInMinutes?: number | null;
  shouldNegativeIntervalCleared?: boolean | null;
  onSelect?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const DuelItem: React.FC<DuelItemProps> = ({
  duelId,
  sessionId,
  isNew,
  youTurn,
  isInitiator,
  roundNumber,
  username,
  avatar,
  created,
  classroomType,
  divider,
  loading,
  timeLeftInMinutes,
  highlightDisabled,
  shouldNegativeIntervalCleared,
  onSelect,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { cache } = useApolloClient();

  const [duelFinished, setDuelFinished] = useState<boolean>(false);
  const { timeLeft, timeLeftInMillis } = useRelativeTime(
    DateTime.fromISO(created),
    {
      hours: timeLeftInMinutes
        ? timeLeftInMinutes / 60
        : DEFAULT_DUEL_DURATION_HOURS,
    },
    1000,
    !!shouldNegativeIntervalCleared
  );

  useEffect(() => {
    if (duelId && !duelFinished && timeLeftInMillis < 1) {
      setDuelFinished(true);
      const duels: GetActiveQuizDuelsQuery | undefined | null = cache.readQuery(
        {
          query: ACTIVE_QUIZ_DUELS,
          variables: {
            quizSessionId: sessionId,
          },
        }
      );
      const filteredQuizDuels = duels?.activeQuizDuels?.filter(
        (it) => it?.id !== duelId
      );
      cache.writeQuery({
        query: ACTIVE_QUIZ_DUELS,
        variables: {
          quizSessionId: sessionId,
        },
        data: {
          activeQuizDuels: filteredQuizDuels,
        },
      });
    }
  }, [timeLeftInMillis, duelFinished, cache, duelId, sessionId]);

  return (
    <ListItem
      button
      divider={divider}
      className={clsx(isNew && !highlightDisabled && classes.created)}
      onClick={onSelect}
      {...rest}
    >
      <ListItemAvatar>
        <Avatar src={avatar ?? undefined} style={{ fontSize: '1.75rem' }}>
          {!avatar && <PermIdentityOutlined fontSize="inherit" />}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={username?.replace(/DELETED[0-9]{0,11}_/, '')}
        secondary={
          <Box component="span">
            <Box component="span" display="flex" mb={2} alignItems="center">
              {roundNumber && (
                <Box marginRight={2} component="span">
                  {isNew
                    ? t('common.duel.created')
                    : t('common.duel.round-number', {
                        round: roundNumber,
                      })}
                </Box>
              )}
              <Box component="span" display="inline-flex" alignItems="center">
                <RemainingTime>{timeLeft}</RemainingTime>
              </Box>
            </Box>
            {!!classroomType && (
              <ChipIndicator
                title={t(
                  classroomType === ClassroomTypes.NETWORK
                    ? 'common.network-cr'
                    : 'common.blended-cr'
                )}
              />
            )}
          </Box>
        }
      />
      <ListItemSecondaryAction className={classes.action}>
        {isNew && !isInitiator && (
          <Button
            variant="outlined"
            color="primary"
            component="div"
            className={classes.offBtn}
          >
            {!loading ? t('common.duel.off') : <CircularProgress size={20} />}
          </Button>
        )}
        {isInitiator && isNew && <ArrowForward color="inherit" />}
        {!isNew && !loading && youTurn && <ArrowForward color="inherit" />}
        {!isNew && !loading && !youTurn && <HourglassEmpty color="disabled" />}
        {!isNew && !!loading && <CircularProgress size={20} />}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default DuelItem;
