import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: 80,
    width: 100,
  },
});

const NotFoundIcon: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 80 100"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <g clipPath="url(#clip0_1791_1613)">
        <path
          d="M96.43 63.7928H90.8767H80.6955C78.7254 63.7928 77.1255 62.2064 77.1255 60.2201V60.2068C77.1255 58.2205 78.7254 56.5942 80.6955 56.5942H90.8767V56.6208C92.8468 56.6208 94.4467 55.0211 94.4467 53.0348C94.4467 51.0485 92.8468 49.4222 90.8767 49.4222V49.3955H87.8355C85.8654 49.3955 84.2655 47.8092 84.2655 45.8229V45.8095C84.2655 43.8233 85.8654 42.1969 87.8355 42.1969H90.8767H95.1078C97.0779 42.1969 98.6778 40.6105 98.6778 38.6243V38.6109C98.6778 36.6246 97.0779 34.9983 95.1078 34.9983H88.2058C86.8703 28.0663 83.5119 21.2676 78.1172 15.8286C64.7231 2.32452 43.5543 1.45802 29.1553 13.1891L17.1099 13.0025C15.1398 12.9758 13.5267 14.5755 13.5002 16.5618C13.5002 16.9884 13.5663 17.4016 13.6985 17.7882C14.1745 19.1746 15.4835 20.1878 17.0306 20.2011L23.2582 20.3344C25.2283 20.3611 26.815 21.9874 26.7886 23.9737C26.7621 25.96 25.149 27.5597 23.1789 27.533L13.5928 27.3997L3.63644 27.2664C1.63988 27.2531 0.0267703 28.8395 0.000325817 30.8257C-0.0261186 32.812 1.56055 34.4384 3.53066 34.465L13.487 34.5984L15.9331 34.6383C17.9032 34.665 19.4899 36.2914 19.4634 38.2777C19.437 40.2639 17.8239 41.8636 15.8538 41.837L13.4077 41.7836L12.0854 41.757C10.1153 41.7303 8.50222 43.33 8.47577 45.3163C8.44933 47.3026 10.036 48.929 12.0061 48.9556L16.1579 49.0223C17.4933 56.0343 20.8518 62.713 26.2464 68.1386C39.5215 81.5494 60.5052 82.5892 74.9042 70.9914H96.43C98.4001 70.9914 100 69.405 100 67.4188V67.4054C100 65.4191 98.4001 63.7928 96.43 63.7928Z"
          fill="#E6EEFA"
        />
        <path
          d="M36.4002 74.4H31.6002C31.3735 74.4 31.2002 74.5734 31.2002 74.8C31.2002 75.0267 31.3735 75.2 31.6002 75.2H36.4002C36.6269 75.2 36.8002 75.0267 36.8002 74.8C36.8002 74.5734 36.6269 74.4 36.4002 74.4Z"
          fill="#2C3E50"
        />
        <path
          d="M47.6004 74.4H38.8004C38.5737 74.4 38.4004 74.5734 38.4004 74.8C38.4004 75.0267 38.5737 75.2 38.8004 75.2H47.6004C47.827 75.2 48.0004 75.0267 48.0004 74.8C48.0004 74.5734 47.827 74.4 47.6004 74.4Z"
          fill="#2C3E50"
        />
        <path
          d="M81.2006 62.4H78.0006C77.7739 62.4 77.6006 62.5734 77.6006 62.8C77.6006 63.0267 77.7739 63.2 78.0006 63.2H81.2006C81.4272 63.2 81.6006 63.0267 81.6006 62.8C81.6006 62.5734 81.4272 62.4 81.2006 62.4Z"
          fill="#2C3E50"
        />
        <path
          d="M92.4002 62.4H83.6002C83.3735 62.4 83.2002 62.5734 83.2002 62.8C83.2002 63.0267 83.3735 63.2 83.6002 63.2H92.4002C92.6269 63.2 92.8002 63.0267 92.8002 62.8C92.8002 62.5734 92.6269 62.4 92.4002 62.4Z"
          fill="#2C3E50"
        />
        <path
          d="M88.0001 60H81.6002C81.3735 60 81.2002 60.1733 81.2002 60.4C81.2002 60.6267 81.3735 60.8 81.6002 60.8H88.0001C88.2268 60.8 88.4001 60.6267 88.4001 60.4C88.4001 60.1733 88.2268 60 88.0001 60Z"
          fill="#2C3E50"
        />
        <path
          d="M40.4004 76.8H20.8004C20.5737 76.8 20.4004 76.9734 20.4004 77.2001C20.4004 77.4267 20.5737 77.6001 20.8004 77.6001H40.4004C40.6271 77.6001 40.8004 77.4267 40.8004 77.2001C40.8004 76.9734 40.6271 76.8 40.4004 76.8Z"
          fill="#2C3E50"
        />
        <path
          d="M45.2002 76.8H43.6002C43.3735 76.8 43.2002 76.9734 43.2002 77.2001C43.2002 77.4267 43.3735 77.6001 43.6002 77.6001H45.2002C45.4269 77.6001 45.6002 77.4267 45.6002 77.2001C45.6002 76.9734 45.4269 76.8 45.2002 76.8Z"
          fill="#2C3E50"
        />
        <path
          d="M46.8002 3.59998H45.6002C45.3735 3.59998 45.2002 3.77331 45.2002 3.99998C45.2002 4.22664 45.3735 4.39998 45.6002 4.39998H46.8002C47.0269 4.39998 47.2002 4.22664 47.2002 3.99998C47.2002 3.77331 47.0269 3.59998 46.8002 3.59998Z"
          fill="#2C3E50"
        />
        <path
          d="M52.8002 3.59998H51.6002C51.3735 3.59998 51.2002 3.77331 51.2002 3.99998C51.2002 4.22664 51.3735 4.39998 51.6002 4.39998H52.8002C53.0268 4.39998 53.2002 4.22664 53.2002 3.99998C53.2002 3.77331 53.0268 3.59998 52.8002 3.59998Z"
          fill="#2C3E50"
        />
        <path
          d="M46.9739 1.21338C46.8139 1.05338 46.5606 1.05338 46.4139 1.21338C46.2539 1.37338 46.2539 1.62672 46.4139 1.77338L47.2673 2.62672C47.3473 2.70672 47.4539 2.74672 47.5473 2.74672C47.6539 2.74672 47.7473 2.70672 47.8273 2.62672C47.9873 2.46672 47.9873 2.21338 47.8273 2.06672L46.9739 1.21338Z"
          fill="#2C3E50"
        />
        <path
          d="M51.2269 5.45337C51.0669 5.29337 50.8135 5.29337 50.6669 5.45337C50.5069 5.61337 50.5069 5.86671 50.6669 6.01337L51.5202 6.86671C51.6002 6.94671 51.7069 6.98671 51.8002 6.98671C51.8935 6.98671 52.0002 6.94671 52.0802 6.86671C52.2402 6.70671 52.2402 6.45338 52.0802 6.30671L51.2269 5.45337Z"
          fill="#2C3E50"
        />
        <path
          d="M49.1998 0C48.9731 0 48.7998 0.173333 48.7998 0.4V1.6C48.7998 1.82667 48.9731 2 49.1998 2C49.4265 2 49.5998 1.82667 49.5998 1.6V0.4C49.5998 0.173333 49.4265 0 49.1998 0Z"
          fill="#2C3E50"
        />
        <path
          d="M49.1998 6C48.9731 6 48.7998 6.17333 48.7998 6.4V7.6C48.7998 7.82667 48.9731 8 49.1998 8C49.4265 8 49.5998 7.82667 49.5998 7.6V6.4C49.5998 6.17333 49.4265 6 49.1998 6Z"
          fill="#2C3E50"
        />
        <path
          d="M51.5065 1.21339L50.6532 2.06673C50.4932 2.22673 50.4932 2.48006 50.6532 2.62673C50.7332 2.70673 50.8399 2.74673 50.9332 2.74673C51.0399 2.74673 51.1332 2.70673 51.2132 2.62673L52.0665 1.77339C52.2265 1.61339 52.2265 1.36006 52.0665 1.21339C51.9199 1.06673 51.6665 1.06673 51.5065 1.21339Z"
          fill="#2C3E50"
        />
        <path
          d="M47.2673 5.45338L46.4139 6.30672C46.2539 6.46672 46.2539 6.72005 46.4139 6.86672C46.4939 6.94672 46.6006 6.98672 46.6939 6.98672C46.8006 6.98672 46.8939 6.94672 46.9739 6.86672L47.8273 6.01338C47.9873 5.85338 47.9873 5.60005 47.8273 5.45338C47.6673 5.30672 47.4139 5.30672 47.2673 5.45338Z"
          fill="#2C3E50"
        />
        <path
          d="M7.20058 34.4H6.00059C5.77392 34.4 5.60059 34.5734 5.60059 34.8C5.60059 35.0267 5.77392 35.2 6.00059 35.2H7.20058C7.42725 35.2 7.60058 35.0267 7.60058 34.8C7.60058 34.5734 7.42725 34.4 7.20058 34.4Z"
          fill="#2C3E50"
        />
        <path
          d="M13.2006 34.4H12.0006C11.7739 34.4 11.6006 34.5734 11.6006 34.8C11.6006 35.0267 11.7739 35.2 12.0006 35.2H13.2006C13.4273 35.2 13.6006 35.0267 13.6006 34.8C13.6006 34.5734 13.4273 34.4 13.2006 34.4Z"
          fill="#2C3E50"
        />
        <path
          d="M7.37434 32.0134C7.21434 31.8534 6.961 31.8534 6.81434 32.0134C6.65434 32.1734 6.65434 32.4268 6.81434 32.5734L7.66767 33.4268C7.74767 33.5068 7.85434 33.5468 7.94767 33.5468C8.05434 33.5468 8.14767 33.5068 8.22767 33.4268C8.38767 33.2668 8.38767 33.0134 8.22767 32.8668L7.37434 32.0134Z"
          fill="#2C3E50"
        />
        <path
          d="M11.6273 36.2533C11.4673 36.0933 11.2139 36.0933 11.0673 36.2533C10.9073 36.4133 10.9073 36.6666 11.0673 36.8133L11.9206 37.6666C12.0006 37.7466 12.1073 37.7866 12.2006 37.7866C12.3073 37.7866 12.4006 37.7466 12.4806 37.6666C12.6406 37.5066 12.6406 37.2533 12.4806 37.1066L11.6273 36.2533Z"
          fill="#2C3E50"
        />
        <path
          d="M9.60019 30.8C9.37353 30.8 9.2002 30.9734 9.2002 31.2V32.4001C9.2002 32.6267 9.37353 32.8001 9.60019 32.8001C9.82686 32.8001 10.0002 32.6267 10.0002 32.4001V31.2C10.0002 30.9734 9.82686 30.8 9.60019 30.8Z"
          fill="#2C3E50"
        />
        <path
          d="M9.60019 36.8C9.37353 36.8 9.2002 36.9734 9.2002 37.2V38.4001C9.2002 38.6267 9.37353 38.8001 9.60019 38.8001C9.82686 38.8001 10.0002 38.6267 10.0002 38.4001V37.2C10.0002 36.9734 9.82686 36.8 9.60019 36.8Z"
          fill="#2C3E50"
        />
        <path
          d="M11.9069 32.0134L11.0536 32.8668C10.8936 33.0268 10.8936 33.2801 11.0536 33.4268C11.1336 33.5068 11.2403 33.5468 11.3336 33.5468C11.4403 33.5468 11.5336 33.5068 11.6136 33.4268L12.4669 32.5734C12.6269 32.4134 12.6269 32.1601 12.4669 32.0134C12.3203 31.8668 12.0669 31.8668 11.9069 32.0134Z"
          fill="#2C3E50"
        />
        <path
          d="M7.66767 36.2533L6.81434 37.1066C6.65434 37.2666 6.65434 37.52 6.81434 37.6666C6.89434 37.7466 7.001 37.7866 7.09434 37.7866C7.201 37.7866 7.29434 37.7466 7.37434 37.6666L8.22767 36.8133C8.38767 36.6533 8.38767 36.4 8.22767 36.2533C8.06767 36.1066 7.81434 36.1066 7.66767 36.2533Z"
          fill="#2C3E50"
        />
        <path
          d="M22.7338 61.4666L21.8405 60.9733C21.4405 60.7466 21.1071 60.4266 20.8805 60.0133L20.3871 59.1199C20.3205 58.9866 20.1871 58.9066 20.0405 58.9066C19.8938 58.9066 19.7605 58.9866 19.6938 59.1199L19.2005 60.0133C18.9738 60.4133 18.6538 60.7466 18.2405 60.9733L17.3471 61.4666C17.2138 61.5333 17.1338 61.6666 17.1338 61.8133C17.1338 61.9599 17.2138 62.0933 17.3471 62.1599L18.2405 62.6533C18.6405 62.8799 18.9738 63.1999 19.2005 63.6133L19.6938 64.5066C19.7605 64.6399 19.8938 64.7199 20.0405 64.7199C20.1871 64.7199 20.3205 64.6399 20.3871 64.5066L20.8805 63.6133C21.1071 63.2133 21.4271 62.8799 21.8405 62.6533L22.7338 62.1599C22.8671 62.0933 22.9471 61.9599 22.9471 61.8133C22.9471 61.6666 22.8671 61.5333 22.7338 61.4666ZM21.4538 61.9466C20.9205 62.2399 20.4805 62.6799 20.1738 63.2266L20.0405 63.4799L19.9071 63.2266C19.6138 62.6933 19.1738 62.2399 18.6271 61.9466L18.3738 61.8133L18.6271 61.6799C19.1605 61.3866 19.6138 60.9466 19.9071 60.3999L20.0405 60.1466L20.1738 60.3999C20.4671 60.9333 20.9071 61.3733 21.4538 61.6799L21.7071 61.8133L21.4538 61.9466Z"
          fill="#2C3E50"
        />
        <path
          d="M99.267 42.9333L98.3737 42.44C97.9737 42.2133 97.6403 41.8933 97.4137 41.48L96.9203 40.5866C96.8537 40.4533 96.7203 40.3733 96.5737 40.3733C96.427 40.3733 96.2937 40.4533 96.227 40.5866L95.7337 41.48C95.507 41.88 95.187 42.2133 94.7737 42.44L93.8803 42.9333C93.747 43 93.667 43.1333 93.667 43.28C93.667 43.4266 93.747 43.56 93.8803 43.6267L94.7737 44.12C95.1737 44.3467 95.507 44.6667 95.7337 45.08L96.227 45.9733C96.2937 46.1067 96.427 46.1867 96.5737 46.1867C96.7203 46.1867 96.8537 46.1067 96.9203 45.9733L97.4137 45.08C97.6403 44.68 97.9603 44.3467 98.3737 44.12L99.267 43.6267C99.4003 43.56 99.4803 43.4266 99.4803 43.28C99.467 43.1466 99.387 43.0133 99.267 42.9333ZM97.987 43.4267C97.4537 43.72 97.0137 44.16 96.707 44.7067L96.5737 44.96L96.4403 44.7067C96.147 44.1733 95.707 43.7333 95.1603 43.4267L94.907 43.2933L95.1603 43.16C95.6937 42.8666 96.147 42.4266 96.4403 41.88L96.5737 41.6266L96.707 41.88C97.0003 42.4133 97.4403 42.8666 97.987 43.16L98.2403 43.2933L97.987 43.4267Z"
          fill="#2C3E50"
        />
        <path
          d="M62.0008 76.8H61.2008C60.9741 76.8 60.8008 76.9734 60.8008 77.2001C60.8008 77.4267 60.9741 77.6001 61.2008 77.6001H62.0008C62.2275 77.6001 62.4008 77.4267 62.4008 77.2001C62.4008 76.9734 62.2275 76.8 62.0008 76.8Z"
          fill="#2C3E50"
        />
        <path
          d="M66.0008 76.8H65.2008C64.9741 76.8 64.8008 76.9734 64.8008 77.2001C64.8008 77.4267 64.9741 77.6001 65.2008 77.6001H66.0008C66.2275 77.6001 66.4008 77.4267 66.4008 77.2001C66.4008 76.9734 66.2275 76.8 66.0008 76.8Z"
          fill="#2C3E50"
        />
        <path
          d="M63.6012 74.4C63.3745 74.4 63.2012 74.5734 63.2012 74.8V75.6C63.2012 75.8267 63.3745 76 63.6012 76C63.8278 76 64.0012 75.8267 64.0012 75.6V74.8C64.0012 74.5734 63.8278 74.4 63.6012 74.4Z"
          fill="#2C3E50"
        />
        <path
          d="M63.6012 78.4C63.3745 78.4 63.2012 78.5734 63.2012 78.8V79.6C63.2012 79.8267 63.3745 80 63.6012 80C63.8278 80 64.0012 79.8267 64.0012 79.6V78.8C64.0012 78.5734 63.8278 78.4 63.6012 78.4Z"
          fill="#2C3E50"
        />
        <path
          d="M84.8006 23.2H84.0006C83.7739 23.2 83.6006 23.3733 83.6006 23.6C83.6006 23.8266 83.7739 24 84.0006 24H84.8006C85.0272 24 85.2006 23.8266 85.2006 23.6C85.2006 23.3733 85.0272 23.2 84.8006 23.2Z"
          fill="#2C3E50"
        />
        <path
          d="M88.8006 23.2H88.0006C87.7739 23.2 87.6006 23.3733 87.6006 23.6C87.6006 23.8266 87.7739 24 88.0006 24H88.8006C89.0273 24 89.2006 23.8266 89.2006 23.6C89.2006 23.3733 89.0273 23.2 88.8006 23.2Z"
          fill="#2C3E50"
        />
        <path
          d="M86.401 20.7999C86.1743 20.7999 86.001 20.9733 86.001 21.1999V21.9999C86.001 22.2266 86.1743 22.3999 86.401 22.3999C86.6276 22.3999 86.801 22.2266 86.801 21.9999V21.1999C86.801 20.9733 86.6276 20.7999 86.401 20.7999Z"
          fill="#2C3E50"
        />
        <path
          d="M86.401 24.7999C86.1743 24.7999 86.001 24.9733 86.001 25.1999V25.9999C86.001 26.2266 86.1743 26.3999 86.401 26.3999C86.6276 26.3999 86.801 26.2266 86.801 25.9999V25.1999C86.801 24.9733 86.6276 24.7999 86.401 24.7999Z"
          fill="#2C3E50"
        />
        <path
          d="M24.401 10H20.401C20.1743 10 20.001 10.1733 20.001 10.4C20.001 10.6267 20.1743 10.8 20.401 10.8H24.401C24.6276 10.8 24.801 10.6267 24.801 10.4C24.801 10.1733 24.6276 10 24.401 10Z"
          fill="#2C3E50"
        />
        <path
          d="M38.4002 12.8H25.6002C25.3735 12.8 25.2002 12.9734 25.2002 13.2C25.2002 13.4267 25.3735 13.6001 25.6002 13.6001H38.4002C38.6269 13.6001 38.8002 13.4267 38.8002 13.2C38.8002 12.9734 38.6269 12.8 38.4002 12.8Z"
          fill="#2C3E50"
        />
        <path
          d="M36.8002 15.6H33.6002C33.3735 15.6 33.2002 15.7733 33.2002 16C33.2002 16.2266 33.3735 16.4 33.6002 16.4H36.8002C37.0268 16.4 37.2002 16.2266 37.2002 16C37.2002 15.7733 37.0268 15.6 36.8002 15.6Z"
          fill="#2C3E50"
        />
        <path
          d="M30.4008 15.6H23.2008C22.9741 15.6 22.8008 15.7733 22.8008 16C22.8008 16.2266 22.9741 16.4 23.2008 16.4H30.4008C30.6275 16.4 30.8008 16.2266 30.8008 16C30.8008 15.7733 30.6275 15.6 30.4008 15.6Z"
          fill="#2C3E50"
        />
        <path
          d="M87.6004 13.2H66.8004C66.5737 13.2 66.4004 13.3733 66.4004 13.6C66.4004 13.8266 66.5737 14 66.8004 14H87.6004C87.827 14 88.0004 13.8266 88.0004 13.6C88.0004 13.3733 87.827 13.2 87.6004 13.2Z"
          fill="#2C3E50"
        />
        <path
          d="M78.0003 10.4H70.8004C70.5737 10.4 70.4004 10.5734 70.4004 10.8C70.4004 11.0267 70.5737 11.2 70.8004 11.2H78.0003C78.227 11.2 78.4003 11.0267 78.4003 10.8C78.4003 10.5734 78.227 10.4 78.0003 10.4Z"
          fill="#2C3E50"
        />
        <path
          d="M84.4006 10.4H82.0006C81.7739 10.4 81.6006 10.5734 81.6006 10.8C81.6006 11.0267 81.7739 11.2 82.0006 11.2H84.4006C84.6273 11.2 84.8006 11.0267 84.8006 10.8C84.8006 10.5734 84.6273 10.4 84.4006 10.4Z"
          fill="#2C3E50"
        />
        <g clipPath="url(#clip1_1791_1613)">
          <path
            d="M44.1109 46.3658C44.423 46.6779 44.9463 47.1828 45.2768 47.4765L45.8918 48.009L44.9371 48.9545L43.9824 49.9091L42.5412 48.4588L41.816 47.7382L41.0908 47.0175L42.0455 46.0629L42.991 45.1082L43.2756 45.4478C43.3658 45.554 43.5297 45.7369 43.7105 45.9343C43.8423 46.0784 43.9832 46.2303 44.1109 46.3658Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.2768 47.4765C44.9463 47.1828 44.423 46.6779 44.1109 46.3658C43.9832 46.2303 43.8423 46.0784 43.7105 45.9343L41.816 47.7382L42.5412 48.4588L43.9824 49.9091L44.9371 48.9545L45.8918 48.009L45.2768 47.4765Z"
            fill="#3D5670"
          />
          <path
            d="M41.0908 47.0175L42.0455 46.0629L42.991 45.1082L43.2756 45.4478C43.3658 45.554 43.5297 45.7369 43.7105 45.9343L41.816 47.7382L41.0908 47.0175Z"
            fill="#2C3E50"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.6713 59.4652C30.4791 60.2914 30.5801 60.4291 30.5801 60.7228L32.2416 62.0631C32.8475 62.5312 33.5176 62.6689 34.1693 62.4486C34.3988 62.3752 35.7299 61.0992 38.9979 57.8312C43.3307 53.48 43.5051 53.2873 43.5969 52.865C43.7896 51.9103 43.6336 51.6533 41.4397 49.4777C40.8763 48.9191 40.4413 48.4942 40.0902 48.1757L29.2596 59.0272C29.3895 59.1691 29.5296 59.3183 29.6713 59.4652Z"
            fill="#B58C83"
          />
          <path
            d="M28.6156 58.2443C28.4504 57.9138 28.2776 57.2749 28.5605 56.7756C29 56 30.6719 54.499 33.132 52.0388C37.3455 47.8162 37.6301 47.5591 38.0799 47.4215C38.7621 47.2236 39.0742 47.2539 40.0902 48.1757L29.2596 59.0272C28.935 58.6723 28.6746 58.3623 28.6156 58.2443Z"
            fill="#9C756C"
          />
          <path
            d="M41.5001 45L42.5001 44L42.991 45.1082L42.0455 46.0629L41.0908 47.0175L41.816 47.7382L42.5412 48.4588L43.9824 49.9091L44.9371 48.9545L45.8918 48.009L47.1311 48.8718L45.8918 49.9091C45.0014 50.7996 44.6434 51.0755 44.6893 51.2132C44.8545 51.6171 45.0197 52.3326 44.9371 52.865C44.6893 53.7263 44.8103 53.6898 40.0001 58.5C36.1647 62.3354 35.296 63.5681 34.0001 64C33.4129 64.1957 32.0001 63.5 31.5001 63C31.2507 62.7505 30.9656 62.4302 31.3512 62.109C31.6541 61.8427 31.9479 61.8336 32.2416 62.0631C32.8475 62.5312 33.5176 62.6689 34.1693 62.4486C34.3988 62.3752 35.7299 61.0992 38.9979 57.8312C43.3307 53.48 43.5051 53.2873 43.5969 52.865C43.7896 51.9103 43.6336 51.6533 41.4397 49.4777C40.8763 48.9191 40.4413 48.4942 40.0902 48.1757C39.0742 47.2539 38.7621 47.2236 38.0799 47.4215C37.6301 47.5591 37.3455 47.8162 33.132 52.0388C30.6719 54.499 29 56 28.5605 56.7756C28.2776 57.2749 28.4504 57.9138 28.6156 58.2443C28.6746 58.3623 28.935 58.6723 29.2596 59.0272C29.3895 59.1691 29.5296 59.3183 29.6713 59.4652C30.4791 60.2914 30.5801 60.4291 30.5801 60.7228C30.5801 61.1175 30.4314 61.5 30 61.5C29.7613 61.5 29.4693 61.1764 28.6156 60.2951C27.5 59 27.0459 58.5 27.0459 57.4824C27.0459 56.4648 27.5 56 32.5 51C37 46.5 36.9169 46.1652 38.0001 46C38.4958 45.9174 39.3122 46.2189 39.8171 46.3658C39.9639 46.4117 40.6281 45.8721 41.5001 45Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.5 51C65.6127 51 73 43.6127 73 34.5C73 25.3873 65.6127 18 56.5 18C55.1036 18 53.7478 18.1735 52.4528 18.5C45.2968 20.3045 40 26.7837 40 34.5C40 40.4044 43.1013 45.5844 47.7637 48.5C50.2972 50.0843 53.2917 51 56.5 51ZM56.5 52C66.165 52 74 44.165 74 34.5C74 24.835 66.165 17 56.5 17C46.835 17 39 24.835 39 34.5C39 44.165 46.835 52 56.5 52Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.5 51C65.6127 51 73 43.6127 73 34.5C73 25.3873 65.6127 18 56.5 18C55.1036 18 53.7478 18.1735 52.4528 18.5C52.4528 18.5 46.1978 22.3582 44 26.5C42.3123 29.6804 41.86 31.9023 42 35.5C42.2192 41.1311 47.7637 48.5 47.7637 48.5C50.2972 50.0843 53.2917 51 56.5 51Z"
            fill="#FFE370"
          />
          <path
            d="M40 34.5C40 40.4044 43.1013 45.5844 47.7637 48.5C47.7637 48.5 42.2192 41.1311 42 35.5C41.86 31.9023 42.3123 29.6804 44 26.5C46.1978 22.3582 52.4528 18.5 52.4528 18.5C45.2968 20.3045 40 26.7837 40 34.5Z"
            fill="#F3CB4C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.5 46C62.8513 46 68 40.8513 68 34.5C68 29.0073 64.1492 24.414 59 23.2725C58.1951 23.0941 57.3586 23 56.5 23C50.1487 23 45 28.1487 45 34.5C45 39.2543 47.8851 43.3348 52 45.0862C53.3822 45.6745 54.9031 46 56.5 46ZM56.5 47C63.4036 47 69 41.4036 69 34.5C69 27.5964 63.4036 22 56.5 22C49.5964 22 44 27.5964 44 34.5C44 41.4036 49.5964 47 56.5 47Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.5 46C62.8513 46 68 40.8513 68 34.5C68 29.0073 64.1492 24.414 59 23.2725C59 23.2725 51.9143 28.1575 50.5 33C49.1666 37.5654 52 45.0862 52 45.0862C53.3822 45.6745 54.9031 46 56.5 46Z"
            fill="#BEE5F7"
          />
          <path
            d="M56.5 23C50.1487 23 45 28.1487 45 34.5C45 39.2543 47.8851 43.3348 52 45.0862C52 45.0862 49.1666 37.5654 50.5 33C51.9143 28.1575 59 23.2725 59 23.2725C58.1951 23.0941 57.3586 23 56.5 23Z"
            fill="#9CDAF8"
          />
          <path
            d="M53.7802 30.4244C53.1006 29.8171 52.0594 29.8675 51.4417 30.5376C50.8172 31.215 50.8601 32.2704 51.5374 32.895L53.3222 34.5407C53.718 34.9058 53.7131 35.5325 53.3116 35.8913L51.7782 37.2617C51.0103 37.9479 50.9988 39.1459 51.7535 39.8466C52.4227 40.468 53.4572 40.4698 54.1285 39.8508L55.867 38.2477L55.9267 38.1926C56.3097 37.8395 56.8995 37.8395 57.2825 38.1926L57.3422 38.2477L59.0807 39.8508C59.752 40.4698 60.7865 40.468 61.4557 39.8466C61.9818 39.3582 62.1356 38.6281 61.9204 37.9981C61.8329 37.7422 61.8727 37.4385 62.0882 37.2751C62.314 37.1041 62.6412 37.1516 62.7602 37.4086C63.2439 38.4527 63.0381 39.7419 62.1361 40.5794C61.0843 41.5561 59.458 41.559 58.4028 40.5859L57.2825 39.5529C56.8995 39.1998 56.3097 39.1998 55.9267 39.5529L54.8064 40.5859C53.7512 41.559 52.1249 41.5561 51.0731 40.5794C49.8868 39.4779 49.9047 37.5948 51.1118 36.516L51.7517 35.9442C52.1919 35.5508 52.1972 34.8637 51.7632 34.4634L50.8595 33.6301C49.7762 32.6312 49.7077 30.9433 50.7064 29.8598C51.6944 28.7881 53.3596 28.7074 54.4465 29.6787L55.9383 31.0118C56.3178 31.351 56.8914 31.351 57.2709 31.0118L58.7627 29.6787C59.8496 28.7074 61.5148 28.7881 62.5028 29.8598C63.5015 30.9433 63.433 32.6312 62.3497 33.6301L61.026 34.8507C60.8171 35.0434 60.8196 35.3742 61.0316 35.5635C61.2535 35.7619 61.244 36.1121 61.0117 36.2982L60.9946 36.3119C60.8039 36.4646 60.5309 36.4573 60.3488 36.2945L60.2809 36.2339L59.8976 35.8913C59.4961 35.5325 59.4912 34.9058 59.887 34.5407L61.6718 32.895C62.3491 32.2704 62.392 31.215 61.7675 30.5376C61.1498 29.8675 60.1086 29.8171 59.429 30.4244L57.355 32.2779L57.2709 32.353C56.8914 32.6921 56.3178 32.6921 55.9383 32.353L55.8542 32.2779L53.7802 30.4244Z"
            fill="black"
          />
          <path
            d="M51.4417 30.5376C52.0594 29.8675 53.1006 29.8171 53.7802 30.4244L55.8542 32.2779L55.9383 32.353C56.3178 32.6921 56.8914 32.6921 57.2709 32.353L57.355 32.2779L59.429 30.4244C60.1086 29.8171 61.1498 29.8675 61.7675 30.5376C62.392 31.215 62.3491 32.2704 61.6718 32.895L59.887 34.5407C59.4912 34.9058 59.4961 35.5325 59.8976 35.8913L60.2809 36.2339L60.3488 36.2945L61.9204 37.9981C62.1356 38.6281 61.9818 39.3582 61.4557 39.8466C60.7865 40.468 59.752 40.4698 59.0807 39.8508L57.3422 38.2477L57.2825 38.1926C56.8995 37.8395 56.3097 37.8395 55.9267 38.1926L55.867 38.2477L54.1285 39.8508C53.4572 40.4698 52.4227 40.468 51.7535 39.8466C50.9988 39.1459 51.0103 37.9479 51.7782 37.2617L53.3116 35.8913C53.7131 35.5325 53.718 34.9058 53.3222 34.5407L51.5374 32.895C50.8601 32.2704 50.8172 31.215 51.4417 30.5376Z"
            fill="#FF7C7C"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1791_1613">
          <rect width="100" height="80" fill="white" />
        </clipPath>
        <clipPath id="clip1_1791_1613">
          <rect
            width="47"
            height="47"
            fill="white"
            transform="translate(27 17)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default NotFoundIcon;
