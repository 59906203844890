import { useEffect, useState } from 'react';
import {
  useUserNotificationsQuery,
  useDailyTipperDataQuery,
  useDtWeeklyProgressQuery,
  DailyTipperStatusType,
  NotificationType,
} from '@/graphql';
import { NotifTypes } from '@/type';
import { useAuth } from '@/contexts/auth-context';

interface ParsedNotification extends NotificationType {
  parsedData: {
    type: NotifTypes;
  };
}

const useNotifications = () => {
  const { token } = useAuth();

  const { data: notificationsData } = useUserNotificationsQuery({
    skip: !token,
  });
  const { data: dtData, loading: loadingDtData } = useDailyTipperDataQuery({
    skip: !token,
  });
  const { data: dtWeekData, loading: loadingDtWeek } = useDtWeeklyProgressQuery(
    {
      skip: !token,
    }
  );

  const [notifications, setNotifications] = useState<ParsedNotification[]>([]);

  useEffect(() => {
    if (!notificationsData) return;

    const unreadNotifications = notificationsData.notifications?.filter(
      (notification) => !notification?.isRead
    );

    const parsedNotifications = unreadNotifications
      ?.map((notification) => {
        try {
          const parsedData = JSON.parse(notification?.data);
          return { ...notification, parsedData };
        } catch (error) {
          console.error('Error parsing notification data:', error);
          return null;
        }
      })
      .filter(
        (notification): notification is ParsedNotification =>
          notification !== null
      );

    if (parsedNotifications) setNotifications(parsedNotifications);
  }, [notificationsData]);

  const dtWeekStatus = dtWeekData?.me?.dailytipper?.dailyStatus;

  const isDtOpen = () => {
    const validStatuses = [
      DailyTipperStatusType.Pending,
      DailyTipperStatusType.InProgress,
    ];
    return (
      dtData?.dailyTipperData &&
      dtWeekStatus &&
      validStatuses.includes(dtWeekStatus)
    );
  };

  const dialogsCanBeVisible = !loadingDtData && !loadingDtWeek && !isDtOpen();

  return {
    notifications,
    dialogsCanBeVisible,
  };
};

export default useNotifications;
