import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  cardPicture: {
    display: 'flex!important',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 100,
    height: 140,
    marginRight: theme.spacing(4),
    backgroundColor: '#2C3E50',
    borderRadius: 6,
    overflow: 'hidden',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  duration: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    color: '#647485',
  },
  chipGolden: {
    borderColor: '#DD6903',
    background: '#fff',
    color: '#DD6903',
  },
  date: {
    fontSize: 12,
    letterSpacing: '0.4px',
    textTransform: 'none',
  },
  status: {
    background: '#ECF0F1',
    borderRadius: 4,
  },
}));
