import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(226, 245, 253, 0.48)',
  },
  cardField: {
    height: '56px',
    width: '100%',
    background: 'none',
    fontSize: '16px',
    fontWeight: 400,
    color: '#2c3e50',
    border: '1px solid #c6d5e3',
    boxShadow: 'none',
    borderRadius: '4px',
    margin: '12px 0',
    '& > *': {
      position: 'absolute',
      left: 16,
      top: 20,
    },
  },
  cardFieldFocused: {
    borderColor: '#2c75b3',
  },
}));
