import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  cardPicture: {
    height: 140,
    width: 100,
    borderRadius: 6,
    border: '1px solid rgba(0, 0, 0, 0.07)',
    backgroundColor: '#E6EEFA',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },
  cardIcon: {
    position: 'absolute',
    right: 8,
    bottom: 8,
    '& img': {
      height: 48,
      width: 48,
      filter: 'brightness(0) invert(1)',
    },
  },
  typeLabel: {
    padding: 4,
    background: 'rgba(0, 0, 0, 0.36)',
    color: 'white',
    textAlign: 'center',
    position: 'absolute',
    left: 1,
    right: 1,
    bottom: -1,
    borderRadius: '0px 0px 6px 6px',
  },
  duration: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#647485',
  },
  caption: {
    color: '#929DA8',
  },
  goal: {
    color: '#647485',
  },
  barColorSecondary: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  barColorPrimary: {
    backgroundColor: theme.palette.primary.light,
  },
  progressWrap: {
    position: 'relative',
  },
  progressGoal: {
    position: 'absolute',
    top: -2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.primary.light,
    fontSize: 10,
  },
  goalMark: {
    width: 1,
    height: 8,
    borderRadius: 2,
    backgroundColor: 'currentColor',
  },
  status: {
    background: '#ECF0F1',
    borderRadius: 4,
  },
  chipGolden: {
    borderColor: '#DD6903',
    color: '#DD6903',
  },
}));
