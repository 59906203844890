import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { sortBy, shuffle } from 'lodash';

import { Box, Button, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import ChooseList from '@/components/choose-list';
import Dot from '@/components/dot';
import AppButton from '@/components/app-button';
import QuestionImage from '@/components/question-image';
import { TAKEN_ACHIEVEMENT } from '@/apollo/queries';

import {
  ClassroomMembersDocument,
  DtWeeklyProgressDocument,
  useSetUserDailyTipperAnswerMutation,
  useTodayDailyTipperQuery,
} from '@/graphql';
import { useApolloClient } from '@apollo/client';

import EmptyLayout from '@/screens/main/components/empty-layout';
import Fallback from '@/components/fallback';
import CheckIcon from '@/components/icons/check';
import RuleWrapper from './../rule-wrapper';
import CorrectAnswer from './correct-answer';
import InCorrectAnswer from './incorrect-answer';
import { datadogRum } from '@datadog/browser-rum';
import FeedbackButton from '../../components/feedback-button';
import FeedbackForQuestion from '../../components/feedback-for-question';

interface QuestionParams {
  questionId: string;
}

const Question: React.FC = ({ ...rest }) => {
  const { cache } = useApolloClient();
  const history = useHistory();
  const { t } = useTranslation();

  const { questionId } = useParams<QuestionParams>();
  const search = history.location.search;
  const query = new URLSearchParams(search);
  const isFeedbackVisible = query.get('isFeedbackVisible');

  const [isVisibleFeedbackForQuestion, setIsVisibleFeedbackForQuestion] =
    useState<boolean>(!!isFeedbackVisible);
  const [answerId, setAnswerId] = useState<string | null>(null);

  const { data, error, loading } = useTodayDailyTipperQuery();
  const [setUserDailyTipperAnswer, { loading: setAnswerLoading }] =
    useSetUserDailyTipperAnswerMutation({
      refetchQueries: [{ query: ClassroomMembersDocument }],
    });

  const userQuestions = useMemo(
    () =>
      sortBy(data?.dailyTipperData?.dailyTipperQuestions || [], [
        'questionNumber',
      ]),
    [data]
  );

  const indx = useMemo(
    () => userQuestions.findIndex((it) => it?.id === questionId),
    [questionId, userQuestions]
  );

  const currentUserQ = useMemo(
    () => (indx < userQuestions.length ? userQuestions[indx] : null),
    [indx, userQuestions]
  );

  const hasAnswer = useMemo(() => !!currentUserQ?.userAnswer, [currentUserQ]);

  const isCorrect = useMemo(
    () =>
      hasAnswer &&
      !!currentUserQ &&
      currentUserQ?.userAnswer?.value === currentUserQ?.correctAnswer?.value,
    [currentUserQ, hasAnswer]
  );

  const isInCorrect = useMemo(
    () =>
      hasAnswer &&
      !!currentUserQ &&
      currentUserQ?.userAnswer?.value !== currentUserQ?.correctAnswer?.value,
    [currentUserQ, hasAnswer]
  );

  const handleSetAnswer = useCallback(async () => {
    try {
      if (currentUserQ?.question?.id) {
        const res = await setUserDailyTipperAnswer({
          variables: {
            questionId: currentUserQ.question.id,
            answerId,
          },
          refetchQueries: [{ query: DtWeeklyProgressDocument }],
        });
        const dailyStatus =
          res?.data?.setDailyTipperAnswer?.dailyTipperQuestionAnswerStatus
            ?.dailyStatus;
        if (dailyStatus) {
          cache.writeQuery({
            query: TAKEN_ACHIEVEMENT,
            data: {
              dailyStatus,
            },
          });
        }
        datadogRum.addAction('DT set answer', {
          questionId,
          answerId,
        });
      }
    } catch (error) {
      console.error('SET_ANSWER', error);
    } finally {
      setAnswerId(null);
    }
  }, [answerId, questionId, currentUserQ, setUserDailyTipperAnswer, cache]);

  const handleChooseAnswer = useCallback(
    (id: string) => {
      setAnswerId(id);
      datadogRum.addAction('DT choose answer', {
        questionId,
        answerId: id,
      });
    },
    [setAnswerId, questionId]
  );

  const dots = useMemo(
    () =>
      userQuestions ? (
        userQuestions.map((it) => {
          if (!it) return false;
          if (!it.userAnswer || !it.correctAnswer)
            return (
              <Dot
                key={`dot-${it.id}`}
                size="large"
                variant={
                  it?.questionNumber === currentUserQ?.questionNumber ||
                  (!!it?.questionNumber &&
                    !!currentUserQ?.questionNumber &&
                    !!currentUserQ?.userAnswer &&
                    it?.questionNumber - currentUserQ?.questionNumber === 1)
                    ? 'highlighted'
                    : 'default'
                }
              />
            );
          return it.userAnswer.value === it.correctAnswer.value ? (
            <Dot key={`dot-${it.id}`} variant="success" size="large">
              <Box
                component="span"
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ width: '100%', height: '100%' }}
              >
                <CheckIcon style={{ width: 12, height: 12, color: '#fff' }} />
              </Box>
            </Dot>
          ) : (
            <Dot key={`dot-${it.id}`} variant="error" size="large" />
          );
        })
      ) : (
        <>
          <Dot size="large" /> <Dot size="large" /> <Dot size="large" />
        </>
      ),
    [userQuestions, currentUserQ]
  );

  const questionNumberNode =
    indx >= 0 ? (
      t('common.question', { question: indx + 1 })
    ) : (
      <Skeleton width={100} />
    );

  const questionNode = currentUserQ?.question?.value ?? (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );

  const options = useMemo(() => {
    if (!currentUserQ?.question?.answers)
      return [...Array(4)].map((_, i) => ({
        value: null,
        label: <Skeleton />,
      }));

    const answersMixed = shuffle(currentUserQ?.question?.answers || []);
    return answersMixed.map((it) => ({
      value: String(it.id),
      label: it.value,
    }));
  }, [currentUserQ]);

  const nextAnswerNode = useMemo(() => {
    if (indx < 0) return <>&nbsp;</>;

    if (indx >= 0 && indx < userQuestions.length - 1) return t('common.next');

    return t('common.finish');
  }, [indx, t, userQuestions.length]);

  const setAnswerNode = useMemo(() => {
    return t('common.submit-answer');
  }, [t]);

  const nextLink = useMemo(() => {
    if (indx < 0) return '';

    if (indx >= userQuestions.length - 1) return `/daily-tipper/result`;

    const next = userQuestions[indx + 1];

    return `/daily-tipper-question/${next?.id}/completed`;
  }, [indx, userQuestions]);

  if (!!error) {
    console.error('QUESTION_QUERY', error);
    return (
      <EmptyLayout title={t('daily-tipper.title')}>
        <Fallback />
      </EmptyLayout>
    );
  }

  return (
    <RuleWrapper>
      <Box
        clone
        data-cy-dt-question
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        px={4}
        minHeight="-webkit-fill-available"
      >
        <Paper elevation={0}>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              py={5}
              px={4}
              mx={-4}
              borderBottom="1px solid #E6EEFA"
            >
              <Box
                display="grid"
                gridTemplateColumns="auto auto auto"
                gridColumnGap={10}
              >
                {dots}
              </Box>
              <Typography variant="overline">{questionNumberNode}</Typography>
            </Box>
            <Box paddingBottom={3} mt={5}>
              <Typography variant="subtitle1">{questionNode}</Typography>
            </Box>
            <QuestionImage
              src={currentUserQ?.question?.questionImage ?? undefined}
              alt={currentUserQ?.question?.value}
            />
            {!hasAnswer && (
              <ChooseList
                data-cy-answers
                options={options}
                value={answerId}
                onChange={handleChooseAnswer}
              />
            )}
            {currentUserQ && isCorrect && (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              <CorrectAnswer userQuestion={currentUserQ as any} />
            )}
            {currentUserQ && isInCorrect && (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              <InCorrectAnswer userQuestion={currentUserQ as any} />
            )}
          </Box>
          <Box py={7}>
            {!loading && !hasAnswer && (
              <AppButton
                fullWidth
                data-cy-submit-answer-btn
                color="primary"
                variant="contained"
                disabled={!answerId}
                loading={setAnswerLoading}
                onClick={handleSetAnswer}
                data-dd-action-name="DT set answer"
              >
                {setAnswerNode}
              </AppButton>
            )}
            {!loading && hasAnswer && (
              <Button
                fullWidth
                data-cy-submit-answer-next
                color="primary"
                variant="contained"
                onClick={() => history.replace(nextLink)}
                data-dd-action-name="DT navigate next question"
              >
                {nextAnswerNode}
              </Button>
            )}
            {loading && (
              <Button fullWidth disabled color="primary" variant="contained">
                &nbsp;
              </Button>
            )}
            {!loading && hasAnswer && (
              <FeedbackButton
                questionId={currentUserQ?.question?.id}
                handleClick={setIsVisibleFeedbackForQuestion}
              />
            )}
          </Box>
        </Paper>
      </Box>
      {hasAnswer &&
        currentUserQ?.question?.id &&
        isVisibleFeedbackForQuestion && (
          <FeedbackForQuestion
            questionId={currentUserQ.question.id}
            nextLink={nextLink}
            setFeedbackVisible={setIsVisibleFeedbackForQuestion}
          />
        )}
    </RuleWrapper>
  );
};

export default Question;
