import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { Box, Paper } from '@material-ui/core';

import * as Form from '@/components/form';
import AppButton from '@/components/app-button';
import Loading from '@/components/loading';
import { useUser } from '@/contexts/user-context';
import {
  useMedicalAssistantTraineeDataQuery,
  useUpdateUserProfileMutation,
} from '@/graphql';

import EditBtn from '../../components/edit-btn';
import { OTHER_WORKPLACE } from '@/utils/constants';

interface Form {
  studyYear: string;
  workplace: string;
  anotherWorkplace: string;
}

interface DoctorProps {
  professionType: number;
}

const DoctorInfo: React.FC<DoctorProps> = ({ professionType }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const toggleMode = useCallback(
    (value: boolean) => () => {
      setIsEditMode(value);
    },
    []
  );

  const { data, error } = useMedicalAssistantTraineeDataQuery({
    variables: { professionType },
  });
  const [updateUserProfile, { loading: saveLoading }] =
    useUpdateUserProfileMutation();

  if (!!error) console.error('useMedicalAssistantTraineeDataQuery', error);

  const { errors, watch, control } = useForm<Form>({ mode: 'onChange' });

  const save = useCallback(async () => {
    await updateUserProfile({
      variables: {
        studyYearId: watch('studyYear'),
        workplaceId: watch('workplace'),
        anotherWorkplace: watch('anotherWorkplace'),
      },
    });

    setIsEditMode(false);
  }, [updateUserProfile, watch]);

  const workplaces = React.useMemo(
    () =>
      (data?.workplaces || [])
        .map((it) => ({
          value: String(it?.id),
          label: String(it?.title),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [data]
  );

  const studyYears = React.useMemo(
    () =>
      (data?.studyYears || []).map((it) => ({
        value: String(it?.id),
        label: String(it?.title),
      })),
    [data]
  );

  const isValid = useMemo(() => {
    const values = watch();

    return (
      isEmpty(errors) &&
      !!values.studyYear &&
      !!values.workplace &&
      (watch('workplace', user?.workplaceId) === OTHER_WORKPLACE
        ? !!values.anotherWorkplace
        : true)
    );
  }, [errors, watch, user]);

  const isDirty = useMemo(() => {
    const { studyYear, workplace, anotherWorkplace } = watch();

    return (
      studyYear !== user?.studyYearId ||
      workplace !== user?.workplaceId ||
      anotherWorkplace !== user?.anotherWorkplace
    );
  }, [user, watch]);

  if (!user) return <Loading />;

  return (
    <Box clone height="100%" boxSizing="border-box" paddingY={7} paddingX={4}>
      <Paper elevation={0}>
        {isEditMode ? (
          <>
            <Form.Row>
              <Controller
                render={(props) => (
                  <Form.SelectField
                    fullWidth
                    required
                    autoFocus
                    data-cy-study-year
                    variant="outlined"
                    label={t('fields.study-year.label')}
                    options={studyYears}
                    errors={errors.studyYear}
                    {...props}
                  />
                )}
                control={control}
                name="studyYear"
                defaultValue={user?.studyYearId}
              />
            </Form.Row>
            <Form.Row>
              <Controller
                render={(props) => (
                  <Form.SelectField
                    fullWidth
                    required
                    data-cy-workplace
                    variant="outlined"
                    label={t('fields.workplace.label')}
                    options={workplaces}
                    defaultValue={user?.workplaceId}
                    errors={errors.workplace}
                    {...props}
                  />
                )}
                control={control}
                name="workplace"
                defaultValue={user?.workplaceId}
              />
            </Form.Row>
            {watch('workplace', user?.workplaceId) === OTHER_WORKPLACE && (
              <Form.Row>
                <Controller
                  render={(props) => (
                    <Form.TextField
                      fullWidth
                      required
                      data-cy-another-workplace
                      variant="outlined"
                      label={t('fields.another-workplace.label')}
                      placeholder={t('fields.another-workplace.placeholder')}
                      errors={errors.anotherWorkplace}
                      {...props}
                      value={props.value?.replace(/[^0-9a-zA-Z]/gi, '')}
                    />
                  )}
                  control={control}
                  name="anotherWorkplace"
                  defaultValue={user?.anotherWorkplace}
                />
              </Form.Row>
            )}
            <AppButton
              fullWidth
              data-cy-save-changes
              color="primary"
              variant="contained"
              disabled={!isValid || !isDirty}
              loading={saveLoading}
              onClick={save}
            >
              {t('common.save-changes')}
            </AppButton>
          </>
        ) : (
          <>
            <Form.InfoField label={t('fields.profession.label')}>
              {user?.profession?.title}
            </Form.InfoField>
            <Form.InfoField label={t('fields.study-year.label')}>
              {user?.studyYear?.title}
            </Form.InfoField>
            <Form.InfoField label={t('fields.workplace.label')}>
              {user?.workplaceId === OTHER_WORKPLACE
                ? user?.anotherWorkplace
                : user?.workplace?.title || t('common.another')}
            </Form.InfoField>
            <EditBtn onClick={toggleMode(true)} />
          </>
        )}
      </Paper>
    </Box>
  );
};

export default DoctorInfo;
