import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Paper, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { SubscriptionPeriodEnum } from '@/type';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  root: {
    backgroundColor: 'rgba(226, 245, 253, 0.48)',
  },
  caption: {
    fontWeight: 'bold',
  },
}));

interface SubsAgreementTextProps {
  subscriptionPeriod?: string | null;
  discountPrice?: string;
  price?: string;
}

const SubsAgreementText: React.FC<SubsAgreementTextProps> = ({
  subscriptionPeriod,
  discountPrice,
  price,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  if (!subscriptionPeriod) return <></>;
  return (
    <Box marginTop={7}>
      <Paper variant="outlined">
        <Box paddingX={5} paddingY={4} className={classes.root}>
          <Typography variant="caption" className={classes.caption}>
            {t('subscription.policies.caption')}
          </Typography>
          <br />
          <Typography variant="caption">
            {subscriptionPeriod === 'CODE' ? (
              <Trans
                i18nKey="subscription.policies.text-code"
                components={{
                  link2: (
                    <Link
                      to="/privacy-dataprotection"
                      component={RouterLink}
                      color="primary"
                    />
                  ),
                }}
              />
            ) : (
              <>
                <Trans
                  i18nKey={`subscription.policies.${`text-${
                    subscriptionPeriod === 'P1Y'
                      ? 'annual'
                      : subscriptionPeriod === SubscriptionPeriodEnum.P1M
                      ? 'monthly'
                      : ''
                  }${discountPrice ? '-discounted' : ''}`}`}
                  values={{ price: price, discountPrice: discountPrice }}
                />
                <Trans
                  i18nKey="subscription.policies.text2"
                  components={{
                    link1: (
                      <Link
                        to="/privacy-agb"
                        component={RouterLink}
                        color="primary"
                      />
                    ),
                    link2: (
                      <Link
                        to="/privacy-dataprotection"
                        component={RouterLink}
                        color="primary"
                      />
                    ),
                  }}
                />
              </>
            )}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default SubsAgreementText;
