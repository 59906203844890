import React from 'react';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, StripeElementLocale } from '@stripe/stripe-js';

const stripePromiseCache: { [locale: string]: Promise<Stripe | null> } = {};

const getStripePromise = (
  locale: StripeElementLocale
): Promise<Stripe | null> => {
  if (!window?.ReactNativeWebView) {
    if (!stripePromiseCache[locale]) {
      console.log(`Loading Stripe for locale: ${locale}`);
      stripePromiseCache[locale] = import('@stripe/stripe-js').then(
        (stripe) => {
          return stripe.loadStripe(
            process.env.REACT_APP_STRIPE_PUBLIC_API_KEY || '',
            {
              locale,
            }
          );
        }
      );
    } else {
      console.log(`Reusing cached Stripe instance for locale: ${locale}`);
    }
    // After the if-block, stripePromiseCache[locale] is guaranteed to be defined
    return stripePromiseCache[locale];
  } else {
    console.log('ReactNativeWebView detected, not loading Stripe.');
    // Return a resolved Promise with null to match the expected return type
    return Promise.resolve(null);
  }
};

const StripeWrapper: React.FC = ({ children }) => {
  const { i18n } = useTranslation();

  const mapLanguageToLocale = (language: string): StripeElementLocale => {
    const supportedLocales: StripeElementLocale[] = ['auto', 'de', 'en'];

    // Check if the exact language matches a supported locale
    if (supportedLocales.includes(language as StripeElementLocale)) {
      return language as StripeElementLocale;
    }

    if (language.startsWith('en')) return 'en';
    if (language.startsWith('de')) return 'de';

    return 'de';
  };

  const locale = mapLanguageToLocale(i18n.language);
  const stripePromise = getStripePromise(locale);

  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeWrapper;
