import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useApolloClient } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';
import luhn from 'luhn';
import { sortBy } from 'lodash';

import { Box } from '@material-ui/core';

import * as Form from '@/components/form';
import { useSignUp } from '@/contexts/sign-up-context';
import {
  EfnNumberVerifyQuery,
  EfnNumberVerifyQueryVariables,
  useDoctorDataQuery,
} from '@/graphql';
import { EFN_NUMBER_VERIFY } from '@/apollo/queries';

import PublicLayout from '../../../components/layout';
import StepNavigate from '../../../components/step-navigation';
import Agreement from '../../../components/agreement';
import PrivacyPolicy from '../../privacy-policy';
import { OTHER_WORKPLACE } from '@/utils/constants';

interface DoctorInfoProps {
  onBack: () => void;
  onNext: () => void;
}

interface Form {
  speciality: string;
  efnNumber: string;
  examYear: number;
  workplace: string;
  anotherWorkplace: string;
  dataProtection: boolean;
  agb: boolean;
  subscribeToNews: boolean;
}

const DoctorInfo: React.FC<DoctorInfoProps> = ({ onBack, onNext }) => {
  const client = useApolloClient();
  const { t } = useTranslation();
  const {
    professionType,
    specialityId,
    efnNumber,
    examYear,
    workplaceId,
    anotherWorkplace,
    subscribeToNews,
    update,
  } = useSignUp();

  const { register, errors, setValue, watch, setError } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      speciality: specialityId || '',
      efnNumber: efnNumber || '',
      examYear: examYear,
      workplace: !!anotherWorkplace ? OTHER_WORKPLACE : workplaceId || '',
      anotherWorkplace: anotherWorkplace || '',
      dataProtection: false,
      agb: false,
      subscribeToNews: !!subscribeToNews,
    },
  });

  if (professionType === undefined) {
    // TODO handle this case
    throw new Error('Not suported professionType');
  }

  const { data } = useDoctorDataQuery({ variables: { professionType } });

  const validation = useMemo(
    () => ({
      efnNumber: {
        validate: async (efnNumber: string) => {
          if (!efnNumber) {
            return undefined;
          }

          if (efnNumber.length !== 15) {
            return t('fields.efn-number.validation.regex') as string;
          }

          if (!luhn.validate(efnNumber)) {
            return t('fields.efn-number.validation.regex') as string;
          }

          const { data, errors } = await client.query<
            EfnNumberVerifyQuery,
            EfnNumberVerifyQueryVariables
          >({
            query: EFN_NUMBER_VERIFY,
            variables: { efnNumber },
            fetchPolicy: 'network-only',
          });

          if (errors) {
            return errors.join('; ');
          }

          if (data && data.efnNumberVerify) {
            return t('fields.efn-number.validation.inuse') as string;
          }

          return undefined;
        },
      },
    }),
    [client, t]
  );

  useEffect(() => {
    register({ name: 'speciality' }, {});
    register({ name: 'efnNumber' }, validation.efnNumber);
    register({ name: 'examYear' }, {});
    register({ name: 'workplace' }, {});
    register({ name: 'anotherWorkplace' }, {});

    register({ name: 'dataProtection' }, {});
    register({ name: 'agb' }, {});
    register({ name: 'subscribeToNews' }, {});
  }, [
    anotherWorkplace,
    efnNumber,
    examYear,
    register,
    specialityId,
    validation.efnNumber,
    workplaceId,
  ]);

  const changeExamYear = useCallback(
    (e: DateTime | null) => {
      setValue('examYear', e?.year, {
        shouldDirty: true,
        shouldValidate: true,
      });

      update({ examYear: e?.year });
    },
    [setValue, update]
  );

  const changeField = useCallback(
    (prop: keyof Form) =>
      (e: React.ChangeEvent<{ checked?: boolean; value: unknown }>) => {
        const options = {
          shouldDirty: true,
          shouldValidate: true,
        };
        const value = String(e.target.value);
        const checked = Boolean(e.target.checked);

        switch (prop) {
          case 'speciality': {
            setValue(prop, value, options);

            return update({ specialityId: value });
          }
          case 'efnNumber': {
            setValue(prop, value, options);

            return update({ efnNumber: value });
          }
          case 'workplace': {
            setValue(prop, value, options);

            return update({
              workplaceId: value,
            });
          }
          case 'anotherWorkplace': {
            setValue(prop, value, options);

            return update({ anotherWorkplace: value });
          }
          case 'subscribeToNews': {
            setValue(prop, checked, options);

            return update({ subscribeToNews: e.target.checked });
          }
          case 'agb': {
            setValue(prop, checked, options);
            return;
          }
          case 'dataProtection': {
            setValue(prop, checked, options);

            return;
          }
        }
      },
    [setValue, update]
  );

  const specialities = React.useMemo(() => {
    const specs = data
      ? (data.specialitiesByType || []).map((it) => ({
          value: String(it?.id),
          label: String(it?.title),
        }))
      : [];
    return sortBy(specs, ['label']);
  }, [data]);

  const workplaces = React.useMemo(
    () =>
      (data?.workplaces || [])
        .map((it) => ({
          value: String(it?.id),
          label: String(it?.title),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [data]
  );

  const isValid = useMemo(() => {
    const values = watch();

    if (values.efnNumber) {
      const validateEfnNumber = async () => {
        // validate efnNumber when component is mounted
        const efnNumberInvalidMessage = await validation.efnNumber.validate(
          values.efnNumber
        );
        if (efnNumberInvalidMessage && isEmpty(errors)) {
          setError('efnNumber', {
            type: 'validate',
            message: efnNumberInvalidMessage || undefined,
            shouldFocus: true,
          });
        }
      };
      validateEfnNumber();
    }

    return (
      isEmpty(errors) &&
      !!values.speciality &&
      !!values.efnNumber &&
      !!values.examYear &&
      !!values.agb &&
      (values.workplace === OTHER_WORKPLACE
        ? !!values.anotherWorkplace
        : !!values.workplace)
    );
  }, [errors, watch, setError, validation]);

  return (
    <PublicLayout title={t('sign-up.doctor.title')} page="3 / 3">
      <Box paddingY={7}>
        <Form.Row>
          <Form.SelectField
            fullWidth
            required
            autoFocus
            data-cy-speciality
            variant="outlined"
            label={t('fields.speciality.label')}
            options={specialities}
            errors={errors.speciality}
            value={watch('speciality')}
            onChange={changeField('speciality')}
          />
        </Form.Row>
        <Form.Row>
          <Form.TextField
            fullWidth
            required
            data-cy-efn-number
            variant="outlined"
            name="efn-number"
            label={t('fields.efn-number.label')}
            placeholder={t('fields.efn-number.placeholder')}
            errors={errors.efnNumber}
            value={watch('efnNumber')}
            onChange={changeField('efnNumber')}
          />
        </Form.Row>
        <Form.Row>
          <Form.DateField
            autoOk
            fullWidth
            disableFuture
            disableToolbar
            data-cy-exam-year
            format="yyyy"
            views={['year']}
            variant="inline"
            name="exam-year"
            inputVariant="outlined"
            placeholder={t('common.date-format')}
            label={t('fields.exam-year.label')}
            errors={errors.examYear}
            value={
              !!watch('examYear')
                ? DateTime.fromObject({ year: watch('examYear') })
                : null
            }
            onChange={changeExamYear}
          />
        </Form.Row>
        <Form.Row>
          <Form.SelectField
            fullWidth
            required
            data-cy-workplace
            variant="outlined"
            name="workplace"
            label={t('fields.workplace.label')}
            options={workplaces}
            errors={errors.workplace}
            value={watch('workplace')}
            onChange={changeField('workplace')}
          />
        </Form.Row>
        {watch('workplace') === OTHER_WORKPLACE && (
          <Form.Row>
            <Form.TextField
              fullWidth
              required
              data-cy-another-workplace
              variant="outlined"
              name="another-workplace"
              label={t('fields.another-workplace.label')}
              placeholder={t('fields.another-workplace.placeholder')}
              errors={errors.anotherWorkplace}
              value={watch('anotherWorkplace').replace(/[^0-9a-zA-Z]/gi, '')}
              onChange={changeField('anotherWorkplace')}
            />
          </Form.Row>
        )}
        <Agreement
          data-cy-agb
          name="agb"
          variant="agb"
          checked={watch('agb')}
          onChange={changeField('agb')}
        />
        <Agreement
          noDivider
          data-cy-subscription
          name="subscribeToNews"
          variant="subscription"
          checked={watch('subscribeToNews')}
          onChange={changeField('subscribeToNews')}
        />
        <PrivacyPolicy />
      </Box>
      <StepNavigate disabled={!isValid} onNext={onNext} onBack={onBack} />
    </PublicLayout>
  );
};

export default DoctorInfo;
