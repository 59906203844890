import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { PurchasesStoreProduct } from 'react-native-purchases';
import { useTranslation } from 'react-i18next';

interface SubscriptionOptionProps {
  product: PurchasesStoreProduct;
  isSelected: boolean;
  onSelect: () => void;
}
const useStyles = makeStyles((theme) => ({
  subscriptionOption: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 8,
    flexShrink: 0,
    padding: 16,
    border: '1.5px solid transparent',
    borderRadius: 4,
    marginBottom: 10,
    backgroundColor: '#3498DB0A',
  },
  selectedSubscriptionOption: {
    borderColor: theme.palette.primary.main,
  },
  subscriptionProfit: {
    position: 'absolute',
    right: 0,
    top: 0,
    backgroundColor: theme.palette.primary.main,
    padding: '4px 10px',
    borderBottomLeftRadius: 4,
  },
  discountChip: {
    display: 'inline-flex',
    width: 'fit-content',
    padding: '4px 10px',
    marginBottom: 8,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
  },
  subscriptionCheck: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 16,
    height: 16,
    borderRadius: '50%',
    border: `1.5px solid ${theme.palette.text.secondary}`,
  },
  subscriptionCheckSelected: {
    borderColor: theme.palette.primary.main,
  },
  subscriptionCheckDot: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
  priceCrossed: {
    color: '#DC362E',
    textDecoration: 'line-through',
    marginLeft: 8,
  },
}));

const SubscriptionOption: React.FC<SubscriptionOptionProps> = ({
  product,
  isSelected,
  onSelect,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const introPrice = product?.introPrice?.priceString;

  return (
    <Box
      onClick={onSelect}
      className={clsx(
        classes.subscriptionOption,
        isSelected && classes.selectedSubscriptionOption
      )}
    >
      {!introPrice && product.subscriptionPeriod === 'P1Y' && (
        <Box className={classes.subscriptionProfit}>
          <Typography variant="subtitle2" style={{ color: '#fff' }}>
            {t('common.save')} 17%
          </Typography>
        </Box>
      )}
      {!!introPrice ? (
        <Box className={classes.discountChip}>
          <Typography variant="subtitle2" style={{ color: '#fff' }}>
            {t('common.only-now')} -
            {product.subscriptionPeriod === 'P1Y' ? '50' : '33'}%
          </Typography>
        </Box>
      ) : (
        <Box
          className={clsx(
            classes.subscriptionCheck,
            isSelected && classes.subscriptionCheckSelected
          )}
        >
          {isSelected && <Box className={classes.subscriptionCheckDot}></Box>}
        </Box>
      )}
      <Typography variant="body1">
        {t(
          product.subscriptionPeriod === 'P1Y'
            ? 'subscription.annual'
            : 'subscription.monthly'
        )}
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h2">
          {introPrice ? introPrice : product.priceString}
        </Typography>
        {!!introPrice && (
          <Typography variant="subtitle2" className={classes.priceCrossed}>
            {introPrice ? product.priceString : introPrice}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SubscriptionOption;
