import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StackLayout from '@/screens/main/components/stack-layout';
import Doctor from './doctor';
import MedicalAssistant from './medical-assistant';
import Student from './student';
import Traine from './trainee';
import MedicalAssistantTraine from './medical-assistant-teaine';
import Another from './another';
import { ProfessionTypesEnum } from '@/type';

const ProfessionInfo: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams<{ professionType: string }>();
  const professionType = Number(params.professionType);

  const Step = useMemo(() => {
    switch (professionType) {
      case ProfessionTypesEnum.Doctor:
        return <Doctor professionType={professionType} />;
      case ProfessionTypesEnum.MedicalAssistant:
        return <MedicalAssistant professionType={professionType} />;
      case ProfessionTypesEnum.TraineeToNurse:
        return <Traine professionType={professionType} />;
      case ProfessionTypesEnum.Trainee:
        return <MedicalAssistantTraine professionType={professionType} />;
      case ProfessionTypesEnum.Student:
        return <Student professionType={professionType} />;
      case ProfessionTypesEnum.Other:
        return <Another />;
      default:
        return <></>;
    }
  }, [professionType]);

  return (
    <StackLayout title={t('my-profile.profession.title')} back="/my-profile">
      {Step}
    </StackLayout>
  );
};

export default ProfessionInfo;
