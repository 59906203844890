import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ReactComponent as ConfettiPic } from './confetti.svg';

import { Box, Button, Dialog, makeStyles, Typography } from '@material-ui/core';

import {
  DailyStatusType,
  useDailyTipperDataQuery,
  useDtWeeklyProgressQuery,
} from '@/graphql';
import { TAKEN_ACHIEVEMENT } from '@/apollo/queries';
import HexahedronIcon from '@/components/icons/hexahedron';
import { getDtYearsColors } from '@/utils/helpers';
import { Check } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(7),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    boxSizing: 'border-box',
  },
  congratCircle: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 140,
    height: 140,
    marginBottom: 12,
    borderRadius: '50%',
    border: '1px solid #E6EEFA',
    zIndex: 1,
    '& svg': {
      fontSize: 80,
    },
  },
  congratCircleBg: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: '#E6EEFA',
    opacity: 0.68,
    filter: 'blur(20px)',
    borderRadius: '50%',
    zIndex: 0,
  },
  hexahedronBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    zIndex: 1,
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  hexahedronNumber: {
    position: 'absolute',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 40,
    letterSpacing: '0.32px',
    color: '#FFFFFF',
    zIndex: 1,
  },
  confettiWrap: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: -22,
    '& svg': {
      width: '100%',
    },
  },
  cirlceCheck: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: 20,
    height: 20,
    left: '60%',
    top: '59%',
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
    zIndex: 1,
  },
}));

const YearCongrats: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();

  const { data: dtWeekData } = useDtWeeklyProgressQuery();
  const { data, refetch } = useDailyTipperDataQuery();
  const handleClose = useCallback(() => {
    refetch();
    history.replace('/daily-tipper');
  }, [history, refetch]);

  const localData = useQuery(TAKEN_ACHIEVEMENT);

  const isStatusTakeYearAchievement = useMemo(
    () =>
      localData?.data?.dailyStatus === DailyStatusType.TakeYearAchievement ||
      data?.dailyTipperData?.dailyResultStatus ===
        DailyStatusType.TakeYearAchievement,
    [data, localData]
  );

  const learningYearNumber = useMemo(
    () => dtWeekData?.me?.dailytipper?.yearAchievement?.yearNumber || 1,
    [dtWeekData]
  );

  const dtYearsColorsObj = useMemo(
    () => getDtYearsColors(learningYearNumber),
    [learningYearNumber]
  );

  return (
    <Dialog
      data-cy-daily-tipper-year-congrats
      fullScreen
      open={isStatusTakeYearAchievement}
      classes={{ paper: classes.paper }}
      onClose={handleClose}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="-webkit-fill-available"
      >
        <Box className={classes.confettiWrap}>
          <ConfettiPic />
        </Box>
        <Box
          display="grid"
          justifyItems="center"
          alignItems="center"
          gridRowGap={4}
          mt="20vh"
          mx="auto"
          maxWidth={260}
        >
          <Box className={classes.congratCircle}>
            <span className={classes.congratCircleBg} />
            <span className={classes.hexahedronBox}>
              <HexahedronIcon
                colorPrimary={dtYearsColorsObj.color1}
                colorSecondary={dtYearsColorsObj.color2}
              />
            </span>
            <span
              className={classes.hexahedronNumber}
              style={{
                textShadow: `0px 0px 2px ${dtYearsColorsObj.color4}`,
              }}
            >
              {learningYearNumber}
            </span>
            <span
              className={classes.cirlceCheck}
              style={{
                background: dtYearsColorsObj.color1,
              }}
            >
              <Check style={{ fontSize: 16, color: '#fff' }} />
            </span>
          </Box>
          <Typography variant="subtitle1">
            {t('daily-tipper.year-congrats.title')}
          </Typography>
          <Typography align="center" variant="body2">
            {t('daily-tipper.year-congrats.descr', {
              value: learningYearNumber,
            })}
          </Typography>
        </Box>
        <Button
          variant="outlined"
          fullWidth
          onClick={handleClose}
          style={{ marginTop: 16, color: '#2B75B3', borderColor: '#C6D5E3' }}
          data-dd-action-name="DT navigate after year congrats"
        >
          {t('common.next')}
        </Button>
      </Box>
    </Dialog>
  );
};

export default YearCongrats;
