import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '14px 24px',
  },
  extraAvatar: {
    position: 'relative',
    backgroundColor: theme.palette.divider,
    outline: '1px solid #fff',
    border: '1px solid rgba(0, 0, 0, 0.07)',
  },
  itemIcon: {
    position: 'relative',
    marginRight: 10,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 32,
    width: 32,
    height: 32,
    borderRadius: 4,
  },
  itemIconDefault: {
    border: '1px solid #E6EEFA',
    color: '#879BB2',
  },
  itemIconActive: {
    background: '#E2F5FD',
    color: '#2B75B3',
  },
  cirlceCheck: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: 12,
    height: 12,
    left: 24,
    top: 24,
    background: '#27AE60',
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
  },
  avatar: {
    height: 36,
    width: 36,
  },
  usersCount: {
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    fontWeight: 600,
    color: '#4A647C',
  },
}));
