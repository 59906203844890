import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const DuelRule2Icon: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M22.541 0H1.45895C1.07201 0 0.700921 0.16225 0.427316 0.451056C0.15371 0.739862 0 1.13157 0 1.54V22.46C0 22.8684 0.15371 23.2601 0.427316 23.5489C0.700921 23.8378 1.07201 24 1.45895 24H22.541C22.928 24 23.2991 23.8378 23.5727 23.5489C23.8463 23.2601 24 22.8684 24 22.46V1.54C24 1.33776 23.9623 1.13751 23.8889 0.950667C23.8156 0.763826 23.7082 0.594058 23.5727 0.451056C23.4372 0.308053 23.2764 0.194618 23.0994 0.117226C22.9224 0.0398334 22.7326 0 22.541 0ZM22.7368 22.46C22.7368 22.5148 22.7162 22.5674 22.6795 22.6061C22.6428 22.6449 22.593 22.6667 22.541 22.6667H1.45895C1.40702 22.6667 1.35722 22.6449 1.3205 22.6061C1.28379 22.5674 1.26316 22.5148 1.26316 22.46V1.54C1.26316 1.48519 1.28379 1.43262 1.3205 1.39386C1.35722 1.35511 1.40702 1.33333 1.45895 1.33333H22.541C22.593 1.33333 22.6428 1.35511 22.6795 1.39386C22.7162 1.43262 22.7368 1.48519 22.7368 1.54V22.46Z"
        fill="#2B75B3"
      />
      <path
        d="M12 15.3333C13.1867 15.3333 14.3467 14.9814 15.3334 14.3221C16.3201 13.6628 17.0892 12.7257 17.5433 11.6294C17.9974 10.533 18.1162 9.3266 17.8847 8.16271C17.6532 6.99883 17.0818 5.92973 16.2426 5.09061C15.4035 4.2515 14.3344 3.68005 13.1705 3.44854C12.0067 3.21703 10.8003 3.33585 9.7039 3.78998C8.60754 4.2441 7.67047 5.01314 7.01118 5.99983C6.35189 6.98653 6 8.14657 6 9.33326C6 10.9246 6.63214 12.4507 7.75736 13.5759C8.88258 14.7011 10.4087 15.3333 12 15.3333ZM12 4.66659C12.923 4.66659 13.8252 4.94028 14.5927 5.45306C15.3601 5.96584 15.9582 6.69468 16.3114 7.5474C16.6646 8.40012 16.7571 9.33843 16.577 10.2437C16.3969 11.1489 15.9525 11.9804 15.2998 12.6331C14.6472 13.2857 13.8157 13.7302 12.9104 13.9103C12.0052 14.0903 11.0669 13.9979 10.2141 13.6447C9.36142 13.2915 8.63259 12.6933 8.11981 11.9259C7.60703 11.1585 7.33333 10.2562 7.33333 9.33326C7.33333 8.09558 7.825 6.90859 8.70017 6.03342C9.57534 5.15825 10.7623 4.66659 12 4.66659Z"
        fill="#2B75B3"
      />
      <path
        d="M11.1536 11.1395C11.2785 11.2637 11.4475 11.3334 11.6236 11.3334C11.7998 11.3334 11.9687 11.2637 12.0936 11.1395L14.7603 8.47286C14.8695 8.34533 14.9266 8.18128 14.9201 8.01349C14.9136 7.84571 14.8441 7.68655 14.7253 7.56782C14.6066 7.44909 14.4475 7.37954 14.2797 7.37306C14.1119 7.36658 13.9478 7.42364 13.8203 7.53286L11.627 9.7262L10.1003 8.19953C9.97277 8.09031 9.80872 8.03324 9.64094 8.03972C9.47315 8.0462 9.31399 8.11576 9.19526 8.23449C9.07653 8.35322 9.00698 8.51238 9.0005 8.68016C8.99402 8.84794 9.05109 9.01199 9.1603 9.13953L11.1536 11.1395Z"
        fill="#2B75B3"
      />
      <path
        d="M19.6667 16.6665H3.66667C3.48986 16.6665 3.32029 16.7367 3.19526 16.8618C3.07024 16.9868 3 17.1564 3 17.3332C3 17.51 3.07024 17.6796 3.19526 17.8046C3.32029 17.9296 3.48986 17.9998 3.66667 17.9998H19.6667C19.8435 17.9998 20.013 17.9296 20.1381 17.8046C20.2631 17.6796 20.3333 17.51 20.3333 17.3332C20.3333 17.1564 20.2631 16.9868 20.1381 16.8618C20.013 16.7367 19.8435 16.6665 19.6667 16.6665Z"
        fill="#2B75B3"
      />
      <path
        d="M16.75 19.3335H7.41667C7.23986 19.3335 7.07029 19.4037 6.94526 19.5288C6.82024 19.6538 6.75 19.8234 6.75 20.0002C6.75 20.177 6.82024 20.3465 6.94526 20.4716C7.07029 20.5966 7.23986 20.6668 7.41667 20.6668H16.75C16.9268 20.6668 17.0964 20.5966 17.2214 20.4716C17.3464 20.3465 17.4167 20.177 17.4167 20.0002C17.4167 19.8234 17.3464 19.6538 17.2214 19.5288C17.0964 19.4037 16.9268 19.3335 16.75 19.3335Z"
        fill="#2B75B3"
      />
    </SvgIcon>
  );
};

export default DuelRule2Icon;
