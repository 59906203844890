import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 11,
    display: 'flex',
  },
  formBlock: {
    '& .MuiFormLabel-asterisk': {
      display: 'none',
    },
    '& .MuiFormLabel-filled': {
      color: '#929DA8',
    },
  },
  formWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 8px 16px',
    boxSizing: 'border-box',
    background: '#E6EEFA',
  },
  close: {
    left: theme.spacing(-1),
  },
}));
