import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 1000,
    height: '100vh',
    backgroundColor: theme.palette.common.white,
  },
  close: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    position: 'absolute',
  },
  circleIcon: {
    marginRight: 13,
  },
}));
