import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { ReactComponent as EmptyDuelIcon } from './index.svg';
import AppButton from '@/components/app-button';
import { LockOutlined } from '@material-ui/icons';

interface EmptyDuelProps {
  disabled?: boolean;
  loading?: boolean;
  onCreate: () => void;
}

const EmptyDuel: React.FC<EmptyDuelProps> = ({
  disabled,
  loading,
  onCreate,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      pt={2}
      pb={5}
      px={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      boxSizing="border-box"
    >
      <Box marginBottom={5}>
        <EmptyDuelIcon id="empty-duel-card-icon" />
      </Box>
      <Box marginBottom={5} marginX={10}>
        <Typography align="center" variant="body2">
          {t('common.duel.info')}
        </Typography>
      </Box>
      <Box display="flex" alignItems="flex-end" alignSelf="normal" flexGrow={1}>
        <AppButton
          fullWidth
          color="primary"
          variant="contained"
          visuallyDisabled={disabled}
          loading={loading}
          onClick={onCreate}
          startIcon={disabled && <LockOutlined />}
        >
          {t('common.duel.start-random-duel')}
        </AppButton>
      </Box>
    </Box>
  );
};

export default EmptyDuel;
