import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';

interface IModalProps {
  content: React.ReactElement;
  handleClose: () => void;
  open: boolean;
  fullScreen?: boolean | undefined;
}

export const Modal: React.FC<IModalProps> = (props) => {
  const { content, handleClose, open, fullScreen } = props;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      TransitionComponent={Fade}
      onClose={handleClose}
    >
      {content}
    </Dialog>
  );
};

interface IInitState {
  open: boolean;
  content: React.ReactElement | null;
  fullScreen: boolean | undefined;
  handleClose: () => void;
}

interface IOpenModalPayload {
  content: React.ReactElement;
  fullScreen: boolean | undefined;
  handleClose: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ModalContext = React.createContext({} as any);

export const ModalProvider: React.FC = (props) => {
  const { children } = props;
  const OPEN_MODAL = 'OPEN_MODAL';
  const CLOSE_MODAL = 'CLOSE_MODAL';

  const initState: IInitState = {
    open: false,
    content: null,
    fullScreen: false,
    handleClose: () => {
      console.log('');
    },
  };

  function reducer(
    state: IInitState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: { type: string; [key: string]: any }
  ) {
    switch (action.type) {
      case OPEN_MODAL: {
        const { backDrop = false } = action.payload;
        return {
          ...state,
          ...action.payload,
          backDrop,
        };
      }
      case CLOSE_MODAL: {
        return {
          ...initState,
        };
      }
      default:
        return state;
    }
  }
  const [state, dispatch] = React.useReducer(reducer, initState);
  const openModal = React.useCallback(
    (payload: IOpenModalPayload) => {
      dispatch({
        type: OPEN_MODAL,
        payload: { ...payload, open: true },
      });
    },
    [OPEN_MODAL]
  );
  const closeModal = React.useCallback(() => {
    dispatch({ type: CLOSE_MODAL });
  }, [CLOSE_MODAL]);
  const modalHandlers = React.useMemo(
    () => ({
      openModal,
      closeModal,
    }),
    [closeModal, openModal]
  );
  return (
    <ModalContext.Provider value={modalHandlers}>
      {children}
      {state.open && (
        <Modal
          open={state.open}
          handleClose={state.handleClose}
          fullScreen={state.fullScreen}
          content={state.content}
        />
      )}
    </ModalContext.Provider>
  );
};
