import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { Box } from '@material-ui/core';

import * as Form from '@/components/form';
import { useSignUp } from '@/contexts/sign-up-context';
import { useAnotherDataQuery } from '@/graphql';

import StepNavigate from '../../../components/step-navigation';
import PublicLayout from '../../../components/layout';
import Agreement from '../../../components/agreement';
import { sortBy } from 'lodash';
import PrivacyPolicy from '../../privacy-policy';

interface AnotherInfoProps {
  onBack: () => void;
  onNext: () => void;
}

interface Form {
  speciality: string;
  dataProtection: boolean;
  agb: boolean;
  subscribeToNews: boolean;
}

const Another: React.FC<AnotherInfoProps> = ({ onBack, onNext }) => {
  const { t } = useTranslation();
  const { professionType, subscribeToNews, specialityId, update } = useSignUp();

  const { register, errors, setValue, watch } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      speciality: specialityId || '',
      dataProtection: false,
      agb: false,
      subscribeToNews: !!subscribeToNews,
    },
  });

  if (professionType === undefined) {
    // TODO handle this case
    throw new Error('Not suported professionType');
  }

  const { data } = useAnotherDataQuery({ variables: { professionType } });

  useEffect(() => {
    register({ name: 'speciality' }, {});
    register({ name: 'dataProtection' }, {});
    register({ name: 'agb' }, {});
    register({ name: 'subscribeToNews' }, {});
  }, [register, specialityId, subscribeToNews]);

  const changeField = useCallback(
    (prop: keyof Form) =>
      (e: React.ChangeEvent<{ checked?: boolean; value?: unknown }>) => {
        const options = {
          shouldDirty: true,
          shouldValidate: true,
        };
        const value = String(e.target.value);
        const checked = Boolean(e.target.checked);

        switch (prop) {
          case 'speciality': {
            setValue(prop, value, options);

            return update({ specialityId: value });
          }
          case 'subscribeToNews': {
            setValue(prop, checked, options);

            return update({ subscribeToNews: e.target.checked });
          }
          case 'agb': {
            setValue(prop, checked, options);
            return;
          }
          case 'dataProtection': {
            setValue(prop, checked, options);

            return;
          }
        }
      },
    [setValue, update]
  );

  const specialities = React.useMemo(() => {
    const specs = data
      ? (data.specialitiesByType || []).map((it) => ({
          value: String(it?.id),
          label: String(it?.title),
        }))
      : [];
    return sortBy(specs, ['label']);
  }, [data]);

  const isValid = useMemo(() => {
    const values = watch();

    return isEmpty(errors) && !!values.speciality && !!values.agb;
  }, [errors, watch]);

  return (
    <PublicLayout title={t('sign-up.another.title')} page="3 / 3">
      <Box paddingY={7}>
        <Form.Row>
          <Form.SelectField
            fullWidth
            required
            data-cy-speciality
            variant="outlined"
            name="speciality"
            label={t('fields.speciality.label')}
            options={specialities}
            errors={errors.speciality}
            value={watch('speciality')}
            onChange={changeField('speciality')}
          />
        </Form.Row>
        <Agreement
          data-cy-agb
          name="agb"
          variant="agb"
          checked={watch('agb')}
          onChange={changeField('agb')}
        />
        <Agreement
          noDivider
          data-cy-subscription
          name="subscribeToNews"
          variant="subscription"
          checked={watch('subscribeToNews')}
          onChange={changeField('subscribeToNews')}
        />
        <PrivacyPolicy />
      </Box>
      <StepNavigate disabled={!isValid} onNext={onNext} onBack={onBack} />
    </PublicLayout>
  );
};

export default Another;
