import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import AccessAlarmIcon from '@/components/icons/alarm';
import { DateTime } from 'luxon';
import {
  QuizSessionsOngoingQuery,
  QuizSessionsOnHomePageQuery,
  QuizTimeDetailsQuery,
  QuizTimeSessionStatus,
  UserQuizSessionSettingsUserStatus,
} from '@/graphql';
import { useRelativeTime } from '@/hooks/useTime';
import DurationIcon from '@/components/icons/duration';
import QuizCountDown from '../header/quiz-count-down';
import { useApolloClient } from '@apollo/client';
import { QuizTimeBlockWithTime } from '@/type';
import {
  QUIZ_SESSIONS_FOR_HOME,
  QUIZ_SESSIONS_ONGOING,
  QUIZ_TIME_DETAILS,
} from '@/apollo/queries';
import { _1_HOUR_IN_MILLIS, _24_HOURS_IN_MILLIS } from '@/utils/constants';

type QuizTimeBlockProps = {
  quizStatus?: QuizTimeSessionStatus | null;
  userStatus?: UserQuizSessionSettingsUserStatus | string | null;
  finishDateTime: string;
  startDateTime: string;
  duration?: number | null;
  type?: QuizTimeBlockWithTime | null;
  quizSessionId?: string | null;
  isShortFormat?: boolean | null;
};

const QuizTimeBlock: React.FC<QuizTimeBlockProps> = ({
  quizStatus,
  userStatus,
  finishDateTime,
  startDateTime,
  duration,
  type,
  quizSessionId,
  isShortFormat,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cache = useApolloClient();

  const luxonFinishDateTime = DateTime.fromISO(finishDateTime);
  const luxonStartDateTime = DateTime.fromISO(startDateTime);

  const [islessThan24HoursTillStart, setIslessThan24HoursTillStart] =
    useState<boolean>(false);

  const [islessThanHourTillStart, setIslessThanHourTillStart] =
    useState<boolean>(false);

  const [isLessThanSecondToStart, setIsLessThanSecondToStart] =
    useState<boolean>(false);

  const timeToStart = (isTimeToStart?: boolean, isTimeToFinish?: boolean) => {
    if (type === QuizTimeBlockWithTime.HOME_PAGE_CARD) {
      const data: QuizSessionsOnHomePageQuery | undefined | null =
        cache.readQuery({
          query: QUIZ_SESSIONS_FOR_HOME,
          variables: {
            startLess24Hours: true,
          },
        });
      if (isTimeToStart) {
        const quizesWithManuallyModifiedStatus = data?.homeQuizSessions?.map(
          (quiz) =>
            quiz?.quizSession?.id !== quizSessionId
              ? quiz
              : {
                  ...quiz,
                  quizSession: {
                    ...quiz?.quizSession,
                    status: QuizTimeSessionStatus.InProgress,
                  },
                }
        );
        cache.writeQuery({
          query: QUIZ_SESSIONS_FOR_HOME,
          variables: {
            startLess24Hours: true,
          },
          data: {
            ...data,
            homeQuizSessions: quizesWithManuallyModifiedStatus,
          },
        });
      } else if (isTimeToFinish) {
        const quizesWithManuallyModifiedStatus = data?.homeQuizSessions?.map(
          (quiz) =>
            quiz?.quizSession?.id !== quizSessionId
              ? quiz
              : {
                  ...quiz,
                  quizSession: {
                    ...quiz?.quizSession,
                    status: QuizTimeSessionStatus.WaitingForFinish,
                  },
                }
        );
        cache.writeQuery({
          query: QUIZ_SESSIONS_FOR_HOME,
          variables: {
            startLess24Hours: true,
          },
          data: {
            ...data,
            homeQuizSessions: quizesWithManuallyModifiedStatus,
          },
        });
      }
    }

    if (type === QuizTimeBlockWithTime.QT_HEADER) {
      const data: QuizTimeDetailsQuery | undefined | null = cache.readQuery({
        query: QUIZ_TIME_DETAILS,
        variables: { quizSessionId: quizSessionId },
      });
      isTimeToStart
        ? cache.writeQuery({
            query: QUIZ_TIME_DETAILS,
            variables: { quizSessionId: quizSessionId },
            data: {
              quizTimeSession: {
                ...data?.quizTimeSession,
                status: QuizTimeSessionStatus.InProgress,
              },
            },
          })
        : cache.writeQuery({
            query: QUIZ_TIME_DETAILS,
            variables: { quizSessionId: quizSessionId },
            data: {
              quizTimeSession: {
                ...data?.quizTimeSession,
                status: QuizTimeSessionStatus.WaitingForFinish,
              },
            },
          });
    }

    if (type === QuizTimeBlockWithTime.LIST_PAGE_CARD) {
      const data: QuizSessionsOngoingQuery | undefined | null = cache.readQuery(
        {
          query: QUIZ_SESSIONS_ONGOING,
        }
      );
      if (isTimeToStart) {
        const quizesWithManuallyModifiedStatus = data?.ongoingQuizSessions?.map(
          (quiz) =>
            quiz?.id !== quizSessionId
              ? quiz
              : { ...quiz, status: QuizTimeSessionStatus.InProgress }
        );
        cache.writeQuery({
          query: QUIZ_SESSIONS_ONGOING,
          data: {
            ...data,
            ongoingQuizSessions: quizesWithManuallyModifiedStatus,
          },
        });
      } else if (isTimeToFinish) {
        const quizesWithManuallyModifiedStatus = data?.ongoingQuizSessions?.map(
          (quiz) =>
            quiz?.id !== quizSessionId
              ? quiz
              : { ...quiz, status: QuizTimeSessionStatus.WaitingForFinish }
        );
        cache.writeQuery({
          query: QUIZ_SESSIONS_ONGOING,
          data: {
            ...data,
            ongoingQuizSessions: quizesWithManuallyModifiedStatus,
          },
        });
      }
    }
  };

  const { timeLeft } = useRelativeTime(
    DateTime.fromISO(startDateTime as string),
    {},
    _1_HOUR_IN_MILLIS,
    !isShortFormat
  );

  useEffect(() => {
    if (!startDateTime) setIslessThan24HoursTillStart(false);
    else if (+DateTime.fromISO(startDateTime).diffNow() < 1000) {
      setIsLessThanSecondToStart(true);
    } else if (
      +DateTime.fromISO(startDateTime as string).diffNow() < _1_HOUR_IN_MILLIS
    ) {
      setIslessThanHourTillStart(true);
    } else if (
      quizStatus === QuizTimeSessionStatus.Announced &&
      +DateTime.fromISO(startDateTime).diffNow() < _24_HOURS_IN_MILLIS
    ) {
      setIslessThan24HoursTillStart(true);
    }
  }, [quizStatus, startDateTime]);

  return (
    <Box
      display="flex"
      alignItems="center"
      gridColumnGap={8}
      gridRowGap={4}
      flexWrap="wrap"
      mr={8}
    >
      {' '}
      {((userStatus !== UserQuizSessionSettingsUserStatus.InProgress &&
        quizStatus === QuizTimeSessionStatus.Announced &&
        !islessThan24HoursTillStart &&
        !islessThanHourTillStart &&
        !isLessThanSecondToStart) ||
        !userStatus ||
        userStatus === UserQuizSessionSettingsUserStatus.Out ||
        userStatus === UserQuizSessionSettingsUserStatus.Finished ||
        quizStatus === QuizTimeSessionStatus.WaitingForFinish ||
        userStatus === UserQuizSessionSettingsUserStatus.WaitingForJoining) && (
        <>
          <DurationIcon htmlColor="inherit" />
          <Typography
            color="inherit"
            variant="overline"
            className={classes.date}
          >
            {luxonFinishDateTime?.hasSame(luxonStartDateTime, 'days')
              ? `${luxonStartDateTime?.toFormat(
                  'dd.MM.yy'
                )} • ${luxonStartDateTime?.toFormat(
                  'HH:mm'
                )} - ${luxonFinishDateTime?.toFormat('HH:mm')}`
              : `${luxonStartDateTime?.toFormat(
                  'dd.MM.yy'
                )} • ${luxonStartDateTime?.toFormat('HH:mm')} - ${t(
                  'time-relative.hours-short',
                  { hours: `${duration}` }
                )}`}
          </Typography>
        </>
      )}
      {userStatus &&
        userStatus !== UserQuizSessionSettingsUserStatus.Out &&
        userStatus !== UserQuizSessionSettingsUserStatus.WaitingForJoining &&
        quizStatus === QuizTimeSessionStatus.Announced &&
        islessThanHourTillStart &&
        !isLessThanSecondToStart && (
          <QuizCountDown
            finishDateTime={startDateTime as string}
            timeToStart={timeToStart}
            isCountToStartDateTime={true}
          />
        )}
      {userStatus &&
        userStatus !== UserQuizSessionSettingsUserStatus.Out &&
        userStatus !== UserQuizSessionSettingsUserStatus.WaitingForJoining &&
        quizStatus === QuizTimeSessionStatus.Announced &&
        islessThan24HoursTillStart &&
        !islessThanHourTillStart &&
        !isLessThanSecondToStart &&
        type === 'header' && (
          <QuizCountDown
            finishDateTime={startDateTime as string}
            timeToStart={timeToStart}
            isCountToStartDateTime={true}
          />
        )}
      {userStatus &&
        userStatus !== UserQuizSessionSettingsUserStatus.Out &&
        userStatus !== UserQuizSessionSettingsUserStatus.WaitingForJoining &&
        quizStatus === QuizTimeSessionStatus.Announced &&
        islessThan24HoursTillStart &&
        !islessThanHourTillStart &&
        !isLessThanSecondToStart &&
        type !== 'header' && (
          <Box display="flex" flexDirection="row" alignItems="end" gridGap={5}>
            <AccessAlarmIcon htmlColor="inherit" />
            <Typography
              color="inherit"
              variant="overline"
              className={classes.date}
            >
              {timeLeft} {t('time-relative.till-begining')}
            </Typography>
          </Box>
        )}
      {userStatus &&
        userStatus !== UserQuizSessionSettingsUserStatus.Out &&
        userStatus !== UserQuizSessionSettingsUserStatus.WaitingForJoining &&
        (quizStatus === QuizTimeSessionStatus.InProgress ||
          userStatus === UserQuizSessionSettingsUserStatus.Paused) && (
          <QuizCountDown
            finishDateTime={finishDateTime as string}
            timeToStart={timeToStart}
            isCountToFinishDateTime={true}
          />
        )}
    </Box>
  );
};

export default QuizTimeBlock;
