import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ErrorOutlineOutlined } from '@material-ui/icons';
import { useStyles } from './styles';

export const InfoBox: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Box display="flex" p={2} pr={4} className={classes.infoBox}>
      <ErrorOutlineOutlined fontSize="small" color="primary" />
      <Typography
        variant="caption"
        color="textPrimary"
        style={{ marginLeft: 8 }}
      >
        {children}
      </Typography>
    </Box>
  );
};
