import { useState } from 'react';
import { LS_KEY_TABINDEX } from '@/utils/constants';

export const useTabsNav = (id: string, noRememberTabIndex?: boolean | null) => {
  const tabIndex = localStorage.getItem(`${id}${LS_KEY_TABINDEX}`);
  const [value, setValue] = useState(JSON.parse(tabIndex || '0'));

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (_: React.ChangeEvent<{}> | null, newValue: number) => {
    setValue(newValue);
    if (!noRememberTabIndex)
      localStorage.setItem(`${id}${LS_KEY_TABINDEX}`, JSON.stringify(newValue));
    document?.querySelector('#classroomScrollBox')?.scrollIntoView();
  };

  return {
    value,
    handleChange,
  };
};
