import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper } from '@material-ui/core';

import StackLayout from '@/screens/main/components/stack-layout';
import ActivateCodeForm from '../subscriptions/activate-code-form';
import SubsAgreementText from '../subscriptions/subs-agreement-text';
import { useStyles } from './styles';

const ActivateCode = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <StackLayout title={t('menu.enter-premium-code')}>
      <Paper elevation={0} className={classes.root}>
        <ActivateCodeForm />
        <SubsAgreementText subscriptionPeriod="CODE" />
      </Paper>
    </StackLayout>
  );
};

export default ActivateCode;
