import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Box, IconButton, Paper, Typography, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ExitToAppOutlined } from '@material-ui/icons';
import {
  QuizTimeSessionStatus,
  useLeaveQuizTimeMutation,
  UserQuizSessionSettingsUserStatus,
  QuizSessionsOnHomePageQuery,
  QuizSessionsOnHomePageDocument,
  QuizTimeDetailsDocument,
  useStartQuizMutation,
  GetUserQuizSessionSettingsDocument,
} from '@/graphql';
import { useLeaveQuizModal } from '@/hooks/useModal';
import { useApolloClient } from '@apollo/client';
import { QUIZ_SESSIONS_FOR_HOME } from '@/apollo/queries';
import { useStyles } from './styles';
import QuizTimeBlock from '../../time-block';
import { QuizTimeBlockWithTime } from '@/type';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AppButton from '@/components/app-button';
import { useSnackbarContext } from '@/contexts/snackbar-context';

type QuiztimeItemProps = {
  filterIgnoredQuizIDs?: () => void;
  quiz: null | {
    id: string;
    startDate: string;
    startDateTime?: string;
    finishDateTime?: string;
    quizTimeDuration?: number | null;
    quizTime: {
      id: string;
      title?: string | undefined | null;
      quizTimeImage?: string | undefined | null;
    };
  };
  quizSessionId: string;
  quizStatus?: QuizTimeSessionStatus | null;
  userStatus?: UserQuizSessionSettingsUserStatus | string | undefined | null;
  isSingleItem: boolean;
  randomDuel?: {
    handleCreateQuizDuel?: () => Promise<void>;
    creatingLoading?: boolean;
    canCreateDuel?: any;
  };
};

const QuiztimeItem: React.FC<QuiztimeItemProps> = ({
  quizSessionId,
  quiz,
  quizStatus,
  userStatus,
  isSingleItem,
  randomDuel,
}) => {
  const { t } = useTranslation();
  const { cache } = useApolloClient();
  const classes = useStyles();
  const history = useHistory();
  const [startQuizMutation] = useStartQuizMutation();
  const { setCustomSnack } = useSnackbarContext();

  const startQuizTime = useCallback(async () => {
    try {
      await startQuizMutation({
        variables: {
          quizSessionId: quizSessionId,
        },
        refetchQueries: [
          {
            query: QuizTimeDetailsDocument,
            variables: { quizSessionId },
          },
          {
            query: GetUserQuizSessionSettingsDocument,
            variables: { quizSessionId },
          },
          {
            query: QuizSessionsOnHomePageDocument,
            variables: { startLess24Hours: true },
          },
        ],
      });
      setTimeout(() => {
        history.push(`/quiztime/${quizSessionId}/`, {
          backPageForQuiz: '/quiz-list',
          showSnackQtStarted: true,
        });
      }, 200);
    } catch (err) {
      console.error('useStartQuizMutation', err);
    }
  }, [quizSessionId, startQuizMutation, history]);

  const filterIgnoredQuizIDs = () => {
    const ignoredQuizIDs = JSON.parse(
      localStorage?.getItem('ignoredQuizIDs') || '[]'
    );
    ignoredQuizIDs.push(quizSessionId);
    localStorage.setItem('ignoredQuizIDs', JSON.stringify(ignoredQuizIDs));

    const data: QuizSessionsOnHomePageQuery | undefined | null =
      cache.readQuery({
        variables: {
          startLess24Hours: true,
        },
        query: QUIZ_SESSIONS_FOR_HOME,
      });

    const filteredQuiztimes = data?.homeQuizSessions?.filter(
      (it) => it?.quizSession?.id !== quizSessionId
    );
    cache.writeQuery({
      variables: {
        startLess24Hours: true,
      },
      query: QUIZ_SESSIONS_FOR_HOME,
      data: {
        ...data,
        homeQuizSessions: filteredQuiztimes,
      },
    });
  };

  const [leaveQuizMutation] = useLeaveQuizTimeMutation();
  const leaveQuizTime = useCallback(async () => {
    try {
      await leaveQuizMutation({
        variables: {
          sessionId: quizSessionId,
        },
        refetchQueries: [
          {
            query: QuizSessionsOnHomePageDocument,
            variables: { startLess24Hours: true },
          },
        ],
      });
      setCustomSnack({
        visibility: true,
        message: 'quiztime.snackBars.snack-message-qt-leaved',
        messageOpts: { value: quiz?.quizTime.title },
      });
    } catch (err) {
      console.error('useLeaveQuizTimeMutation', err);
    }
  }, [quizSessionId, leaveQuizMutation, setCustomSnack, quiz]);

  const redirectLink = useMemo(() => {
    return !userStatus || userStatus === UserQuizSessionSettingsUserStatus.Out
      ? `/quiztime-details/${quiz?.id}`
      : `/quiztime/${quiz?.id}`;
  }, [userStatus, quiz]);

  const leaveModal = useLeaveQuizModal(leaveQuizTime);

  return (
    <Paper
      className={classes.container}
      elevation={0}
      style={{ marginRight: `${isSingleItem ? '0px' : '8px'}` }}
    >
      <Box className={classes.header}>
        <Typography variant="overline">
          {(userStatus === UserQuizSessionSettingsUserStatus.Out ||
            userStatus ===
              UserQuizSessionSettingsUserStatus.WaitingForJoining ||
            !userStatus) &&
            t('quiztime.home.title-announced')}
          {quizStatus === QuizTimeSessionStatus.Announced &&
            userStatus === UserQuizSessionSettingsUserStatus.Joined &&
            t('quiztime.home.title-ongoing')}
          {quizStatus === QuizTimeSessionStatus.InProgress &&
            (userStatus === UserQuizSessionSettingsUserStatus.Joined ||
              userStatus === UserQuizSessionSettingsUserStatus.InProgress) &&
            t('quiztime.home.title-inprogress')}
          {userStatus === UserQuizSessionSettingsUserStatus.Paused &&
            t('quiztime.title')}
        </Typography>
        {userStatus === UserQuizSessionSettingsUserStatus.Paused && (
          <Typography className={classes.loverCaseCaption}>
            {' '}
            • {t('common.pause')}{' '}
          </Typography>
        )}
        {(quizStatus === QuizTimeSessionStatus.Announced ||
          quizStatus === QuizTimeSessionStatus.InProgress) &&
          userStatus !== UserQuizSessionSettingsUserStatus.Paused &&
          (userStatus === UserQuizSessionSettingsUserStatus.Out ||
            userStatus !==
              UserQuizSessionSettingsUserStatus.WaitingForJoining ||
            !userStatus) && (
            <IconButton style={{ padding: 0 }} onClick={filterIgnoredQuizIDs}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          )}
      </Box>
      <hr className={classes.line} />
      <Link
        to={{ pathname: redirectLink, state: { backPageForQuiz: '/home' } }}
        className={classes.link}
      >
        <Box className={classes.contant}>
          <Box className={classes.imageContainer}>
            {quiz?.quizTime.quizTimeImage && quiz?.quizTime.title && (
              <LazyLoadImage
                effect="blur"
                wrapperClassName={classes.cardPicture}
                useIntersectionObserver
                src={quiz?.quizTime.quizTimeImage}
                alt={quiz?.quizTime.title}
              />
            )}
          </Box>
          <Box className={classes.details}>
            <Typography variant="subtitle1" className={classes.titleText}>
              {quiz?.quizTime.title}
            </Typography>
            <Box className={classes.detailsContent}>
              {quizStatus && (
                <QuizTimeBlock
                  quizStatus={quizStatus}
                  userStatus={userStatus}
                  startDateTime={quiz?.startDateTime || ''}
                  finishDateTime={quiz?.finishDateTime || ''}
                  duration={quiz?.quizTimeDuration}
                  type={QuizTimeBlockWithTime.HOME_PAGE_CARD}
                  quizSessionId={quizSessionId}
                />
              )}
            </Box>
          </Box>
        </Box>
        {quizStatus === QuizTimeSessionStatus.InProgress &&
          userStatus === UserQuizSessionSettingsUserStatus.Joined && (
            <Box className={classes.actionButtonsContainer}>
              <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                className={classes.actionButton}
                onClick={(e: any) => {
                  e.preventDefault();
                  startQuizTime();
                }}
              >
                {t('common.start')}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                fullWidth={true}
                onClick={(e: any) => {
                  e.preventDefault();
                  quizStatus === QuizTimeSessionStatus.InProgress
                    ? leaveModal.open()
                    : leaveQuizTime();
                }}
              >
                <ExitToAppOutlined
                  style={{ width: 15, height: 15, marginRight: 7.5 }}
                />
                {t('common.leave')}
              </Button>
            </Box>
          )}
        {userStatus === UserQuizSessionSettingsUserStatus.Paused && (
          <Box mx={2} pb={7} pt={3}>
            <AppButton
              variant="contained"
              fullWidth
              data-cy-duel-start-new-duel
              color="primary"
              loading={randomDuel?.creatingLoading}
              disabled={!randomDuel?.canCreateDuel}
              onClick={randomDuel?.handleCreateQuizDuel}
            >
              {t('classrooms.play-random-duel')}
            </AppButton>
          </Box>
        )}
      </Link>
    </Paper>
  );
};
export default QuiztimeItem;
