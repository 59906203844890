import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from '@material-ui/core';

type OrgOverviewProps = {
  about?: string | null;
  website?: string | null;
  email?: string | null;
};

const OrgOverview: React.FC<OrgOverviewProps> = ({ about, website, email }) => {
  const { t } = useTranslation();

  return (
    <Box p={2}>
      <Paper elevation={0}>
        <Box py={5} px={6} display="grid" gridRowGap={28}>
          {about && (
            <Box display="grid" gridRowGap={12}>
              <Typography color="inherit" variant="overline">
                {t('common.about')}
              </Typography>
              <Typography variant="body1">{about}</Typography>
            </Box>
          )}
          {(website || email) && (
            <Box display="grid" gridRowGap={12}>
              <Typography color="inherit" variant="overline">
                {t('common.contacts')}
              </Typography>
              {website && (
                <a href={website} className="text-decoration-none">
                  <Typography variant="body2" color="primary">
                    {new URL(website).hostname}
                  </Typography>
                </a>
              )}
              {email && (
                <a href={`mailto:${email}`} className="text-decoration-none">
                  <Typography variant="body2" color="primary">
                    {email}
                  </Typography>
                </a>
              )}
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default OrgOverview;
