import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const SentimentNeutral: React.FC<SvgIconProps> = ({ ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99337 1.33333C4.31337 1.33333 1.33337 4.32 1.33337 8C1.33337 11.68 4.31337 14.6667 7.99337 14.6667C11.68 14.6667 14.6667 11.68 14.6667 8C14.6667 4.32 11.68 1.33333 7.99337 1.33333ZM4.66671 6.33333C4.66671 5.78105 5.11442 5.33333 5.66671 5.33333C6.21899 5.33333 6.66671 5.78105 6.66671 6.33333C6.66671 6.88562 6.21899 7.33333 5.66671 7.33333C5.11442 7.33333 4.66671 6.88562 4.66671 6.33333ZM10.3334 5.33333C9.78109 5.33333 9.33337 5.78105 9.33337 6.33333C9.33337 6.88562 9.78109 7.33333 10.3334 7.33333C10.8857 7.33333 11.3334 6.88562 11.3334 6.33333C11.3334 5.78105 10.8857 5.33333 10.3334 5.33333ZM6.00004 10.3333V9.33333H10V10.3333H6.00004ZM2.66671 8C2.66671 10.9467 5.05337 13.3333 8.00004 13.3333C10.9467 13.3333 13.3334 10.9467 13.3334 8C13.3334 5.05333 10.9467 2.66667 8.00004 2.66667C5.05337 2.66667 2.66671 5.05333 2.66671 8Z"
      />
    </SvgIcon>
  );
};

export default SentimentNeutral;
