import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const DailyTipperIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H8C6.9 18 6 17.1 6 16V4C6 2.9 6.9 2 8 2ZM2 6H4V20H18V22H4C2.9 22 2 21.1 2 20V6ZM8 16V4H20V16H8ZM18 9H10V11H18V9ZM10 12H14V14H10V12ZM18 6H10V8H18V6Z"
    />
  </SvgIcon>
);

export default DailyTipperIcon;
