import React, { useCallback } from 'react';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useEarlyFinishClassroom } from '@/hooks/useEarlyFinishClassroom';
import { ClassroomTypes, TabNavIdsEnum } from '@/type';
import { LS_KEY_TABINDEX } from '@/utils/constants';

type BannerGoalReachedProps = {
  sessionSettingsId: string;
  classroomType: ClassroomTypes;
};

const BannerGoalReached: React.FC<BannerGoalReachedProps> = ({
  sessionSettingsId,
  classroomType,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { earlyFinishClassroom } = useEarlyFinishClassroom();

  const handleEarlyFinish = useCallback(async () => {
    try {
      await earlyFinishClassroom(sessionSettingsId, classroomType);
      localStorage.setItem(
        `${TabNavIdsEnum.CLASSROOMS}${LS_KEY_TABINDEX}`,
        '1'
      );
      history.replace(`/classrooms/`);
    } catch (error) {
      console.error('Early Finish Classroom Mutation', error);
    }
  }, [earlyFinishClassroom, sessionSettingsId, history, classroomType]);

  return (
    <Paper elevation={0}>
      <Box
        py={3}
        px={4}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box mr={4}>
          <Typography variant="subtitle2">
            {t('classrooms.banner-goal-reached.title')}
          </Typography>
          <Typography variant="caption">
            {t('classrooms.banner-goal-reached.descr')}
          </Typography>
        </Box>
        <Button color="primary" variant="contained" onClick={handleEarlyFinish}>
          {t('common.end')}
        </Button>
      </Box>
    </Paper>
  );
};

export default BannerGoalReached;
