import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    minHeight: 220,
    maxHeight: 220,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  cardIcon: {
    position: 'absolute',
    right: 16,
    bottom: 20,
    zIndex: 0,
    '& img': {
      height: 80,
      width: 80,
      filter: 'brightness(0) invert(1)',
    },
  },
  overlay: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    width: '100%',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(9),
    color: theme.palette.common.white,
    zIndex: 1,
  },
  orgLink: {
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
  chipGolden: {
    borderColor: '#fff',
    background: '#fff',
    color: '#DD6903',
  },
  statusIsActive: {
    color: '#27AE60',
  },
  avatar: {
    height: 32,
    width: 32,
  },
}));
