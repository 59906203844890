import { useMemo } from 'react';
import { keyBy } from 'lodash';
import {
  useUserMeQuery,
  User_FragmentFragment,
  User_Settings_FragmentFragment,
} from '@/graphql';
import { FeatureFlagsNamesEnum } from '@/type';
import { useAuth } from '@/contexts/auth-context';

interface FeatureFlags {
  dailyTipperEnabled: boolean | null;
  classroomsEnabled: boolean | null;
  humeoAppEnabled: boolean | null;
  paymentEnabled: boolean | null;
  inAppPaymentEnabled: boolean | null;
  cookiesEnabled: boolean | null;
  subsDiscountsEnabled: boolean | null;
  crAvaGroupEnabled: boolean | null;
  me: User_FragmentFragment | null | undefined;
  userSettings: User_Settings_FragmentFragment | null | undefined;
}

export const useFeatureFlags = (): FeatureFlags => {
  const { token } = useAuth();
  const { data } = useUserMeQuery({
    skip: !token,
  });

  const featureFlagsObj = useMemo(
    () => keyBy(data?.featureFlags, 'name'),
    [data]
  );

  return {
    dailyTipperEnabled:
      featureFlagsObj[FeatureFlagsNamesEnum.DAILY_TIPPER]?.enabled !== false,
    classroomsEnabled:
      featureFlagsObj[FeatureFlagsNamesEnum.CLASS_ROOMS]?.enabled !== false,
    humeoAppEnabled:
      featureFlagsObj[FeatureFlagsNamesEnum.HUMEO_APP]?.enabled !== false,
    paymentEnabled:
      featureFlagsObj[FeatureFlagsNamesEnum.PAYMENT]?.enabled !== false,
    inAppPaymentEnabled:
      featureFlagsObj[FeatureFlagsNamesEnum.IN_APP_PAYMENT]?.enabled !== false,
    cookiesEnabled:
      featureFlagsObj[FeatureFlagsNamesEnum.COOKIES]?.enabled !== false,
    subsDiscountsEnabled:
      featureFlagsObj[FeatureFlagsNamesEnum.SUBS_DISCOUNTS]?.enabled !== false,
    crAvaGroupEnabled:
      featureFlagsObj[FeatureFlagsNamesEnum.CLASSROOM_AVA_GROUP]?.enabled !==
      false,
    me: data?.me,
    userSettings: data?.me?.settings,
  };
};
