import { useReadNotificationMutation } from '@/graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box, Dialog, Typography } from '@material-ui/core';

interface AgbChangedProps {
  agbChangedNotificationId?: string;
}

const AgbChangedModal: React.FC<AgbChangedProps> = ({
  agbChangedNotificationId,
}) => {
  const [readNotification] = useReadNotificationMutation();
  const history = useHistory();
  const { t } = useTranslation();
  const [opened, setOpened] = useState(true);

  const onLinkClick = () => {
    setOpened(false);
    if (agbChangedNotificationId)
      readNotification({
        variables: { id: agbChangedNotificationId },
      });
    history.push('/privacy-agb');
  };

  const onClose = () => {
    if (agbChangedNotificationId)
      readNotification({
        variables: { id: agbChangedNotificationId },
      });
    setOpened(false);
  };

  return (
    <Dialog
      open={opened}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onClose={onClose}
    >
      <Box>
        <DialogTitle>{t('dialogs.agb-changed.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText variant="body2">
            {t('dialogs.agb-changed.content')}

            <Typography
              variant="inherit"
              style={{ color: '#3498DB' }}
              onClick={onLinkClick}
            >
              {' '}
              {t('dialogs.agb-changed.link-to-agb-text')}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('common.ok')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AgbChangedModal;
