import React from 'react';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: 56,
    height: 40,
  },
});

const AcadCap: React.FC<SvgIconProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  return (
    <SvgIcon
      viewBox="0 0 56 40"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <path
        d="M54.3299 11.8434L29.1338 0.639989C28.7768 0.481749 28.3905 0.400002 28 0.400002C27.6095 0.400002 27.2232 0.481749 26.8662 0.639989L1.67007 11.8434C1.17329 12.0626 0.750914 12.4215 0.454394 12.8766C0.157874 13.3316 0 13.863 0 14.4062C0 14.9494 0.157874 15.4809 0.454394 15.9359C0.750914 16.3909 1.17329 16.7499 1.67007 16.969L7.26921 19.4058L5.58947 19.994V38.1996C5.58947 38.571 5.73694 38.9272 5.99945 39.1898C6.26196 39.4525 6.618 39.6 6.98925 39.6C7.36049 39.6 7.71654 39.4525 7.97905 39.1898C8.24156 38.9272 8.38903 38.571 8.38903 38.1996V21.7305L27.986 14.3922L24.5705 12.8798L10.9086 18.0053L2.7899 14.3922L27.986 3.18877L53.1821 14.3922L27.986 25.5957L17.7396 21.0583L13.9882 22.4587L26.8382 28.0604C27.1953 28.2187 27.5815 28.3004 27.972 28.3004C28.3625 28.3004 28.7488 28.2187 29.1058 28.0604L41.9558 22.4587V31.1974C38.1039 34.6701 33.1412 36.6561 27.958 36.7991C22.7748 36.6561 17.8121 34.6701 13.9602 31.1974V22.4587L11.1606 23.495V32.3738L12.0005 33.186C16.3814 37.1829 22.0587 39.4608 27.986 39.6C33.8927 39.4772 39.5576 37.2301 43.9435 33.2701L44.7834 32.4578V21.2543L54.3299 17.025C54.8267 16.8059 55.2491 16.4469 55.5456 15.9919C55.8421 15.5369 56 15.0054 56 14.4622C56 13.9191 55.8421 13.3876 55.5456 12.9326C55.2491 12.4776 54.8267 12.1186 54.3299 11.8995V11.8434Z"
        fill="#2B75B3"
      />
    </SvgIcon>
  );
};

export default AcadCap;
